import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Rating,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fromWei, formatCurrency } from "../../../utils/helper";
import PaymentInfo from "./PaymentInfo";
import CheckIcon from "@mui/icons-material/Check";
import ChatBox from "./ChatBox";
import { useDispatch, useSelector } from "react-redux";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import { notifySeller } from "../../../utils/httpCalls/orderTradeCalls";
import { getUserTradeById } from "../../../actions/tradeActions";
import {
  BUYER_DISPUTE_TIME_LIMIT,
  BUYER_NOTIFY_SELLER_TIME_LIMIT,
} from "../../../constants/index";
import DisputePopup from "../../../common/popups/Dispute/DisputePopup";
import CountDownTime from "./CountDownTime";
import DisputeProgress from "./DisputeProgress";
import DisputeProofs from "./DisputeProofs";
import CancelDisputePopup from "../../../common/popups/CancelDisputePopup";
import PopupLayout from "../../../common/popups/PopupLayout";
import { postReview } from "../../../utils/httpCalls/reviewCalls";
import { FormattedMessage, useIntl } from "react-intl";

export default function BuyOrderWaiting({
  classes,
  pendingTrade,
  fiatTransfer,
  tradeType,
  isTradeCancelled,
}) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [notifySellerFlag, setNotifySeller] = useState(false);
  const [raiseDisputeFlag, setRaiseDispute] = useState(false);
  const [disputePopup, setDisputePopup] = useState(false);
  const [cancelDisputePopup, setCancelDispute] = useState(false);

  const [isTradeTimeExpired, setTradeTimeExpire] = useState(false);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const userAuth = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const { chainId } = useActiveWeb3React();
  const intl = useIntl();
  const handleReviewSubmit = async () => {
    const payload = {
      comment: review,
      rating,
      transactionId: pendingTrade._id,
    };

    await postReview(payload, userAuth?.account, userAuth?.jwtToken);
    dispatch(
      getUserTradeById(pendingTrade?._id, userAuth?.account, userAuth.jwtToken)
    );
    setOpenSnackbar(true);
  };

  const isFiatTransferDone = useMemo(() => {
    if (pendingTrade?.transaction_status >= 2) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isSellerNotified = useMemo(() => {
    if (!pendingTrade) {
      return true;
    }

    if (!pendingTrade?.is_seller_notified) {
      return false;
    }
    if (!pendingTrade?.is_seller_notified) {
      return false;
    }
    return true;
  }, [pendingTrade]);

  const isTradeCompleted = useMemo(() => {
    if (pendingTrade?.completed) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isDisputeRaised = useMemo(() => {
    if (pendingTrade?.dispute) {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const isDisputeCancelled = useMemo(() => {
    if (pendingTrade?.dispute?.dispute_status === "cancelled") {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const isReviewed = useMemo(() => {
    return pendingTrade?.review ? true : false;
  }, [pendingTrade]);

  const isAllowedToReview = useMemo(() => {
    if (pendingTrade?.order?.user === userAuth?.id) {
      return false;
    }

    return true;
  }, [pendingTrade, userAuth]);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const handleSellerNotify = useCallback(async () => {
    if (!userAuth?.jwtToken || !pendingTrade?._id || !chainId) {
      return;
    }

    setLoading(true);
    try {
      const res = await notifySeller(
        pendingTrade?._id,
        userAuth?.account,
        userAuth?.jwtToken
      );

      if (res?.status !== 201) {
        // request error
        console.log("notify request error ", res);
      }
      dispatch(
        getUserTradeById(
          pendingTrade?._id,
          userAuth?.account,
          userAuth.jwtToken
        )
      );
    } catch (error) {}
    setLoading(false);
  }, [pendingTrade, userAuth, chainId, dispatch, setLoading]);

  return (
    <div className={classes.infoCard}>
      {raiseDisputeFlag && (
        <DisputePopup
          resetPopup={() => setDisputePopup(false)}
          trade={pendingTrade}
          isSeller={false}
          popupActive={disputePopup}
        />
      )}
      <PopupLayout popupActive={cancelDisputePopup}>
        <CancelDisputePopup
          resetPopup={() => setCancelDispute(false)}
          trade={pendingTrade}
        />
      </PopupLayout>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <SnackbarContent message="Review submitted successfully!" />
      </Snackbar>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={md ? "100%" : "60%"}
        flexWrap="wrap"
      >
        <Typography
          variant="body2"
          color={theme.palette.text.primary}
          fontSize={16}
          fontWeight={500}
        >
          <FormattedMessage
            id={tradeType?.toUpperCase()}
            defaultMessage={tradeType?.toUpperCase()}
          />{" "}
          {` ${pendingTrade?.order?.token?.symbol}`}{" "}
          <FormattedMessage id="with" defaultMessage="with" />{" "}
          {pendingTrade?.order?.fiat?.fiat}
        </Typography>

        {!isTradeCancelled && !isTradeCompleted && (
          <Box display="flex" alignItems="center">
            <Typography
              textAlign="left"
              variant="body2"
              fontSize={14}
              fontWeight={500}
              color={theme.palette.mode === "light" ? "#778090" : "#646892"}
              mr={2}
            >
              <FormattedMessage
                id="Time remaining"
                defaultMessage="Time remaining"
              />
            </Typography>
            <Typography
              textAlign="left"
              variant="body2"
              fontSize={18}
              color={theme.palette.text.primary}
              fontWeight={600}
              letterSpacing={1.1}
            >
              <CountDownTime
                color={theme.palette.text.primary}
                isCompleted={isTradeCompleted}
                createdAt={pendingTrade?.created_at}
                timeWindow={pendingTrade?.time_limit}
                onComplete={() => setTradeTimeExpire(true)}
              />
            </Typography>
          </Box>
        )}
      </Box>

      <Grid container spacing={1}>
        <Grid item md={8} sm={12}>
          <Box mt={1}>
            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor: "#c8e6c9",
                    color: "white",
                    border: "3px solid #04A56D",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={16}
                  fontWeight={600}
                  pl={1}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Confirm Order Info"
                    defaultMessage="Confirm Order Info"
                  />
                </Typography>
              </div>{" "}
              <div className="d-flex justify-content-start">
                <div
                  style={{
                    borderLeft: `1px dotted ${theme.palette.text.primary}`,
                    height: "auto",
                    marginLeft: 5,
                    width: 20,
                  }}
                ></div>
                <div
                  className="row w-100"
                  style={{
                    maxWidth: 600,
                    marginTop: sm ? 8 : 10,
                    marginBottom: 10,
                  }}
                >
                  <div className="col-md-3">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage id="Amount" defaultMessage="Amount" />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={"#04A56D"}
                        fontWeight={600}
                        letterSpacing={1.1}
                      >
                        {formatCurrency(pendingTrade?.fiat_amount, true)}{" "}
                        {pendingTrade?.order?.fiat?.fiat_label}
                      </Typography>
                    </Box>
                  </div>
                  <div className="col-md-3">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage id="Price" defaultMessage="Price" />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={theme.palette.text.primary}
                        fontWeight={600}
                        letterSpacing={1.1}
                      >
                        {formatCurrency(pendingTrade?.order?.order_unit_price)}{" "}
                        {pendingTrade?.order?.fiat?.fiat}
                      </Typography>
                    </Box>
                  </div>
                  <div className="col-md-4">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage
                          id="Quantity (After fee)"
                          defaultMessage="Quantity (After fee)"
                        />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={theme.palette.text.primary}
                        fontWeight={600}
                        letterSpacing={1.1}
                      >
                        {formatCurrency(
                          fromWei(
                            pendingTrade?.final_token_amount,
                            pendingTrade?.order?.token?.decimals
                          )
                        )}{" "}
                        {pendingTrade?.order?.token?.symbol}
                      </Typography>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>

            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: isFiatTransferDone ? "#c8e6c9" : "#f9f9f9",
                    color: "white",
                    border: isFiatTransferDone
                      ? "3px solid #04A56D"
                      : "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={16}
                  fontWeight={600}
                  pl={1}
                  py={0.5}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Transfer the funds to seller account provided below"
                    defaultMessage="Transfer the funds to seller account provided below"
                  />
                  :
                </Typography>
              </div>
              <div
                style={sm ? { maxWidth: 400 } : {}}
                className="d-flex justify-content-start h-100"
              >
                <div
                  style={{
                    borderLeft: `1px dotted ${theme.palette.text.primary}`,
                    height: "auto",
                    marginLeft: 5,
                    width: 20,
                  }}
                ></div>
                <PaymentInfo
                  classes={classes}
                  pendingTrade={pendingTrade}
                  isFiatTransferDone={isFiatTransferDone}
                  isTimeExpire={isTradeTimeExpired}
                  isTradeCancelled={isTradeCancelled}
                />
              </div>
            </Box>

            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: "#f9f9f9",
                    color: "white",
                    border: isTradeCompleted
                      ? "3px solid  #04A56D"
                      : "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={16}
                  fontWeight={600}
                  pl={1}
                  px={0.5}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Waiting for seller to release tokens"
                    defaultMessage={"Waiting for seller to release tokens"}
                  />
                </Typography>
              </div>{" "}
              {pendingTrade?.transaction_status >= 2 && !isTradeCancelled && (
                <div className="d-flex justify-content-start h-100">
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,
                      height: "auto",
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>
                  <div className={classes.paymentCard}>
                    {isTradeCompleted && (
                      <div
                        className="d-flex  justify-content-center align-items-center"
                        style={{ color: theme.palette.text.primary }}
                      >
                        <CheckIcon
                          color="success"
                          fontSize="small"
                          style={{ marginRight: 10 }}
                        />{" "}
                        <FormattedMessage
                          id="Seller released tokens"
                          defaultMessage={"Seller released tokens"}
                        />
                      </div>
                    )}

                    {!isTradeCompleted && !isDisputeRaised && (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src="/loader.gif"
                            alt="loader"
                            height="100px"
                            style={{ marginTop: 20 }}
                          />{" "}
                        </div>
                        <Typography
                          textAlign="left"
                          variant="body2"
                          fontSize={14}
                          fontWeight={600}
                          pt={2}
                          color={theme.palette.text.primary}
                        >
                          <FormattedMessage
                            id="Waiting for confirmation"
                            defaultMessage={"Waiting for confirmation"}
                          />
                        </Typography>
                        <div className="text-center mt-2">
                          <Button
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              fontSize: 12,
                              margin: 5,
                            }}
                            disabled={
                              !notifySellerFlag || isSellerNotified || loading
                            }
                            onClick={handleSellerNotify}
                          >
                            {isSellerNotified ? (
                              <FormattedMessage
                                id="Seller notified"
                                defaultMessage={"Seller notified"}
                              />
                            ) : (
                              <FormattedMessage
                                id="Notify Seller"
                                defaultMessage={"Notify Seller"}
                              />
                            )}
                            {!notifySellerFlag &&
                              fiatTransfer?.created_at !== undefined && (
                                <Box ml={0.5}>
                                  <CountDownTime
                                    onComplete={() => {
                                      setNotifySeller(true);
                                    }}
                                    timeWindow={BUYER_NOTIFY_SELLER_TIME_LIMIT}
                                    font={12}
                                    createdAt={fiatTransfer?.created_at}
                                  />
                                </Box>
                              )}
                          </Button>
                          <Button
                            onClick={() => setDisputePopup(true)}
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              margin: 5,
                              fontSize: 12,
                            }}
                            disabled={!raiseDisputeFlag || isDisputeRaised}
                          >
                            {isDisputeRaised ? (
                              <FormattedMessage
                                id="Pending dispute"
                                defaultMessage={"Pending dispute"}
                              />
                            ) : (
                              <FormattedMessage
                                id="Raise dispute"
                                defaultMessage={"Raise dispute"}
                              />
                            )}
                            {!raiseDisputeFlag &&
                              fiatTransfer?.created_at !== undefined && (
                                <Box ml={0.5}>
                                  <CountDownTime
                                    onComplete={() => {
                                      setRaiseDispute(true);
                                    }}
                                    timeWindow={BUYER_DISPUTE_TIME_LIMIT}
                                    font={12}
                                    createdAt={fiatTransfer?.created_at}
                                  />
                                </Box>
                              )}
                          </Button>
                        </div>
                      </div>
                    )}
                    {!isTradeCompleted && isDisputeRaised && (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex align-items-center text-center ">
                          <Button
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              fontSize: 12,
                            }}
                            disabled={true}
                          >
                            <FormattedMessage
                              id="Dispute raised"
                              defaultMessage={"Dispute raised"}
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Box>

            {isDisputeRaised && (
              <Box>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 8,
                      height: 8,

                      borderRadius: "50%",
                      backgroundColor: isTradeCompleted ? "#c8e6c9" : "#f9f9f9",
                      color: "white",
                      border: isTradeCompleted
                        ? "3px solid #04A56D"
                        : "3px solid #bdbdbd",
                      padding: 3,
                    }}
                  ></div>{" "}
                  <Typography
                    textAlign="left"
                    variant="body2"
                    fontSize={16}
                    fontWeight={600}
                    pl={1}
                    color={theme.palette.text.primary}
                  >
                    <FormattedMessage
                      id="Dispute submitted"
                      defaultMessage={"Dispute submitted"}
                    />
                  </Typography>
                </div>{" "}
                <div className="d-flex justify-content-start h-100">
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,
                      height: "auto",
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>
                  <Box className={classes.paymentCard}>
                    <div className="d-flex flex-column  align-items-start  h-100">
                      <DisputeProgress
                        pendingTrade={pendingTrade}
                        tradeType={tradeType}
                      />
                      {
                        <>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={15}
                            fontWeight={600}
                            mt={2}
                            pl={sm && 0.5}
                            color={theme.palette.text.primary}
                          >
                            <FormattedMessage
                              id="Reason:"
                              defaultMessage="Reason:"
                            />
                          </Typography>

                          <Typography
                            textAlign="left"
                            variant="body2"
                            w="50%"
                            fontSize={16}
                            mt={0.5}
                            pl={sm && 0.5}
                            color={theme.palette.text.primary}
                          >
                            {pendingTrade?.dispute?.reason === "Others"
                              ? pendingTrade?.dispute?.description
                              : pendingTrade?.dispute?.reason}
                          </Typography>
                          <Box mb={3}>
                            <DisputeProofs
                              isTradeCompleted={isTradeCompleted}
                              pendingTrade={pendingTrade}
                              userType="buyer"
                              allowUpload={
                                isTradeCancelled ||
                                isTradeCompleted ||
                                isDisputeCancelled
                                  ? false
                                  : true
                              }
                              title="Your Proofs:"
                            />
                            <DisputeProofs
                              isTradeCompleted={isTradeCompleted}
                              pendingTrade={pendingTrade}
                              userType="seller"
                              title="Seller's Proofs:"
                            />
                          </Box>
                        </>
                      }
                    </div>

                    <div className="text-center mt-2">
                      {!isTradeCancelled &&
                        !isDisputeCancelled &&
                        !isTradeCompleted && (
                          <Button
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              fontSize: 12,
                            }}
                            disabled={loading || isDisputeCancelled}
                            onClick={() => setCancelDispute(true)}
                          >
                            {isDisputeCancelled ? (
                              <FormattedMessage
                                id="Cancelled"
                                defaultMessage="Cancelled"
                              />
                            ) : (
                              <FormattedMessage
                                id="Cancel Dispute"
                                defaultMessage="Cancel Dispute"
                              />
                            )}
                          </Button>
                        )}
                    </div>
                  </Box>
                </div>
              </Box>
            )}

            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: isTradeCompleted ? "#c8e6c9" : "#f9f9f9",
                    color: "white",
                    border: isTradeCompleted
                      ? "3px solid #04A56D"
                      : "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={16}
                  fontWeight={600}
                  pl={1}
                  py={0.5}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Completed"
                    defaultMessage={"Completed"}
                  />
                </Typography>
              </div>{" "}
              {isTradeCompleted && (
                <div className="d-flex justify-content-start h-100">
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,
                      height: "100%",
                      minHeight: 340,
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>

                  <div className={classes.paymentCard}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src="https://cdn3d.iconscout.com/3d/premium/thumb/check-2872554-2389850.png"
                          height="100px"
                          style={{ marginTop: 20 }}
                        />{" "}
                      </div>
                      <Typography
                        textAlign="center"
                        variant="body2"
                        fontSize={16}
                        fontWeight={600}
                        pl={1}
                        color={theme.palette.text.primary}
                      >
                        <FormattedMessage
                          id="Your order is executed successfully."
                          defaultMessage={
                            "Your order is executed successfully."
                          }
                        />
                      </Typography>
                      <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* <Button
                          style={{
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? "white"
                                : "#646892",
                            border: `1px solid #6A55EA`,
                            borderRadius: 14,
                            paddingLeft: 20,
                            paddingRight: 20,
                            fontSize: 12,
                            whiteSpace: "nowrap",
                            color: theme.palette.text.primary,
                          }}
                        >
                          View your order
                        </Button> */}
                      </Box>
                      <Box
                        display={isAllowedToReview ? "flex" : "none"}
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt={6}
                      >
                        {!isReviewed && (
                          <TextField
                            placeholder={`${intl.formatMessage({
                              id: "Write a review",
                              defaultMessage: "Write a review",
                            })}`}
                            multiline
                            fullWidth
                            variant="outlined"
                            size="small"
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              borderRadius: 1,
                              ".MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: theme.palette.text.primary,
                                color: theme.palette.text.primary,
                              },
                            }}
                            inputProps={{ maxLength: 1000 }} // 162-340 words with spaces
                            maxRows={2}
                            value={
                              isReviewed
                                ? pendingTrade?.review?.comment
                                : review
                            }
                            disabled={isReviewed}
                            onChange={(e) => setReview(e.target.value)}
                            className={classes.textInput}
                          />
                        )}
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={1.5}
                          width="100%"
                        >
                          <Rating
                            size="large"
                            value={
                              isReviewed ? pendingTrade?.review?.rating : rating
                            }
                            readOnly={isReviewed}
                            onChange={(e, newRating) => setRating(newRating)}
                          />
                          <Button
                            sx={{
                              ml: 2,
                              display: isReviewed ? "none" : "block",
                            }}
                            variant="outlined"
                            disabled={!rating}
                            onClick={handleReviewSubmit}
                          >
                            <FormattedMessage
                              id="Submit"
                              defaultMessage="Submit"
                            />
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} md={4}>
          <ChatBox />
        </Grid>
      </Grid>
    </div>
  );
}
