import React, { useMemo } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { FormattedMessage } from "react-intl";
// import theme from "../../../theme";

export default function OrderProgress({
  pendingTrade,
  createLoading,
  fetchLoading,
  tradeType,
  isTradeCancelled,
}) {
  const theme = useTheme();
  const isFiatTransferDone = useMemo(() => {
    // todo: add transfer logic
    if (pendingTrade?.transaction_status >= 2) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isSellerTokenReleased = useMemo(() => {
    if (pendingTrade?.transaction_status === 3) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isTradeCompleted = useMemo(() => {
    if (pendingTrade?.transaction_status === 3) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  // for sell order status
  const isBuyerConfirmedPayment = useMemo(() => {
    if (pendingTrade?.transaction_status >= 2) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isTokenDeposited = useMemo(() => {
    if (pendingTrade?.transaction_status === 0) {
      return false;
    }
    return true;
  }, [pendingTrade]);

  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      flexWrap="wrap-reverse"
    >
      {tradeType === "buy" && (
        <Box display={"flex"} mt={3}>
          {isTradeCancelled && (
            <>
              <Box pr={1}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={md ? 13 : 14}
                  w="50%"
                  fontWeight={500}
                  mt={1}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Trade cancelled"
                    defaultMessage="Trade cancelled"
                  />
                </Typography>
              </Box>
            </>
          )}
          {!isTradeCancelled && (
            <>
              <Box pr={1}>
                <Box display="flex" alignItems="center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      backgroundColor: isFiatTransferDone
                        ? "#04A56D"
                        : "#bdbdbd",
                      color: isFiatTransferDone ? "white" : "black",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    {createLoading || fetchLoading ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      1
                    )}
                  </div>{" "}
                  <div
                    style={{
                      borderTop: `1px dotted ${theme.palette.text.primary}`,
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 90 : md ? 110 : 170,
                    }}
                  ></div>
                </Box>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={md ? 13 : 14}
                  w="50%"
                  fontWeight={500}
                  color={
                    isFiatTransferDone
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                  mt={1}
                >
                  <FormattedMessage
                    id="Transfer Payment to seller"
                    defaultMessage="Transfer Payment to seller"
                  />
                </Typography>
              </Box>
              <Box pr={1}>
                <Box width={"100%"} className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      backgroundColor: isSellerTokenReleased
                        ? "#04A56D"
                        : "#bdbdbd",
                      color: isSellerTokenReleased ? "white" : "black",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    2
                  </div>{" "}
                  <div
                    style={{
                      borderTop: `1px dotted ${theme.palette.text.primary}`,
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 90 : md ? 110 : 170,
                    }}
                  ></div>
                </Box>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={md ? 13 : 14}
                  fontWeight={500}
                  color={
                    isSellerTokenReleased
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                  mt={1}
                >
                  {isSellerTokenReleased ? (
                    <FormattedMessage
                      id="Seller released tokens"
                      defaultMessage="Seller released tokens"
                    />
                  ) : (
                    <FormattedMessage
                      id="Pending seller confirmation"
                      defaultMessage="Pending seller confirmation"
                    />
                  )}
                </Typography>
              </Box>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      backgroundColor: isTradeCompleted ? "#04A56D" : "#bdbdbd",
                      color: isTradeCompleted ? "white" : "black",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    3
                  </div>
                </div>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={md ? 13 : 14}
                  fontWeight={500}
                  color={
                    isTradeCompleted
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                  mt={1}
                >
                  <FormattedMessage id="Completed" defaultMessage="Completed" />
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}

      {tradeType === "sell" && (
        <Box display="flex" mt={3}>
          {isTradeCancelled && (
            <>
              <Box pr={1}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={14}
                  fontWeight={500}
                  mt={1}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Trade cancelled"
                    defaultMessage="Trade cancelled"
                  />
                </Typography>
              </Box>
            </>
          )}
          {!isTradeCancelled && (
            <>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      backgroundColor: "#04A56D",
                      color: "white",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    {createLoading || fetchLoading ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      1
                    )}
                  </div>
                  <div
                    style={{
                      borderTop: `1px dotted ${theme.palette.text.primary}`,
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 90 : md ? 110 : 170,
                    }}
                  ></div>
                </div>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={14}
                  fontWeight={500}
                  mt={1}
                  color={
                    isTokenDeposited
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                >
                  {isTokenDeposited ? (
                    <FormattedMessage
                      id="Token Deposited"
                      defaultMessage="Token Deposited"
                    />
                  ) : (
                    <>
                      <FormattedMessage
                        id="Deposit tokens to"
                        defaultMessage="Deposit tokens to"
                      />{" "}
                      {pendingTrade?.order?.token?.symbol}{" "}
                      <FormattedMessage id="reserve" defaultMessage="reserve" />
                    </>
                  )}
                </Typography>
              </Box>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      backgroundColor: isBuyerConfirmedPayment
                        ? "#04A56D"
                        : "#bdbdbd",
                      color: isBuyerConfirmedPayment ? "white" : "black",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    2
                  </div>{" "}
                  <div
                    style={{
                      borderTop: `1px dotted ${theme.palette.text.primary}`,
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 90 : md ? 110 : 170,
                    }}
                  ></div>
                </div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={14}
                  fontWeight={500}
                  mt={1}
                  color={
                    isBuyerConfirmedPayment
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                >
                  {isBuyerConfirmedPayment ? (
                    <FormattedMessage
                      id="Buyer confirmed"
                      defaultMessage="Buyer confirmed"
                    />
                  ) : (
                    <FormattedMessage
                      id="Pending buyer confirmation"
                      defaultMessage="Pending buyer confirmation"
                    />
                  )}
                </Typography>
              </Box>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      backgroundColor: isTradeCompleted ? "#04A56D" : "#bdbdbd",
                      color: isTradeCompleted ? "white" : "black",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    3
                  </div>{" "}
                  <div
                    style={{
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 90 : md ? 110 : 170,
                    }}
                  ></div>
                </div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={14}
                  fontWeight={500}
                  mt={1}
                  color={
                    isTradeCompleted
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                >
                  <FormattedMessage id="Completed" defaultMessage="Completed" />
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}

      <Box>
        <Typography
          textAlign="left"
          variant="body2"
          fontSize={13}
          color={theme.palette.mode === "light" ? "#778090" : "#646892"}
        >
          <FormattedMessage id="Order Number" defaultMessage="Order Number" />:
          <span
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
              paddingLeft: 5,
            }}
          >
            {pendingTrade?.order?.order_id}
          </span>
        </Typography>
        <Typography
          textAlign="left"
          variant="body2"
          fontSize={13}
          mt={1}
          color={theme.palette.mode === "light" ? "#778090" : "#646892"}
        >
          <FormattedMessage id="Trade Id" defaultMessage="Trade Id" />:
          <span
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
              paddingLeft: 5,
            }}
          >
            {pendingTrade?._id}
          </span>
        </Typography>
        <Typography
          textAlign="left"
          variant="body2"
          fontSize={13}
          color={theme.palette.mode === "light" ? "#778090" : "#646892"}
          mt={1}
        >
          <FormattedMessage id="Time created" defaultMessage="Time created" />:
          <span
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
              paddingLeft: 5,
            }}
          >
            {moment(pendingTrade?.created_at).format("hh:mm A MM-DD-YYYY")}
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
