const JAPANESE = {
  Mode: "モード",
  Feedback: "フィードバック",
  Home: "家",
  "Create Order": "注文を作成します",
  "My Orders": "私の注文",
  Wallet: "財布",
  Profile: "プロフィール",
  Leaderboard: "リーダーボード",
  Faucet: "蛇口",
  Banned: "禁止された",
  "This account has been banned. If this is unexpected, please contact":
    "このアカウントは禁止されています。これが予期しない場合は、お問い合わせください",
  footermessage: "100K以上の人々に協力して、世界をより良い場所にしてください。",
  "Copyright 2023 By": "著作権2023 by",
  "All Rights Reserved": "全著作権所有",
  "How P2P Works": "P2Pの仕組み",
  home_howitwork:
    "Polkabridge P2Pは、ポルカブリッジがトランザクションの保管のためのエスクローとして機能する場所ですぐにお金を暗号に変換するのに役立ちます。",
  Buy: "買う",
  Sell: "売る",
  "STEP 1": "ステップ1",
  buy_step1:
    "目的の価格と支払い方法で広告を選択してください。注文を完了するには、購入数量と取引金額を入力します。",
  "Deposit tokens into": "トークンを入金します",
  wallet: "財布",
  "and create orders": "注文を作成します",
  "STEP 2": "ステップ2",
  buy_step2: "売り手が提供する情報に基づいて支払いを完了します。",
  step2_sell: "買い手から全額の支払いを受け取ったことを確認してください。",
  "STEP 3": "ステップ3",
  step31_buy:
    "売り手が支払いを受け取った後、彼はコインを解放します。に向かいます",
  step32_buy: "受け取ったコインを確認します。",
  step3_sell:
    "支払いを全額受け取った後にのみ、買い手にコインをリリースします。",
  "Before you start trading you need to update your profile.":
    "取引を開始する前に、プロフィールを更新する必要があります。",
  "Before your start trading please verify your phone":
    "取引を開始する前に、電話を確認してください",
  "Please add a payment method to start buy crypto":
    "Cryptoの購入を開始するには、支払い方法を追加してください",
  "Update now": "今すぐアップデート",
  "Verify now": "今すぐ確認してください",
  "Add now": "今すぐ追加します",
  Logout: "ログアウト",
  "Switching...": "切り替え...",
  Switch: "スイッチ",
  "Switch Network": "スイッチネットワーク",
  "Cancel dispute": "紛争をキャンセルします",
  canceldisputewarn:
    "再び紛争を提起する選択肢はありません。問題が解決しない場合はキャンセルしないでください",
  "I confirm I have recieved the money from seller":
    "売り手からお金を受け取ったことを確認します",
  "Cancelling...": "キャンセル...",
  "Cancel Trade": "取引をキャンセルします",
  Warning: "警告",
  warningcancel:
    "注文をキャンセルした後、払い戻しはありません。すでにお金を譲渡している場合は、キャンセルしないでください。",
  tradewarning:
    "1日に4つのトランザクションをキャンセルした場合、取引はその日に停止されます。",
  "I confirm I have not paid the payee.":
    "私は受取人に支払っていないことを確認します。",
  "Please wait...": "お待ちください...",
  "Cancel this trade": "この取引をキャンセルします",
  "Confirm Transfer": "転送を確認します",
  confirmnote:
    "P2Pトランザクションでは、受信当事者がサポートする方法で支払いまたは譲渡する必要があります。 Polkabridgeはアカウントから自動的に差し引かれません。",
  "Transfer Amount": "払込金額",
  "In exchange for": "見返りに",
  "Upload proof of payment": "支払い証明をアップロードします",
  "Confirm the account of the receiving party":
    "受信当事者のアカウントを確認します",
  "Payment QR code": "支払いQRコード",
  confirmnote2:
    "受信者があなたの譲渡を確認できるように、できるだけ早く注文を完了してください。受信者のアカウントを選択してください。詐欺的な支払いの確認は、あなたのアカウントを凍結する可能性があります",
  "I didn't Pay Yet": "私はまだ支払いませんでした",
  "Transfer Completed": "転送が完了しました",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "トークンをポルカブリッジボールトに堆積させ、手間をかけずに取引します",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Polkabridge Vaultからトークンを料金で引き出します",
  Amount: "額",
  "Select Max": "Maxを選択します",
  Balance: "バランス",
  "Available Amount": "利用可能な金額",
  "Withdrawal Fee": "引き出し料",
  "In order": "順番に",
  View: "意見",
  "Send OTP": "OTPを送信します",
  "Verify Phone": "電話を確認します",
  "Verify Email": "Eメールを確認します",
  "Your phone number has been verified": "お使いの電話番号が確認されました",
  "Your email address has been verified":
    "あなたのメールアドレスが検証されました",
  "6 digit otp has been sent on your phone":
    "6桁のOTPがお使いの携帯電話に送信されました",
  emailsent:
    "電子メールに送信された6桁の確認コード。受信トレイにメールが表示されない場合は、必ずスパムフォルダーを確認してください",
  "Verify OTP": "OTPを確認します",
  "OTP expired": "OTPの有効期限が切れました",
  "Resend OTP": "otpを再送信します",
  "Your OTP will expire in": "OTPは期限切れになります",
  "Confirm and Release": "確認とリリース",
  Tips: "チップ",
  dontnotcheckbuyerpayment:
    "買い手の支払い証明を確認するだけではありません。アカウントにログインし、支払いが受信されたことを確認してください。",
  ifstillprocessing:
    "支払いがまだ処理されている場合は、暗号をリリースする前に、アカウントへの支払いを受信するまで待ちます！",
  donotacceptthridparty:
    "サードパーティアカウントからの支払いを受け入れないでください。暗号をリリースした後、銀行のチャージバックによって引き起こされた財政的損失を避けるために、そのような支払いを受け取った場合、すぐに全額を払い戻します。",
  "Confirm User Account and Release Amount":
    "ユーザーアカウントとリリース金額を確認します",
  iconfirmpaymentcorrect:
    "支払いが私のアカウントにクレジットされており、利用可能な残高が正しいことを確認します。",
  "Releasing...": "リリース...",
  Order: "注文",
  "is successfully completed": "正常に完了しました",
  Submitted: "提出",
  Submit: "送信",
  "Click below for processing": "処理については以下をクリックしてください",
  "Waiting for confirmation": "確認を待ちます",
  "Please confirm the transaction into your metamask popup":
    "メタマスクポップアップへのトランザクションを確認してください",
  "Withdrawal Request Submitted": "提出された撤回要求",
  "Withdrawal Address": "引き出しアドレス",
  Back: "戻る",
  "Withdrawal History": "撤退履歴",
  "Deposit Processing": "堆積処理",
  depositapproval:
    "預金の承認が提出されました。我慢してください。預金プロセスが進行中に預金を増やさないでください。",
  "Deposits History": "預金の歴史",
  "Transaction Submitted": "トランザクションが送信されました",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "トランザクションが提出され、ブロックチェーンからの確認を待っています。",
  "View Status": "ステータスを表示します",
  "Transaction Successful!": "トランザクションが成功しました！",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "素晴らしい！ブロックチェーンでトランザクションが正常に確認されています。",
  "Transaction Failed!": "処理に失敗しました！",
  "We have encountered an error in the transaction. Please try again.":
    "トランザクションでエラーが発生しました。もう一度やり直してください。",
  "Sign Message": "署名メッセージ",
  "Connect Wallet": "ウォレットを接続します",
  "Waiting to connect": "接続を待っています",
  "Confirm this connection in your wallet":
    "ウォレットでこの接続を確認してください",
  walletconnect_rule:
    "ウォレットを接続することにより、Polkabridge P2Pのサービス利用規約に同意し、プライバシーポリシーに同意します。",
  "Please sign this message requested in your metamask":
    "メタマスクでリクエストされたこのメッセージに署名してください",

  Metamask: "メタマスク",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "次のテストネットバージョンが来ます！",
  "Follow Us": "フォローする",
  "Claim Faucet": "Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Goerli TestNetまたはBSC TestNetのテストトークンをクレームします。",
  "Unauthorised Access!": "不正アクセス！",
  "Please connect your wallet first to continue P2P trading.":
    "最初にウォレットを接続して、P2P取引を続けてください。",
  Price: "価格",
  Limit: "制限",
  Following: "続く",
  Unfollow: "解除してください",
  Follow: "従う",
  Rating: "評価",
  "Online Ads": "オンライン広告",
  "All Feedback": "すべてのフィードバック",
  "Volume in": "ボリュームイン",
  "Decentralized P2P Exchange, powered by ": "分散型P2P交換、搭載 ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Polkabridgeとの最初の分散P2P取引を体験してください",
  Fiat: "フィアット",
  All: "全て",
  Token: "トークン",
  "Payment Mode": "支払いモード",
  orders: "注文",
  completion: "完了",
  BUY: "買う",
  SELL: "売る",
  Verify: "確認",
  "Crypto Amount": "暗号量",
  "Fiat Amount": "フィアット量",
  "Market Open Orders": "市場のオープン注文",
  Advertisers: "広告主",
  "Available | Limit": "利用可能|制限",
  Payment: "支払い",
  Trade: "トレード",
  "Exclusive ranking for Polkabridge Traders":
    "ポルカブリッジトレーダー向けの独占ランキング",
  Volume: "音量",
  Followers: "フォロワー",
  "Avg. Pay Time": "平均。支払い時間",
  "Avg. Release Time": "平均。リリース時間",
  Username: "ユーザー名",
  Filter: "フィルター",
  Clear: "クリア",
  User: "ユーザー",
  "Last Trade Time": "最後の取引時間",
  Action: "アクション",
  "Unauthorised Access": "不正アクセス",
  Apply: "申し込み",
  "Order No.": "注文番号。",
  Coin: "コイン",
  Type: "タイプ",
  Status: "スターテス",
  Time: "時間",
  Operation: "手術",
  "Crypto amount": "暗号量",
  "Fiat amount": "フィアット量",
  "Order type": "注文タイプ",
  "Order Details": "注文詳細",
  "Order Number": "注文番号",
  "Time created": "作成された時間",
  "Buy Order": "注文を購入します",
  Executed: "実行された",
  at: "で",
  "Total Fiat Amount": "合計フィアット額",
  "Crypto Unit Price": "暗号単価",
  "Min Order Limit": "最小注文制限",
  "Max Order Limit": "最大注文制限",
  "Trade between": "間の取引",
  "Payment Time Limit": "支払い時間制限",
  Remark: "述べる",
  "Need Help": "助けが必要",
  "Please wait": "お待ちください",
  "Cancel Order": "注文をキャンセルする",
  "Go To Home": "家に行きます",
  "Initiate New P2P Order": "新しいP2P注文を開始します",
  "Set type and price": "タイプと価格を設定します",
  "Review Your Order": "ご注文を確認してください",
  "I want to": "したい",
  "Order Limit": "注文制限",
  Min: "分",
  Max: "マックス",
  "Payment Methods": "お支払い方法",
  "Add payments": "支払いを追加します",
  "Current Price": "現在の価格",
  "Highest Price": "最高価格",
  "Lowest Price": "最低価格",
  "Please note that if you decide to":
    "あなたがすることにした場合に注意してください",
  SUBMIT: "送信",
  "the order, you wouldn't be able to": "注文、できないでしょう",
  EDIT: "編集",
  "it afterwards.": "その後。",
  "Review New P2P Order": "新しいP2P注文を確認します",
  "Review your order": "ご注文を確認してください",
  Remarks: "備考",
  Cancel: "キャンセル",
  "Submit Order": "注文を送信",
  "Order Submitted Successfully": "注文は正常に送信されます",
  "Order Type": "注文タイプ",
  Total: "合計",
  "Check your order": "ご注文を確認してください",
  Deposit: "デポジット",
  "to fulfill this sell order": "この売り注文を満たすため",
  with: "と",
  "Time remaining": "残り時間",
  "Confirm Order Info": "注文情報を確認します",
  "Quantity (After fee)": "数量（料金後）",
  "Chat with": "とチャットする",
  Uploading: "アップロード",
  "Upload Proof of Payment": "支払い証明をアップロードします",
  Browse: "ブラウズ",
  "Trade cancelled": "取引はキャンセルされました",
  "Transfer Payment to seller": "支払いを売り手に転送します",
  "Seller released tokens": "売り手はトークンをリリースしました",
  "Pending seller confirmation": "保留中の売り手の確認",
  Completed: "完了しました",
  "Token Deposited": "堆積したトークン",
  "Deposit tokens to": "トークンを預けます",
  reserve: "予約",
  "Buyer confirmed": "バイヤーが確認した",
  "Pending buyer confirmation": "保留中の購入者の確認",
  "Order Summary": "注文の概要",
  Available: "利用可能",
  "payment method": "支払方法",
  "Seller's Message:": "売り手のメッセージ：",
  "Buyer's Message:": "バイヤーのメッセージ：",
  "No message": "メッセージはありません",
  "Terms and conditions": "規約と条件",
  ordersummary_rule:
    "第三者アカウントを通じて支払いを送信しないでください。そのような支払いはすべて紛争に行かなければならず、返金/キャンセルされます。 P2P、Polkabridge、USDT、ETH、BTCなどの暗号関連の単語を含めないでください。",
  "I want to buy for": "買いたいです",
  "I want to sell for": "売りたいです",
  "I will get:": "私は取得します：",
  "I will send:": "私がお送りします：",
  Loading: "読み込み",
  "Order Processing": "注文処理",
  "Reference message": "参照メッセージ",
  "QR Code": "QRコード",
  "Payment confirmed": "支払いが確認されました",
  "I've Paid. Confirm": "私は支払いました。確認",
  Cancelled: "キャンセル",
  Name: "名前",
  Email: "Eメール",
  Quantity: "量",
  Your: "あなたの",
  "has been reserved in PolkaBridge vault":
    "Polkabridge Vaultに予約されています",
  "Token reserved": "トークンは予約されています",
  "Waiting for buyer's confirmation": "バイヤーの確認を待っています",
  "Buyer confirmed payment": "買い手は支払いを確認しました",
  "Buyer notified": "バイヤーが通知しました",
  "Notify buyer": "バイヤーに通知します",
  "Cancel trade": "取引をキャンセルします",
  "Dispute submitted": "紛争が提出されました",
  Reason: "理由",
  "Release tokens to buyer wallet":
    "バイヤーウォレットにトークンをリリースします",
  "Token released": "トークンがリリースされました",
  "Release tokens": "トークンをリリースします",
  "Your order is executed successfully.": "ご注文は正常に実行されます。",
  "My Profile": "私のプロフィール",
  "Update your preference for smooth trading experience.":
    "スムーズな取引体験の好みを更新してください。",
  Menu: "メニュー",
  Payments: "支払い",
  "Transaction history": "取引履歴",
  Settings: "設定",
  Referral: "照会",
  "Filter Order": "フィルターオーダー",
  TxnId: "txnid",
  "Transaction not found": "トランザクションが見つかりません",
  "Wallet unavailable": "ウォレットは利用できません",
  "Check explorer": "エクスプローラーを確認してください",
  "Full Name": "フルネーム",
  Mobile: "モバイル",
  "Preferred Fiat": "好ましいフィアット",
  "Edit profile": "プロファイル編集",
  "Upload Avatar": "アバターをアップロードします",
  "Updating...": "更新...",
  "Update profile": "プロファイルを更新します",
  "Are you sure, want to leave PolkaBridge?":
    "確かに、ポルカブリッジを離れたいですか？",
  "Log out": "ログアウト",
  "Fiat Preference": "フィアットの好み",
  Referrals: "紹介",
  "Will be available soon.": "まもなく利用可能になります。",
  "Payment Options": "支払いオプション",
  "Transaction History": "取引履歴",
  Fee: "手数料",
  TxnID: "txnid",
  "All payment methods were added": "すべての支払い方法が追加されました",
  "Add Payment Method": "支払い方法を追加します",
  "Payment method": "支払方法",
  "QR Code (optional)": "QRコード（オプション）",
  "Upload QR Code": "QRコードをアップロードします",
  "Update details": "詳細を更新します",
  "Not Uploaded": "アップロードされていません",
  Edit: "編集",
  "Update Payment": "支払いを更新します",
  Confirm: "確認",
  "Depositing...": "デポジット...",
  "Confirming...": "確認...",
  Withdraw: "撤退",
  Locked: "ロックされています",
  "P2P Wallet": "P2Pウォレット",
  walletpage_message:
    "P2Pプラットフォームでアセットを管理します。預金し、資産を引き出してP2Pの取引に参加できます",
  "Your Balances": "あなたのバランス",
  "Buy Amount": "購入金額",
  "Sell Amount": "販売金額",
  "Trade(s)": "貿易",
  active: "アクティブ",
  "Executed at": "で実行されました",
  Chain: "チェーン",
  "My Ads": "私の広告",
  Active: "アクティブ",
  "Amount reversed by my Bank.": "私の銀行によって取り消された金額。",
  "Transaction failed on my behalf": "「私の代わりに取引が失敗しました」",
  "Seller received the amount successfully.":
    "売り手は金額を正常に受け取りました。",
  "Seller provided the necessary information to complete the payment.":
    "売り手は、支払いを完了するために必要な情報を提供しました。",
  "I sent the remaining amount.": "「残りの金額を送金しました。」",
  "I raised the dispute by mistake.": "「私は間違って論争を起こしました。」",
  "I received the tokens.": "「トークンを受け取りました。」",
  Others: "その他",
  Quest: "クエスト",
  "Complete your quests and collect points.":
    "「クエストを完了してポイントを集めてください。」",
  "Total Points": "合計点",
  Redeem: "償還",
  "Resets in": "リセットインします",
  "Daily Quests": "毎日のクエスト",
  "Weekly Quests": "毎週のクエスト",
  Rules: "ルール",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1.毎日のタスク：タスクは00:00 UTCで毎日更新されます。",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2.タスクポイントを取得するには、「クレーム」をクリックする必要があります。ポイントがアカウントにクレジットされない場合。",
  questrule3:
    "3. クエスト ラウンド 1 の合計報酬は 60,000 PBR です。 ポイントは、要求されたポイント数に応じて、対応する PBR に変換されます",
  "4. Redeem points limit is 100 Points.":
    "4.償還ポイント制限は100ポイントです。",
  questrule5:
    "5. クエスト ラウンド 1 が終了すると、ユーザーは報酬を受け取ります。 特典は 7 営業日以内にユーザーに送信されます",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridgeは、イベントの最終解釈の権利を留保します。ユーザーが悪意のある行動に関与するか、セキュリティの脆弱性を活用した場合、Polkabridgeはユーザーのアカウントと資産を処分する権利を持っています。",
  Points: "ポイント",
  Claiming: "主張",
  Claimed: "主張した",
  Claim: "請求",
  Action: "アクション",
  "Redeem Points": "ポイントを引き換えます",
  "1 Points = 1 PBR": "1ポイント= 1 pbr",
  "Redeem Points limit is 100 Points": "引き換えポイント制限は100ポイントです",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "Redeem Request Adminを送信すると、財布にトークンを確認して転送します。",
  Tokens: "トークン",
  "Wallet Address": "ウォレットアドレス",
  "Confirm your wallet address and submit your request.":
    "ウォレットアドレスを確認し、リクエストを送信してください。",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "7日間以内にイーサリアムチェーンで少なくとも20注文を完了しました。",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "7日間以内にBSCチェーンで少なくとも20注文を完了しました。",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "両方のチェーンで7日間連続して預金して撤回します。",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "すべてのトークン（PBR-ETH、PBR-BSC、USDT-ETH、USDT-BSC、USDC、DAI、ETH、BUSD、BNB）との完全な取引。",
  "Give at least 10 feedback .": "少なくとも10個のフィードバックを与えます。",
  "Create at least 1 Ad on your FIAT currency":
    "Créez au moins 1 annonce sur votre monnaie fiduciaire",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Ethereum チェーンで少なくとも 1 つの買い注文と 1 つの売り注文を完了するる",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "BSC チェーンで少なくとも 1 つの買い注文と 1 つの売り注文を完了する",

  "Deposit or withdraw at least 1 token on Ethereum chain":
    "Ethereumチェーンに少なくとも1つのトークンを入金または引き出します",
  "Deposit or withdraw at least 1 token on BSC chain":
    "BSCチェーンに少なくとも1つのトークンを入金または引き出します",
  "Deposit at least 0.1 ETH or 0.1 BNB.":
    "少なくとも0.1 ETHまたは0.1 bnbを堆積させます。",

  "Waiting for seller to release tokens":
    "販売者がトークンをリリースするのを待っています",
  "Transfer the funds to seller account provided below":
    "下記の売り手のアカウントに資金を送金します",
  "Seller released tokens": "売り手がリリースしたトークン",
  "Waiting for confirmation": "確認を待ちます",
  "Dispute submitted": "提出された異議申し立て",
  Completed: "完了",
  "Your order is executed successfully": "ご注文は正常に実行されました",
  Submit: "送信",
  "Cancel Dispute": "紛争のキャンセル",
  Cancelled: "キャンセル",
  "Reason:": "理由",
  "Dispute raised": "提起された紛争",
  "In reviewing": "見直しでは",
  Resolved: "解決済み",
  "Your Proofs": "あなたの証明",
  "Write a review": "レビューを書く",
  "Transfer the funds to seller account provided below":
    "下記の売り手のアカウントに資金を送金します",
  "Waiting for seller to release tokens":
    "販売者がトークンをリリースするのを待っています",
  "Dispute submitted": "提出された異議申し立て",
  "Your Proofs": "あなたの証明",
  "Seller's Proofs": "売主の証明",
  "Raise dispute": "論争を起こす",
  "Upload dispute proof": "異議申し立てのアップロード",
  "Raise a dispute request with valid proof":
    "有効な証拠を添えて異議申し立てを行う",
  "Upload more proofs": "さらに証拠をアップロードする",
  "Reason for dispute": "論争の理由",
  Description: "説明",
  "Upload Video Proof of Payment": "支払証明の動画をアップロード",
  Remove: "削除",
  "Drag and drop more proof of payment":
    "さらに支払い証明をドラッグ アンド ドロップします",
  "Allowed file size less than 50 MB":
    "T許容されるファイル サイズは 50 MB 未満です",
  "Start video from your P2P order screen": "P2P注文画面からビデオを開始",
  "Clear all applications running in the background":
    "バックグラウンドで実行されているすべてのアプリケーションをクリアする",
  "Open the payment application": "支払いアプリケーションを開く",
  "Show your Name and ID clearly in the application":
    "申請書にあなたの名前とIDをはっきりと表示してください",
  "Show the transaction history": "取引履歴を表示する",
  "The following things should be clearly visible. - Name and ID of the receiver. - Date and Time of the transaction. - Amount of the transaction. - Transaction Number":
    "次のことがはっきりと見えるはずです。 - 受信者の名前と ID。 - 取引の日時。 - 取引金額。 - 取引番号",
  "Uploading...": "アップロード中...",
  "I have paid, but the seller does not release the coin":
    "私は支払いましたが、売り手はコインを解放しません",
  "Seller notified": "販売者に通知",
  "Notify Seller": "売り手に通知する",
  "Pending dispute": "係争中の紛争",
  "Raise dispute": "論争を起こす",
  "I paid too much": "払いすぎた",
  "The seller payment account is wrong, can't pay":
    "販売者の支払いアカウントが間違っているため、支払いができません",
  "I paid the wrong person": "支払う相手を間違えた",
  "I paid for the part of the money, can't continue to pay":
    "お金の一部を支払いましたが、支払いを続けることはできません",
  Pending: "保留中",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "問題が解決したことを確認し、この紛争を終了したいと思います。",

  "Upload proof": "証明をアップロード",
  "Upload proof of payment": "支払い証明書をアップロードする",
  "I confirm there is no problem and I want to cancel this transaction":
    "問題ないことを確認し、この取引をキャンセルしたい",
  warningcancel_seller:
    "この取引をキャンセルしてもよろしいですか？ 購入者がすでに支払いを済ませている場合、PolkaBridge はキャンセル後の紛争を処理しませんのでご注意ください。", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?":
    "こんにちは、どうすればあなたを助けることができますか？",
  Support: "サポート",
  "Raise Ticket": "チケットを上げる",
  "Watch Tutorial": "チュートリアルをご覧ください",
  "Video Tutorial": "ビデオチュートリアル",
  Open: "開ける",
  Official: "正式",
  "Email Us": "私達に電子メールを送り",
  Issues: "問題",
  Description: "説明",
  "Upload Proof": "プルーフをアップロードします",
  "Drag and drop the file, or": "ファイルをドラッグアンドドロップします",
  "Enter the description": "説明を入力してください",
  General: "全般的",
  "Deposit/Withdraw": "預金/撤回",
  Account: "アカウント",
  Trading: "取引",
  Others: "その他",
  Telegram: "Telegram",
  "Show Online Users": "オンライン ユーザーを表示",
  "Tokens Locked": "トークンがロックされています",
  "Payment Done": "支払い完了",
  "In Dispute": "論争中",
  Date: "日にち",
  Ascending: "上昇",
  Descending: "上昇",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "あなたのチケットは提出され、私たちのチームによってレビューされます。この問題は24時間以内に解決されます。お待ち頂きまして、ありがとうございます。",
  "Ticket History": "チケット履歴",
  Date: "日にち",
  "Ticket ID": "チケットID",
  Issue: "問題",
  Reviewing: "レビュー",
  Resolved: "解決しました",
  Pending: "保留中",
  Authenticating: "認証中",
  "Admin Remark": "管理者備考",
  Message: "メッセージ",
  "Price Ascending": "価格が上昇します",
  "Price Descending": "価格が下降します",
  "Latest Ads": "最新の広告",
  "Latest Orders": "最新の注文",
  "Completed Trades": "完了した取引",
  "User Guide": "ユーザーガイド",
  "Beginner's Guide": "初心者のガイド",
  "Video Tutorial": "ビデオチュートリアル",
  "P2P trading FAQ": "P2P取引FAQ",
  "Admin Message": "管理者メッセージ",
  "Ticket History": "チケット履歴",
  "Review Submitted Successfully": "レビューは正常に送信されました",
  "Transaction Hash": "トランザクションハッシュ",
  "Ticket Details": "チケットの詳細",
  Ended: "終了しました",
  "Round 1 Ended": "ラウンド1が終了しました",
  "Redeem your points before Round 2 starts":
    "ラウンド2が始まる前にポイントを引き換えます",
  "Redeem History": "履歴を引き換えます",
  "Redeem Time": "時間を引き換えます",
  Address: "住所",
  pending: "保留中",
  completed: "完了しました",
  rejected: "拒否された",
  "Redeemed At": "で償還された",
  "Redeem Detail": "詳細を引き換えます",
  "Transaction ID": "トランザクションID",
  "Admin Remark": "管理者の発言",
  rejected: "拒否された",
  "Profile Tab": "[プロファイル]タブ",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "[名前、ユーザーID、モバイルいいえ、電子メールなどのプロファイルをクリックして更新して、事前にフィクションの支払いオプションを追加します。",
  "Quest Tab": "クエストタブ",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "クリックして進行中のクエストキャンペーンに参加し、ポイントを請求して$ PBRリワードを取得します。",
  "LeaderBoard Tab": "リーダーボードタブ",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "クリックして、Polkabridgeトレーダーの独占ランキングをご覧ください。",
  "Buy Vs Sell": "購入対販売",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "購入オプションを選択することにより、事前にフィーフしたフィアット通貨で暗号を購入できます。",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "販売オプションを選択することで、事前にフィーフされたフィアット通貨で暗号を販売できます。",
  "Filter Tool": "フィルターツール",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "選択肢によって[広告を並べ替える]をクリックするか、フィルターを使用して表示される広告を構成します。フィルターオプションは、地域/通貨の可用性に基づいています。",
  "Advertiser Info": "広告主情報",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "ここでは、商人のプロファイル、オンラインステータス、完了率、完了した注文の総数、および商人バッジが表示されます。 Merchantの名前をクリックして、詳細情報を表示します。",
  "Price and Limits": "価格と制限",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "ここでは、ユーザーが取引を希望する1コインの価格、その可用性の量、およびユーザーが取引する最低および最高額を確認できます。",
  "Payment options": "支払いオプション",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "ここでは、広告主がトークン/フィアットと取引するために受け入れる支払いのモードを見ることができます。",
  "Lowest Fees": "最低料金",
  "Buy crypto with lowest fees ever!":
    "これまでで最も低い料金で暗号を購入してください！",
  "P2P Wallet": "P2Pウォレット",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "クリックして残高を確認し、資産とその履歴の預金と引き出しを管理します。",
  Next: "次",
  Skip: "スキップ",
  Close: "近い",
  "Create Order Tab": "[注文]タブを作成します",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "ボタンをクリックして、優先通貨/トークン/支払いモードを使用して購入/販売注文を作成します。",
  "Switch Network": "スイッチネットワーク",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "当社のP2Pプラットフォームは、マルチチャインをサポートするように設計されています。つまり、選択したチェーンで取引を行う柔軟性があります。",
  "24h Trading Volume": "24時間の取引量",
  "24h Total Orders": "24時間の合計注文",
  "Show Users Following": "ユーザーをフォローします",
  Following: "続く",
  Online: "オンライン",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "3 月 24 日午前 0 時 (UTC) までにポイントを引き換えてください。 変換するポイントの最小数は 100 ポイントです",
  "User's Statistics": "ユーザーの統計",
  Phone: "電話",
  Follower: "フォロワー",
  "All Trades": "すべての取引",
  "Total Buy Orders": "合計購入注文",
  "Total Sell Orders": "総売り注文",
  Share: "共有",
  "Reopen Ticket": "チケットを再開します",
  Comment: "コメント",
  "Add your comment": "コメントを追加します",
  "Comment must be less than 5000 characters":
    "コメントは5000文字未満でなければなりません",
  "Comment is required": "コメントが必要です",
  "Reopen Date": "日付を再開します",
  Proof: "証拠",
  "Share your P2P profile": "P2Pプロファイルを共有します",
  Sort: "選別",
  "Select Token": "トークンを選択します",
  "Select Fiat": "フィアットを選択します",
  "Date Range": "日付範囲",
  "Enter username": "ユーザーネームを入力してください",
  "Trade Currency": "貿易通貨",
  "Enter your message for seller": "売り手にメッセージを入力してください",
  "Deposit Token": "トークンをデポジットします",
  "Withdraw Token": "トークンを引き出します",
  "Insufficient!": "不十分！",
  Wait: "待って",
  "Insufficient funds!": "残高不足！",
  "Insufficient withdraw!": "不十分な撤退！",
  Approve: "承認",
  "Approving...": "承認...",
  "Issues with Deposit?": "預金の問題？",
  "All Networks": "すべてのネットワーク",
  "Enter amount": "金額を入力します",
  "Enter price": "価格を入力します",
  "Select payment methods": "支払い方法を選択します",
  "Enter order min and max limits": "注文minとmaxの制限を入力します",
  "Enter valid min limit": "有効な最小制限を入力します",
  "Max limit must be less than": "最大制限はより少ない必要があります",
  "Min limit must be less than": "最小制限は未満でなければなりません",
  "Preview order": "プレビューオーダー",
  min: "分",
  Global: "グローバル",
  "Enter your name": "あなたの名前を入力してください",
  "Choose your username": "ユーザー名を決める",
  "Enter your email": "メールアドレスを入力",
  "Your Full Name": "あなたのフルネーム",
  "A/c Number": "A/C番号",
  "Account number": "口座番号",
  "Bank Name": "銀行名",
  "IFSC Code": "ifscコード",
  "Note (Optional)": "メモ（オプション）",
  "Additional Notes": "その他の注意事項",
  "Phone Number": "電話番号",
  "Account phone number": "アカウントの電話番号",
  "Payment QR Code (Optional)": "支払いQRコード（オプション）",
  "Please enter your QR Code": "QRコードを入力してください",
  Day: "日",
  Week: "週",
  Month: "月",
  Months: "数ヶ月",
  hash: "ハッシュ",
  "Enter txn hash": "TXNハッシュを入力します",
  Success: "成功",
  Failed: "失敗した",
  Review: "レビュー",
  Dropped: "落とされた",
  LongPending: "長い",
  "Redeem Ended": "終了しました",
  "Please enter your bank account number": "銀行口座番号を入力してください",
  "Bank Name": "銀行名",
  "Enter the name of your bank": "銀行の名前を入力してください",
  "Account opening branch(Optional)": "アカウント開設ブランチ（オプション）",
  "Enter bank branch information": "銀行支店情報を入力します",
  "Paypal ID / Number": "PayPal ID /番号",
  "Perfect Money ID / Number": "完璧なお金ID /番号",
  "Bank Details (Optional)": "銀行の詳細（オプション）",
  "Please enter your bank details": "銀行の詳細を入力してください",
  "Country of Residence (Optional)": "居住国（オプション）",
  "Please enter your country of residence": "あなたの居住国を入力してください",
  "Username (Optional)": "ユーザー名（オプション）",
  "Account Username": "アカウントのユーザー名",
  "Account Email": "アカウントメール",
  "Wallet ID": "ウォレットID",
  "Revtag (Optional)": "Revtag（オプション）",
  "Please enter your Revtag": "Revtagを入力してください",
  "Phone number (Optional)": "電話番号（オプション）",
  "Please enter payment details": "支払いの詳細を入力してください",
  "Account number (USD)": "アカウント番号（USD）",
  "Account number (KHR)(Optional)": "アカウント番号（khr）（オプション）",
  "Payeer account number": "Payeerアカウント番号",
  "Enter your Payeer account number": "Payeerアカウント番号を入力してください",
  "Email (Optional)": "メール（オプション）",
  "Account Email (Not required)": "アカウントメール（不要）",
  "Email or Username": "メールアドレスまたはユーザ名",
  "Account Email or Username": "アカウントメールまたはユーザー名",
  "Full Name of Receiver": "レシーバーのフルネーム",
  "Receivers's Full Name": "レシーバーのフルネーム",
  "Payment details (Optional)": "支払いの詳細（オプション）",
  "ID Number": "ID番号",
  "Please enter your National ID Number": "全国ID番号を入力してください",
  "Account Type": "口座の種類",
  "Enter your account type": "アカウントタイプを入力します",
  "Name and Lastname": "名前とlastName",
  "Enter Name and Lastname": "名前とlastNameを入力します",
  "Full Name": "フルネーム",
  "Enter Full Name": "フルネームを入力します",
  Cashtag: "CashTag",
  "Please enter your Cashtag": "CashTagを入力してください",
  "Full Name (Optional)": "フルネーム（オプション）",
  "ID Number (Optional)": "ID番号（オプション）",
  "Address (Optional)": "アドレス（オプション）",
  "Residential Address": "住宅アドレス",
  "Mobile Number": "携帯電話番号",
  "Enter your Mobile Number available for all networks":
    "すべてのネットワークで利用可能な携帯電話番号を入力します",
  "Contact Number": "連絡先番号",
  "Phone Number": "電話番号",
  Operator: "オペレーター",
  "Mobile / Landline / Data / TV": "モバイル /固定電話 /データ /テレビ",
  "Enter account opening branch (Not required)":
    "アカウント開口ブランチを入力する（不要）",
  "IBAN Number": "IBAN番号",
  "BIC/SWIFT Code(Optional)": "BIC/SWIFTコード（オプション）",
  "BIC/SWIFT Code": "BIC/SWIFTコード",
  "Enter international bank account number":
    "国際銀行口座番号を入力してください",
  "SWIFT BIC (Optional)": "SwiftBIC（オプション）",
  "Enter your bank identification code": "銀行識別コードを入力します",
  "GB IBAN account number": "GB IBANアカウント番号",
  "IBAN (Optional)": "iban（オプション）",
  "Other payment details (for international transfer) (Optional)":
    "その他の支払い詳細（国際譲渡用）（オプション）",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "独自の住所、銀行の住所、IBANコード、Swiftbicコードなどの詳細。",
  "Bank Branch (Optional)": "銀行支店（オプション）",
  "Recommended Transfer Remarks (Optional)": "推奨転送備考（オプション）",
  "Recommended Transfer Remarks": "推奨転送備考",
  'Please enter your MoMo phone number"':
    "お母さんの電話番号を入力してください」",
  "Please enter your MoMo QR Code": "MOMO QRコードを入力してください",
  "Please enter your Zalopay QR Code": "Zalopay QRコードを入力してください",
  "Additional account number (Optional)": "追加のアカウント番号（オプション）",
  "Please enter additional account number":
    "追加のアカウント番号を入力してください",
  "Branch code (Optional)": "ブランチコード（オプション）",
  "Enter branch code (not required)": "ブランチコードを入力します（不要）",
  "IBAN (Optional)": "iban（オプション）",
  "Please enter the IBAN code of your Bank (not required)":
    "銀行のIBANコードを入力してください（不要）",
  "IBAN or account number": "IBANまたはアカウント番号",
  "Please enter your IBAN or account number":
    "IBANまたはアカウント番号を入力してください",
  "Papara account number": "パパラアカウント番号",
  "Please enter your Papara account number":
    "Paparaアカウント番号を入力してください",
  City: "街",
  "Please enter your city": "あなたの街に入ってください",
  "Zip Code": "郵便番号",
  "Please enter your city zipcode": "City Zipcodeを入力してください",
  Country: "国",
  "Please enter Country": "入国してください",
  "Moneygram Account Number": "MoneyGramアカウント番号",
  "Please enter your Moneygram account number":
    "MoneyGramアカウント番号を入力してください",
  "Enter bank branch information": "銀行支店情報を入力します",
  "Please enter your BCA account number": "BCAアカウント番号を入力してください",
  "Please enter your Mandiri account number":
    "Mandiriアカウント番号を入力してください",
  "Chipper Cash full name": "チッパーキャッシュフルネーム",
  "Please enter your full name used to register on chipper cash":
    "チッパーキャッシュに登録するために使用されるフルネームを入力してください",
  "Chipper Tag": "チッパータグ",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "@chippertagを入力してください。 Chipper Cashの[プロフィール]タブで見つけることができます",
  "QIWI account": "QIWIアカウント",
  "Please enter your QIWI account": "QIWIアカウントを入力してください",
  "Your email registered on Binance":
    "あなたの電子メールはBinanceで登録されました",
  "Please enter your email registered on Binance":
    "Binanceで登録されているメールを入力してください",
  "Payment Details": "支払詳細",
  "WeChat account": "WeChatアカウント",
  "Please enter your WeChat account": "WeChatアカウントを入力してください",
  "Receive QR Code (Optional)": "QRコードを受信する（オプション）",
  "Alipay account": "Alipayアカウント",
  "Please enter your Alipay account": "Alipayアカウントを入力してください",
  "Payee's LINE ID": "受取人のラインID",
  "Please fill in your LINE ID": "ラインIDを入力してください",
  "Please fill in your phone number registered with LINE":
    "ラインで登録されている電話番号に記入してください",
  "Linepay QR Code (Optional)": "LinePay QRコード（オプション）",
  "Reference number or barcode": "参照番号またはバーコード",
  "Please enter reference number or barcode":
    "参照番号またはバーコードを入力してください",
  "Remarks (Optional)": "発言（オプション）",
  "Additional Remarks": "追加の発言",
  "Cell Phone Number": "携帯番号",
  "Please enter your cell phone number": "携帯電話番号を入力してください",
  "Enter your ID number": "ID番号を入力します",
  "Fill in your phone number": "電話番号を入力してください",
  "Full Name of Receiver": "レシーバーのフルネーム",
  "Please enter your Full Name": "フルネームを入力してください",
  CBU: "CBU",
  "Please enter your CBU": "CBUを入力してください",
  "CUIT / CUIL": "CUIT / CUIL",
  "Enter your unique tax identification code":
    "一意の税識別コードを入力してください",
  "Please enter your CVU": "CVUを入力してください",
  Alias: "エイリアス",
  "Please enter Alias": "エイリアスを入力してください",
  Lemontag: "lemontag",
  "Lemon Wallet Tag": "レモンウォレットタグ",
  "Uniform Bank Key": "ユニフォームバンクキー",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "DNI/CPF番号を入力します",
  "CVU (Optional)": "CVU（オプション）",
  "Uniform Virtual Key": "均一な仮想キー",
  "CBU/CVU or": "CBU/CVUまたは",
  "alias CVU/CBU or CVU/CBU number": "エイリアスCVU/CBUまたはCVU/CBU番号",
  "Alias del CBU": "エイリアスデルCBU",
  "Vodafone cash Number": "Vodafoneの現金番号",
  "Please enter your vodafone cash Number":
    "Vodafoneの現金番号を入力してください",
  "Sort code": "ソートコード",
  "Please fill in your 6-digit sort code":
    "6桁のソートコードを入力してください",
  "Sort Code(Optional)": "ソートコード（オプション）",
  "GB IBAN account number": "GB IBANアカウント番号",
  "IIN (Optional)": "iin（オプション）",
  "Enter individual identification number": "個別の識別番号を入力します",
  "Please enter your bank card number": "銀行カード番号を入力してください",
  "Mobile Carrier": "モバイルキャリア",
  "Please enter your mobile carrier": "モバイルキャリアを入力してください",
  "Easypaisa mobile account": "Easypaisaモバイルアカウント",
  "Please enter your Easypaisa mobile account":
    "Easypaisaモバイルアカウントを入力してください",
  "NayaPay Id": "Nayapay ID",
  "Please enter NayaPay Id": "Nayapay IDを入力してください",
  "Enter Brazilian valid document":
    "ブラジルの有効なドキュメントを入力してください",
  "Bank agency": "銀行代理店",
  "Enter the name of your bank agency": "銀行代理店の名前を入力してください",
  "Type of transfer": "転送の種類",
  "Please filled with TED/DOC/PIX": "Ted/doc/pixで満たしてください",
  "Name and Last Name": "名前と姓",
  "Pix Key": "pixキー",
  "Phone, email or key": "電話、電子メール、またはキー",
  'Type of Bank"': "銀行の種類」",
  "Name registered in PicPay": "Picpayに登録された名前",
  "PicPay username": "PicPayユーザー名",
  "Username used to log in in PicPay":
    "PicPayでログインするために使用されるユーザー名",
  "urpay Account Details": "urpayアカウントの詳細",
  "Please enter your urpay account details":
    "urpayアカウントの詳細を入力してください",
  "bKash Wallet Number": "bkashウォレット番号",
  "Please enter your 11 digit registered bKash account number":
    "11桁の登録BKASHアカウント番号を入力してください",
  "Nagad Wallet Number": "ナガドウォレット番号",
  "Please enter your 11 digit registered Nagad wallet number":
    "11桁の登録されたNagadウォレット番号を入力してください",
  "Please enter your full name registered with Rocket account":
    "Rocketアカウントに登録されているフルネームを入力してください",
  "Rocket Wallet Number": "ロケットウォレット番号",
  "Please enter your 12 digit Rocket Wallet number":
    "12桁のロケットウォレット番号を入力してください",
  "Please enter your JKOPAY ID": "jkopay IDを入力してください",
  "EasyPay wallet number (Optional)": "Easypayウォレット番号（オプション）",
  "Please fill in EasyPay wallet number":
    "Easypayウォレット番号に記入してください",
  "User API Key": "ユーザーAPIキー",
  "User Secret Key": "ユーザーシークレットキー",
  "Bank account number": "銀行の口座番号",
  PayID: "Payid",
  "Please enter your PayID": "PayIDを入力してください",
  "Institution number": "機関番号",
  "Please enter institution number": "機関番号を入力してください",
  "PayCode (Optional)": "PayCode（オプション）",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "私のアカウントにあります - >私のpaycode->リンクをコピーします",
  "Please fill in your mobile phone number connected with your PayMe":
    "Paymeに接続された携帯電話番号に記入してください",
  Cellphone: "携帯電話",
  "Card Number or password": "カード番号またはパスワード",
  "Please fill in card Number or password":
    "カード番号またはパスワードを入力してください",
  "Purpose of payment (Optional)": "支払い目的（オプション）",
  "Enter a payment purpose for buyers": "バイヤーの支払い目的を入力します",
  "Card number, account or key": "カード番号、アカウント、またはキー",
  "CLABE number (Optional)": "クレイブ番号（オプション）",
  "CLABE number": "クレイブ番号",
  "Account number/card": "アカウント番号/カード",
  "Reference number or card number": "参照番号またはカード番号",
  "Please enter reference number or card number":
    "参照番号またはカード番号を入力してください",
  "Provide transfer details during the trade": "取引中に転送の詳細を提供します",
  "Variable symbol (Optional)": "可変シンボル（オプション）",
  "Enter Variable symbol": "可変シンボルを入力します",
  "Enter your PayNow account": "PayNowアカウントを入力してください",
  "Account Name": "アカウント名",
  "Email Address": "電子メールアドレス",
  "Please enter email address": "メールアドレスを入力してください",
  "Please enter Your Full Name": "フルネームを入力してください",
  "Account Number": "口座番号",
  "Enter your phone number": "電話番号を入力してください",
  "Enter your account number": "アカウント番号を入力してください",
  "Enter your bank account number": "銀行口座番号を入力します",
  "Payment details": "支払詳細",
  "Bank name": "銀行名",
  "Please enter your MoMo phone number": "お母さんの電話番号を入力してください",
  "Please enter your email": "あなたのメールアドレスを入力してください",
  "Enter branch code": "ブランチコードを入力します",
  "Please enter the IBAN code of your Bank":
    "銀行のIBANコードを入力してください",
  "Account opening branch (Optional)": "アカウント開設ブランチ（オプション）",
  "Enter account opening branch (not required)":
    "アカウント開口ブランチを入力する（不要）",
  "Please enter your Bank account number": "銀行口座番号を入力してください",
  "Please enter your ID Number": "ID番号を入力してください",
  "Enter bank account type": "銀行口座タイプを入力します",
  "Bank Branch (Optional)": "銀行支店（オプション）",
  "Account type": "口座の種類",
  "Please enter bank branch information": "銀行支店情報を入力してください",
  "Please enter Email address": "メールアドレスを入力してください",
  "Phone number(Optional)": "電話番号（オプション）",
  "Phone Number (Optional)": "電話番号（オプション）",
  "Additional information": "追加情報",
  "Type of Bank": "銀行の種類",
  "Remark (Optional)": "注：オプション）",
  "Mobile Phone": "携帯電話",
  "Enter the bank code": "銀行コードを入力します",
  "Please enter your phone number": "電話番号を入力してください",
  "Please enter your Bank name": "銀行名を入力してください",
};

export default JAPANESE;
