import {
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  CREATE_ADMIN,
  GET_ADMINS,
  SET_ADMIN_AUTH_ERROR,
  SET_ADMIN_AUTH_LOADING,
} from "actions/types";

const initalState = {
  jwtToken: null,
  email: null,
  id: null,
  authenticated: false,
  loading: false,
  error: null,
  admins: [],
};

export default function adminAuthReducer(state = initalState, action) {
  switch (action.type) {
    case ADMIN_LOGIN:
      return {
        ...state,
        jwtToken: action?.payload?.jwtToken,
        email: action?.payload?.email,
        id: action?.payload?.id,
        role: action?.payload?.role,
        authenticated: true,
        loading: false,
        error: null,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        ...initalState,
      };
    case SET_ADMIN_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ADMIN_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ADMINS:
      return {
        ...state,
        admins: action.payload,
        error: null,
        loading: false,
      };
    case CREATE_ADMIN:
      return {
        ...state,
        admins: [action.payload, ...state.admins],
        error: null,
        loading: false,
      };

    default:
      return state;
  }
}
