import { Connector } from "@web3-react/types";
import { walletConnectConnection } from "../constants/connectionConstants";

import {
  getChainInfo,
  isSupportedChain,
  NETWORK_DETAILS,
  SupportedChainId,
} from "../constants/chains";
import { RPC_URLS } from "./infura";

function getRpcUrl(chainId: SupportedChainId): string {
  switch (chainId) {
    case SupportedChainId.MAINNET:
    case SupportedChainId.RINKEBY:
    case SupportedChainId.ROPSTEN:
    case SupportedChainId.BSC_TESTNET:
    case SupportedChainId.BSC:
    case SupportedChainId.GOERLI:
      return RPC_URLS[chainId][0];
    // Attempting to add a chain using an infura URL will not work, as the URL will be unreachable from the MetaMask background page.
    // MetaMask allows switching to any publicly reachable URL, but for novel chains, it will display a warning if it is not on the "Safe" list.
    // See the definition of FALLBACK_URLS for more details.
    default:
      return RPC_URLS[chainId][0];
  }
}

function getNetworkObjectForSwitch(chainId: Number) {
  switch (chainId) {
    case 1:
      return NETWORK_DETAILS.MAINNET;
    case 5:
      return NETWORK_DETAILS.GOERLI;
    case 97:
      return NETWORK_DETAILS.BSC_TESTNET;
    case 56:
      return NETWORK_DETAILS.BSC;
    default:
      return NETWORK_DETAILS.MAINNET;
  }
}

export const switchChain = async (
  connector: Connector,
  chainId: SupportedChainId
) => {
  if (!isSupportedChain(chainId)) {
    throw new Error(
      `Chain ${chainId} not supported for connector (${typeof connector})`
    );
  } else if (connector === walletConnectConnection.connector) {
    // console.log("switch test activating for wallet connect");
    await connector.activate(chainId);
  } else {
    // console.log("switch test activating for metamask");
    const info = getChainInfo(chainId);
    const addChainParameter = {
      chainId,
      chainName: info.label,
      rpcUrls: [getRpcUrl(chainId)],
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
    };

    try {
      await connector.activate(addChainParameter);
    } catch (error) {
      // console.log(
      //   "switch test activating : activation failed trying other ways to activate"
      // );

      let networkObject = getNetworkObjectForSwitch(chainId);
      // console.log("switch test network object ", networkObject);

      if ([56, 97].includes(chainId)) {
        // console.log("switch test trying add network");

        await connector?.provider?.request({
          method: "wallet_addEthereumChain",
          params: [networkObject],
        });
      } else {
        await connector?.provider?.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networkObject.chainId }],
        });
      }

      return;
    }
  }
};
