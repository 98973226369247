import axios from "axios";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_ERROR,
  AUTH_LOADING,
  LOAD_GUEST_USER,
  LOAD_USER,
  SIGNATURE_REQ_INIT,
  UPDATE_AUTH_STATE,
} from "../actions/types";
import {
  AUTHENTICATION_STATE,
  CONNECTOR_TYPE,
  DAPP_SUPPORTED_ON_CHAINS,
} from "../constants";
import { BASE_API_ENDPOINT, getGuestUser, getUser } from "../utils/httpCalls";
import useActiveWeb3React from "./useActiveWeb3React";
import { connect } from "./useEagerlyConnect";
import {
  injectedConnection,
  walletConnectConnection,
} from "../constants/connectionConstants";
import { getLocalCurrency, getLocation } from "../utils/helper";
import { updateLastLogin } from "../actions/profileActions";
import { useLocation } from "react-router-dom";

export function useUserAuthentication(initHook = false): {
  connectWallet: () => {};
  logout: () => void;
  verifyUserWallet: () => {};
} {
  const { chainId, isActive, account, connector, provider } =
    useActiveWeb3React();
  const userSelectedChain = useSelector((state: any) => state?.user?.chainId);
  const authenticated = useSelector((state: any) => state?.user?.authenticated);
  const location = useLocation();

  const dispatch = useDispatch();

  const connectWallet = useCallback(
    async (connectorType?: string | null) => {
      try {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.CONNECTING_WALLET,
        });
        // let connector;
        if (connectorType === CONNECTOR_TYPE.injected) {
          await connect(injectedConnection.connector);
          localStorage.connectorType = CONNECTOR_TYPE.injected;
        } else if (connectorType === CONNECTOR_TYPE.walletConnect) {
          await connect(walletConnectConnection.connector);
          localStorage.connectorType = CONNECTOR_TYPE.walletConnect;
        } else if (connectorType === CONNECTOR_TYPE.unstoppable) {
          localStorage.connectorType = CONNECTOR_TYPE.unstoppable;
        } else {
          await connect(injectedConnection.connector);
          localStorage.connectorType = CONNECTOR_TYPE.injected;
        }

        localStorage.log_out = undefined;
      } catch (error) {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.NOT_STARTED,
        });
        dispatch({
          type: AUTH_ERROR,
          payload: "Something went wrong while connecting with wallet",
        });
        console.log("wallet connection error", { error });
      }
    },
    [dispatch]
  );

  const verifyUserWallet = useCallback(async () => {
    dispatch({ type: AUTH_LOADING });
    dispatch({ type: SIGNATURE_REQ_INIT });
    try {
      if (
        !isActive ||
        !account ||
        !(chainId && DAPP_SUPPORTED_ON_CHAINS.includes(chainId))
      ) {
        setupGuestUser();
        return;
      }

      if (userSelectedChain !== chainId) {
        setupGuestUser();
        return;
      }
      dispatch({
        type: UPDATE_AUTH_STATE,
        payload: AUTHENTICATION_STATE.REQUESTING_SIGNATURE,
      });

      // check cached user status logged in before
      const user: any = await getUser(account, chainId, undefined);
      if (user?.status === 200 && user?.data?.wallet_address === account) {
        //update login data
        const data = {
          last_login: new Date(),
          email: user?.data?.email,
          phone: user?.data?.phone,
          location: await getLocation(),
        };

        dispatch(
          updateLastLogin(
            data,
            account,
            localStorage.getItem(`${account}_${chainId}`)
          )
        );

        dispatch({
          type: LOAD_USER,
          payload: {
            jwtToken: localStorage.getItem(`${account}_${chainId}`),
            account,
            id: user?.data?._id,
            authenticated: true,
            signature: localStorage.getItem(`signature_${account}`),
            userFiat: user?.data?.fiat?.fiat,
            tour_done: user?.data?.tour_done,
          },
        });
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.AUTHENTICATION_SUCCESS,
        });
        return;
      }

      const messageToSign = process.env.REACT_APP_MESSAGE_TO_SIGN;
      if (!messageToSign) {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.SIGNATURE_VALIDATION_FAILED,
        });
        setupGuestUser();
        dispatch({
          type: AUTH_ERROR,
          payload: "Invalid message to sign!",
        });
        return;
      }

      // todo: fix and update nonce
      const nonce = 123;

      const messageHashRes = await axios.get(
        `${BASE_API_ENDPOINT}/auth-apis/v1/getMessageHash/${chainId}/${account}/${nonce}}`
      );

      if (messageHashRes.status !== 200) {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.SIGNATURE_VALIDATION_FAILED,
        });
        setupGuestUser();
        dispatch({
          type: AUTH_ERROR,
          payload: "Failed to fetch message hash!",
        });
        return;
      }

      const messageHash = messageHashRes.data?.hash;

      if (!messageHash || !account) {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.SIGNATURE_VALIDATION_FAILED,
        });
        setupGuestUser();
        dispatch({
          type: AUTH_ERROR,
          payload: "Failed to generate message hash!",
        });
        return;
      }

      const signature = await provider
        ?.getSigner(0)
        .signMessage(`Ethereum Signed Message:${messageHash}`);

      if (!signature) {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.SIGNATURE_VALIDATION_FAILED,
        });
        setupGuestUser();
        dispatch({
          type: AUTH_ERROR,
          payload: "Failed to sign signature from message hash!",
        });
        return;
      }
      localStorage.setItem(`signature_${account}`, signature);
      //get local currency when user login
      const localFiat = await getLocalCurrency(account);
      const location = await getLocation(account);

      const verifyObject = {
        nonce: nonce,
        account: account,
        signature,
        chainId: chainId,
        fiat: localFiat,
        location: location ? location : null,
      };

      const verify = await axios.post(
        `${BASE_API_ENDPOINT}/auth-apis/v1/signatureVerify`,
        verifyObject
      );

      // verify user wallet from server and authenticate
      if (verify?.data?.verified === true) {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.AUTHENTICATION_SUCCESS,
        });
        const user: any = await getUser(
          account,
          chainId,
          verify?.data?.jwtToken
        );

        dispatch({
          type: LOAD_USER,
          payload: {
            jwtToken: verify?.data?.jwtToken,
            account,
            id: user?.data?._id,
            authenticated: true,
            signature: signature,
            userFiat: user?.data?.fiat?.fiat,
            tour_done: user?.data?.tour_done,
          },
        });
        localStorage.setItem(`${account}_${chainId}`, verify?.data?.jwtToken);
        localStorage.setItem(`signature_${account}`, signature);
        localStorage.setItem(`active_account`, "yes");
      } else {
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.SIGNATURE_VALIDATION_FAILED,
        });
        setupGuestUser();
        dispatch({
          type: AUTH_ERROR,
          payload: "Failed to sign signature from message hash!",
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_AUTH_STATE,
        payload: AUTHENTICATION_STATE.SIGNATURE_REQUEST_FAILED,
      });
      setupGuestUser();
      //check if error because user is blacklisted
      const isBlacklisted = error?.response?.data?.errors?.[0]?.blacklisted;
      dispatch({
        type: AUTH_ERROR,
        payload: isBlacklisted
          ? error?.response?.data?.errors?.[0]
          : "Signature verification failed!",
      });

      console.log("signed message error ", error);
    }
  }, [dispatch, account, isActive, chainId, provider, userSelectedChain]);

  const setupGuestUser = async () => {
    try {
      const res: any = await getGuestUser();
      dispatch({
        type: LOAD_GUEST_USER,
        payload: {
          jwtToken: res?.data?.jwtToken,
          account: null,
          id: null,
          authenticated: false,
        },
      });
    } catch (error) {
      console.log("guest user error", error);
      dispatch({
        type: AUTH_ERROR,
        payload: "Failed to setup guest user! please refresh and try again",
      });
    }
  };

  function removeWalletConnectFromLocalStorage() {
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
  }

  const logout = useCallback(async () => {
    if (!connector || !chainId || !account) {
      return;
    }

    dispatch({ type: AUTH_LOADING });

    if (connector.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    dispatch({
      type: UPDATE_AUTH_STATE,
      payload: AUTHENTICATION_STATE.NOT_STARTED,
    });

    localStorage.log_out = account;
    localStorage.removeItem("connectorType");
    localStorage.removeItem(`${account}_${chainId}`);
    localStorage.removeItem(`active_account`);
    localStorage.removeItem(`signature_${account}`);
    removeWalletConnectFromLocalStorage();
    await setupGuestUser();
  }, [dispatch, account, connector, chainId]);

  useEffect(() => {
    if (!initHook) {
      return;
    }
    if (location?.pathname?.includes("admin")) {
      // skip wallet connection flow if it is  admin page
      return;
    }

    if (authenticated && isActive) {
      return;
    }

    if (!account && localStorage.log_out !== "undefined") {
      setupGuestUser();
      return;
    }

    if (isActive) {
      verifyUserWallet();
    }

    if (
      !isActive &&
      localStorage.log_out !== account &&
      localStorage.getItem(`active_account`)
    ) {
      setupGuestUser();
      connectWallet(localStorage.connectorType);
      //:toto fix init wallet connection
      return;
    }
  }, [isActive, account, location.pathname, authenticated]);

  return {
    connectWallet: connectWallet,
    logout: logout,
    verifyUserWallet: verifyUserWallet,
  };
}
