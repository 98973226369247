import axios from "axios";
import { globalHeaders } from "./index";
import { BASE_API_ENDPOINT } from "./index";

export const depositFee = async (account, authToken) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/kafka/v1/deposit-fee/`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error) {
    console.log("depositFee ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

/**
 * @example
 * {txnHash:"abc",
 * time:"100294322",in unix
 * note:""
 * }
 */
export const depositTxn = async (
  account,
  chainId,
  tokenId,
  vaultId,
  trxHash,
  tokenAmount,
  authToken,
  startedAt
) => {
  try {
    const body = {
      vaultId,
      tokenId,
      trxHash,
      tokenAmount,
      startedAt,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/kafka/v1/deposit/`,
      body,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error) {
    console.log("depositTxn ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

/**
 * @description send the txn to be pushed to queue
 */
export const withdrawTxn = async (requestBody, account, authToken) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/kafka/v1/withdraw/`,
      requestBody,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(
                `${requestBody.account}_${requestBody.chainId}`
              ),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error) {
    console.log("wallet test updateDepositCall error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
/**
 * @description get transaction history of user
 */
export const getUserHistory = async (
  pageNumber,
  params,
  account,
  authToken
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/kafka/v1/history/${pageNumber || 1}`,
      {
        params: params,
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );
    return { status: result?.status, data: result?.data };
  } catch (error) {
    console.log("getHistory ", { error, params });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
/**
 * @description get transaction history of user
 */
export const getLatestWithdraw = async (account, authToken) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/kafka/v1/latest-withdraw/`,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );
    return { status: result?.status, data: result?.data };
  } catch (error) {
    console.log("getLatestWithdraw ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
