import {
  getVaultBalance,
  getVaultBalances,
  updateDepositCall,
  withdrawQuoteCall,
} from "../utils/httpCalls/walletCalls";
import { depositTxn, getLatestWithdraw } from "../utils/httpCalls/txnCall";

import {
  WALLET_ERROR,
  FETCH_TOKEN_BALANCES,
  WALLET_LOADING,
  FETCH_TOKEN_BALANCE,
  GET_WITHDRAW_QUOTE,
  GET_LATEST_WITHDRAW,
} from "./types";

export const fetchBalances =
  (account, chainId, authToken) => async (dispatch) => {
    if (!account || !chainId || !authToken) {
      return;
    }

    dispatch({ type: WALLET_LOADING });

    const result = await getVaultBalances(account, chainId, authToken);

    if (result?.status !== 200) {
      dispatch({
        type: WALLET_ERROR,
        payload: result.message,
      });
      return;
    }

    dispatch({
      type: FETCH_TOKEN_BALANCES,
      payload: result.data,
    });
  };

export const fetchBalance =
  (account, chainId, vaultId, authToken) => async (dispatch) => {
    if (!account || !chainId || !vaultId || !authToken) {
      return;
    }
    dispatch({ type: WALLET_LOADING });

    const result = await getVaultBalance(account, chainId, vaultId, authToken);

    if (result?.status !== 200) {
      dispatch({
        type: WALLET_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: FETCH_TOKEN_BALANCE,
      payload: result.data,
    });
  };

export const recordDepositHash =
  (account, chainId, tokenId, vaultId, trxHash, tokenAmount, authToken) =>
  async (dispatch) => {
    if (!account) {
      return;
    }
    const result = await depositTxn(
      account,
      chainId,
      tokenId,
      vaultId,
      trxHash,
      tokenAmount,
      authToken,
      Date.now()
    );
    if (result?.status !== 201) {
      dispatch({
        type: WALLET_ERROR,
        payload: result.message,
      });
      return;
    }
    //todo: update status
  };

export const updateDeposit =
  (account, chainId, tokenId, vaultId, trxHash, authToken) =>
  async (dispatch) => {
    if (
      !account ||
      !chainId ||
      !tokenId ||
      !vaultId ||
      !trxHash ||
      !authToken
    ) {
      return;
    }

    dispatch({ type: WALLET_LOADING });
    console.log("updating deposit ", {
      account,
      chainId,
      tokenId,
      vaultId,
      trxHash,
      authToken,
    });
    const result = await updateDepositCall(
      account,
      chainId,
      tokenId,
      vaultId,
      trxHash,
      authToken
    );

    if (result?.status !== 201) {
      dispatch({
        type: WALLET_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: FETCH_TOKEN_BALANCE,
      payload: result.data,
    });
  };

export const latestWithdraw = (account, authToken) => async (dispatch) => {
  if (!authToken) {
    return;
  }
  dispatch({ type: WALLET_LOADING });
  const result = await getLatestWithdraw(account, authToken);

  if (result?.status !== 200) {
    dispatch({
      type: WALLET_ERROR,
      payload: result.message,
    });
    return;
  }
  dispatch({
    type: GET_LATEST_WITHDRAW,
    payload: result.data.txnDoc,
  });
};

export const withdrawQuote =
  (account, chainId, tokenId, tokenAmount, authToken) => async (dispatch) => {
    if (!account) {
      return;
    }
    dispatch({ type: WALLET_LOADING });
    const result = await withdrawQuoteCall(
      account,
      chainId,
      tokenId,
      tokenAmount,
      authToken
    );

    if (result?.status !== 200) {
      dispatch({
        type: WALLET_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_WITHDRAW_QUOTE,
      payload: result.data,
    });
  };
