const VIETNAMESE = {
  Mode: "Chế độ",
  Feedback: "Gửi phản hồi",
  Home: "Trang chủ",
  "Create Order": "Tạo quảng cáo",
  "My Orders": "Lệnh của tôi",
  Wallet: "Ví",
  Profile: "Hồ sơ",
  Leaderboard: "Bảng xếp hạng",
  Faucet: "Faucet",
  Banned: "Bị cấm",
  "This account has been banned. If this is unexpected, please contact":
    "Tài khoản này đã bị cấm. Nếu bạn có thắc mắc, vui lòng liên hệ",
  footermessage:
    "Tham gia cộng đồng PolkaBridge với hơn 100k+ người dùng để nhận nhiều phần thưởng hấp dẫn",
  "Copyright 2023 By": "Bản quyền 2023 bởi",
  "All Rights Reserved": "Đã đăng ký Bản quyền",
  "How P2P Works": "Cách thức hoạt động của P2P",
  home_howitwork:
    "Polkabridge P2P giúp bạn chuyển đổi tiền pháp định của bạn sang tiền điện tử ngay lập tức, nơi Polkabridge hoạt động như một ký quỹ để giữ an toàn giao dịch.",
  Buy: "Mua",
  Sell: "Bán",
  "STEP 1": "BƯỚC 1",
  buy_step1:
    "Chọn quảng cáo với giá và phương thức thanh toán mong muốn. Nhập số lượng mua và số tiền giao dịch của bạn để hoàn thành lệnh mua hoặc bán.",
  "Deposit tokens into": "Nạp tiền vào",
  wallet: "Ví",
  "and create orders": "và tạo quảng cáo",
  "STEP 2": "BƯỚC 2",
  buy_step2:
    "Hoàn thành các khoản thanh toán của bạn dựa trên thông tin được cung cấp bởi người bán.",
  step2_sell:
    "Xác nhận rằng bạn đã nhận được các khoản thanh toán đầy đủ từ người mua.",
  "STEP 3": "BƯỚC 3",
  step31_buy:
    "Sau khi người bán nhận được khoản thanh toán, anh ấy sẽ release token cho bạn. Đi đến",
  step32_buy: "Để xem tokens bạn nhận được.",
  step3_sell:
    "Chỉ release tokens sau  khi bạn nhận được khoản thanh toán từ người mua.",
  "Before you start trading you need to update your profile.":
    "Trước khi bắt đầu giao dịch, bạn cần cập nhật hồ sơ của mình.",
  "Before your start trading please verify your phone":
    "Trước khi bạn bắt đầu giao dịch, vui lòng xác minh điện thoại của bạn",
  "Please add a payment method to start buy crypto":
    "Vui lòng thêm phương thức thanh toán để bắt đầu Mua tiền điện tử",
  "Update now": "Cập nhật",
  "Verify now": "Xác minh",
  "Add now": "Thêm",
  Logout: "Đăng xuất",
  "Switching...": "Đang chuyển ...",
  Switch: "Chuyển",
  "Switch Network": "Chuyển mạng",
  "Cancel dispute": "Hủy tranh chấp",
  canceldisputewarn:
    "Lưu ý rằng Bạn sẽ không thể mở tranh chấp được nữa sau khi bạn hủy. Vui lòng KHÔNG HỦY nếu vấn đề vẫn chưa được xử lý xong.",
  "I confirm I have recieved the money from seller":
    "Tôi xác nhận tôi đã nhận được tiền từ người bán",
  "Cancelling...": "Đang hủy ...",
  "Cancel Trade": "Hủy giao dịch",
  Warning: "Cảnh báo",
  warningcancel:
    "Sẽ không có khoản hoàn trả sau khi hủy lệnh. Vui lòng không bám hủy nếu bạn đã chuyển tiền cho người bán.",
  tradewarning:
    "Giao dịch sẽ bị đình chỉ trong ngày nếu một lần hủy 4 giao dịch trong một ngày.",
  "I confirm I have not paid the payee.":
    "Tôi xác nhận tôi chưa thực hiện thanh toán.",
  "Please wait...": "Vui lòng chờ...",
  "Cancel this trade": "Hủy giao dịch này",
  "Confirm Transfer": "Xác nhận",
  confirmnote:
    "Các giao dịch P2P yêu cầu bạn thực hiện thanh toán hoặc chuyển giao theo phương thức được hỗ trợ bởi bên nhận. Polkabridge không tự động khấu trừ từ tài khoản của bạn.",
  "Transfer Amount": "Số lượng Token cần chuyển",
  "In exchange for": "Đổi với số tiền",
  "Upload proof of payment": "Tải bằng chứng thanh toán",
  "Confirm the account of the receiving party":
    "Xác nhận tài khoản của người bán",
  "Payment QR code": "Mã QR thanh toán",
  confirmnote2:
    "Vui lòng hoàn thành đơn đặt hàng của bạn càng sớm càng tốt để bên nhận có thể xác nhận lệnh của bạn. Vui lòng chọn tài khoản của bên nhận. Vui lòng không gian lận, nếu không tài khoản của bạn sẽ bị khóa",
  "I didn't Pay Yet": "Tôi vẫn chưa thanh toán",
  "Transfer Completed": "Xác nhận",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "Nạp tokens vào Polkabridge Vault và giao dịch miễn phí",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Rút mã thông báo từ Polkabridge Vault với phí",
  Amount: "Số lượng",
  "Select Max": "Chọn tối đa",
  Balance: "Số dư",
  "Available Amount": "Số tiền có sẵn",
  "Withdrawal Fee": "Phí rút tiền",
  "In order": "Khóa",
  View: "Xem",
  "Send OTP": "Gửi OTP",
  "Verify Phone": "Xác minh số điện thoại",
  "Verify Email": "Xác minh Email",
  "Your phone number has been verified":
    "Số điện thoại của bạn đã được xác minh",
  "Your email address has been verified":
    "Địa chỉ email của bạn đã được xác minh",
  "6 digit otp has been sent on your phone":
    "6 chữ số OTP đã được gửi về điện thoại của bạn",
  emailsent:
    "Mã xác minh 6 chữ số được gửi về email của bạn. Nếu bạn không thể thấy email xác minh trong hộp thư đến của mình, hãy đảm bảo kiểm tra thư mục spam hoặc thùng rác",
  "Verify OTP": "Xác minh OTP",
  "OTP expired": "OTP đã hết hạn",
  "Resend OTP": "Gửi lại OTP",
  "Your OTP will expire in": "OTP của bạn sẽ hết hạn trong vòng",
  "Confirm and Release": "Xác nhận và giải phóng",
  Tips: "Khuyến nghị",
  dontnotcheckbuyerpayment:
    "Đừng chỉ kiểm tra bằng chứng thanh toán của người mua. Hãy đảm bảo rằng bạn đã đăng nhập vào tài khoản của mình và xác nhận rằng bạn đã nhận được tiền thanh toán từ người mua vào tài khoản.",
  ifstillprocessing:
    "Nếu thanh toán vẫn đang xử lý, hãy đợi cho đến khi bạn nhận được tiền vào tài khoản của mình trước khi phát hành tokens !",
  donotacceptthridparty:
    "Không bao giờ chấp nhận thanh toán từ tài khoản của bên thứ ba. Nếu bạn nhận được thanh toán từ bên thứ ba, hãy hoàn trả nó để tránh mất tiền khi xảy ra tranh chấp sau này.",
  "Confirm User Account and Release Amount": "Xác nhận thanh toán",
  iconfirmpaymentcorrect:
    "Tôi xác nhận là tôi đã nhận được tiền vào tài khoản và số dư khả dụng là chính xác.",
  "Releasing...": "Phát hành ...",
  Order: "Lệnh",
  "is successfully completed": "đã hoàn thành",
  Submitted: "Đã Gửi",
  Submit: "Gửi",
  "Click below for processing": "Nhấp vào bên dưới để xử lý",
  "Waiting for confirmation": " Vui lòng chờ đợi xác nhận",
  "Please confirm the transaction into your metamask popup":
    "Vui lòng xác nhận giao dịch từ ví của bạn",
  "Withdrawal Request Submitted": "Yêu cầu rút tiền đã được gửi đi",
  "Withdrawal Address": "Địa chỉ rút tiền",
  Back: "Quay về",
  "Withdrawal History": "Lịch sử rút tiền",
  "Deposit Processing": "Giao dịch nạp tiền đang được xử lý",
  depositapproval:
    "Vui lòng kiên nhẫn chờ đợi hệ thống xử lý lệnh nạp tiền của bạn. Nó sẽ không mất quá 3 phút để tokens vào tài khoản của bạn",
  "Deposits History": "Lịch sử nạp tiền",
  "Transaction Submitted": "Đã gửi yêu cầu",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "Lệnh nạp tiền của bạn đang được xử lý. Vui lòng chờ đợi trong giây lát.",
  "View Status": "Xem",
  "Transaction Successful!": "Giao dịch thành công!",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "Tuyệt vời! Giao dịch đã được xác nhận tại blockchain thành công.",
  "Transaction Failed!": "Giao dịch không thành công!",
  "We have encountered an error in the transaction. Please try again.":
    "Chúng tôi đã gặp phải một lỗi trong giao dịch. Vui lòng thử lại.",
  "Sign Message": "Kết nối ví",
  "Connect Wallet": "Kết nối ví",
  "Waiting to connect": "Chờ kết nối",
  "Confirm this connection in your wallet": "Xác nhận kết nối với ví của bạn",
  walletconnect_rule:
    "Bằng cách kết nối ví, bạn đồng ý với các điều khoản dịch vụ của Polkabridge P2P và đồng ý với chính sách quyền riêng tư của mình.",
  "Please sign this message requested in your metamask": "Vui lòng kết nối ví",
  Metamask: "Metamask",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "Phiên bản Testnet tiếp theo sắp tới!",
  "Follow Us": "Theo dõi chúng tôi",
  "Claim Faucet": "Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Yêu cầu test token cho Goerli Testnet hoặc BSC Testnet.",
  "Unauthorised Access!": "Không có quyền truy cập!",
  "Please connect your wallet first to continue P2P trading.":
    "Vui lòng kết nối với ví của bạn trước để tiếp tục giao dịch P2P.",
  Price: "Đơn Giá",
  Limit: "Giới hạn",
  Following: "Đang theo dõi",
  Unfollow: "Hủy theo dõi",
  Follow: "Theo dõi",
  Rating: "Tỷ lệ đánh giá",
  "Online Ads": "Quảng cáo đang hoạt động",
  "All Feedback": "Tất cả các phản hồi",
  "Volume in": "Khối lượng giao dịch",
  "Decentralized P2P Exchange, powered by ":
    "Sàn giao dịch P2P phi tập trung, phát triển bởi ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Trải nghiệm sàn giao dịch P2P phi tập trung đầu tiên với Polkabridge",
  Fiat: "Tiền tệ",
  All: "Tất cả",
  Token: "Mã thông báo",
  "Payment Mode": "Phương thức thanh toán",
  orders: "lệnh",
  completion: "hoàn thành",
  BUY: "MUA",
  SELL: "BÁN",
  Verify: "Xác minh",
  "Crypto Amount": "Số lượng Tokens",
  "Fiat Amount": "Số tiền",
  "Market Open Orders": "Lệnh thị trường",
  Advertisers: "Nhà quảng cáo",
  "Available | Limit": "Khả dụhg | Giới hạn",
  Payment: "Phương thức thanh toán",
  Trade: "Giao dịch",
  "Exclusive ranking for Polkabridge Traders":
    "Xếp hạng độc quyền cho các thương nhân Polkabridge",
  Volume: "Khối lượng giao dịch",
  Followers: "Người theo dõi",
  "Avg. Pay Time": "Thời gian thanh toán trung bình",
  "Avg. Release Time": "Thời gian release token trung bình",
  Username: "tên tài khoản",
  Filter: "Lọc",
  Clear: "Dọn dẹp",
  User: "Người dùng",
  "Last Trade Time": "Thời gian giao dịch cuối cùng",
  Action: "Hoạt động",
  "Unauthorised Access": "Không có quyền truy cập",
  Apply: "Áp dụng",
  "Order No.": "Lệnh số",
  Coin: "Đồng tiền",
  Type: "Loại",
  Status: "Trạng thái",
  Time: "Thời gian",
  Operation: "Hoạt động",
  "Crypto amount": "Số lượng token",
  "Fiat amount": "Số tiền",
  "Order type": "Loại lệnh",
  "Order Details": "Chi tiết lệnh",
  "Order Number": "ID lệnh",
  "Time created": "Thời gian tạo",
  "Buy Order": "Lệnh mua",
  Executed: "Thực thi",
  at: "Tại",
  "Total Fiat Amount": "Tổng số tiền",
  "Crypto Unit Price": "Đơn giá token",
  "Min Order Limit": "Giới hạn lệnh tối thiểu",
  "Max Order Limit": "Giới hạn lệnh tối đa",
  "Trade between": "Giao dịch giữa",
  "Payment Time Limit": "Thời gian thanh toán",
  Remark: "Ghi chú",
  "Need Help": "Cần giúp đỡ",
  "Please wait": "Vui lòng chờ",
  "Cancel Order": "Hủy lệnh",
  "Go To Home": "Trở về trang chủ",
  "Initiate New P2P Order": "Tạo quảng cáo P2P",
  "Set type and price": "Đặt loại quảng cáo và giá",
  "Review Your Order": "Xem lại quảng cáo của bạn",
  "I want to": "Tôi muốn",
  "Order Limit": "Giới hạn lệnh",
  Min: "Tối thiểu",
  Max: "Tối đa",
  "Payment Methods": "Phương thức thanh toán",
  "Add payments": "Thêm phương thức thanh toán",
  "Current Price": "Giá hiện tại",
  "Highest Price": "Giá cao nhất",
  "Lowest Price": "Giá thấp nhất",
  "Please note that if you decide to": "Xin lưu ý rằng nếu bạn quyết định",
  SUBMIT: "GỬI",
  "the order, you wouldn't be able to": "quảng cáo, bạn sẽ không thể",
  EDIT: "SỬA ĐỔI",
  "it afterwards.": "nó sau đó.",
  "Review New P2P Order": "Xem lại quảng cáo P2P mới",
  "Review your order": "xem lại quảng cáo của bạn",
  Remarks: "Ghi chú",
  Cancel: "Hủy bỏ",
  "Submit Order": "Xác nhận",
  "Order Submitted Successfully": "Tạo quảng cáo thành công",
  "Order Type": "Loại lệnh",
  Total: "Tổng cộng",
  "Check your order": "Kiểm tra quảng cáo của bạn",
  Deposit: "Nạp",
  "to fulfill this sell order": "Để hoàn thành lênh bán này",
  with: "với",
  "Time remaining": "Thời gian còn lại",
  "Confirm Order Info": "Xác nhận thông tin lệnh",
  "Quantity (After fee)": "Số lượng (sau phí)",
  "Chat with": "Trò chuyện với",
  Uploading: "Tải lên",
  "Upload Proof of Payment": "Tải lên bằng chứng thanh toán",
  Browse: "Chọn",
  "Trade cancelled": "Giao dịch bị hủy bỏ",
  "Transfer Payment to seller": "Thanh toán cho người bán",
  "Seller released tokens": "Người bán phát hành token",
  "Pending seller confirmation": "Đang chờ xác nhận từ người bán",
  Completed: "Hoàn thành",
  Processing: "Đang xử lý",
  "Token Deposited": "Đã Nạp Token",
  "Deposit tokens to": "Nạp mã thông báo đến",
  reserve: "dự trữ",
  "Buyer confirmed": "Người mua đã xác nhận",
  "Pending buyer confirmation": "Đang chờ xác nhận từ người mua",
  "Order Summary": "Tóm tắt",
  Available: "Khả dụng",
  "payment method": "Phương thức thanh toán",
  "Seller's Message:": "Tin nhắn từ người bán:",
  "Buyer's Message:": "Tin nhắn từ người mua:",
  "No message": "Không có tin nhắn",
  "Terms and conditions": "Các điều khoản và điều kiện",
  ordersummary_rule:
    "Không gửi thanh toán qua tài khoản của bên thứ ba, tất cả các khoản thanh toán như vậy sẽ phải tranh chấp và sẽ được hoàn trả/hủy bỏ. Vui lòng không ghi nội dung thanh toán gồm các từ liên quan đến tiền điện tử như P2P, Polkabridge, USDT, ETH, BTC, v.v.",
  "I want to buy for": "Tôi muốn mua",
  "I want to sell for": "Tôi muốn bán",
  "I will get:": "Tôi sẽ nhận:",
  "I will send:": "Tôi sẽ trả:",
  Loading: "Đang tải",
  "Order Processing": "Lệnh đang được xử lý",
  "Reference message": "Nội dung",
  "QR Code": "Mã QR",
  "Payment confirmed": "Thanh toán đã được xác nhận",
  "I've Paid. Confirm": "Tôi xác nhận rằng đã thanh toán",
  Cancelled: "Bị hủy bỏ",
  Name: "Tên",
  Email: "Email",
  Quantity: "Số lượng",
  Your: "Của bạn",
  "has been reserved in PolkaBridge vault":
    "đã được bảo lưu trong Polkabridge Vault",
  "Token reserved": "Token dự trữ",
  "Waiting for buyer's confirmation": "Chờ xác nhận của người mua",
  "Buyer confirmed payment": "Người mua xác nhận thanh toán",
  "Buyer notified": "Đã thông báo cho người mua",
  "Notify buyer": "Thông báo cho người mua",
  "Cancel trade": "Hủy giao dịch",
  "Dispute submitted": "Đã gửi tranh chấp",
  Reason: "Lý do",
  "Release tokens to buyer wallet": "Giải phóng token cho người mua",
  "Token released": "Token đã được giải phóng",
  "Release tokens": "Giải phóng token",
  "Your order is executed successfully.":
    "Lệnh của bạn đã được thực hiện thành công.",
  "My Profile": "Hồ sơ của tôi",
  "Update your preference for smooth trading experience.":
    "Cập nhật hồ sơ của bạn.",
  Menu: "Menu",
  Payments: "Phương Thức Thanh toán",
  "Transaction history": "Lịch sử giao dịch",
  Settings: "Cài đặt",
  Referral: "Giới thiệu",
  "Filter Order": "Lọc Lệnh",
  TxnId: "Txnid",
  "Transaction not found": "Giao dịch không tìm thấy",
  "Wallet unavailable": "Ví không có sẵn",
  "Check explorer": "Kiểm tra Explorer",
  "Full Name": "Họ và tên",
  Mobile: "Di động",
  "Preferred Fiat": "Tiền tệ của bạn",
  "Edit profile": "Chỉnh sửa hồ sơ",
  "Upload Avatar": "Tải lên Avatar",
  "Updating...": "Cập nhật ...",
  "Update profile": "Cập nhật hồ sơ",
  "Are you sure, want to leave PolkaBridge?":
    "Bạn có chắc chắn muốn đăng xuất ?",
  "Log out": "Đăng xuất",
  "Fiat Preference": "Tiền tệ yêu thích",
  Referrals: "Giới thiệu",
  "Will be available soon.": "Sẽ sớm có sẵn.",
  "Payment Options": "Các lựa chọn thanh toán",
  "Transaction History": "Lịch sử giao dịch",
  Fee: "Phí",
  TxnID: "Txnid",
  "All payment methods were added":
    "Tất cả các phương thức thanh toán đã được thêm vào",
  "Add Payment Method": "Thêm phương thức thanh toán",
  "Payment method": "Phương thức thanh toán",
  "QR Code (optional)": "Mã QR (tùy chọn)",
  "Upload QR Code": "Tải lên mã QR",
  "Update details": "Cập nhật",
  "Not Uploaded": "Không tải lên được",
  Edit: "Chỉnh sửa",
  "Update Payment": "Cập nhật thanh toán",
  Confirm: "Xác nhận",
  "Depositing...": "Đamg nạp tiền ...",
  "Confirming...": "Đang xác nhận ...",
  Withdraw: "Rút",
  Locked: "Khóa",
  "P2P Wallet": "Ví P2P",
  walletpage_message:
    "Quản lý tài sản trên nền tảng P2P của bạn. Bạn có thể nạp, rút tiền (tokens) để tham gia vào các giao dịch trên P2P",
  "Your Balances": "Số dư của bạn",
  "Buy Amount": "Số lượng mua",
  "Sell Amount": "Số lượng bán",
  "Trade(s)": "Giao dịch",
  active: "hoạt động",
  "Executed at": "Thực hiện tại",
  Chain: "Chuỗi",
  Authenticating: "Xác thực...",
  "Final withdraw amount": "Số tiền rút cuối cùng",
  "Buy Orders": "Danh sách lệnh mua",
  "Sell Orders": "Danh sách lệnh bán",
  "My Ads": "Quảng cáo của tôi",
  Active: "Hoạt động",
  "My Ads": "Danh sách quảng cáo",
  "Notify Seller": "Thông báo cho người bán",
  "Amount reversed by my Bank.": "Số tiền đã được ngân hàng dữ trữ",

  "Transaction failed on my behalf.":
    "Giao dịch thanh toán của tôi không thành công",
  "Seller received the amount successfully.":
    "Người bán đã nhận được số tiền thành công.",
  "Seller provided the necessary information to complete the payment.":
    "Người bán đã cung cấp thông tin cần thiết để hoàn tất thanh toán.",
  "I sent the remaining amount.": "Tôi đã gửi số tiền còn lại.",
  "I raised the dispute by mistake.": "Tôi đã nêu ra tranh chấp do nhầm lẫn.",
  "I received the tokens.": "Tôi đã nhận được token.",
  Others: "Lý do khác",
  Quest: "Nhiệm vụ",
  "Complete your quests and collect points.":
    "Hoàn thành nhiệm vụ của bạn và nhận điểm.",
  "Total Points": "Tổng số điểm",
  Redeem: "Yêu cầu thanh toán",
  "Resets in": "Đặt lại",
  "Daily Quests": "Nhiệm vụ hàng ngày",
  "Weekly Quests": "Nhiệm vụ hàng tuần",
  Rules: "Quy tắc",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1. Nhiệm vụ hàng ngày: Nhiệm vụ sẽ được reset vào lúc 00:00 UTC hàng ngày.",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2. Bạn cần nhấp vào 'Yêu cầu' để có được điểm nhiệm vụ của mình. Nếu không, điểm sẽ không được cộng vào tài khoản của bạn.",
  questrule3:
    "3. Tổng phần thưởng cho Quest round 1 sẽ là 60,000 PBR. Số điểm sẽ được chuyển đổi thành PBR tương ứng, tùy theo số point được yêu cầu. ",
  "4. Redeem points limit is 100 Points.":
    "4. Bạn phải có ít nhất 100 điểm trước khi thực hiện yêu cầu thanh toán.",

  questrule5:
    "5. Sau khi Quest round 1 kết thúc, người dùng sẽ yêu cầu claim phần thưởng của mình. Phần thưởng sẽ được gửi tới người dùng trong vòng 7 ngày  làm việc.",

  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge là người có quyền đưa ra quyết định cuối cùng. Nếu người dùng tham gia vào bất kỳ hành vi lừa đảo nào hoặc cố tình gian lận, Polkabridge có quyền đóng băng tài khoản và cấm vĩnh viễn người dùng tham gia.",
  Points: "Điểm",
  Claiming: "Đang Yêu cầu",
  Claimed: "Đã yêu cầu",
  Claim: "Yêu cầu",
  Action: "Hoạt động",
  "Redeem Points": "Đổi điểm",
  "1 Points = 1 PBR": "1 điểm = 1 pbr",
  "Redeem Points limit is 100 Points":
    "Bạn phải có ít nhất 100 điểm trước khi thực hiện yêu cầu thanh toán",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "Khi gửi yêu cầu thanh toán, admin sẽ xử lý yêu cầu của bạn và sẽ chuyển token vào ví của bạn trong vòng 1 ngày làm việc.",
  Tokens: "mã thông báo",
  "Wallet Address": "Địa chỉ ví",
  "Confirm your wallet address and submit your request.":
    "Xác nhận địa chỉ ví của bạn và gửi yêu cầu của bạn.",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "Hoàn thành ít nhất 20 giao dịch trong chuỗi Ethereum trong vòng 7 ngày liên tiếp.",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "Hoàn thành ít nhất 20 đơn đặt hàng trong chuỗi BSC trong vòng 7 ngày liên tiếp.",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "Nạp và rút tiền liên tiếp trong vòng 7 ngày.",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "Hoàn thành giao dịch với tất cả các tokens (PBR-ETH, PBR-BSC, USDT-ETH, USDT-BSC, USDC, DAI, ETH, BUSD, BNB) trong vòng 7 ngày liên tiếp.",
  "Give at least 10 feedback .":
    "Thực hiện ít nhất 10 đánh giá sau khi hoàn thành giao dịch.",
  "Create at least 1 Ad on your FIAT currency":
    "Tạo ít nhất 1 quảng cáo trên loại tiền tệ fiat của bạn",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Hoàn thành ít nhất 1 lệnh bán và 1 lệnh mua trên mạng Ethereum",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "Hoàn thành ít nhất 1 lệnh bán và 1 lệnh mua trên mạng BSC",
  "Deposit or withdraw at least 1 token on Ethereum chain":
    "Nạp hoặc rút ít nhất 1 token trên chuỗi Ethereum",
  "Deposit or withdraw at least 1 token on BSC chain":
    "Nạp hoặc rút ít nhất 1 token trên chuỗi BSC",
  "Deposit at least 0.1 ETH or 0.1 BNB.": "Nạp ít nhất 0,1 ETH hoặc 0,1 BNB.",
  "Waiting for seller to release tokens": "Đợi người bán trả token cho bạn",
  "Transfer the funds to seller account provided below":
    "Thanh toán cho người bán theo một trong những tài khoản được cung cấp dưới đây",
  "Seller released tokens": "Người bán đã trả token",
  "Waiting for confirmation": "Đợi xác nhận",
  "Dispute submitted": "Tranh chấp đã được gửi",
  Completed: "Hoàn thành",
  "Your order is executed successfully": "Lệnh giao dịch của bạn đã thành công",
  Submit: "Gửi",
  "Cancel Dispute": "Hủy Tranh Chấp",
  Cancelled: "Đã hủy",
  "Reason:": "Lý do",
  "Dispute raised": "Giao dịch đã bị tranh chấp",
  "In reviewing": "Đang xử lý",
  Cancelled: "Đã hủy",
  Resolved: "Đã giải quyết",
  "Your Proofs": "Bằng chứng của bạn",

  "I received the tokens.": "Tôi đã nhận được token từ người bán.",
  Others: "Lý do khác",
  "Seller notified": "Thông báo cho người bán",
  "Notify Seller": "Thông báo cho người bán",
  "Pending dispute": "Tranh chấp đang được xử lý",
  "Raise dispute": "Mở tranh chấp",
  "I paid too much": "Tôi đã trả quá nhiều",
  "The seller payment account is wrong, can't pay":
    "Tài khoản thanh toán người bán bị sai, không thanh toán được",
  "I paid the wrong person": "Tôi đã thanh toán nhầm người",
  "I paid for the part of the money, can't continue to pay":
    "Tôi đã thanh toán một phần tiền, không thể tiếp tục thanh toán",
  Pending: "Đang xử lý",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "Tôi xác nhận rằng vấn đề đã được xử lý và tôi muốn đóng tranh chấp",

  "Upload proof": "Tải lên bằng chứng",
  "Upload proof of payment": "Tải lên bằng chứng thanh toán",
  "I confirm there is no problem and I want to cancel this transaction":
    "Tôi xác nhận rằng không có vấn đề và tôi muốn hủy giao dịch này",
  warningcancel_seller:
    "Bạn có chắc chắn muốn hủy giao dịch này không? Lưu ý, PolkaBridge sẽ không xử lý các trường hợp tranh chấp sau khi hủy, nếu người mua đã thanh toán", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?":
    "	Xin chào, Làm thế nào chúng tôi có thể giúp bạn?",
  Support: "Hỗ trợ",
  "Raise Ticket": "Tăng vé",
  "Watch Tutorial": "Xem hướng dẫn",
  "Video Tutorial": "Video hướng dẫn",
  Open: "Mở",
  Official: "Chính thức",
  "Email Us": "Gửi email cho chúng tôi",
  Issues: "Vấn đề",
  Description: "Mô tả",
  "Upload Proof": "Tải lên bằng chứng",
  "Drag and drop the file, or": "Kéo và thả tệp, hoặc",
  "Enter the description": "Nhập mô tả",
  General: "Tổng quan",
  "Deposit/Withdraw": "Nạp/Rút",
  Account: "Tài khoản",
  Trading: "Giao dịch",
  Others: "Khác",
  Telegram: "Telegram",
  "Online/Offline": "Trực tuyến/Ngoại tuyến",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "Vé của bạn đã được gửi và sẽ được xem xét bởi nhóm của chúng tôi. Vấn đề sẽ được giải quyết trong vòng 24 giờ. Cảm ơn sự kiên nhẫn của bạn.",
  "Ticket History": "Lịch sử vé",
  Date: "Ngày",
  "Ticket ID": "ID vé",
  Issue: "Vấn đề",
  Reviewing: "Xem xét",
  Resolved: "Đã giải quyết",
  Pending: "Chưa giải quyết",
  "Show Online Users": "Chỉ hiển thị người dùng trực tuyến",
  "Tokens Locked": "Mã thông báo bị khóa",
  "Payment Done": "Thanh toán được thực hiện",
  "In Dispute": "Tranh chấp",
  Date: "Ngày",
  Ascending: "Tăng dần",
  Descending: "Tăng dần",
  Authenticating: "Xác thực",
  "Admin Remark": "Ghi chú của quản trị viên",
  Message: "Tin nhắn",
  "Price Ascending": "Giá tăng dần",
  "Price Descending": "Giá giảm dần",
  "Latest Ads": "Quảng cáo mới nhất",
  "Latest Orders": "Đơn đặt hàng mới nhất",
  "Completed Trades": "Hoàn thành giao dịch",
  "Admin Message": "Phản hổi từ Admin",
  "User Guide": "Hướng dẫn sử dụng",
  "Beginner's Guide": "Hướng dẫn của người mới bắt đầu",
  "Video Tutorial": "Video hướng dẫn",
  "P2P trading FAQ": "Câu hỏi thường gặp về giao dịch P2P",
  "Review Submitted Successfully": "Đánh giá đã gửi thành công",
  "Transaction Hash": "Băm giao dịch",
  "Ticket Details": "Chi tiết vé",
  Ended: "Đã kết thúc",
  "Round 1 Ended": "Vòng 1 kết thúc",
  "Redeem your points before Round 2 starts":
    "Đổi điểm của bạn trước khi vòng 2 bắt đầu",
  "Redeem History": "Đổi lịch sử",
  "Redeem Time": "Đổi thời gian",
  Address: "Địa chỉ",
  pending: "chưa giải quyết",
  completed: "hoàn thành",
  rejected: "vật bị loại bỏ",
  "Redeemed At": "Chuộc lại tại",
  "Redeem Detail": "Đổi chi tiết",
  "Transaction ID": "ID giao dịch",
  "Admin Remark": "Nhận xét quản trị viên",
  rejected: "vật bị loại bỏ",
  "Profile Tab": "Tab hồ sơ",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "Bấm để cập nhật hồ sơ của bạn như tên, ID người dùng, số điện thoại di động, email và quan trọng nhất là thêm các tùy chọn thanh toán được đặt trước của bạn.",
  "Quest Tab": "Tab Quest",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "Bấm để tham gia vào chiến dịch Quest liên tục và để yêu cầu các điểm để nhận phần thưởng $ PBR.",
  "LeaderBoard Tab": "Tab bảng xếp hạng",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "Bấm để xem xếp hạng độc quyền cho các nhà giao dịch Polkabridge.",
  "Buy Vs Sell": "Mua vs bán",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "Bằng cách chọn Tùy chọn Mua, bạn có thể mua Crypto với các loại tiền tệ fiat được đặt trước của bạn.",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "Bằng cách chọn Tùy chọn Bán, bạn có thể bán tiền điện tử với các loại tiền tệ fiat được đặt sẵn của mình.",
  "Filter Tool": "Công cụ lọc",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "Bấm để sắp xếp quảng cáo theo tùy chọn của bạn hoặc sử dụng bộ lọc để định cấu hình quảng cáo được hiển thị cho bạn. Tùy chọn bộ lọc dựa trên tính khả dụng của khu vực/tiền tệ của bạn.",
  "Advertiser Info": "Thông tin nhà quảng cáo",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "Ở đây bạn thấy hồ sơ của người bán, trạng thái trực tuyến, tỷ lệ hoàn thành, tổng số đơn đặt hàng đã hoàn thành và huy hiệu thương gia. Nhấp vào tên của người bán để xem thêm thông tin.",
  "Price and Limits": "Giá cả và giới hạn",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "Tại đây, bạn có thể thấy giá của 1 đồng tiền mà người dùng muốn giao dịch, số lượng sẵn có và số tiền thấp nhất và cao nhất mà người dùng sẵn sàng giao dịch.",
  "Payment options": "Các lựa chọn thanh toán",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "Tại đây, bạn có thể thấy phương thức thanh toán mà nhà quảng cáo chấp nhận giao dịch cho mã thông báo/fiat.",
  "Lowest Fees": "Phí thấp nhất",
  "Buy crypto with lowest fees ever!":
    "Mua tiền điện tử với phí thấp nhất từ ​​trước đến nay!",
  "P2P Wallet": "Ví P2P",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "Bấm để kiểm tra số dư của bạn và để quản lý tiền gửi và rút tiền tài sản của bạn và lịch sử của họ.",
  Next: "Kế tiếp",
  Skip: "Nhảy",
  Close: "Đóng",
  "Create Order Tab": "Tạo tab đơn hàng",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "Vui lòng nhấp vào nút để tạo đơn đặt hàng mua/bán bằng cách sử dụng các loại tiền tệ/mã thông báo/mã thanh toán ưa thích của bạn.",
  "Switch Network": "Chuyển mạng",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "Nền tảng P2P của chúng tôi được thiết kế để hỗ trợ Multichain, có nghĩa là bạn có tính linh hoạt để tiến hành các giao dịch trên bất kỳ chuỗi nào bạn chọn.",
  "24h Trading Volume": "Khối lượng giao dịch 24h",
  "24h Total Orders": "Tổng số đơn đặt hàng",
  "Show Users Following": "Hiển thị người dùng theo sau",
  Following: "Tiếp theo",
  Online: "Trực tuyến",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "Vui lòng đổi điểm của bạn trước ngày 24 tháng 3, 00:00 AM UTC. Số điểm nhỏ nhất để quy đổi là 100 điểm",
  "User's Statistics": "Thống kê của người dùng",
  Phone: "Điện thoại",
  Follower: "Người theo dõi",
  "All Trades": "Tất cả các giao dịch",
  "Total Buy Orders": "Tổng số đơn đặt hàng mua",
  "Total Sell Orders": "Tổng số đơn đặt hàng",
  Share: "Chia sẻ",
  "Reopen Ticket": "Mở lại vé",
  Comment: "Bình luận",
  "Add your comment": "Thêm nhận xét của bạn",
  "Comment must be less than 5000 characters":
    "Nhận xét phải ít hơn 5000 ký tự",
  "Comment is required": "Nhận xét là bắt buộc",
  "Reopen Date": "Ngày mở lại",
  Proof: "Bằng chứng",
  "Share your P2P profile": "Chia sẻ hồ sơ P2P của bạn",
  Sort: "Loại",
  "Select Token": "Chọn mã thông báo",
  "Select Fiat": "Chọn Fiat",
  "Date Range": "Phạm vi ngày",
  "Enter username": "Điền tên đăng nhập",
  "Trade Currency": "Tiền tệ thương mại",
  "Enter your message for seller": "Nhập tin nhắn của bạn cho người bán",
  "Deposit Token": "Mã thông báo gửi tiền",
  "Withdraw Token": "Rút mã thông báo",
  "Insufficient!": "Không đủ!",
  Wait: "Chờ đợi",
  "Insufficient funds!": "Không đủ tiền!",
  "Insufficient withdraw!": "Rút tiền không đủ!",
  Approve: "Chấp thuận",
  "Approving...": "Chấp thuận ...",
  "Issues with Deposit?": "Vấn đề với tiền gửi?",
  "All Networks": "Tất cả các mạng",
  "Enter amount": "Nhập số tiền",
  "Enter price": "Nhập giá",
  "Select payment methods": "Chọn phương thức thanh toán",
  "Enter order min and max limits":
    "Nhập đơn đặt hàng tối thiểu và giới hạn tối đa",
  "Enter valid min limit": "Nhập giới hạn tối thiểu hợp lệ",
  "Max limit must be less than": "Giới hạn tối đa phải nhỏ hơn",
  "Min limit must be less than": "Giới hạn tối thiểu phải nhỏ hơn",
  "Preview order": "Trước đặt hàng",
  min: "Tối thiểu",
  Global: "Toàn cầu",
  "Enter your name": "Nhập tên của bạn",
  "Choose your username": "Chọn tên người dùng của bạn",
  "Enter your email": "Nhập email của bạn",
  "Your Full Name": "Tên đầy đủ của bạn",
  "A/c Number": "Số a/c",
  "Account number": "Số tài khoản",
  "Bank Name": "Tên ngân hàng",
  "IFSC Code": "Mã IFSC",
  "Note (Optional)": "LƯU Ý (Tùy chọn)",
  "Additional Notes": "Ghi chú bổ sung",
  "Phone Number": "Số điện thoại",
  "Account phone number": "Số điện thoại tài khoản",
  "Payment QR Code (Optional)": "Mã QR thanh toán (tùy chọn)",
  "Please enter your QR Code": "Vui lòng nhập mã QR của bạn",
  Day: "Ngày",
  Week: "Tuần",
  Month: "Tháng",
  Months: "Tháng",
  hash: "băm",
  "Enter txn hash": "Nhập băm TXN",
  Success: "Thành công",
  Failed: "Thất bại",
  Review: "Ôn tập",
  Dropped: "Giảm",
  LongPending: "Lâu xa",
  "Redeem Ended": "Cứu chuộc đã kết thúc",
  "Please enter your bank account number":
    "Vui lòng nhập số tài khoản ngân hàng của bạn",
  "Bank Name": "Tên ngân hàng",
  "Enter the name of your bank": "Nhập tên ngân hàng của bạn",
  "Account opening branch(Optional)": "Chi nhánh mở tài khoản (tùy chọn)",
  "Enter bank branch information": "Nhập thông tin chi nhánh ngân hàng",
  "Paypal ID / Number": "Id / số paypal",
  "Perfect Money ID / Number": "Id / số tiền hoàn hảo",
  "Bank Details (Optional)": "Chi tiết ngân hàng (tùy chọn)",
  "Please enter your bank details": "Vui lòng nhập chi tiết ngân hàng của bạn",
  "Country of Residence (Optional)": "Quốc gia cư trú (tùy chọn)",
  "Please enter your country of residence":
    "Vui lòng vào quốc gia cư trú của bạn",
  "Username (Optional)": "Tên người dùng (tùy chọn)",
  "Account Username": "Tài khoản người dùng",
  "Account Email": "Tai khoản thư điện tử",
  "Wallet ID": "Ví ID",
  "Revtag (Optional)": "Revtag (tùy chọn)",
  "Please enter your Revtag": "Vui lòng nhập Revtag của bạn",
  "Phone number (Optional)": "Số điện thoại (không bắt buộc)",
  "Please enter payment details": "Vui lòng nhập chi tiết thanh toán",
  "Account number (USD)": "Số tài khoản (USD)",
  "Account number (KHR)(Optional)": "Số tài khoản (KHR) (Tùy chọn)",
  "Payeer account number": "Số tài khoản Payeer",
  "Enter your Payeer account number": "Nhập số tài khoản Payeer của bạn",
  "Email (Optional)": "Email (tùy chọn)",
  "Account Email (Not required)": "Email tài khoản (không bắt buộc)",
  "Email or Username": "Thư điện tử hoặc tên người dùng",
  "Account Email or Username": "Email tài khoản hoặc tên người dùng",
  "Full Name of Receiver": "Tên đầy đủ của người nhận",
  "Receivers's Full Name": "Tên đầy đủ của người nhận",
  "Payment details (Optional)": "Chi tiết thanh toán (tùy chọn)",
  "ID Number": "Số ID",
  "Please enter your National ID Number":
    "Vui lòng nhập số ID quốc gia của bạn",
  "Account Type": "Kiểu tài khoản",
  "Enter your account type": "Nhập loại tài khoản của bạn",
  "Name and Lastname": "Tên và tên cuối cùng",
  "Enter Name and Lastname": "Nhập tên và tên cuối cùng",
  "Full Name": "Họ và tên",
  "Enter Full Name": "Nhập tên đầy đủ",
  Cashtag: "Cashtag",
  "Please enter your Cashtag": "Vui lòng nhập CashTag của bạn",
  "Full Name (Optional)": "Tên đầy đủ (tùy chọn)",
  "ID Number (Optional)": "Số ID (tùy chọn)",
  "Address (Optional)": "Địa chỉ (tùy chọn)",
  "Residential Address": "Địa chỉ dân cư",
  "Mobile Number": "Số điện thoại",
  "Enter your Mobile Number available for all networks":
    "Nhập số điện thoại di động của bạn có sẵn cho tất cả các mạng",
  "Contact Number": "Số liên lạc",
  "Phone Number": "Số điện thoại",
  Operator: "Nhà điều hành",
  "Mobile / Landline / Data / TV":
    "Di động / điện thoại cố định / dữ liệu / TV",
  "Enter account opening branch (Not required)":
    "Nhập chi nhánh mở tài khoản (không bắt buộc)",
  "IBAN Number": "Số Iban",
  "BIC/SWIFT Code(Optional)": "Mã BIC/Swift (tùy chọn)",
  "BIC/SWIFT Code": "Mã BIC/Swift",
  "Enter international bank account number":
    "Nhập số tài khoản ngân hàng quốc tế",
  "SWIFT BIC (Optional)": "Swift Bic (tùy chọn)",
  "Enter your bank identification code": "Nhập mã nhận dạng ngân hàng của bạn",
  "GB IBAN account number": "Số tài khoản GB IBAN",
  "IBAN (Optional)": "IBAN (Tùy chọn)",
  "Other payment details (for international transfer) (Optional)":
    "Các chi tiết thanh toán khác (để chuyển nhượng quốc tế) (tùy chọn)",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "Các chi tiết như địa chỉ của riêng bạn, địa chỉ của ngân hàng, mã IBAN và mã Swiftbic, v.v.",
  "Bank Branch (Optional)": "Chi nhánh ngân hàng (tùy chọn)",
  "Recommended Transfer Remarks (Optional)":
    "Nhận xét chuyển nhượng được đề xuất (tùy chọn)",
  "Recommended Transfer Remarks": "Nhận xét chuyển nhượng được đề xuất",
  'Please enter your MoMo phone number"':
    'Vui lòng nhập số điện thoại Momo của bạn "',
  "Please enter your MoMo QR Code": "Vui lòng nhập mã MOMO QR của bạn",
  "Please enter your Zalopay QR Code": "Vui lòng nhập mã QR Zalopay của bạn",
  "Additional account number (Optional)": "Số tài khoản bổ sung (tùy chọn)",
  "Please enter additional account number":
    "Vui lòng nhập số tài khoản bổ sung",
  "Branch code (Optional)": "Mã chi nhánh (tùy chọn)",
  "Enter branch code (not required)": "Nhập mã nhánh (không bắt buộc)",
  "IBAN (Optional)": "IBAN (Tùy chọn)",
  "Please enter the IBAN code of your Bank (not required)":
    "Vui lòng nhập mã IBAN của ngân hàng của bạn (không bắt buộc)",
  "IBAN or account number": "Iban hoặc số tài khoản",
  "Please enter your IBAN or account number":
    "Vui lòng nhập số IBAN hoặc số tài khoản của bạn",
  "Papara account number": "Số tài khoản papara",
  "Please enter your Papara account number":
    "Vui lòng nhập số tài khoản papara của bạn",
  City: "Thành phố",
  "Please enter your city": "Làm ơn nhập tên thành phố bạn đang sinh sống",
  "Zip Code": "Mã Bưu Chính",
  "Please enter your city zipcode":
    "Vui lòng nhập mã Zipcode thành phố của bạn",
  Country: "Quốc gia",
  "Please enter Country": "Vui lòng vào quốc gia",
  "Moneygram Account Number": "Số tài khoản Moneygram",
  "Please enter your Moneygram account number":
    "Vui lòng nhập số tài khoản MoneyGram của bạn",
  "Enter bank branch information": "Nhập thông tin chi nhánh ngân hàng",
  "Please enter your BCA account number":
    "Vui lòng nhập số tài khoản BCA của bạn",
  "Please enter your Mandiri account number":
    "Vui lòng nhập số tài khoản Mandiri của bạn",
  "Chipper Cash full name": "Tên đầy đủ tiền mặt của chipper",
  "Please enter your full name used to register on chipper cash":
    "Vui lòng nhập tên đầy đủ của bạn được sử dụng để đăng ký trên chipper Cash",
  "Chipper Tag": "Tag Chipper",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "Vui lòng nhập @Chippertag của bạn. Bạn có thể tìm thấy nó trong tab Hồ sơ của bạn trên Chipper Cash",
  "QIWI account": "Tài khoản Qiwi",
  "Please enter your QIWI account": "Vui lòng nhập tài khoản Qiwi của bạn",
  "Your email registered on Binance": "Email của bạn đã đăng ký trên Binance",
  "Please enter your email registered on Binance":
    "Vui lòng nhập email của bạn đã đăng ký trên Binance",
  "Payment Details": "Chi tiết thanh toán",
  "WeChat account": "Tài khoản WeChat",
  "Please enter your WeChat account": "Vui lòng nhập tài khoản WeChat của bạn",
  "Receive QR Code (Optional)": "Nhận mã QR (tùy chọn)",
  "Alipay account": "Tài khoản Alipay",
  "Please enter your Alipay account": "Vui lòng nhập tài khoản Alipay của bạn",
  "Payee's LINE ID": "ID dòng của người được trả tiền",
  "Please fill in your LINE ID": "Vui lòng điền ID dòng của bạn",
  "Please fill in your phone number registered with LINE":
    "Vui lòng điền vào số điện thoại của bạn đã đăng ký với dòng",
  "Linepay QR Code (Optional)": "Mã QR của LinePay (Tùy chọn)",
  "Reference number or barcode": "Số tham chiếu hoặc mã vạch",
  "Please enter reference number or barcode":
    "Vui lòng nhập số tham chiếu hoặc mã vạch",
  "Remarks (Optional)": "Nhận xét (tùy chọn)",
  "Additional Remarks": "Nhận xét bổ sung",
  "Cell Phone Number": "Số điện thoại di động",
  "Please enter your cell phone number":
    "Vui lòng nhập số điện thoại di động của bạn",
  "Enter your ID number": "Nhập số ID của bạn",
  "Fill in your phone number": "Điền vào số điện thoại của bạn",
  "Full Name of Receiver": "Tên đầy đủ của người nhận",
  "Please enter your Full Name": "Vui lòng nhập tên đầy đủ của bạn",
  CBU: "CBU",
  "Please enter your CBU": "Vui lòng nhập CBU của bạn",
  "CUIT / CUIL": "Cuit / Cuil",
  "Enter your unique tax identification code":
    "Nhập mã nhận dạng thuế duy nhất của bạn",
  "Please enter your CVU": "Vui lòng nhập CVU của bạn",
  Alias: "Bí danh",
  "Please enter Alias": "Vui lòng nhập bí danh",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "Thẻ ví chanh",
  "Uniform Bank Key": "Khóa ngân hàng thống nhất",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "Nhập số DNI/CPF của bạn",
  "CVU (Optional)": "CVU (tùy chọn)",
  "Uniform Virtual Key": "Khóa ảo đồng đều",
  "CBU/CVU or": "CBU/CVU hoặc",
  "alias CVU/CBU or CVU/CBU number": "Bí danh CVU/CBU hoặc số CVU/CBU",
  "Alias del CBU": "Bí danh del cbu",
  "Vodafone cash Number": "Số tiền mặt Vodafone",
  "Please enter your vodafone cash Number":
    "Vui lòng nhập số tiền mặt Vodafone của bạn",
  "Sort code": "Sort code",
  "Please fill in your 6-digit sort code":
    "Vui lòng điền vào mã sắp xếp 6 chữ số của bạn",
  "Sort Code(Optional)": "Sắp xếp mã (tùy chọn)",
  "GB IBAN account number": "Số tài khoản GB IBAN",
  "IIN (Optional)": "Iin (tùy chọn)",
  "Enter individual identification number": "Nhập số nhận dạng cá nhân",
  "Please enter your bank card number":
    "Vui lòng nhập số thẻ ngân hàng của bạn",
  "Mobile Carrier": "Mạng di động",
  "Please enter your mobile carrier": "Vui lòng nhập nhà mạng di động của bạn",
  "Easypaisa mobile account": "Tài khoản di động Easypaisa",
  "Please enter your Easypaisa mobile account":
    "Vui lòng nhập tài khoản di động Easypaisa của bạn",
  "NayaPay Id": "ID Nayapay",
  "Please enter NayaPay Id": "Vui lòng nhập ID Nayapay",
  "Enter Brazilian valid document": "Nhập tài liệu hợp lệ của Brazil",
  "Bank agency": "Cơ quan ngân hàng",
  "Enter the name of your bank agency":
    "Nhập tên của cơ quan ngân hàng của bạn",
  "Type of transfer": "Loại chuyển nhượng",
  "Please filled with TED/DOC/PIX": "Vui lòng chứa đầy TED/DOC/PIX",
  "Name and Last Name": "Tên và tên cuối cùng",
  "Pix Key": "Phím pix",
  "Phone, email or key": "Điện thoại, email hoặc chìa khóa",
  'Type of Bank"': 'Loại ngân hàng "',
  "Name registered in PicPay": "Tên đã đăng ký trong picpay",
  "PicPay username": "Tên người dùng picpay",
  "Username used to log in in PicPay":
    "Tên người dùng được sử dụng để đăng nhập vào picpay",
  "urpay Account Details": "Chi tiết tài khoản Urpay",
  "Please enter your urpay account details":
    "Vui lòng nhập chi tiết tài khoản Urpay của bạn",
  "bKash Wallet Number": "Số ví Bkash",
  "Please enter your 11 digit registered bKash account number":
    "Vui lòng nhập số tài khoản BKash đã đăng ký 11 chữ số của bạn",
  "Nagad Wallet Number": "Số ví Nagad",
  "Please enter your 11 digit registered Nagad wallet number":
    "Vui lòng nhập số ví Nagad đã đăng ký 11 chữ số của bạn",
  "Please enter your full name registered with Rocket account":
    "Vui lòng nhập tên đầy đủ của bạn đã đăng ký với tài khoản Rocket",
  "Rocket Wallet Number": "Số ví tên lửa",
  "Please enter your 12 digit Rocket Wallet number":
    "Vui lòng nhập số ví tên lửa 12 chữ số của bạn",
  "Please enter your JKOPAY ID": "Vui lòng nhập ID JKopay của bạn",
  "EasyPay wallet number (Optional)": "Số ví Easypay (tùy chọn)",
  "Please fill in EasyPay wallet number": "Vui lòng điền vào số ví Easypay",
  "User API Key": "Khóa API người dùng",
  "User Secret Key": "Khóa bí mật người dùng",
  "Bank account number": "số tài khoản ngân hàng",
  PayID: "Payid",
  "Please enter your PayID": "Vui lòng nhập Payid của bạn",
  "Institution number": "Số tổ chức",
  "Please enter institution number": "Vui lòng nhập số tổ chức",
  "PayCode (Optional)": "PayCode (Tùy chọn)",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "Có thể tìm thấy trong tài khoản của tôi -> paycode của tôi -> sao chép liên kết",
  "Please fill in your mobile phone number connected with your PayMe":
    "Vui lòng điền vào số điện thoại di động của bạn được kết nối với Payme của bạn",
  Cellphone: "Điện thoại di động",
  "Card Number or password": "Số thẻ hoặc mật khẩu",
  "Please fill in card Number or password":
    "Vui lòng điền vào số thẻ hoặc mật khẩu",
  "Purpose of payment (Optional)": "Mục đích thanh toán (tùy chọn)",
  "Enter a payment purpose for buyers":
    "Nhập mục đích thanh toán cho người mua",
  "Card number, account or key": "Số thẻ, tài khoản hoặc khóa",
  "CLABE number (Optional)": "Số clabe (tùy chọn)",
  "CLABE number": "Số clabe",
  "Account number/card": "Số tài khoản/thẻ",
  "Reference number or card number": "Số tham chiếu hoặc số thẻ",
  "Please enter reference number or card number":
    "Vui lòng nhập số tham chiếu hoặc số thẻ",
  "Provide transfer details during the trade":
    "Cung cấp chi tiết chuyển nhượng trong quá trình giao dịch",
  "Variable symbol (Optional)": "Biểu tượng biến (tùy chọn)",
  "Enter Variable symbol": "Nhập ký hiệu biến",
  "Enter your PayNow account": "Nhập tài khoản PayNow của bạn",
  "Account Name": "Tên tài khoản",
  "Email Address": "Địa chỉ email",
  "Please enter email address": "Vui lòng nhập địa chỉ email",
  "Please enter Your Full Name": "Vui lòng nhập tên đầy đủ của bạn",
  "Account Number": "Số tài khoản",
  "Enter your phone number": "Nhập số điện thoại của bạn",
  "Enter your account number": "Nhập số tài khoản của bạn",
  "Enter your bank account number": "Nhập số tài khoản ngân hàng của bạn",
  "Payment details": "Chi tiết thanh toán",
  "Bank name": "tên ngân hàng",
  "Please enter your MoMo phone number":
    "Vui lòng nhập số điện thoại Momo của bạn",
  "Please enter your email": "Vui lòng nhập email",
  "Enter branch code": "Nhập mã nhánh",
  "Please enter the IBAN code of your Bank":
    "Vui lòng nhập mã IBAN của ngân hàng của bạn",
  "Account opening branch (Optional)": "Chi nhánh mở tài khoản (tùy chọn)",
  "Enter account opening branch (not required)":
    "Nhập nhánh mở tài khoản (không bắt buộc)",
  "Please enter your Bank account number":
    "Vui lòng nhập số tài khoản ngân hàng của bạn",
  "Please enter your ID Number": "Vui lòng nhập số ID của bạn",
  "Enter bank account type": "Nhập loại tài khoản ngân hàng",
  "Bank Branch (Optional)": "Chi nhánh ngân hàng (tùy chọn)",
  "Account type": "Kiểu tài khoản",
  "Please enter bank branch information":
    "Vui lòng nhập thông tin chi nhánh ngân hàng",
  "Please enter Email address": "Vui lòng nhập địa chỉ email",
  "Phone number(Optional)": "Số điện thoại (không bắt buộc)",
  "Phone Number (Optional)": "Số điện thoại (không bắt buộc)",
  "Additional information": "Thông tin thêm",
  "Type of Bank": "Loại ngân hàng",
  "Remark (Optional)": "Ghi chú (tùy chọn)",
  "Mobile Phone": "Điện thoại di động",
  "Enter the bank code": "Nhập mã ngân hàng",
  "Please enter your phone number": "Xin vui lòng điền số điện thoại của bạn",
  "Please enter your Bank name": "Vui lòng nhập tên ngân hàng của bạn",
};

export default VIETNAMESE;
