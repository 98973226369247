import React, { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { cancelTradeCall } from "utils/httpCalls/orderTradeCalls";
import { useDispatch, useSelector } from "react-redux";
import { getUserTradeById } from "actions/tradeActions";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  icon: {
    width: 25,
    height: 25,
    color: "white",
  },
  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: 20,
    width: "90%",

    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  condition: {
    cursor: "pointer",
  },
}));

const CancelTradePopup = ({ resetPopup, trade, isSeller }) => {
  const [isSelect, setSelect] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = useSelector((state) => state?.user?.jwtToken);
  const account = useSelector((state) => state?.user?.account);
  const dispatch = useDispatch();

  const handleSelect = () => {
    setSelect(!isSelect);
  };

  const handleConfirm = useCallback(async () => {
    if (!authToken) {
      setError("Invalid auth token, please refresh and try again!");
      return;
    }

    if (!trade?._id) {
      setError("Invalid trade, please refresh and try again!");
      return;
    }

    setLoading(true);

    const cancelRes = await cancelTradeCall(
      trade?._id,
      isSeller,
      account,
      authToken
    );
    setLoading(false);

    if (cancelRes.status !== 200) {
      console.log("cancel response ", { cancelRes });
      setError(cancelRes?.message?.msg);
      return;
    }

    dispatch(getUserTradeById(trade?._id, account, authToken));

    resetPopup();
  }, [loading, authToken, trade, isSeller]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className="d-flex justify-content-end">
          <Close
            style={{ color: theme.palette.text.primary, fontSize: 28 }}
            onClick={resetPopup}
          />
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Box className={classes.heading}>
            <FormattedMessage id="Cancel Trade" defaultMessage="Cancel Trade" />
          </Box>
        </div>
        <Box display="flex" justifyContent="center" pt={sm ? 1 : 2}>
          <Box className={classes.cautionTextBox}>
            <Typography
              fontSize={sm ? 20 : 22}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
              fontWeight={600}
              mb={sm ? 0.5 : 1}
            >
              <FormattedMessage id="Warning" defaultMessage="Warning" />
            </Typography>
            <Typography
              fontSize={sm ? 13 : 16}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
              fontWeight={sm ? 400 : 500}
              mb={sm ? 0.5 : 1}
            >
              {isSeller ? (
                <FormattedMessage
                  id="warningcancel_seller"
                  defaultMessage="Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid."
                />
              ) : (
                <FormattedMessage
                  id="warningcancel"
                  defaultMessage="There will be no refund after cancelling the order. Please do NOT
              cancel if you have already transferred money."
                />
              )}
            </Typography>
          </Box>
        </Box>

        <Typography
          fontSize={sm ? 14 : 17}
          fontWeight={sm ? 400 : 600}
          color={theme.palette.mode === "light" ? "#919191" : "#646892"}
          textAlign="center"
          mt={2.5}
          mb={2.5}
        >
          <FormattedMessage
            id="tradewarning"
            defaultMessage="Trading will be suspended for the day if one cancelled 10 transactions
          in a day."
          />
        </Typography>

        <Box
          margin="3px auto"
          mt={2.5}
          display="flex"
          justifyContent="center"
          width="90%"
          alignItems="center"
          className={classes.condition}
          onClick={handleSelect}
        >
          <Checkbox size="small" checked={!isSelect} />
          <Typography
            fontSize={sm ? 14 : 18}
            fontWeight={500}
            color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            ml={1}
          >
            {isSeller ? (
              <FormattedMessage
                id="I confirm there is no problem and I want to cancel this transaction"
                defaultMessage="I confirm there is no problem and I want to cancel this transaction"
              />
            ) : (
              <FormattedMessage
                id="I confirm I have not paid the payee."
                defaultMessage="I confirm I have not paid the payee."
              />
            )}
          </Typography>
        </Box>
        {error && (
          <Box
            margin="3px auto"
            mt={2.5}
            display="flex"
            justifyContent="center"
            width="90%"
            alignItems="center"
          >
            <Typography
              fontSize={sm ? 14 : 18}
              fontWeight={500}
              color={"red"}
              ml={1}
            >
              {error}
            </Typography>
          </Box>
        )}
        <Box className="d-flex justify-content-center align-items-baseline pt-3">
          <div>
            <button
              onClick={handleConfirm}
              style={{
                background: isSelect
                  ? theme.palette.mode === "light"
                    ? "#bdbdbd"
                    : "#646892"
                  : "#6A55EA",
                border: isSelect
                  ? theme.palette.mode === "light"
                    ? "1px solid #bdbdbd"
                    : "1px solid #646892"
                  : `1px solid #6A55EA`,
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: sm ? 15 : 20,
                paddingRight: sm ? 15 : 20,
                fontSize: sm ? 13 : 16,
                textDecoration: "none",
                color: "#fff",
              }}
              disabled={isSelect || loading}
            >
              {loading ? (
                <FormattedMessage
                  id="Please wait..."
                  defaultMessage="Please wait..."
                />
              ) : (
                <FormattedMessage
                  id="Cancel this trade"
                  defaultMessage="Cancel this trade"
                />
              )}
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default CancelTradePopup;
