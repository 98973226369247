import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, fromWei } from "../../../utils/helper";
import { FormattedMessage } from "react-intl";
import { CHAIN_IDS_TO_DISPLAY_NAMES } from "../../../constants/chains";
import { GradeRounded, StarBorderRounded } from "@mui/icons-material";
import BigNumber from "bignumber.js";

const OrderCard = ({ order, index }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const profile = useSelector((state) => state?.profile?.profile);

  const verified = useMemo(() => {
    return profile?.email_verified && profile?.phone_verified;
  }, [profile]);

  const orderProgress = () => {
    const progress = new BigNumber(order.order_amount)
      .minus(order.pending_amount)
      .div(order.order_amount)
      .multipliedBy(100)
      .toNumber();

    return progress;
  };
  return (
    <Box
      id={`order-${index}`}
      px={2}
      py={2}
      bgcolor={
        index % 2
          ? theme.palette.background.primary
          : theme.palette.background.secondary
      }
      borderRadius={2}
      mb={1}
      border={`1px solid ${theme.palette.background.primary}`}
      style={{
        backgroundColor:
          order?.user?._id === user?.id && theme.palette.background.highlight,
        border:
          order?.user?._id === user?.id &&
          `2px solid ${theme.palette.text.secondary}`,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="advertiser-info"
      >
        <Box display="flex" alignItems="center">
          <Box
            mr={2}
            borderRadius="100%"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight={600}
            fontSize={14}
            color="#EAECEE"
          >
            <Box
              style={{
                position: "absolute",
                height: 10,
                width: 10,
                borderRadius: "100%",
                border: "1px solid white",
                bottom: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: order?.user?.isOnline ? "#6EB437" : "#E59939",
              }}
            />
            <Avatar
              src={order?.user?.avatar ? `${order?.user?.avatar}` : null}
              alt={order?.user?.name}
              onClick={() => navigate(`/advertiser-detail/${order?.user?._id}`)}
              sx={{
                cursor: "pointer",
                bgcolor: theme.palette.primary.main,
                height: 30,
                width: 30,
                fontSize: 16,
              }}
            >
              {order?.user?.name[0]?.toUpperCase()}
            </Avatar>
          </Box>
          <Link
            className="d-flex flex-column"
            to={`/advertiser-detail/${order?.user?._id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              fontWeight={500}
              color={theme.palette.text.primary}
              sx={{ wordBreak: "break-all" }}
            >
              {order?.user?.name}
            </Typography>
            <Rating
              name="customized-color"
              defaultValue={order?.user?.rating ? order?.user?.rating : 0}
              precision={0.1}
              icon={<GradeRounded sx={{ fontSize: 16 }} />}
              emptyIcon={<StarBorderRounded sx={{ fontSize: 16 }} />}
              readOnly
            />
          </Link>
        </Box>
        <Typography
          className="d-flex flex-sm-row flex-column"
          textAlign={sm ? "right" : "left"}
          fontSize={12}
          fontWeight={500}
          color={theme.palette.text.primary}
        >
          {order?.user?.totalTrades}{" "}
          {<FormattedMessage id="orders" defaultMessage="orders" />}
          {order?.user?.totalTrades
            ? ` | 
              ${(
                (order?.user?.successfulTrades * 100) /
                order?.user?.totalTrades
              )?.toFixed(2)}
            % `
            : " | 0 % "}
          <FormattedMessage id="completion" defaultMessage="completion" />
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
        mb={sm && 1}
      >
        <Box id={"price"}>
          <Typography fontSize={13} color={theme.palette.text.secondary}>
            <FormattedMessage id="Price" defaultMessage="Price" />
          </Typography>

          <Typography
            fontSize={sm ? 16 : 20}
            fontWeight={600}
            color={theme.palette.text.primary}
          >
            {`${formatCurrency(order?.order_unit_price, true)} ${
              order?.fiat?.fiat
            }`}
          </Typography>
        </Box>
        <Link
          className="fees"
          to={
            !verified
              ? "/profile"
              : `/order/${order?._id}?tradeType=${
                  order?.order_type === "sell" ? "buy" : "sell"
                }`
          }
          style={{
            textDecoration: "none",
            pointerEvents: order?.user?._id === user?.id && "none",
          }}
        >
          <Button
            style={{
              backgroundColor:
                order?.user?._id !== user?.id
                  ? order?.order_type === "sell"
                    ? theme.palette.action.green
                    : theme.palette.action.red
                  : theme.palette.action.disabled,
              color: "#ffffff",
              borderRadius: 7,
              paddingLeft: 20,
              fontWeight: 600,
              paddingRight: 20,
              fontSize: 12,
              visibility: order?.user?._id === user?.id ? "hidden" : "visible",
            }}
            disabled={order?.user?._id === user?.id}
          >
            {verified ? (
              order?.order_type === "sell" ? (
                <>
                  <FormattedMessage id="BUY" defaultMessage="BUY" />{" "}
                  {order?.token?.symbol}
                </>
              ) : (
                <>
                  <FormattedMessage id="SELL" defaultMessage="SELL" />{" "}
                  {order?.token?.symbol}
                </>
              )
            ) : (
              <FormattedMessage id="Verify" defaultMessage="Verify" />
            )}
          </Button>
        </Link>
      </Box>

      <Box display="flex" flexDirection="column" mt={0.5} mb={0.5}>
        <Box display="flex">
          <Typography fontSize={13} color={theme.palette.text.secondary} mr={1}>
            <FormattedMessage
              id="Crypto Amount"
              defaultMessage="Crypto Amount"
            />
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={500}
            color={theme.palette.text.primary}
          >
            {`${formatCurrency(
              fromWei(order?.pending_amount, order?.token?.decimals),
              true
            )} ${order?.token?.symbol}`}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" mt={0.5}>
          <Box display="flex">
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Limit" defaultMessage="Limit" />
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {`${
                order?.curr_min_order_value <= 0
                  ? "1"
                  : formatCurrency(order?.curr_min_order_value, true)
              } ${order?.fiat?.fiat_label} - ${formatCurrency(
                order?.curr_max_order_value,
                true
              )} ${order?.fiat?.fiat_label}`}
            </Typography>
          </Box>
          <Box display="flex">
            <LinearProgress
              value={orderProgress()}
              variant="determinate"
              color={theme.palette.mode === "light" ? "primary" : "secondary"}
              sx={{
                width: "50%",
                height: 5,
                alignSelf: "center",
                display: user.id === order?.user?._id ? "flex" : "none",
              }}
            />
            <Typography fontSize={11} ml={1} color={theme.palette.text.primary}>
              {orderProgress().toFixed(1)}%
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Typography fontSize={13} color={theme.palette.text.secondary} mr={1}>
          <FormattedMessage id="Fiat Amount" defaultMessage="Fiat Amount" />
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          color={theme.palette.text.primary}
        >
          {`${formatCurrency(
            order?.order_unit_price *
              fromWei(order?.pending_amount, order?.token?.decimals),
            true
          )} ${order?.fiat?.fiat}`}
        </Typography>
      </Box>

      <Box display="flex" mt={0.5}>
        <Typography fontSize={13} color={theme.palette.text.secondary} mr={1}>
          <FormattedMessage id="Chain" defaultMessage="Chain" />
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          color={theme.palette.text.primary}
        >
          {CHAIN_IDS_TO_DISPLAY_NAMES[order?.chainId]}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        mt={sm ? 2 : 1}
        id="payment"
      >
        {order?.payment_options?.map((paymentOption, index) => {
          return (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              mx={0.5}
              my={0.3}
            >
              <Box
                mr={0.3}
                height="17px"
                borderRadius={4}
                bgcolor={theme.palette.action.payment[paymentOption]}
                border={`2px solid ${theme.palette.action.payment[paymentOption]}`}
              ></Box>
              <Box
                px={1}
                py={0.5}
                mr={0.5}
                bgcolor={theme.palette.background.primary}
                color={theme.palette.text.primary}
                borderRadius="12px"
                border={`1px solid ${theme.palette.action.payment[paymentOption]}`}
                fontSize={sm ? 9 : 12}
                whiteSpace="nowrap"
              >
                {paymentOption?.toUpperCase()}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default OrderCard;
