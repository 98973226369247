import axios from "axios";
import { BASE_API_ENDPOINT, globalHeaders } from "./index";

export const getQuests = async (payload: any, authToken: string,account: string | undefined) => {
  try {

    if (!payload.page_number || !payload.quest_type) {
        return;
      }
    
      const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/quest-apis/v1/getAllQuests/${payload.quest_type}/${
        payload.page_number ? payload.page_number : 1
      }`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getQuests :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getUserQuest = async (payload: any, authToken: string,account: string | undefined) => {
  try {
    if (!payload.user_id || !payload.quest_type) {
      return;
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/quest-apis/v1/getQuests/${payload.user_id}/${payload.quest_type}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getUserQuests :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getRedeemHistory = async (page_number: number, authToken: string,account: string | undefined) => {
  try {
   
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/quest-apis/v1/redeemHistory/${page_number || 1}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getRedeemHistory :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const checkQuests = async (payload: any, authToken: string,account: string | undefined) => {
  try {
    if (!payload.user_id) {
      return;
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/quest-apis/v1/checkQuests/${payload.user_id}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("checkQuests :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const claimQuest = async (quest_id: string, authToken: string,account: string | undefined) => {
  try {
    if (!quest_id) {
      return;
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.put(
      `${BASE_API_ENDPOINT}/quest-apis/v1/claim/${quest_id}`,
      {},
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("claimQuest :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
export const redeem = async (payload:any, authToken: string,account: string | undefined) => {
  try {
    if ( !payload.points || !payload.account) {
      return;
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/quest-apis/v1/redeem`,
      payload,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("redeem :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
