import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  useTheme,
  Typography,
  Box,
  TextareaAutosize,
  Select,
  MenuItem,
  useMediaQuery,
  InputLabel,
  FormControl,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { Close } from "@mui/icons-material";
import {
  raiseDisputeCall,
  updateDisputeProofCall,
} from "../../../utils/httpCalls/disputeCalls";
import { useDispatch, useSelector } from "react-redux";
import { getUserTradeById } from "../../../actions/tradeActions";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  inputAreaContainer: {
    width: "100%",
    height: "auto",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    boxSizing: "border-box",
    borderRadius: 15,
    outline: "none",
    padding: 10,
    scrollPaddingBlock: 10,
    backgroundColor: theme.palette.background.highlight,
    color: theme.palette.text.primary,
  },
  username: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: { fontSize: 20 },
  },
  label: {
    color:
      theme.palette.mode === "light" ? "#919191" : theme.palette.text.primary,
    fontWeight: 500,
    [theme.breakpoints.down("md")]: { marginBottom: 2 },
  },
  labelDescription: {
    color:
      theme.palette.mode === "light" ? "#919191" : theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 12,
    textAlign: "center",
  },
  labelHint: {
    color:
      theme.palette.mode === "light" ? "#919191" : theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 14,
    marginTop: 2,
    marginBottom: 2,
    // textAlign: "center",
  },
  submitButton: {
    borderRadius: 10,
    width: 200,
    height: 45,
    backgroundColor: theme.palette.button.primary,
    padding: "8px 30px 8px 30px",
    color:
      theme.palette.mode === "light" ? "#ffffff" : theme.palette.text.primary,
    border: "none",
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  cancelButton: {
    borderRadius: 10,
    width: 200,
    height: 45,
    backgroundColor: theme.palette.button.secondary,
    padding: "8px 30px 8px 30px",
    color:
      theme.palette.mode === "light" ? theme.palette.text.primary : "#ffffff",
    border: `1px solid ${theme.palette.button.primary}`,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
}));

const DISPUTE_REASONS = [
  "I have paid, but the seller does not release the coin",
  "I paid too much",
  "The seller payment account is wrong, can't pay",
  "I paid the wrong person",
  "I paid for the part of the money, can't continue to pay",
  "Others",
];

const DisputeForm = ({ trade, isSeller, handleClose, isMoreProof }) => {
  const [formFields, setFormField] = useState({});
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const authToken = useSelector((state) => state?.user?.jwtToken);
  const account = useSelector((state) => state?.user?.account);
  const dispatch = useDispatch();

  const handleConfirm = useCallback(async () => {
    if (!authToken) {
      setErrors("Invalid auth token, please refresh and try again!");
      return;
    }

    if (!trade?._id) {
      setErrors("Invalid trade, please refresh and try again!");
      return;
    }

    if (!formFields.proof?.type?.includes("video")) {
      setErrors("Invalid proof file, please upload video only");
      return;
    }

    if (formFields.proof?.size > 50 * 1024 * 2024) {
      setErrors("Proof video must be less than 50MB");
      return;
    }

    const formData = new FormData();

    try {
      if (formFields?.reason) {
        formData.append("reason", formFields?.reason);
      }

      if (formFields?.description) {
        formData.append("description", formFields?.description);
      }

      if (formFields?.proof) {
        formData.append("proof_video", formFields?.proof);
      }

      formData.append("tradeId", trade?._id);
      formData.append("token", trade?.order?.token?._id);
      formData.append("fiat", trade?.order?.fiat?._id);
      formData.append("chain_id", trade?.order?.chainId);
    } catch (error) {
      console.log("dispute test ", { error });
    }

    setLoading(true);
    const disputeRes = await raiseDisputeCall(formData, account, authToken);

    console.log("dispute response ", disputeRes);
    setLoading(false);

    if (disputeRes.status !== 201) {
      setErrors(disputeRes?.message?.msg);
      return;
    }

    dispatch(getUserTradeById(trade?._id, account, authToken));

    handleClose();
  }, [loading, authToken, trade, isSeller, formFields]);

  const handleUploadMoreProofs = useCallback(
    async (e) => {
      if (!authToken) {
        return;
      }

      setErrors("");

      const formData = new FormData();

      if (!formFields?.proof) {
        setErrors("Please attatch video to upload");
        return;
      }

      if (!formFields.proof?.type?.includes("video")) {
        setErrors("Invalid proof file, please upload video only");
        return;
      }

      if (formFields.proof?.size > 50 * 1024 * 2024) {
        setErrors("Proof video must be less than 50MB");
        return;
      }

      if (formFields?.proof) {
        formData.append("proof_video", formFields?.proof);
      }

      setLoading(true);

      formData.append("tradeId", trade?._id);

      const fileType = "video";
      const userType = isSeller ? "seller" : "buyer";
      const response = await updateDisputeProofCall(
        formData,
        fileType,
        userType,
        account,
        authToken
      );

      setLoading(false);

      if (response?.status !== 201) {
        setErrors("Upload failed");
        return;
      }

      dispatch(getUserTradeById(trade?._id, account, authToken));
      handleClose();
    },
    [loading, formFields, errors, setErrors, trade, isSeller, authToken]
  );
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box style={{ position: "absolute", right: "15px", top: "15px" }}>
          <Close
            style={{ color: theme.palette.text.primary, cursor: "pointer" }}
            onClick={() => {
              handleClose();
            }}
          />
        </Box>
        <Box className="d-flex w-100 flex-column justify-content-center">
          <Typography
            variant="body2"
            textAlign="center"
            color="textSecondary"
            className={classes.username}
            fontWeight={600}
            fontSize={sm ? 20 : 24}
          >
            {!isMoreProof ? (
              <FormattedMessage
                id="Raise dispute"
                defaultMessage={"Raise dispute"}
              />
            ) : (
              <FormattedMessage
                id="Upload dispute proof"
                defaultMessage={"Upload dispute proof"}
              />
            )}
          </Typography>
          <span className={classes.labelDescription}>
            {!isMoreProof ? (
              <FormattedMessage
                id="Raise a dispute request with valid proof"
                defaultMessage={"Raise a dispute request with valid proof"}
              />
            ) : (
              <FormattedMessage
                id="Upload more proofs"
                defaultMessage={"Upload more proofs"}
              />
            )}
          </span>
        </Box>
      </Box>

      <Box pt={3} px={2} pb={2}>
        {!isMoreProof && (
          <div className="row mt-3">
            <div className="col-md-2 pb-2 pb-sm-0">
              <label htmlFor="inputEmail4" className={classes.label}>
                <FormattedMessage
                  id="Reason for dispute"
                  defaultMessage={"Reason for dispute"}
                />
                :
              </label>
            </div>

            <div className="col-md-10">
              <FormControl
                variant="outlined"
                margin={"1"}
                style={{ width: "100%" }}
              >
                <InputLabel id="raise-dispute-label">
                  <FormattedMessage id="Reason" defaultMessage={"Reason"} />
                </InputLabel>
                <Select
                  variant="outlined"
                  value={formFields?.reason}
                  label="Reason"
                  disableUnderline={true}
                  style={{
                    fontWeight: 600,
                    letterSpacing: 1,
                    width: sm ? "90%" : "80%",
                    color: theme.palette.text.primary,
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 350,
                        width: 250,
                        backgroundColor: theme.palette.dropdown.main,
                      },
                    },
                  }}
                  onChange={(e) => {
                    setFormField({ ...formFields, reason: e.target.value });
                  }}
                >
                  {DISPUTE_REASONS?.map((item, index) => (
                    <MenuItem
                      key={index}
                      // onClick={() => setToken(item?.symbol)}
                      value={item}
                    >
                      <div className="text-wrap">
                        {" "}
                        <FormattedMessage id={item} defaultMessage={item} />
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                color="red"
                fontSize={13}
                mt={0.2}
                style={{
                  wordBreak: "break-word",
                }}
              >
                {errors?.name}
              </Typography>
            </div>
          </div>
        )}

        {!isMoreProof && (
          <div className=" row mt-3">
            <div className="col-md-2 pb-2 pb-sm-0">
              <label htmlFor="inputEmail4" className={classes.label}>
                <FormattedMessage
                  id="Description"
                  defaultMessage={"Description"}
                />
                :
              </label>
            </div>
            <div className="col-md-10">
              <TextareaAutosize
                type="text"
                maxLength={1000}
                value={formFields?.description}
                onChange={(e) => {
                  setFormField({ ...formFields, description: e.target.value });
                }}
                placeholder="Enter your message for seller"
                style={{
                  width: sm ? "95%" : "80%",
                  minHeight: 120,
                  height: 120,
                  maxHeight: 800,
                  border:
                    theme.palette.mode === "light"
                      ? "1px solid #EAECEE"
                      : "1px solid #25284B",
                  boxSizing: "border-box",
                  borderRadius: 15,
                  outline: "none",
                  padding: 10,
                  backgroundColor: theme.palette.background.highlight,
                  color: theme.palette.text.primary,
                }}
              />
              <Typography
                color="red"
                fontSize={13}
                mt={0.2}
                style={{
                  wordBreak: "break-word",
                }}
              >
                {errors?.username}
              </Typography>
            </div>
          </div>
        )}

        <div className=" row mt-3">
          <div className="col-md-2 pb-2 pb-sm-0">
            <label htmlFor="inputEmail4" className={classes.label}>
              <FormattedMessage
                id="Upload Video Proof of Payment"
                defaultMessage={"Upload Video Proof of Payment"}
              />
              :
            </label>
            <span className={classes.labelDescription}>
              {/* Screenshots or video/audio recordings of payment and communication
              data should not exceed a total of files with total size of 50M */}
            </span>
          </div>

          <div className="col-md-8">
            {formFields?.proof && (
              <Box className={classes.inputAreaContainer}>
                <video width="100%" height={150} controls>
                  <source
                    src={URL.createObjectURL(formFields?.proof)}
                    type="video/mp4"
                  />
                </video>

                <Box textAlign={"center"} mt={1.5}>
                  <Box className={classes.labelDescription}>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        setFormField({
                          ...formFields,
                          proof: null,
                        });
                        setErrors("");
                      }}
                    >
                      <FormattedMessage id="Remove" defaultMessage={"Remove"} />
                    </span>
                  </Box>
                </Box>
              </Box>
            )}

            {!formFields?.proof && (
              <Box className={classes.inputAreaContainer}>
                <label
                  style={{
                    cursor: "pointer",
                    for: "fileSelection",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "auto",
                    paddingBottom: 10,
                  }}
                >
                  <Box>
                    <ImageIcon style={{ fontSize: 50 }} fontSize="large" />
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    justifyContent="flex-start"
                    width={"95%"}
                  >
                    <Box className={classes.labelDescription}>
                      {isSeller ? (
                        <FormattedMessage
                          id="Upload proof"
                          defaultMessage={"Upload proof"}
                        />
                      ) : (
                        <FormattedMessage
                          id="Upload proof of payment"
                          defaultMessage={"Upload proof of payment"}
                        />
                      )}

                      <span style={{ color: "#0C7ED0", marginLeft: 5 }}>
                        <FormattedMessage
                          id="Browse"
                          defaultMessage={"Browse"}
                        />
                      </span>
                    </Box>
                    <Box mt={2.5} className={classes.labelHint}>
                      1.{" "}
                      <FormattedMessage
                        id="Allowed file size less than 50 MB"
                        defaultMessage={"Allowed file size less than 50 MB"}
                      />
                    </Box>
                    <Box mt={0.5} className={classes.labelHint}>
                      2.{" "}
                      <FormattedMessage
                        id="Start video from your P2P order screen"
                        defaultMessage={
                          "Start video from your P2P order screen"
                        }
                      />
                      .
                    </Box>
                    <Box mt={0.5} className={classes.labelHint}>
                      3.{" "}
                      <FormattedMessage
                        id="Clear all applications running in the background"
                        defaultMessage={
                          "Clear all applications running in the background"
                        }
                      />
                      .
                    </Box>
                    <Box mt={0.5} className={classes.labelHint}>
                      4.{" "}
                      <FormattedMessage
                        id="Open the payment application"
                        defaultMessage={"Open the payment application"}
                      />
                      .
                    </Box>
                    <Box mt={0.5} className={classes.labelHint}>
                      5.{" "}
                      <FormattedMessage
                        id="Show your Name and ID clearly in the application"
                        defaultMessage={
                          "Show your Name and ID clearly in the application"
                        }
                      />
                      .
                    </Box>
                    <Box mt={0.5} className={classes.labelHint}>
                      6.{" "}
                      <FormattedMessage
                        id="Show the transaction history"
                        defaultMessage={"Show the transaction history"}
                      />
                      .
                    </Box>
                    {!isSeller && (
                      <Box mt={0.5} className={classes.labelHint}>
                        7.{" "}
                        <FormattedMessage
                          id="The following things should be clearly visible. -
                        Name and ID of the receiver. - Date and Time of the
                        transaction. - Amount of the transaction. - Transaction
                        Number"
                          defaultMessage={
                            "The following things should be clearly visible. -Name and ID of the receiver. - Date and Time of the transaction. - Amount of the transaction. - Transaction Number"
                          }
                        />
                        .
                      </Box>
                    )}
                  </Box>
                  <input
                    id="fileSelection"
                    type="file"
                    hidden
                    draggable={true}
                    accept="video/*"
                    onChange={(e) => {
                      setFormField({
                        ...formFields,
                        proof: e.target.files[0],
                      });
                    }}
                  />
                </label>
              </Box>
            )}

            <Box />
            <Typography
              color="red"
              fontSize={13}
              mt={0.2}
              style={{
                wordBreak: "break-word",
              }}
            >
              {errors}
            </Typography>
          </div>
        </div>

        <div className="text-center mt-4 ">
          <button
            className={classes.submitButton}
            disabled={loading}
            onClick={() =>
              isMoreProof ? handleUploadMoreProofs() : handleConfirm()
            }
          >
            {loading ? (
              <FormattedMessage
                id="Uploading..."
                defaultMessage={"Uploading..."}
              />
            ) : isMoreProof ? (
              <FormattedMessage id="Upload" defaultMessage={"Upload"} />
            ) : (
              <FormattedMessage
                id="Raise dispute"
                defaultMessage={"Raise dispute"}
              />
            )}
          </button>
          <button className={classes.cancelButton} onClick={handleClose}>
            <FormattedMessage id="Cancel" defaultMessage={"Cancel"} />
          </button>
        </div>
      </Box>
    </>
  );
};

export default DisputeForm;
