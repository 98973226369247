import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../actions/profileActions";
import { GET_FIATS, GET_PAYMENTS, GET_TOKENS } from "../actions/types";
import {
  FALLBACK_DEFAULT_CHAIN,
  GLOBAL_FIAT_LIST,
  GLOBAL_TOKEN_LIST,
} from "../constants";
import allPaymentOptions from "../constants/payment_options.json";

export function useFetchGlobalDataCallback(initHook = false): {
  fetchGlobalData: (chainId: number, userFiat: string) => {};
} {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: any) => state?.user);
  const userChainId = useSelector((state: any) => state?.user?.chainId);

  const GLOBAL_PAYMENT_OPTIONS: any = allPaymentOptions;

  const fetchGlobalData = useCallback(
    async (chainId: number, userFiat: string) => {
      try {
        // make api calls to fetch all required global data
        //1. handle for guest user
        //2. handle for logged in user
        //3. on account switch

        dispatch({
          type: GET_TOKENS,
          payload: GLOBAL_TOKEN_LIST[chainId ?? FALLBACK_DEFAULT_CHAIN],
        });

        dispatch({
          type: GET_FIATS,
          payload: GLOBAL_FIAT_LIST,
        });

        dispatch({
          type: GET_PAYMENTS,
          payload: [
            ...GLOBAL_PAYMENT_OPTIONS?.["INR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["USD"],
            ...GLOBAL_PAYMENT_OPTIONS?.["EUR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["VND"],
            ...GLOBAL_PAYMENT_OPTIONS?.["TRY"],
            ...GLOBAL_PAYMENT_OPTIONS?.["IDR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["NGN"],
            ...GLOBAL_PAYMENT_OPTIONS?.["RUB"],
            ...GLOBAL_PAYMENT_OPTIONS?.["CNY"],
            ...GLOBAL_PAYMENT_OPTIONS?.["JPY"],
            ...GLOBAL_PAYMENT_OPTIONS?.["ZAR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["VES"],
            ...GLOBAL_PAYMENT_OPTIONS?.["ARS"],
            ...GLOBAL_PAYMENT_OPTIONS?.["COP"],
            ...GLOBAL_PAYMENT_OPTIONS?.["AED"],
            ...GLOBAL_PAYMENT_OPTIONS?.["EGP"],
            ...GLOBAL_PAYMENT_OPTIONS?.["GBP"],
            ...GLOBAL_PAYMENT_OPTIONS?.["GHS"],
            ...GLOBAL_PAYMENT_OPTIONS?.["KZT"],
            ...GLOBAL_PAYMENT_OPTIONS?.["PHP"],
            ...GLOBAL_PAYMENT_OPTIONS?.["PKR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["BRL"],
            ...GLOBAL_PAYMENT_OPTIONS?.["SAR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["BDT"],
            ...GLOBAL_PAYMENT_OPTIONS?.["TWD"],
            ...GLOBAL_PAYMENT_OPTIONS?.["UAH"],
            ...GLOBAL_PAYMENT_OPTIONS?.["PEN"],
            ...GLOBAL_PAYMENT_OPTIONS?.["NZD"],
            ...GLOBAL_PAYMENT_OPTIONS?.["AUD"],

            ...GLOBAL_PAYMENT_OPTIONS?.["CAD"],
            ...GLOBAL_PAYMENT_OPTIONS?.["THB"],
            ...GLOBAL_PAYMENT_OPTIONS?.["XOF"],
            ...GLOBAL_PAYMENT_OPTIONS?.["XAF"],
            ...GLOBAL_PAYMENT_OPTIONS?.["UZS"],
            ...GLOBAL_PAYMENT_OPTIONS?.["UYU"],
            ...GLOBAL_PAYMENT_OPTIONS?.["QAR"],
            ...GLOBAL_PAYMENT_OPTIONS?.["HKD"],
            ...GLOBAL_PAYMENT_OPTIONS?.["MXN"],
            ...GLOBAL_PAYMENT_OPTIONS?.["CZK"],
            ...GLOBAL_PAYMENT_OPTIONS?.["RON"],
            ...GLOBAL_PAYMENT_OPTIONS?.["SGD"],
            ...GLOBAL_PAYMENT_OPTIONS?.["MYR"],
          ],
        });

        // console.log("fetching global data...", { chainId, userFiat });
        if (!chainId) {
          return;
        }
      } catch (error) { }
    },
    []
  );

  useEffect(() => {
    if (!initHook || !currentUser?.authenticated || !userChainId) {
      return;
    }

    dispatch(
      getUserProfile(currentUser?.account, userChainId, currentUser?.jwtToken)
    );
  }, [currentUser, userChainId]);

  return {
    fetchGlobalData: fetchGlobalData,
  };
}
