import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OrderTable from "./components/OrderTable";
import HowItWorks from "../../common/HowItWorks";
import { useDispatch, useSelector } from "react-redux";
import { getLatestOrders } from "../../actions/orderActions";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { useNavigate } from "react-router-dom";
import useBlockNumber from "../../hooks/useBlockNumber";
import OrderCardList from "./components/OrderCardsList";
import {
  DAPP_SUPPORTED_ON_CHAINS,
  FIAT_LOGO_PATH,
  GLOBAL_TOKEN_LIST,
  TOKEN_LOGO_PATH,
  TOKEN_SYMBOL_BASED_ON_CHAIN,
} from "../../constants";
import { Check, Search } from "@mui/icons-material";
import { useDebounce } from "hooks/useDebounce";
import UpdateNeededBanner from "common/UpdateNeededBanner";
import { FormattedMessage, useIntl } from "react-intl";
import { getOrdersAnalytics } from "utils/httpCalls";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url("images/tokens.png")`,
    background:
      theme.palette.mode === "light"
        ? `linear-gradient(180deg, #ffffff 0%, #d9e8fc 100%)`
        : `linear-gradient(180deg, #1E2142 0%, #1E2142 100%)`,

    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat,no-repeat",
    backgroundSize: "contain",
    height: "100%",
    width: "100%",
    paddingTop: "3%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mainHeading: {
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
    color: "#212121",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      color: "#212121",
    },
  },
  para: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#414141",
    textAlign: "center",
  },
  imgIcon: {
    width: 18,
  },
  buttonWrapper: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
  },
  buttonFirst: {
    width: "fit-content",
    color: "#212121",
    backgroundColor: "#eeeeee",
    padding: "12px 50px 12px 50px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonSecond: {
    width: "fit-content",
    color: "white",
    backgroundColor: "#6A55EA",
    padding: "12px 50px 12px 50px",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  filterCard: {
    marginTop: 30,
    marginBottom: 10,
    height: "100%",
    width: "80%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #eeeeee"
        : "1px solid #212446",
    maxWidth: 900,

    backgroundColor: theme.palette.background.primary,
    transition: "background-color 0.2s ease",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 20,
      marginBottom: 5,
    },
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: 36,
    letterSpacing: "0.02em",
    color: theme.palette.text.primary,
    transition: "color 0.2s ease",
    textAlign: "center",
    paddingTop: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  subTitle: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: 400,
    color: theme.palette.text.secondary,
    transition: "color 0.2s ease",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));

export default function Home({ }) {
  const classes = useStyles();
  const theme = useTheme();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const parsedQuery = useParsedQueryString();

  //state loading
  const { fiats, payments, orders, totalOrders } = store.order;
  const authenticatedUser = useSelector((state) => state?.user);
  const orderLoading = useSelector((state) => state?.order?.orderLoading);
  const userFiat = useSelector((state) => state?.user?.userFiat);

  const [pageNumber, setPageNumber] = useState(1);
  const [fiat, setFiat] = useState(
    authenticatedUser?.authenticated ? userFiat : "All"
  );
  const [token, setToken] = useState(
    parsedQuery?.token ? parsedQuery.token : "All"
  );
  const [payment, setPayment] = useState(["All"]);
  const [onlineOnly, setOnlineOnly] = useState(false);
  const [followingOnly, setFollowingOnly] = useState(false);
  const [selectedChain, setSelectedChain] = useState("All");
  const [fiatAmount, setFiatAmount] = useState("");
  const [analyticsData, setAnalyticsData] = useState({
    totalOrders: 0,
    totalVolume: 0,
  });
  const debouncedFiatAmount = useDebounce(fiatAmount, 500);
  const [sortBy, setSortBy] = useState({
    by: "order_unit_price",
    order: 1,
    name: "price_ascending",
  });
  const [fiatInput, setFiatInput] = useState("");
  const [paymentInput, setPaymentInput] = useState("");

  const tokensList = useMemo(() => {
    let tokens = [];
    for (let chain of DAPP_SUPPORTED_ON_CHAINS) {
      tokens = tokens.concat(GLOBAL_TOKEN_LIST[chain]);
    }

    if (selectedChain !== "All") {
      tokens = tokens.filter((token) => token.chainId === selectedChain);
    }

    return tokens;
  }, [selectedChain]);

  const containsText = (text, paymentInput) =>
    text.toLowerCase().indexOf(paymentInput.toLowerCase()) > -1;

  const paymentOptions = useMemo(() => {
    if (payments?.length) {
      return payments?.filter((option) => {
        return (
          option?.fiat === fiat && containsText(option?.provider, paymentInput)
        );
      });
    }

    return [];
  }, [paymentInput, payments, fiat]);

  const fiatOptions = useMemo(() => {
    if (fiats?.length) {
      return fiats?.filter((option) => containsText(option?.fiat, fiatInput));
    }

    return [];
  }, [fiatInput, fiats]);

  const formattedNumber = (number, decimals) => {
    const numberArray = number.toString().split(".");
    const integer = numberArray?.[0];
    const decimal = numberArray?.[1];

    const formattedNumber = integer
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^0+(?=\d)/, "");

    return decimal !== undefined
      ? `${formattedNumber}.${decimals ? decimal.slice(0, decimals) : decimal}`
      : formattedNumber;
  };

  const navigate = useNavigate();
  const intl = useIntl();

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const orderType = useMemo(() => {
    const type = parsedQuery.type;

    if (type) {
      localStorage.currentTab = type;
    }

    return type || localStorage.currentTab || "buy";
  }, [parsedQuery]);

  useEffect(() => {
    setPageNumber(1);
    orderType == "buy"
      ? setSortBy({
        by: "order_unit_price",
        order: 1,
        name: "price_ascending",
      })
      : setSortBy({
        by: "order_unit_price",
        order: -1,
        name: "price_descending",
      });
  }, [orderType]);

  useEffect(() => {
    if (!authenticatedUser?.authenticated || !userFiat) {
      return;
    }

    setFiat(authenticatedUser?.authenticated ? userFiat : "All");
  }, [userFiat, authenticatedUser]);

  const selectedUserFiatObject = useMemo(() => {
    const fiatObject = fiats?.find((item) => item?.fiat === fiat);
    if (!fiatObject) {
      return { _id: null };
    }
    return fiatObject;
  }, [fiats, userFiat, fiat]);

  const filter = useMemo(() => {
    const filterObject = {
      order_type: orderType === "sell" ? "buy" : "sell",
      fiat: fiat === "All" ? null : selectedUserFiatObject?._id,
      token: token !== "All" ? token : null,
      chainId: selectedChain === "All" ? null : selectedChain,
      online_only: onlineOnly,
      payment_option: payment[0] === "All" ? [] : payment,
      fiatAmount: debouncedFiatAmount,
      sortBy: sortBy,
      following_only: followingOnly,
    };

    return filterObject;
  }, [
    orderType,
    selectedUserFiatObject,
    token,
    selectedChain,
    payment,
    onlineOnly,
    fiat,
    debouncedFiatAmount,
    sortBy,
    followingOnly,
  ]);

  const blockNumber = useBlockNumber();
  useEffect(() => {
    if (!authenticatedUser?.jwtToken || !pageNumber) {
      return;
    }

    async function fetchData() {
      if (!filter) {
        return;
      }
      dispatch(
        getLatestOrders(
          pageNumber,
          filter,
          authenticatedUser?.account,
          authenticatedUser?.jwtToken
        )
      );

      const result = await getOrdersAnalytics();
      if (result?.status == 200) {
        setAnalyticsData(result?.data);
      }
    }
    fetchData();
  }, [
    filter,
    pageNumber,
    authenticatedUser?.account,
    authenticatedUser?.jwtToken,
    blockNumber,
  ]);

  const handlePageChange = useCallback(
    (_page) => {
      setPageNumber(_page);
    },
    [setPageNumber]
  );

  const handlePaymentChange = useCallback(
    (e) => {
      let paymentMethods = e.target.value;

      if (
        !paymentMethods.length ||
        (!payment.includes("All") && paymentMethods.includes("All"))
      ) {
        paymentMethods = ["All"];
      }

      if (paymentMethods.length > 1 && paymentMethods.includes("All")) {
        paymentMethods = paymentMethods.filter((item) => item !== "All");
      }

      setPaymentInput("");
      setPayment(paymentMethods);
    },
    [payment, setPaymentInput, setPayment]
  );

  const handleFiatUpdate = useCallback(
    (selectedFiatItem) => {
      {
        // reset filter
        setFiatInput("");
        setToken("All");
        setPayment(["All"]);
        setFiat(selectedFiatItem);
      }
    },
    [setFiatInput, setToken, setPayment, setFiat]
  );

  const sortByItems = [
    {
      key: (
        <FormattedMessage
          id="Price Descending"
          defaultMessage="Price Descending"
        />
      ),
      value: "order_unit_price",
      sortBy: { by: "order_unit_price", order: -1, name: "price_descending" },
    },
    {
      key: (
        <FormattedMessage
          id="Price Ascending"
          defaultMessage="Price Ascending"
        />
      ),
      value: "order_unit_price",
      sortBy: { by: "order_unit_price", order: 1, name: "price_ascending" },
    },

    {
      key: <FormattedMessage id="Rating" defaultMessage="Rating" />,
      value: "rating",
      sortBy: { by: "rating", order: -1, name: "rating" },
    },
    {
      key: (
        <FormattedMessage id="Latest Orders" defaultMessage="Latest Orders" />
      ),
      value: "created_at",
      sortBy: { by: "created_at", order: -1, name: "latest_orders" },
    },
    {
      key: (
        <FormattedMessage
          id="Completed Trades"
          defaultMessage="Completed Trades"
        />
      ),
      value: "completed_trades",
      sortBy: { by: "completed_trades", order: -1, name: "completed_trades" },
    },
  ];

  return (
    <Box className={classes.background}>
      <Box>
        <h5 variant="body2" className={classes.pageTitle}>
          <FormattedMessage
            id="Decentralized P2P Exchange, powered by "
            defaultMessage="Decentralized P2P Exchange, powered by "
          />
          <a
            href="https://polkabridge.org"
            style={{ color: "rgb(224, 7, 125)", textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            PolkaBridge
          </a>
        </h5>
        <p className={classes.subTitle}>
          <FormattedMessage
            id="Experience first decentralized P2P trading with PolkaBridge"
            defaultMessage="Experience first decentralized P2P trading with PolkaBridge"
          />
        </p>

        <Box display="flex" justifyContent="center">
          <Box
            className={classes.filterCard}
            display="flex"
            justifyContent="center"
            width="fit-content"
            p={1}
          >
            {/* <Box className="d-flex flex-column justify-content-center align-content-center p-1 m-1 mx-2">
              <Typography
                textAlign="center"
                color={theme.palette.text.secondary}
                fontSize={sm ? 14 : 16}
                fontWeight={400}
              >
                <FormattedMessage
                  id="24h Trading Volume"
                  defaultMessage="24h Trading Volume"
                />
              </Typography>
              <Typography
                textAlign="center"
                color={theme.palette.action.green}
                fontSize={
                  sm ? (analyticsData?.totalVolume > 100000000 ? 13 : 16) : 18
                }
                fontWeight={500}
                pt={1}
              >
                {formattedNumber(analyticsData?.totalVolume, 2)} $
              </Typography>
            </Box> */}
            {/* <Box className="d-flex flex-column justify-content-center align-content-center p-1 m-1 mx-2">
              <Typography
                textAlign="center"
                color={theme.palette.text.secondary}
                fontSize={sm ? 14 : 16}
                fontWeight={400}
              >
                <FormattedMessage
                  id="24h Total Orders"
                  defaultMessage="24h Total Orders"
                />
              </Typography>
              <Typography
                textAlign="center"
                color={theme.palette.text.primary}
                fontSize={sm ? 16 : 18}
                fontWeight={500}
                pt={1}
              >
                {formattedNumber(analyticsData?.totalOrders, 0)}
              </Typography>
            </Box> */}
          </Box>
        </Box>

        <Container style={{ marginTop: 40 }}>
          <Box className={classes.buttonWrapper}>
            <Box
              className={classes.buttonFirst}
              style={{
                backgroundColor:
                  orderType === "buy"
                    ? theme.palette.action.green
                    : theme.palette.mode === "light"
                      ? theme.palette.button.secondary
                      : "#646892",
                color:
                  orderType === "buy" ? "white" : theme.palette.text.primary,
                transition: "background-color 0.2s ease",
              }}
              onClick={() => navigate("?type=buy")}
            >
              <FormattedMessage id="Buy" defaultMessage="Buy" />
            </Box>
            <Box
              className={classes.buttonSecond}
              style={{
                backgroundColor:
                  orderType === "sell"
                    ? theme.palette.action.red
                    : theme.palette.mode === "light"
                      ? theme.palette.button.secondary
                      : "#646892",
                color:
                  orderType === "sell" ? "white" : theme.palette.text.primary,
                transition: "background-color 0.2s ease",
              }}
              onClick={() => navigate("?type=sell")}
            >
              <FormattedMessage id="Sell" defaultMessage="Sell" />
            </Box>
          </Box>
          <UpdateNeededBanner />
          <div className="d-flex justify-content-center">
            <div className={classes.filterCard}>
              <Grid
                container
                display="flex"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  xs={6}
                  sm={4}
                  md={2}
                >
                  <FormControl
                    fullWidth
                    sx={{ m: sm ? "10px 15px" : "15px 20px" }}
                  >
                    <Box fontSize={12} color={theme.palette.text.secondary}>
                      <FormattedMessage id="Fiat" defaultMessage="Fiat" />
                    </Box>

                    <Select
                      variant="standard"
                      disableUnderline={true}
                      value={fiat}
                      sx={{
                        fontWeight: 600,
                        fontSize: sm && 14,
                        letterSpacing: 1,
                        color: theme.palette.text.primary,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 350,
                            width: 250,
                            backgroundColor: theme.palette.dropdown.main,
                          },
                        },
                      }}
                      onChange={(e) => handleFiatUpdate(e.target.value)}
                    >
                      <ListSubheader
                        style={{ backgroundColor: theme.palette.dropdown.main }}
                      >
                        <TextField
                          size="small"
                          autoFocus
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search fontSize="small" />
                              </InputAdornment>
                            ),
                            style: {
                              fontSize: sm && 14,
                              backgroundColor: theme.palette.dropdown.main,
                            },
                          }}
                          value={fiatInput}
                          onChange={(e) => setFiatInput(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      <MenuItem
                        value="All"
                        sx={{ fontSize: sm && 14 }}
                        dense={sm}
                      >
                        <FormattedMessage id="All" defaultMessage="All" />
                      </MenuItem>
                      {fiatOptions.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.fiat}
                          sx={{ fontSize: sm && 14 }}
                          dense={sm}
                        >
                          <img
                            src={FIAT_LOGO_PATH[item.fiat] ?? item?.flag_url}
                            style={{
                              height: sm ? 16 : 20,
                              marginRight: 5,
                            }}
                            alt=""
                          />
                          {item.fiat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  xs={6}
                  sm={4}
                  md={2}
                >
                  <Box
                    borderLeft={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                      }`}
                    height={sm ? 70 : 80}
                    position="absolute"
                    left={0}
                  />
                  <FormControl
                    fullWidth
                    sx={{ m: sm ? "10px 15px" : "15px 20px" }}
                  >
                    <Box fontSize={12} color={theme.palette.text.secondary}>
                      <FormattedMessage id="Token" defaultMessage="Token" />
                    </Box>

                    <Select
                      variant="standard"
                      disableUnderline={true}
                      value={token}
                      sx={{
                        fontWeight: 600,
                        letterSpacing: 1,
                        fontSize: sm && 14,
                        color: theme.palette.text.primary,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: theme.palette.dropdown.main,
                          },
                        },
                      }}
                      onChange={(e) => setToken(e.target.value)}
                    >
                      <MenuItem
                        value="All"
                        sx={{ fontSize: sm && 14 }}
                        dense={sm}
                      >
                        <FormattedMessage id="All" defaultMessage="All" />
                      </MenuItem>
                      {tokensList?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item._id}
                            sx={{
                              fontSize: sm && 14,
                            }}
                            dense={sm}
                          >
                            <img
                              src={TOKEN_LOGO_PATH[item.symbol] ?? item.logo}
                              style={{
                                height: sm ? 16 : 20,
                                marginRight: 8,
                              }}
                            />
                            {selectedChain === "All"
                              ? TOKEN_SYMBOL_BASED_ON_CHAIN[item.chainId][
                              item.symbol
                              ]
                              : item.symbol}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Box
                  borderBottom={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                    }`}
                  width="100%"
                  display={!sm && "none"}
                />

                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  xs={6}
                  sm={4}
                  md={2}
                >
                  <Box
                    borderLeft={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                      }`}
                    height={sm ? 70 : 80}
                    position="absolute"
                    left={0}
                    display={sm && "none"}
                  />

                  <FormControl
                    fullWidth
                    sx={{ m: sm ? "10px 15px" : "15px 20px" }}
                  >
                    <Box fontSize={12} color={theme.palette.text.secondary}>
                      <FormattedMessage id="Chain" defaultMessage="Chain" />
                    </Box>

                    <Select
                      variant="standard"
                      disableUnderline={true}
                      value={selectedChain}
                      sx={{
                        fontWeight: 600,
                        letterSpacing: 1,
                        fontSize: sm && 14,
                        color: theme.palette.text.primary,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: theme.palette.dropdown.main,
                          },
                        },
                      }}
                      onChange={(e) => {
                        setToken("All");
                        setSelectedChain(e.target.value);
                      }}
                    >
                      <MenuItem
                        value="All"
                        sx={{
                          fontSize: sm && 14,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        dense
                      >
                        <span style={{ fontSize: 14 }}>
                          <FormattedMessage id="All" defaultMessage="All" />
                        </span>
                      </MenuItem>
                      <MenuItem
                        value={DAPP_SUPPORTED_ON_CHAINS[0]}
                        sx={{
                          fontSize: sm && 14,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        dense
                      >
                        <span style={{ fontSize: 14 }}>Ethereum</span>
                        <img
                          className={classes.imgIcon}
                          src={
                            "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880"
                          }
                        />
                      </MenuItem>
                      <MenuItem
                        value={DAPP_SUPPORTED_ON_CHAINS[1]}
                        sx={{
                          fontSize: sm && 14,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        dense
                      >
                        <span style={{ fontSize: 14 }}>BSC</span>
                        <img
                          className={classes.imgIcon}
                          src={
                            "https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png?1600947313"
                          }
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Box
                  borderBottom={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                    }`}
                  width="100%"
                  display={(!md || sm) && "none"}
                />

                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  xs={6}
                  sm={4}
                  md={2}
                >
                  <Box
                    borderLeft={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                      }`}
                    height="100%"
                    position="absolute"
                    left={0}
                    display={!sm && md && "none"}
                  />
                  <FormControl
                    fullWidth
                    sx={{ m: sm ? "10px 15px" : "15px 20px" }}
                  >
                    <Box fontSize={12} color={theme.palette.text.secondary}>
                      <FormattedMessage
                        id="Payment Mode"
                        defaultMessage="Payment Mode"
                      />
                    </Box>
                    <Select
                      variant="standard"
                      disableUnderline={true}
                      value={
                        fiat === "All"
                          ? [
                            intl.formatMessage({
                              id: "Select Fiat",
                              defaultMessage: "Select Fiat",
                            }),
                          ]
                          : payment
                      }
                      renderValue={(selected) => {
                        const translatedValues = selected.map((value) =>
                          value == "All"
                            ? `${intl.formatMessage({
                              id: selected,
                              defaultMessage: selected,
                            })}`
                            : value
                        );

                        return translatedValues.join(", ");
                      }}
                      multiple
                      label="payment"
                      fullWidth
                      onChange={handlePaymentChange}
                      disabled={fiat === "All"}
                      sx={{
                        fontWeight: 600,
                        fontSize: sm && 14,
                        letterSpacing: 1,
                        color: theme.palette.text.primary,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 350,
                            width: 250,
                            backgroundColor: theme.palette.dropdown.main,
                          },
                        },
                      }}
                    >
                      <ListSubheader
                        style={{ backgroundColor: theme.palette.dropdown.main }}
                      >
                        <TextField
                          size="small"
                          autoFocus
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search fontSize="small" />
                              </InputAdornment>
                            ),
                            style: {
                              fontSize: sm && 14,
                            },
                          }}
                          value={paymentInput}
                          onChange={(e) => setPaymentInput(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>

                      <MenuItem
                        value={fiat === "All" ? "Select Fiat" : "All"}
                        sx={{
                          fontSize: sm && 14,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        dense={sm}
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            mr={0.8}
                            height="15px"
                            borderRadius={4}
                            bgcolor="green"
                            border="2px solid green"
                          />
                          <FormattedMessage id="All" defaultMessage="All" />
                        </Box>
                        <Check
                          style={{
                            fontSize: sm ? 16 : 20,
                            color: theme.palette.background.main,
                            display: !payment.includes("All") && "none",
                          }}
                        />
                      </MenuItem>
                      {paymentOptions.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item?.provider}
                          sx={{
                            fontSize: sm && 14,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          dense={sm}
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              mr={0.8}
                              height="15px"
                              borderRadius={4}
                              bgcolor={
                                theme.palette.action.payment[item?.provider]
                              }
                              border={`2px solid ${theme.palette.action.payment[item?.provider]
                                }`}
                            />
                            {item?.provider}
                          </Box>
                          <Check
                            style={{
                              fontSize: sm ? 16 : 20,
                              color: theme.palette.background.main,
                              display:
                                !payment.includes(item?.provider) && "none",
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Box
                  borderBottom={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                    }`}
                  width="100%"
                  display={!sm && "none"}
                />

                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  xs={6}
                  sm={4}
                  md={2}
                >
                  <Box
                    borderLeft={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                      }`}
                    height={sm ? 70 : 80}
                    position="absolute"
                    left={0}
                    display={sm && "none"}
                  />

                  <FormControl
                    fullWidth
                    sx={{ m: sm ? "10px 15px" : "15px 20px" }}
                  >
                    <Box fontSize={12} color={theme.palette.text.secondary}>
                      <FormattedMessage id="Sort" defaultMessage="Sort" />
                    </Box>

                    <Select
                      variant="standard"
                      disableUnderline={true}
                      value={sortBy?.name}
                      label="Sort"
                      style={{
                        fontWeight: 600,
                        letterSpacing: 1,
                        fontSize: sm && 14,
                        color: theme.palette.text.primary,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: theme.palette.dropdown.main,
                          },
                        },
                      }}
                    >
                      {sortByItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item?.sortBy?.name}
                          sx={{ fontSize: sm && 13 }}
                          onClick={() => setSortBy(item?.sortBy)}
                        >
                          {item?.key}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  xs={6}
                  sm={4}
                  md={2}
                >
                  <Box
                    borderLeft={`1px solid ${theme.palette.mode === "light" ? "#EAECEE" : "#1E2142"
                      }`}
                    height={sm ? 70 : 80}
                    position="absolute"
                    left={0}
                    display={!sm && "none"}
                  />
                  <FormControl
                    fullWidth
                    sx={{ m: sm ? "10px 15px" : "15px 20px" }}
                  >
                    <Box fontSize={12} color={theme.palette.text.secondary}>
                      {orderType === "buy" ? (
                        <FormattedMessage
                          id="Buy Amount"
                          defaultMessage="Buy Amount"
                        />
                      ) : (
                        <FormattedMessage
                          id="Sell Amount"
                          defaultMessage="Sell Amount"
                        />
                      )}
                    </Box>
                    <TextField
                      variant="outlined"
                      value={formattedNumber(fiatAmount, 4)}
                      size="small"
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/,/g, "")
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*)\./g, "$1");

                        setFiatAmount(e.target.value);
                      }}
                      placeholder={
                        fiat === "All"
                          ? `${intl.formatMessage({
                            id: "Select Fiat",
                            defaultMessage: "Select Fiat",
                          })}`
                          : token === "All"
                            ? `${intl.formatMessage({
                              id: "Select Token",
                              defaultMessage: "Select Token",
                            })}`
                            : selectedUserFiatObject.fiat
                      }
                      disabled={
                        fiat === "All" || token === "All" ? true : false
                      }
                      inputProps={{
                        min: 0,
                        pattern: "[0-9]*",
                      }}
                      InputProps={{
                        style: {
                          height: 32,
                          fontSize: 14,
                        },
                      }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </Box>
      <Container>
        {!md ? (
          <OrderTable
            orders={orders}
            page={pageNumber}
            loading={orderLoading}
            onlineOnly={onlineOnly}
            setOnlineOnly={setOnlineOnly}
            followingOnly={followingOnly}
            setFollowingOnly={setFollowingOnly}
            changePage={(_page) => handlePageChange(_page)}
            totalPages={Math.ceil(totalOrders / 10)}
          />
        ) : (
          <OrderCardList
            orders={orders}
            page={pageNumber}
            loading={orderLoading}
            onlineOnly={onlineOnly}
            setOnlineOnly={setOnlineOnly}
            followingOnly={followingOnly}
            setFollowingOnly={setFollowingOnly}
            changePage={(_page) => handlePageChange(_page)}
            totalPages={Math.ceil(totalOrders / 10)}
          />
        )}
      </Container>
      <Container>
        <HowItWorks />
      </Container>
    </Box>
  );
}
