import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GLOBAL_TOKEN_LIST, TOKEN_LOGO_PATH } from "../../constants";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
// import theme from "theme";

const TransactionFilter = ({ filter, setFilter }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const intl = useIntl();
  const [token, setToken] = React.useState("all");
  const [chain, setChain] = React.useState("all");
  const [status, setStatus] = React.useState("all");
  const [orderType, setOrderType] = React.useState("all");
  const [dateRange, setDateRange] = React.useState("all");

  const applyFilter = () => {
    const updatedFilter = {
      token: token === "all" ? null : token,
      chain: chain === "all" ? null : chain,
      status: status === "all" ? null : status,
      orderType: orderType === "all" ? null : orderType,
      dateRange: dateRange === "all" ? null : dateRange,
    };

    if (
      updatedFilter.token === filter.token &&
      updatedFilter.chain === filter.chain &&
      updatedFilter.status === filter.status &&
      updatedFilter.orderType === filter.orderType &&
      updatedFilter.dateRange === filter.dateRange
    ) {
      return;
    }

    setIsFilterApplied(true);
    setFilter(updatedFilter);
  };

  const resetFilter = () => {
    setIsFilterApplied(false);
    setToken("all");
    setChain("all");
    setStatus("all");
    setOrderType("all");
    setDateRange("all");

    setFilter({
      token: null,
      chain: null,
      status: null,
      orderType: null,
      dateRange: null,
    });
  };

  const tokens = React.useMemo(() => {
    const allTokens = [...GLOBAL_TOKEN_LIST[5], ...GLOBAL_TOKEN_LIST[97]];
    const uniqueTokens = allTokens.filter(
      (v, i, a) => a.findIndex((t) => t.symbol === v.symbol) === i
    );

    return [
      { name: "All", value: "all", image: "" },
      ...new Set(
        uniqueTokens.map((token) => ({
          name: token.symbol,
          value: token.symbol,
          image: TOKEN_LOGO_PATH[token.symbol] || token.logo,
        }))
      ),
    ];
  }, []);

  const chains = [
    { name: "All", value: "all", image: "" },
    {
      name: "Ethereum",
      value: 5,
      image:
        "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "BSC",
      value: 97,
      image:
        "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615",
    },
  ];

  const statii = [
    { name: "All", value: "all", image: "" },
    { name: "Processing", value: "pending", image: "" },
    { name: "Completed", value: "completed", image: "" },
    { name: "Cancelled", value: "cancelled", image: "" },
  ];

  const orderTypes = [
    { name: "All", value: "all", image: "" },
    { name: "Buy", value: "buy", image: "" },
    { name: "Sell", value: "sell", image: "" },
  ];

  const dateRanges = [
    {
      name: "All",
      value: "all",
      image: "",
    },
    {
      name: `1 ${intl.formatMessage({
        id: "Day",
        defaultMessage: "Day",
      })}`,
      value: new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .split("T")[0],
      image: "",
    },
    {
      name: `1 ${intl.formatMessage({
        id: "Week",
        defaultMessage: "Week",
      })}`,
      value: new Date(new Date().setDate(new Date().getDate() - 7))
        .toISOString()
        .split("T")[0],
      image: "",
    },
    {
      name: `1 ${intl.formatMessage({
        id: "Month",
        defaultMessage: "Month",
      })}`,
      value: new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .split("T")[0],
      image: "",
    },
    {
      name: `3 ${intl.formatMessage({
        id: "Months",
        defaultMessage: "Months",
      })}`,
      value: new Date(new Date().setDate(new Date().getDate() - 90))
        .toISOString()
        .split("T")[0],
      image: "",
    },
    {
      name: `6 ${intl.formatMessage({
        id: "Months",
        defaultMessage: "Months",
      })}`,
      value: new Date(new Date().setDate(new Date().getDate() - 180))
        .toISOString()
        .split("T")[0],
      image: "",
    },
  ];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Grid
        container
        borderRadius={3}
        border={
          theme.palette.mode === "light"
            ? `1px solid #eeeeee`
            : "1px solid #25284B"
        }
        bgcolor={theme.palette.background.primary}
        p={sm ? "10px 5px" : "15px 8px"}
        mb={1}
      >
        <DropdownFilter
          items={tokens}
          label="Tokens"
          value={token}
          setValue={setToken}
        />
        <DropdownFilter
          items={chains}
          label="Chain"
          value={chain}
          setValue={setChain}
        />
        <DropdownFilter
          items={statii}
          label="Status"
          value={status}
          setValue={setStatus}
        />
        <DropdownFilter
          items={orderTypes}
          label="Order Type"
          value={orderType}
          setValue={setOrderType}
        />
        <DropdownFilter
          items={dateRanges}
          label="Date Range"
          value={dateRange}
          setValue={setDateRange}
        />
        <Grid
          item
          xs={6}
          sm={9}
          mb={1}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button sx={{ mx: 1 }} variant="contained" onClick={applyFilter}>
            <FormattedMessage id="Apply" defaultMessage="Apply" />
          </Button>
          <Button
            variant="outlined"
            sx={{
              mx: 1,
              display: !isFilterApplied && "none",
            }}
            onClick={resetFilter}
          >
            <FormattedMessage id="Clear" defaultMessage="Clear" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const DropdownFilter = ({ items, label, value, setValue }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      item
      xs={6}
      sm={3}
      mb={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <FormControl
        size="small"
        fullWidth
        sx={{ m: sm ? "0px 8px" : "0px 10px" }}
      >
        <Box fontSize={12} color={theme.palette.text.secondary}>
          <FormattedMessage id={label} defaultMessage={label} />
        </Box>
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 350,
                backgroundColor: theme.palette.dropdown.main,
              },
            },
          }}
        >
          {items.map((item, index) => {
            return (
              <MenuItem value={item.value} dense={sm} key={index}>
                <Box display="flex" alignItems="center">
                  {item.image && (
                    <img
                      src={item.image}
                      style={{
                        borderRadius: "100%",
                        width: sm ? 18 : 20,
                        height: sm ? 18 : 20,
                        marginRight: 6,
                      }}
                    />
                  )}
                  <Typography fontSize={sm && 14}>
                    <FormattedMessage
                      id={item.name}
                      defaultMessage={item.name}
                    />
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default TransactionFilter;
