import {
  GET_HISTORY,
  GET_TOTAL_TRANSACTIONS,
  SET_HISTORY_LOADING,
  SET_TRANSACTION_FETCH_ERROR,
} from "../actions/types";

const initalState = {
  transactions: [],
  totalTransactions: 0,
  txnsLoading: false,
  txnFetchError: null,
};

export default function historyReducer(state = initalState, action) {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
        transactions: [...action.payload],
        txnFetchError: null,
      };
    case GET_TOTAL_TRANSACTIONS:
      return {
        ...state,
        totalTransactions: action.payload,
      };
    case SET_HISTORY_LOADING:
      return {
        ...state,
        txnsLoading: action.payload,
      };
    case SET_TRANSACTION_FETCH_ERROR: {
      return {
        ...state,
        txnFetchError: action.payload,
      };
    }
    default:
      return state;
  }
}
