import { Box, Stack, Switch, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Pagination from "../../../common/Pagination";
import OrderCard from "./OrderCard";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 10,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
    padding: 5,
    marginTop: 2,
    position: "relative",
    minHeight: "50vh",
    display: "flex",
    flexDirection: "column",
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)",
    borderRadius: 20,
    opacity: "0.6",
    zIndex: 10,
  },
  loaded: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
}));

const OrderCardList = ({
  orders,
  page,
  loading,
  changePage,
  totalPages,
  onlineOnly,
  setOnlineOnly,
  followingOnly,
  setFollowingOnly,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.tableCard}>
        <h5 className={classes.title}>
          <FormattedMessage
            id="Market Open Orders"
            defaultMessage="Market Open Orders"
          />
        </h5>
        <Box display="flex" justifyContent="center">
          <Box display="flex" justifyContent="center" my={1}>
            <Stack
              alignItems="center"
              spacing={1}
              direction="row"
              py={1}
              px={1.5}
              mr={0.5}
              width="fit-content"
              bgcolor={theme.palette.background.primary}
              border={`2px solid ${theme.palette.text.secondary}`}
              borderRadius={2}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                color={theme.palette.text.primary}
              >
                <FormattedMessage id="Online" defaultMessage="Online" />
              </Typography>
              <Switch
                size="small"
                checked={onlineOnly}
                onChange={(e) => setOnlineOnly(e.target.checked)}
              />
            </Stack>
          </Box>
          <Box display="flex" justifyContent="center" my={1}>
            <Stack
              alignItems="center"
              spacing={1}
              direction="row"
              py={1}
              px={1.4}
              ml={0.5}
              width="fit-content"
              bgcolor={theme.palette.background.primary}
              border={`2px solid ${theme.palette.text.secondary}`}
              borderRadius={2}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                color={theme.palette.text.primary}
              >
                <FormattedMessage id="Following" defaultMessage="Following" />
              </Typography>
              <Switch
                size="small"
                checked={followingOnly}
                onChange={(e) => setFollowingOnly(e.target.checked)}
              />
            </Stack>
          </Box>
        </Box>

        {loading && (
          <Box className={classes.loading}>
            <img src="/table_loader.gif" height={80} width={80} />
          </Box>
        )}
        <Box mt={1}>
          {orders.map((order, index) => {
            return (
              <React.Fragment key={order._id}>
                <OrderCard order={order} index={index} />
              </React.Fragment>
            );
          })}
        </Box>
        {totalPages > 1 && (
          <Pagination
            page={page}
            changePage={changePage}
            totalPages={totalPages}
          />
        )}
      </Box>
    </>
  );
};

export default OrderCardList;
