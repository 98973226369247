const PORTUGUESE = {
  Mode: "Modo",
  Feedback: "Opinião",
  Home: "Lar",
  "Create Order": "Criar ordem",
  "My Orders": "minhas ordens",
  Wallet: "Carteira",
  Profile: "Perfil",
  Leaderboard: "Entre os melhores",
  Faucet: "Torneira",
  Banned: "Banido",
  "This account has been banned. If this is unexpected, please contact":
    "Esta conta foi proibida. Se isso for inesperado, entre em contato",
  footermessage:
    "Junte -se a 100k+ pessoas trabalhando juntas para tornar o mundo um lugar melhor.",
  "Copyright 2023 By": "Copyright 2023 por",
  "All Rights Reserved": "Todos os direitos reservados",
  "How P2P Works": "Como funciona o P2P",
  home_howitwork:
    "O Polkabridge P2P ajuda a converter seu dinheiro para criptografia instantaneamente, onde o Polkabridge atua como uma garantia para a segurança da transação.",
  Buy: "Comprar",
  Sell: "Vender",
  "STEP 1": "PASSO 1",
  buy_step1:
    "Escolha o anúncio com o preço desejado e o método de pagamento. Digite sua quantidade de compra e valor da transação para concluir o pedido.",
  "Deposit tokens into": "Depositar tokens em",
  wallet: "carteira",
  "and create orders": "e criar pedidos",
  "STEP 2": "PASSO 2",
  buy_step2:
    "Conclua seus pagamentos com base nas informações fornecidas pelo vendedor.",
  step2_sell: "Confirme que você recebeu os pagamentos completos do comprador.",
  "STEP 3": "ETAPA 3",
  step31_buy:
    "Depois que o vendedor recebe o pagamento, ele liberará as moedas. Vá para o",
  step32_buy: "Para revisar as moedas que você recebeu.",
  step3_sell:
    "Libere moedas ao comprador somente depois de receber seus pagamentos integralmente.",
  "Before you start trading you need to update your profile.":
    "Antes de começar a negociar, você precisa atualizar seu perfil.",
  "Before your start trading please verify your phone":
    "Antes de começar a negociar, verifique seu telefone",
  "Please add a payment method to start buy crypto":
    "Adicione um método de pagamento para começar a comprar criptografia",
  "Update now": "Atualizar agora",
  "Verify now": "Verifique agora",
  "Add now": "Adicione agora",
  Logout: "Sair",
  "Switching...": "Switching ...",
  Switch: "Trocar",
  "Switch Network": "Switch Network",
  "Cancel dispute": "Cancelar disputa",
  canceldisputewarn:
    "Não haverá opção para levantar a disputa novamente. Por favor, NÃO cancele se o seu problema não for resolvido",
  "I confirm I have recieved the money from seller":
    "Eu confirmei que recebi o dinheiro do vendedor",
  "Cancelling...": "Cancelando ...",
  "Cancel Trade": "Cancelar o comércio",
  Warning: "Aviso",
  warningcancel:
    "Não haverá reembolso após o cancelamento do pedido. Por favor, não cancele se você já transferiu dinheiro.",
  tradewarning:
    "A negociação será suspensa durante o dia se cancelar 4 transações em um dia.",
  "I confirm I have not paid the payee.":
    "Confirme que não paguei o beneficiário.",
  "Please wait...": "Por favor, aguarde...",
  "Cancel this trade": "Cancelar esta negociação",
  "Confirm Transfer": "Confirme a transferência",
  confirmnote:
    "As transações P2P exigem que você faça pagamento ou transferência em um método suportado pela parte receptora. O Polkabridge não deduz automaticamente da sua conta.",
  "Transfer Amount": "Valor da transferência",
  "In exchange for": "Em troca de",
  "Upload proof of payment": "Carregar prova de pagamento",
  "Confirm the account of the receiving party":
    "Confirme a conta da parte receptora",
  "Payment QR code": "Código QR de pagamento",
  confirmnote2:
    "Preencha seus pedidos o mais rápido possível para que a parte receptora possa confirmar sua transferência. Selecione a conta da parte receptora. A confirmação de pagamento fraudulenta provavelmente fará com que sua conta seja congelada",
  "I didn't Pay Yet": "Eu não paguei ainda",
  "Transfer Completed": "Transferência concluída",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "Tokens depositar no cofre de Polkabridge e comércio de comércio livre",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Retire tokens do Vault Polkabridge com taxa",
  Amount: "Quantia",
  "Select Max": "Selecione máx",
  Balance: "Equilíbrio",
  "Available Amount": "Quantidade disponível",
  "Withdrawal Fee": "Taxa de retirada",
  "In order": "Em ordem",
  View: "Visualizar",
  "Send OTP": "Enviar OTP",
  "Verify Phone": "Verifique o telefone",
  "Verify Email": "Verificar e-mail",
  "Your phone number has been verified":
    "Seu número de telefone foi verificado",
  "Your email address has been verified":
    "seu endereço de email foi verificado",
  "6 digit otp has been sent on your phone":
    "6 dígitos OTP foi enviado no seu telefone",
  emailsent:
    "Código de verificação de 6 dígitos enviado no seu email. Se você não puder ver o e -mail em sua caixa de entrada, verifique sua pasta de spam",
  "Verify OTP": "Verifique o OTP",
  "OTP expired": "OTP expirou",
  "Resend OTP": "Reenvie otp",
  "Your OTP will expire in": "Seu OTP expirará em",
  "Confirm and Release": "Confirme e libere",
  Tips: "Pontas",
  dontnotcheckbuyerpayment:
    "Não apenas verifique a prova de pagamento do comprador. Certifique -se de fazer login na sua conta e verificar se o pagamento foi recebido.",
  ifstillprocessing:
    "Se o pagamento ainda estiver processando, aguarde até receber o pagamento em sua conta antes de liberar a criptografia!",
  donotacceptthridparty:
    "Não aceite o pagamento de uma conta de terceiros. Reembolsar o valor total imediatamente se você receber esse pagamento para evitar perdas financeiras causadas pelo estorno bancário depois de libertar a criptografia.",
  "Confirm User Account and Release Amount":
    "Confirme a conta de usuário e o valor da liberação",
  iconfirmpaymentcorrect:
    "Confirme que o pagamento foi creditado na minha conta e o saldo disponível está correto.",
  "Releasing...": "Lançando ...",
  Order: "Ordem",
  "is successfully completed": "está concluído com sucesso",
  Submitted: "Submetido",
  Submit: "Enviar",
  "Click below for processing": "Clique abaixo para processamento",
  "Waiting for confirmation": "Esperando por confirmação",
  "Please confirm the transaction into your metamask popup":
    "Por favor, confirme a transação no seu pop -up de metamask",
  "Withdrawal Request Submitted": "Solicitação de retirada enviada",
  "Withdrawal Address": "Endereço de retirada",
  Back: "Voltar",
  "Withdrawal History": "História da retirada",
  "Deposit Processing": "Processamento de depósitos",
  depositapproval:
    "A aprovação do depósito foi enviada, seja paciente. Não inicie mais depósitos enquanto o processo de depósito estiver em andamento.",
  "Deposits History": "Histórico de depósitos",
  "Transaction Submitted": "Transação enviada",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "A transação foi enviada e aguardando a confirmação da Blockchain.",
  "View Status": "Visualizar status",
  "Transaction Successful!": "Transação bem -sucedida!",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "Ótimo! A transação foi confirmada no blockchain com sucesso.",
  "Transaction Failed!": "Falha na transação!",
  "We have encountered an error in the transaction. Please try again.":
    "Encontramos um erro na transação. Por favor, tente novamente.",
  "Sign Message": "Mensagem de assinar",
  "Connect Wallet": "Conecte a carteira",
  "Waiting to connect": "Esperando para se conectar",
  "Confirm this connection in your wallet":
    "Confirme esta conexão em sua carteira",
  walletconnect_rule:
    "Ao conectar uma carteira, você concorda com os termos de serviço da Polkabridge P2P e consentimento à sua política de privacidade.",
  "Please sign this message requested in your metamask":
    "Assine esta mensagem solicitada em seu metamask",

  Metamask: "Metamask",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "Próxima versão do TestNet chegando!",
  "Follow Us": "Siga-nos",
  "Claim Faucet": "FAUCETA DE ALIMAÇÃO",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Tokens de teste de reivindicação para Goerli testNet ou BSC TestNet.",
  "Unauthorised Access!": "Acesso não autorizado!",
  "Please connect your wallet first to continue P2P trading.":
    "Por favor, conecte sua carteira primeiro para continuar a negociação P2P.",
  Price: "Preço",
  Limit: "Limite",
  Following: "Seguindo",
  Unfollow: "Deixar de seguir",
  Follow: "Seguir",
  Rating: "Avaliação",
  "Online Ads": "Anúncios online",
  "All Feedback": "Todo feedback",
  "Volume in": "Volume em",
  "Decentralized P2P Exchange, powered by ":
    "Troca de P2P descentralizada, alimentada por ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Experimente a primeira negociação P2P descentralizada com Polkabridge",
  Fiat: "Fiat",
  All: "Todos",
  Token: "Símbolo",
  "Payment Mode": "Modo de pagamento",
  orders: "ordens",
  completion: "conclusão",
  BUY: "COMPRAR",
  SELL: "VENDER",
  Verify: "Verificar",
  "Crypto Amount": "Quantidade de criptografia",
  "Fiat Amount": "Quantidade fiduciária",
  "Market Open Orders": "Ordens abertas de mercado",
  Advertisers: "Anunciantes",
  "Available | Limit": "Disponível | Limite",
  Payment: "Pagamento",
  Trade: "Troca",
  "Exclusive ranking for Polkabridge Traders":
    "Classificação exclusiva para comerciantes de Polkabridge",
  Volume: "Volume",
  Followers: "Seguidores",
  "Avg. Pay Time": "Avg. Tempo de pagamento",
  "Avg. Release Time": "Avg. Tempo de liberação",
  Username: "Nome de usuário",
  Filter: "Filtro",
  Clear: "Claro",
  User: "Do utilizador",
  "Last Trade Time": "Última hora de negociação",
  Action: "Ação",
  "Unauthorised Access": "Acesso não autorizado",
  Apply: "Aplicar",
  "Order No.": "Número do pedido",
  Coin: "Moeda",
  Type: "Tipo",
  Status: "Status",
  Time: "Tempo",
  Operation: "Operação",
  "Crypto amount": "Quantidade de criptografia",
  "Fiat amount": "Quantidade fiduciária",
  "Order type": "Tipo de pedido",
  "Order Details": "detalhes do pedido",
  "Order Number": "Número do pedido",
  "Time created": "Tempo criado",
  "Buy Order": "Pedido de compra",
  Executed: "Executado",
  at: "no",
  "Total Fiat Amount": "Quantidade total fiduciária",
  "Crypto Unit Price": "Preço unitário de criptografia",
  "Min Order Limit": "Min Limite de pedidos",
  "Max Order Limit": "Limite máximo de pedido",
  "Trade between": "Comércio entre",
  "Payment Time Limit": "Limite de tempo de pagamento",
  Remark: "Observação",
  "Need Help": "Preciso de ajuda",
  "Please wait": "Por favor, aguarde",
  "Cancel Order": "Cancelar pedido",
  "Go To Home": "Vá para casa",
  "Initiate New P2P Order": "Inicie o novo pedido P2P",
  "Set type and price": "Tipo e preço definidos",
  "Review Your Order": "Revise seu pedido",
  "I want to": "Eu quero",
  "Order Limit": "Limite de pedido",
  Min: "Min",
  Max: "Máx",
  "Payment Methods": "Métodos de Pagamento",
  "Add payments": "Adicionar pagamentos",
  "Current Price": "Preço atual",
  "Highest Price": "Preço mais alto",
  "Lowest Price": "Menor preço",
  "Please note that if you decide to": "Observe que se você decidir",
  SUBMIT: "ENVIAR",
  "the order, you wouldn't be able to": "a ordem, você não seria capaz de",
  EDIT: "EDITAR",
  "it afterwards.": "depois.",
  "Review New P2P Order": "Revise o novo pedido P2P",
  "Review your order": "Revise seu pedido",
  Remarks: "Comentários",
  Cancel: "Cancelar",
  "Submit Order": "Enviar pedido",
  "Order Submitted Successfully": "Pedido enviado com sucesso",
  "Order Type": "Tipo de pedido",
  Total: "Total",
  "Check your order": "Verifique seu pedido",
  Deposit: "Depósito",
  "to fulfill this sell order": "Para cumprir este pedido de venda",
  with: "com",
  "Time remaining": "Tempo restante",
  "Confirm Order Info": "Confirme as informações do pedido",
  "Quantity (After fee)": "Quantidade (após taxa)",
  "Chat with": "Conversar com",
  Uploading: "Enviando",
  "Upload Proof of Payment": "Carregar prova de pagamento",
  Browse: "Navegar",
  "Trade cancelled": "Comércio cancelado",
  "Transfer Payment to seller": "Transferir pagamento para o vendedor",
  "Seller released tokens": "O vendedor liberou tokens",
  "Pending seller confirmation": "Confirmação pendente do vendedor",
  Completed: "Concluído",
  "Token Deposited": "Token depositado",
  "Deposit tokens to": "Depositar tokens para",
  reserve: "reserva",
  "Buyer confirmed": "Comprador confirmou",
  "Pending buyer confirmation": "Confirmação pendente do comprador",
  "Order Summary": "resumo do pedido",
  Available: "Disponível",
  "payment method": "Forma de pagamento",
  "Seller's Message:": "Mensagem do vendedor:",
  "Buyer's Message:": "Mensagem do comprador:",
  "No message": "Nenhuma mensagem",
  "Terms and conditions": "Termos e Condições",
  ordersummary_rule:
    "Não envie pagamentos por meio de contas de terceiros, todos esses pagamentos terão que ir para disputas e serão reembolsados/cancelados. Por favor, não inclua palavras relacionadas a criptografia, como P2P, Polkabridge, USDT, ETH, BTC, etc.",
  "I want to buy for": "Eu quero comprar para",
  "I want to sell for": "Eu quero vender para",
  "I will get:": "Eu vou conseguir:",
  "I will send:": "Vou enviar:",
  Loading: "Carregando",
  "Order Processing": "processamento de pedido",
  "Reference message": "Mensagem de referência",
  "QR Code": "Código QR",
  "Payment confirmed": "Pagamento Confirmado",
  "I've Paid. Confirm": "Eu paguei. confirme",
  Cancelled: "Cancelado",
  Name: "Nome",
  Email: "E-mail",
  Quantity: "Quantidade",
  Your: "Seu",
  "has been reserved in PolkaBridge vault":
    "foi reservado no Polkabridge Vault",
  "Token reserved": "Token reservado",
  "Waiting for buyer's confirmation": "Esperando pela confirmação do comprador",
  "Buyer confirmed payment": "O comprador confirmou o pagamento",
  "Buyer notified": "Comprador notificado",
  "Notify buyer": "Notificar o comprador",
  "Cancel trade": "Cancelar o comércio",
  "Dispute submitted": "Disputa enviada",
  Reason: "Razão",
  "Release tokens to buyer wallet": "Libere tokens para carteira de comprador",
  "Token released": "Token lançado",
  "Release tokens": "Libere tokens",
  "Your order is executed successfully.": "Seu pedido é executado com sucesso.",
  "My Profile": "Meu perfil",
  "Update your preference for smooth trading experience.":
    "Atualize sua preferência por uma experiência de negociação suave.",
  Menu: "Cardápio",
  Payments: "Pagamentos",
  "Transaction history": "Histórico de transações",
  Settings: "Configurações",
  Referral: "Referência",
  "Filter Order": "Pedido de filtro",
  TxnId: "Txnid",
  "Transaction not found": "Transação não encontrada",
  "Wallet unavailable": "Carteira indisponível",
  "Check explorer": "Verifique o Explorer",
  "Full Name": "Nome completo",
  Mobile: "Móvel",
  "Preferred Fiat": "Fiat preferido",
  "Edit profile": "Editar Perfil",
  "Upload Avatar": "Carregue o avatar",
  "Updating...": "Atualizando ...",
  "Update profile": "Atualizar perfil",
  "Are you sure, want to leave PolkaBridge?":
    "Tem certeza, quer deixar Polkabridge?",
  "Log out": "Sair",
  "Fiat Preference": "Preferência fiduciária",
  Referrals: "Referências",
  "Will be available soon.": "Estará disponível em breve.",
  "Payment Options": "Opções de pagamento",
  "Transaction History": "Histórico de transações",
  Fee: "Taxa",
  TxnID: "Txnid",
  "All payment methods were added":
    "Todos os métodos de pagamento foram adicionados",
  "Add Payment Method": "Adicionar método de pagamento",
  "Payment method": "Forma de pagamento",
  "QR Code (optional)": "Código QR (opcional)",
  "Upload QR Code": "Carregue o código QR",
  "Update details": "Atualizar detalhes",
  "Not Uploaded": "Não carregado",
  Edit: "Editar",
  "Update Payment": "Atualizar pagamento",
  Confirm: "confirme",
  "Depositing...": "Depositando ...",
  "Confirming...": "Confirmando ...",
  Withdraw: "Retirar",
  Locked: "Trancado",
  "P2P Wallet": "Carteira p2p",
  walletpage_message:
    "Gerencie ativos em sua plataforma P2P. Você pode depositar, retirar ativos para participar de transações no P2P",
  "Your Balances": "Seus saldos",
  "Buy Amount": "Valor de compra",
  "Sell Amount": "Valor de venda",
  "Trade(s)": "Comércio(s)",
  active: "ativo",
  "Executed at": "Executado em",
  Chain: "Cadeia",
  "My Ads": "Meus anúncios",
  Active: "Ativo",
  "Amount reversed by my Bank.": "Valor revertido pelo meu Banco.",
  "Transaction failed on my behalf": "A transação falhou em meu nome",
  "Seller received the amount successfully.":
    "O vendedor recebeu o valor com sucesso.",
  "Seller provided the necessary information to complete the payment.":
    "O vendedor forneceu as informações necessárias para concluir o pagamento.",
  "I sent the remaining amount.": "Eu enviei o valor restante.",
  "I raised the dispute by mistake.": "Levantei a disputa por engano.",
  "I received the tokens.": "Recebi os tokens.",
  Others: "Outros",
  Quest: "Busca",
  "Complete your quests and collect points.":
    "Conclua suas missões e colete pontos",
  "Total Points": "Total de pontos",
  Redeem: "Resgatar",
  "Resets in": "Redefinir",
  "Daily Quests": "Missões diárias",
  "Weekly Quests": "Missões semanais",
  Rules: "Regras",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1. Tarefa diária: a tarefa será atualizada diariamente às 00:00 UTC.",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2. Você precisa clicar em 'reivindicar' para obter seus pontos de tarefa. Se não os pontos não serão creditados em sua conta.",
  questrule3:
    "3. A recompensa total para a rodada 1 da Missão será de 60,000 PBR. Os pontos serão convertidos para o PBR correspondente, dependendo do número de pontos solicitados",
  "4. Redeem points limit is 100 Points.":
    "4. O limite de pontos de resgate é de 100 pontos.",
  questrule5:
    "5. Após o término da rodada 1 da Missão, o usuário reivindicará sua recompensa. As recompensas serão enviadas aos usuários em até 7 dias úteis",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge reserva -se o direito de interpretação final para o evento.",
  Points: "Pontos",
  Claiming: "Reivindicação",
  Claimed: "Reivindicado",
  Claim: "Alegar",
  Action: "Ação",
  "Redeem Points": "Resgatar pontos",
  "1 Points = 1 PBR": "1 pontos = 1 PBR",
  "Redeem Points limit is 100 Points":
    "Limite de pontos de resgate é 100 pontos",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "Envie sua solicitação de resgate Admin revisará e transferirá o token em sua carteira.",
  Tokens: "fichas",
  "Wallet Address": "Endereço da carteira",
  "Confirm your wallet address and submit your request.":
    "Confirme o endereço da sua carteira e envie sua solicitação.",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "Concluiu pelo menos 20 ordens na cadeia Ethereum em 7 dias consecutivos.",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "Concluiu pelo menos 20 ordens na cadeia BSC em 7 dias consecutivos.",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "Depositar e retirar -se dentro de 7 dias consecutivos em ambas as cadeias.",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "Comércio completo com todos os tokens (PBR-ETH, PBR-BSC, USDT-ETH, USDT-BSC, USDC, DAI, ETH, BUSD, BNB) em 7 dias.",
  "Give at least 10 feedback .": "Dê pelo menos 10 comentários.",
  "Create at least 1 Ad on your FIAT currency":
    "Crie pelo menos 1 anúncio em sua moeda fiduciária",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Complete pelo menos 1 ordem de COMPRA e 1 ordem de VENDA na Ethereum Chain",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "Complete pelo menos 1 ordem de COMPRA e 1 ordem de VENDA na BSC Chain",

  "Deposit or withdraw at least 1 token on BSC chain":
    "Depositar ou retirar pelo menos 1 token na cadeia BSC",
  "Deposit at least 0.1 ETH or 0.1 BNB.":
    "Deposite pelo menos 0,1 ETH ou 0,1 Bnb.",

  "Waiting for seller to release tokens":
    "Aguardando o vendedor liberar os tokens",
  "Transfer the funds to seller account provided below":
    "Transfira os fundos para a conta do vendedor fornecida abaixo",
  "Seller released tokens": "Tokens liberados pelo vendedor",
  "Waiting for confirmation": "Aguardando confirmação",
  "Dispute submitted": "Disputa enviada",
  Completed: "Concluída",
  "Your order is executed successfully": "Seu pedido foi executado com sucesso",
  Submit: "Enviar",
  "Cancel Dispute": "Cancelar Disputa",
  Cancelled: "Cancelada",
  "Reason:": "Razão",
  "Dispute raised": "Disputa levantada",
  "In reviewing": "Ao revisar",
  Resolved: "Resolvida",
  "Your Proofs": "suas provas",

  "Write a review": "Escreva uma crítica",
  "Transfer the funds to seller account provided below":
    "Transfira os fundos para a conta do vendedor fornecida abaixo",
  "Waiting for seller to release tokens":
    "Aguardando o vendedor liberar os tokens",
  "Dispute submitted": "Disputa enviada",
  "Your Proofs": "suas provas",
  "Seller's Proofs": "Provas do vendedor",
  "Raise dispute": "Levantar disputa",
  "Upload dispute proof": "Carregar prova de contestação",
  "Raise a dispute request with valid proof":
    "Faça uma solicitação de disputa com prova válida",
  "Upload more proofs": "Carregar mais provas",
  "Reason for dispute": "Motivo da disputa",
  Description: "Descrição",
  "Upload Video Proof of Payment": "Enviar comprovante de pagamento em vídeo",
  Remove: "Remover",
  "Drag and drop more proof of payment":
    "Arraste e solte mais comprovantes de pagamento",
  "Allowed file size less than 50 MB":
    "Tamanho de arquivo permitido inferior a 50 MB",
  "Start video from your P2P order screen":
    "Inicie o vídeo na tela do seu pedido P2P",
  "Clear all applications running in the background":
    "Limpe todos os aplicativos em execução em segundo plano",
  "Open the payment application": "Abra o aplicativo de pagamento",
  "Show your Name and ID clearly in the application":
    "Mostre seu Nome e ID claramente no aplicativo",
  "Show the transaction history": "Mostrar o histórico de transações",
  "The following things should be clearly visible. - Name and ID of the receiver. - Date and Time of the transaction. - Amount of the transaction. - Transaction Number":
    "As seguintes coisas devem estar claramente visíveis. - Nome e ID do destinatário. - Data e hora da transação. - Valor da transação. - Número da Transação",
  "Uploading...": "Enviando...",
  "I have paid, but the seller does not release the coin":
    "Eu paguei, mas o vendedor não libera a moeda",
  "Seller notified": "Vendedor notificado",
  "Notify Seller": "Notifique o vendedor",
  "Pending dispute": "Disputa pendente",
  "Raise dispute": "Levantar disputa",
  "I paid too much": "eu paguei demais",
  "The seller payment account is wrong, can't pay":
    "A conta de pagamento do vendedor está errada, não consigo pagar",
  "I paid the wrong person": "paguei a pessoa errada",
  "I paid for the part of the money, can't continue to pay":
    "Paguei a parte do dinheiro, não posso continuar pagando",
  Pending: "Pendente",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "Confirmo que meu problema foi resolvido e quero encerrar esta disputa.",

  "Upload proof": "Carregar prova",
  "Upload proof of payment": "Carregar comprovante de pagamento",
  "I confirm there is no problem and I want to cancel this transaction":
    "Confirmo que não há problema e desejo cancelar esta transaçãoy",
  warningcancel_seller:
    "Tem certeza de que deseja cancelar esta transação? Observe que a PolkaBridge não tratará de disputas pós-cancelamento se o comprador já tiver pago.", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?": "	Oi, como podemos ajudá -lo?",
  Support: "Apoiar",
  "Raise Ticket": "Aumentar o bilhete",
  "Watch Tutorial": "Veja o tutorial",
  "Video Tutorial": "Vídeo tutorial",
  Open: "Abrir",
  Official: "Oficial",
  "Email Us": "Envia-nos um email",
  Issues: "Problemas",
  Description: "Descrição",
  "Upload Proof": "Upload de prova",
  "Drag and drop the file, or": "Arraste e solte o arquivo, ou",
  "Enter the description": "Digite a descrição",
  General: "Em geral",
  "Deposit/Withdraw": "Depósito/retirada",
  Account: "Conta",
  Trading: "Negociação",
  Others: "Outros",
  Telegram: "Telegram",
  "Show Online Users": "Mostrar usuários on-line",
  "Tokens Locked": "Tokens trancados",
  "Payment Done": "Pagamento efetuado",
  "In Dispute": "Em disputa",
  Date: "Data",
  Ascending: "Ascendente",
  Descending: "Ascendente",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "Seu ingresso foi enviado e será revisado por nossa equipe. O problema será resolvido dentro de 24 horas. Obrigado pela sua paciência.",
  "Ticket History": "História do ingresso",
  Date: "Data",
  "Ticket ID": "ID do ingresso",
  Issue: "Emitir",
  Reviewing: "Revendo",
  Resolved: "Resolvido",
  Pending: "Pendente",
  Authenticating: "Autenticando",
  "Admin Remark": "Comentário do administrador",
  Message: "Mensagem",
  "Price Ascending": "Preço ascendente",
  "Price Descending": "Preço descendo",
  "Latest Ads": "Últimos anúncios",
  "Latest Orders": "Pedidos mais recentes",
  "Completed Trades": "Negociações concluídas",
  "User Guide": "Guia de usuario",
  "Beginner's Guide": "Guia do iniciante",
  "Video Tutorial": "Vídeo tutorial",
  "P2P trading FAQ": "Perguntas frequentes sobre negociação P2P",
  "Admin Message": "Mensagem do administrador",
  "Ticket History": "Histórico de Ingressos",
  "Review Submitted Successfully": "Revisão enviada com sucesso",
  "Transaction Hash": "Hash de transação",
  "Ticket Details": "Detalhes dos ingressos",
  "Round 1 Ended": "Rodada 1 terminou",
  "Redeem your points before Round 2 starts":
    "Resgate seus pontos antes do início da segunda rodada",
  "Redeem History": "Resgatar história",
  "Redeem Time": "Resgatar tempo",
  Address: "Endereço",
  pending: "pendente",
  completed: "concluído",
  rejected: "rejeitado",
  "Redeemed At": "Resgatado em",
  "Redeem Detail": "Resgatar detalhes",
  "Transaction ID": "ID da transação",
  "Admin Remark": "Observação do administrador",
  Ended: "Terminou",
  rejected: "rejeitado",
  "Profile Tab": "Guia Perfil",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "Clique para atualizar seu perfil, como nome, ID de usuário, não, email, e -mail e, o mais importante, para adicionar suas opções de pagamento preferidas.",
  "Quest Tab": "Guia da missão",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "Clique para participar da campanha de missões em andamento e para reivindicar pontos para obter recompensas de $ PBR.",
  "LeaderBoard Tab": "Guia da tabela de classificação",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "Clique para ver a classificação exclusiva para os comerciantes da Polkabridge.",
  "Buy Vs Sell": "Comprar vs vender",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "Ao selecionar a opção Buy, você pode comprar criptografia com suas moedas fiduciárias preferidas.",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "Ao selecionar a opção de venda, você pode vender criptografia com suas moedas fiduciárias preferidas.",
  "Filter Tool": "Ferramenta de filtro",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "Clique para classificar anúncios por suas preferências ou use o filtro para configurar anúncios exibidos para você. As opções de filtro são baseadas na disponibilidade da sua região/moeda.",
  "Advertiser Info": "Informações do anunciante",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "Aqui você vê o perfil do comerciante, o status on -line, a taxa de conclusão, o número total de pedidos concluídos e o crachá do comerciante. Clique no nome do comerciante para visualizar mais informações.",
  "Price and Limits": "Preço e limites",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "Aqui você pode ver o preço de 1 moeda pela qual o usuário deseja negociar, sua quantidade de disponibilidade e a menor e maior quantidade que o usuário está disposto a negociar.",
  "Payment options": "Opções de pagamento",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "Aqui você pode ver o modo de pagamentos que o anunciante aceita negociar por tokens/fiat.",
  "Lowest Fees": "Taxas mais baixas",
  "Buy crypto with lowest fees ever!":
    "Compre criptografia com taxas mais baixas de todos os tempos!",
  "P2P Wallet": "Carteira p2p",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "Clique para verificar seu saldo e gerenciar depósitos e retiradas de seus ativos e histórico deles.",
  Next: "Próximo",
  Skip: "Pular",
  Close: "Fechar",
  "Create Order Tab": "Crie a guia Order",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "Por favor, clique no botão para criar um pedido de compra/venda usando suas moedas/tokens preferidos/modos de pagamento.",
  "Switch Network": "Switch Network",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "Nossa plataforma P2P foi projetada para suportar o Multichain, o que significa que você tem a flexibilidade de realizar negociações em qualquer cadeia de sua escolha.",
  "24h Trading Volume": "Volume de negociação 24h",
  "24h Total Orders": "24h Ordens totais",
  "Show Users Following": "Mostre os usuários seguintes",
  Following: "Seguindo",
  Online: "Trực tuyến",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "Por favor, resgate seus pontos antes de 24 de março, 00:00 UTC. Número mínimo de pontos para converter é de 100 pontos",
  "User's Statistics": "Estatísticas do usuário",
  Phone: "Telefone",
  Follower: "Seguidor",
  "All Trades": "Todas as negociações",
  "Total Buy Orders": "Total de pedidos de compra",
  "Total Sell Orders": "Pedidos de venda total",
  Share: "Compartilhar",
  "Reopen Ticket": "Reabrir o ingresso",
  Comment: "Comente",
  "Add your comment": "Adicione seu comentário",
  "Comment must be less than 5000 characters":
    "O comentário deve ser inferior a 5000 caracteres",
  "Comment is required": "Comentário é necessário",
  "Reopen Date": "Ngày mở lại",
  Proof: "Prova",
  "Share your P2P profile": "Compartilhe seu perfil P2P",
  Sort: "Organizar",
  "Select Token": "Selecione token",
  "Select Fiat": "Selecione Fiat",
  "Date Range": "Data de data",
  "Enter username": "Insira nome de usuário",
  "Trade Currency": "Moeda comercial",
  "Enter your message for seller": "Digite sua mensagem para o vendedor",
  "Deposit Token": "Token de depósito",
  "Withdraw Token": "Retirar o token",
  "Insufficient!": "Insuficiente!",
  Wait: "Espere",
  "Insufficient funds!": "Fundos insuficientes!",
  "Insufficient withdraw!": "Retiro insuficiente!",
  Approve: "Aprovar",
  "Approving...": "Aprovando ...",
  "Issues with Deposit?": "Problemas com depósito?",
  "All Networks": "Todas as redes",
  "Enter amount": "Insira o valor",
  "Enter price": "Insira o preço",
  "Select payment methods": "Selecione métodos de pagamento",
  "Enter order min and max limits": "Digite os limites de ordem min e máximo",
  "Enter valid min limit": "Insira o limite mínimo válido",
  "Max limit must be less than": "O limite máximo deve ser menor que",
  "Min limit must be less than": "O limite mínimo deve ser menor que",
  "Preview order": "Ordem de visualização",
  min: "min",
  Global: "Global",
  "Enter your name": "Digite seu nome",
  "Choose your username": "Escolha o seu nome de usuário",
  "Enter your email": "Digite seu e-mail",
  "Your Full Name": "Seu nome completo",
  "A/c Number": "Número A/C",
  "Account number": "Número de conta",
  "Bank Name": "Nome do banco",
  "IFSC Code": "Código IFSC",
  "Note (Optional)": "Nota (opcional)",
  "Additional Notes": "Notas Adicionais",
  "Phone Number": "Número de telefone",
  "Account phone number": "Número de telefone da conta",
  "Payment QR Code (Optional)": "Código QR de pagamento (opcional)",
  "Please enter your QR Code": "Por favor, insira seu código QR",
  Day: "Dia",
  Week: "Semana",
  Month: "Mês",
  Months: "Meses",
  hash: "cerquilha",
  "Enter txn hash": "Digite o hash txn",
  Success: "Sucesso",
  Failed: "Fracassado",
  Review: "Análise",
  Dropped: "Derrubado",
  LongPending: "Longpending",
  "Redeem Ended": "A resgate terminou",
  "Please enter your bank account number":
    "Por favor, insira o número da sua conta bancária",
  "Bank Name": "Nome do banco",
  "Enter the name of your bank": "Digite o nome do seu banco",
  "Account opening branch(Optional)": "Ramo de abertura da conta (opcional)",
  "Enter bank branch information": "Digite informações da agência bancária",
  "Paypal ID / Number": "PayPal ID / número",
  "Perfect Money ID / Number": "Id / número perfeito de dinheiro",
  "Bank Details (Optional)": "Detalhes bancários (opcional)",
  "Please enter your bank details":
    "Por favor, insira os detalhes do seu banco",
  "Country of Residence (Optional)": "País de residência (opcional)",
  "Please enter your country of residence":
    "Por favor, digite seu país de residência",
  "Username (Optional)": "Nome de usuário (opcional)",
  "Account Username": "Nome de usuário da conta",
  "Account Email": "Conta de e-mail",
  "Wallet ID": "ID da carteira",
  "Revtag (Optional)": "RevTag (opcional)",
  "Please enter your Revtag": "Por favor, digite seu Revtag",
  "Phone number (Optional)": "Número de telefone (opcional)",
  "Please enter payment details": "Por favor, insira detalhes de pagamento",
  "Account number (USD)": "Número da conta (USD)",
  "Account number (KHR)(Optional)": "Número da conta (KHR) (opcional)",
  "Payeer account number": "Número da conta Payeer",
  "Enter your Payeer account number": "Digite o número da sua conta Payeer",
  "Email (Optional)": "E-mail opcional)",
  "Account Email (Not required)": "E -mail da conta (não é necessário)",
  "Email or Username": "Email ou nome de usuário",
  "Account Email or Username": "Email de conta ou nome de usuário",
  "Full Name of Receiver": "Nome completo do receptor",
  "Receivers's Full Name": "Nome completo dos receptores",
  "Payment details (Optional)": "Detalhes do pagamento (opcional)",
  "ID Number": "Número de identidade",
  "Please enter your National ID Number":
    "Por favor, insira seu número de identificação nacional",
  "Account Type": "tipo de conta",
  "Enter your account type": "Digite seu tipo de conta",
  "Name and Lastname": "Nome e ultimo Nome",
  "Enter Name and Lastname": "Insira o nome e o último nome",
  "Full Name": "Nome completo",
  "Enter Full Name": "Digite o nome completo",
  Cashtag: "CashTag",
  "Please enter your Cashtag": "Por favor, insira sua fita Cashtag",
  "Full Name (Optional)": "Nome completo (opcional)",
  "ID Number (Optional)": "Número de identificação (opcional)",
  "Address (Optional)": "Endereço (Opcional)",
  "Residential Address": "Endereço residencial",
  "Mobile Number": "Número de telemóvel",
  "Enter your Mobile Number available for all networks":
    "Digite seu número de celular disponível para todas as redes",
  "Contact Number": "Número de contato",
  "Phone Number": "Número de telefone",
  Operator: "Operador",
  "Mobile / Landline / Data / TV": "Celular / fixo / dados / TV",
  "Enter account opening branch (Not required)":
    "Digite a filial de abertura da conta (não é necessário)",
  "IBAN Number": "Número do iban",
  "BIC/SWIFT Code(Optional)": "Código BIC/SWIFT (Opcional)",
  "BIC/SWIFT Code": "Código Bic/Swift",
  "Enter international bank account number":
    "Digite o número da conta bancária internacional",
  "SWIFT BIC (Optional)": "Swift Bic (opcional)",
  "Enter your bank identification code":
    "Digite seu código de identificação do banco",
  "GB IBAN account number": "Número da conta GB IBAN",
  "IBAN (Optional)": "Iban (opcional)",
  "Other payment details (for international transfer) (Optional)":
    "Outros detalhes de pagamento (para transferência internacional) (opcional)",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "Detalhes como seu próprio endereço, o endereço do banco, o código IBAN e o código Swiftbic, etc.",
  "Bank Branch (Optional)": "Agência bancária (opcional)",
  "Recommended Transfer Remarks (Optional)":
    "Comentários de transferência recomendados (opcional)",
  "Recommended Transfer Remarks": "Observações de transferência recomendadas",
  'Please enter your MoMo phone number"':
    'Por favor, digite seu número de telefone MOMO "',
  "Please enter your MoMo QR Code": "Por favor, insira seu código MOMO QR",
  "Please enter your Zalopay QR Code":
    "Por favor, digite seu código QR Zalopay",
  "Additional account number (Optional)":
    "Número de conta adicional (opcional)",
  "Please enter additional account number":
    "Por favor, insira o número da conta adicional",
  "Branch code (Optional)": "Código de ramificação (opcional)",
  "Enter branch code (not required)":
    "Digite o código da ramificação (não é necessário)",
  "IBAN (Optional)": "Iban (opcional)",
  "Please enter the IBAN code of your Bank (not required)":
    "Por favor, insira o código IBAN do seu banco (não é necessário)",
  "IBAN or account number": "IBAN ou número da conta",
  "Please enter your IBAN or account number":
    "Por favor, insira o seu IBAN ou o número da conta",
  "Papara account number": "Número da conta do Papa",
  "Please enter your Papara account number":
    "Por favor, insira o número da sua conta de papa",
  City: "Cidade",
  "Please enter your city": "Por favor, digite sua cidade",
  "Zip Code": "CEP",
  "Please enter your city zipcode": "Por favor, insira sua cidade ZIPCODE",
  Country: "País",
  "Please enter Country": "Por favor, entre no país",
  "Moneygram Account Number": "Número da conta MoneyGram",
  "Please enter your Moneygram account number":
    "Por favor, insira o número da sua conta MoneyGram",
  "Enter bank branch information": "Digite informações da agência bancária",
  "Please enter your BCA account number":
    "Por favor, insira o número da sua conta BCA",
  "Please enter your Mandiri account number":
    "Por favor, insira o número da sua conta Mandiri",
  "Chipper Cash full name": "Nome completo do dinheiro do chipper",
  "Please enter your full name used to register on chipper cash":
    "Por favor, insira seu nome completo usado para se registrar no chipper dinheiro",
  "Chipper Tag": "Tag de filmagem",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "Por favor, digite seu @chippertag. Você pode encontrá -lo na sua guia de perfil no Chipper Cash",
  "QIWI account": "Conta Qiwi",
  "Please enter your QIWI account": "Por favor, insira sua conta qiwi",
  "Your email registered on Binance": "Seu e -mail registrado no Binance",
  "Please enter your email registered on Binance":
    "Por favor, insira seu e -mail registrado no Binance",
  "Payment Details": "Detalhes do pagamento",
  "WeChat account": "Conta do WeChat",
  "Please enter your WeChat account": "Por favor, insira sua conta do WeChat",
  "Receive QR Code (Optional)": "Receba o código QR (opcional)",
  "Alipay account": "Conta Alipay",
  "Please enter your Alipay account": "Por favor, insira sua conta Alipay",
  "Payee's LINE ID": "ID da linha do beneficiário",
  "Please fill in your LINE ID": "Por favor, preencha seu ID de linha",
  "Please fill in your phone number registered with LINE":
    "Por favor, preencha seu número de telefone registrado na linha",
  "Linepay QR Code (Optional)": "Código QR da Pay Line (Opcional)",
  "Reference number or barcode": "Número de referência ou código de barras",
  "Please enter reference number or barcode":
    "Por favor, insira o número de referência ou código de barras",
  "Remarks (Optional)": "Comentários (opcionais)",
  "Additional Remarks": "Observações adicionais",
  "Cell Phone Number": "Número do telefone celular",
  "Please enter your cell phone number":
    "Por favor, insira seu número de telefone celular",
  "Enter your ID number": "Digite seu número de identificação",
  "Fill in your phone number": "Preencha seu número de telefone",
  "Full Name of Receiver": "Nome completo do receptor",
  "Please enter your Full Name": "Porfavor digite seu nome completo",
  CBU: "CBU",
  "Please enter your CBU": "Por favor, digite seu CBU",
  "CUIT / CUIL": "Cuit / Cuil",
  "Enter your unique tax identification code":
    "Digite seu código de identificação tributária exclusivo",
  "Please enter your CVU": "Por favor, insira seu CVU",
  Alias: "Alias",
  "Please enter Alias": "Por favor, insira o alias",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "Tag da carteira de limão",
  "Uniform Bank Key": "Chave do banco uniforme",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "Digite seu número DNI/CPF",
  "CVU (Optional)": "CVU (opcional)",
  "Uniform Virtual Key": "Chave virtual uniforme",
  "CBU/CVU or": "CBU/CVU ou",
  "alias CVU/CBU or CVU/CBU number": "Alias ​​CVU/CBU ou CVU/CBU Número",
  "Alias del CBU": "Alias ​​del Cbu",
  "Vodafone cash Number": "Número de caixa da Vodafone",
  "Please enter your vodafone cash Number":
    "Por favor, insira seu número de dinheiro da Vodafone",
  "Sort code": "Código de Ordenação",
  "Please fill in your 6-digit sort code":
    "Por favor, preencha seu código de classificação de 6 dígitos",
  "Sort Code(Optional)": "Código de classificação (opcional)",
  "GB IBAN account number": "Número da conta GB IBAN",
  "IIN (Optional)": "Iin (opcional)",
  "Enter individual identification number":
    "Digite o número de identificação individual",
  "Please enter your bank card number":
    "Por favor, insira o número do seu cartão bancário",
  "Mobile Carrier": "Transportadora móvel",
  "Please enter your mobile carrier":
    "Por favor, insira sua operadora de celular",
  "Easypaisa mobile account": "Conta móvel easypaisa",
  "Please enter your Easypaisa mobile account":
    "Por favor, insira sua conta móvel easypaisa",
  "NayaPay Id": "Nayapay ID",
  "Please enter NayaPay Id": "Por favor, insira Nayapay ID",
  "Enter Brazilian valid document": "Insira o documento válido brasileiro",
  "Bank agency": "Agência bancária",
  "Enter the name of your bank agency": "Digite o nome da sua agência bancária",
  "Type of transfer": "Tipo de transferência",
  "Please filled with TED/DOC/PIX": "Por favor, preenchido com TED/DOC/PIX",
  "Name and Last Name": "Nome e ultimo Nome",
  "Pix Key": "Chave de pix",
  "Phone, email or key": "Telefone, e -mail ou chave",
  'Type of Bank"': 'Tipo de banco "',
  "Name registered in PicPay": "Nome registrado em picpay",
  "PicPay username": "Nome de usuário do picpay",
  "Username used to log in in PicPay":
    "Nome de usuário costumava fazer login no picpay",
  "urpay Account Details": "Detalhes da conta do URPAY",
  "Please enter your urpay account details":
    "Por favor, insira os detalhes da sua conta URPAY",
  "bKash Wallet Number": "Número da carteira Bkash",
  "Please enter your 11 digit registered bKash account number":
    "Por favor, digite seu número da conta BKASH registrado de 11 dígitos",
  "Nagad Wallet Number": "Número da carteira Nagad",
  "Please enter your 11 digit registered Nagad wallet number":
    "Por favor, insira seu número de carteira Nagad registrado de 11 dígitos",
  "Please enter your full name registered with Rocket account":
    "Por favor, insira seu nome completo registrado na conta do foguete",
  "Rocket Wallet Number": "Número da carteira de foguete",
  "Please enter your 12 digit Rocket Wallet number":
    "Por favor, insira seu número de carteira de foguete de 12 dígitos",
  "Please enter your JKOPAY ID": "Por favor, insira seu ID JKOPAY",
  "EasyPay wallet number (Optional)": "Número da carteira EasyPay (opcional)",
  "Please fill in EasyPay wallet number":
    "Por favor, preencha o número da carteira EasyPay",
  "User API Key": "Tecla API do usuário",
  "User Secret Key": "Chave secreta do usuário",
  "Bank account number": "número da conta de banco",
  PayID: "Payid",
  "Please enter your PayID": "Por favor, digite seu PayId",
  "Institution number": "Número da instituição",
  "Please enter institution number":
    "Por favor, digite o número da instituição",
  "PayCode (Optional)": "PayCode (opcional)",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "Pode ser encontrado em minha conta -> meu código de pagamento -> copie o link",
  "Please fill in your mobile phone number connected with your PayMe":
    "Por favor, preencha o número do seu celular conectado ao seu PayMe",
  Cellphone: "Celular",
  "Card Number or password": "Número do cartão ou senha",
  "Please fill in card Number or password":
    "Por favor, preencha o número do cartão ou senha",
  "Purpose of payment (Optional)": "Objetivo do pagamento (opcional)",
  "Enter a payment purpose for buyers":
    "Insira um propósito de pagamento para compradores",
  "Card number, account or key": "Número do cartão, conta ou chave",
  "CLABE number (Optional)": "Número Clabe (opcional)",
  "CLABE number": "Número do clabe",
  "Account number/card": "Número da conta/cartão",
  "Reference number or card number": "Número de referência ou número do cartão",
  "Please enter reference number or card number":
    "Por favor, insira o número de referência ou o número do cartão",
  "Provide transfer details during the trade":
    "Fornecer detalhes de transferência durante o comércio",
  "Variable symbol (Optional)": "Símbolo variável (opcional)",
  "Enter Variable symbol": "Insira o símbolo variável",
  "Enter your PayNow account": "Digite sua conta PayNow",
  "Account Name": "Nome da conta",
  "Email Address": "Endereço de email",
  "Please enter email address": "Por favor, insira endereço de e -mail",
  "Please enter Your Full Name": "Porfavor digite seu nome completo",
  "Account Number": "Número de conta",
  "Enter your phone number": "Digite seu número de telefone",
  "Enter your account number": "Digite o número da sua conta",
  "Enter your bank account number": "Digite o número da sua conta bancária",
  "Payment details": "Detalhes do pagamento",
  "Bank name": "nome do banco",
  "Please enter your MoMo phone number":
    "Por favor, insira seu número de telefone MOMO",
  "Please enter your email": "Por favor introduza o seu e-mail",
  "Enter branch code": "Digite o código da ramificação",
  "Please enter the IBAN code of your Bank":
    "Por favor, insira o código IBAN do seu banco",
  "Account opening branch (Optional)": "Ramo de abertura da conta (opcional)",
  "Enter account opening branch (not required)":
    "Digite a filial de abertura da conta (não é necessário)",
  "Please enter your Bank account number":
    "Por favor, insira o número da sua conta bancária",
  "Please enter your ID Number":
    "Por favor, insira seu número de identificação",
  "Enter bank account type": "Digite tipo de conta bancária",
  "Bank Branch (Optional)": "Agência bancária (opcional)",
  "Account type": "Tipo de conta",
  "Please enter bank branch information":
    "Por favor, insira informações da agência bancária",
  "Please enter Email address": "Por favor, insira endereço de e -mail",
  "Phone number(Optional)": "Número de telefone (opcional)",
  "Phone Number (Optional)": "Número de telefone (opcional)",
  "Additional information": "Informações adicionais",
  "Type of Bank": "Tipo de banco",
  "Remark (Optional)": "Observação (opcional)",
  "Mobile Phone": "Celular",
  "Enter the bank code": "Digite o código bancário",
  "Please enter your phone number": "Por favor, digite seu número de telefone",
  "Please enter your Bank name": "Por favor, insira o nome do seu banco",
};

export default PORTUGUESE;
