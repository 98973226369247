import axios from "axios";
import { globalHeaders } from "./index";
import { BASE_API_ENDPOINT } from "./index";

// order api calls

export const getOrders = async (
  page: number,
  params: any,
  account: string,
  token: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/order-apis/v1/orders/${page || 1}`,
      {
        params: params,
        headers: {
          ...globalHeaders,
          "x-auth-token": token,
          signature: signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getOrders ", { error, params, token, page });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getOrdersAnalytics = async (
) => {
  try {
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/order-apis/v1/order-analytics/`,
      {
        headers: {
          ...globalHeaders,
      
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getOrdersAnayltics ", { error});
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getAdvertiserOrders = async (
  advertiserId: string,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/order-apis/v1/advertiser-orders/${advertiserId}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature: signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getOrders ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getOrderById = async (
  id: string,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/order-apis/v1/order/${id}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature: signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getOrders ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export async function MockApiCalls(payload: any, mockError: boolean = false) {
  const response = await Promise.resolve(
    setTimeout(() => {
      return { data: payload };
    }, 3000)
  );

  return response;
}

export const createOrder = async (
  orderType: string,
  payload: any,
  account: string,
  authToken: string
) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    if (orderType === "sell") {
      response = await axios.post(
        `${BASE_API_ENDPOINT}/order-apis/v1/sell-order`,
        payload,
        {
          headers: { ...globalHeaders, "x-auth-token": authToken, signature },
        }
      );
    } else {
      response = await axios.post(
        `${BASE_API_ENDPOINT}/order-apis/v1/buy-order`,
        payload,
        {
          headers: { ...globalHeaders, "x-auth-token": authToken, signature },
        }
      );
    }

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("getGlobalPaymentOptions ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const verifyDeposit = async (
  orderId: string,
  account: string,
  authToken: string
) => {
  try {
    if (!orderId) {
      return { status: false, data: null };
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/order-apis/v1/verify-deposit/${orderId}`,
      {},
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

// token deposts used in all sell orders
export const fetchUserLockedDeposits = async (
  token_id: string | undefined,
  account: string,
  authToken: string
) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    response = await axios.get(
      `${BASE_API_ENDPOINT}/order-apis/v1/locked-deposits/${token_id}`,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("fetchUserLockedDeposits ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

//  fetch current buy / sell order highest market price
export const fetchMarketPrice = async (
  orderType: string,
  tokenId: string,
  fiatId: string,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const response = await axios.get(
      `${BASE_API_ENDPOINT}/order-apis/v1/current-market-price/${orderType}/${tokenId}/${fiatId}`,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    const data = await response.data;

    return {
      status: response?.status,
      data: {
        current_price: data?.current_order?.order_unit_price,
        all_time_price: data?.all_time_order?.order_unit_price,
      },
    };
  } catch (error: any) {
    console.log("fetchUserTrades ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const cancelOrder = async (
  orderId: string,
  account: string,
  authToken: string
) => {
  try {
    if (!orderId) {
      return { status: false, data: null };
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/order-apis/v1/cancel-order/${orderId}`,
      {},
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
