import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Pagination from "common/Pagination";
import { FormattedMessage } from "react-intl";
import DayProgress from "./DayProgress";

export default function QuestsTable({
  quests,
  progress,
  claim,
  claiming,
  claimLoading,
  classes,
  tab,
  page,
  totalPages,
  changePage,
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box className={classes.tableCard}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            <th style={{ height: "20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Quest" defaultMessage="Quest" />
              </Typography>
            </th>

            <th style={{ height: "20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Points" defaultMessage="Points" />
              </Typography>
            </th>

            <th style={{ height: "20px" }}>
              <Typography
                textAlign="center"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Action" defaultMessage="Action" />
              </Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {quests?.map((quest, index) => (
            <tr className={classes.trHighlight} key={index}>
              <td
                style={{
                  width: "70%",
                  padding: "16px 10px",
                  borderRadius: "10px 0 0 10px",
                }}
              >
                <div className="d-flex flex-row align-content-center align-items-center">
                  <Typography
                    textAlign="left"
                    variant="body2"
                    fontSize={sm ? 12 : 14}
                    style={{
                      fontWeight: 500,
                      width: "95%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className={classes.otherText}
                  >
                    {progress && progress[quest?._id]?.day && (
                      <DayProgress day={progress[quest?._id]?.day} />
                    )}
                    <FormattedMessage
                      id={quest?.description}
                      defaultMessage={quest?.description}
                    />
                  </Typography>
                  {progress && progress[quest?._id]?.total && (
                    <Typography
                      textAlign="right"
                      variant="body2"
                      fontSize={sm ? 12 : 14}
                      style={{
                        color: theme.palette.action.green,
                        width: "5%",
                        marginRight: "10%",
                      }}
                    >
                      {progress[quest?._id]?.achieved}/
                      {progress[quest?._id]?.total}
                    </Typography>
                  )}
                </div>
              </td>

              <td
                className={classes.otherText}
                style={{ width: "15%", padding: "16px 10px" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {quest?.reward}
                </Typography>
              </td>

              <td
                className={classes.otherText}
                style={{
                  width: "15%",
                  padding: "16px 10px",
                  borderRadius: "0 10px 10px 0",
                  textAlign: "center",
                }}
              >
                <Button
                  disabled={!quest.completed || quest.claimed || claimLoading}
                  style={{
                    borderRadius: 10,
                    backgroundColor: quest.completed
                      ? quest.claimed
                        ? theme.palette.action.green
                        : theme.palette.button.primary
                      : theme.palette.mode === "light"
                      ? "#bdbdbd"
                      : "#646892",
                    padding: "5px 20px 5px 20px",
                    color: "white",
                    margin: "auto",
                  }}
                  onClick={() => claim(quest)}
                >
                  {claiming?.id === quest?._id ? (
                    <FormattedMessage id="Claiming" defaultMessage="Claiming" />
                  ) : !claimLoading ? (
                    quest.claimed ? (
                      <FormattedMessage id="Claimed" defaultMessage="Claimed" />
                    ) : (
                      <FormattedMessage id="Claim" defaultMessage="Claim" />
                    )
                  ) : (
                    <img
                      src="/table_loader.gif"
                      alt="loader"
                      height={25}
                      width={25}
                    />
                  )}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <Pagination
          page={page}
          changePage={changePage}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
}
