import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "10%",
  },
  heading: {
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: "0.02em",
    color: theme.palette.text.primary,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
  },
  para: {
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: "-0.02em",
    color: theme.palette.mode === "light" ? "#778090" : "#646892",
    textAlign: "center",
    lineHeight: 1.5,
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },

  title: {
    fontWeight: 600,
    fontSize: 18,
    letterSpacing: "0.02em",
    color: theme.palette.text.primary,
    textAlign: "center",
    paddingTop: 7,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  description: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: "0.02em",
    color: "#414141",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      color: "#212121",
    },
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  buttonFirst: {
    width: "fit-content",
    color: "#212121",
    backgroundColor: "#eeeeee",
    padding: "12px 50px 12px 50px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  buttonSecond: {
    width: "fit-content",
    color: "white",
    backgroundColor: "#6A55EA",
    padding: "12px 50px 12px 50px",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  filterCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "80%",
    border: "1px solid #eeeeee",

    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#414141",
    textAlign: "left",
  },
  stepImage: {
    height: 80,
    [theme.breakpoints.down("md")]: {
      height: 50,
    },
  },
  stepText: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: "0.02em",
    color: theme.palette.mode === "light" ? "#778090" : "#646892",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },
}));

export default function HowItWorks() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [orderType, setOrderType] = useState("buy");

  return (
    <Box pb={sm ? 0 : 5}>
      <Box className={classes.background}>
        <h1 variant="h1" className={classes.heading}>
          <FormattedMessage id="How P2P Works" defaultMessage="How P2P Works" />
        </h1>
        <div className="d-flex justify-content-center">
          <p className={classes.para}>
            <FormattedMessage
              id="home_howitwork"
              defaultMessage="PolkaBridge P2P helps you convert your money to crypto instantly
            where PolkaBridge acts as an escrow for safekeeping of the
            transaction."
            />
          </p>
        </div>

        <Box className={classes.buttonWrapper}>
          <Box
            className={classes.buttonFirst}
            style={{
              backgroundColor:
                orderType === "buy"
                  ? theme.palette.background.main
                  : theme.palette.mode === "light"
                    ? theme.palette.background.secondary
                    : "#646892",
              color:
                orderType === "buy"
                  ? "white"
                  : theme.palette.mode === "light"
                    ? theme.palette.text.secondary
                    : "#ffffff",
            }}
            onClick={() => setOrderType("buy")}
          >
            <FormattedMessage id="Buy" defaultMessage="Buy" />
          </Box>
          <Box
            className={classes.buttonSecond}
            style={{
              backgroundColor:
                orderType === "sell"
                  ? theme.palette.background.main
                  : theme.palette.mode === "light"
                    ? theme.palette.background.secondary
                    : "#646892",
              color:
                orderType === "sell"
                  ? "white"
                  : theme.palette.mode === "light"
                    ? theme.palette.text.secondary
                    : "#ffffff",
            }}
            onClick={() => setOrderType("sell")}
          >
            <FormattedMessage id="Sell" defaultMessage="Sell" />
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
          mb={sm ? 10 : 12}
          style={{ marginTop: 30 }}
        >
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mt={3}
            mb={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={sm ? 100 : 140}
              width={sm ? "80%" : 200}
            >
              <div>
                <img
                  src="/images/steps/BuyButton.png"
                  className={classes.stepImage}
                />
              </div>
              <h1 variant="h1" className={classes.title}>
                <FormattedMessage id="STEP 1" defaultMessage="STEP 1" />
              </h1>
              <p className={classes.stepText}>
                {orderType === "buy" ? (
                  <span>
                    <FormattedMessage
                      id="buy_step1"
                      defaultMessage="Choose the ad with desired price and payment method. Enter
                    your buy quantity and transaction amount to complete the
                    order."
                    />
                  </span>
                ) : (
                  <span>
                    <FormattedMessage
                      id="Deposit tokens into"
                      defaultMessage="Deposit tokens into"
                    />{" "}
                    <Link to="/wallet">
                      <FormattedMessage id="wallet" defaultMessage="wallet" />
                    </Link>{" "}
                    <FormattedMessage
                      id="and create orders"
                      defaultMessage="and create orders"
                    />
                    .
                  </span>
                )}
              </p>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mt={3}
            mb={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={sm ? 100 : 140}
              width={sm ? "80%" : 200}
            >
              <div>
                <img
                  src="/images/steps/Bank.png"
                  className={classes.stepImage}
                />
              </div>

              <h1 variant="h1" className={classes.title}>
                <FormattedMessage id="STEP 2" defaultMessage="STEP 2" />
              </h1>
              <p className={classes.stepText}>
                {orderType === "buy" ? (
                  <span>
                    <FormattedMessage
                      id="buy_step2"
                      defaultMessage="Complete your payments based on information provided by the
                    seller."
                    />
                  </span>
                ) : (
                  <span>
                    <FormattedMessage
                      id="step2_sell"
                      defaultMessage="Confirm that you've received the full payments from the
                    buyer."
                    />
                  </span>
                )}
              </p>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mt={3}
            mb={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={sm ? 100 : 140}
              width={sm ? "80%" : 200}
            >
              <div>
                <img
                  src="/images/steps/Wallet.png"
                  className={classes.stepImage}
                />
              </div>

              <h1 variant="h1" className={classes.title}>
                <FormattedMessage id="STEP 3" defaultMessage="STEP 3" />
              </h1>
              <p className={classes.stepText}>
                {orderType === "buy" ? (
                  <span>
                    <FormattedMessage
                      id="step31_buy"
                      defaultMessage="After the seller receives the payment, he will release the
                    coins. Head to the"
                    />{" "}
                    <Link to="/wallet">
                      <FormattedMessage id="wallet" defaultMessage="wallet" />
                    </Link>{" "}
                    <FormattedMessage
                      id="step32_buy"
                      defaultMessage="to review the coins you received."
                    />
                  </span>
                ) : (
                  <span>
                    <FormattedMessage
                      id="step3_sell"
                      defaultMessage="Release coins to the buyer only after you've received their
                    payments in full."
                    />
                  </span>
                )}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
