import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

function Rules({ classes }) {
  const theme = useTheme();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box display="flex" justifyContent="center" pt={sm ? 1 : 2}>
      <Box>
        <Typography
          fontSize={sm ? 20 : 22}
          color={theme.palette.text.primary}
          fontWeight={600}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage id="Rules" defaultMessage="Rules" />
        </Typography>
        <Typography
          fontSize={sm ? 13 : 16}
          color={"#919191"}
          fontWeight={400}
          mb={sm ? 0.5 : 1}
          pl={0.4}
        >
          <FormattedMessage
            id="1. Daily Task : The task will be refreshed daily at 00:00 UTC."
            defaultMessage="1. Daily Task : The task will be refreshed daily at 00:00 UTC."
          />
        </Typography>
        <Typography
          fontSize={sm ? 13 : 16}
          color={"#919191"}
          fontWeight={400}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage
            id="2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account."
            defaultMessage="2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account."
          />
        </Typography>
        {/* <Typography
          fontSize={sm ? 13 : 16}
          color={"#919191"}
          fontWeight={400}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage
            id="questrule3"
            defaultMessage="3. The total reward for Quest round 1 will be 60,000 PBR.
            The points will be converted to the corresponding PBR, depending on the number of points requested"
          />
        </Typography> */}
        <Typography
          fontSize={sm ? 13 : 16}
          color={"#919191"}
          fontWeight={400}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage
            id="3. Redeem points limit is 100 Points."
            defaultMessage="3. Redeem points limit is 100 Points."
          />
        </Typography>
        {/* <Typography
          fontSize={sm ? 13 : 16}
          color={"#919191"}
          fontWeight={400}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage
            id="questrule5"
            defaultMessage="5. After Quest Round 1 ends, the user will claim his reward. Rewards will be sent to users within 7 working days."
          />
        </Typography> */}
        <Typography
          fontSize={sm ? 13 : 16}
          color={"#919191"}
          fontWeight={400}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage
            id="4. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets."
            defaultMessage="4. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets."
          />
        </Typography>
      </Box>
    </Box>
  );
}

export default Rules;
