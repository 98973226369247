import { initializeConnector, Web3ReactHooks } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { Connector } from "@web3-react/types";
import { WalletConnect } from "@web3-react/walletconnect";
import { RPC_URLS } from "../utils/infura";
import { isInjected, isMetaMaskWallet } from "./walletConstants";
import { isMobile } from "utils/userAgent";

export enum ConnectionType {
  INJECTED = "INJECTED",
  WALLET_CONNECT = "WALLET_CONNECT",
}

export interface Connection {
  name: string;
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
  icon?: string;
  shouldDisplay?: boolean;
  overrideActivate?: () => void;
  isNew?: boolean;
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

const getShouldAdvertiseMetaMask =
  !isMetaMaskWallet && !isMobile && !isInjected;
const isGenericInjector = isInjected && !isMetaMaskWallet;

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, onError })
);
export const injectedConnection: Connection = {
  name: isGenericInjector ? "Browser Wallet" : "MetaMask",
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  shouldDisplay:
    isMetaMaskWallet || getShouldAdvertiseMetaMask || isGenericInjector,
};

const [web3WalletConnect, web3WalletConnectHooks] =
  initializeConnector<WalletConnect>((actions) => {
    // Avoid testing for the best URL by only passing a single URL per chain.
    // Otherwise, WC will not initialize until all URLs have been tested (see getBestUrl in web3-react).
    const RPC_URLS_WITHOUT_FALLBACKS = Object.entries(RPC_URLS).reduce(
      (map, [chainId, urls]) => ({
        ...map,
        [chainId]: RPC_URLS?.[chainId]?.[0],
      }),
      {}
    );
    return new WalletConnect({
      actions,
      options: {
        rpc: RPC_URLS_WITHOUT_FALLBACKS,
        qrcode: true,
      },
      onError,
    });
  });

const isMetaMaskBrowser = isMobile && isMetaMaskWallet;
const getIsInjectedMobileBrowser = isMetaMaskBrowser;

const WALLET_CONNECT_ICON_URL = "images/walletConnect.svg";
export const walletConnectConnection: Connection = {
  name: "WalletConnect",
  connector: web3WalletConnect,
  hooks: web3WalletConnectHooks,
  type: ConnectionType.WALLET_CONNECT,
  icon: WALLET_CONNECT_ICON_URL,
  shouldDisplay: !getIsInjectedMobileBrowser,
};
