import { Box, Container, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { FormattedMessage } from "react-intl";
import WalletButton from "./WalletButton";

const useStyles = makeStyles((theme) => ({
  infoCardError: {
    marginTop: 100,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border: "1px solid #eeeeee",
    padding: 20,
    backgroundColor: "rgba(255,0,0,0.06)",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
export default function UnauthorisedUI() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container>
      <div className={classes.infoCardError}>
        <Box
          height={250}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <div className="text-center">
            <img
              src="https://cdn3d.iconscout.com/3d/premium/thumb/incognito-mode-5847986-4897792.png"
              style={{ height: 100 }}
            />
          </div>
          <Box my={2} style={{ color: theme.palette.text.primary }}>
            <h4 className="text-center">Unauthorised Acess!</h4>
            <Typography textAlign="center">
              <FormattedMessage
                id="Please connect your wallet first to continue P2P trading."
                defaultMessage="Please connect your wallet first to continue P2P trading."
              />
            </Typography>
          </Box>
          <WalletButton />
        </Box>
      </div>
    </Container>
  );
}
