import {
  Box,
  Button,
  Avatar,
  Typography,
  useMediaQuery,
  LinearProgress,
  useTheme,
  Rating,
} from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, fromWei } from "../../../utils/helper";
import BigNumber from "bignumber.js";
import { FormattedMessage } from "react-intl";
import { CHAIN_IDS_TO_DISPLAY_NAMES } from "../../../constants/chains";
import { GradeRounded, StarBorderRounded } from "@mui/icons-material";

const OrderTableRow = ({ order, index, classes }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const navigate = useNavigate();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const profile = useSelector((state) => state?.profile?.profile);

  const verified = useMemo(() => {
    return profile?.email_verified && profile?.phone_verified;
  }, [profile]);

  const orderProgress = () => {
    const progress = new BigNumber(order.order_amount)
      .minus(order.pending_amount)
      .div(order.order_amount)
      .multipliedBy(100)
      .toNumber();

    return progress;
  };

  return (
    <tbody id={`ad_row_${index}`}>
      <tr
        className={index % 2 === 0 ? classes.trHighlight : classes.tr}
        style={{
          backgroundColor:
            order?.user?._id === user?.id && theme.palette.background.highlight,
          border:
            order?.user?._id === user?.id &&
            `2px solid ${theme.palette.text.secondary}`,
        }}
      >
        <td
          className={classes.userText}
          id="advertiser"
          style={{
            padding: !md && lg ? "10px 15px" : "16px 10px 16px 20px",
          }}
        >
          <Box display="flex" alignItems="center" whiteSpace="nowrap">
            <Box className={classes.avatar}>
              <Box
                style={{
                  position: "absolute",
                  height: 10,
                  width: 10,
                  borderRadius: "100%",
                  border: "1px solid white",
                  bottom: 0,
                  right: 0,
                  zIndex: 1,
                  backgroundColor: order?.user?.isOnline
                    ? "#6EB437"
                    : "#E59939",
                }}
              />
              <Avatar
                src={order?.user?.avatar ? `${order?.user?.avatar}` : null}
                alt={order?.user?.name}
                onClick={() =>
                  navigate(`/advertiser-detail/${order?.user?._id}`)
                }
                sx={{
                  cursor: "pointer",
                  bgcolor: theme.palette.primary.main,
                  height: 30,
                  width: 30,
                  fontSize: 16,
                }}
              >
                {order?.user?.name?.[0]?.toUpperCase()}
              </Avatar>
            </Box>
            <Box ml={0.5}>
              <Link
                to={`/advertiser-detail/${order?.user?._id}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={md ? 12 : 14}
                  fontWeight={500}
                  mb={0.8}
                  sx={{ wordBreak: "break-all" }}
                  className={classes.userText}
                >
                  {order?.user?.name}
                </Typography>
              </Link>
              <Typography
                textAlign="left"
                fontSize={12}
                className={classes.otherText}
              >
                <FormattedMessage id="Trade(s)" defaultMessage="Trade(s)" />
                {order?.user?.totalTrades
                  ? `: ${order?.user?.totalTrades} | 
                ${(
                  (order?.user?.successfulTrades / order?.user?.totalTrades) *
                  100
                ).toFixed(2)}%`
                  : ": 0 | 0 %"}
              </Typography>
              <Rating
                name="customized-color"
                defaultValue={order?.user?.rating ? order?.user?.rating : 0}
                precision={0.1}
                icon={<GradeRounded sx={{ fontSize: 16 }} />}
                emptyIcon={<StarBorderRounded sx={{ fontSize: 16 }} />}
                readOnly
              />
            </Box>
          </Box>
        </td>
        <td
          className="ad_price"
          style={{
            padding: "10px 20px",
          }}
        >
          <Typography
            textAlign="left"
            variant="body2"
            fontSize={md ? 12 : 15}
            style={{ fontWeight: 600 }}
            className={classes.otherText}
          >
            {formatCurrency(order?.order_unit_price, true)}
            <span
              style={{
                paddingLeft: 4,
                fontWeight: 600,
              }}
            >
              {order?.fiat?.fiat}
            </span>
          </Typography>
        </td>
        <td
          style={{
            padding: "10px 20px",
          }}
        >
          <Box display="flex" flexDirection={"column"}>
            <Typography
              variant="body2"
              fontSize={md ? 12 : 14}
              color={theme.palette.text.primary}
            >
              {`${formatCurrency(
                fromWei(order?.pending_amount, order?.token?.decimals),
                true
              )} ${order?.token?.symbol}`}
            </Typography>

            <Box display="flex" mt={0.5} mb={0.5} flexWrap="wrap">
              <Typography
                fontSize={md ? 10 : 12}
                color={theme.palette.text.secondary}
                mr={0.3}
              >
                <FormattedMessage id="Limit" defaultMessage="Limit" />:
              </Typography>

              <Typography
                fontSize={md ? 10 : 12}
                color={theme.palette.text.primary}
              >
                {`${
                  order?.curr_min_order_value !== order?.curr_max_order_value &&
                  order?.curr_min_order_value <= 0
                    ? "1"
                    : formatCurrency(order?.curr_min_order_value, true)
                } ${order?.fiat?.fiat_label} - ${formatCurrency(
                  order?.curr_max_order_value,
                  true
                )} ${order?.fiat?.fiat_label}`}
              </Typography>
            </Box>
            <Box
              display={user.id === order?.user?._id ? "flex" : "none"}
              alignItems="center"
            >
              <LinearProgress
                value={orderProgress()}
                variant="determinate"
                color={theme.palette.mode === "light" ? "primary" : "secondary"}
                sx={{
                  width: "90%",
                  height: 5,
                }}
              />
              <Typography
                fontSize={11}
                ml={1}
                color={theme.palette.text.primary}
              >
                {orderProgress().toFixed(1)}%
              </Typography>
            </Box>
          </Box>
        </td>
        <td
          className="ad_payment"
          style={{
            padding: "10px 20px",
            color: theme.palette.text.primary,
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          <Box display="flex" flexWrap="wrap">
            {order?.payment_options?.map((paymentOption, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  mx={0.5}
                  my={0.3}
                >
                  <Box
                    mr={0.3}
                    height="75%"
                    borderRadius={4}
                    bgcolor={theme.palette.action.payment[paymentOption]}
                    border={`2px solid ${theme.palette.action.payment[paymentOption]}`}
                  ></Box>
                  <Box
                    px={1}
                    py={0.5}
                    mr={0.5}
                    bgcolor={theme.palette.background.primary}
                    borderRadius="12px"
                    border={`1px solid ${theme.palette.action.payment[paymentOption]}`}
                    whiteSpace="nowrap"
                    style={{ transition: "background-color 0.2s ease" }}
                  >
                    {paymentOption?.toUpperCase()}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </td>

        <td
          className={classes.otherText}
          style={{
            padding: "16px 20px",
            whiteSpace: "nowrap",
          }}
        >
          {CHAIN_IDS_TO_DISPLAY_NAMES?.[order?.chainId]}
        </td>

        <td
          className={classes.otherText}
          id="trade_button"
          style={{
            padding: "16px 20px",
            textAlign: "center",
          }}
        >
          {order?.order_type === "sell" ? (
            <Link
              to={!verified ? "/profile" : `/order/${order?._id}?tradeType=buy`}
              style={{ textDecoration: "none" }}
            >
              <Button
                style={{
                  backgroundColor:
                    order?.user?._id !== user?.id
                      ? theme.palette.action.green
                      : theme.palette.action.disabled,
                  color: "white",
                  borderRadius: 7,
                  paddingLeft: md ? 5 : 20,
                  paddingRight: md ? 5 : 20,
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  visibility:
                    order?.user?._id === user?.id ? "hidden" : "visible",
                }}
              >
                {verified ? (
                  <>
                    <FormattedMessage id="BUY" defaultMessage="BUY" />
                    {` ${(!md && order)?.token?.symbol}`}
                  </>
                ) : (
                  <FormattedMessage id="Verify" defaultMessage="Verify" />
                )}
              </Button>
            </Link>
          ) : (
            <Link
              to={
                !verified ? "/profile" : `/order/${order?._id}?tradeType=sell`
              }
              style={{
                textDecoration: "none",
                pointerEvents: order?.user?._id === user?.id && "none",
              }}
            >
              <Button
                style={{
                  backgroundColor:
                    order?.user?._id !== user?.id
                      ? theme.palette.action.red
                      : theme.palette.action.disabled,
                  color: "white",
                  borderRadius: 7,
                  paddingLeft: md ? 5 : 20,
                  paddingRight: md ? 5 : 20,
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  visibility:
                    order?.user?._id === user?.id ? "hidden" : "visible",
                }}
              >
                {verified ? (
                  <>
                    <FormattedMessage id="SELL" defaultMessage="SELL" />
                    {` ${(!md && order)?.token?.symbol}`}
                  </>
                ) : (
                  <FormattedMessage id="Verify" defaultMessage="Verify" />
                )}
              </Button>
            </Link>
          )}
        </td>
      </tr>
    </tbody>
  );
};

export default OrderTableRow;
