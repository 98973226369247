export const GET_ERRORS = "GET_ERRORS";

//Order reducers
export const GET_ORDER = "GET_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const GET_TOTAL_ORDERS = "GET_TOTAL_ORDERS";
export const SET_ORDER_LOADING = "SET_ORDER_LOADING";
export const CREATE_NEW_ORDER = "CREATE_NEW_ORDER";
export const RESET_NEW_ORDER = "RESET_NEW_ORDER";
export const GET_FIATS = "GET_FIATS";
export const GET_TOKENS = "GET_TOKENS";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const SET_BUY_MARKET_PRICE = "SET_BUY_MARKET_PRICE";
export const SET_SELL_MARKET_PRICE = "SET_SELL_MARKET_PRICE";
export const SET_ORDER_FETCH_ERROR = "SET_ORDER_FETCH_ERROR";

// Profile reducers

export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PAYMENTS = "UPDATE_PAYMENTS";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const SET_PROFILE_LOADING = "SET_PROFILE_LOADING";
export const SET_PROFILE_ERROR = "SET_PROFILE_ERROR";
export const SET_PHONE_OTP = "SET_PHONE_OTP";
export const SET_EMAIL_OTP = "SET_EMAIL_OTP";

// User reducers
export const ERROR = "ERROR";
export const LOAD_USER = "LOAD_USER";
export const LOAD_GUEST_USER = "LOAD_GUEST_USER";
export const SIGN_MESSAGE = "";
export const AUTH_LOADING = "AUTH_LOADING";
export const SIGNATURE_REQ_INIT = "SIGNATURE_REQ_INIT";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_USER_CHAIN = "SET_USER_CHAIN";
export const SET_USER_FIAT = "SET_USER_FIAT";
export const UPDATE_AUTH_STATE = "UPDATE_AUTH_STATE";
export const UPDATE_TOUR = "UPDATE_TOUR";
export const SET_NETWORK_SWITCH = "SET_NETWORK_SWITCH";

// trade reduces for buy/sell order transactions
export const CREATE_TRADE_LOADING = "CREATE_TRADE_LOADING";
export const FETCH_TRADE_LOADING = "FETCH_TRADE_LOADING";
export const CREATE_TRADE = "CREATE_TRADE";
export const GET_TRADES = "GET_TRADES";
export const GET_TOTAL_TRADES = "GET_TOTAL_TRADES";
export const GET_TRADE = "GET_TRADE";
export const SET_TRADE_ERROR = "SET_TRADE_ERROR";
export const TRADE_LOADING = "TRADE_LOADING";
export const GET_FIAT_TRANSFER_DATA = "GET_FIAT_TRANSFER_DATA";
export const GET_TOKEN_TRANSFER_DATA = "GET_TOKEN_TRANSFER_DATA";

// action for getting history
export const SET_HISTORY_LOADING = "SET_HISTORY_LOADING";
export const GET_HISTORY = "GET_HISTORY";
export const GET_TOTAL_TRANSACTIONS = "GET_TOTAL_TRANSACTIONS";
export const SET_TRANSACTION_FETCH_ERROR = "SET_TRANSACTION_FETCH_ERROR";

// wallet reducers
export const FETCH_TOKEN_BALANCES = "FETCH_TOKEN_BALANCES";
export const FETCH_TOKEN_BALANCE = "FETCH_TOKEN_BALANCE";
export const WALLET_ERROR = "WALLET_ERROR";
export const WALLET_LOADING = "WALLET_LOADING";
export const GET_WITHDRAW_QUOTE = "GET_WITHDRAW_QUOTE";
export const WITHDRAW_TOKEN = "WITHDRAW_TOKEN";
export const WITHDRAW_QUEUED = "WITHDRAW_QUEUED";
export const GET_LATEST_WITHDRAW = "GET_LATEST_WITHDRAW";

//admin actions
export const SET_ADMIN_TXN_LOADING = "SET_ADMIN_TXN_LOADING";
export const SET_ADMIN_TXN_FETCH_ERROR = "SET_ADMIN_TXN_FETCH_ERROR";
export const GET_ADMIN_TOTAL_TRANSACTIONS = "GET_ADMIN_TOTAL_TRANSACTIONS";
export const GET_ADMIN_TRANSACTIONS = "GET_ADMIN_TRANSACTIONS";
export const SET_REPUSH_ERROR = "SET_REPUSH_ERROR";
export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const SET_ANALYTICS_LOADING = "SET_ANALYTICS_LOADING";
export const SET_ANALYTICS_FETCH_ERROR = "SET_ANALYTICS_FETCH_ERROR";
export const GET_ADMIN_ORDERS = "GET_ADMIN_ORDERS";
export const GET_ADMIN_TOTAL_ORDERS = "GET_ADMIN_TOTAL_ORDERS";
export const SET_ADMIN_ORDERS_LOADING = "SET_ADMIN_ORDERS_LOADING";
export const SET_ADMIN_ORDER_FETCH_ERROR = "SET_ADMIN_ORDER_FETCH_ERROR";
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_TOTAL_USERS = "GET_ADMIN_TOTAL_USERS";
export const SET_ADMIN_USERS_LOADING = "SET_ADMIN_USERS_LOADING";
export const SET_ADMIN_USER_FETCH_ERROR = "SET_ADMIN_USER_FETCH_ERROR";
export const GET_REDEEM_REQUESTS = "GET_REDEEM_REQUESTS";
export const GET_TOTAL_REQUESTS = "GET_TOTAL_REQUESTS";
export const SET_REQUESTS_LOADING = "SET_REQUESTS_LOADING";
export const SET_REQUESTS_FETCH_ERROR = "SET_REQUESTS_FETCH_ERROR";

// admin auth reducers
export const SET_ADMIN_AUTH_LOADING = "SET_ADMIN_AUTH_LOADING";
export const SET_ADMIN_LOADING = "SET_ADMIN_LOADING";
export const SET_ADMIN_AUTH_ERROR = "SET_ADMIN_AUTH_ERROR";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_ADMINS = "GET_ADMINS";

// admin dispute reducers
export const GET_DISPUTES_ADMIN = "GET_DISPUTES_ADMIN";
export const GET_DISPUTE_ADMIN = "GET_DISPUTE";
export const DISPUTE_LOADING_ADMIN = "DISPUTE_LOADING_ADMIN";
export const DISPUTE_ERROR_ADMIN = "DISPUTE_ERROR_ADMIN";
export const SET_DISPUTE_COUNT = "SET_DISPUTE_COUNT";
export const SET_CURRENT_ADMIN_REVIEW = "SET_CURRENT_ADMIN_REVIEW";
