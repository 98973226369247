import { Box, Divider, useTheme } from "@mui/material";
import React from "react";
import Pagination from "../../../common/Pagination";
import QuestCard from "./QuestCard";

const QuestsCardList = ({
  quests,
  progress,
  claim,
  claiming,
  claimLoading,
  loading,
  tab,
  page,
  totalPages,
  changePage,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Box
          px={0.1}
          py={2}
          mb={1}
          style={{
            backgroundColor:
              theme.palette.mode === "light"
                ? "#ffffff"
                : theme.palette.background.highlight,
          }}
        >
          {quests.map((quest, index) => {
            return (
              <React.Fragment key={index}>
                <QuestCard
                  quest={quest}
                  progress={progress}
                  claim={claim}
                  claiming={claiming}
                  claimLoading={claimLoading}
                  index={index}
                />
                {index !== 9 && (
                  <Divider variant="fullwidth" sx={{ color: "#bdbdbd" }} />
                )}
              </React.Fragment>
            );
          })}
          {totalPages > 1 && (
            <Pagination
              page={page}
              changePage={changePage}
              totalPages={totalPages}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default QuestsCardList;
