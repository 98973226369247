import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const desktopSteps = [
  {
    title: (
      <FormattedMessage
        id="Create Order Tab"
        defaultMessage="Create Order Tab"
      />
    ),
    target: ".create-order",

    content: (
      <FormattedMessage
        id="Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes."
        defaultMessage="Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes."
      />
    ),
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="Profile Tab" defaultMessage="Profile Tab" />,
    target: ".my-profile",
    content: (
      <FormattedMessage
        id="Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options."
        defaultMessage="Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options."
      />
    ),
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="Quest Tab" defaultMessage="Quest Tab" />,
    target: ".quest-tab",
    content: (
      <FormattedMessage
        id="Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards."
        defaultMessage="Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards."
      />
    ),
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <FormattedMessage id="LeaderBoard Tab" defaultMessage="LeaderBoard Tab" />
    ),
    target: ".leaderboard-tab",
    content: (
      <FormattedMessage
        id="Click to see exclusive ranking for Polkabridge Traders."
        defaultMessage="Click to see exclusive ranking for Polkabridge Traders."
      />
    ),
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <FormattedMessage id="Switch Network" defaultMessage="Switch Network" />
    ),
    target: ".network-select",
    content: (
      <FormattedMessage
        id="Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing."
        defaultMessage="Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing."
      />
    ),
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage id="Buy Vs Sell" defaultMessage="Buy Vs Sell" />
      </Typography>
    ),
    target: ".makeStyles-buttonWrapper-29",
    content: (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <Typography fontWeight={500} fontSize={16} mr={2}>
            <FormattedMessage id="BUY" defaultMessage="BUY" />:
          </Typography>
          <Typography fontSize={14} textAlign={"left"} noWrap={false}>
            <FormattedMessage
              id="By selecting Buy option you can buy Crypto with your preffered Fiat currencies."
              defaultMessage="By selecting Buy option you can buy Crypto with your preffered Fiat currencies."
            />
          </Typography>
        </div>
        <div className="d-flex flex-row">
          <Typography fontWeight={500} fontSize={16} mr={2}>
            <FormattedMessage id="SELL" defaultMessage="SELL" />:
          </Typography>
          <Typography fontSize={14} textAlign={"left"} noWrap={false}>
            <FormattedMessage
              id="By selecting Sell option you can sell Crypto with your preffered Fiat currencies."
              defaultMessage="By selecting Sell option you can sell Crypto with your preffered Fiat currencies."
            />
          </Typography>
        </div>
      </div>
    ),
    placement: "right-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="Filter Tool" defaultMessage="Filter Tool" />,
    target: ".makeStyles-filterCard-32",
    content: (
      <FormattedMessage
        id="Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency."
        defaultMessage="Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency."
      />
    ),
    placement: "bottom",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <FormattedMessage id="Advertiser Info" defaultMessage="Advertiser Info" />
    ),
    target: "#ad_row_0 #advertiser",
    content: (
      <FormattedMessage
        id="Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information."
        defaultMessage="Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information."
      />
    ),
    placement: "right-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage
          id="Price and Limits"
          defaultMessage="Price and Limits"
        />
      </Typography>
    ),
    target: "#ad_row_0 .ad_price",
    content: (
      <FormattedMessage
        id="Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade."
        defaultMessage="Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade."
      />
    ),
    placement: "right-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage
          id="Payment options"
          defaultMessage="Payment options"
        />
      </Typography>
    ),
    target: "#ad_row_0 .ad_payment",
    content: (
      <FormattedMessage
        id="Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat."
        defaultMessage="Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat."
      />
    ),
    placement: "right-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage id="Lowest Fees" defaultMessage="Lowest Fees" />
      </Typography>
    ),
    target: "#ad_row_0 #trade_button",
    content: (
      <FormattedMessage
        id="Buy crypto with lowest fees ever!"
        defaultMessage="Buy crypto with lowest fees ever!"
      />
    ),
    placement: "left-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="P2P Wallet" defaultMessage="P2P Wallet" />,
    target: ".my-wallet",
    content: (
      <FormattedMessage
        id="Click to check your balance and to manage deposits and withdrawals of your assets and their history."
        defaultMessage="Click to check your balance and to manage deposits and withdrawals of your assets and their history."
      />
    ),
    placement: "bottom",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
      last: <FormattedMessage id="Close" defaultMessage="Close" />,
    },
  },
];

//steps for mobile ui
export const mobileSteps = [
  {
    title: (
      <FormattedMessage
        id="Create Order Tab"
        defaultMessage="Create Order Tab"
      />
    ),
    target: ".create_order",
    content: (
      <FormattedMessage
        id="Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes."
        defaultMessage="Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes."
      />
    ),
    placement: "left-start",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="Profile Tab" defaultMessage="Profile Tab" />,
    target: ".profile",
    content: (
      <FormattedMessage
        id="Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options."
        defaultMessage="Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options."
      />
    ),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    placement: "left-start",
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="Quest Tab" defaultMessage="Quest Tab" />,
    target: ".quest",
    content: (
      <FormattedMessage
        id="Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards."
        defaultMessage="Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards."
      />
    ),
    disableBeacon: true,
    placement: "left-start",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <FormattedMessage id="LeaderBoard Tab" defaultMessage="LeaderBoard Tab" />
    ),
    target: ".leaderboard",
    content: (
      <FormattedMessage
        id="Click to see exclusive ranking for Polkabridge Traders."
        defaultMessage="Click to see exclusive ranking for Polkabridge Traders."
      />
    ),
    disableBeacon: true,
    placement: "left-start",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="P2P Wallet" defaultMessage="P2P Wallet" />,
    target: ".wallet",
    content: (
      <FormattedMessage
        id="Click to check your balance and to manage deposits and withdrawals of your assets and their history."
        defaultMessage="Click to check your balance and to manage deposits and withdrawals of your assets and their history."
      />
    ),
    placement: "left-start",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <FormattedMessage id="Switch Network" defaultMessage="Switch Network" />
    ),
    target: ".network-select",
    content: (
      <FormattedMessage
        id="Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing."
        defaultMessage="Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing."
      />
    ),
    disableBeacon: true,
    placement: "left-start",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage id="Buy Vs Sell" defaultMessage="Buy Vs Sell" />
      </Typography>
    ),
    target: ".makeStyles-buttonWrapper-29",
    content: (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <Typography fontWeight={500} fontSize={16} mr={2}>
            <FormattedMessage id="BUY" defaultMessage="BUY" />:
          </Typography>
          <Typography fontSize={14} textAlign={"left"} noWrap={false}>
            <FormattedMessage
              id="By selecting Buy option you can buy Crypto with your preffered Fiat currencies."
              defaultMessage="By selecting Buy option you can buy Crypto with your preffered Fiat currencies."
            />
          </Typography>
        </div>
        <div className="d-flex flex-row">
          <Typography fontWeight={500} fontSize={16} mr={2}>
            <FormattedMessage id="SELL" defaultMessage="SELL" />:
          </Typography>
          <Typography fontSize={14} textAlign={"left"} noWrap={false}>
            <FormattedMessage
              id="By selecting Sell option you can sell Crypto with your preffered Fiat currencies."
              defaultMessage="By selecting Sell option you can sell Crypto with your preffered Fiat currencies."
            />
          </Typography>
        </div>
      </div>
    ),
    placement: "bottom",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: <FormattedMessage id="Filter Tool" defaultMessage="Filter Tool" />,
    target: ".makeStyles-filterCard-32",
    content: (
      <FormattedMessage
        id="Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency."
        defaultMessage="Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency."
      />
    ),
    placement: "bottom",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <FormattedMessage id="Advertiser Info" defaultMessage="Advertiser Info" />
    ),
    target: "#order-0 .advertiser-info",
    content: (
      <FormattedMessage
        id="Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information."
        defaultMessage="Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information."
      />
    ),
    placement: "bottom",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage
          id="Price and Limits"
          defaultMessage="Price and Limits"
        />
      </Typography>
    ),
    target: "#order-0 #price",
    content: (
      <FormattedMessage
        id="Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade."
        defaultMessage="Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade."
      />
    ),
    placement: "bottom-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage
          id="Payment options"
          defaultMessage="Payment options"
        />
      </Typography>
    ),
    target: "#order-0 #payment",
    content: (
      <FormattedMessage
        id="Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat."
        defaultMessage="Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat."
      />
    ),
    placement: "bottom-start",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
    },
  },
  {
    title: (
      <Typography
        fontWeight={500}
        fontSize={18}
        color={"#6A55EA"}
        textAlign="left"
      >
        <FormattedMessage id="Lowest Fees" defaultMessage="Lowest Fees" />
      </Typography>
    ),
    target: "#order-0 .fees",
    content: (
      <FormattedMessage
        id="Buy crypto with lowest fees ever!"
        defaultMessage="Buy crypto with lowest fees ever!"
      />
    ),
    placement: "bottom-end",
    disableBeacon: true,
    locale: {
      next: <FormattedMessage id="Next" defaultMessage="Next" />,
      back: <FormattedMessage id="Back" defaultMessage="Back" />,
      skip: <FormattedMessage id="Skip" defaultMessage="Skip" />,
      last: <FormattedMessage id="Close" defaultMessage="Close" />,
    },
  },
];
const options = {
  arrowColor: "#ffffff",
  primaryColor: "#6A55EA",
  width: 900,
  zIndex: 1400,
};

const buttonReset = {
  backgroundColor: "transparent",
  border: 0,
  borderRadius: 0,
  color: "#555",
  outline: "none",
  lineHeight: 1,
  padding: 8,
  WebkitAppearance: "none",
};

const spotlight = {
  borderRadius: 4,
  position: "absolute",
};
export const stepStyles = {
  options: {
    arrowColor: "#ffffff",
    primaryColor: "#6A55EA",
    // width: 900,
    zIndex: 1000,
  },
  tooltipTitle: {
    fontSize: 18,
    color: "#6A55EA",
    margin: "0 0 10px 0",
  },
  buttonNext: {
    ...buttonReset,
    backgroundColor: options.primaryColor,
    borderRadius: 4,
    color: "#fff",
  },
  buttonBack: {
    ...buttonReset,
    color: options.primaryColor,
    marginLeft: "auto",
    marginRight: 5,
  },
  buttonClose: {
    ...buttonReset,
    color: options.textColor,
    height: 14,
    padding: 15,
    position: "absolute",
    right: 0,
    top: 0,
    width: 14,
  },
  buttonSkip: {
    ...buttonReset,
    color: options.textColor,
    fontSize: 14,
  },
  spotlight: {
    ...spotlight,
    backgroundColor: "gray",
    border: "1px solid #6A55EA",
  },
};
