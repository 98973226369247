import React from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useMediaQuery, useTheme, Box, Dialog } from "@mui/material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "98%",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },
  inputAreaContainer: {
    width: "100%",
    height: "auto",
    border:
      theme.palette.mode === "light"
        ? "0.5px solid #EAECEE"
        : "0.5px solid #25284B",
    boxSizing: "border-box",
    borderRadius: 15,
    outline: "none",
    padding: 5,
    scrollPaddingBlock: 10,
    backgroundColor: theme.palette.background.highlight,
    color: theme.palette.text.primary,
  },
  cancelButton: {
    borderRadius: 10,
    width: 200,
    height: 45,
    backgroundColor: theme.palette.button.secondary,
    padding: "8px 30px 8px 30px",
    color:
      theme.palette.mode === "light" ? theme.palette.text.primary : "#ffffff",
    border: `1px solid ${theme.palette.button.primary}`,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      marginTop: 2,
      marginBottom: 2,
    },
  },
}));

const VideoPreviewPopup = ({ popupActive, resetPopup, videoUrl }) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      onClose={resetPopup}
      aria-labelledby="simple-dialog-title"
      open={popupActive}
    >
      <div className={classes.background}>
        <div className={classes.container}>
          <Box
            style={{
              position: "absolute",
              right: "1px",
              top: "1px",
            }}
          >
            <Close
              style={{ color: theme.palette.text.primary, cursor: "pointer" }}
              onClick={() => {
                resetPopup();
              }}
            />
          </Box>
          <Box className={classes.inputAreaContainer}>
            <video width="100%" height={"auto"} controls>
              <source src={videoUrl} />
            </video>
            <div className="text-center mt-2 ">
              <button className={classes.cancelButton} onClick={resetPopup}>
                <FormattedMessage id="Close" defaultMessage={"Close"} />
              </button>
            </div>
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoPreviewPopup;
