const SPANISH = {
  Mode: "Modo",
  Feedback: "Comentario",
  Home: "Hogar",
  "Create Order": "Crear orden",
  "My Orders": "Mis ordenes",
  Wallet: "Billetera",
  Profile: "Perfil",
  Leaderboard: "Tabla de clasificación",
  Faucet: "Grifo",
  Banned: "Prohibido",
  "This account has been banned. If this is unexpected, please contact":
    "Esta cuenta ha sido prohibida. Si esto es inesperado, comuníquese con",
  footermessage:
    "Únase a 100k+ personas que trabajan juntas para hacer del mundo un lugar mejor.",
  "Copyright 2023 By": "Copyright 2023 por",
  "All Rights Reserved": "Reservados todos los derechos",
  "How P2P Works": "Cómo funciona P2P",
  home_howitwork:
    "Polkabridge P2P lo ayuda a convertir su dinero en cripto al instante, donde Polkabridge actúa como un depósito en garantía para la custodia de la transacción.",
  Buy: "Comprar",
  Sell: "Vender",
  "STEP 1": "PASO 1",
  buy_step1:
    "Elija el anuncio con el precio deseado y el método de pago. Ingrese su cantidad de compra y cantidad de transacción para completar el pedido.",
  "Deposit tokens into": "Depositar los tokens en",
  wallet: "billetera",
  "and create orders": "y crear pedidos",
  "STEP 2": "PASO 2",
  buy_step2:
    "Complete sus pagos según la información proporcionada por el vendedor.",
  step2_sell: "Confirme que haya recibido los pagos completos del comprador.",
  "STEP 3": "PASO 3",
  step31_buy:
    "Después de que el vendedor reciba el pago, liberará las monedas. Dirígete al",
  step32_buy: "Para revisar las monedas que recibió.",
  step3_sell:
    "Libere monedas al comprador solo después de haber recibido sus pagos en su totalidad.",
  "Before you start trading you need to update your profile.":
    "Antes de comenzar a comerciar, debe actualizar su perfil.",
  "Before your start trading please verify your phone":
    "Antes de comenzar a operar, verifique su teléfono",
  "Please add a payment method to start buy crypto":
    "Agregue un método de pago para comenzar a comprar cripto",
  "Update now": "Actualizar ahora",
  "Verify now": "Verifica ahora",
  "Add now": "Añadir ahora",
  Logout: "Cerrar sesión",
  "Switching...": "Traspuesta...",
  Switch: "Cambiar",
  "Switch Network": "Cambiar de red",
  "Cancel dispute": "Cancelar disputa",
  canceldisputewarn:
    "No habrá opción de volver a plantear disputa. NO cancele si su problema no se resuelve.",
  "I confirm I have recieved the money from seller":
    "Confirmo que he recibido el dinero del vendedor",
  "Cancelling...": "Cancelado...",
  "Cancel Trade": "Cancelar el comercio",
  Warning: "Advertencia",
  warningcancel:
    "No habrá reembolso después de cancelar el pedido. No cancele si ya ha transferido dinero.",
  tradewarning:
    "El comercio se suspenderá por el día si uno canceló 4 transacciones en un día.",
  "I confirm I have not paid the payee.":
    "Confirmo que no he pagado al beneficiario.",
  "Please wait...": "Espere por favor...",
  "Cancel this trade": "Cancelar este intercambio",
  "Confirm Transfer": "Confirmar transferencia",
  confirmnote:
    "Las transacciones P2P requieren que realice el pago o la transferencia en un método compatible con la parte recepera. Polkabridge no se deduce automáticamente de su cuenta.",
  "Transfer Amount": "Monto de la transferencia",
  "In exchange for": "A cambio de",
  "Upload proof of payment": "Subir prueba de pago",
  "Confirm the account of the receiving party":
    "Confirmar la cuenta de la parte receptor",
  "Payment QR code": "Código QR de pago",
  confirmnote2:
    "Complete sus pedidos lo antes posible para que la parte receptora pueda confirmar su transferencia. Seleccione la cuenta de la parte receptora. La confirmación de pago fraudulenta probablemente hará que su cuenta se congele",
  "I didn't Pay Yet": "Todavía no pagué",
  "Transfer Completed": "Transferencia completada",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "Deposite los tokens en Polkabridge Vault y Comercio sin problemas",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Retirar tokens de Polkabridge Vault con tarifa",
  Amount: "Cantidad",
  "Select Max": "Seleccione Max",
  Balance: "Balance",
  "Available Amount": "Monto disponible",
  "Withdrawal Fee": "Cargo por retiro",
  "In order": "En orden",
  View: "Vista",
  "Send OTP": "Enviar OTP",
  "Verify Phone": "Verificar el teléfono",
  "Verify Email": "Verificar correo electrónico",
  "Your phone number has been verified":
    "Su número de teléfono ha sido verificado",
  "Your email address has been verified":
    "Tu dirección de email ha sido verificada",
  "6 digit otp has been sent on your phone":
    "Se ha enviado OTP de 6 dígitos en su teléfono",
  emailsent:
    "Código de verificación de 6 dígitos enviado en su correo electrónico. Si no puede ver el correo electrónico en su bandeja de entrada, asegúrese de verificar su carpeta de spam",
  "Verify OTP": "Verificar OTP",
  "OTP expired": "OTP expiró",
  "Resend OTP": "Reiniciar OTP",
  "Your OTP will expire in": "Tu OTP caducará en",
  "Confirm and Release": "Confirmar y lanzar",
  Tips: "Consejos",
  dontnotcheckbuyerpayment:
    "No solo verifique la prueba de pago del comprador. Asegúrese de iniciar sesión en su cuenta y verificar que se haya recibido el pago.",
  ifstillprocessing:
    "Si el pago aún se está procesando, ¡espere hasta que haya recibido el pago en su cuenta antes de lanzar la criptografía!",
  donotacceptthridparty:
    "No acepte el pago de una cuenta de terceros. Retrocede el monto total inmediatamente si recibe dicho pago para evitar pérdidas financieras causadas por la devolución de cargo bancaria después de haber liberado la criptografía.",
  "Confirm User Account and Release Amount":
    "Confirmar la cuenta de usuario y el monto de la liberación",
  iconfirmpaymentcorrect:
    "Confirmo que el pago se ha acreditado a mi cuenta y el saldo disponible es correcto.",
  "Releasing...": "Liberando ...",
  Order: "Orden",
  "is successfully completed": "se completa con éxito",
  Submitted: "Enviado",
  Submit: "Entregar",
  "Click below for processing": "Haga clic a continuación para procesar",
  "Waiting for confirmation": "esperando confirmación",
  "Please confirm the transaction into your metamask popup":
    "Confirme la transacción en su ventana emergente de metamask",
  "Withdrawal Request Submitted": "Solicitud de retiro enviada",
  "Withdrawal Address": "Dirección de retiro",
  Back: "Atrás",
  "Withdrawal History": "Historial de retiro",
  "Deposit Processing": "Procesamiento de depósitos",
  depositapproval:
    "Se ha presentado la aprobación para el depósito, sea paciente. No inicie más depósitos mientras el proceso de depósito está en curso.",
  "Deposits History": "Depósitos Historia",
  "Transaction Submitted": "Transacción presentada",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "La transacción ha sido presentada y esperando la confirmación de Blockchain.",
  "View Status": "Ver el estado de",
  "Transaction Successful!": "¡Transacción exitosa!",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "¡Excelente! La transacción ha sido confirmada en la cadena de bloques con éxito.",
  "Transaction Failed!": "¡Transacción fallida!",
  "We have encountered an error in the transaction. Please try again.":
    "Hemos encontrado un error en la transacción. Inténtalo de nuevo.",
  "Sign Message": "Mensaje de firma",
  "Connect Wallet": "Conectar billetera",
  "Waiting to connect": "Esperando para conectarse",
  "Confirm this connection in your wallet":
    "Confirme esta conexión en su billetera",
  walletconnect_rule:
    "Al conectar una billetera, usted acepta los términos de servicio y consentimiento de Polkabridge P2P para su política de privacidad.",
  "Please sign this message requested in your metamask":
    "Firme este mensaje solicitado en su Metamask",

  Metamask: "Meta",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "¡Siguiente versión de Testnet!",
  "Follow Us": "Síganos",
  "Claim Faucet": "Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Reclamar tokens de prueba para Goerli Testnet o BSC Testnet.",
  "Unauthorised Access!": "¡Acceso no autorizado!",
  "Please connect your wallet first to continue P2P trading.":
    "Conecte primero su billetera para continuar con el comercio P2P.",
  Price: "Precio",
  Limit: "Límite",
  Following: "Siguiente",
  Unfollow: "Dejar de seguir",
  Follow: "Seguir",
  Rating: "Clasificación",
  "Online Ads": "Anuncios en línea",
  "All Feedback": "Todos los comentarios",
  "Volume in": "Volumen",
  "Decentralized P2P Exchange, powered by ":
    "Intercambio P2P descentralizado, alimentado por ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Experimente el primer comercio P2P descentralizado con Polkabridge",
  Fiat: "Fíat",
  All: "Todo",
  Token: "Símbolo",
  "Payment Mode": "Modo de pago",
  orders: "pedidos",
  completion: "terminación",
  BUY: "COMPRAR",
  SELL: "VENDER",
  Verify: "Verificar",
  "Crypto Amount": "Cantidad criptográfica",
  "Fiat Amount": "Monto fiduciario",
  "Market Open Orders": "Pedidos abiertos del mercado",
  Advertisers: "Anunciantes",
  "Available | Limit": "Disponible | Límite",
  Payment: "Pago",
  Trade: "Comercio",
  "Exclusive ranking for Polkabridge Traders":
    "Clasificación exclusiva para Polkabridge Traders",
  Volume: "Volumen",
  Followers: "Seguidores",
  "Avg. Pay Time": "Avg. Tiempo de pago",
  "Avg. Release Time": "Avg. Tiempo de liberación",
  Username: "Nombre de usuario",
  Filter: "Filtrar",
  Clear: "Claro",
  User: "Usuario",
  "Last Trade Time": "Último tiempo comercial",
  Action: "Acción",
  "Unauthorised Access": "Acceso no autorizado",
  Apply: "Aplicar",
  "Order No.": "N º de pedido.",
  Coin: "Moneda",
  Type: "Tipo",
  Status: "Estado",
  Time: "Tiempo",
  Operation: "Operación",
  "Crypto amount": "Cantidad criptográfica",
  "Fiat amount": "Monto fiduciario",
  "Order type": "Tipo de orden",
  "Order Details": "Detalles del pedido",
  "Order Number": "Número de orden",
  "Time created": "Tiempo creado",
  "Buy Order": "Comprar pedido",
  Executed: "Ejecutado",
  at: "en",
  "Total Fiat Amount": "Cantidad total de fiducias",
  "Crypto Unit Price": "Precio unitario criptográfico",
  "Min Order Limit": "Límite de pedido mínimo",
  "Max Order Limit": "Límite de pedido máximo",
  "Trade between": "Intercambio",
  "Payment Time Limit": "Límite de tiempo de pago",
  Remark: "Observación",
  "Need Help": "Necesitas ayuda",
  "Please wait": "Espere por favor",
  "Cancel Order": "Cancelar orden",
  "Go To Home": "Ir a casa",
  "Initiate New P2P Order": "Iniciar un nuevo pedido P2P",
  "Set type and price": "Tipo y precio establecer",
  "Review Your Order": "Revise su orden",
  "I want to": "Yo quiero",
  "Order Limit": "Límite de pedido",
  Min: "Mínimo",
  Max: "Máximo",
  "Payment Methods": "Métodos de pago",
  "Add payments": "Agregar pagos",
  "Current Price": "Precio actual",
  "Highest Price": "Precio más alto",
  "Lowest Price": "El precio más bajo",
  "Please note that if you decide to": "Tenga en cuenta que si decide",
  SUBMIT: "ENTREGAR",
  "the order, you wouldn't be able to": "el pedido, no podrías",
  EDIT: "EDITAR",
  "it afterwards.": "es después.",
  "Review New P2P Order": "Revise el nuevo pedido P2P",
  "Review your order": "Revise su orden",
  Remarks: "Observaciones",
  Cancel: "Cancelar",
  "Submit Order": "Orden de envio",
  "Order Submitted Successfully": "Pedido enviado con éxito",
  "Order Type": "Tipo de orden",
  Total: "Total",
  "Check your order": "Verifique su pedido",
  Deposit: "Depósito",
  "to fulfill this sell order": "Para cumplir con esta orden de venta",
  with: "con",
  "Time remaining": "Tiempo restante",
  "Confirm Order Info": "Confirmar la información del pedido",
  "Quantity (After fee)": "Cantidad (después de la tarifa)",
  "Chat with": "Charlar con",
  Uploading: "Carga",
  "Upload Proof of Payment": "Subir prueba de pago",
  Browse: "Navegar",
  "Trade cancelled": "Comercio cancelado",
  "Transfer Payment to seller": "Transferir el pago al vendedor",
  "Seller released tokens": "Tokens lanzados al vendedor",
  "Pending seller confirmation": "Confirmación de vendedor pendiente",
  Completed: "Terminado",
  "Token Deposited": "Token depositado",
  "Deposit tokens to": "Depositar los tokens para",
  reserve: "reservar",
  "Buyer confirmed": "Comprador confirmado",
  "Pending buyer confirmation": "Confirmación de comprador pendiente",
  "Order Summary": "Resumen de pedido",
  Available: "Disponible",
  "payment method": "método de pago",
  "Seller's Message:": "Mensaje del vendedor:",
  "Buyer's Message:": "Mensaje del comprador:",
  "No message": "Sin mensaje",
  "Terms and conditions": "Términos y condiciones",
  ordersummary_rule:
    "No envíe pagos a través de cuentas de terceros, todos esos pagos tendrán que ir a disputa y serán reembolsados/cancelados. No incluya palabras relacionadas con criptografía como P2P, Polkabridge, USDT, ETH, BTC, etc.",
  "I want to buy for": "Quiero comprar para",
  "I want to sell for": "Quiero vender por",
  "I will get:": "Obtendré:",
  "I will send:": "Voy a enviar:",
  Loading: "Cargando",
  "Order Processing": "procesando orden",
  "Reference message": "Mensaje de referencia",
  "QR Code": "Código QR",
  "Payment confirmed": "Pago confirmado",
  "I've Paid. Confirm": "He pagado. Confirmar",
  Cancelled: "Cancelado",
  Name: "Nombre",
  Email: "Correo electrónico",
  Quantity: "Cantidad",
  Your: "Su",
  "has been reserved in PolkaBridge vault":
    "ha sido reservado en Polkabridge Vault",
  "Token reserved": "Token reservado",
  "Waiting for buyer's confirmation": "Esperando la confirmación del comprador",
  "Buyer confirmed payment": "Pago confirmado del comprador",
  "Buyer notified": "Comprador notificado",
  "Notify buyer": "Notificar al comprador",
  "Cancel trade": "Cancelar el comercio",
  "Dispute submitted": "Disputa presentada",
  Reason: "Razón",
  "Release tokens to buyer wallet":
    "Lanzar tokens a la billetera del comprador",
  "Token released": "Token lanzado",
  "Release tokens": "Tokens de lanzamiento",
  "Your order is executed successfully.": "Su pedido se ejecuta con éxito.",
  "My Profile": "Mi perfil",
  "Update your preference for smooth trading experience.":
    "Actualice su preferencia para una experiencia comercial sin problemas.",
  Menu: "Menú",
  Payments: "Pagos",
  "Transaction history": "Historial de transacciones",
  Settings: "Ajustes",
  Referral: "Remisión",
  "Filter Order": "Orden de filtro",
  TxnId: "Txnid",
  "Transaction not found": "Transacción no encontrada",
  "Wallet unavailable": "Billetera no disponible",
  "Check explorer": "Verifique el explorador",
  "Full Name": "Nombre completo",
  Mobile: "Móvil",
  "Preferred Fiat": "Fiat preferido",
  "Edit profile": "Editar perfil",
  "Upload Avatar": "Subir avatar",
  "Updating...": "Actualización ...",
  "Update profile": "Actualización del perfil",
  "Are you sure, want to leave PolkaBridge?":
    "¿Estás seguro, quieres dejar Polkabridge?",
  "Log out": "Cerrar sesión",
  "Fiat Preference": "Preferencia fiduciaria",
  Referrals: "Referencias",
  "Will be available soon.": "Disponible próximamente.",
  "Payment Options": "Opciones de pago",
  "Transaction History": "Historial de transacciones",
  Fee: "Tarifa",
  TxnID: "Txnid",
  "All payment methods were added": "Se agregaron todos los métodos de pago",
  "Add Payment Method": "Añadir método de pago",
  "Payment method": "Método de pago",
  "QR Code (optional)": "Código QR (opcional)",
  "Upload QR Code": "Subir el código QR",
  "Update details": "Detalles de actualización",
  "Not Uploaded": "No cargado",
  Edit: "Editar",
  "Update Payment": "Actualización de pago",
  Confirm: "Confirmar",
  "Depositing...": "Depositando ...",
  "Confirming...": "Confirmando ...",
  Withdraw: "Retirar",
  Locked: "Encerrado",
  "P2P Wallet": "Billetera P2P",
  walletpage_message:
    "Administre los activos en su plataforma P2P. Puede depositar, retirar activos para participar en transacciones en P2P",
  "Your Balances": "Tus equilibrios",
  "Buy Amount": "COMPRA CANTIDAD",
  "Sell Amount": "Monto de venta",
  "Trade(s)": "Comercio(s)",
  active: "activo",
  "Executed at": "Ejecutado en",
  Chain: "Cadena",
  "My Ads": "Mis anuncios",
  Active: "Activo",
  "Amount reversed by my Bank.": "Cantidad revertida por mi Banco.",
  "Transaction failed on my behalf": "Transacción fallida en mi nombre",
  "Seller received the amount successfully.":
    "El vendedor recibió la cantidad con éxito.",
  "Seller provided the necessary information to complete the payment.":
    "El vendedor proporcionó la información necesaria para completar el pago.",
  "I sent the remaining amount.": "Envié la cantidad restante.",
  "I raised the dispute by mistake.": "Levanté la disputa por error.",
  "I received the tokens.": "Recibí las fichas.",
  Others: "Otros",
  Quest: "Búsqueda",
  "Complete your quests and collect points.":
    "Completa tus misiones y recolecta puntos.",
  "Total Points": "Puntos totales",
  Redeem: "Canjear",
  "Resets in": "Restablecer",
  "Daily Quests": "Misiones diarias",
  "Weekly Quests": "Misiones semanales",
  Rules: "Normas",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1. Tarea diaria: la tarea se actualizará diariamente a las 00:00 UTC.",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2. Debe hacer clic en 'Reclamar' para obtener sus puntos de tarea. Si no los puntos no se acreditarán en su cuenta.",
  questrule3:
    "3. La recompensa total de la ronda 1 de Quest será de 60,000 PBR. Los puntos se convertirán al PBR correspondiente, dependiendo de la cantidad de puntos solicitados",
  "4. Redeem points limit is 100 Points.":
    "4. El límite de puntos de canalización es de 100 puntos.",
  questrule5:
    "5. Después de que finalice la ronda 1 de Quest, el usuario reclamará su recompensa. Las recompensas se enviarán a los usuarios dentro de los 7 días hábiles",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge se reserva el derecho de interpretación final para el evento. Si un usuario participa en cualquier comportamiento malicioso o ha explotado las vulnerabilidades de seguridad, Polkabridge tiene el derecho de deshacerse de la cuenta y los activos del usuario.",
  Points: "Puntos",
  Claiming: "Reclamando",
  Claimed: "Reclamado",
  Claim: "Afirmar",
  Action: "Acción",
  "Redeem Points": "Puntos de redimencia",
  "1 Points = 1 PBR": "1 puntos = 1 PBR",
  "Redeem Points limit is 100 Points":
    "El límite de puntos de canalización es de 100 puntos",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "Envíe su solicitud de canalización que el administrador revisará y transferirá token en su billetera.",
  Tokens: "fichas",
  "Wallet Address": "Dirección de billetera",
  "Confirm your wallet address and submit your request.":
    "Confirme su dirección de billetera y envíe su solicitud.",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "Completó al menos 20 órdenes en la cadena Ethereum en 7 días consecutivos.",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "Completó al menos 20 órdenes en la cadena BSC en 7 días consecutivos.",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "Depositar y retirar dentro de los 7 días consecutivos en ambas cadenas.",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "Comercio completo con todos los tokens (PBR-ET, PBR-BSC, USDT-ET, USDT-BSC, USDC, DAI, ETH, BUSD, BNB) en 7 días.",
  "Give at least 10 feedback .": "Dé al menos 10 comentarios.",
  "Create at least 1 Ad on your FIAT currency":
    "Cree al menos 1 anuncio en su moneda fiduciaria",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Complete al menos 1 orden de COMPRA y 1 orden de VENTA en Ethereum Chain",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "Complete al menos 1 orden de COMPRA y 1 orden de VENTA en BSC Chain",

  "Deposit or withdraw at least 1 token on Ethereum chain":
    "Depositar o retirar al menos 1 token en la cadena Ethereum",
  "Deposit or withdraw at least 1 token on BSC chain":
    "Depositar o retirar al menos 1 token en la cadena BSC",
  "Deposit at least 0.1 ETH or 0.1 BNB.":
    "Deposite al menos 0.1 eth o 0.1 bnB.",

  "Waiting for seller to release tokens":
    "Esperando a que el vendedor libere tokens",
  "Transfer the funds to seller account provided below":
    "Transfiera los fondos a la cuenta del vendedor que se proporciona a continuación",
  "Seller released tokens": "El vendedor lanzó tokens",
  "Waiting for confirmation": "esperando confirmación",
  "Dispute submitted": "Disputa presentada",
  Completed: "Terminada",
  "Your order is executed successfully": "Su orden se ejecuta con éxito",
  Submit: "Entregar",
  "Cancel Dispute": "Cancelar disputa",
  Cancelled: "Cancelada",
  "Reason:": "Razón",
  "Dispute raised": "Disputa planteada",
  "In reviewing": "Al revisar",
  Resolved: "Resuelta",
  "Your Proofs": "tus pruebas",

  "Write a review": "Eu paguei, mas o vendedor não libera a moeda",
  "Transfer the funds to seller account provided below":
    "Transfiera los fondos a la cuenta del vendedor que se proporciona a continuación",
  "Waiting for seller to release tokens":
    "Esperando a que el vendedor libere tokens",
  "Dispute submitted": "Disputa enviada",
  "Your Proofs": "tus pruebas",
  "Seller's Proofs": "Pruebas del vendedor",
  "Raise dispute": "Levantar disputa",
  "Upload dispute proof": "Subir prueba de disputa",
  "Raise a dispute request with valid proof":
    "Presentar una solicitud de disputa con prueba válida",
  "Upload more proofs": "Sube más pruebas",
  "Reason for dispute": "Motivo de la disputa",
  Description: "Descripción",
  "Upload Video Proof of Payment": "Subir Video Comprobante de Pago",
  Remove: "Eliminar",
  "Drag and drop more proof of payment":
    "Arrastra y suelta más comprobantes de pago",
  "Allowed file size less than 50 MB":
    "Tamaño de archivo permitido inferior a 50 MB",
  "Start video from your P2P order screen":
    "Inicie el video desde su pantalla de pedido P2P",
  "Clear all applications running in the background":
    "Borrar todas las aplicaciones que se ejecutan en segundo plano",
  "Open the payment application": "Abre la aplicación de pago",
  "Show your Name and ID clearly in the application":
    "Muestre su Nombre y DNI claramente en la aplicación",
  "Show the transaction history": "Mostrar el historial de transacciones",
  "The following things should be clearly visible. - Name and ID of the receiver. - Date and Time of the transaction. - Amount of the transaction. - Transaction Number":
    "Las siguientes cosas deben ser claramente visibles. - Nombre y DNI del receptor. - Fecha y Hora de la transacción. - Monto de la transacción. - Número de transacción",
  "Uploading...": "Uploading...",
  "I have paid, but the seller does not release the coin":
    "He pagado, pero el vendedor no suelta la moneda",
  "Seller notified": "Vendedor notificada",
  "Notify Seller": "Notificar a la vendedora",
  "Pending dispute": "Disputa pendiente",
  "Raise dispute": "Levantar disputa",
  "I paid too much": "pagué demasiado",
  "The seller payment account is wrong, can't pay":
    "La cuenta de pago del vendedor es incorrecta, no puede pagar",
  "I paid the wrong person": "Le pagué a la persona equivocada",
  "I paid for the part of the money, can't continue to pay":
    "Pagué por la parte del dinero, no puedo seguir pagando",
  Pending: "Pendiente",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "Confirmo que mi problema se resolvió y quiero cerrar esta disputa.",
  "Upload proof": "Subir prueba",
  "Upload proof of payment": "Subir comprobante de pago",
  "I confirm there is no problem and I want to cancel this transaction":
    "Confirmo que no hay problema y quiero cancelar esta transacción",
  warningcancel_seller:
    "¿Está seguro de que desea cancelar esta transacción? Tenga en cuenta que PolkaBridge no manejará disputas posteriores a la cancelación, si el comprador ya pagó.", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?": "Hola, ¿cómo podemos ayudarte?",
  Support: "Apoyo",
  "Raise Ticket": "Recaudar boleto",
  "Watch Tutorial": "Ver el tutorial",
  "Video Tutorial": "Tutorial de video",
  Open: "Abierto",
  Official: "Oficial",
  "Email Us": "Envíanos un correo electrónico",
  Issues: "Asuntos",
  Description: "Descripción",
  "Upload Proof": "Prueba de carga",
  "Drag and drop the file, or": "Arrastrar y soltar el archivo, o",
  "Enter the description": "Ingrese la descripción",
  General: "General",
  "Deposit/Withdraw": "Depositar/retirar",
  Account: "Cuenta",
  Trading: "Comercio",
  Others: "Otros",
  Telegram: "Telegram",
  "Show Online Users": "Mostrar usuarios en línea",
  "Tokens Locked": "Tokens bloqueados",
  "Payment Done": "Pago hecho",
  "In Dispute": "En disputa",
  Date: "Fecha",
  Ascending: "Ascendente",
  Descending: "Ascendente",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "Su boleto ha sido enviado y será revisado por nuestro equipo. El problema se resolverá dentro de las 24 horas. Gracias por su paciencia.",
  "Ticket History": "Historial de boletos",
  Date: "Fecha",
  "Ticket ID": "Identificación de entradas",
  Issue: "Asunto",
  Reviewing: "Revisión",
  Resolved: "Resuelto",
  Pending: "Pendiente",
  Authenticating: "Autenticación",
  "Admin Remark": "Comentario del administrador",
  Message: "Mensaje",
  "Price Ascending": "Precio ascendente",
  "Price Descending": "Precio descendente",
  "Latest Ads": "Últimos anuncios",
  "Latest Orders": "Últimos pedidos",
  "Completed Trades": "Operaciones completadas",
  "User Guide": "Guía del usuario",
  "Beginner's Guide": "Guía para principiantes",
  "Video Tutorial": "Tutorial de video",
  "P2P trading FAQ": "Preguntas frecuentes de comercio P2P",
  "Admin Message": "Mensaje de administración",
  "Ticket History": "Historial de entradas",
  "Review Submitted Successfully": "Revisión enviada con éxito",
  "Transaction Hash": "Hachís",
  "Ticket Details": "Detalles del boleto",
  Ended: "Terminado",
  "Round 1 Ended": "La ronda 1 terminó",
  "Redeem your points before Round 2 starts":
    "Redime sus puntos antes de que comience la Ronda 2",
  "Redeem History": "Redimir la historia",
  "Redeem Time": "El tiempo de redimir",
  Address: "DIRECCIÓN",
  pending: "pendiente",
  completed: "terminado",
  rejected: "rechazado",
  "Redeemed At": "Redimido en",
  "Redeem Detail": "Detalles de redimir",
  "Transaction ID": "ID de transacción",
  "Admin Remark": "Comentario administrativo",
  rejected: "rechazado",
  "Profile Tab": "Pestaña de perfil",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "Haga clic para actualizar su perfil como nombre, ID de usuario, móvil no, correo electrónico y, lo más importante, para agregar sus opciones de pago prefabricadas.",
  "Quest Tab": "Pestaña de búsqueda",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "Haga clic para participar en la campaña de búsqueda en curso y para reclamar puntos para obtener recompensas de $ PBR.",
  "LeaderBoard Tab": "Pestaña de la tabla de clasificación",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "Haga clic para ver la clasificación exclusiva para los comerciantes de Polkabridge.",
  "Buy Vs Sell": "Comprar vs vender",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "Al seleccionar la opción de compra, puede comprar criptografía con sus monedas fiduciarias prefabricadas.",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "Al seleccionar la opción de venta, puede vender Crypto con sus monedas Fiat prefabricadas.",
  "Filter Tool": "Herramienta de filtro",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "Haga clic para ordenar los anuncios por sus preferencias o use el filtro para configurar los anuncios que se muestran. Las opciones de filtro se basan en la disponibilidad de su región/moneda.",
  "Advertiser Info": "Información del anunciante",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "Aquí ve el perfil del comerciante, el estado en línea, la tasa de finalización, el número total de pedidos completos y la insignia de comerciantes. Haga clic en el nombre del comerciante para ver más información.",
  "Price and Limits": "Precio y límites",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "Aquí puede ver el precio de 1 moneda que el usuario desea comerciar, su cantidad de disponibilidad y la cantidad más baja y más alta que el usuario está dispuesto a comerciar.",
  "Payment options": "Opciones de pago",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "Aquí puede ver el modo de pagos que el anunciante acepta intercambiar tokens/fiat.",
  "Lowest Fees": "Tarifas más bajas",
  "Buy crypto with lowest fees ever!":
    "¡Compre criptografía con las tarifas más bajas de la historia!",
  "P2P Wallet": "Billetera P2P",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "Haga clic para verificar su saldo y administrar depósitos y retiros de sus activos y su historial.",
  Next: "Próximo",
  Skip: "Saltar",
  Close: "Cerca",
  "Create Order Tab": "Pestaña Crear pedido",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "Haga clic en el botón para crear un pedido de compra/venta utilizando sus monedas/tokens/modos de pago preferidos.",
  "Switch Network": "Cambiar de red",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "Nuestra plataforma P2P está diseñada para admitir multichain, lo que significa que tiene la flexibilidad de realizar operaciones en cualquier cadena de su elección.",
  "24h Trading Volume": "Volumen comercial de 24 h",
  "24h Total Orders": "24 horas de pedidos totales",
  "Show Users Following": "Mostrar a los usuarios seguidores",
  Following: "Siguiente",
  Online: "En línea",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "Por favor, canjee sus puntos antes del 24 de marzo, 00:00 am UTC. El número mínimo de puntos para convertir es 100 puntos",
  "User's Statistics": "Estadísticas del usuario",
  Phone: "Teléfono",
  Follower: "Seguidor",
  "All Trades": "Todos los oficios",
  "Total Buy Orders": "Pedidos totales de compra",
  "Total Sell Orders": "Total de venta de órdenes de venta",
  Share: "Compartir",
  "Reopen Ticket": "Boleto reabrir",
  Comment: "Comentario",
  "Add your comment": "Añade tu comentario",
  "Comment must be less than 5000 characters":
    "El comentario debe ser menos de 5000 caracteres",
  "Comment is required": "Se requiere comentario",
  "Reopen Date": "Reabrir fecha",
  Proof: "Prueba",
  "Share your P2P profile": "Comparte tu perfil P2P",
  Sort: "Clasificar",
  "Select Token": "Seleccionar token",
  "Select Fiat": "Seleccionar Fiat",
  "Date Range": "Rango de fechas",
  "Enter username": "Introduzca su nombre de usuario",
  "Trade Currency": "Moneda comercial",
  "Enter your message for seller": "Ingrese su mensaje para el vendedor",
  "Deposit Token": "Token de depósito",
  "Withdraw Token": "Retirar token",
  "Insufficient!": "¡Insuficiente!",
  Wait: "Esperar",
  "Insufficient funds!": "¡Fondos insuficientes!",
  "Insufficient withdraw!": "¡Atrapado insuficiente!",
  Approve: "Aprobar",
  "Approving...": "Aprobatorio...",
  "Issues with Deposit?": "¿Problemas con el depósito?",
  "All Networks": "Todas las redes",
  "Enter amount": "Ingresar la cantidad",
  "Enter price": "Ingresar precio",
  "Select payment methods": "Seleccionar métodos de pago",
  "Enter order min and max limits": "Ingrese los límites de orden min y máximo",
  "Enter valid min limit": "Ingrese el límite mínimo válido",
  "Max limit must be less than": "El límite máximo debe ser menor que",
  "Min limit must be less than": "El límite mínimo debe ser menor que",
  "Preview order": "Orden de vista previa",
  min: "mínimo",
  Global: "Global",
  "Enter your name": "Introduzca su nombre",
  "Choose your username": "Elija su nombre de usuario",
  "Enter your email": "Introduce tu correo electrónico",
  "Your Full Name": "Tu nombre completo",
  "A/c Number": "Número de A/C",
  "Account number": "Número de cuenta",
  "Bank Name": "Nombre del banco",
  "IFSC Code": "Código IFSC",
  "Note (Optional)": "Nota (opcional)",
  "Additional Notes": "Notas adicionales",
  "Phone Number": "Número de teléfono",
  "Account phone number": "Número de teléfono de la cuenta",
  "Payment QR Code (Optional)": "Código QR de pago (opcional)",
  "Please enter your QR Code": "Ingrese su código QR",
  Day: "Día",
  Week: "Semana",
  Month: "Mes",
  Months: "Meses",
  hash: "picadillo",
  "Enter txn hash": "Ingrese TXN Hash",
  Success: "Éxito",
  Failed: "Fallido",
  Review: "Revisar",
  Dropped: "Abandonó",
  LongPending: "Longitud",
  "Redeem Ended": "El canje termina",
  "Please enter your bank account number":
    "Ingrese su número de cuenta bancaria",
  "Bank Name": "Nombre del banco",
  "Enter the name of your bank": "Ingrese el nombre de su banco",
  "Account opening branch(Optional)": "Rama de apertura de cuenta (opcional)",
  "Enter bank branch information":
    "Ingrese la información de la sucursal bancaria",
  "Paypal ID / Number": "ID / Número de PayPal",
  "Perfect Money ID / Number": "ID / número de dinero perfecto",
  "Bank Details (Optional)": "Detalles bancarios (opcionales)",
  "Please enter your bank details": "Ingrese sus datos bancarios",
  "Country of Residence (Optional)": "País de residencia (opcional)",
  "Please enter your country of residence": "Ingrese su país de residencia",
  "Username (Optional)": "Nombre de usuario (opcional)",
  "Account Username": "Nombre de usuario",
  "Account Email": "Cuenta de email",
  "Wallet ID": "ID de billetera",
  "Revtag (Optional)": "Revtag (opcional)",
  "Please enter your Revtag": "Por favor ingrese su revestimiento",
  "Phone number (Optional)": "Numero de teléfono (opcional)",
  "Please enter payment details": "Ingrese los detalles de pago",
  "Account number (USD)": "Número de cuenta (USD)",
  "Account number (KHR)(Optional)": "Número de cuenta (KHR) (opcional)",
  "Payeer account number": "Número de cuenta de Payer",
  "Enter your Payeer account number": "Ingrese su número de cuenta de Payer",
  "Email (Optional)": "Email opcional)",
  "Account Email (Not required)":
    "Correo electrónico de la cuenta (no requerido)",
  "Email or Username": "Correo electrónico o nombre de usuario",
  "Account Email or Username":
    "Correo electrónico de cuenta o nombre de usuario",
  "Full Name of Receiver": "Nombre completo del receptor",
  "Receivers's Full Name": "Nombre completo de los receptores",
  "Payment details (Optional)": "Detalles de pago (opcionales)",
  "ID Number": "Número de identificación",
  "Please enter your National ID Number":
    "Ingrese su número de identificación nacional",
  "Account Type": "Tipo de cuenta",
  "Enter your account type": "Ingrese su tipo de cuenta",
  "Name and Lastname": "Nombre y apellido",
  "Enter Name and Lastname": "Ingrese Nombre y LastName",
  "Full Name": "Nombre completo",
  "Enter Full Name": "Ingrese el nombre completo",
  Cashtag: "Cashtag",
  "Please enter your Cashtag": "Ingrese su Cashtag",
  "Full Name (Optional)": "Nombre completo (opcional)",
  "ID Number (Optional)": "Número de identificación (opcional)",
  "Address (Optional)": "Dirección (opcional)",
  "Residential Address": "Dirección residencial",
  "Mobile Number": "Número de teléfono móvil",
  "Enter your Mobile Number available for all networks":
    "Ingrese su número de móvil disponible para todas las redes",
  "Contact Number": "Número de contacto",
  "Phone Number": "Número de teléfono",
  Operator: "Operador",
  "Mobile / Landline / Data / TV": "Móvil / fijo / datos / TV",
  "Enter account opening branch (Not required)":
    "Ingrese la rama de apertura de la cuenta (no requerida)",
  "IBAN Number": "Número IBAN",
  "BIC/SWIFT Code(Optional)": "Código Bic/Swift (opcional)",
  "BIC/SWIFT Code": "Código Bic/Swift",
  "Enter international bank account number":
    "Ingrese el número de cuenta bancaria internacional",
  "SWIFT BIC (Optional)": "Swift BIC (opcional)",
  "Enter your bank identification code":
    "Ingrese su código de identificación bancaria",
  "GB IBAN account number": "Número de cuenta GB Iban",
  "IBAN (Optional)": "Iban (opcional)",
  "Other payment details (for international transfer) (Optional)":
    "Otros detalles de pago (para transferencia internacional) (opcional)",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "Detalles como su propia dirección, la dirección del banco, el código Iban y el código Swiftbic, etc.",
  "Bank Branch (Optional)": "Sucursal bancaria (opcional)",
  "Recommended Transfer Remarks (Optional)":
    "Observaciones de transferencia recomendadas (opcional)",
  "Recommended Transfer Remarks": "Observaciones de transferencia recomendadas",
  'Please enter your MoMo phone number"':
    'Ingrese su número de teléfono de Momo "',
  "Please enter your MoMo QR Code": "Ingrese su código MOMO QR",
  "Please enter your Zalopay QR Code": "Ingrese su código Zalopay QR",
  "Additional account number (Optional)":
    "Número de cuenta adicional (opcional)",
  "Please enter additional account number":
    "Ingrese el número de cuenta adicional",
  "Branch code (Optional)": "Código de rama (opcional)",
  "Enter branch code (not required)":
    "Ingrese el código de la rama (no requerido)",
  "IBAN (Optional)": "Iban (opcional)",
  "Please enter the IBAN code of your Bank (not required)":
    "Ingrese el código Iban de su banco (no es necesario)",
  "IBAN or account number": "Iban o número de cuenta",
  "Please enter your IBAN or account number":
    "Ingrese su número de cuenta o cuenta IBAN",
  "Papara account number": "Número de cuenta de papara",
  "Please enter your Papara account number":
    "Ingrese su número de cuenta de Papara",
  City: "Ciudad",
  "Please enter your city": "Por favor ingrese a su ciudad",
  "Zip Code": "Código postal",
  "Please enter your city zipcode": "Introduzca el código postal de su ciudad",
  Country: "País",
  "Please enter Country": "Por favor ingrese el país",
  "Moneygram Account Number": "Número de cuenta de Moneygram",
  "Please enter your Moneygram account number":
    "Ingrese su número de cuenta de MoneyGram",
  "Enter bank branch information":
    "Ingrese la información de la sucursal bancaria",
  "Please enter your BCA account number": "Ingrese su número de cuenta de BCA",
  "Please enter your Mandiri account number":
    "Ingrese su número de cuenta de Mandiri",
  "Chipper Cash full name": "Nombre completo de Chipper Cash Full",
  "Please enter your full name used to register on chipper cash":
    "Ingrese su nombre completo utilizado para registrarse en el efectivo de Chipper",
  "Chipper Tag": "Etiqueta de chipper",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "Ingrese su @Chippertag. Puede encontrarlo en su pestaña de perfil en el efectivo de Chipper",
  "QIWI account": "Cuenta QIWI",
  "Please enter your QIWI account": "Ingrese su cuenta QIWI",
  "Your email registered on Binance":
    "Su correo electrónico registrado en Binance",
  "Please enter your email registered on Binance":
    "Ingrese su correo electrónico registrado en Binance",
  "Payment Details": "Detalles del pago",
  "WeChat account": "Cuenta de WeChat",
  "Please enter your WeChat account": "Ingrese su cuenta de WeChat",
  "Receive QR Code (Optional)": "Recibir código QR (opcional)",
  "Alipay account": "Cuenta de Alipay",
  "Please enter your Alipay account": "Ingrese su cuenta de Alipay",
  "Payee's LINE ID": "ID de línea de beneficiario",
  "Please fill in your LINE ID": "Complete su ID de línea",
  "Please fill in your phone number registered with LINE":
    "Complete su número de teléfono registrado en línea",
  "Linepay QR Code (Optional)": "Código QR de LinePay (opcional)",
  "Reference number or barcode": "Número de referencia o código de barras",
  "Please enter reference number or barcode":
    "Ingrese el número de referencia o el código de barras",
  "Remarks (Optional)": "Observaciones (opcionales)",
  "Additional Remarks": "Observaciones adicionales",
  "Cell Phone Number": "Número Celular",
  "Please enter your cell phone number":
    "Ingrese su número de teléfono celular",
  "Enter your ID number": "Ingrese su número de identificación",
  "Fill in your phone number": "Complete su número de teléfono",
  "Full Name of Receiver": "Nombre completo del receptor",
  "Please enter your Full Name": "Por favor ingresa tu nombre completo",
  CBU: "CBU",
  "Please enter your CBU": "Por favor ingrese su CBU",
  "CUIT / CUIL": "Cuil / cuil",
  "Enter your unique tax identification code":
    "Ingrese su código de identificación fiscal único",
  "Please enter your CVU": "Por favor ingrese su CVU",
  Alias: "Alias",
  "Please enter Alias": "Por favor ingrese alias",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "Etiqueta de billetera de limón",
  "Uniform Bank Key": "Clave del banco uniforme",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "Ingrese su número DNI/CPF",
  "CVU (Optional)": "CVU (opcional)",
  "Uniform Virtual Key": "Clave virtual uniforme",
  "CBU/CVU or": "CBU/CVU o",
  "alias CVU/CBU or CVU/CBU number": "Número de alias CVU/CBU o CVU/CBU",
  "Alias del CBU": "Alias ​​del cbu",
  "Vodafone cash Number": "Número de efectivo de vodafone",
  "Please enter your vodafone cash Number":
    "Ingrese su número de efectivo de Vodafone",
  "Sort code": "Código de clasificacion",
  "Please fill in your 6-digit sort code":
    "Complete su código de clasificación de 6 dígitos",
  "Sort Code(Optional)": "Código de clasificación (opcional)",
  "GB IBAN account number": "Número de cuenta GB Iban",
  "IIN (Optional)": "Iin (opcional)",
  "Enter individual identification number":
    "Ingrese el número de identificación individual",
  "Please enter your bank card number": "Ingrese su número de tarjeta bancaria",
  "Mobile Carrier": "Operador de telefonía móvil",
  "Please enter your mobile carrier": "Ingrese su transportista móvil",
  "Easypaisa mobile account": "Cuenta móvil Easypaisa",
  "Please enter your Easypaisa mobile account":
    "Ingrese su cuenta móvil Easypaisa",
  "NayaPay Id": "Identificación de Nayapay",
  "Please enter NayaPay Id": "Ingrese la identificación de Nayapay",
  "Enter Brazilian valid document": "Ingrese el documento válido brasileño",
  "Bank agency": "Agencia bancaria",
  "Enter the name of your bank agency":
    "Ingrese el nombre de su agencia bancaria",
  "Type of transfer": "Tipo de transferencia",
  "Please filled with TED/DOC/PIX": "Lleno de Ted/Doc/Pix",
  "Name and Last Name": "Nombre y apellido",
  "Pix Key": "Llave de las pix",
  "Phone, email or key": "Teléfono, correo electrónico o clave",
  'Type of Bank"': 'Tipo de banco "',
  "Name registered in PicPay": "Nombre registrado en Picpay",
  "PicPay username": "Nombre de usuario de Picpay",
  "Username used to log in in PicPay":
    "Nombre de usuario utilizado para iniciar sesión en Picpay",
  "urpay Account Details": "Detalles de la cuenta de Urpay",
  "Please enter your urpay account details":
    "Ingrese los detalles de su cuenta de Urpay",
  "bKash Wallet Number": "número de billetera bkash",
  "Please enter your 11 digit registered bKash account number":
    "Ingrese su número de cuenta BKASH registrado de 11 dígitos",
  "Nagad Wallet Number": "Número de billetera de Nagad",
  "Please enter your 11 digit registered Nagad wallet number":
    "Ingrese su número de billetera Nagad registrado de 11 dígitos",
  "Please enter your full name registered with Rocket account":
    "Ingrese su nombre completo registrado con una cuenta de cohete",
  "Rocket Wallet Number": "Número de billetera de cohete",
  "Please enter your 12 digit Rocket Wallet number":
    "Ingrese su número de billetera de cohete de 12 dígitos",
  "Please enter your JKOPAY ID": "Ingrese su identificación de Jkopay",
  "EasyPay wallet number (Optional)": "Número de billetera EasyPay (opcional)",
  "Please fill in EasyPay wallet number":
    "Complete el número de billetera Easypay",
  "User API Key": "Clave de API de usuario",
  "User Secret Key": "Clave de secreto del usuario",
  "Bank account number": "número de cuenta bancaria",
  PayID: "Payid",
  "Please enter your PayID": "Por favor ingrese su PayID",
  "Institution number": "Número de institución",
  "Please enter institution number": "Ingrese el número de la institución",
  "PayCode (Optional)": "Código de pago (opcional)",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "Se puede encontrar en mi cuenta -> mi código de pago -> copiar el enlace",
  "Please fill in your mobile phone number connected with your PayMe":
    "Complete su número de teléfono móvil conectado con su PayMe",
  Cellphone: "Teléfono móvil",
  "Card Number or password": "Número de tarjeta o contraseña",
  "Please fill in card Number or password":
    "Complete el número de tarjeta o la contraseña",
  "Purpose of payment (Optional)": "Propósito del pago (opcional)",
  "Enter a payment purpose for buyers":
    "Ingrese un propósito de pago para compradores",
  "Card number, account or key": "Número de tarjeta, cuenta o clave",
  "CLABE number (Optional)": "Número de clabe (opcional)",
  "CLABE number": "Número de clabe",
  "Account number/card": "Número de cuenta/tarjeta",
  "Reference number or card number": "Número de referencia o número de tarjeta",
  "Please enter reference number or card number":
    "Ingrese el número de referencia o el número de tarjeta",
  "Provide transfer details during the trade":
    "Proporcionar detalles de transferencia durante el comercio",
  "Variable symbol (Optional)": "Símbolo variable (opcional)",
  "Enter Variable symbol": "Ingrese el símbolo variable",
  "Enter your PayNow account": "Ingrese su cuenta de PayNow",
  "Account Name": "Nombre de la cuenta",
  "Email Address": "Dirección de correo electrónico",
  "Please enter email address": "Ingrese la dirección de correo electrónico",
  "Please enter Your Full Name": "Por favor ingresa tu nombre completo",
  "Account Number": "Número de cuenta",
  "Enter your phone number": "Ingrese su número telefónico",
  "Enter your account number": "Ingrese su número de cuenta",
  "Enter your bank account number": "Ingrese su número de cuenta bancaria",
  "Payment details": "Detalles del pago",
  "Bank name": "Nombre del banco",
  "Please enter your MoMo phone number":
    "Ingrese su número de teléfono de Momo",
  "Please enter your email": "Por favor introduzca su correo electrónico",
  "Enter branch code": "Ingrese el código de rama",
  "Please enter the IBAN code of your Bank":
    "Ingrese el código Iban de su banco",
  "Account opening branch (Optional)": "Rama de apertura de cuenta (opcional)",
  "Enter account opening branch (not required)":
    "Ingrese la rama de apertura de la cuenta (no requerida)",
  "Please enter your Bank account number":
    "Ingrese su número de cuenta bancaria",
  "Please enter your ID Number": "Ingrese su número de identificación",
  "Enter bank account type": "Ingrese el tipo de cuenta bancaria",
  "Bank Branch (Optional)": "Sucursal bancaria (opcional)",
  "Account type": "Tipo de cuenta",
  "Please enter bank branch information":
    "Ingrese la información de la sucursal bancaria",
  "Please enter Email address": "Ingrese la dirección de correo electrónico",
  "Phone number(Optional)": "Numero de teléfono (opcional)",
  "Phone Number (Optional)": "Numero de teléfono (opcional)",
  "Additional information": "Información adicional",
  "Type of Bank": "Tipo de banco",
  "Remark (Optional)": "Observación (opcional)",
  "Mobile Phone": "Teléfono móvil",
  "Enter the bank code": "Ingrese el código bancario",
  "Please enter your phone number":
    "Por favor, introduzca su número de teléfono",
  "Please enter your Bank name": "Ingrese el nombre de su banco",
};

export default SPANISH;
