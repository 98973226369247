import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import VideoPreviewPopup from "./popups/VideoPreviewPopup";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  media: {
    maxWidth: "90%",
    maxHeight: "90%",
  },
}));

const ProofList = ({ title, proofs }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayUrl, setOverlayUrl] = useState("");
  const handleClose = () => {
    setShowOverlay(false);
    setOverlayUrl("");
  };

  const getFormattedVideoUrl = (url) => {
    if (!url) {
      return "";
    }
    const paths = url.split("/p2p/");
    const finalUrl = `https://polkabridge.sgp1.digitaloceanspaces.com/p2p/${paths?.[1]}`;
    return finalUrl;
  };

  return (
    <Box mt={1} width="100%">
      <VideoPreviewPopup
        popupActive={showOverlay}
        resetPopup={() => setShowOverlay(false)}
        videoUrl={overlayUrl}
      />
      <Typography
        textAlign="left"
        variant="body2"
        fontSize={14}
        fontWeight={600}
        color={theme.palette.text.primary}
      >
        <FormattedMessage id={title} defaultMessage={title} />
      </Typography>
      <Box display="flex" alignItems="center" width="100%" flexWrap="wrap">
        {proofs?.map((proof, index) => {
          return (
            <Box key={index}>
              <video
                width={100}
                height={70}
                style={{
                  margin: 3,
                  border: "1px solid #f0f0f0",
                  borderRadius: 5,
                }}
                onClick={() => {
                  setShowOverlay(true);
                  setOverlayUrl(getFormattedVideoUrl(proof));
                }}
              >
                <source src={getFormattedVideoUrl(proof)} />
              </video>
            </Box>
          );
        })}
      </Box>
      {/* {showOverlay && (
        <div className={classes.overlay} onClick={handleClose}>
          <video className={classes.media} controls>
            <source src={overlayUrl} />
          </video>
        </div>
      )} */}
    </Box>
  );
};

export default ProofList;
