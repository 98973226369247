import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransactionStatus, Token, TransactionState } from "../utils/interface";
import useActiveWeb3React from "./useActiveWeb3React";
import useBlockNumber from "./useBlockNumber";
import { useFaucetContract, useP2pContract } from "./useContract";
import {
  fetchBalances,
  recordDepositHash,
  withdrawQuote,
} from "../actions/walletActions";
import { withdrawTxn } from "../utils/httpCalls/txnCall";
import pusher from "../utils/pusherInstance";
import {
  getReadOnlyWeb3Provider,
  getTransactionWithRetry,
} from "../utils/contractUtils";
import { recordLog } from "utils/httpCalls/logCalls";

function isNativeToken(token: Token) {
  if (token?.symbol === "ETH" || token?.symbol === "BNB") {
    return true;
  }
  return false;
}

export function useDepositCallback(
  token?: Token,
  token_id?: string,
  vaultId?: string
): {
  depositTokens: (tokenAmount: string) => {};
  claimTokens: (tokenAddress: string) => {};
  withdrawTokens: () => {};
  resetTrxState: () => void;
  fetchWithdrawQuote: (tokenAmount: string, token_id: string) => {};
  transactionStatus: TransactionStatus;
} {
  const { account, chainId } = useActiveWeb3React();
  const p2pContract = useP2pContract();
  const faucetContract = useFaucetContract();
  const initialState: TransactionStatus = {
    hash: "",
    status: null,
    state: 0,
    type: null,
    tokenAmount: null,
    account: null,
    token: null,
    tokenId: token_id,
  };
  const [data, setData] = useState<TransactionStatus>(initialState);
  const blockNumber = useBlockNumber();
  // const fastFarwardBlockNumber = useFastForwardBlockNumber();
  const userAuth = useSelector((state: any) => state?.user);
  const dispatch = useDispatch();

  const depositTokens = useCallback(
    async (tokenAmount?: string) => {
      try {
        const depositTokens = tokenAmount;
        console.log(token, chainId);

        if (
          !depositTokens ||
          !token ||
          !chainId ||
          chainId !== token?.chainId ||
          !userAuth?.account ||
          !userAuth?.jwtToken ||
          !vaultId
        ) {
          console.log("depsoit failed invalid trx params ", {
            depositTokens,
            token,
            chainId,
          });
          setData({
            ...data,
            status: TransactionState.FAILED,
            state: 4,
            type: "deposit",
          });
          return;
        }

        setData({
          ...data,
          status: TransactionState.WAITING,
          state: 1,
          type: "deposit",
        });
        let trxRes: any = null;
        if (isNativeToken(token)) {
          trxRes = await p2pContract?.depositETH({
            value: depositTokens,
            gasLimit: 200000,
          });
        } else {
          console.log("handle token deposits ", {
            tokenAddress: token?.address,
            amnt: depositTokens,
          });
          trxRes = await p2pContract?.deposit(token?.address, depositTokens, {
            gasLimit: 200000,
          });
          console.log(trxRes);
        }
        setData({
          ...data,
          hash: trxRes?.hash,
          status: TransactionState.PENDING,
          state: 5,
          type: "deposit",
          tokenAmount: tokenAmount,
          token: token,
          account: userAuth?.account,
        });

        //save record in local storage temporarily
        const pendingRecord = {
          hash: trxRes?.hash,
          chainId: chainId,
          account: account,
          tokenAmount: tokenAmount,
          token: token_id,
          vault: vaultId,
        };
        localStorage.setItem(
          `pending_deposit_${account}`,
          JSON.stringify(pendingRecord)
        );
        //start seacrhing
        const web3 = getReadOnlyWeb3Provider(chainId);

        console.log("finding txn in pool.....");
        const txnData = await getTransactionWithRetry(web3, trxRes.hash);
        console.log("found txn in node", txnData);

        console.log("waiting 3 sec");
        await sleep(3000);
        console.log("back");

        //update after searching done
        setData({
          ...data,
          hash: trxRes?.hash,
          status: TransactionState.PENDING,
          state: 2,
          type: "deposit",
          tokenAmount: tokenAmount,
          token: token,
          account: userAuth?.account,
        });

        // dispatch action to record hash to be deposited
        dispatch(
          recordDepositHash(
            account,
            chainId,
            token_id,
            vaultId,
            trxRes?.hash,
            depositTokens,
            userAuth?.jwtToken
          )
        );
        localStorage.removeItem(`pending_deposit_${account}`);
      } catch (error) {
        const logPayload = {
          message: "token depsit error",
          functionName: "depositTokens",
          error: JSON.stringify(error?.message),
        };
        recordLog(logPayload, userAuth?.account, userAuth?.jwtToken);
        setData({
          ...data,
          status: TransactionState.FAILED,
          state: 4,
          type: "deposit",
        });
        localStorage.removeItem("pending_deposit");
        console.log("depositTokens trx error ", { error });
      }
    },
    [
      p2pContract,
      token,
      data,
      setData,
      chainId,
      account,
      token_id,
      userAuth?.jwtToken,
      userAuth?.account,
      vaultId,
      dispatch,
    ]
  );
  const claimFaucet = useCallback(
    async (tokenAddress?: string) => {
      try {
        setData({
          ...data,
          status: TransactionState.WAITING,
          state: 1,
          type: "claim",
        });

        // console.log(tokenAddress);
        // console.log(faucetContract);
        const txResponse = await faucetContract?.claimTokens(tokenAddress);

        // console.log(txResponse);

        setData({
          ...data,
          hash: txResponse?.hash,
          status: TransactionState.PENDING,
          state: 2,
          type: "claim",
        });
      } catch (error) {
        setData({
          ...data,
          status: TransactionState.FAILED,
          state: 4,
          type: "claim",
        });

        console.log("depositTokens trx error ", { error });
      }
    },
    [faucetContract, data, setData]
  );

  const fetchWithdrawQuote = useCallback(
    async (tokenAmount?: string, tokenId?: string) => {
      if (
        !tokenAmount ||
        !tokenId ||
        !account ||
        !chainId ||
        !userAuth?.jwtToken
      ) {
        return;
      }

      if (tokenId !== token_id) {
        return;
      }
      dispatch(
        withdrawQuote(
          account,
          chainId,
          tokenId,
          tokenAmount,
          userAuth?.jwtToken
        )
      );
    },
    [account, chainId, token_id, userAuth?.jwtToken, dispatch]
  );

  const withdrawTokens = useCallback(
    async (tokenAmount?: string) => {
      try {
        setData({
          ...data,
          status: TransactionState.WAITING,
          state: 1,
          type: "withdraw",
          tokenAmount: tokenAmount,
          token: token,
          account: userAuth?.account,
          tokenId: token_id,
        });

        if (
          !tokenAmount ||
          !token ||
          !chainId ||
          !account ||
          !token_id ||
          chainId !== token?.chainId
        ) {
          setData({
            ...data,
            status: TransactionState.FAILED,
            state: 4,
            type: "withdraw",
          });
          return;
        }

        let trxRes: any = null;
        trxRes = await withdrawTxn(
          {
            tokenId: token_id,
            tokenAmount: tokenAmount,
            time: `${Math.floor(Date.now() / 1000)}`,
            note: "note",
          },
          userAuth?.account,

          userAuth?.jwtToken
        );
        console.log(trxRes);

        if (trxRes?.status !== 201) {
          setData({
            ...data,
            status: TransactionState.FAILED,
            state: 4,
            type: "withdraw",
          });
          return;
        }

        dispatch(fetchBalances(account, chainId, userAuth?.jwtToken));
      } catch (error) {
        setData({
          ...data,
          status: TransactionState.FAILED,
          state: 4,
          type: "withdraw",
        });

        console.log("withdrawTokens error ", error);
      }
    },
    [
      token,
      token_id,
      data,
      setData,
      chainId,
      account,
      userAuth?.jwtToken,
      userAuth?.account,
      dispatch,
    ]
  );

  const resetTrxState = useCallback(() => {
    setData(initialState);
  }, [setData, data]);

  useEffect(() => {
    setData(initialState);
  }, []);

  // subscribe and check for withdraw transaction response from realtime events
  useEffect(() => {
    if (!token_id || !userAuth?.id || !account || !chainId) {
      return;
    }
    const transactionChannel = `transaction_${userAuth?.id}_${token_id}`;
    const channel = pusher.subscribe(transactionChannel);
    channel.bind("transaction_submitted", (responseData: any) => {
      // console.log("pusher service message ", data);

      if (!responseData?.success) {
        // setData({
        //   ...data,
        //   status: TransactionState.FAILED,
        //   state: 4,
        //   type: "withdraw",
        // });
        // dispatch balance update
        dispatch(fetchBalances(account, chainId, userAuth?.jwtToken));

        return;
      }
      // setData({
      //   ...data,
      //   hash: responseData?.hash,
      //   status: TransactionState.PENDING,
      //   state: 2,
      //   type: "withdraw",
      // });
    });

    return () => {
      pusher.unsubscribe(transactionChannel);
    };
  }, [account, chainId, token_id, userAuth]);

  // check and update pending deposits from hash record
  useEffect(() => {
    async function checkAndUpdate() {
      if (!account || !chainId || !userAuth?.jwtToken) {
        return;
      }

      const pendingRecord = localStorage.getItem(`pending_deposit_${account}`);

      if (!pendingRecord) {
        return;
      }

      const txn = JSON.parse(pendingRecord);

      if (!txn?.hash) {
        return;
      }

      dispatch(
        recordDepositHash(
          txn.account,
          txn.chainId,
          txn.token,
          txn.vault,
          txn.hash,
          txn.tokenAmount,
          userAuth?.jwtToken
        )
      );
      localStorage.removeItem(`pending_deposit_${account}`);
    }

    if (!account || !chainId || !userAuth?.jwtToken) {
      return;
    }

    checkAndUpdate();
  }, [userAuth, account, chainId, token_id]);

  // useEffect(() => {
  //   if (!data?.hash) {
  //     return;
  //   }

  //   if (
  //     data?.status === TransactionState.COMPLETED ||
  //     data?.status === TransactionState.FAILED
  //   ) {
  //     return;
  //   }

  //   library
  //     ?.getTransactionReceipt(data?.hash)
  //     .then((res) => {
  //       if (res?.blockHash && res?.blockNumber) {
  //         // call update deposits at backend
  //         // update balance in transaction update
  //         // setData({ ...data, status: TransactionState.COMPLETED, state: 3 });
  //         fastFarwardBlockNumber(res?.blockNumber);

  //         if (data?.type === "deposit") {
  //           console.log("updating deposit requesting action ", {
  //             account,
  //             chainId,
  //             token_id,
  //             vaultId,
  //             hash: data?.hash,
  //             auth: userAuth?.jwtToken,
  //           });
  //           dispatch(
  //             updateDeposit(
  //               account,
  //               chainId,
  //               token_id,
  //               vaultId,
  //               data?.hash,
  //               userAuth?.jwtToken
  //             )
  //           );
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("transaction failed ", err);
  //       console.log("fetch latest init fetch action from deposit error");
  //       dispatch(fetchBalances(account, chainId, userAuth?.jwtToken));
  //       // setData({ ...data, status: TransactionState.FAILED, state: 4 });
  //     });
  // }, [blockNumber]);
  useEffect(() => {
    dispatch(fetchBalances(account, chainId, userAuth?.jwtToken));
  }, [blockNumber]);

  const transactionStatus = useMemo(() => {
    return {
      status: data?.status,
      hash: data?.hash,
      state: data?.state,
      type: data?.type,
      tokenAmount: data?.tokenAmount,
      account: data?.account,
      token: data?.token,
      tokenId: data?.tokenId,
    };
  }, [data]);
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  return {
    depositTokens: depositTokens,
    claimTokens: claimFaucet,
    withdrawTokens: withdrawTokens,
    resetTrxState: resetTrxState,
    fetchWithdrawQuote: fetchWithdrawQuote,
    transactionStatus: transactionStatus,
  };
}
