import {
  Badge,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TOKEN_LOGO_PATH } from "../../constants";
import { useSelector } from "react-redux";
import {
  camelize,
  formatCurrency,
  formatTime,
  formattedTokenLabel,
  fromWei,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { CHAIN_IDS_TO_DISPLAY_NAMES } from "../../constants/chains";

const UserAdCard = ({ userAd, index }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const navigate = useNavigate();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getStatusColor = () => {
    switch (userAd?.order_status) {
      case "completed":
        return "success";
      case "cancelled":
        return "error";
      case "active":
        return "warning";
      default:
        return "info";
    }
  };

  return (
    <Box
      pl={2}
      py={2}
      bgcolor={
        index % 2
          ? theme.palette.background.primary
          : theme.palette.background.secondary
      }
      borderRadius={2}
      mb={1}
      border={
        theme.palette.mode === "light"
          ? "1px solid #E0E0E0"
          : "1px solid #25284B"
      }
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{
        backgroundColor:
          userAd?.order?.user?._id === user?.id
            ? theme.palette.background.highlight
            : "",
        border:
          userAd?.order?.user?._id === user?.id &&
          `1px solid ${theme.palette.background.highlight}`,
      }}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="center">
            <img
              src={
                TOKEN_LOGO_PATH[userAd?.token?.symbol] ?? userAd?.token?.logo
              }
              style={{
                height: 24,
                marginRight: 8,
              }}
            />
            <Typography
              fontSize={16}
              fontWeight={600}
              color={theme.palette.text.primary}
            >
              <FormattedMessage
                id={camelize(userAd?.order_type)}
                defaultMessage={camelize(userAd?.order_type)}
              />
              {` ${formattedTokenLabel(userAd?.token)}`}
            </Typography>
          </Box>

          <Box>
            <Box ml={1} display="flex" alignItems="center" justifyContent="end">
              <Badge
                color={getStatusColor()}
                variant="dot"
                overlap="circular"
              />
              <Typography
                fontSize={sm ? 12 : 14}
                fontWeight={500}
                ml={1}
                color={theme.palette.text.primary}
              >
                <FormattedMessage
                  id={camelize(userAd?.order_status)}
                  defaultMessage={camelize(userAd?.order_status)}
                />
              </Typography>
            </Box>
            <Typography fontSize={12} color={theme.palette.text.secondary}>
              {formatTime(userAd?.created_at)}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Typography
                fontSize={13}
                color={theme.palette.text.secondary}
                mr={1}
              >
                <FormattedMessage id="Price" defaultMessage="Price" />
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={theme.palette.text.primary}
              >
                {`${formatCurrency(userAd?.order_unit_price, true)} ${
                  userAd?.fiat?.fiat
                }`}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Amount" defaultMessage="Amount" />
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {`${formatCurrency(
                fromWei(userAd?.pending_amount, userAd?.token?.decimals),
                true
              )} ${userAd?.token?.symbol}`}
            </Typography>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Limit" defaultMessage="Limit" />
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {`${
                userAd?.min_order_value <= 0
                  ? "1"
                  : formatCurrency(userAd?.curr_min_order_value, true)
              } ${userAd?.fiat?.fiat_label} - ${formatCurrency(
                userAd?.curr_max_order_value,
                true
              )} ${userAd?.fiat?.fiat_label}`}
            </Typography>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Chain" defaultMessage="Chain" />
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {CHAIN_IDS_TO_DISPLAY_NAMES?.[userAd?.chainId]}
            </Typography>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Order No." defaultMessage="Order No." />
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {userAd?.order_id}
            </Typography>
          </Box>
        </Box>
      </Box>
      <IconButton
        style={{ height: "100px" }}
        onClick={() => navigate(`/my-orders/${userAd?._id}`)}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default UserAdCard;
