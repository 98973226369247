import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useParams } from "react-router-dom";
import HowItWorks from "../../../common/HowItWorks";
import { getOrderDetailsById } from "../../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, fromWei, toMinutes } from "../../../utils/helper";
import BigNumber from "bignumber.js";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    paddingTop: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.background.primary,
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
  },
  subtitle: {
    color: "#414141",
    fontWeight: 400,
    fontSize: 16,
  },
  cardTitle: {
    textAlign: "center",
    color: theme.palette.text.primary,
  },

  submitButton: {
    borderRadius: 10,
    backgroundColor: "#E0077D",
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
    minWidth: 120,
    textAlign: "center",
  },
  orderTabSelected: {
    backgroundColor: "#DF097C",
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
    minWidth: 120,
    textAlign: "center",
  },
  tableCard: {
    width: "100%",
    height: "100%",
    border: "1px solid #eeeeee",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  table: {
    width: "100%",
  },
  tr: {
    width: "100%",
    height: 45,
  },
  userText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#DF097C",
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
  },
  label: {
    color: "#616161",
    fontWeight: 500,
  },
  orderBox: {
    border: "1px solid #eeeeee",
    padding: 20,
    borderRadius: "30px",
  },
  para: {
    paddingTop: 10,
    color: "#757575",
    fontSize: 14,
  },
  iconTitle: {
    paddingTop: 20,
    color: "#333333",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
  },
  iconSubtitle: {
    color: "#757575",
    fontSize: 14,
    textAlign: "center",
  },
  icon: {
    height: 50,
  },
  howTitle: {
    color: "#333333",
    fontSize: 22,
    fontWeight: 600,
    textAlign: "center",
  },
  howSubtitle: {
    width: 600,
    color: "#757575",
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
  },
}));

function OrderPlaced() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { order_id } = useParams();
  const order = useSelector((state) => state?.order?.order);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const userAuth = useSelector((state) => state?.user);

  useEffect(() => {
    if (!order_id || !userAuth?.account || !userAuth?.jwtToken) {
      return;
    }
    async function fetchData() {
      dispatch(
        getOrderDetailsById(order_id, userAuth?.account, userAuth?.jwtToken)
      );
    }
    fetchData();
  }, [order_id, userAuth?.jwtToken, userAuth?.account, dispatch]);

  return (
    <Box className={classes.background}>
      <Container>
        <Box>
          <Box>
            <Typography variant="h3" className={classes.title}>
              <FormattedMessage
                id="Create Order"
                defaultMessage="Create Order"
              />
            </Typography>
          </Box>
          <div className={classes.infoCard}>
            <Typography
              variant="h4"
              classes={classes.cardTitle}
              align="left"
              color={theme.palette.text.primary}
            >
              <FormattedMessage
                id="Order Submitted Successfully"
                defaultMessage="Order Submitted Successfully"
              />
            </Typography>
            <Box display={"flex"} justifyContent="start" mt={3}>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "#04A56D",
                      color: "white",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    1.
                  </div>{" "}
                  <div
                    style={{
                      borderTop: `1px dotted ${theme.palette.text.primary}`,
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 100 : 170,
                    }}
                  ></div>
                </div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 11 : 14}
                  fontWeight={500}
                  mt={1}
                  color={theme.palette.mode === "light" ? "#919191" : "#646892"}
                >
                  <FormattedMessage
                    id="Set type and price"
                    defaultMessage="Set type and price"
                  />
                </Typography>
              </Box>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "#04A56D",
                      color: "white",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    2.
                  </div>
                  <div
                    style={{
                      borderTop: `1px dotted ${theme.palette.text.primary}`,
                      width: "80%",
                      height: 1,
                      marginLeft: 5,
                      minWidth: sm ? 100 : 170,
                    }}
                  ></div>
                </div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 11 : 14}
                  fontWeight={500}
                  mt={1}
                  color={theme.palette.mode === "light" ? "#919191" : "#646892"}
                >
                  <FormattedMessage
                    id="Review your order"
                    defaultMessage="Review your order"
                  />
                </Typography>
              </Box>
              <Box pr={1}>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "#04A56D",
                      color: "white",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    3.
                  </div>
                </div>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 11 : 14}
                  fontWeight={500}
                  mt={1}
                  color={theme.palette.mode === "light" ? "#919191" : "#646892"}
                >
                  <FormattedMessage id="Submit" defaultMessage="Submit" />
                </Typography>
              </Box>
            </Box>
            {order && (
              <div className="row align-items-center mt-5">
                <div className="col-md-6">
                  <Box>
                    <Grid container>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Order Type"
                            defaultMessage="Order Type"
                          />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{
                            fontWeight: 600,
                            color: theme.palette.text.primary,
                            fontSize: sm ? 18 : 22,
                          }}
                        >
                          {order?.order_type?.toUpperCase()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage id="Price" defaultMessage="Price" />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {order?.order_unit_price} {order?.fiat?.fiat}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Amount"
                            defaultMessage="Amount"
                          />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {formatCurrency(
                              fromWei(
                                order?.pending_amount,
                                order?.token?.decimals
                              ),
                              true
                            )}{" "}
                            {order?.token?.symbol}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Min Order Limit"
                            defaultMessage="Min Order Limit"
                          />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {formatCurrency(order?.min_order_value, true)}{" "}
                            {order?.fiat?.fiat_label}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Max Order Limit"
                            defaultMessage="Max Order Limit"
                          />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {formatCurrency(order?.max_order_value, true)}{" "}
                            {order?.fiat?.fiat_label}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage id="Total" defaultMessage="Total" />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {formatCurrency(
                              new BigNumber(
                                fromWei(
                                  order?.pending_amount,
                                  order?.token?.decimals
                                )
                              )
                                .multipliedBy(order?.order_unit_price)
                                ?.toString(),
                              true
                            )}{" "}
                            {order?.fiat?.fiat}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Payment Time Limit"
                            defaultMessage="Payment Time Limit"
                          />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          style={{
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="left"
                            style={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {toMinutes(order?.order_transfaction_duration)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container mt={2}>
                      <Grid item md={5} sm={6} xs={6} display="flex">
                        <Typography
                          display="flex"
                          alignItems={"center"}
                          color={
                            theme.palette.mode === "light"
                              ? "#919191"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Payment"
                            defaultMessage="Payment"
                          />
                          :
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={6} xs={6}>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          {order?.payment_options?.map((value, index) => (
                            <Box
                              key={index}
                              display="flex"
                              mb={1}
                              style={{
                                backgroundColor: "#E1DCFF",
                                width: "fit-content",
                                padding: "5px 14px 5px 14px",
                                borderRadius: 7,
                                marginRight: 5,
                                fontSize: 14,
                              }}
                            >
                              {value?.toUpperCase()}
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
                <div className="col-md-6 align-self-start p-md-0">
                  <Box
                    style={{
                      width: "80%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      align="left"
                      style={{
                        marginBottom: 10,
                        color:
                          theme.palette.mode === "light"
                            ? "#919191"
                            : "#646892",
                      }}
                    >
                      <FormattedMessage id="Remark" defaultMessage="Remark" />:
                    </Typography>
                    <Typography
                      // variant="body1"
                      align="left"
                      style={{
                        marginBottom: 10,
                        color:
                          theme.palette.mode === "light"
                            ? "#616161"
                            : "#ffffff",
                        wordBreak: "break-word",
                      }}
                    >
                      {order?.description}
                    </Typography>
                  </Box>
                </div>
              </div>
            )}
            <div className="text-center mt-4">
              <Link to="/my-orders" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    borderRadius: 10,
                    background: "#6A55EA",
                    padding: "9px 35px 9px 35px",
                    color: "white",
                  }}
                >
                  <FormattedMessage
                    id="Check your order"
                    defaultMessage="Check your order"
                  />
                </Button>
              </Link>
            </div>
          </div>
          <HowItWorks />
        </Box>
      </Container>
    </Box>
  );
}

export default OrderPlaced;
