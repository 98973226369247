import {
  cancelTradeCall,
  createTrade,
  fetchFiatTransaferData,
  fetchUserTradeById,
  fetchUserTradeByOrderId,
  fetchUserTrades,
  updateTransaction,
} from "../utils/httpCalls/orderTradeCalls";
import {
  CREATE_TRADE_LOADING,
  FETCH_TRADE_LOADING,
  GET_FIAT_TRANSFER_DATA,
  GET_TOTAL_TRADES,
  GET_TRADE,
  GET_TRADES,
  SET_TRADE_ERROR,
  TRADE_LOADING,
} from "./types";

export const startOrderTrade =
  (authToken, account, tradeType, tradeInput) => async (dispatch) => {
    dispatch({ type: CREATE_TRADE_LOADING, payload: true });
    const requestBody = {
      order_id: tradeInput?.orderId,
      token_amount: tradeInput?.tokenAmount,
      fiat_amount: tradeInput?.fiatAmount,
    };

    const result = await createTrade(
      tradeType,
      requestBody,
      account,
      authToken
    );

    dispatch({ type: CREATE_TRADE_LOADING, payload: false });

    if (result?.status !== 201) {
      dispatch({
        type: SET_TRADE_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_TRADE,
      payload: result.data,
    });
  };

export const getUserTrades =
  (account, authToken, filter) => async (dispatch) => {
    dispatch({ type: FETCH_TRADE_LOADING, payload: true });

    const result = await fetchUserTrades(filter, account, authToken);

    dispatch({ type: FETCH_TRADE_LOADING, payload: false });

    if (result?.status !== 200) {
      dispatch({
        type: SET_TRADE_ERROR,
        payload: result.message,
      });
      return;
    }

    const { transactions, totalTransactions } = result.data;

    dispatch({
      type: GET_TOTAL_TRADES,
      payload: totalTransactions,
    });

    dispatch({
      type: GET_TRADES,
      payload: transactions,
    });
  };

export const getUserTradeById =
  (tradeId, account, authToken) => async (dispatch) => {
    dispatch({ type: FETCH_TRADE_LOADING, payload: true });

    const [result, fiatTransferData] = await Promise.all([
      fetchUserTradeById(tradeId, account, authToken),
      fetchFiatTransaferData(tradeId, account, authToken),
    ]);

    dispatch({ type: FETCH_TRADE_LOADING, payload: false });
    if (result?.status !== 200) {
      dispatch({
        type: SET_TRADE_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_TRADE,
      payload: result.data,
    });

    if (fiatTransferData?.status === 200) {
      if (!fiatTransferData?.data?._id) {
        return;
      }

      dispatch({
        type: GET_FIAT_TRANSFER_DATA,
        payload: fiatTransferData?.data,
      });
    }
  };

export const getUserTradeByOrderId =
  (authToken, account, orderId) => async (dispatch) => {
    dispatch({ type: FETCH_TRADE_LOADING, payload: true });

    const result = await fetchUserTradeByOrderId(orderId, account, authToken);

    dispatch({ type: FETCH_TRADE_LOADING, payload: false });
    if (result?.status !== 200) {
      dispatch({
        type: SET_TRADE_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_TRADE,
      payload: result.data,
    });
  };

export const updateTransferData =
  (trxId, account, authToken) => async (dispatch) => {
    if (!trxId || !authToken) {
      return;
    }

    dispatch({ type: TRADE_LOADING, payload: true });

    // const result = await updateTransaction(trxId, paymentOptionId, authToken);
    const fiatTransferData = await fetchFiatTransaferData(
      trxId,
      account,
      authToken
    );

    dispatch({ type: TRADE_LOADING, payload: false });

    // if (result?.status !== 200) {
    //   dispatch({
    //     type: SET_TRADE_ERROR,
    //     payload: result.message,
    //   });
    //   return;
    // }
    // dispatch({
    //   type: GET_TRADE,
    //   payload: result.data,
    // });

    if (fiatTransferData?.status === 200) {
      if (!fiatTransferData?.data?._id) {
        return;
      }

      dispatch({
        type: GET_FIAT_TRANSFER_DATA,
        payload: fiatTransferData?.data,
      });
    }
  };

export const cancelTrade =
  (trxId, isSeller, account, authToken) => async (dispatch) => {
    if (!trxId || !authToken) {
      return;
    }

    dispatch({ type: TRADE_LOADING, payload: true });
    const result = await cancelTradeCall(trxId, isSeller, account, authToken);

    dispatch({ type: TRADE_LOADING, payload: false });

    if (result?.status !== 200) {
      dispatch({
        type: SET_TRADE_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_TRADE,
      payload: result.data,
    });
  };
