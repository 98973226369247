const FRENCH = {
  Mode: "Mode",
  Feedback: "Retour",
  Home: "Maison",
  "Create Order": "Créer une commande",
  "My Orders": "Mes commandes",
  Wallet: "Portefeuille",
  Profile: "Profil",
  Leaderboard: "Classement",
  Faucet: "Robinet",
  Banned: "banni",
  "This account has been banned. If this is unexpected, please contact":
    "Ce compte a été interdit. Si cela est inattendu, veuillez contacter",
  footermessage:
    "Rejoignez plus de 100 000 personnes travaillant ensemble pour rendre le monde meilleur.",
  "Copyright 2023 By": "Copyright 2023 par",
  "All Rights Reserved": "Tous les droits sont réservés",
  "How P2P Works": "Comment fonctionne P2P",
  home_howitwork:
    "Polkabridge P2P vous aide à convertir votre argent en crypto instantanément où Polkabridge agit comme un séquestre pour la garde de la transaction.",
  Buy: "Acheter",
  Sell: "Vendre",
  "STEP 1": "ÉTAPE 1",
  buy_step1:
    "Choisissez l'annonce avec le prix et le mode de paiement souhaités. Entrez votre montant d'achat et le montant de la transaction pour terminer la commande.",
  "Deposit tokens into": "Déposer des jetons dans",
  wallet: "portefeuille",
  "and create orders": "et créer des commandes",
  "STEP 2": "ÉTAPE 2",
  buy_step2:
    "Complétez vos paiements en fonction des informations fournies par le vendeur.",
  step2_sell:
    "Confirmez que vous avez reçu les paiements complets de l'acheteur.",
  "STEP 3": "ÉTAPE 3",
  step31_buy:
    "Une fois que le vendeur a reçu le paiement, il libérera les pièces. Dirigez-vous vers le",
  step32_buy: "pour revoir les pièces que vous avez reçues.",
  step3_sell:
    "Libérez les pièces de monnaie à l'acheteur uniquement après avoir reçu leurs paiements en totalité.",
  "Before you start trading you need to update your profile.":
    "Avant de commencer à échanger, vous devez mettre à jour votre profil.",
  "Before your start trading please verify your phone":
    "Avant de commencer à échanger, veuillez vérifier votre téléphone",
  "Please add a payment method to start buy crypto":
    "Veuillez ajouter un mode de paiement pour commencer à acheter de la crypto",
  "Update now": "Mettez à jour maintenant",
  "Verify now": "Vérifiez maintenant",
  "Add now": "Ajouter maintenant",
  Logout: "Se déconnecter",
  "Switching...": "Commutation ...",
  Switch: "Changer",
  "Switch Network": "Réseau de commutation",
  "Cancel dispute": "Annuler le différend",
  canceldisputewarn:
    "Il n'y aura pas d'option pour soulever à nouveau un différend. Veuillez ne PAS annuler si votre problème n'est pas résolu.",
  "I confirm I have recieved the money from seller":
    "Je confirme que j'ai reçu l'argent du vendeur",
  "Cancelling...": "Annulation ...",
  "Cancel Trade": "Annuler le commerce",
  Warning: "Avertissement",
  warningcancel:
    "Il n'y aura pas de remboursement après l'annulation de la commande. Veuillez ne pas annuler si vous avez déjà transféré de l'argent.",
  tradewarning:
    "Le trading sera suspendu pour la journée si l'on a annulé 4 transactions par jour.",
  "I confirm I have not paid the payee.":
    "Je confirme que je n'ai pas payé le bénéficiaire.",
  "Please wait...": "S'il vous plaît, attendez...",
  "Cancel this trade": "Annuler ce métier",
  "Confirm Transfer": "Confirmer le transfert",
  confirmnote:
    "Les transactions P2P exigent que vous effectuiez le paiement ou le transfert dans une méthode prise en charge par la partie réceptrice. Polkabridge ne déduit pas automatiquement de votre compte.",
  "Transfer Amount": "Montant du transfert",
  "In exchange for": "En échange de",
  "Upload proof of payment": "Télécharger la preuve de paiement",
  "Confirm the account of the receiving party":
    "Confirmez le compte de la partie réceptrice",
  "Payment QR code": "Code QR de paiement",
  confirmnote2:
    "Veuillez remplir vos commandes dès que possible afin que la partie réceptrice puisse confirmer votre transfert. Veuillez sélectionner le compte de la partie réceptrice. La confirmation de paiement frauduleux entraînera probablement la gel de votre compte",
  "I didn't Pay Yet": "Je n'ai pas encore payé",
  "Transfer Completed": "Transfert terminé",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "Déposer des jetons dans le coffre-fort à polkabridge et échanger des tracas gratuits",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Retirer les jetons du coffre-fort Polkabridge avec frais",
  Amount: "Montant",
  "Select Max": "Sélectionner max",
  Balance: "Équilibre",
  "Available Amount": "Montant disponible",
  "Withdrawal Fee": "frais de retrait",
  "In order": "En ordre",
  View: "Voir",
  "Send OTP": "Envoyer OTP",
  "Verify Phone": "Vérifiez le téléphone",
  "Verify Email": "Vérifier les courriels",
  "Your phone number has been verified":
    "Votre numéro de téléphone a été vérifié",
  "Your email address has been verified": "Votre adresse e-mail a été vérifiée",
  "6 digit otp has been sent on your phone":
    "6 chiffres OTP a été envoyé sur votre téléphone",
  emailsent:
    "Code de vérification à 6 chiffres envoyés sur votre e-mail. Si vous ne pouvez pas voir l'e-mail dans votre boîte de réception, assurez-vous de vérifier votre dossier de spam",
  "Verify OTP": "Vérifiez OTP",
  "OTP expired": "OTP a expiré",
  "Resend OTP": "Repenser otp",
  "Your OTP will expire in": "Votre OTP expirera",
  "Confirm and Release": "Confirmer et libérer",
  Tips: "Conseils",
  dontnotcheckbuyerpayment:
    "Ne vérifiez pas seulement la preuve de paiement de l'acheteur. Assurez-vous de vous connecter à votre compte et de vérifier que le paiement a été reçu.",
  ifstillprocessing:
    "Si le paiement est toujours en train de traiter, attendez d'avoir reçu le paiement sur votre compte avant de publier la crypto!",
  donotacceptthridparty:
    "N'acceptez pas le paiement d'un compte tiers. Remandit immédiatement le montant total si vous recevez un tel paiement pour éviter les pertes financières causées par la recharge bancaire après avoir libéré la crypto.",
  "Confirm User Account and Release Amount":
    "Confirmer le compte d'utilisateur et le montant de la libération",
  iconfirmpaymentcorrect:
    "Je confirme que le paiement a été crédité sur mon compte et que le solde disponible est correct.",
  "Releasing...": "Libération...",
  Order: "Commande",
  "is successfully completed": "est terminé avec succès",
  Submitted: "Soumis",
  Submit: "Soumettre",
  "Click below for processing": "Cliquez ci-dessous pour le traitement",
  "Waiting for confirmation": "En attente de confirmation",
  "Please confirm the transaction into your metamask popup":
    "Veuillez confirmer la transaction dans votre popup Metamask",
  "Withdrawal Request Submitted": "Demande de retrait soumise",
  "Withdrawal Address": "Adresse de retrait",
  Back: "Dos",
  "Withdrawal History": "Historique de sevrage",
  "Deposit Processing": "Traitement des dépôts",
  depositapproval:
    "L'approbation du dépôt a été soumise, veuillez être patient. N'initiez pas plus de dépôts pendant le processus de dépôt.",
  "Deposits History": "Dépose l'histoire",
  "Transaction Submitted": "Transaction soumise",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "La transaction a été soumise et en attente de la confirmation de la blockchain.",
  "View Status": "Afficher l'état",
  "Transaction Successful!": "Transaction réussie!",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "Super! La transaction a été confirmée avec succès à la blockchain.",
  "Transaction Failed!": "La transaction a échoué!",
  "We have encountered an error in the transaction. Please try again.":
    "Nous avons rencontré une erreur dans la transaction. Veuillez réessayer.",
  "Sign Message": "Signer le message",
  "Connect Wallet": "Portefeuille de connexion",
  "Waiting to connect": "En attente de se connecter",
  "Confirm this connection in your wallet":
    "Confirmez cette connexion dans votre portefeuille",
  walletconnect_rule:
    "En connectant un portefeuille, vous acceptez les conditions d'utilisation de Polkabridge P2P et le consentement à sa politique de confidentialité.",
  "Please sign this message requested in your metamask":
    "Veuillez signer ce message demandé dans votre métamask",
  Metamask: "Métamasque",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "Next TestNet Version à venir!",
  "Follow Us": "Suivez-nous",
  "Claim Faucet": "Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Réclamer les jetons de test pour Goerli TestNet ou BSC testnet.",
  "Unauthorised Access!": "Accès non autorisé!",
  "Please connect your wallet first to continue P2P trading.":
    "Veuillez d'abord connecter votre portefeuille pour continuer le trading P2P.",
  Price: "Prix",
  Limit: "Limite",
  Following: "Suivant",
  Unfollow: "Ne pas suivre",
  Follow: "Suivre",
  Rating: "Notation",
  "Online Ads": "Publicités en ligne",
  "All Feedback": "Tous les commentaires",
  "Volume in": "Volume dans",
  "Decentralized P2P Exchange, powered by ":
    "Échange P2P décentralisé, alimenté par ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Découvrez le premier trading P2P décentralisé avec Polkabridge",
  Fiat: "Décret",
  All: "Tous",
  Token: "Jeton",
  "Payment Mode": "Mode de paiement",
  orders: "ordres",
  completion: "achèvement",
  BUY: "ACHETER",
  SELL: "VENDRE",
  Verify: "Vérifier",
  "Crypto Amount": "Montant cryptographique",
  "Fiat Amount": "Montant Fiat",
  "Market Open Orders": "Ordres ouverts au marché",
  Advertisers: "Annonceurs",
  "Available | Limit": "Disponible | Limite",
  Payment: "Paiement",
  Trade: "Commerce",
  "Exclusive ranking for Polkabridge Traders":
    "Classement exclusif pour les commerçants Polkabridge",
  Volume: "Volume",
  Followers: "Suiveurs",
  "Avg. Pay Time": "Avg. Temps de payer",
  "Avg. Release Time": "Avg. Temps de libération",
  Username: "Nom d'utilisateur",
  Filter: "Filtre",
  Clear: "Clair",
  User: "Utilisateur",
  "Last Trade Time": "Dernier temps de commerce",
  Action: "Action",
  "Unauthorised Access": "Accès non autorisé",
  Apply: "Appliquer",
  "Order No.": "N ° de commande.",
  Coin: "Pièce de monnaie",
  Type: "Taper",
  Status: "Statut",
  Time: "Temps",
  Operation: "Opération",
  "Crypto amount": "Montant cryptographique",
  "Fiat amount": "Montant Fiat",
  "Order type": "Type de commande",
  "Order Details": "détails de la commande",
  "Order Number": "Numéro de commande",
  "Time created": "Temps créé",
  "Buy Order": "Acheter la commande",
  Executed: "Réalisé",
  at: "à",
  "Total Fiat Amount": "Montant fiat total",
  "Crypto Unit Price": "Prix ​​unitaire cryptographique",
  "Min Order Limit": "Limite de commande min",
  "Max Order Limit": "Limite de commande maximale",
  "Trade between": "Échanger",
  "Payment Time Limit": "Limite de temps de paiement",
  Remark: "Remarque",
  "Need Help": "Besoin d'aide",
  "Please wait": "S'il vous plaît, attendez",
  "Cancel Order": "annuler la commande",
  "Go To Home": "Aller à la maison",
  "Initiate New P2P Order": "Initier une nouvelle commande P2P",
  "Set type and price": "Type de réglage et prix",
  "Review Your Order": "Vérifiez votre commande",
  "I want to": "Je veux",
  "Order Limit": "Limite de commande",
  Min: "Min",
  Max: "Max",
  "Payment Methods": "méthodes de payement",
  "Add payments": "Ajouter des paiements",
  "Current Price": "Prix ​​actuel",
  "Highest Price": "Le prix le plus élevé",
  "Lowest Price": "Prix ​​le plus bas",
  "Please note that if you decide to": "Veuillez noter que si vous décidez de",
  SUBMIT: "SOUMETTRE",
  "the order, you wouldn't be able to": "la commande, vous ne pourriez pas",
  EDIT: "MODIFIER",
  "it afterwards.": "ça après.",
  "Review New P2P Order": "Passez en revue la nouvelle commande P2P",
  "Review your order": "Vérifiez votre commande",
  Remarks: "Remarques",
  Cancel: "Annuler",
  "Submit Order": "Soumettre la commande",
  "Order Submitted Successfully": "Commander soumis avec succès",
  "Order Type": "Type de commande",
  Total: "Total",
  "Check your order": "Vérifiez votre commande",
  Deposit: "Dépôt",
  "to fulfill this sell order": "Pour passer cette commande de vente",
  with: "avec",
  "Time remaining": "Temps restant",
  "Confirm Order Info": "Confirmer les informations de commande",
  "Quantity (After fee)": "Quantité (après frais)",
  "Chat with": "Parler avec",
  Uploading: "Téléchargement",
  "Upload Proof of Payment": "Télécharger la preuve de paiement",
  Browse: "Parcourir",
  "Trade cancelled": "Commerce annulé",
  "Transfer Payment to seller": "Transférer le paiement au vendeur",
  "Seller released tokens": "Jetons libérés du vendeur",
  "Pending seller confirmation": "Confirmation du vendeur en attente",
  Completed: "Complété",
  "Token Deposited": "Jeton déposé",
  "Deposit tokens to": "Déposer des jetons à",
  reserve: "réserve",
  "Buyer confirmed": "Acheteur confirmé",
  "Pending buyer confirmation": "Confirmation de l'acheteur en attente",
  "Order Summary": "Récapitulatif de la commande",
  Available: "Disponible",
  "payment method": "mode de paiement",
  "Seller's Message:": "Message du vendeur:",
  "Buyer's Message:": "Message de l'acheteur:",
  "No message": "Pas de message",
  "Terms and conditions": "Termes et conditions",
  ordersummary_rule:
    "N'envoyez pas les paiements via des comptes tiers, tous ces paiements devront être contestés et seront remboursés / annulés. Veuillez ne pas inclure de mots liés à la crypto tels que P2P, Polkabridge, USDT, ETH, BTC, etc.",
  "I want to buy for": "Je veux acheter pour",
  "I want to sell for": "Je veux vendre pour",
  "I will get:": "J'aurai:",
  "I will send:": "J'enverrai:",
  Loading: "Chargement",
  "Order Processing": "commande en cours de traitement",
  "Reference message": "Message de référence",
  "QR Code": "QR Code",
  "Payment confirmed": "Paiement accepté",
  "I've Paid. Confirm": "J'ai payé. Confirmer",
  Cancelled: "Annulé",
  Name: "Nom",
  Email: "E-mail",
  Quantity: "Quantité",
  Your: "Ton",
  "has been reserved in PolkaBridge vault": "a été réservé à Polkabridge Vault",
  "Token reserved": "Jeton réservé",
  "Waiting for buyer's confirmation":
    "En attente de la confirmation de l'acheteur",
  "Buyer confirmed payment": "Paiement confirmé de l'acheteur",
  "Buyer notified": "L'acheteur a notifié",
  "Notify buyer": "Informer l'acheteur",
  "Cancel trade": "Annuler le commerce",
  "Dispute submitted": "Litige soumis",
  Reason: "Raison",
  "Release tokens to buyer wallet":
    "Libérez les jetons au portefeuille acheteur",
  "Token released": "Token sorti",
  "Release tokens": "Les jetons de libération",
  "Your order is executed successfully.":
    "Votre commande est exécutée avec succès.",
  "My Profile": "Mon profil",
  "Update your preference for smooth trading experience.":
    "Mettez à jour vos préférences pour une expérience de trading en douceur.",
  Menu: "Menu",
  Payments: "Paiements",
  "Transaction history": "Historique des transactions",
  Settings: "Paramètres",
  Referral: "Référence",
  "Filter Order": "Commande de filtre",
  TxnId: "Txnide",
  "Transaction not found": "Transaction introuvable",
  "Wallet unavailable": "Portefeuille indisponible",
  "Check explorer": "Vérifier l'explorateur",
  "Full Name": "Nom et prénom",
  Mobile: "Mobile",
  "Preferred Fiat": "Fiat préféré",
  "Edit profile": "Editer le profil",
  "Upload Avatar": "Télécharger Avatar",
  "Updating...": "Mise à jour ...",
  "Update profile": "Mettre à jour le profil",
  "Are you sure, want to leave PolkaBridge?":
    "Êtes-vous sûr, vous voulez quitter Polkabridge?",
  "Log out": "Se déconnecter",
  "Fiat Preference": "Préférence fiat",
  Referrals: "Références",
  "Will be available soon.": "Sera disponible bientôt.",
  "Payment Options": "Options de paiement",
  "Transaction History": "Historique des transactions",
  Fee: "Frais",
  TxnID: "Txnide",
  "All payment methods were added":
    "Tous les méthodes de paiement ont été ajoutées",
  "Add Payment Method": "Ajouter le mode de paiement",
  "Payment method": "Mode de paiement",
  "QR Code (optional)": "Code QR (facultatif)",
  "Upload QR Code": "Télécharger le code QR",
  "Update details": "Mettre à jour les détails",
  "Not Uploaded": "Non téléchargé",
  Edit: "Modifier",
  "Update Payment": "Mettre à jour le paiement",
  Confirm: "Confirmer",
  "Depositing...": "Déposez ...",
  "Confirming...": "Confirmant ...",
  Withdraw: "Retirer",
  Locked: "Fermé à clé",
  "P2P Wallet": "Portefeuille P2P",
  walletpage_message:
    "Gérez les actifs sur votre plate-forme P2P. Vous pouvez déposer, retirer des actifs pour participer aux transactions sur P2P",
  "Your Balances": "Vos soldes",
  "Buy Amount": "Acheter le montant",
  "Sell Amount": "Montant du montant",
  "Trade(s)": "Commerce(s)",
  active: "actif",
  "Executed at": "Exécuté à",
  Chain: "Chaîne",
  "My Ads": "Mes annonces",
  Active: "Actif",
  "Amount reversed by my Bank.": "Montant annulé par ma banque.",
  "Transaction failed on my behalf": "Échec de la transaction en mon nom",
  "Seller received the amount successfully.":
    "Le vendeur a reçu le montant avec succès.",
  "Seller provided the necessary information to complete the payment.":
    "Le vendeur a fourni les informations nécessaires pour effectuer le paiement",
  "I sent the remaining amount.": "J'ai envoyé le montant restant.",
  "I raised the dispute by mistake.": "J'ai soulevé le litige par erreur.",
  "I received the tokens.": "J'ai reçu les jetons.",
  Others: "Autres",
  Quest: "Quête",
  "Complete your quests and collect points.":
    "Complétez vos quêtes et collectez des points.",
  "Total Points": "Points totaux",
  Redeem: "Racheter",
  "Resets in": "Réinitialise",
  "Daily Quests": "Quêtes quotidiennes",
  "Weekly Quests": "Quêtes hebdomadaires",
  Rules: "Règles",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1. Tâche quotidienne: la tâche sera actualisée quotidiennement à 00:00 UTC.",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2. Vous devez cliquer sur «réclamer» pour obtenir vos points de tâche. Si les points ne seront pas crédités sur votre compte.",
  questrule3:
    "3. La récompense totale pour le premier tour de quête sera de 60,000 PBR. Les points seront convertis en PBR correspondant, en fonction du nombre de points demandés",
  "4. Redeem points limit is 100 Points.":
    "4. La limite des points d'échange est de 100 points.",
  questrule5:
    "5. Après la fin du premier tour de quête, l'utilisateur réclamera sa récompense. Les récompenses seront envoyées aux utilisateurs dans les 7 jours ouvrables",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge se réserve le droit d'interprétation finale pour l'événement. Si un utilisateur est impliqué dans un comportement malveillant ou a des vulnérabilités de sécurité exploitées, Polkabridge a le droit de éliminer le compte et les actifs de l'utilisateur.",
  Points: "Points",
  Claiming: "Affirmant",
  Claimed: "Revendiqué",
  Claim: "Réclamation",
  Action: "Action",
  "Redeem Points": "Échanger des points",
  "1 Points = 1 PBR": "1 point = 1 PBR",
  "Redeem Points limit is 100 Points":
    "La limite des points d'échange est de 100 points",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "Soumettez votre demande de réévaluation, l'administrateur examinera et transférera le jeton dans votre portefeuille.",
  Tokens: "Jetons",
  "Wallet Address": "Adresse du portefeuille",
  "Confirm your wallet address and submit your request.":
    "Confirmez votre adresse de portefeuille et soumettez votre demande.",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "Terminé au moins 20 commandes dans la chaîne Ethereum en 7 jours consécutifs.",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "Terminé au moins 20 commandes dans la chaîne BSC en 7 jours consécutifs.",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "Déposer et retirer dans les 7 jours consécutifs dans les deux chaînes.",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "Terminer le commerce avec tous les jetons (PBR-ETH, PBR-BSC, USDT-ETH, USDT-BSC, USDC, DAI, ETH, BUSD, BNB) en 7 jours.",
  "Give at least 10 feedback .": "Donnez au moins 10 commentaires.",
  "Create at least 1 Ad on your FIAT currency":
    "Créez au moins 1 annonce sur votre monnaie fiduciaire",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Terminez au moins 1 commande d'ACHAT et 1 commande de VENTE dans Ethereum Chain",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "Terminez au moins 1 commande d'ACHAT et 1 commande de VENTE dans la chaîne BSC",

  "Deposit or withdraw at least 1 token on Ethereum chain":
    "Déposer ou retirer au moins 1 jeton sur la chaîne Ethereum",
  "Deposit or withdraw at least 1 token on BSC chain":
    "Déposer ou retirer au moins 1 jeton sur la chaîne BSC",
  "Deposit at least 0.1 ETH or 0.1 BNB.":
    "Déposez au moins 0,1 ETH ou 0,1 BNB.",

  "Waiting for seller to release tokens":
    "Attendre que le vendeur libère les jetons",
  "Transfer the funds to seller account provided below":
    "Transférez les fonds sur le compte du vendeur fourni ci-dessous",
  "Seller released tokens": "Le vendeur a publié des jetons",
  "Waiting for confirmation": "En attente de confirmation",
  "Dispute submitted": "Différend soumis",
  Completed: "Complété",
  "Your order is executed successfully":
    "Votre commande est exécutée avec succès",
  Submit: "Soumettre",
  "Cancel Dispute": "Annuler le litige",
  Cancelled: "Annulé",
  "Reason:": "Raison",
  "Dispute raised": "Différend soulevé",
  "In reviewing": "En passant en revue",
  Resolved: "Résolue",
  "Your Proofs": "Vos preuves",

  "Write a review": "Écrire une critique",
  "Transfer the funds to seller account provided below":
    "Transférez les fonds sur le compte du vendeur fourni ci-dessous",
  "Waiting for seller to release tokens":
    "Attendre que le vendeur libère les jetons",
  "Dispute submitted": "Différend soumis",
  "Your Proofs": "Vos preuves",
  "Seller's Proofs": "Preuves du vendeur",
  "Raise dispute": "Soulever un litige",
  "Upload dispute proof": "Télécharger une preuve de contestation",
  "Raise a dispute request with valid proof":
    "Faire une demande de contestation avec justificatif valide",
  "Upload more proofs": "Télécharger plus de preuves",
  "Reason for dispute": "Raison du litige",
  Description: "Description",
  "Upload Video Proof of Payment": "Télécharger une preuve de paiement vidéo",
  Remove: "Retirer",
  "Drag and drop more proof of payment":
    "Faites glisser et déposez d'autres preuves de paiement",
  "Allowed file size less than 50 MB":
    "Taille de fichier autorisée inférieure à 50 Mo",
  "Start video from your P2P order screen":
    "Démarrer la vidéo à partir de votre écran de commande P2P",
  "Clear all applications running in the background":
    "Effacer toutes les applications exécutées en arrière-plan",
  "Open the payment application": "Ouvrez l'application de paiement",
  "Show your Name and ID clearly in the application":
    "Montrez clairement votre nom et votre identifiant dans l'application",
  "Show the transaction history": "Afficher l'historique des transactions",
  "The following things should be clearly visible. - Name and ID of the receiver. - Date and Time of the transaction. - Amount of the transaction. - Transaction Number":
    "Les éléments suivants doivent être clairement visibles. - Nom et identifiant du destinataire. - Date et heure de la transaction. - Montant de la transaction. - Numéro de transaction",
  "Uploading...": "Téléchargement...",
  "I have paid, but the seller does not release the coin":
    "J'ai payé, mais le vendeur ne libère pas la pièce",
  "Seller notified": "Vendeur notifié",
  "Notify Seller": "Avertir le vendeur",
  "Pending dispute": "Litige en cours",
  "Raise dispute": "Soulever un litige",
  "I paid too much": "j'ai trop payé",
  "The seller payment account is wrong, can't pay":
    "Le compte de paiement du vendeur est erroné, impossible de payer",
  "I paid the wrong person": "J'ai payé la mauvaise personne",
  "I paid for the part of the money, can't continue to pay":
    "J'ai payé la partie de l'argent, je ne peux pas continuer à payer",
  Pending: "En attente",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "Je confirme que mon problème est résolu et je souhaite clore ce litige.",

  "Upload proof": "Télécharger une preuve",
  "Upload proof of payment": "Télécharger une preuve de paiement",
  "I confirm there is no problem and I want to cancel this transaction":
    "Je confirme qu'il n'y a pas de problème et je veux annuler cette transaction",
  warningcancel_seller:
    "Voulez-vous vraiment annuler cette transaction ? Veuillez noter que PolkaBridge ne traitera pas les litiges post-annulation si l'acheteur a déjà payé.", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?": "Salut, comment pouvons-nous vous aider?",
  Support: "Soutien",
  "Raise Ticket": "Lever le billet",
  "Watch Tutorial": "Regarder le didacticiel",
  "Video Tutorial": "Didacticiel vidéo",
  Open: "Ouvrir",
  Official: "Officiel",
  "Email Us": "Envoyez-nous un email",
  Issues: "Questions",
  Description: "Description",
  "Upload Proof": "Télécharger la preuve",
  "Drag and drop the file, or": "Faites glisser et déposez le fichier, ou",
  "Enter the description": "Entrez la description",
  General: "Général",
  "Deposit/Withdraw": "Dépôt retirer",
  Account: "Compte",
  Trading: "Commerce",
  Others: "Autres",
  Telegram: "Telegram",
  "Show Online Users": "Afficher les utilisateurs en ligne",
  "Tokens Locked": "Jetons verrouillés",
  "Payment Done": "Paiement effectué",
  "In Dispute": "En conflit",
  Date: "Date",
  Ascending: "Ascendant",
  Descending: "Ascendant",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "Votre billet a été soumis et sera examiné par notre équipe. Le problème sera résolu dans les 24 heures. Merci pour votre patience.",
  "Ticket History": "Historique des billets",
  Date: "Date",
  "Ticket ID": "ID de billets",
  Issue: "Problème",
  Reviewing: "Révision",
  Resolved: "Résolu",
  Pending: "En attente",
  Authenticating: "Authentification",
  "Admin Remark": "Remarque de l'administrateur",
  Message: "Message",
  "Price Ascending": "Prix ​​ascendant",
  "Price Descending": "Prix ​​descendant",
  "Latest Ads": "Dernières publicités",
  "Latest Orders": "Dernières commandes",
  "Completed Trades": "Trades terminés",
  "User Guide": "Mode d'emploi",
  "Beginner's Guide": "Guide du débutant",
  "Video Tutorial": "Didacticiel vidéo",
  "P2P trading FAQ": "FAQ de trading P2P",
  "Admin Message": "Message de l'administrateur",
  "Ticket History": "Historique des tickets",
  "Review Submitted Successfully": "Examiner soumis avec succès",
  "Transaction Hash": "Hachage de transaction",
  "Ticket Details": "Détails des billets",
  Ended: "Terminé",
  "Round 1 Ended": "Le tour 1 s'est terminé",
  "Redeem your points before Round 2 starts":
    "Échangez vos points avant le début du tour 2",
  "Redeem History": "Racheter l'histoire",
  "Redeem Time": "Racheter le temps",
  Address: "Adresse",
  pending: "en attente",
  completed: "complété",
  rejected: "rejeté",
  "Redeemed At": "Racheté à",
  "Redeem Detail": "Racheter les détails",
  "Transaction ID": "identifiant de transaction",
  "Admin Remark": "Remarque d'administration",
  rejected: "rejeté",
  "Profile Tab": "Onglet Profil",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "Cliquez pour mettre à jour votre profil comme le nom, l'ID utilisateur, le NO mobile, le courrier électronique et surtout pour ajouter vos options de paiement préfabriquées.",
  "Quest Tab": "Onglet Quest",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "Cliquez pour participer à la campagne de quête en cours et pour réclamer des points pour obtenir des récompenses $ PBR.",
  "LeaderBoard Tab": "Onglet de classement",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "Cliquez pour voir le classement exclusif pour les commerçants Polkabridge.",
  "Buy Vs Sell": "Acheter vs vendre",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "En sélectionnant l'option d'achat, vous pouvez acheter de la crypto avec vos devises fiduciaires préfabriquées.",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "En sélectionnant l'option Sell, vous pouvez vendre Crypto avec vos devises fiduciaires préfabriquées.",
  "Filter Tool": "Outil de filtre",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "Cliquez pour trier les annonces par vos préférences, ou utilisez le filtre pour configurer les annonces affichées à vous. Les options de filtre sont basées sur la disponibilité de votre région / devise.",
  "Advertiser Info": "Informations sur l'annonceur",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "Ici, vous voyez le profil du marchand, le statut en ligne, le taux d'achèvement, le nombre total de commandes terminées et le badge marchand. Cliquez sur le nom du marchand pour afficher plus d'informations.",
  "Price and Limits": "Prix ​​et limites",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "Ici, vous pouvez voir le prix de 1 pièce pour laquelle l'utilisateur souhaite échanger, sa quantité de disponibilité et le montant le plus bas et le plus élevé que l'utilisateur est prêt à échanger.",
  "Payment options": "Options de paiement",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "Ici, vous pouvez voir le mode de paiement que l'annonceur accepte d'échanger contre des jetons / fiat.",
  "Lowest Fees": "Frais les plus bas",
  "Buy crypto with lowest fees ever!":
    "Achetez la crypto avec les frais les plus bas de tous les temps!",
  "P2P Wallet": "Portefeuille P2P",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "Cliquez pour vérifier votre solde et gérer les dépôts et les retraits de vos actifs et de leur historique.",
  Next: "Suivant",
  Skip: "Sauter",
  Close: "Fermer",
  "Create Order Tab": "Créer une languette de commande",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "Veuillez cliquer sur le bouton pour créer une commande d'achat / vente en utilisant vos devises / jetons / modes de paiement préférés.",
  "Switch Network": "Réseau de commutation",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "Notre plate-forme P2P est conçue pour prendre en charge Multichain, ce qui signifie que vous avez la flexibilité de réaliser des transactions sur n'importe quelle chaîne de votre choix.",
  "24h Trading Volume": "Volume commercial 24h",
  "24h Total Orders": "24h commandes totales",
  "Show Users Following": "Afficher les utilisateurs qui suivent",
  Following: "Suivant",
  Online: "En ligne",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "Veuillez échanger vos points avant le 24 mars à 00h00 UTC. Le nombre minimum de points à convertir est de 100 points",
  "User's Statistics": "Statistiques de l'utilisateur",
  Phone: "Téléphone",
  Follower: "Disciple",
  "All Trades": "Tous les métiers",
  "Total Buy Orders": "Commandes d'achat total",
  "Total Sell Orders": "Commandes de vente totale",
  Share: "Partager",
  "Reopen Ticket": "Rouvrir le billet",
  Comment: "Commentaire",
  "Add your comment": "Ajoutez votre commentaire",
  "Comment must be less than 5000 characters":
    "Le commentaire doit comporter moins de 5000 caractères",
  "Comment is required": "Un commentaire est requis",
  "Reopen Date": "Rouvrir la date",
  Proof: "Preuve",
  "Share your P2P profile": "Partagez votre profil P2P",
  Sort: "Trier",
  "Select Token": "Sélectionner le jeton",
  "Select Fiat": "Sélectionnez Fiat",
  "Date Range": "Plage de dates",
  "Enter username": "Saisissez votre nom d'utilisateur",
  "Trade Currency": "Densité commerciale",
  "Enter your message for seller": "Entrez votre message pour le vendeur",
  "Deposit Token": "Jeton de dépôt",
  "Withdraw Token": "Retirer le jeton",
  "Insufficient!": "Insuffisant!",
  Wait: "Attendez",
  "Insufficient funds!": "Fonds insuffisants!",
  "Insufficient withdraw!": "Retrait insuffisant!",
  Approve: "Approuver",
  "Approving...": "Approuvant...",
  "Issues with Deposit?": "Problèmes de dépôt?",
  "All Networks": "Tous les réseaux",
  "Enter amount": "Entrer le montant",
  "Enter price": "Saisir le prix",
  "Select payment methods": "Sélectionnez des méthodes de paiement",
  "Enter order min and max limits": "Entrez les limites de commande min et max",
  "Enter valid min limit": "Entrez la limite minimale valide",
  "Max limit must be less than": "La limite maximale doit être inférieure à",
  "Min limit must be less than": "La limite min doit être inférieure à",
  "Preview order": "Commande de prévisualisation",
  min: "min",
  Global: "Mondial",
  "Enter your name": "Entrez votre nom",
  "Choose your username": "Choisissez votre nom d'utilisateur",
  "Enter your email": "Entrer votre Email",
  "Your Full Name": "Ton nom complet",
  "A/c Number": "Numéro de climatisation",
  "Account number": "Numéro de compte",
  "Bank Name": "Nom de banque",
  "IFSC Code": "Code IFSC",
  "Note (Optional)": "Remarque (facultatif)",
  "Additional Notes": "Notes complémentaires",
  "Phone Number": "Numéro de téléphone",
  "Account phone number": "Numéro de téléphone du compte",
  "Payment QR Code (Optional)": "Code QR de paiement (facultatif)",
  "Please enter your QR Code": "Veuillez saisir votre code QR",
  Day: "Jour",
  Week: "Semaine",
  Month: "Mois",
  Months: "Mois",
  hash: "hacher",
  "Enter txn hash": "Entrez le hachage TXN",
  Success: "Succès",
  Failed: "Échoué",
  Review: "Examen",
  Dropped: "Abandonné",
  LongPending: "Long",
  "Redeem Ended": "Le rachat s'est terminé",
  "Please enter your bank account number":
    "Veuillez saisir votre numéro de compte bancaire",
  "Bank Name": "Nom de banque",
  "Enter the name of your bank": "Entrez le nom de votre banque",
  "Account opening branch(Optional)":
    "Branche d'ouverture du compte (facultatif)",
  "Enter bank branch information":
    "Entrez les informations de la succursale bancaire",
  "Paypal ID / Number": "PayPal ID / numéro",
  "Perfect Money ID / Number": "ID / numéro d'argent parfait",
  "Bank Details (Optional)": "Détails bancaires (facultatif)",
  "Please enter your bank details": "Veuillez saisir vos coordonnées bancaires",
  "Country of Residence (Optional)": "Pays de résidence (facultatif)",
  "Please enter your country of residence":
    "Veuillez entrer dans votre pays de résidence",
  "Username (Optional)": "Nom d'utilisateur (facultatif)",
  "Account Username": "Nom d'utilisateur du compte",
  "Account Email": "Compte email",
  "Wallet ID": "ID de portefeuille",
  "Revtag (Optional)": "RevTag (facultatif)",
  "Please enter your Revtag": "Veuillez saisir votre revtag",
  "Phone number (Optional)": "Numéro de téléphone (facultatif)",
  "Please enter payment details": "Veuillez saisir les détails du paiement",
  "Account number (USD)": "Numéro de compte (USD)",
  "Account number (KHR)(Optional)": "Numéro de compte (KHR) (facultatif)",
  "Payeer account number": "Numéro de compte payeer",
  "Enter your Payeer account number": "Entrez votre numéro de compte payeer",
  "Email (Optional)": "E-mail (facultatif)",
  "Account Email (Not required)": "E-mail du compte (non requis)",
  "Email or Username": "E-mail ou nom d'utilisateur",
  "Account Email or Username": "Email du compte ou nom d'utilisateur",
  "Full Name of Receiver": "Nom complet du récepteur",
  "Receivers's Full Name": "Nom complet des récepteurs",
  "Payment details (Optional)": "Détails de paiement (facultatif)",
  "ID Number": "Numéro d'identification",
  "Please enter your National ID Number":
    "Veuillez saisir votre numéro d'identification national",
  "Account Type": "Type de compte",
  "Enter your account type": "Entrez votre type de compte",
  "Name and Lastname": "Prénom et nom",
  "Enter Name and Lastname": "Entrez le nom et le nom",
  "Full Name": "Nom et prénom",
  "Enter Full Name": "Entrez le nom complet",
  Cashtag: "Argent",
  "Please enter your Cashtag": "Veuillez saisir votre argent",
  "Full Name (Optional)": "Nom complet (facultatif)",
  "ID Number (Optional)": "Numéro d'identification (facultatif)",
  "Address (Optional)": "Adresse (facultative)",
  "Residential Address": "Adresse résidentielle",
  "Mobile Number": "Numéro de portable",
  "Enter your Mobile Number available for all networks":
    "Entrez votre numéro de mobile disponible pour tous les réseaux",
  "Contact Number": "Numéro de contact",
  "Phone Number": "Numéro de téléphone",
  Operator: "Opérateur",
  "Mobile / Landline / Data / TV": "Mobile / ligne fixe / données / téléviseur",
  "Enter account opening branch (Not required)":
    "Entrez la branche d'ouverture du compte (non requise)",
  "IBAN Number": "Numéro IBAN",
  "BIC/SWIFT Code(Optional)": "BIC / Code Swift (facultatif)",
  "BIC/SWIFT Code": "BIC / Code Swift",
  "Enter international bank account number":
    "Entrez le numéro de compte bancaire international",
  "SWIFT BIC (Optional)": "Swift Bic (facultatif)",
  "Enter your bank identification code":
    "Entrez votre code d'identification bancaire",
  "GB IBAN account number": "Numéro de compte GB Iban",
  "IBAN (Optional)": "Iban (facultatif)",
  "Other payment details (for international transfer) (Optional)":
    "Autres détails de paiement (pour le transfert international) (facultatif)",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "Détails comme votre propre adresse, l'adresse de la banque, le code Iban et le code SwiftBic, etc.",
  "Bank Branch (Optional)": "Succursale bancaire (facultative)",
  "Recommended Transfer Remarks (Optional)":
    "Remarques de transfert recommandées (facultatif)",
  "Recommended Transfer Remarks": "Remarques de transfert recommandés",
  'Please enter your MoMo phone number"':
    'Veuillez saisir votre numéro de téléphone Momo "',
  "Please enter your MoMo QR Code": "Veuillez saisir votre code MOMO QR",
  "Please enter your Zalopay QR Code": "Veuillez saisir votre code QR Zalopay",
  "Additional account number (Optional)":
    "Numéro de compte supplémentaire (facultatif)",
  "Please enter additional account number":
    "Veuillez saisir le numéro de compte supplémentaire",
  "Branch code (Optional)": "Code de branche (facultatif)",
  "Enter branch code (not required)": "Entrez le code de branche (non requis)",
  "IBAN (Optional)": "Iban (facultatif)",
  "Please enter the IBAN code of your Bank (not required)":
    "Veuillez saisir le code Iban de votre banque (non requis)",
  "IBAN or account number": "Iban ou numéro de compte",
  "Please enter your IBAN or account number":
    "Veuillez saisir votre numéro d'Iban ou de compte",
  "Papara account number": "Numéro de compte papara",
  "Please enter your Papara account number":
    "Veuillez saisir votre numéro de compte papara",
  City: "Ville",
  "Please enter your city": "Veuillez entrer dans votre ville",
  "Zip Code": "Code postal",
  "Please enter your city zipcode": "Veuillez saisir votre ville postale",
  Country: "Pays",
  "Please enter Country": "Veuillez entrer dans le pays",
  "Moneygram Account Number": "Numéro de compte MoneyGram",
  "Please enter your Moneygram account number":
    "Veuillez saisir votre numéro de compte MoneyGram",
  "Enter bank branch information":
    "Entrez les informations de la succursale bancaire",
  "Please enter your BCA account number":
    "Veuillez saisir votre numéro de compte BCA",
  "Please enter your Mandiri account number":
    "Veuillez saisir votre numéro de compte Mandiri",
  "Chipper Cash full name": "Nom complet de Cripper Cash",
  "Please enter your full name used to register on chipper cash":
    "Veuillez saisir votre nom complet utilisé pour vous inscrire sur Cripper Cash",
  "Chipper Tag": "Tag de déchiqueter",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "Veuillez saisir votre @chippertag. Vous pouvez le trouver dans votre onglet de profil sur Cripper Cash",
  "QIWI account": "Compte Qiwi",
  "Please enter your QIWI account": "Veuillez saisir votre compte Qiwi",
  "Your email registered on Binance": "Votre e-mail enregistré sur Binance",
  "Please enter your email registered on Binance":
    "Veuillez saisir votre e-mail enregistré sur Binance",
  "Payment Details": "Détails de paiement",
  "WeChat account": "Compte WeChat",
  "Please enter your WeChat account": "Veuillez saisir votre compte WeChat",
  "Receive QR Code (Optional)": "Recevoir du code QR (facultatif)",
  "Alipay account": "Compte Alipay",
  "Please enter your Alipay account": "Veuillez saisir votre compte Alipay",
  "Payee's LINE ID": "ID de ligne du bénéficiaire",
  "Please fill in your LINE ID": "Veuillez remplir votre identifiant de ligne",
  "Please fill in your phone number registered with LINE":
    "Veuillez remplir votre numéro de téléphone enregistré avec ligne",
  "Linepay QR Code (Optional)": "Code QR LinePay (facultatif)",
  "Reference number or barcode": "Numéro de référence ou code-barres",
  "Please enter reference number or barcode":
    "Veuillez saisir le numéro de référence ou le code-barres",
  "Remarks (Optional)": "Remarques (facultative)",
  "Additional Remarks": "Remarque additionnelle",
  "Cell Phone Number": "Numéro de téléphone portable",
  "Please enter your cell phone number":
    "Veuillez saisir votre numéro de téléphone portable",
  "Enter your ID number": "Entrez votre numéro d'identification",
  "Fill in your phone number": "Remplissez votre numéro de téléphone",
  "Full Name of Receiver": "Nom complet du récepteur",
  "Please enter your Full Name": "S'il vous plait entrez votre nom entier",
  CBU: "CBU",
  "Please enter your CBU": "Veuillez saisir votre CBU",
  "CUIT / CUIL": "Cuit / cuil",
  "Enter your unique tax identification code":
    "Entrez votre code d'identification fiscale unique",
  "Please enter your CVU": "Veuillez saisir votre CVU",
  Alias: "Alias",
  "Please enter Alias": "Veuillez saisir Alias",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "Étiquette de portefeuille de citron",
  "Uniform Bank Key": "Clé de banque uniforme",
  "DNI/CPF": "DNI / CPF",
  "Enter your DNI/CPF number": "Entrez votre numéro DNI / CPF",
  "CVU (Optional)": "CVU (facultatif)",
  "Uniform Virtual Key": "Clé virtuelle uniforme",
  "CBU/CVU or": "CBU / CVU ou",
  "alias CVU/CBU or CVU/CBU number": "Alias ​​CVU / CBU ou CVU / CBU",
  "Alias del CBU": "Alias ​​del cbu",
  "Vodafone cash Number": "Numéro de trésorerie Vodafone",
  "Please enter your vodafone cash Number":
    "Veuillez saisir votre numéro de trésorerie Vodafone",
  "Sort code": "Trier le code",
  "Please fill in your 6-digit sort code":
    "Veuillez remplir votre code de tri à 6 chiffres",
  "Sort Code(Optional)": "Trier le code (facultatif)",
  "GB IBAN account number": "Numéro de compte GB Iban",
  "IIN (Optional)": "Iin (facultatif)",
  "Enter individual identification number":
    "Entrez le numéro d'identification individuel",
  "Please enter your bank card number":
    "Veuillez saisir votre numéro de carte bancaire",
  "Mobile Carrier": "Opérateur de téléphonie mobile",
  "Please enter your mobile carrier": "Veuillez saisir votre opérateur mobile",
  "Easypaisa mobile account": "Compte mobile easypaisa",
  "Please enter your Easypaisa mobile account":
    "Veuillez saisir votre compte mobile Easypaisa",
  "NayaPay Id": "ID Nayapay",
  "Please enter NayaPay Id": "Veuillez entrer l'ID Nayapay",
  "Enter Brazilian valid document": "Entrez un document valide brésilien",
  "Bank agency": "Agence de banque",
  "Enter the name of your bank agency":
    "Entrez le nom de votre agence bancaire",
  "Type of transfer": "Type de transfert",
  "Please filled with TED/DOC/PIX": "S'il vous plaît rempli de ted / doc / pix",
  "Name and Last Name": "Prénom et nom",
  "Pix Key": "Clé PIX",
  "Phone, email or key": "Téléphone, e-mail ou clé",
  'Type of Bank"': 'Type de banque "',
  "Name registered in PicPay": "Nom enregistré dans PicPay",
  "PicPay username": "Nom d'utilisateur PicPay",
  "Username used to log in in PicPay":
    "Nom d'utilisateur utilisé pour se connecter à PicPay",
  "urpay Account Details": "Détails du compte Urpay",
  "Please enter your urpay account details":
    "Veuillez saisir les détails de votre compte Urpay",
  "bKash Wallet Number": "Numéro de portefeuille BKASH",
  "Please enter your 11 digit registered bKash account number":
    "Veuillez saisir votre numéro de compte BKASH enregistré à 11 chiffres",
  "Nagad Wallet Number": "Numéro de portefeuille Nagad",
  "Please enter your 11 digit registered Nagad wallet number":
    "Veuillez saisir votre numéro de portefeuille Nagad enregistré à 11 chiffres",
  "Please enter your full name registered with Rocket account":
    "Veuillez saisir votre nom complet enregistré avec un compte Rocket",
  "Rocket Wallet Number": "Numéro de portefeuille de fusée",
  "Please enter your 12 digit Rocket Wallet number":
    "Veuillez saisir votre numéro de portefeuille de fusée à 12 chiffres",
  "Please enter your JKOPAY ID": "Veuillez saisir votre identifiant JKOPAY",
  "EasyPay wallet number (Optional)":
    "Numéro de portefeuille Easypay (facultatif)",
  "Please fill in EasyPay wallet number":
    "Veuillez remplir le numéro de portefeuille Easypay",
  "User API Key": "Clé de l'API utilisateur",
  "User Secret Key": "Clé secrète de l'utilisateur",
  "Bank account number": "numéro de compte bancaire",
  PayID: "Pyeide",
  "Please enter your PayID": "Veuillez saisir votre payid",
  "Institution number": "Numéro d'institution",
  "Please enter institution number":
    "Veuillez saisir le numéro de l'institution",
  "PayCode (Optional)": "Code de paie (facultatif)",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "Peut être trouvé dans mon compte -> mon code salarial -> Copiez le lien",
  "Please fill in your mobile phone number connected with your PayMe":
    "Veuillez remplir votre numéro de téléphone portable connecté avec votre PayMe",
  Cellphone: "Téléphone portable",
  "Card Number or password": "Numéro de carte ou mot de passe",
  "Please fill in card Number or password":
    "Veuillez remplir le numéro de carte ou le mot de passe",
  "Purpose of payment (Optional)": "Objectif de paiement (facultatif)",
  "Enter a payment purpose for buyers":
    "Entrez un objectif de paiement pour les acheteurs",
  "Card number, account or key": "Numéro de carte, compte ou clé",
  "CLABE number (Optional)": "Numéro de Clabe (facultatif)",
  "CLABE number": "Numéro de Clabe",
  "Account number/card": "Numéro de compte / carte",
  "Reference number or card number": "Numéro de référence ou numéro de carte",
  "Please enter reference number or card number":
    "Veuillez saisir le numéro de référence ou le numéro de carte",
  "Provide transfer details during the trade":
    "Fournir des détails de transfert pendant le commerce",
  "Variable symbol (Optional)": "Symbole variable (facultatif)",
  "Enter Variable symbol": "Entrez le symbole variable",
  "Enter your PayNow account": "Entrez votre compte Paynow",
  "Account Name": "Nom du compte",
  "Email Address": "Adresse e-mail",
  "Please enter email address": "Veuillez saisir l'adresse e-mail",
  "Please enter Your Full Name": "S'il vous plait entrez votre nom entier",
  "Account Number": "Numéro de compte",
  "Enter your phone number": "Entrez votre numéro de téléphone",
  "Enter your account number": "Entrez votre numéro de compte",
  "Enter your bank account number": "Entrez votre numéro de compte bancaire",
  "Payment details": "Détails de paiement",
  "Bank name": "Nom de banque",
  "Please enter your MoMo phone number":
    "Veuillez saisir votre numéro de téléphone Momo",
  "Please enter your email": "Veuillez saisir votre e-mail",
  "Enter branch code": "Entrez le code de succursale",
  "Please enter the IBAN code of your Bank":
    "Veuillez saisir le code Iban de votre banque",
  "Account opening branch (Optional)":
    "Branche d'ouverture du compte (facultatif)",
  "Enter account opening branch (not required)":
    "Entrez la branche d'ouverture du compte (non requise)",
  "Please enter your Bank account number":
    "Veuillez saisir votre numéro de compte bancaire",
  "Please enter your ID Number":
    "Veuillez saisir votre numéro d'identification",
  "Enter bank account type": "Entrez le type de compte bancaire",
  "Bank Branch (Optional)": "Succursale bancaire (facultative)",
  "Account type": "Type de compte",
  "Please enter bank branch information":
    "Veuillez saisir les informations de la succursale bancaire",
  "Please enter Email address": "Veuillez saisir l'adresse e-mail",
  "Phone number(Optional)": "Numéro de téléphone (facultatif)",
  "Phone Number (Optional)": "Numéro de téléphone (facultatif)",
  "Additional information": "Informations Complémentaires",
  "Type of Bank": "Type de banque",
  "Remark (Optional)": "Remarque (facultatif)",
  "Mobile Phone": "Téléphone mobile",
  "Enter the bank code": "Entrez le code bancaire",
  "Please enter your phone number": "Veuillez entrer votre numéro de téléphone",
  "Please enter your Bank name": "Veuillez saisir votre nom de banque",
};

export default FRENCH;
