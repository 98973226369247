import { Badge, Box, IconButton, Typography, useTheme } from "@mui/material";
import { TOKEN_LOGO_PATH } from "../../constants";
import { useSelector } from "react-redux";
// import theme from "../../theme";
import {
  camelize,
  formatCurrency,
  formatTime,
  formattedTokenLabel,
  fromWei,
  isExpired,
} from "../../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

const UserTradeCard = ({ trade, index }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const navigate = useNavigate();

  const getStatusColor = () => {
    switch (trade?.status) {
      case "completed":
        return "success";
      case "cancelled":
        return "error";
      case "pending":
        return "warning";
      default:
        return "info";
    }
  };

  return (
    <Box
      pl={2}
      py={2}
      bgcolor={
        trade?.status === "pending"
          ? theme.palette.background.highlight
          : index % 2
          ? theme.palette.background.primary
          : theme.palette.background.secondary
      }
      borderRadius={2}
      mb={1}
      border={
        theme.palette.mode === "light"
          ? "1px solid #E0E0E0"
          : "1px solid #25284B"
      }
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={
        trade?.dispute?.dispute_status === "pending" ||
        trade?.dispute?.dispute_status === "in_review"
          ? {
              backgroundColor:
                theme.palette.mode == "light" ? "#FED9DA" : "#502D4A",
            }
          : trade?.status === "pending"
          ? !isExpired(trade?.created_at, trade?.time_limit)
            ? {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? theme.palette.background.highlight
                    : "#414071",
              }
            : {
                backgroundColor:
                  theme.palette.mode == "light" ? "#F0E68C" : "#493EA0",
              }
          : {}
      }
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="center">
            <img
              src={
                TOKEN_LOGO_PATH[trade?.order?.token?.symbol] ??
                trade?.order?.token?.logo
              }
              style={{
                height: 24,
                marginRight: 8,
              }}
            />
            <Typography
              fontSize={16}
              fontWeight={600}
              color={theme.palette.text.primary}
            >
              {trade?.buyer?._id?.toString() === user?.id?.toString() ? (
                <FormattedMessage id="Buy" defaultMessage="Buy" />
              ) : (
                <FormattedMessage id="Sell" defaultMessage="Sell" />
              )}
              {` ${formattedTokenLabel(trade?.order?.token)}`}
            </Typography>
          </Box>

          <Box>
            <Box ml={1} display="flex" alignItems="center" justifyContent="end">
              <Badge
                color={getStatusColor()}
                variant="dot"
                overlap="circular"
              />
              <Typography
                fontSize={14}
                fontWeight={500}
                ml={1}
                color={theme.palette.text.primary}
              >
                {trade?.status === "pending" &&
                  trade?.transaction_status === 0 &&
                  trade?.dispute?.dispute_status !== "pending" &&
                  trade?.dispute?.dispute_status !== "in_review" && (
                    <FormattedMessage id={"Joined"} defaultMessage={"Joined"} />
                  )}
                {trade?.status === "pending" &&
                  trade?.transaction_status === 1 &&
                  trade?.dispute?.dispute_status !== "pending" &&
                  trade?.dispute?.dispute_status !== "in_review" && (
                    <FormattedMessage
                      id={"Tokens Locked"}
                      defaultMessage={"Tokens Locked"}
                    />
                  )}
                {trade?.status === "pending" &&
                  trade?.transaction_status === 2 &&
                  trade?.dispute?.dispute_status !== "pending" &&
                  trade?.dispute?.dispute_status !== "in_review" && (
                    <FormattedMessage
                      id={"Payment Done"}
                      defaultMessage={"Payment Done"}
                    />
                  )}
                {trade?.status === "pending" &&
                  (trade?.dispute?.dispute_status === "pending" ||
                    trade?.dispute?.dispute_status === "in_review") && (
                    <FormattedMessage
                      id={"In Dispute"}
                      defaultMessage={"In Dispute"}
                    />
                  )}

                {trade?.status === "cancelled" && (
                  <FormattedMessage
                    id={"Cancelled"}
                    defaultMessage={"Cancelled"}
                  />
                )}
                {trade?.status === "completed" && (
                  <FormattedMessage
                    id={"Completed"}
                    defaultMessage={"Completed"}
                  />
                )}
              </Typography>
            </Box>
            <Typography fontSize={12} color={theme.palette.text.secondary}>
              {formatTime(trade?.created_at)}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Typography
                fontSize={13}
                color={theme.palette.text.secondary}
                mr={1}
              >
                <FormattedMessage id="Price" defaultMessage="Price" />
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={theme.palette.text.primary}
              >
                {`${formatCurrency(trade?.order?.order_unit_price, true)} ${
                  trade?.order?.fiat?.fiat
                }`}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Amount" defaultMessage="Amount" />
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {`${formatCurrency(
                fromWei(trade?.token_amount, trade?.order?.token?.decimals),
                true
              )} ${trade?.order?.token?.symbol}`}
            </Typography>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Fiat Amount" defaultMessage="Fiat Amount" />
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {`${formatCurrency(trade?.fiat_amount, true)} ${
                trade?.order?.fiat?.fiat_label
              }`}
            </Typography>
          </Box>

          <Box display="flex" mt={0.5}>
            <Typography
              fontSize={13}
              color={theme.palette.text.secondary}
              mr={1}
            >
              <FormattedMessage id="Order No." defaultMessage="Order No." />
            </Typography>

            <Typography
              fontSize={14}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              {trade?.order?.order_id}
            </Typography>
          </Box>
        </Box>
      </Box>
      <IconButton
        style={{ height: "100px" }}
        onClick={() => navigate(`/order-waiting/${trade?._id}`)}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default UserTradeCard;
