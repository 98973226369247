import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Box,
  Dialog,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useUserAuthentication } from "../../hooks/useUserAuthentication";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import {
  AUTHENTICATION_STATE,
  CONNECTOR_TYPE,
  DAPP_SUPPORTED_ON_CHAINS,
  IS_MAINNET,
} from "../../constants/index";
import {
  isMetaMaskInstalled,
  isTrustWalletInstalled,
} from "../../utils/helper";
import { CellTower } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },

  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 22,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },

  card: {
    padding: 25,
    color:
      theme.palette.mode === "light" ? theme.palette.text.primary : "#212121",
    border: "1px solid #919191",
    width: 300,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 12,
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 20,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: 80,
      fontSize: 14,
    },
  },
  navbarButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 18px 7px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 14,
    "&:hover": {
      background: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      marginRight: 0,
      marginLeft: 15,
      width: "fit-content",
    },
  },
}));

const WalletModal = ({
  popupActive,
  resetPopup,
  action,
  initHooks,
  handleSwitchNetwork,
  authenticationState,
  loading,
}) => {
  const authLoading = useSelector((state) => state?.user?.authLoading);

  const { connectWallet, verifyUserWallet } = useUserAuthentication(initHooks);
  const { isActive, chainId } = useActiveWeb3React();

  const handleClose = () => {
    resetPopup();
    action();
  };

  const userSelectedChain = useSelector((state) => state?.user?.chainId);

  const isDappSupported = useMemo(() => {
    if (!chainId) {
      return false;
    }

    if (userSelectedChain !== chainId) {
      return false;
    }

    const support = DAPP_SUPPORTED_ON_CHAINS.includes(chainId);
    return support;
  }, [chainId, userSelectedChain]);

  const isNetworkSwitchRequired = useMemo(() => {
    if (isActive && !isDappSupported && !authLoading) {
      return true;
    }

    return false;
  }, [isActive, isDappSupported, authLoading]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const handleConnect = useCallback(
    (wallet) => {
      if (isActive) {
        verifyUserWallet();
        return;
      }

      switch (wallet) {
        case "metamask":
          if (!isMetaMaskInstalled() && !isTrustWalletInstalled()) {
            window.open(
              "https://metamask.app.link/dapp/p2p.polkabridge.org/",
              "_blank",
              "noopener,noreferrer"
            );
          }

          connectWallet(CONNECTOR_TYPE.injected);
          break;
        case "trustWallet":
          if (!isTrustWalletInstalled()) {
            window.open(
              "https://link.trustwallet.com/open_url?coin_id=637&url=https://p2p.polkabridge.org/",
              "_blank",
              "noopener,noreferrer"
            );
          } else {
            connectWallet(CONNECTOR_TYPE.injected);
          }
          break;
        case "walletConnect":
          connectWallet(CONNECTOR_TYPE.walletConnect);
          break;
        default:
          connectWallet(CONNECTOR_TYPE.injected);
      }
    },
    [isActive, connectWallet]
  );

  const isConnectingState = useMemo(() => {
    return authenticationState === AUTHENTICATION_STATE.CONNECTING_WALLET;
  }, [authenticationState]);

  // document.addEventListener("visibilitychange", () => {
  //   if (document.visibilityState === "hidden" ) {
  //     window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
  //   }
  // });

  const isSignatureReqState = useMemo(() => {
    return [
      AUTHENTICATION_STATE.REQUESTING_SIGNATURE,
      AUTHENTICATION_STATE.AUTHENTICATION_SUCCESS,
    ].includes(authenticationState);
  }, [authenticationState]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={popupActive}
    // maxWidth="md"
    >
      <Box
        border="10px solid #D1FF1A"
        px={!sm ? "2vw" : "5%"}
        py={!sm ? "5vh" : "10%"}
        className={classes.popup}
      >
        <Box style={{ position: "absolute", right: "15px", top: "15px" }}>
          <Close
            style={{ color: theme.palette.text.primary, cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <div className={classes.background}>
          <div className={classes.container}>
            <div className="d-flex justify-content-end">
              <Close
                style={{ color: theme.palette.text.primary, fontSize: 28 }}
                onClick={resetPopup}
              />
            </div>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <Box className={classes.heading}>
                {isConnectingState && !isNetworkSwitchRequired && (
                  <FormattedMessage
                    id="Connect Wallet"
                    defaultMessage="Connect Wallet"
                  />
                )}

                {isSignatureReqState && (
                  <FormattedMessage
                    id="Sign Message"
                    defaultMessage="Sign Message"
                  />
                )}

                {isNetworkSwitchRequired && (
                  <FormattedMessage
                    id="Switch Network"
                    defaultMessage="Switch Network"
                  />
                )}
              </Box>
            </div>

            {isConnectingState && (
              <Box
                mt={3}
                display={"flex"}
                flexDirection="column"
                alignItems="center"
              >
                <CircularProgress></CircularProgress>
                <Typography
                  fontSize={sm ? 13 : 16}
                  color={"#919191"}
                  fontWeight={sm ? 400 : 500}
                  mb={sm ? 0.5 : 1}
                >
                  <FormattedMessage
                    id="Waiting to connect"
                    defaultMessage="Waiting to connect"
                  />
                </Typography>

                <Typography
                  fontSize={sm ? 13 : 16}
                  color={"#919191"}
                  fontWeight={600}
                  mb={sm ? 0.5 : 1}
                >
                  <FormattedMessage
                    id="Confirm this connection in your wallet"
                    defaultMessage="Confirm this connection in your wallet"
                  />
                </Typography>
                <Typography
                  style={{
                    backgroundColor: "#d1c4e9",
                    padding: 5,
                    borderRadius: 5,
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.primary
                        : "#212121",
                    textAlign: "center",
                  }}
                  mt={3}
                >
                  <FormattedMessage
                    id="walletconnect_rule"
                    defaultMessage="By connecting a wallet, you agree to Polkabridge P2P's Terms of
                  Service and consent to its Privacy Policy."
                  />
                </Typography>
              </Box>
            )}

            {isSignatureReqState && (
              <Box
                mt={3}
                display={"flex"}
                flexDirection="column"
                alignItems="center"
              >
                <CircularProgress></CircularProgress>
                <Typography
                  fontSize={sm ? 13 : 16}
                  color={"#919191"}
                  fontWeight={sm ? 400 : 500}
                  mb={sm ? 0.5 : 1}
                >
                  <FormattedMessage
                    id="Please sign this message requested in your metamask"
                    defaultMessage="Please sign this message requested in your metamask"
                  />
                </Typography>

                <Typography
                  fontSize={sm ? 13 : 16}
                  color={"#919191"}
                  fontWeight={600}
                  mb={sm ? 0.5 : 1}
                ></Typography>
                <Typography
                  style={{
                    backgroundColor: "#d1c4e9",
                    padding: 5,
                    borderRadius: 5,
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.primary
                        : "#212121",
                    textAlign: "center",
                  }}
                  mt={3}
                >
                  <FormattedMessage
                    id="walletconnect_rule"
                    defaultMessage="By connecting a wallet, you agree to Polkabridge P2P's Terms of
                  Service and consent to its Privacy Policy."
                  />
                </Typography>
              </Box>
            )}

            {isNetworkSwitchRequired && (
              <Box
                mt={3}
                mb={5}
                display={"flex"}
                flexDirection="column"
                alignItems="center"
              >
                <button
                  className={classes.navbarButton}
                  onClick={handleSwitchNetwork}
                  disabled={loading}
                >
                  <CellTower />{" "}
                  {loading ? (
                    <FormattedMessage
                      id="Switching..."
                      defaultMessage="Switching..."
                    />
                  ) : (
                    <FormattedMessage
                      id="Switch Network"
                      defaultMessage="Switch Network"
                    />
                  )}
                </button>
              </Box>
            )}
            {!isSignatureReqState &&
              !isConnectingState &&
              !isNetworkSwitchRequired && (
                <Box
                  mt={3}
                  display={"flex"}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    className={classes.card}
                    style={{ backgroundColor: "#d1c4e9" }}
                    onClick={() => handleConnect("metamask")}
                  >
                    <img
                      src="images/metamask.png"
                      alt="metamask"
                      height="30px"
                      style={{ marginRight: 10 }}
                    />
                    <FormattedMessage id="Metamask" defaultMessage="Metamask" />
                  </Box>
                  <Box
                    className={classes.card}
                    style={{ backgroundColor: "#d1c4e9" }}
                    onClick={() => handleConnect("walletConnect")}
                  >
                    <img
                      src="images/walletConnect.svg"
                      height="27px"
                      alt="wallet"
                      style={{ marginRight: 10 }}
                    />{" "}
                    <FormattedMessage
                      id="Wallet Connect"
                      defaultMessage="Wallet Connect"
                    />
                  </Box>

                  {IS_MAINNET && (
                    <Box
                      className={classes.card}
                      style={{ backgroundColor: "#d1c4e9" }}
                      onClick={() => handleConnect("trustWallet")}
                    >
                      <img
                        src="images/trustWallet.png"
                        height="27px"
                        alt=""
                        style={{ marginRight: 10 }}
                      />{" "}
                      Trust Wallet
                    </Box>
                  )}
                </Box>
              )}
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default WalletModal;
