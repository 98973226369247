import { Box, Container } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserTradeById } from "../../../actions/tradeActions";
import OrderProgress from "./OrderProgress";
import BuyOrderWaiting from "./BuyOrderWaiting";
import SellOrderWaiting from "./SellOrderWaiting";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    paddingTop: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.background.primary,
  },
  zoomedImageContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "20px",
    border: "1px solid #EAECEE",
    height: "100%",
    width: "100%",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  zoomedImage: {
    width: "100%",
    borderRadius: "10px",
    maxWidth: "500px",
    cursor: "pointer",
  },
  paymentCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    maxWidth: 700,
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    paddingTop: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#ffffff"
        : theme.palette.background.highlight,
    [theme.breakpoints.down("sm")]: {
      padding: "20px 4px 20px 4px",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 22,
    letterSpacing: "0.02em",
  },
  subtitle: {
    color: "#414141",
    fontWeight: 400,
    fontSize: 14,
  },
  cardTitle: {
    textAlign: "center",
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "200vh",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFF",
    opacity: "0.6",
    zIndex: 10,
  },
  loaded: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  submitButton: {
    borderRadius: 10,
    backgroundColor: "#E0077D",
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
    minWidth: 120,
    textAlign: "center",
  },
  orderTabSelected: {
    backgroundColor: "#DF097C",
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
    minWidth: 120,
    textAlign: "center",
  },
  tableCard: {
    width: "100%",
    height: "100%",
    border: "1px solid #eeeeee",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  table: {
    width: "100%",
  },
  tr: {
    width: "100%",
    height: 45,
  },
  userText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#DF097C",
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
  },
  label: {
    color: theme.palette.mode === "light" ? "#616161" : "#646892",
    fontWeight: 500,
  },
  submitButton: {
    borderRadius: 10,
    backgroundColor: "#E0077D",
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderBox: {
    border: "1px solid #eeeeee",
    padding: 20,
    borderRadius: "30px",
  },
  para: {
    paddingTop: 10,
    color: "#757575",
    fontSize: 14,
  },
  iconTitle: {
    paddingTop: 20,
    color: "#333333",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
  },
  iconSubtitle: {
    color: "#757575",
    fontSize: 14,
    textAlign: "center",
  },
  icon: {
    height: 50,
  },
  howTitle: {
    color: "#333333",
    fontSize: 22,
    fontWeight: 600,
    textAlign: "center",
  },
  howSubtitle: {
    width: 600,
    color: "#757575",
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
  },
  buttonAction: {
    backgroundColor: "green",
    border: `1px solid #6A55EA`,
    borderRadius: 14,
    marginRight: 5,
  },
  qrCodeImage: {
    width: 200,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: 150,
      height: 150,
      objectFit: "contain",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 22,
    letterSpacing: "0.02em",
  },
}));

function OrderWaiting() {
  const classes = useStyles();
  const { trade_id } = useParams();
  const dispatch = useDispatch();

  const createLoading = useSelector(
    (state) => state?.userTrade?.createTradeLoading
  );
  const fetchLoading = useSelector(
    (state) => state?.userTrade?.fetchTradeLoading
  );

  const authenticatedUser = useSelector((state) => state?.user);

  const trade = useSelector((state) => state?.userTrade.trade); // current order trade

  const tradeType = useMemo(() => {
    if (authenticatedUser?.id?.toString() === trade?.buyer?._id?.toString()) {
      return "buy";
    } else {
      return "sell";
    }
  }, [trade, authenticatedUser]);

  const pendingTrade = useMemo(() => {
    return trade;
  }, [trade]);

  const fiatTransfer = useSelector(
    (state) => state?.userTrade?.fiatTransfers?.[trade?._id]
  );

  // load current order transaction with orderId and userId
  // if userId is buyer: load buy order waiting page
  // if userId is seller: load sell order waiting page
  useEffect(() => {
    async function asyncFn() {
      if (trade_id && authenticatedUser?.jwtToken) {
        dispatch(
          getUserTradeById(
            trade_id,
            authenticatedUser?.account,
            authenticatedUser?.jwtToken
          )
        );
      }
    }
    asyncFn();
  }, [trade_id, authenticatedUser, dispatch]);

  const isTradeCancelled = useMemo(() => {
    if (!trade?.status) {
      return false;
    }

    if (trade?.status === "cancelled") {
      return true;
    }

    return false;
  }, [trade]);

  return (
    <Box className={classes.background}>
      <Container>
        <Box style={{ width: "100%" }}>
          <Box>
            <h4 variant="h4" color="textSecondary" className={classes.title}>
              <FormattedMessage
                id={tradeType?.toUpperCase()}
                defaultMessage={tradeType?.toUpperCase()}
              />{" "}
              <FormattedMessage
                id="Order Processing"
                defaultMessage="Order Processing"
              />
            </h4>
          </Box>
          <OrderProgress
            tradeType={tradeType}
            pendingTrade={trade}
            createLoading={createLoading}
            fetchLoading={fetchLoading}
            isTradeCancelled={isTradeCancelled}
          />
          {tradeType === "buy" && (
            <BuyOrderWaiting
              tradeType={tradeType}
              pendingTrade={pendingTrade}
              classes={classes}
              fiatTransfer={fiatTransfer}
              isTradeCancelled={isTradeCancelled}
            />
          )}
          {tradeType === "sell" && (
            <SellOrderWaiting
              tradeType={tradeType}
              pendingTrade={trade}
              classes={classes}
              isTradeCancelled={isTradeCancelled}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default OrderWaiting;
