import {
  GET_PROFILE,
  UPDATE_CURRENCY,
  GET_ERRORS,
  SET_PROFILE_ERROR,
  SET_PROFILE_LOADING,
  SET_PHONE_OTP,
  SET_EMAIL_OTP,
} from "./types";
import {
  getOtpState,
  getUser,
  updateDefaultCurrency,
  updateLastLoginCall,
  updatePayments,
  updateProfile,
} from "../utils/httpCalls";

export const getUserProfile =
  (account, chainId, authToken) => async (dispatch) => {
    if (!account) {
      return;
    }
    dispatch({
      type: SET_PROFILE_LOADING,
      payload: true,
    });

    const result = await getUser(account, chainId, authToken);

    dispatch({
      type: SET_PROFILE_LOADING,
      payload: false,
    });
    if (result?.status !== 200) {
      dispatch({
        type: GET_ERRORS,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_PROFILE,
      payload: result.data,
    });
  };

// POST
// UPDATE USER PROFILE DATA
export const updateUserProfile =
  (data, account, authToken) => async (dispatch) => {
    dispatch({ type: SET_PROFILE_LOADING });
    const result = await updateProfile(data, account, authToken);

    if (result?.status !== 201) {
      console.log("setting profile error ", result.message);
      dispatch({
        type: SET_PROFILE_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: GET_PROFILE,
      payload: result.data,
    });
  };

// POST
// UPDATE USER PROFILE DATA
export const updateLastLogin =
  (data, account, authToken) => async (dispatch) => {
    // dispatch({ type: SET_PROFILE_LOADING });
    const result = await updateLastLoginCall(data, account, authToken);

    if (result?.status !== 201) {
      console.log("update last login error", result.message);

      return;
    }
  };

// POST
// UPDATE USER PAYMENT PREFERENCES
export const updateUserPaymentPreferences =
  (data, account, authToken) => async (dispatch) => {
    dispatch({ type: SET_PROFILE_LOADING });
    const result = await updatePayments(data, account, authToken);

    if (result?.status !== 201) {
      dispatch({
        type: SET_PROFILE_ERROR,
        payload: result?.message?.msg,
      });
      return;
    }

    dispatch({
      type: GET_PROFILE,
      payload: result.data,
    });
  };

// POST
// UPDATE USER PAYMENT PREFERENCES
export const updateUserCurrency =
  (data, account, authToken) => async (dispatch) => {
    dispatch({ type: SET_PROFILE_LOADING });

    const result = await updateDefaultCurrency(data, account, authToken);

    if (result?.status !== 201) {
      dispatch({
        type: SET_PROFILE_ERROR,
        payload: result.message,
      });
      return;
    }
    dispatch({
      type: UPDATE_CURRENCY,
      payload: result.data,
    });
  };

export const updateOtpState =
  (verificationType, account, authToken) => async (dispatch) => {
    const result = await getOtpState(verificationType, account, authToken);

    if (result?.status !== 200) {
      console.log("state test ", result.message);

      return;
    }

    if (verificationType === "phone") {
      const payload = {
        phone: result.data,
      };
      dispatch({
        type: SET_PHONE_OTP,
        payload: payload,
      });
    } else {
      const payload = {
        email: result.data,
      };
      dispatch({
        type: SET_EMAIL_OTP,
        payload: payload,
      });
    }
  };
