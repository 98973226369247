import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTHENTICATION_STATE,
  DAPP_SUPPORTED_ON_CHAINS,
  FALLBACK_DEFAULT_CHAIN,
  NATIVE_TOKENS,
} from "../constants";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import { useCurrencyBalance } from "../hooks/useBalance";
import { makeStyles } from "@mui/styles";
import { formatCurrency, fromWei } from "../utils/helper";
import { CellTower } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { switchChain } from "../utils/switchChain";
import WalletModal from "./popups/WalletModal";
import { FormattedMessage } from "react-intl";
import { logoutAdmin } from "../actions/admin/adminAuthActions";
import { SET_NETWORK_SWITCH, UPDATE_AUTH_STATE } from "../actions/types";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    backgroundColor: "transparent",
  },
  linkItems: {
    paddingRight: 20,
    paddingTop: 7,
    fontWeight: 600,
    paddingLeft: 15,
    fontSize: 15,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  logo: {
    height: 55,
  },
  paper: {
    top: "67px !important",
    left: "unset !important",
    right: "0 !important",
    width: "45%",
    borderRadius: "0",
    backgroundColor: "black",
    transformOrigin: "16px -1px !important",
  },
  listItem: {
    justifyContent: "center",
  },
  navbarButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 18px 7px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 14,
    "&:hover": {
      background: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      marginRight: 0,
      marginLeft: 15,
      width: "fit-content",
    },
  },
  connectedButton: {
    backgroundColor: theme.palette.background.secondary,
    transition: "background-color 0.2s ease",
    color: "white",
    padding: "7px 5px 7px 10px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 14,
    minWidth: 200,
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      fontSize: 12,

      minWidth: 10,
      width: "fit-content",
      padding: "7px 5px 7px 10px",
    },
  },
  connectedAddress: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: "4px 18px 4px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 15,

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 5,
      fontSize: 12,

      width: "fit-content",
      padding: "4px 10px 4px 10px",
    },
  },
  balanceText: {
    color: theme.palette.text.secondary,
    height: "100%",
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "-0.02em",
    textAlign: "center",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 5,
      fontSize: 12,
      width: "fit-content",
      padding: "4px 5px 4px 5px",
    },
  },
}));

export default function WalletButton({ initHooks = false }) {
  const classes = useStyles();
  const authLoading = useSelector((state) => state?.user?.authLoading);
  const authenticated = useSelector((state) => state?.user?.authenticated);
  const { account, chainId, isActive, provider, connector } =
    useActiveWeb3React();
  const userSelectedChain = useSelector((state) => state?.user?.chainId);
  const authenticationState = useSelector(
    (state) => state?.user?.authenticationState
  );
  const [loading, setLoading] = useState(false);

  const [walletModal, setWalletModal] = useState(false);
  const dispatch = useDispatch();

  const handleConnectWallet = useCallback(() => {
    setWalletModal(true);
  }, [walletModal, setWalletModal]);

  useEffect(() => {
    // console.log("auth state test ", authenticationState);
    if (authenticationState === AUTHENTICATION_STATE.CONNECTING_WALLET) {
      setWalletModal(true);
    } else if (
      authenticationState === AUTHENTICATION_STATE.AUTHENTICATION_SUCCESS
    ) {
      setWalletModal(false);
    }
  }, [authenticationState]);

  const balance = useCurrencyBalance(
    account,
    NATIVE_TOKENS[chainId ?? FALLBACK_DEFAULT_CHAIN]
  );

  const isDappSupported = useMemo(() => {
    if (!chainId) {
      return false;
    }

    if (userSelectedChain !== chainId) {
      return false;
    }

    const support = DAPP_SUPPORTED_ON_CHAINS.includes(chainId);
    return support;
  }, [chainId, userSelectedChain]);

  const isNetworkSwitchRequired = useMemo(() => {
    if (isActive && !isDappSupported && !authLoading) {
      return true;
    }

    return false;
  }, [isActive, isDappSupported, authLoading, account]);

  useEffect(() => {
    dispatch({ type: SET_NETWORK_SWITCH, payload: isNetworkSwitchRequired });
  }, [dispatch, isNetworkSwitchRequired]);

  const isConnectionRequired = useMemo(() => {
    if (!authenticated && !authLoading && !isNetworkSwitchRequired) {
      return true;
    }

    if (!isActive && !authLoading) {
      return true;
    }

    return false;
  }, [isActive, authLoading, authenticated, isNetworkSwitchRequired]);

  const handleSwitchNetwork = useCallback(async () => {
    setLoading(true);

    try {
      console.log("user selected chain ", userSelectedChain);
      const switchRes = await switchChain(connector, userSelectedChain);
      console.log("activating  chain switch failed ", switchRes);
    } catch (error) {
      console.log("activating  chain switch failed ", error);
    }

    setLoading(false);
  }, [connector, userSelectedChain, setLoading]);

  const adminAuth = useSelector((state) => state?.adminAuth);

  const handleAdminLogout = useCallback(() => {
    dispatch(logoutAdmin());
  }, [dispatch]);

  const handleCloseWalletModal = useCallback(() => {
    setWalletModal(false);

    dispatch({
      type: UPDATE_AUTH_STATE,
      payload: AUTHENTICATION_STATE.NOT_STARTED,
    });
  }, [setWalletModal, dispatch]);

  return adminAuth?.authenticated ? (
    <button className={classes.navbarButton} onClick={handleAdminLogout}>
      <FormattedMessage id="Logout" defaultMessage="Logout" />
    </button>
  ) : (
    <div>
      <WalletModal
        initHooks={initHooks}
        popupActive={walletModal && !authenticated}
        resetPopup={handleCloseWalletModal}
        handleSwitchNetwork={handleSwitchNetwork}
        authenticationState={authenticationState}
        loading={loading}
      />
      {authenticated &&
        isActive &&
        !authLoading &&
        !isNetworkSwitchRequired && (
          <Link to="/wallet">
            <button onClick={null} className={classes.connectedButton}>
              <span className={classes.balanceText}>
                {balance &&
                  formatCurrency(fromWei(balance?.toString(), 18)) +
                    NATIVE_TOKENS?.[chainId]?.symbol}
              </span>{" "}
              {account && window.innerWidth > 500 && (
                <span className={classes.connectedAddress}>
                  {[...account].splice(0, 2)}
                  {"..."}
                  {[...account].splice([...account].length - 5, 5)}
                </span>
              )}
              {account && window.innerWidth < 500 && (
                <span className={classes.connectedAddress}>
                  {"..."}
                  {[...account].splice([...account].length - 5, 5)}
                </span>
              )}
            </button>
          </Link>
        )}

      {isConnectionRequired && (
        <button className={classes.navbarButton} onClick={handleConnectWallet}>
          {window.innerWidth < 500 ? (
            <FormattedMessage id="Connect" defaultMessage="Connect" />
          ) : (
            <FormattedMessage
              id="Connect Wallet"
              defaultMessage="Connect Wallet"
            />
          )}
        </button>
      )}
      {authLoading && (
        <button
          className={classes.navbarButton}
          disabled={true}
          // onClick={handleConnectWallet}
        >
          {window.innerWidth < 500 ? (
            "Auth..."
          ) : (
            <FormattedMessage
              id="Authenticating"
              defaultMessage="Authenticating..."
            />
          )}
        </button>
      )}

      {isNetworkSwitchRequired && (
        <button
          className={classes.navbarButton}
          disabled={loading}
          onClick={handleSwitchNetwork}
        >
          <CellTower />{" "}
          {loading ? (
            <FormattedMessage id="Switching..." defaultMessage="Switching..." />
          ) : window.innerWidth < 500 ? (
            <FormattedMessage id="Switch" defaultMessage="Switch" />
          ) : (
            <FormattedMessage
              id="Switch Network"
              defaultMessage="Switch Network"
            />
          )}
        </button>
      )}
    </div>
  );
}
