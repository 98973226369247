import { CellTower } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SET_USER_CHAIN } from "../../actions/types";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { switchChain } from "../../utils/switchChain";
import { IS_MAINNET } from "../../constants";

const useStyles = makeStyles((theme) => ({
  navbarButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 18px 7px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 14,
    "&:hover": {
      background: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      marginRight: 0,
      marginLeft: 15,
      width: "fit-content",
    },
  },
}));

const GenericPopup = ({
  open,
  handleClose,
  title,
  message,
  showSwitch = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const userSelectedChain = useSelector((state) => state?.user?.chainId);
  const { account, chainId, provider, connector } = useActiveWeb3React();
  const dispatch = useDispatch();

  const handleSwitchNetwork = useCallback(async () => {
    setLoading(true);

    try {
      console.log("user selected chain ", userSelectedChain);
      const chainToSelect = IS_MAINNET
        ? userSelectedChain === 1
          ? 56
          : 1
        : userSelectedChain === 5
        ? 97
        : 5;
      dispatch({ type: SET_USER_CHAIN, payload: parseInt(chainToSelect) });
      localStorage.userSelectedChain = chainToSelect;
      const switchRes = await switchChain(connector, chainToSelect);
      console.log("activating  chain switch failed ", switchRes);
    } catch (error) {
      console.log("activating  chain switch failed ", error);
    }

    setLoading(false);
  }, [userSelectedChain, loading, setLoading, provider, chainId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.secondary,
          border: `8px solid ${theme.palette.background.main}`,
        },
      }}
    >
      <DialogTitle fontSize={19}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          fontSize={14}
          color={theme.palette.text.primary}
          minWidth={300}
          maxWidth={500}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2.5 }}>
        {showSwitch && (
          <button
            className={classes.navbarButton}
            onClick={handleSwitchNetwork}
          >
            <CellTower />{" "}
            <FormattedMessage
              id="Switch Network"
              defaultMessage="Switch Network"
            />
          </button>
        )}
        {!showSwitch && (
          <Button onClick={handleClose} variant="contained" size="small">
            <FormattedMessage id="Ok" defaultMessage="Ok" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GenericPopup;
