import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Rating,
  Snackbar,
  SnackbarContent,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { postReview } from "utils/httpCalls/reviewCalls";
import { useDispatch, useSelector } from "react-redux";
import { getUserTradeById } from "actions/tradeActions";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    height: "max-content",
    height: 400,
    minHeight: 350,
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 4,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      padding: "25px 5%",
      width: "100%",
      maxWidth: "95%",
      height: 350,
    },
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },
  inputWrapper: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    backgroundColor: "#ffffff",
    border: "1px solid #757575",
    borderRadius: 18,
    width: "80%",
    padding: 6,
    outline: "none",
    color: "#212121",
    textAlign: "left",
    paddingLeft: 10,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    fontFamily: "Karla",
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    letterSpacing: "0.01em",
    textAlign: "center",
    marginBottom: 14,
    [theme.breakpoints.down("md")]: {
      paddingTop: 5,
      fontSize: 20,
    },
  },

  para: {
    color: "#919191",
    letterSpacing: 1,
    textAlign: "center",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.5,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },

  connectButton: {
    width: "fit-content",
    height: "45px",
    background: "#6A55EA",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontSize: 16,
    lineHeight: "33px",
    color: "#ffffff",
    fontWeight: 700,
    marginTop: 20,
    padding: "12px 50px 12px 50px",
    "&:hover": {
      background: "#FFB469",
    },
    [theme.breakpoints.down("md")]: {
      padding: "12px 20px 12px 20px",
      fontSize: 18,
    },
  },

  svgImage: {
    width: "100%",
    height: "fit-content",
    maxHeight: 250,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      maxHeight: 120,
    },
  },

  iconWrapper: {
    marginRight: 10,
    backgroundColor: "#FF87FF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 42,
    height: 42,
  },
  icon: {
    width: 25,
    height: 25,
    color: "white",
  },
}));

// event: buyer_paid, seller_tranferred, buyer_joined, seller_joined
const TradePopup = ({ open, handleClose, message, tradeId, tradeEvent }) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const authenticatedUser = useSelector((state) => state?.user);

  useEffect(() => {
    setRating(0);
    setReview("");
    setReviewSubmitted(false);
  }, [tradeId]);

  const handleReviewSubmit = async () => {
    const payload = {
      comment: review,
      rating,
      transactionId: tradeId,
    };

    await postReview(
      payload,
      authenticatedUser?.account,
      authenticatedUser?.jwtToken
    );
    dispatch(
      getUserTradeById(
        tradeId,
        authenticatedUser?.account,
        authenticatedUser?.jwtToken
      )
    );
    setReviewSubmitted(true);
    setOpenSnackbar(true);
    handleClose();
  };

  const handleViewTrade = useCallback(() => {
    dispatch(
      getUserTradeById(
        tradeId,
        authenticatedUser?.account,
        authenticatedUser?.jwtToken
      )
    );
    handleClose();
  }, [tradeId, authenticatedUser, dispatch]);

  const tradeImages = {
    buyer_joined: "/images/buyer_joined.png",
    seller_joined: "/images/seller_joined.png",
    seller_transfered: "/images/seller_transferred.png",
    buyer_paid: "/images/buyer_paid.png",
    successful_trade: "/images/seller_transferred.png",
    buyer_proof: "/images/buyer_proof.png",
    dispute_raised: "/images/dispute_raised.png",
  };

  if (tradeEvent === "seller_transfered") {
    return (
      <Dialog open={open}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <SnackbarContent message="Review submitted successfully!" />
        </Snackbar>
        <div className={classes.background}>
          <div className={classes.container}>
            <div className="d-flex justify-content-end">
              <Close
                style={{ color: theme.palette.text.primary, fontSize: 28 }}
                onClick={handleClose}
              />
            </div>
            <div
              className="row flex-row align-items-center justify-content-center mb-sm-4"
              align="center"
              style={{
                height: "75%",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <div className="row">
                <div className="col-md-6 my-auto">
                  <h4 className={classes.heading}>
                    <FormattedMessage id="Order" defaultMessage="Order" />{" "}
                    {message}
                  </h4>
                  <h6 className={classes.para}>
                    <FormattedMessage
                      id="is successfully completed"
                      defaultMessage="is successfully completed"
                    />
                  </h6>
                  <div className="text-center my-3">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <TextField
                        placeholder="Write a review"
                        multiline
                        fullWidth
                        variant="outlined"
                        sx={{
                          mt: 2,
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          border: "1px solid #919191",
                          borderRadius: 1,
                          ".MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.text.primary,
                            color: theme.palette.text.primary,
                          },
                        }}
                        maxRows={2}
                        disabled={reviewSubmitted}
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                      />
                      <Box
                        display="flex"
                        justifyContent={
                          reviewSubmitted ? "center" : "space-between"
                        }
                        alignItems="center"
                        width="100%"
                        mt={1.5}
                      >
                        <Rating
                          size="large"
                          value={rating}
                          readOnly={reviewSubmitted}
                          onChange={(e, newRating) => setRating(newRating)}
                        />
                        <Button
                          sx={{ display: reviewSubmitted ? "none" : "block" }}
                          variant="outlined"
                          disabled={!rating}
                          onClick={handleReviewSubmit}
                        >
                          {reviewSubmitted ? (
                            <FormattedMessage
                              id="Submitted"
                              defaultMessage="Submitted"
                            />
                          ) : (
                            <FormattedMessage
                              id="Submit"
                              defaultMessage="Submit"
                            />
                          )}
                        </Button>
                      </Box>
                    </Box>
                    {/* <Button
                      style={{
                        marginTop: 40,
                        backgroundColor: "#6A55EA",
                        border: `1px solid #6A55EA`,
                        borderRadius: 10,
                        paddingTop: sm ? 10 : 10,
                        paddingBottom: sm ? 10 : 10,
                        paddingLeft: 67,
                        paddingRight: 67,
                        fontSize: 17,
                        textDecoration: "none",
                        color: "#fff",
                      }}
                      onClick={() => {
                        handleClose();
                        if (!location.pathname?.includes("order-waiting")) {
                          navigate(`/order-waiting/${tradeId}`);
                        }
                      }}
                    >
                      View
                    </Button> */}
                  </div>
                </div>

                <div className="col-md-6 my-auto">
                  <div className="text-center">
                    <img
                      src={tradeImages?.[tradeEvent]}
                      className={classes.svgImage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog open={open}>
      <div className={classes.background}>
        <div className={classes.container}>
          <div className="d-flex justify-content-end">
            <Close
              style={{ color: theme.palette.text.primary, fontSize: 28 }}
              onClick={handleClose}
            />
          </div>
          <div
            className="row flex-row align-items-center justify-content-center mb-sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="row">
              <div className="col-md-6 my-auto">
                <h4 className={classes.heading}>{message}</h4>
                <h6 className={classes.para}>
                  <FormattedMessage
                    id="Click below for processing"
                    defaultMessage="Click below for processing"
                  />
                </h6>
                <div className="text-center mt-md-5">
                  <h6 className={classes.para}>
                    <Button
                      style={{
                        marginTop: sm ? 2 : 30,
                        backgroundColor: "#6A55EA",
                        border: `1px solid #6A55EA`,
                        borderRadius: 10,
                        paddingTop: sm ? 10 : 10,
                        paddingBottom: sm ? 10 : 10,
                        paddingLeft: 67,
                        paddingRight: 67,
                        fontSize: 17,
                        textDecoration: "none",
                        color: "#fff",
                      }}
                      onClick={() => {
                        handleViewTrade();
                        navigate(`/order-waiting/${tradeId}`);
                      }}
                    >
                      <FormattedMessage id="View" defaultMessage="View" />
                    </Button>
                  </h6>
                </div>
              </div>

              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <img
                    src={tradeImages?.[tradeEvent]}
                    className={classes.svgImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TradePopup;
