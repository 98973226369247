import React, { useMemo } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

const DISPUTE_STATUS = {
  in_review: "in_review",
  pending: "pending",
  resolved: "resolved",
  cancelled: "cancelled",
};

export default function DisputeProgress({ pendingTrade, tradeType }) {
  const theme = useTheme();

  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isAdminReviewing = useMemo(() => {
    if (pendingTrade?.dispute?.dispute_status === DISPUTE_STATUS.in_review) {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const isResolved = useMemo(() => {
    if (pendingTrade?.dispute?.dispute_status === DISPUTE_STATUS.resolved) {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const isCancelled = useMemo(() => {
    if (pendingTrade?.dispute?.dispute_status === DISPUTE_STATUS.cancelled) {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const disputeCancelledBy = useMemo(() => {
    if (!pendingTrade?.dispute?.resolved_at) {
      return "Buyer";
    }

    return "Admin";
  }, [pendingTrade]);

  const disputeProgress = useMemo(() => {
    if (tradeType === "buy") {
      if (isCancelled && disputeCancelledBy === "Buyer") {
        return [
          {
            label: "Dispute submitted",
            completed: true,
          },
          {
            label: "Cancelled",
            completed: true,
          },
        ];
      }

      if (isCancelled && disputeCancelledBy === "Admin") {
        return [
          {
            label: "Dispute submitted",
            completed: true,
          },
          {
            label: "In reviewing",
            completed: true,
          },
          {
            label: "Cancelled",
            completed: true,
          },
        ];
      }

      return [
        {
          label: "Dispute submitted",
          completed: true,
        },
        {
          label: "In reviewing",
          completed: isResolved ? true : isAdminReviewing,
        },
        {
          label: "Resolved",
          completed: isResolved,
        },
      ];
    }
    //status progress for seller

    if (isCancelled && disputeCancelledBy === "Buyer") {
      return [
        {
          label: "Dispute submitted",
          completed: true,
        },
        {
          label: "Cancelled",
          completed: true,
        },
      ];
    }

    if (isCancelled && disputeCancelledBy === "Admin") {
      return [
        {
          label: "Dispute submitted",
          completed: true,
        },
        {
          label: "In reviewing",
          completed: true,
        },
        {
          label: "Cancelled",
          completed: true,
        },
      ];
    }
    return [
      {
        label: "Dispute submitted",
        completed: true,
      },
      {
        label: "In reviewing",
        completed: isResolved ? true : isAdminReviewing,
      },
      {
        label: "Resolved",
        completed: isResolved,
      },
    ];
  }, [
    tradeType,
    pendingTrade,
    isAdminReviewing,
    isResolved,
    isCancelled,
    disputeCancelledBy,
  ]);

  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      flexWrap="wrap-reverse"
      style={sm ? { maxWidth: 300 } : {}}
    >
      <Box
        style={
          sm
            ? { width: "95%", maxWidth: 280, overflowX: "scroll" }
            : { overflowX: "auto" }
        }
        display={"flex"}
        mt={3}
      >
        {disputeProgress.map((progress, index) => (
          <Box pr={1} key={index}>
            <Box display="flex" alignItems="center">
              <div
                style={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  borderRadius: "100%",
                  backgroundColor: progress.completed ? "#04A56D" : "#bdbdbd",
                  color: progress.completed ? "white" : "black",
                  fontWeight: 500,
                  fontSize: 12,
                }}
              >
                {index + 1}
              </div>
              {index < disputeProgress.length - 1 && (
                <div
                  style={{
                    borderTop: `1px dotted ${theme.palette.text.primary}`,
                    width: "80%",
                    height: 1,
                    marginLeft: 5,
                    minWidth: md ? 70 : 130,
                  }}
                ></div>
              )}
            </Box>
            <Typography
              textAlign="left"
              variant="body2"
              fontSize={md ? 13 : 14}
              w="50%"
              fontWeight={500}
              mt={1}
              color={theme.palette.text.primary}
            >
              <FormattedMessage
                id={progress.label}
                defaultMessage={progress.label}
              />
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
