import CHINESE from "./chinese";
import ENGLISH from "./english";
import FRENCH from "./french";
import JAPANESE from "./japanese";
import PORTUGUESE from "./portugese";
import SPANISH from "./spanish";
import TURKISH from "./turkish";
import VIETNAMESE from "./vietnamese";

const messages = {
  en: ENGLISH,
  zh: CHINESE,
  fr: FRENCH,
  ja: JAPANESE,
  pt: PORTUGUESE,
  es: SPANISH,
  tr: TURKISH,
  vi: VIETNAMESE,
};

export default messages;
