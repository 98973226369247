import axios from "axios";
import { globalHeaders } from "./index";
import { BASE_API_ENDPOINT } from "./index";

export const postMessage = async (
  payload: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/message-apis/v1/message/`,
      payload,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          "Content-Type": "multipart/form-data",
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("postMessage ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getMessageImage = async (
  params: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/message-apis/v1/message/image`,
      {
        params: params,
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getMessageImage ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const deleteMessage = async (
  params: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.delete(
      `${BASE_API_ENDPOINT}/message-apis/v1/message/`,
      {
        params: params,
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("deleteMessage ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const updateMessage = async (
  payload: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/message-apis/v1/message/`,
      payload,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("updateMessage ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
