import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrxEtherscanUrl,
  fromWei,
  formatCurrency,
} from "../../utils/helper";
import processIcon from "../../assets/image/Process_ICON.png";
import tickIcon from "../../assets/image/TICK_ICON.png";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    height: "max-content",
    height: "auto",
    maxHeight: 750,
    minHeight: 400,
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.highlight,
    border: "10px solid #6A55EA",
    padding: 4,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      padding: "25px 5%",
      width: "100%",
      maxWidth: "95%",
      height: 350,
    },
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },
  container2: {
    // border: "1px solid #bdbdbd",
    background: theme.palette.mode === "light" ? "#D9E8FC" : "#393D68",
    borderRadius: 10,
    padding: 10,
    width: "80%",
    // marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      padding: "8px 5%",
      width: "100%",
      maxWidth: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },
  inputWrapper: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    backgroundColor: "#ffffff",
    border: "1px solid #757575",
    borderRadius: 18,
    width: "80%",
    padding: 6,
    outline: "none",
    color: "#212121",
    textAlign: "left",
    paddingLeft: 10,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    fontFamily: "Karla",
  },
  heading: {
    color: "#6A55EA",
    fontWeight: 700,
    fontSize: 26,
    letterSpacing: "0.01em",
    textAlign: "center",
    marginBottom: 14,
    [theme.breakpoints.down("md")]: {
      paddingTop: 5,
      fontSize: 20,
    },
  },

  para: {
    color: theme.palette.text.secondary,
    letterSpacing: 1,
    textAlign: "center",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.5,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
  historyButton: {
    color: "#ffffff",
    letterSpacing: 1,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 400,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 10,
      marginLeft: 10,
      marginRight: 10,
    },
  },
  connectButton: {
    width: "fit-content",
    height: "45px",
    background: "#6A55EA",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontSize: 16,
    lineHeight: "33px",
    color: "#ffffff",
    fontWeight: 700,
    marginTop: 20,
    padding: "12px 50px 12px 50px",
    "&:hover": {
      background: "#FFB469",
    },
    [theme.breakpoints.down("md")]: {
      padding: "12px 20px 12px 20px",
      fontSize: 18,
    },
  },
  connectedAddress: {
    // backgroundColor: theme.palette.primary.light,
    color:
      theme.palette.mode === "light" ? "#343434" : theme.palette.text.primary,
    // padding: "4px 18px 4px 18px",
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 16,

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 5,
      fontSize: 14,

      width: "fit-content",
      // padding: "4px 10px 4px 10px",
    },
  },
  svgImage: {
    width: "100%",
    height: "fit-content",
    maxHeight: 200,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      maxHeight: 120,
    },
  },

  iconWrapper: {
    marginRight: 10,
    backgroundColor: "#FF87FF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 42,
    height: 42,
  },
  icon: {
    width: 25,
    height: 25,
    color: "white",
  },
}));

const TxPopup = ({ txCase, hash, resetPopup, type, txData }) => {
  const classes = useStyles();
  const theme = useTheme();
  // console.log(txData);
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const userSelectedChain = useSelector((state) => state?.user?.chainId);
  const wallet = useSelector((state) => state?.wallet);

  const tokenWithdrawQuote = useMemo(() => {
    if (!wallet?.withdrawQuote?.[txData?.tokenId]) {
      return {};
    }
    const quote = wallet?.withdrawQuote?.[txData?.tokenId];
    // console.log(quote);
    return quote;
  }, [wallet, txData]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "Deposit might be lost on reload!"; // Required for Chrome
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <div className={classes.background}>
      <div className={classes.container}>
        {/* <div className="d-flex justify-content-end">
          <Close
            style={{ color: "#212121", fontSize: 28, cursor: "pointer" }}
            onClick={resetPopup}
          />
        </div> */}
        {txCase === 1 && type !== "withdraw" && (
          <div
            className="row flex-row align-items-center justify-content-center sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="d-flex justify-content-end">
              <Close
                style={{
                  color: theme.palette.text.primary,
                  fontSize: 28,
                  cursor: "pointer",
                }}
                onClick={resetPopup}
              />
            </div>
            <div className="row">
              <div className="col-md-6 my-auto">
                <h4
                  className={classes.heading}
                  style={{ color: theme.palette.text.primary }}
                >
                  <FormattedMessage
                    id="Waiting for confirmation"
                    defaultMessage="Waiting for confirmation"
                  />
                </h4>
                <h6
                  className={classes.para}
                  stlye={{ color: theme.palette.text.primary }}
                >
                  <FormattedMessage
                    id="Please confirm the transaction into your metamask popup"
                    defaultMessage="Please confirm the transaction into your metamask popup"
                  />
                </h6>
              </div>

              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/hourglass-4029229-3337928.png"
                    className={classes.svgImage}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {txCase === 1 && type === "withdraw" && (
          <div
            className="row flex-row align-items-center justify-content-center sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="row">
              <div className="col-md-12 my-auto">
                <div className="text-center">
                  <img src={tickIcon} className={classes.svgImage} />
                </div>
              </div>

              <div className="col-md-12 my-auto">
                <h4 className={classes.heading}>
                  <FormattedMessage
                    id="Withdrawal Request Submitted"
                    defaultMessage="Withdrawal Request Submitted"
                  />
                </h4>
              </div>
              <div className="col-md-12 my-auto ">
                <div className={classes.container2}>
                  <div className="d-flex justify-content-between">
                    <div
                      className="pt-2 flex "
                      style={{
                        color:
                          theme.palette.mode === "light"
                            ? "#383838"
                            : "#646892",
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "left",
                      }}
                    >
                      <FormattedMessage id="Amount" defaultMessage="Amount" />
                    </div>
                    <div
                      className="pt-2 d-flex "
                      style={{ fontWeight: "400", style: "14px" }}
                    >
                      <div
                        className="text-right "
                        // style={{ fontSize: sm ? 14 : 16 }}
                      >
                        {formatCurrency(
                          fromWei(txData.tokenAmount, txData.token?.decimals),
                          true,
                          3
                        )}
                      </div>
                      <div
                        className="text-right px-1 "
                        // style={{ fontSize: sm ? 14 : 16 }}
                      >
                        {txData.token?.symbol}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      className="pt-2 flex "
                      style={{
                        color:
                          theme.palette.mode === "light"
                            ? "#383838"
                            : "#919191",
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "left",
                      }}
                    >
                      <FormattedMessage
                        id="Withdrawal Address"
                        defaultMessage="Withdrawal Address"
                      />
                    </div>
                    <div className="pt-2 d-flex  ">
                      <span
                        className={classes.connectedAddress}
                        style={{ padding: "2px 0px 2px 0px" }}
                      >
                        {[...txData.account].splice(0, 3)}
                        {"..."}
                        {[...txData.account].splice(
                          [...txData.account].length - 3,
                          4
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      className="pt-2 flex "
                      style={{
                        color:
                          theme.palette.mode === "light"
                            ? "#383838"
                            : "#919191",
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "left",
                      }}
                    >
                      <FormattedMessage
                        id="Withdrawal Fee"
                        defaultMessage="Withdrawal Fee"
                      />
                    </div>
                    <div className=" d-flex  ">
                      <div
                        className="pt-2 text-right"
                        // style={{ fontSize: sm ? 14 : 16 }}
                      >
                        {formatCurrency(
                          fromWei(
                            tokenWithdrawQuote?.fee,
                            txData?.token?.decimals
                          ),
                          false,
                          2
                        )}
                      </div>
                      <div
                        className="pt-2 px-1 text-right"
                        // style={{ fontSize: sm ? 14 : 16 }}
                      >
                        {txData.token?.symbol}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-center bd-highlight mb-3 my-auto">
                <div className="col-md-3 my-auto">
                  <div className="text-center">
                    <h6 className={classes.para}>
                      <Button
                        style={{
                          borderRadius: 14,
                          paddingLeft: sm ? 5 : 20,
                          paddingRight: sm ? 5 : 20,
                          fontSize: sm ? 10 : 14,
                          marginLeft: sm ? 10 : 14,
                          marginRight: sm ? 10 : 14,
                          marginTop: 12,
                          textTransform: "none",
                        }}
                        onClick={resetPopup}
                      >
                        <FormattedMessage id="Back" defaultMessage="Back" />
                      </Button>
                    </h6>
                  </div>
                </div>
                <div className="col-md-3 my-auto">
                  <div className="text-center">
                    <h6 className={classes.historyButton}>
                      <Link
                        to={"/profile/history"}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#6A55EA",
                            borderRadius: 14,
                            fontSize: sm ? 10 : 14,
                            color: "white",
                            textTransform: "none",
                          }}
                        >
                          <FormattedMessage
                            id="Withdrawal History"
                            defaultMessage="Withdrawal History"
                          />
                        </Button>
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {txCase === 2 && type === "deposit" && (
          <div
            className="row flex-row align-items-center justify-content-center sm-4"
            align="center"
            style={{
              height: "100%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="row">
              <div className="col-md-12 my-auto">
                <div className="text-center">
                  <img src={processIcon} className={classes.svgImage} />
                </div>
              </div>

              <div className="col-md-12 my-auto">
                <h4 className={classes.heading}>
                  <FormattedMessage
                    id="Deposit Processing"
                    defaultMessage="Deposit Processing"
                  />
                </h4>
              </div>
              <div className="col-md-12 my-auto ">
                <div className={classes.container2}>
                  <div className="d-flex justify-content-between">
                    <div
                      className="p-2 flex "
                      style={{
                        color:
                          theme.palette.mode === "light"
                            ? "#383838"
                            : "#646892",
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      <FormattedMessage id="Amount" defaultMessage="Amount" />
                    </div>
                    <div className="p-2 d-flex  ">
                      <div className="text-right px-2">
                        {formatCurrency(
                          fromWei(txData.tokenAmount, txData.token?.decimals),
                          true,
                          3
                        )}
                      </div>
                      <div className="text-right">{txData.token?.symbol}</div>
                    </div>
                  </div>
                  {txData.pbrFee && (
                    <div className="d-flex justify-content-between">
                      <div
                        className="p-2 flex "
                        style={{
                          color:
                            theme.palette.mode === "light"
                              ? "#383838"
                              : "#646892",
                          fontWeight: 600,
                          fontSize: 16,
                          textAlign: "center",
                        }}
                      >
                        <FormattedMessage id="Fee" defaultMessage="Fee" />
                      </div>
                      <div className="p-2 d-flex  ">
                        <div className="text-right px-2">
                          {formatCurrency(
                            fromWei(
                              txData.tokenAmount * txData.pbrFee,
                              txData.token?.decimals
                            ),
                            true,
                            3
                          )}
                        </div>
                        <div className="text-right">{txData.token?.symbol}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12 my-auto">
                <h6 className={classes.para} style={{ color: "#6A55EA" }}>
                  <FormattedMessage
                    id="depositapproval"
                    defaultMessage="The approval for deposit has been submitted, please be patient. Do not initiate more deposits while the deposit process is ongoing."
                  />
                </h6>
              </div>
              <div className="d-flex flex-row justify-content-center bd-highlight mb-3 my-auto">
                <div className="col-md-3 my-auto">
                  <div className="text-center">
                    <h6 className={classes.para}>
                      <Button
                        style={{
                          borderRadius: 14,
                          paddingLeft: sm ? 5 : 20,
                          paddingRight: sm ? 5 : 20,
                          fontSize: sm ? 10 : 18,
                          marginLeft: sm ? 10 : 14,
                          marginRight: sm ? 10 : 14,
                          marginTop: 12,
                          textTransform: "none",
                        }}
                        onClick={resetPopup}
                      >
                        <FormattedMessage id="Back" defaultMessage="Back" />
                      </Button>
                    </h6>
                  </div>
                </div>
                <div className="col-md-3 my-auto">
                  <div className="text-center">
                    <h6 className={classes.historyButton}>
                      <Link
                        to={"/profile/history"}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#6A55EA",
                            borderRadius: 14,
                            fontSize: sm ? 10 : 14,
                            color: "white",
                            textTransform: "none",
                          }}
                        >
                          <FormattedMessage
                            id="Deposits History"
                            defaultMessage="Deposits History"
                          />
                        </Button>
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {txCase === 2 && type !== "deposit" && (
          <div
            className="row flex-row align-items-center justify-content-center mb-sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="d-flex justify-content-end">
              <Close
                style={{
                  color: theme.palette.text.primary,
                  fontSize: 28,
                  cursor: "pointer",
                }}
                onClick={resetPopup}
              />
            </div>
            <div className="row">
              <div className="col-md-6 my-auto">
                <h4 className={classes.heading}>
                  <FormattedMessage
                    id="Transaction Submitted"
                    defaultMessage="Transaction Submitted"
                  />
                </h4>
                <h6 className={classes.para}>
                  <FormattedMessage
                    id="Transaction has been submitted and waiting for the confirmation from blockchain."
                    defaultMessage="Transaction has been submitted and waiting for the confirmation from blockchain."
                  />
                </h6>
              </div>

              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/transaction-4996080-4159677.png"
                    className={classes.svgImage}
                  />
                </div>
              </div>
              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <h6 className={classes.para}>
                    <a
                      href={getTrxEtherscanUrl(hash, userSelectedChain)}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        style={{
                          backgroundColor: "#f9f9f9",
                          border: `1px solid #6A55EA`,
                          borderRadius: 14,
                          paddingLeft: sm ? 5 : 20,
                          paddingRight: sm ? 5 : 20,
                          fontSize: 13,
                        }}
                      >
                        <FormattedMessage
                          id="View Status"
                          defaultMessage="View Status"
                        />
                      </Button>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
        {txCase === 3 && (
          <div
            className="row flex-row align-items-center justify-content-center mb-sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="d-flex justify-content-end">
              <Close
                style={{
                  color: theme.palette.text.primary,
                  fontSize: 28,
                  cursor: "pointer",
                }}
                onClick={resetPopup}
              />
            </div>
            <div className="row">
              <div className="col-md-6 my-auto">
                <h4 className={classes.heading}>
                  <FormattedMessage
                    id="Transaction Successful!"
                    defaultMessage="Transaction Successful!"
                  />
                </h4>
                <h6 className={classes.para}>
                  <FormattedMessage
                    id="Great! Transaction has been confirmed at the blockchain
                  Successfully."
                    defaultMessage="Great! Transaction has been confirmed at the blockchain
                  Successfully."
                  />
                </h6>
              </div>

              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/successful-payment-5298923-4471337.png"
                    className={classes.svgImage}
                  />
                </div>
              </div>
              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <h6 className={classes.para}>
                    <a
                      href={getTrxEtherscanUrl(hash, userSelectedChain)}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        style={{
                          backgroundColor: "#f9f9f9",
                          border: `1px solid #6A55EA`,
                          borderRadius: 14,
                          paddingLeft: sm ? 5 : 20,
                          paddingRight: sm ? 5 : 20,
                          fontSize: 13,
                        }}
                      >
                        <FormattedMessage
                          id="View Status"
                          defaultMessage="View Status"
                        />
                      </Button>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}

        {txCase === 4 && (
          <div
            className="row flex-row align-items-center justify-content-center sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="d-flex justify-content-end">
              <Close
                style={{
                  color: theme.palette.text.primary,
                  fontSize: 28,
                  cursor: "pointer",
                }}
                onClick={resetPopup}
              />
            </div>
            <div className="row">
              <div className="col-md-6 my-auto">
                <h4 className={classes.heading}>
                  <FormattedMessage
                    id="Transaction Failed!"
                    defaultMessage="Transaction Failed!"
                  />
                </h4>
                <h6 className={classes.para}>
                  <FormattedMessage
                    id="We have encountered an error in the transaction. Please try
                  again."
                    defaultMessage="We have encountered an error in the transaction. Please try
                  again."
                  />
                </h6>
              </div>

              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/warning-4958308-4124832.png"
                    className={classes.svgImage}
                  />
                </div>
              </div>
              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <h6 className={classes.para}>
                    <a
                      href={getTrxEtherscanUrl(hash, userSelectedChain)}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        style={{
                          backgroundColor: "#f9f9f9",
                          border: `1px solid #6A55EA`,
                          borderRadius: 14,
                          paddingLeft: sm ? 5 : 20,
                          paddingRight: sm ? 5 : 20,
                          fontSize: 13,
                        }}
                      >
                        <FormattedMessage
                          id="View Status"
                          defaultMessage="View Status"
                        />
                      </Button>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
        {txCase === 5 && (
          <div
            className="row flex-row align-items-center justify-content-center sm-4"
            align="center"
            style={{
              height: "75%",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <div className="row">
              <div className="col-md-6 my-auto">
                <h4
                  className={classes.heading}
                  style={{ color: theme.palette.text.primary }}
                >
                  <FormattedMessage
                    id="Searching transaction"
                    defaultMessage="Searching transaction"
                  />
                </h4>
                <h6
                  className={classes.para}
                  stlye={{ color: theme.palette.text.primary }}
                >
                  <FormattedMessage
                    id="Searching for transaction in the txn pool. Please do not reload or close this window."
                    defaultMessage="Searching for transaction in the txn pool. Please do not reload or close this window."
                  />
                </h6>
              </div>

              <div className="col-md-6 my-auto">
                <div className="text-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/hourglass-4029229-3337928.png"
                    className={classes.svgImage}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TxPopup;
