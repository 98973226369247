import { makeStyles } from "@mui/styles";
import {
  Box,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Pagination from "../../../common/Pagination";
import OrderTableRow from "./OrderTableRow";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 10,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    width: "100%",
    border: `1px solid ${theme.palette.background.secondary}`,
    backgroundColor: theme.palette.background.primary,
    transition: "background-color 0.2s ease",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    position: "relative",
    minHeight: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
    opacity: "0.6",
    zIndex: 10,
  },
  loaded: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  avatar: {
    marginRight: 8,
    borderRadius: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 14,
    color: "#EAECEE",
  },
  table: {
    width: "100%",
  },
  tr: {
    width: "100%",
  },
  trHighlight: {
    width: "100%",
    backgroundColor: theme.palette.mode === "light" ? "#F7F7F7" : "#393D68",
    transition: "background-color 0.2s ease",
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  userText: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.mode === "light" ? "#1943DB" : "#eeeeee",
  },
  orderText: {
    color: theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 400,
  },
  otherText: {
    color: theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
  },
  orderTabSelected: {
    backgroundColor: "#DF097C",
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
  },
  tableHeading: {
    fontSize: 13,
    fontWeight: 600,
  },
  buttonAction: {
    backgroundColor: "green",
    border: `1px solid #6A55EA`,
    borderRadius: 14,
  },
}));

export default function OrderTable({
  orders,
  page,
  loading,
  changePage,
  totalPages,
  onlineOnly,
  setOnlineOnly,
  followingOnly,
  setFollowingOnly,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box mt={5}>
      <h5 className={classes.title}>
        <FormattedMessage
          id="Market Open Orders"
          defaultMessage="Market Open Orders"
        />
      </h5>
      <Box display="flex" justifyContent="center">
        <Box display="flex" justifyContent="center" px={1}>
          <Stack
            alignItems="center"
            spacing={1.5}
            direction="row"
            py={1}
            px={2}
            width="fit-content"
            bgcolor={theme.palette.background.primary}
            border={`2px solid ${theme.palette.text.secondary}`}
            borderRadius={2}
          >
            <Typography
              fontSize={14}
              fontWeight={600}
              color={theme.palette.text.primary}
            >
              <FormattedMessage
                id="Show Online Users"
                defaultMessage="Show Online Users"
              />
            </Typography>
            <Switch
              size="small"
              checked={onlineOnly}
              onChange={(e) => setOnlineOnly(e.target.checked)}
            />
          </Stack>
        </Box>
        <Box display="flex" justifyContent="center" px={1}>
          <Stack
            alignItems="center"
            spacing={1.5}
            direction="row"
            py={1}
            px={2}
            width="fit-content"
            bgcolor={theme.palette.background.primary}
            border={`2px solid ${theme.palette.text.secondary}`}
            borderRadius={2}
          >
            <Typography
              fontSize={14}
              fontWeight={600}
              color={theme.palette.text.primary}
            >
              <FormattedMessage
                id="Show Users Following"
                defaultMessage="Show Users Following"
              />
            </Typography>
            <Switch
              size="small"
              checked={followingOnly}
              onChange={(e) => setFollowingOnly(e.target.checked)}
            />
          </Stack>
        </Box>
      </Box>

      <Box p={md ? 0 : 2}>
        <Box className={classes.tableCard}>
          {loading && (
            <Box className={classes.loading}>
              <img
                src="/table_loader.gif"
                alt="loader"
                height={80}
                width={80}
              />
            </Box>
          )}
          <table className={classes.table}>
            <thead>
              <tr>
                <th style={{ padding: "0px 20px" }}>
                  <Typography
                    textAlign="left"
                    variant="body2"
                    color={
                      theme.palette.mode === "light" ? "#616161" : "#ffffff"
                    }
                    fontSize={md ? 11 : 12}
                    pl={1}
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage
                      id="Advertisers"
                      defaultMessage="Advertisers"
                    />
                  </Typography>
                </th>
                <th style={{ padding: "0px 20px" }}>
                  <Typography
                    textAlign="left"
                    variant="body2"
                    color={
                      theme.palette.mode === "light" ? "#616161" : "#ffffff"
                    }
                    fontSize={md ? 11 : 12}
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Price" defaultMessage="Price" />
                  </Typography>
                </th>
                <th style={{ padding: "0px 20px" }}>
                  <Typography
                    textAlign="left"
                    variant="body2"
                    color={
                      theme.palette.mode === "light" ? "#616161" : "#ffffff"
                    }
                    fontSize={md ? 11 : 12}
                    style={{ fontWeight: 500 }}
                    whiteSpace="nowrap"
                  >
                    <FormattedMessage
                      id="Available | Limit"
                      defaultMessage="Available | Limit"
                    />
                  </Typography>
                </th>
                <th style={{ padding: "0px 20px" }}>
                  <Typography
                    textAlign="left"
                    variant="body2"
                    color={
                      theme.palette.mode === "light" ? "#616161" : "#ffffff"
                    }
                    fontSize={md ? 11 : 12}
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Payment" defaultMessage="Payment" />
                  </Typography>
                </th>
                <th style={{ padding: "0px 20px" }}>
                  <Typography
                    variant="body2"
                    color={
                      theme.palette.mode === "light" ? "#616161" : "#ffffff"
                    }
                    fontSize={md ? 11 : 12}
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Chain" defaultMessage="Chain" />
                  </Typography>
                </th>
                <th style={{ padding: "0px 20px", textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    color={
                      theme.palette.mode === "light" ? "#616161" : "#ffffff"
                    }
                    fontSize={md ? 11 : 12}
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Trade" defaultMessage="Trade" />
                  </Typography>
                </th>
              </tr>
            </thead>
            {orders?.map((order, index) => {
              return (
                <OrderTableRow
                  key={order._id}
                  order={order}
                  index={index}
                  classes={classes}
                />
              );
            })}
          </table>
          {totalPages > 1 && (
            <Pagination
              page={page}
              changePage={changePage}
              totalPages={totalPages}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
