import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Pagination from "../../common/Pagination";
import UserAdCard from "./UserAdCard";
import UserTradeCard from "./UserTradeCard";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 10,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
    padding: 5,
    marginTop: 2,
    position: "relative",
    minHeight: "50vh",
    display: "flex",
    flexDirection: "column",
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)",
    borderRadius: 20,
    opacity: "0.6",
    zIndex: 10,
  },
  loaded: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
}));

const UserAdsCardList = ({
  userAds,
  loading,
  page,
  totalPages,
  changePage,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.tableCard}>
        <h5 className={classes.title}>
          <FormattedMessage
            id="Your Ads"
            defaultMessage="Your Ads"
          />
        </h5>
        <Box className={loading ? classes.loading : classes.loaded}>
          <img
            src="/table_loader.gif"
            height={80}
            width={80}
            className={loading ? "" : "d-none"}
          />
        </Box>
        <Box mt={1}>
          {userAds.map((userAd, index) => {
            return (
              <React.Fragment key={userAd._id}>
                <UserAdCard userAd={userAd} index={index} />
              </React.Fragment>
            );
          })}
        </Box>
        {totalPages > 1 && (
          <Pagination
            page={page}
            changePage={changePage}
            totalPages={totalPages}
          />
        )}
      </Box>
    </>
  );
};

export default UserAdsCardList;
