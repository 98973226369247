import React from "react";
import { Pagination, Stack, useMediaQuery, useTheme } from "@mui/material";

export default function PaginationComponent({
  page,
  changePage,
  totalPages,
  size = null,
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack mt={1.5} spacing={5} alignItems="center">
      <Pagination
        page={page}
        count={totalPages}
        onChange={(event, value) => changePage(value)}
        size={size ? size : sm ? "small" : "large"}
        color="primary"
        shape="rounded"
      />
    </Stack>
  );
}
