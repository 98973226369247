import axios from "axios";
import { globalHeadersAdmin } from "./index";
import { BASE_API_ENDPOINT } from "./index";

/**
 *
 * @param page
 * @param token
 * @returns
 */
export const getDisputes = async (
  page: number,
  account: string,
  token: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/dispute-apis/v1/login`,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getDisputes admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const raiseDisputeCall = async (
  payload: any,
  account: string,
  token: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/dispute-apis/v1/dispute`,
      payload,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("dispute test : error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

/**
 *
 * @param payload : request body for proof
 * @param userType : buyer | seller
 * @param token : user auth token
 * @returns
 */
export const updateDisputeProofCall = async (
  payload: any,
  fileType: string,
  userType: string,
  account: string,
  token: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/dispute-apis/v1/dispute-proof-${fileType}-${userType}`,
      payload,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("dispute test : error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const cancelDisputeCall = async (
  disputeId: string,
  reason: string,
  account: string,
  token: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const body = { reason };
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/dispute-apis/v1/dispute-cancel/${disputeId}`,
      body,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("dispute test : error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
