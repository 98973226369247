import axios from "axios";
import { BASE_API_ENDPOINT, globalHeaders } from "./index";

export const postReview = async (
  payload: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/review-apis/v1/review/`,
      payload,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("postReview :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getReviews = async (
  payload: any,
  authToken: string,
  account: string | undefined
) => {
  try {
    if (!payload.user_id) {
      return;
    }

    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/review-apis/v1/reviews/${payload.user_id}/${
        payload.page_number ? payload.page_number : 1
      }`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getReviews :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getRating = async (user_id: string) => {
  try {
    if (!user_id) {
      return;
    }

    const result = await axios.get(
      `${BASE_API_ENDPOINT}/review-apis/v1/getRating/${user_id}`,
      {
        headers: {
          ...globalHeaders,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getRating :", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
