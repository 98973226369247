import { isValidNumber } from "../utils/helper";
import {
  AUTH_ERROR,
  AUTH_LOADING,
  LOAD_GUEST_USER,
  LOAD_USER,
  SET_NETWORK_SWITCH,
  SET_USER_CHAIN,
  SET_USER_FIAT,
  SIGNATURE_REQ_INIT,
  UPDATE_AUTH_STATE,
  UPDATE_TOUR,
} from "../actions/types";
import {
  AUTHENTICATION_STATE,
  FALLBACK_DEFAULT_CHAIN,
  FALLBACK_DEFAULT_FIAT,
} from "../constants";

const initalState = {
  jwtToken: null,
  account: null,
  id: null,
  authenticated: false,
  initSignRequest: false,
  authLoading: false, // authentication process is in progress
  authError: null, // error messgae while authenticating user
  chainId: isValidNumber(localStorage.userSelectedChain)
    ? parseInt(localStorage.userSelectedChain)
    : FALLBACK_DEFAULT_CHAIN, // default chain id when user visit the app
  userFiat: FALLBACK_DEFAULT_FIAT,
  signature: null,
  authenticationState: AUTHENTICATION_STATE.NOT_STARTED,
  tour_done: false,
  isNetworkSwitchRequired: false,
};

export default function userReducer(state = initalState, action) {
  // todo design and write action types and state updates
  switch (action.type) {
    case LOAD_USER:
      return {
        ...state,
        jwtToken: action?.payload?.jwtToken,
        signature: action?.payload?.signature,
        account: action?.payload?.account,
        id: action?.payload?.id,
        authenticated: !action?.payload?.authenticated ? false : true,
        userFiat: action?.payload?.userFiat
          ? action?.payload?.userFiat
          : FALLBACK_DEFAULT_FIAT,
        tour_done: action?.payload?.tour_done,
        authLoading: false,
        authError: null,
        initSignRequest: false,
      };
    case LOAD_GUEST_USER:
      return {
        ...state,
        jwtToken: action?.payload?.jwtToken,
        account: action?.payload?.account,
        id: action?.payload?.id,
        authenticated: !action?.payload?.authenticated ? false : true,
        authLoading: false,
        initSignRequest: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        authLoading: true,
      };
    case SIGNATURE_REQ_INIT:
      return {
        ...state,
        initSignRequest: true,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
        authLoading: false,
        initSignRequest: false,
      };
    case SET_USER_CHAIN:
      return {
        ...state,
        chainId: action.payload,
      };
    case SET_USER_FIAT:
      return {
        ...state,
        userFiat: action.payload,
      };
    case UPDATE_AUTH_STATE:
      return {
        ...state,
        authenticationState: action.payload,
      };
    case UPDATE_TOUR:
      return {
        ...state,
        tour_done: action.payload,
      };
    case SET_NETWORK_SWITCH:
      return {
        ...state,
        isNetworkSwitchRequired: action.payload,
      };
    default:
      return state;
  }
}
