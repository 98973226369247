import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Cancel,
  CloseRounded,
  Done,
  DoneAll,
  Image,
  Send,
  Verified,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getChat } from "../../../utils/httpCalls/chatCalls";
import {
  postMessage,
  updateMessage,
} from "../../../utils/httpCalls/messageCalls";
import pusher from "../../../utils/pusherInstance";
import moment from "moment";
import { getUserTradeById } from "../../../actions/tradeActions";
import notificationSound from "../../../assets/audio/message_notification.mp3";
import { getAdminMessage, getDateTime } from "utils/helper";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  chatTitle: {
    fontWeight: 800,
    backgroundColor: theme.palette.primary.main,
    color: "#EAECEE",
    padding: "20px 10px",
  },
  chatCard: {
    height: 500,
    width: "100%",
    maxWidth: 1000,
    borderBottom: "none",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    backgroundColor:
      theme.palette.mode === "light"
        ? "#ffffff"
        : theme.palette.background.highlight,
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
  },
  avatar: {
    marginRight: 8,
    borderRadius: "100%",
    position: "relative",
    height: 45,
    width: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 20,
    color: "#EAECEE",
  },
  message: {
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 15,
    padding: "8px 12px",
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
  },
  senderWrapper: {
    width: "70%",
    backgroundColor: "#e5e5e5",
    float: "right",
    wordWrap: "break-word",
  },
  receiverWrapper: {
    width: "70%",
    backgroundColor: "#f3e5f5",
    float: "left",
    wordWrap: "break-word",
  },
  eventWrapper: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    wordWrap: "break-word",
    color: theme.palette.text.primary,
  },
  verificationWrapper: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.primary,
    border:
      theme.palette.mode === "light" ? "1px solid black" : "1px solid #25284B",
    wordWrap: "break-word",
  },
  messageImage: {
    width: "100%",
    marginBottom: "5px",
    cursor: "pointer",
  },
  timestamp: {
    width: "100%",
    textAlign: "right",
    fontSize: 12,
  },
  zoomedImageContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "20px",
    border: "1px solid #EAECEE",
    height: "100%",
    width: "100%",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  zoomedImage: {
    width: "100%",
    borderRadius: "10px",
    maxWidth: "700px",
    cursor: "pointer",
  },
  selectedImage: {
    width: "100%",
    height: 60,
    backgroundColor: "#FFFFFF",
    padding: "10px 10px",
    border: "1px solid #EAECEE",
    borderBottom: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  messageInput: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "#FFFFFF"
        : theme.palette.background.secondary,
    padding: "10px 0",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTop: "1px solid #EAECEE",
  },
  textInput: {
    border: "1px solid #EAECEE",
    borderRadius: 10,
    width: "100%",
  },
  customAdminMessage: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "#FFFFFF"
        : theme.palette.background.secondary,
    padding: "8px",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    borderRadius: 10,
    textAlign: "left",
  },
  imageInput: {
    width: "14%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  sendButton: {
    width: "14%",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: "#757575",
    fontSize: "1.3rem",
    cursor: "pointer",
  },
}));

export default function ChatBox() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const authenticatedUser = useSelector((state) => state?.user);

  const transaction = useSelector((state) => state.userTrade.trade);
  const intl = useIntl();
  const audio = new Audio(notificationSound);
  audio.loop = true;

  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const [zoomedImage, setZoomedImage] = useState(null);

  const [otherUser, setOtherUser] = useState(null);

  const sendMessage = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("buyer", transaction?.buyer?._id);
    formData.append("seller", transaction?.seller?._id);
    formData.append("text", message);
    formData.append("image", image);
    formData.append("transactionId", transaction?._id);

    postMessage(
      formData,
      authenticatedUser?.account,
      authenticatedUser?.jwtToken
    );

    setImage(null);
    setMessage("");
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;

    sendMessage(e);
  };

  const handleImageInput = (e) => {
    const file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert("Image size must be less than 5MB");
      return;
    }
    setImage(file);
  };

  const handleAdminMessage = (message) => {
    const [messageType, dateTime] = message?.text?.split("_");

    const dateString = getDateTime(dateTime);
    const userType =
      authenticatedUser?.id === transaction?.buyer?._id ? "buyer" : "seller";

    const adminMessage = getAdminMessage(
      messageType,
      userType,
      dateString,
      intl
    );

    return adminMessage ? adminMessage : message?.text;
  };

  useEffect(() => {
    setTimeout(() => {
      // console.log("calling hook", messages);
      const chatCard = document.getElementById("chatCard");
      if (chatCard) chatCard.scrollTop = chatCard?.scrollHeight;
    }, 800);
  }, [messages]);

  const fetchMessages = async () => {
    const params = {
      seller: transaction?.seller?._id,
      buyer: transaction?.buyer?._id,
      transactionId: transaction?._id,
    };

    try {
      const response = await getChat(
        params,
        authenticatedUser?.account,
        authenticatedUser?.jwtToken
      );

      if (response && response?.data && response?.data?.messages.length) {
        let messages = response.data.messages;

        messages = messages.map((message) => {
          if (
            !message?.read &&
            !message?.isAdmin &&
            message?.sender !== authenticatedUser?.id
          ) {
            const payload = {
              messageId: message?._id,
              transactionId: transaction?._id,
              read: true,
            };

            updateMessage(
              payload,
              authenticatedUser?.account,
              authenticatedUser?.jwtToken
            );
          }

          return message;
        });

        setMessages(messages);

        return messages;
      }
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    if (Object.keys(transaction).length && otherUser) {
      const userStatusChannel = `user_status_${otherUser?._id}`;
      const channel = pusher.subscribe(userStatusChannel);

      channel.bind("user_status_updated", (data) => {
        dispatch(
          getUserTradeById(
            transaction._id,
            authenticatedUser?.account,
            authenticatedUser?.jwtToken
          )
        );
      });

      return () => {
        channel.unbind();
        pusher.unsubscribe(userStatusChannel);
      };
    }
  }, [otherUser]);

  useEffect(() => {
    if (!(authenticatedUser?.authenticated && authenticatedUser?.jwtToken)) {
      return;
    }

    if (Object.keys(transaction).length) {
      fetchMessages();
      setOtherUser(
        transaction?.buyer?._id === authenticatedUser?.id
          ? transaction?.seller
          : transaction?.buyer
      );
    }

    const messageChannel = `message_${transaction?._id}`;
    const channel = pusher.subscribe(messageChannel);

    channel.bind("message_received", async (data) => {
      const messages = await fetchMessages();
      if (messages[messages.length - 1]?.sender !== authenticatedUser?.id) {
        audio.loop = false;
        await audio.play();
      }
    });

    channel.bind("message_updated", (data) => {
      fetchMessages();
    });

    channel.bind("message_deleted", (data) => {
      fetchMessages();
    });

    return () => {
      setMessages([]);
      setOtherUser(null);
      channel.unbind();
      pusher.unsubscribe(messageChannel);
    };
  }, [transaction, authenticatedUser]);

  const isOtherPartyVerified = (field) => {
    switch (field) {
      case "email":
        return authenticatedUser?.id !== transaction?.seller?._id
          ? transaction?.seller?.email_verified
          : transaction?.buyer?.email_verified;
      case "phone":
        return authenticatedUser?.id !== transaction?.seller?._id
          ? transaction?.seller?.phone_verified
          : transaction?.buyer?.phone_verified;
      default:
        return false;
    }
  };

  const VerifiedFields = (field) => {
    return (
      <Box
        display="flex"
        textAlign="center"
        flexWrap="wrap"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" mr={0.3}>
          <Box fontWeight={600} mr={1}>
            <FormattedMessage
              id={field[0].toUpperCase() + field.slice(1)}
              defaultMessage={field[0].toUpperCase() + field.slice(1)}
            />
            :
          </Box>
          <Typography
            mr={0.3}
            fontSize={14}
            fontWeight={500}
            style={{ wordBreak: "break-word" }}
          >
            {transaction.transaction_status >= 2
              ? `${
                  authenticatedUser?.id !== transaction?.seller?._id
                    ? transaction?.seller?.[field]
                    : transaction?.buyer?.[field]
                }`
              : "xxxxxxxxxxx"}
          </Typography>
        </Box>
        {isOtherPartyVerified(field) ? (
          <Verified
            fontSize="small"
            sx={{
              color: theme.palette.action.green,
              fontSize: sm ? 16 : md ? 18 : 20,
            }}
          />
        ) : (
          <Cancel
            fontSize="small"
            sx={{
              color: theme.palette.action.red,
              fontSize: sm ? 16 : md ? 18 : 20,
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box width="100%">
      <div
        className={classes.zoomedImageContainer}
        onClick={() => setZoomedImage(null)}
        style={{ display: zoomedImage ? "flex" : "none" }}
      >
        <img src={zoomedImage} className={classes.zoomedImage} />
      </div>
      <Box
        py={2.5}
        style={{
          backgroundColor: "#6A55EA",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          position: "sticky",
          // maxWidth: "700px",
          top: 0,
        }}
      >
        <Typography
          display="flex"
          textAlign="left"
          variant="body1"
          color="white"
          px={2}
          style={{ fontWeight: 600 }}
        >
          <FormattedMessage id="Chat with" defaultMessage="Chat with" />{" "}
          {otherUser?.name}
        </Typography>
      </Box>
      <Box
        style={{
          width: "100%",
        }}
        className={classes.chatCard}
        id="chatCard"
      >
        <Box p={2} style={{ width: "100%" }}>
          <Box
            display="flex"
            width="100%"
            pb={1}
            justifyContent="center"
            visibility={authenticatedUser && transaction}
          >
            <Box className={[classes.message, classes.verificationWrapper]}>
              <Box
                mb={0.6}
                py={0.2}
                px={0.2}
                borderRadius={2}
                bgcolor={
                  theme.palette.mode === "light"
                    ? theme.palette.action.disabled
                    : "#646892"
                }
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={theme.palette.text.primary}
                >
                  {authenticatedUser?.id !== transaction?.seller?._id
                    ? `${transaction?.seller?.name}'s ${intl.formatMessage({
                        id: "Info",
                        defaultMessage: "Info",
                      })}`
                    : `${transaction?.buyer?.name}'s ${intl.formatMessage({
                        id: "Info",
                        defaultMessage: "Info",
                      })}`}
                </Typography>
              </Box>
              {["email", "phone"].map((field, index) => {
                return (
                  <Box key={index} mt={0.3} color={theme.palette.text.primary}>
                    {VerifiedFields(field)}
                  </Box>
                );
              })}
            </Box>
          </Box>
          {messages.map((message, index) => {
            return (
              <Box
                key={index}
                display="flex"
                width="100%"
                pb={1}
                justifyContent={
                  message?.sender === authenticatedUser?.id
                    ? "flex-end"
                    : "flex-start"
                }
              >
                {message?.sender !== authenticatedUser?.id &&
                  !message?.isAdmin && (
                    <Box className={classes.avatar}>
                      <Box
                        style={{
                          position: "absolute",
                          height: 14,
                          width: 14,
                          borderRadius: "100%",
                          border: "2px solid white",
                          bottom: 1,
                          right: 1,
                          zIndex: 1,
                          backgroundColor: otherUser?.isOnline
                            ? "#6EB437"
                            : "#E59939",
                        }}
                      />
                      <Avatar
                        src={otherUser?.avatar ? `${otherUser?.avatar}` : null}
                        alt={otherUser?.name}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                        }}
                      >
                        {otherUser?.name[0]?.toUpperCase()}
                      </Avatar>
                    </Box>
                  )}
                <Box
                  className={[
                    classes.message,
                    message?.isAdmin
                      ? classes.eventWrapper
                      : message?.sender === authenticatedUser?.id
                      ? classes.senderWrapper
                      : message?.sender !== "admin"
                      ? classes.receiverWrapper
                      : classes.eventWrapper,
                  ]}
                  sx={
                    message?.isAdmin &&
                    handleAdminMessage(message) === message?.text && {
                      alignItems: "start",
                      padding: 0,
                    }
                  }
                >
                  {message?.image && (
                    <img
                      src={message?.image}
                      className={classes.messageImage}
                      onClick={() => setZoomedImage(message?.image)}
                    />
                  )}

                  <div style={sm ? { maxWidth: "200px" } : {}}>
                    {!message?.isAdmin ? (
                      message?.text
                    ) : handleAdminMessage(message) !== message?.text ? (
                      handleAdminMessage(message)
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar
                          src="/p2picon.png"
                          variant="rounded"
                          sx={{
                            border:
                              theme.palette.mode === "light"
                                ? "1px solid #EAECEE"
                                : "1px solid #25284B",
                            backgroundColor: "white",
                          }}
                        >
                          A
                        </Avatar>
                        <Stack
                          spacing={0.5}
                          className={classes.customAdminMessage}
                          minWidth={200}
                        >
                          <Typography fontSize={13} fontWeight={600}>
                            ADMIN
                          </Typography>
                          <div>{message?.text}</div>
                          <div
                            className={classes.timestamp}
                            style={{ marginTop: 10 }}
                          >
                            {moment(message.createdAt).format("hh:mm A")}
                          </div>
                        </Stack>
                      </Stack>
                    )}
                  </div>
                  <div
                    className={classes.timestamp}
                    style={{
                      display: message?.isAdmin ? "none" : "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!message?.isAdmin &&
                      moment(message.createdAt).format("hh:mm A")}
                    <span style={{ fontSize: 14, marginLeft: 4 }}>
                      {message?.isAdmin ||
                      message?.sender !== authenticatedUser?.id ? (
                        <></>
                      ) : message?.read ? (
                        <DoneAll fontSize="inherit" color="info" />
                      ) : (
                        <Done fontSize="inherit" color="action" />
                      )}
                    </span>
                  </div>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box style={{ position: "relative", width: "100%" }}>
        {image && (
          <Box className={classes.selectedImage}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: "80%",
                overflow: "hidden",
              }}
            >
              <img
                src={URL.createObjectURL(image)}
                alt="selected"
                style={{ maxWidth: "30%" }}
              />
              <Typography fontSize={13} pl={2} noWrap>
                {image.name}
              </Typography>
            </div>
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => setImage(null)}
            >
              <CloseRounded fontSize="small" color="primary" />
            </button>
          </Box>
        )}
        <form onSubmit={sendMessage}>
          <Box display="flex" className={classes.messageInput}>
            <Box className={classes.imageInput}>
              <label htmlFor="image-input" style={{ cursor: "pointer" }}>
                <Image size="small" color="primary" />
                <input
                  id="image-input"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageInput}
                  disabled={
                    transaction?.status === "completed" ||
                    transaction?.status === "cancelled"
                  }
                />
              </label>
            </Box>
            <TextField
              placeholder={`${intl.formatMessage({
                id: "Send a message",
                defaultMessage: "Send a message",
              })}`}
              multiline
              fullWidth
              variant="outlined"
              onKeyDown={handleKeyDown}
              disabled={
                transaction?.status === "completed" ||
                transaction?.status === "cancelled"
              }
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              inputProps={{ maxLength: 1000 }} //160-340 words
              maxRows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={classes.textInput}
            />
            <button
              type="submit"
              className={classes.sendButton}
              disabled={
                transaction?.status === "completed" ||
                transaction?.status === "cancelled"
              }
            >
              <Send fontSize="inherit" color="primary" />
            </button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
