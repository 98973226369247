import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Rating,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fromWei, formatCurrency } from "../../../utils/helper";
import CheckIcon from "@mui/icons-material/Check";
import allPaymentOptions from "../../../constants/payment_options.json";
import { useDispatch, useSelector } from "react-redux";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import { notifyBuyer } from "../../../utils/httpCalls/orderTradeCalls";
import { getUserTradeById } from "../../../actions/tradeActions";
import PopupLayout from "../../../common/popups/PopupLayout";
import ChatBox from "./ChatBox";
import ReleaseTokenPopup from "../../../common/popups/ReleaseTokenPopup";
import CancelTradePopup from "common/popups/CancelTradePopup";
import CountDownTime from "./CountDownTime";
import DisputeProgress from "./DisputeProgress";
import DisputeProofs from "./DisputeProofs";
import { postReview } from "utils/httpCalls/reviewCalls";
import { FormattedMessage, useIntl } from "react-intl";

export default function SellOrderWaiting({
  classes,
  pendingTrade,
  tradeType,
  isTradeCancelled,
}) {
  const { chainId } = useActiveWeb3React();
  const theme = useTheme();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [notifyBuyerFlag, setNotifyBuyer] = useState(false);
  // const [raiseDisputeFlag, setRaiseDispute] = useState(false);
  const [cancelTradeFlag, setCancelTradeFlag] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);

  const isBuyerNotified = useMemo(() => {
    if (!pendingTrade) {
      return true;
    }

    if (!pendingTrade?.is_buyer_notified) {
      return false;
    }
    if (!pendingTrade?.is_buyer_notified) {
      return false;
    }
    return true;
  }, [pendingTrade]);
  const isBuyerConfirmedPayment = useMemo(() => {
    if (pendingTrade?.transaction_status >= 2) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isTradeCompleted = useMemo(() => {
    if (pendingTrade?.transaction_status === 3) {
      return true;
    }
    return false;
  }, [pendingTrade]);

  const isTokenDeposited = useMemo(() => {
    if (pendingTrade?.transaction_status === 0) {
      return false;
    }
    return true;
  }, [pendingTrade]);

  const isTokenReleased = useMemo(() => {
    if (pendingTrade?.transaction_status < 3) {
      return false;
    }
    return true;
  }, [pendingTrade]);

  const userAuth = useSelector((state) => state?.user);

  const dispatch = useDispatch();

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleReviewSubmit = async () => {
    const payload = {
      comment: review,
      rating,
      transactionId: pendingTrade._id,
    };

    await postReview(payload, userAuth?.account, userAuth?.jwtToken);
    dispatch(
      getUserTradeById(pendingTrade?._id, userAuth?.account, userAuth.jwtToken)
    );
    setOpenSnackbar(true);
  };

  const allPaymentModes = useMemo(() => {
    if (!pendingTrade?.fiat) {
      return [];
    }

    return allPaymentOptions?.[pendingTrade?.order?.fiat?.fiat];
  }, [pendingTrade]);

  const selectedPaymentOptionFields = useMemo(() => {
    if (!pendingTrade?.transfered_mode?.payment_mode) {
      return [];
    }

    const option = allPaymentModes?.find(
      (item) => item?.provider === pendingTrade?.transfered_mode?.payment_mode
    );

    if (!option?.fields) {
      return [];
    }

    return option.fields;
  }, [pendingTrade, allPaymentModes]);

  //handling confirm popup
  const [confirm, setConfirm] = useState(false);
  const handleModalClose = useCallback(() => {
    setConfirm(false);
  }, [setConfirm]);

  const handleBuyerNotify = useCallback(async () => {
    if (!userAuth?.jwtToken || !pendingTrade?._id || !chainId) {
      return;
    }

    setLoading(true);
    try {
      const res = await notifyBuyer(
        pendingTrade?._id,
        userAuth?.account,
        userAuth?.jwtToken
      );

      if (res?.status !== 201) {
        // request error
        console.log("notify request error ", res);
      }
      dispatch(
        getUserTradeById(
          pendingTrade?._id,
          userAuth?.account,
          userAuth.jwtToken
        )
      );
    } catch (error) {}
    setLoading(false);
  }, [pendingTrade, userAuth, chainId, dispatch, setLoading]);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const isDisputeRaised = useMemo(() => {
    if (pendingTrade?.dispute) {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const isDisputeCancelled = useMemo(() => {
    if (pendingTrade?.dispute?.dispute_status === "cancelled") {
      return true;
    }

    return false;
  }, [pendingTrade]);

  const isReviewed = useMemo(() => {
    return pendingTrade?.review ? true : false;
  }, [pendingTrade]);

  const isAllowedToReview = useMemo(() => {
    if (pendingTrade?.order?.user === userAuth?.id) {
      return false;
    }

    return true;
  }, [pendingTrade, userAuth]);

  const tokenReleaseDisabled = useMemo(() => {
    return (
      !isBuyerConfirmedPayment ||
      loading ||
      pendingTrade?.transfer_trx_pending ||
      isTokenReleased
    );
  }, [isBuyerConfirmedPayment, loading, pendingTrade, isTokenReleased]);

  return (
    <div className={classes.infoCard}>
      {confirm && (
        <PopupLayout popupActive={confirm}>
          <ReleaseTokenPopup
            pendingTrade={pendingTrade}
            resetPopup={handleModalClose}
          />
        </PopupLayout>
      )}

      {cancelPopup && (
        <PopupLayout popupActive={cancelPopup}>
          <CancelTradePopup
            resetPopup={() => setCancelPopup(false)}
            trade={pendingTrade}
            isSeller={true}
          />
        </PopupLayout>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <SnackbarContent message="Review submitted successfully!" />
      </Snackbar>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={md ? "100%" : "100%"}
        flexWrap="wrap"
      >
        <Typography
          variant="body2"
          color={theme.palette.text.primary}
          fontSize={16}
          fontWeight={500}
        >
          {`${tradeType?.toUpperCase()} ${
            pendingTrade?.order?.token?.symbol
          } with ${pendingTrade?.order?.fiat?.fiat}`}
        </Typography>

        {!isTradeCancelled && !isTradeCompleted && (
          <Box display="flex" alignItems="center">
            <Typography
              textAlign="left"
              variant="body2"
              fontSize={14}
              fontWeight={500}
              color={theme.palette.mode === "light" ? "#778090" : "#646892"}
              mr={2}
            >
              <FormattedMessage
                id="Time remaining"
                defaultMessage="Time remaining"
              />
            </Typography>
            <Typography
              textAlign="left"
              variant="body2"
              fontSize={18}
              color={theme.palette.text.primary}
              fontWeight={600}
              letterSpacing={1.1}
            >
              <CountDownTime
                color={theme.palette.text.primary}
                isCompleted={isTradeCompleted}
                createdAt={pendingTrade?.created_at}
                timeWindow={pendingTrade?.time_limit}
                onComplete={() => setCancelTradeFlag(true)}
              />
            </Typography>
          </Box>
        )}
      </Box>

      <Grid container spacing={1}>
        <Grid item md={8} sm={12}>
          <Box mt={1}>
            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: "#c8e6c9",
                    color: "white",
                    border: "3px solid #04A56D",
                    padding: 3,
                  }}
                ></div>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  fontWeight={600}
                  pl={1}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Confirm Order Info"
                    defaultMessage="Confirm Order Info"
                  />
                </Typography>
              </div>
              <div className="d-flex justify-content-start">
                <div
                  style={{
                    borderLeft: `1px dotted ${theme.palette.text.primary}`,
                    height: "auto",
                    marginLeft: 5,
                    width: 20,
                  }}
                ></div>
                <div className="row w-100" style={{ maxWidth: 600 }}>
                  <div className="col-md-3">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage id="Name" defaultMessage="Name" />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color="#ef5350"
                        fontWeight={600}
                        letterSpacing={1.1}
                        sx={{ wordBreak: "break-word" }}
                      >
                        {pendingTrade?.buyer?.name}
                      </Typography>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage id="Email" defaultMessage="Email" />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={theme.palette.text.primary}
                        fontWeight={600}
                        letterSpacing={1.1}
                        sx={{ wordBreak: "break-word" }}
                      >
                        {pendingTrade?.buyer?.email}
                      </Typography>
                    </Box>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div
                  style={{
                    borderLeft: `1px dotted ${theme.palette.text.primary}`,
                    height: "auto",
                    marginLeft: 5,
                    width: 20,
                  }}
                ></div>
                <div className="row w-100" style={{ maxWidth: 600 }}>
                  <div className="col-md-3">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage id="Amount" defaultMessage="Amount" />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={"#ef5350"}
                        fontWeight={600}
                        letterSpacing={1.1}
                      >
                        {formatCurrency(pendingTrade?.fiat_amount, true)}{" "}
                        {pendingTrade?.order?.fiat?.fiat_label}
                      </Typography>
                    </Box>
                  </div>
                  <div className="col-md-3">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage id="Price" defaultMessage="Price" />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={theme.palette.text.primary}
                        fontWeight={600}
                        letterSpacing={1.1}
                      >
                        {formatCurrency(pendingTrade?.order?.order_unit_price)}{" "}
                        {pendingTrade?.order?.fiat?.fiat}
                      </Typography>
                    </Box>
                  </div>
                  <div className="col-md-3">
                    <Box mt={sm ? 1 : 2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage
                          id="Quantity"
                          defaultMessage="Quantity"
                        />
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={sm ? 16 : 18}
                        color={theme.palette.text.primary}
                        fontWeight={600}
                        letterSpacing={1.1}
                      >
                        {formatCurrency(
                          fromWei(
                            pendingTrade?.token_amount,
                            pendingTrade?.order?.token?.decimals
                          ),
                          true
                        )}{" "}
                        {pendingTrade?.order?.token?.symbol}
                      </Typography>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>

            <Box mt={2}>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: "#f9f9f9",
                    color: "white",
                    border: isTokenDeposited
                      ? "3px solid  #04A56D"
                      : "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  fontWeight={600}
                  pl={1}
                  width={sm ? "80%" : "100%"}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage id="Your" defaultMessage="Your" />{" "}
                  {formatCurrency(
                    fromWei(
                      pendingTrade?.token_amount,
                      pendingTrade?.order?.token?.decimals
                    )
                  )}{" "}
                  {pendingTrade?.order?.token?.symbol}{" "}
                  <FormattedMessage
                    id="has been reserved in PolkaBridge vault"
                    defaultMessage="has been reserved in PolkaBridge vault"
                  />
                </Typography>
              </div>{" "}
              <div
                style={sm ? { maxWidth: 300 } : {}}
                className="d-flex justify-content-start h-100 w-100"
              >
                <div
                  style={{
                    borderLeft: `1px dotted ${theme.palette.text.primary}`,
                    height: "100%",
                    minHeight: 340,
                    marginLeft: 5,
                    width: 20,
                  }}
                ></div>
                <div className={classes.paymentCard}>
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src="/images/deposit_funds.png"
                      alt="deposit"
                      height="130px"
                      style={{ marginTop: 20 }}
                    />{" "}
                  </div>
                  <div className="text-center">
                    <Button
                      style={{
                        borderRadius: 7,
                        background: isTokenDeposited
                          ? theme.palette.mode === "light"
                            ? "#bdbdbd"
                            : "#646892"
                          : "#6A55EA",
                        marginTop: 20,
                        color: "white",
                        minWidth: 160,
                        maxWidth: 300,
                        fontWeight: 600,
                        width: sm ? "80%" : "100%",
                        marginLeft: !sm && 5,
                      }}
                      onClick={() => {}}
                    >
                      <FormattedMessage
                        id="Token reserved"
                        defaultMessage="Token reserved"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </Box>

            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: "#f9f9f9",
                    color: "white",
                    border: isBuyerConfirmedPayment
                      ? "3px solid  #04A56D"
                      : "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  fontWeight={600}
                  pl={1}
                  py={0.5}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Waiting for buyer's confirmation"
                    defaultMessage="Waiting for buyer's confirmation"
                  />
                </Typography>
              </div>{" "}
              <div
                style={{
                  borderLeft: `1px dotted ${theme.palette.text.primary}`,
                  height: "auto",
                  marginLeft: 5,
                  width: 20,
                }}
              ></div>
              {!isTradeCancelled && pendingTrade?.transaction_status >= 1 && (
                <div
                  style={sm ? { maxWidth: 300 } : {}}
                  className="d-flex justify-content-start h-100"
                >
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,
                      height: "auto",
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>
                  <div className={classes.paymentCard}>
                    {isBuyerConfirmedPayment ? (
                      <>
                        <div
                          className="d-flex  justify-content-center align-items-center"
                          style={{ color: theme.palette.text.primary }}
                        >
                          <CheckIcon
                            color="success"
                            fontSize="small"
                            style={{ marginRight: 10 }}
                          />
                          <FormattedMessage
                            id="Buyer confirmed payment"
                            defaultMessage="Buyer confirmed payment"
                          />
                        </div>
                        <Stack spacing={1} py={4} px={2}>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={16}
                            fontWeight={500}
                            color={theme.palette.text.primary}
                          >
                            {pendingTrade?.transfered_mode?.payment_mode}
                          </Typography>
                          {selectedPaymentOptionFields?.map((field, index) => {
                            if (!pendingTrade?.transfered_mode?.[field?.key]) {
                              return null;
                            }

                            if (field?.key === "qr_code") {
                              return (
                                <Box
                                  my={1.5}
                                  display="flex"
                                  flexDirection={sm && "column"}
                                  alignItems={!sm && "center"}
                                  flexWrap="wrap"
                                  key={index}
                                >
                                  <label
                                    htmlFor="inputEmail4"
                                    className={classes.label}
                                    style={{
                                      float: "left",
                                      minWidth: "fit-content",
                                      width: "50%",
                                      marginRight: 5,
                                    }}
                                  >
                                    <Typography fontWeight={500} mb={sm && 1}>
                                      <FormattedMessage
                                        id="QR Code"
                                        defaultMessage="QR Code"
                                      />
                                      :
                                    </Typography>
                                  </label>
                                  {pendingTrade?.transfered_mode?.qr_code && (
                                    <img
                                      className={classes.qrCodeImage}
                                      alt="deposit"
                                      src={
                                        pendingTrade?.transfered_mode?.qr_code
                                      }
                                    />
                                  )}
                                </Box>
                              );
                            }

                            return (
                              <Box
                                my={1}
                                display="flex"
                                flexDirection={sm && "column"}
                                alignItems={!sm && "center"}
                                flexWrap="wrap"
                                key={index}
                              >
                                <label
                                  htmlFor="inputEmail4"
                                  className={classes.label}
                                  style={{
                                    float: "left",
                                    minWidth: "fit-content",
                                    width: "50%",
                                    marginRight: 5,
                                  }}
                                >
                                  <Typography fontWeight={500}>
                                    <FormattedMessage
                                      id={field?.label}
                                      defaultMessage={field?.label}
                                    />
                                    :
                                  </Typography>
                                </label>
                                <Box display="flex">
                                  <Typography
                                    textAlign="start"
                                    fontWeight={500}
                                    style={{ wordBreak: "break-all" }}
                                    color={theme.palette.text.primary}
                                  >
                                    {
                                      pendingTrade?.transfered_mode?.[
                                        field?.key
                                      ]
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                        </Stack>
                      </>
                    ) : (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src="/loader.gif"
                            alt="loader"
                            height="100px"
                            style={{ marginTop: 20 }}
                          />
                        </div>
                        <Typography
                          textAlign="left"
                          variant="body2"
                          fontSize={14}
                          fontWeight={600}
                          pt={2}
                          color={theme.palette.text.primary}
                        >
                          <FormattedMessage
                            id="Waiting for confirmation"
                            defaultMessage="Waiting for confirmation"
                          />
                        </Typography>
                        <div className="text-center mt-2">
                          <Button
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              fontSize: 12,
                            }}
                            disabled={
                              !notifyBuyerFlag || isBuyerNotified || loading
                            }
                            onClick={handleBuyerNotify}
                          >
                            {isBuyerNotified ? (
                              <FormattedMessage
                                id="Buyer notified"
                                defaultMessage="Buyer notified"
                              />
                            ) : (
                              <FormattedMessage
                                id="Notify buyer"
                                defaultMessage="Notify buyer"
                              />
                            )}
                            {!notifyBuyerFlag && (
                              <Box ml={0.5}>
                                <CountDownTime
                                  onComplete={() => setNotifyBuyer(true)}
                                  timeWindow={3 * 60}
                                  font={12}
                                  createdAt={pendingTrade?.created_at}
                                  color={theme.palette.text.primary}
                                />
                              </Box>
                            )}
                          </Button>
                          {/* <Button
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              marginLeft: 10,
                              fontSize: 12,
                            }}
                            disabled={!raiseDisputeFlag || loading}
                          >
                            Raise dispute
                            {!raiseDisputeFlag && (
                              <Box ml={0.5}>
                                <CountDownTime
                                  onComplete={() => setRaiseDispute(true)}
                                  timeWindow={5 * 60}
                                  font={12}
                                  createdAt={pendingTrade?.created_at}
                                />
                              </Box>
                            )}
                          </Button> */}
                          <Button
                            style={{
                              backgroundColor: "white",
                              border: `1px solid #6A55EA`,
                              borderRadius: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              marginLeft: 10,
                              fontSize: 12,
                            }}
                            onClick={() => setCancelPopup(true)}
                            disabled={
                              !cancelTradeFlag ||
                              loading ||
                              isBuyerConfirmedPayment
                            }
                          >
                            <FormattedMessage
                              id="Cancel trade"
                              defaultMessage="Cancel trade"
                            />
                            {!cancelTradeFlag && (
                              <Box ml={0.5}>
                                <CountDownTime
                                  onComplete={() => {}}
                                  font={12}
                                  timeWindow={pendingTrade?.time_limit}
                                  createdAt={pendingTrade?.created_at}
                                />
                              </Box>
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Box>

            {isDisputeRaised && (
              <Box>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: 8,
                      height: 8,

                      borderRadius: "50%",
                      backgroundColor: isDisputeRaised ? "#c8e6c9" : "#f9f9f9",
                      color: "white",
                      border: isDisputeRaised
                        ? "3px solid #04A56D"
                        : "3px solid #bdbdbd",
                      padding: 3,
                    }}
                  ></div>{" "}
                  <Typography
                    textAlign="left"
                    variant="body2"
                    fontSize={16}
                    fontWeight={600}
                    pl={1}
                    color={theme.palette.text.primary}
                  >
                    <FormattedMessage
                      id="Dispute submitted"
                      defaultMessage="Dispute submitted"
                    />
                  </Typography>
                </div>{" "}
                <div
                  style={{
                    borderLeft: `1px dotted ${theme.palette.text.primary}`,

                    height: "auto",
                    marginLeft: 5,
                    width: 20,
                  }}
                ></div>
                <div
                  // style={sm ? { maxWidth: 300 } : {}}
                  className="d-flex justify-content-start h-100 w-100"
                >
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,

                      height: "auto",
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>
                  <Box className={classes.paymentCard}>
                    <div className="d-flex flex-column align-items-start h-100">
                      <DisputeProgress
                        pendingTrade={pendingTrade}
                        tradeType={tradeType}
                      />
                      {
                        <>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={15}
                            fontWeight={600}
                            mt={2}
                            pl={sm && 0.5}
                            color={theme.palette.text.primary}
                          >
                            <FormattedMessage
                              id="Reason"
                              defaultMessage="Reason"
                            />
                            :
                          </Typography>

                          <Typography
                            textAlign="left"
                            variant="body2"
                            w="50%"
                            fontSize={16}
                            mt={0.5}
                            pl={sm && 0.5}
                            color={theme.palette.text.primary}
                          >
                            {pendingTrade?.dispute?.reason === "Others"
                              ? pendingTrade?.dispute?.description
                              : pendingTrade?.dispute?.reason}
                          </Typography>
                          <Box mb={3}>
                            <DisputeProofs
                              isTradeCompleted={isTradeCompleted}
                              pendingTrade={pendingTrade}
                              userType="seller"
                              allowUpload={
                                isTradeCancelled ||
                                isTradeCompleted ||
                                isDisputeCancelled
                                  ? false
                                  : true
                              }
                              title="Your Proofs:"
                            />
                            <DisputeProofs
                              isTradeCompleted={isTradeCompleted}
                              pendingTrade={pendingTrade}
                              userType="buyer"
                              title="Buyer's Proofs:"
                            />
                          </Box>
                        </>
                      }
                    </div>
                  </Box>
                </div>
              </Box>
            )}

            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,

                    borderRadius: "50%",
                    backgroundColor: "#f9f9f9",
                    color: "white",
                    border: isTokenDeposited
                      ? "3px solid  #04A56D"
                      : "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  fontWeight={600}
                  pl={1}
                  py={0.5}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Release tokens to buyer wallet"
                    defaultMessage="Release tokens to buyer wallet"
                  />
                </Typography>
              </div>{" "}
              {!isTradeCancelled && (
                <div className="d-flex justify-content-start h-100">
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,

                      height: "auto",
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>
                  <div className={classes.paymentCard}>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src="/images/token_released.png"
                        alt="release"
                        height="130px"
                        style={{ marginTop: 20 }}
                      />{" "}
                    </div>
                    <div className="text-center">
                      <Button
                        style={{
                          borderRadius: 7,
                          background: tokenReleaseDisabled
                            ? theme.palette.mode === "light"
                              ? "#bdbdbd"
                              : "#646892"
                            : "#6A55EA",
                          marginTop: 20,
                          color: "white",
                          minWidth: 200,
                          maxWidth: 300,
                          fontWeight: 600,
                          width: sm ? "80%" : "100%",
                          marginLeft: !sm && 5,
                        }}
                        onClick={() => setConfirm(true)}
                        disabled={tokenReleaseDisabled}
                      >
                        {isTokenReleased ? (
                          <>
                            <CheckIcon
                              color="success"
                              fontSize="small"
                              style={{ marginRight: 10 }}
                            />
                            <FormattedMessage
                              id="Token released"
                              defaultMessage="Token released"
                            />
                          </>
                        ) : loading || pendingTrade?.transfer_trx_pending ? (
                          <FormattedMessage
                            id="Releasing..."
                            defaultMessage="Releasing..."
                          />
                        ) : (
                          <FormattedMessage
                            id="Release tokens"
                            defaultMessage="Release tokens"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Box>

            <Box>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor: "#f9f9f9",
                    color: "white",
                    border: "3px solid #bdbdbd",
                    padding: 3,
                  }}
                ></div>{" "}
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  fontWeight={600}
                  pl={1}
                  py={0.5}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage id="Completed" defaultMessage="Completed" />
                </Typography>
              </div>{" "}
              {isTradeCompleted && (
                <div className="d-flex justify-content-start h-100">
                  <div
                    style={{
                      borderLeft: `1px dotted ${theme.palette.text.primary}`,
                      height: "auto",
                      marginLeft: 5,
                      width: 20,
                    }}
                  ></div>
                  <div className={classes.paymentCard}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src="https://cdn3d.iconscout.com/3d/premium/thumb/check-2872554-2389850.png"
                          alt="check"
                          height="100px"
                          style={{ marginTop: 20 }}
                        />{" "}
                      </div>
                      <Typography
                        textAlign="center"
                        variant="body2"
                        fontSize={16}
                        fontWeight={600}
                        pl={1}
                        color={theme.palette.text.primary}
                      >
                        <FormattedMessage
                          id="Your order is executed successfully."
                          defaultMessage="Your order is executed successfully."
                        />
                      </Typography>
                      <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* <Button
                          style={{
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? "white"
                                : "#646892",
                            border: `1px solid #6A55EA`,
                            borderRadius: 14,
                            paddingLeft: 20,
                            paddingRight: 20,
                            fontSize: 12,
                            whiteSpace: "nowrap",
                            color: theme.palette.text.primary,
                          }}
                        >
                          View your order
                        </Button> */}
                      </Box>
                      <Box
                        display={isAllowedToReview ? "flex" : "none"}
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt={6}
                      >
                        <TextField
                          placeholder={`${intl.formatMessage({
                            id: "Write a review",
                            defaultMessage: "Write a review",
                          })}`}
                          multiline
                          fullWidth
                          textAlign="center"
                          variant="outlined"
                          size="small"
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            borderRadius: 1,
                            ".MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: theme.palette.text.primary,
                              color: theme.palette.text.primary,
                            },
                          }}
                          maxRows={2}
                          value={
                            isReviewed ? pendingTrade?.review?.comment : review
                          }
                          disabled={isReviewed}
                          onChange={(e) => setReview(e.target.value)}
                          className={classes.textInput}
                        />
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={1.5}
                          width="100%"
                        >
                          <Rating
                            size="large"
                            value={
                              isReviewed ? pendingTrade?.review?.rating : rating
                            }
                            readOnly={isReviewed}
                            onChange={(e, newRating) => setRating(newRating)}
                          />
                          <Button
                            sx={{
                              ml: 2,
                              display: isReviewed ? "none" : "block",
                            }}
                            variant="outlined"
                            disabled={!rating}
                            onClick={handleReviewSubmit}
                          >
                            <FormattedMessage
                              id="Submit"
                              defaultMessage="Submit"
                            />
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} sm={12}>
          <ChatBox />
        </Grid>
      </Grid>
    </div>
  );
}
