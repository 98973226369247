import {
  DISPUTE_ERROR_ADMIN,
  DISPUTE_LOADING_ADMIN,
  GET_DISPUTES_ADMIN,
  GET_DISPUTE_ADMIN,
  SET_CURRENT_ADMIN_REVIEW,
  SET_DISPUTE_COUNT,
} from "actions/types";

const initalState = {
  disputes: [],
  dispute: null,
  total: 0,
  loading: false,
  error: null,
  currentAdminReview: null,
};

export default function adminDisputeReducer(state = initalState, action) {
  switch (action.type) {
    case GET_DISPUTES_ADMIN:
      return {
        ...state,
        disputes: action?.payload,
      };
    case GET_DISPUTE_ADMIN:
      return {
        ...state,
        dispute: action.payload,
      };
    case DISPUTE_LOADING_ADMIN:
      return {
        ...state,
        loading: action.payload,
      };
    case DISPUTE_ERROR_ADMIN:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_DISPUTE_COUNT:
      return {
        ...state,
        total: action.payload,
      };
    case SET_CURRENT_ADMIN_REVIEW:
      return {
        ...state,
        currentAdminReview: action.payload,
      };
    default:
      return state;
  }
}
