import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";

import { FormattedMessage } from "react-intl";
import { getRedeemHistory } from "utils/httpCalls/questCalls";
import QuestsEndedTable from "./QuestsEndedTable";
import QuestsEndedCardList from "./QuestsEndedCardList";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  infoCardError: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border: "1px solid #eeeeee",
    padding: 20,
    backgroundColor: "rgba(255,0,0,0.06)",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #eeeeee"
        : "1px solid #212446",
    padding: 30,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#ffffff"
        : theme.palette.background.highlight,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
  },
  subtitle: {
    color:
      theme.palette.mode === "light" ? "#414141" : theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 16,
  },
  label: {
    color: "#c4c4c4",
    fontWeight: 500,
  },
  submitButton: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
  },
  orderTabSelected: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #eeeeee"
        : "1px solid #25284B",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: theme.palette.background.primary,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1em",
  },
  tr: {
    width: "100%",
    maxHeight: 20,
  },
  trHighlight: {
    width: "100%",
    backgroundColor:
      theme.palette.mode === "light"
        ? "#f7f7f7"
        : theme.palette.background.highlight,
    height: 45,
  },
  userText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  filterCard: {
    marginTop: 10,
    marginBottom: 10,
    height: "100%",
    width: "80%",
    border: "1px solid #eeeeee",

    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
}));

function QuestEnded() {
  const classes = useStyles();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(null);
  const [totalHistory, setTotalHistory] = useState(null);
  const authState = useSelector((state) => state?.user);
  const authenticated = useSelector((state) => state?.user?.authenticated);

  //load quests
  useEffect(() => {
    async function fetchData() {
      if (!authState?.jwtToken || !authState?.account) {
        return;
      }
      setLoading(true);
      const userRedeemRes = await getRedeemHistory(
        pageNumber,
        authState?.jwtToken,
        authState?.account
      );
      setHistory(userRedeemRes?.data?.redeemHistory);
      setTotalHistory(userRedeemRes?.data?.totalRedeems);
      setLoading(false);
    }
    fetchData();

    return;
  }, [authState?.account, authState?.jwtToken, pageNumber]);

  return (
    <Box className={classes.background}>
      {authenticated && (
        <Container>
          <Box>
            <Typography variant="h3" className={classes.title}>
              <FormattedMessage
                id="Redeem History"
                defaultMessage="Redeem History"
              />
            </Typography>
          </Box>
          <Box mt={2}>
            {sm ? (
              <QuestsEndedCardList
                loading={loading}
                requests={history}
                page={pageNumber}
                totalPages={Math.ceil(totalHistory / 10)}
                changePage={(_page) => setPageNumber(_page)}
              />
            ) : (
              <QuestsEndedTable
                requests={history}
                loading={loading}
                classes={classes}
                page={pageNumber}
                changePage={(_page) => setPageNumber(_page)}
                totalPages={Math.ceil(totalHistory / 10)}
              />
            )}
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default QuestEnded;
