import {
  CREATE_TRADE_LOADING,
  FETCH_TRADE_LOADING,
  GET_FIAT_TRANSFER_DATA,
  GET_TOTAL_TRADES,
  GET_TRADE,
  GET_TRADES,
  SET_TRADE_ERROR,
  TRADE_LOADING,
} from "../actions/types";

const initalState = {
  trades: [],
  fiatTransfers: {}, // trade_id: fiatTransferData
  tokenTransfers: {}, // trade_id: tokenTransfers
  totalTrades: 0,
  trade: {},
  createTradeLoading: false,
  fetchTradeLoading: false,
  tradeError: null,
  loading: false,
};

export default function tradeReducer(state = initalState, action) {
  switch (action.type) {
    case GET_TRADES:
      return {
        ...state,
        trades: [...action.payload],
        tradeError: null,
      };
    case GET_TOTAL_TRADES:
      return {
        ...state,
        totalTrades: action.payload,
        tradeError: null,
      };
    case GET_TRADE:
      return {
        ...state,
        trade: action.payload,
        tradeError: null,
      };
    case CREATE_TRADE_LOADING:
      return {
        ...state,
        createTradeLoading: action.payload,
      };
    case FETCH_TRADE_LOADING:
      return {
        ...state,
        fetchTradeLoading: action.payload,
      };

    case SET_TRADE_ERROR:
      return {
        ...state,
        tradeError: action.payload,
      };
    case TRADE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_FIAT_TRANSFER_DATA:
      const transferData = action?.payload;
      let newFiatState = state?.fiatTransfers;
      newFiatState[transferData?.transaction] = transferData;
      return {
        ...state,
        fiatTransfers: newFiatState,
      };
    default:
      return state;
  }
}
