import React, { useMemo, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { makeStyles } from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormattedMessage } from "react-intl";
import DisputePopup from "../../../common/popups/Dispute/DisputePopup";
import ProofList from "../../../common/ProofList";

const useStyles = makeStyles((theme) => ({
  inputAreaContainer: {
    width: 150,
    height: 100,
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    margin: 3,
    border: "1px solid #f0f0f0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 5,
    padding: 2,
    backgroundColor: theme.palette.background.highlight,
    color: theme.palette.text.primary,
  },
  username: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 18,
  },
  label: {
    color:
      theme.palette.mode === "light" ? "#919191" : theme.palette.text.primary,
    fontWeight: 500,
  },
  labelDescription: {
    color:
      theme.palette.mode === "light" ? "#919191" : theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 12,
  },
  submitButton: {
    borderRadius: 10,
    width: 200,
    height: 45,
    backgroundColor: theme.palette.button.primary,
    padding: "8px 30px 8px 30px",
    color:
      theme.palette.mode === "light" ? "#ffffff" : theme.palette.text.primary,
    border: "none",
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  cancelButton: {
    borderRadius: 10,
    width: 200,
    height: 45,
    backgroundColor: theme.palette.button.secondary,
    padding: "8px 30px 8px 30px",
    color:
      theme.palette.mode === "light" ? theme.palette.text.primary : "#ffffff",
    border: `1px solid ${theme.palette.button.primary}`,
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
}));

export default function DisputeProofs({
  isTradeCompleted,
  pendingTrade,
  title,
  userType,
  allowUpload = false,
}) {
  const [loading, setLoading] = useState(false);

  const [disputePopup, setDisputePopup] = useState(false);

  const theme = useTheme();

  const classes = useStyles();

  const proofs = useMemo(() => {
    if (userType === "buyer") {
      return pendingTrade?.dispute?.user_proofs;
    }
    return pendingTrade?.dispute?.other_user_proofs;
  }, [pendingTrade, userType]);

  return (
    <Box mt={2} width="100%">
      <DisputePopup
        resetPopup={() => setDisputePopup(false)}
        trade={pendingTrade}
        isSeller={userType === "seller" ? true : false}
        popupActive={disputePopup}
        isMoreProof={true}
      />
      <Typography
        textAlign="left"
        variant="body2"
        fontSize={15}
        fontWeight={500}
        color={theme.palette.text.primary}
      >
        {/* <FormattedMessage id={title} defaultMessage={title} /> */}
      </Typography>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <ProofList title={title} proofs={proofs} allowUpload={allowUpload} />
        {allowUpload && (
          <Box className={classes.inputAreaContainer}>
            {loading && (
              <Box
                padding={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  w="50%"
                  fontWeight={500}
                  mt={1}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage id="Uploading" defaultMessage="Uploading" />
                  ...
                </Typography>
                <CloudUploadIcon fontSize="large" />
              </Box>
            )}
            {!loading && (
              <div
                style={{
                  cursor: "pointer",
                  for: "fileSelection",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: 100,
                  padding: 10,
                }}
                onClick={() => setDisputePopup(true)}
              >
                <ImageIcon style={{ fontSize: 24 }} fontSize="large" />
                <Typography fontSize={12} textAlign="center">
                  <FormattedMessage
                    id="Upload Proof of Payment"
                    defaultMessage="Upload Proof of Payment"
                  />
                  <span
                    style={{ color: "#0C7ED0", fontSize: 12, marginLeft: 3 }}
                  >
                    <FormattedMessage id="Browse" defaultMessage="Browse" />
                  </span>
                </Typography>
              </div>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
