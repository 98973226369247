import {
  Box,
  Button,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  camelize,
  formatCurrency,
  formatTime,
  formattedTokenLabel,
  fromWei,
  isExpired,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import Pagination from "common/Pagination";
import { FormattedMessage } from "react-intl";

export default function UserTrades({
  pendingTrades,
  classes,
  page,
  changePage,
  totalPages,
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const authState = useSelector((state) => state?.user);
  return (
    <Box className={classes.tableCard}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Coin" defaultMessage="Coin" />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Price" defaultMessage="Price" />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage
                  id="Crypto amount"
                  defaultMessage="Crypto amount"
                />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage
                  id="Fiat amount"
                  defaultMessage="Fiat amount"
                />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Order type" defaultMessage="Order type" />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Status" defaultMessage="Status" />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Time" defaultMessage="Time" />
              </Typography>
            </th>
            <th style={{ padding: "10px 20px" }}>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Operation" defaultMessage="Operation" />
              </Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {pendingTrades?.map((item, index) => (
            <tr
              className={classes.tr}
              key={index}
              style={
                item?.dispute?.dispute_status === "pending" ||
                item?.dispute?.dispute_status === "in_review"
                  ? {
                      backgroundColor:
                        theme.palette.mode == "light" ? "#FED9DA" : "#502D4A",
                    }
                  : item?.status === "pending"
                  ? !isExpired(item?.created_at, item?.time_limit)
                    ? {
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? theme.palette.background.highlight
                            : "#414071",
                      }
                    : {
                        backgroundColor:
                          theme.palette.mode == "light" ? "#F0E68C" : "#493EA0",
                      }
                  : {}
              }
            >
              <td style={{ width: "12%" }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.userText}
                >
                  {formattedTokenLabel(item?.order?.token)}
                </Typography>
              </td>

              <td
                className={classes.otherText}
                style={{ padding: "20px 20px" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {formatCurrency(item?.order?.order_unit_price, true)}
                  <span style={{ fontSize: 10, paddingLeft: 2 }}>
                    {item?.order?.fiat?.fiat}
                  </span>
                </Typography>
              </td>
              <td
                className={classes.otherText}
                style={{ padding: "20px 20px" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {formatCurrency(
                    fromWei(item?.token_amount, item?.order?.token?.decimals),
                    true
                  )}
                  <span style={{ fontSize: 10, paddingLeft: 2 }}>
                    {item?.order?.token?.symbol}
                  </span>
                </Typography>
              </td>
              <td style={{ padding: "20px 20px" }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {formatCurrency(item?.fiat_amount, true)}{" "}
                  <span style={{ fontSize: 10, paddingLeft: 2 }}>
                    {item?.order?.fiat?.fiat_label}
                  </span>
                </Typography>
              </td>
              <td
                className={classes.otherText}
                style={{ padding: "20px 20px" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {item?.buyer?._id?.toString() ===
                  authState?.id?.toString() ? (
                    <FormattedMessage id="Buy" defaultMessage="Buy" />
                  ) : (
                    <FormattedMessage id="Sell" defaultMessage="Sell" />
                  )}
                </Typography>
              </td>
              <td
                className={classes.otherText}
                style={{ padding: "20px 20px" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={15}
                  style={{ fontWeight: 500, color: theme.palette.text.primary }}
                  className={classes.otherText}
                >
                  {item?.status === "pending" &&
                    item?.transaction_status === 0 &&
                    item?.dispute?.dispute_status !== "pending" &&
                    item?.dispute?.dispute_status !== "in_review" && (
                      <FormattedMessage
                        id={"Joined"}
                        defaultMessage={"Joined"}
                      />
                    )}
                  {item?.status === "pending" &&
                    item?.transaction_status === 1 &&
                    item?.dispute?.dispute_status !== "pending" &&
                    item?.dispute?.dispute_status !== "in_review" && (
                      <FormattedMessage
                        id={"Tokens Locked"}
                        defaultMessage={"Tokens Locked"}
                      />
                    )}
                  {item?.status === "pending" &&
                    item?.transaction_status === 2 &&
                    item?.dispute?.dispute_status !== "pending" &&
                    item?.dispute?.dispute_status !== "in_review" && (
                      <FormattedMessage
                        id={"Payment Done"}
                        defaultMessage={"Payment Done"}
                      />
                    )}
                  {item?.status === "pending" &&
                    (item?.dispute?.dispute_status === "pending" ||
                      item?.dispute?.dispute_status === "in_review") && (
                      <FormattedMessage
                        id={"In Dispute"}
                        defaultMessage={"In Dispute"}
                      />
                    )}

                  {item?.status === "cancelled" && (
                    <FormattedMessage
                      id={"Cancelled"}
                      defaultMessage={"Cancelled"}
                    />
                  )}
                  {item?.status === "completed" && (
                    <FormattedMessage
                      id={"Completed"}
                      defaultMessage={"Completed"}
                    />
                  )}
                </Typography>
              </td>
              <td
                className={classes.otherText}
                style={{ padding: "20px 20px" }}
              >
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {formatTime(item?.created_at)}
                </Typography>
              </td>
              <td
                className={classes.otherText}
                style={{ padding: "20px 20px" }}
              >
                <Button
                  style={{
                    borderRadius: 10,
                    backgroundColor: theme.palette.primary.main,
                    padding: "5px 20px 5px 20px",
                    color: "white",
                  }}
                  onClick={() => navigate(`/order-waiting/${item?._id}`)}
                >
                  <FormattedMessage id="View" defaultMessage="View" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <Pagination
          page={page}
          totalPages={totalPages}
          changePage={changePage}
        />
      )}
    </Box>
  );
}
