import {
  Box,
  CircularProgress,
  Container,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { getUserTrades } from "../../actions/tradeActions";
import { useNavigate } from "react-router-dom";
import { getUserOrders } from "../../actions/orderActions";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import UserTrades from "./UserTrades";
import UserAds from "./UserAds";
import WalletButton from "../../common/WalletButton";
import TransactionFilter from "./TransactionFilter";
import UserTradesCardList from "./UserTradesCardList";
import UserAdsCardList from "./UserAdsCardList";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  infoCardError: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border: "1px solid #eeeeee",
    padding: 20,
    backgroundColor: "rgba(255,0,0,0.06)",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border: "1px solid #eeeeee",
    padding: 30,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
  },
  subtitle: {
    color: "#414141",
    fontWeight: 400,
    fontSize: 16,
  },
  label: {
    color: "#c4c4c4",
    fontWeight: 500,
  },
  submitButton: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
  },
  orderTabSelected: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #eeeeee"
        : "1px solid #25284B",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: theme.palette.background.primary,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  table: {
    width: "100%",
  },
  tr: {
    width: "100%",
    height: 45,
  },
  userText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  filterCard: {
    marginTop: 10,
    marginBottom: 10,
    height: "100%",
    width: "80%",
    border: "1px solid #eeeeee",

    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  walletBtn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 18px 7px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 15,
    "&:hover": {
      background: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 15,
      width: 150,
    },
  },
}));

function MyOrders() {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState({
    token: null,
    chain: null,
    status: null,
    orderType: null,
    dateRange: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector((state) => state?.user);
  const authenticated = useSelector((state) => state?.user?.authenticated);
  const loading = useSelector((state) => state?.userTrade?.fetchTradeLoading);
  const pendingTrades = useSelector((state) => state?.userTrade?.trades);
  const totalTrades = useSelector((state) => state?.userTrade?.totalTrades);

  const userAds = useSelector((state) => state?.order?.userOrders);
  const totalUserAds = useSelector((state) => state?.order?.totalOrders);
  const userFiat = useSelector((state) => state?.user?.userFiat);

  const parsedQuery = useParsedQueryString();

  const currentTab = useMemo(() => {
    const page = parsedQuery.page;
    if (page === "ads") {
      return 0;
    } else if (page === "transactions") {
      return 1;
    } else {
      return 0;
    }
  }, [parsedQuery]);

  useEffect(() => {
    if (!authState?.id || !userFiat) {
      return;
    }

    if (currentTab === 0) {
      dispatch(
        getUserOrders(
          pageNumber,
          { ...{}, user: authState?.id, frompage: "myorder" },
          authState?.account,
          authState?.jwtToken
        )
      );
    }

    dispatch(
      getUserTrades(authState?.account, authState?.jwtToken, {
        ...filter,
        page: pageNumber,
      })
    );
  }, [authState, filter, currentTab, pageNumber]);

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      navigate("/my-orders?page=ads");
    } else if (newValue === 1) {
      navigate("/my-orders?page=transactions");
    }
  };

  return (
    <Box className={classes.background}>
      {authenticated && (
        <Container>
          <Box>
            <Typography variant="h3" className={classes.title}>
              <FormattedMessage id="My Orders" defaultMessage="My Orders" />
            </Typography>
          </Box>
          <Box mt={2}>
            <Box sx={{ width: "100%", marginBottom: 1 }}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  value={0}
                  label={
                    <FormattedMessage id="My Ads" defaultMessage="My Ads" />
                  }
                />
                <Tab
                  value={1}
                  label={
                    <FormattedMessage
                      id="My Orders"
                      defaultMessage="My Orders"
                    />
                  }
                />
              </Tabs>
            </Box>

            {currentTab === 1 && (
              <TransactionFilter filter={filter} setFilter={setFilter} />
            )}

            <Box>
              {currentTab ? (
                md ? (
                  <UserTradesCardList
                    loading={loading}
                    pendingTrades={pendingTrades}
                    page={pageNumber}
                    totalPages={Math.ceil(totalTrades / 10)}
                    changePage={(_page) => setPageNumber(_page)}
                  />
                ) : (
                  <UserTrades
                    pendingTrades={pendingTrades}
                    classes={classes}
                    page={pageNumber}
                    changePage={(_page) => setPageNumber(_page)}
                    totalPages={Math.ceil(totalTrades / 10)}
                  />
                )
              ) : md ? (
                <UserAdsCardList
                  userAds={userAds}
                  page={pageNumber}
                  totalPages={Math.ceil(totalUserAds / 10)}
                  changePage={(_page) => setPageNumber(_page)}
                />
              ) : (
                <UserAds
                  userAds={userAds}
                  classes={classes}
                  page={pageNumber}
                  totalPages={Math.ceil(totalUserAds / 10)}
                  changePage={(_page) => setPageNumber(_page)}
                />
              )}
              <div className="text-center">
                {loading && <CircularProgress />}
              </div>
            </Box>
          </Box>
        </Container>
      )}

      {!authenticated && (
        <Container>
          <div className={classes.infoCardError}>
            <Box
              height={250}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <div className="text-center">
                <img
                  src="https://cdn3d.iconscout.com/3d/premium/thumb/incognito-mode-5847986-4897792.png"
                  style={{ height: 100 }}
                />
              </div>
              <Box my={2} style={{ color: theme.palette.text.primary }}>
                <h4 className="text-center">
                  <FormattedMessage
                    id="Unauthorised Access"
                    defaultMessage="Unauthorised Access"
                  />
                  !
                </h4>
                <Typography textAlign="center">
                  <FormattedMessage
                    id="Please connect your wallet first to continue P2P trading."
                    defaultMessage="Please connect your wallet first to continue P2P trading."
                  />
                </Typography>
              </Box>
              <WalletButton />
            </Box>
          </div>
        </Container>
      )}
    </Box>
  );
}

export default MyOrders;
