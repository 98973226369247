import axios from "axios";
import { globalHeadersAdmin } from "../index";
import { BASE_API_ENDPOINT } from "../index";

export const loginCall = async (email: string, password: string) => {
  try {
    const body = {
      email: email,
      password: password,
    };
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/admin-auth-apis/v1/login`,
      body,
      {
        headers: { ...globalHeadersAdmin },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("loginCall admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getAdminCall = async (token: string) => {
  try {
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/admin-auth-apis/v1/admin`,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("get admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getAdminsCall = async (token: string, page: number) => {
  try {
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/admin-auth-apis/v1/admins/${page}`,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("get admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getPassword = async (token: string) => {
  try {
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/admin-auth-apis/v1/strong-password/15`,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("get admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const createAdmin = async (payload: any, token: string) => {
  try {
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/admin-auth-apis/v1/admin`,
      payload,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("get admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const deleteAdmin = async (adminId: any, token: string) => {
  try {
    const result = await axios.delete(
      `${BASE_API_ENDPOINT}/admin-auth-apis/v1/admin/${adminId}`,
      {
        headers: { ...globalHeadersAdmin, "x-auth-token": token },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("get admin: error ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
