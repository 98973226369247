import { Typography, useMediaQuery, useTheme } from "@mui/material";

function DayProgress({ day }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="d-flex flex-row mb-1">
      <Typography
        className="mr-1"
        fontSize={sm ? 12 : 14}
        color={day[0] ? theme.palette.action.green : theme.palette.action.red}
      >
        M
      </Typography>
      <Typography
        className="mx-1"
        fontSize={sm ? 12 : 14}
        color={day[1] ? theme.palette.action.green : theme.palette.action.red}
      >
        T
      </Typography>
      <Typography
        className="mx-1"
        fontSize={sm ? 12 : 14}
        color={day[2] ? theme.palette.action.green : theme.palette.action.red}
      >
        W
      </Typography>
      <Typography
        className="mx-1"
        fontSize={sm ? 12 : 14}
        color={day[3] ? theme.palette.action.green : theme.palette.action.red}
      >
        T
      </Typography>
      <Typography
        className="mx-1"
        fontSize={sm ? 12 : 14}
        color={day[4] ? theme.palette.action.green : theme.palette.action.red}
      >
        F
      </Typography>
      <Typography
        className="mx-1"
        fontSize={sm ? 12 : 14}
        color={day[5] ? theme.palette.action.green : theme.palette.action.red}
      >
        S
      </Typography>
      <Typography
        className="mx-1"
        fontSize={sm ? 12 : 14}
        color={day[6] ? theme.palette.action.green : theme.palette.action.red}
      >
        S
      </Typography>
    </div>
  );
}

export default DayProgress;
