import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { formatCurrency, fromWei, toWei } from "../../../utils/helper";
import BigNumber from "bignumber.js";

import { useSelector } from "react-redux";
import DepositPopup from "../../../common/popups/DepositPopup";
import { FormattedMessage } from "react-intl";

export default function UserBalance({
  inputTokens,
  selectedToken,
  handleMaxToken,
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const wallet = useSelector((state) => state?.wallet);

  const [depositPopup, setDepositPopup] = React.useState({
    open: false,
    type: null,
  });

  const selectedTokenDeposits = useMemo(() => {
    if (!wallet?.tokenBalances?.[selectedToken?._id]) {
      return "0";
    }
    return wallet?.tokenBalances?.[selectedToken?._id]?.available_balance;
  }, [selectedToken, wallet]);

  const depositsNeeded = useMemo(() => {
    const _tokenAmt = toWei(inputTokens, selectedToken?.decimals);

    if (!_tokenAmt || !selectedTokenDeposits) {
      return "0";
    }

    if (new BigNumber(selectedTokenDeposits).gte(_tokenAmt)) {
      return "0";
    }

    const _depositNeeded = new BigNumber(_tokenAmt)
      .minus(selectedTokenDeposits)
      ?.toString();

    return _depositNeeded;
  }, [selectedTokenDeposits, inputTokens, selectedToken]);

  const isSufficientDeposits = useMemo(() => {
    return new BigNumber(depositsNeeded).eq(0);
  }, [depositsNeeded]);

  const handleDeposit = useCallback(() => {
    setDepositPopup({ open: true, type: "deposit" });
  }, [depositPopup, setDepositPopup]);

  const closeDeposit = (tokenName) => {
    setDepositPopup({ open: false, type: null });
  };

  return (
    <Box>
      <DepositPopup
        token={selectedToken}
        state={depositPopup}
        handleClose={closeDeposit}
      />
      <Box
        className="d-flex justify-content-end  align-items-center"
        width="100%"
      >
        <Box className="d-flex align-items-center">
          <Typography
            alignItems="center"
            alignSelf="end"
            variant="body2"
            fontSize={14}
            color={theme.palette.mode === "light" ? "#757575" : "#646892"}
            style={{ fontWeight: 400 }}
          >
            {wallet?.loading ? (
              <FormattedMessage id="Updating..." defaultMessage="Updating..." />
            ) : (
              <FormattedMessage id="Deposit" defaultMessage="Deposit" />
            )}{" "}
            :{" "}
            {formatCurrency(
              fromWei(selectedTokenDeposits, selectedToken?.decimals)
            )}{" "}
          </Typography>

          {isSufficientDeposits && (
            <button
              style={{
                padding: 0,
                color: `#6A55EA`,
                border: "none",
                borderRadius: 14,
                marginRight: 0,
                backgroundColor: "transparent",
                marginLeft: 8,
                fontSize: 14,
              }}
              onClick={() =>
                handleMaxToken(
                  fromWei(selectedTokenDeposits, selectedToken?.decimals)
                )
              }
              disabled={
                inputTokens ===
                formatCurrency(
                  fromWei(selectedTokenDeposits, selectedToken?.decimals)
                )
              }
            >
              <FormattedMessage id="Max" defaultMessage="Max" />
            </button>
          )}

          {inputTokens && !isSufficientDeposits && (
            <Button
              onClick={handleDeposit}
              style={{
                marginLeft: 8,
                borderRadius: 10,
                background: "#6A55EA",
                padding: sm ? "3px 10px 3px 10px" : "3px 18px 3px 18px",
                color: "white",
                width: sm ? "fit-content" : "fit-content",
                fontSize: 12,
              }}
            >
              <FormattedMessage id="Deposit" defaultMessage="Deposit" />
            </Button>
          )}
        </Box>

        {isSufficientDeposits && (
          <Box ml={1.5}>
            <CheckCircle fontSize="small" color="success" />{" "}
          </Box>
        )}
      </Box>
      {!isSufficientDeposits && inputTokens && (
        <Box className="d-flex  ">
          <Typography color="error" fontSize={14} style={{ fontWeight: 400 }}>
            <FormattedMessage id="Deposit" defaultMessage="Deposit" />{" "}
            {` ${formatCurrency(
              fromWei(depositsNeeded, selectedToken?.decimals)
            )}  ${selectedToken?.symbol} `}
            <FormattedMessage
              id="to fulfill this sell order"
              defaultMessage="to fulfill this sell order"
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
}
