const TURKISH = {
  Mode: "Moda",
  Feedback: "Geri bildirim",
  Home: "Ev",
  "Create Order": "Sipariş Oluştur",
  "My Orders": "Siparişlerim",
  Wallet: "Cüzdan",
  Profile: "Profil",
  Leaderboard: "Liderler Sıralaması",
  Faucet: "Musluk",
  Banned: "Yaslanmış",
  "This account has been banned. If this is unexpected, please contact":
    "Bu hesap yasaklandı. Bu beklenmedik ise lütfen iletişime geçin",
  footermessage:
    "Dünyayı daha iyi bir yer haline getirmek için birlikte çalışan 100k+ kişiye katılın.",
  "Copyright 2023 By": "Telif Hakkı 2023",
  "All Rights Reserved": "Her hakkı saklıdır",
  "How P2P Works": "P2P Nasıl Çalışır?",
  home_howitwork:
    "Polkabridge P2P, Polkabridge'in işlemin güvenliği için bir emanet görevi gördüğü paranızı anında kriptoya dönüştürmenize yardımcı olur.",
  Buy: "Satın almak",
  Sell: "Satmak",
  "STEP 1": "AŞAMA 1",
  buy_step1:
    "İstenen fiyat ve ödeme yöntemi ile reklamı seçin. Siparişi tamamlamak için satın alma miktarınızı ve işlem tutarınızı girin.",
  "Deposit tokens into": "Jetonları içine yatırmak",
  wallet: "cüzdan",
  "and create orders": "ve sipariş oluştur",
  "STEP 2": "ADIM 2",
  buy_step2:
    "Ödemelerinizi satıcı tarafından sağlanan bilgilere göre tamamlayın.",
  step2_sell: "Alıcıdan tam ödemelerin tamamını aldığınızı onaylayın.",
  "STEP 3": "AŞAMA 3",
  step31_buy:
    "Satıcı ödemeyi aldıktan sonra madeni paraları serbest bırakacaktır. Git",
  step32_buy: "aldığınız paraları gözden geçirmek için.",
  step3_sell:
    "Para madeni paraları ancak ödemelerini tam olarak aldıktan sonra serbest bırakın.",
  "Before you start trading you need to update your profile.":
    "Ticaret yapmaya başlamadan önce profilinizi güncellemeniz gerekir.",
  "Before your start trading please verify your phone":
    "Başlangıç ​​işleminizden önce lütfen telefonunuzu doğrulayın",
  "Please add a payment method to start buy crypto":
    "Lütfen kripto satın almaya başlamak için bir ödeme yöntemi ekleyin",
  "Update now": "Şimdi güncelle",
  "Verify now": "Şimdi doğrulayın",
  "Add now": "Şimdi ekle",
  Logout: "Çıkış Yap",
  "Switching...": "Anahtarlama ...",
  Switch: "Anahtar",
  "Switch Network": "Anahtar Ağı",
  "Cancel dispute": "Anlaşmazlığı iptal et",
  canceldisputewarn:
    "Anlaşmazlığı tekrar yükseltmek için bir seçenek olmayacak. Sorununuz çözülmediyse lütfen İPTAL ETMEYİN.",
  "I confirm I have recieved the money from seller":
    "Satıcıdan parayı aldığımı onaylıyorum",
  "Cancelling...": "İptal...",
  "Cancel Trade": "Ticareti İptal Et",
  Warning: "Uyarı",
  warningcancel:
    "Siparişi iptal ettikten sonra geri ödeme yapılmayacaktır. Zaten para aktardıysanız lütfen iptal etmeyin.",
  tradewarning:
    "Biri günde 4 işlemi iptal ederse, ticaret gün boyunca askıya alınacaktır.",
  "I confirm I have not paid the payee.": "Alacaklı ödemediğimi onaylıyorum.",
  "Please wait...": "Lütfen bekleyin...",
  "Cancel this trade": "Bu ticareti iptal et",
  "Confirm Transfer": "Transfer onaylayın",
  confirmnote:
    "P2P işlemleri, alıcı taraf tarafından desteklenen bir yöntemle ödeme yapmanızı veya transfer etmenizi gerektirir. Polkabridge, hesabınızdan otomatik olarak düşmez.",
  "Transfer Amount": "Transfer miktarı",
  "In exchange for": "Karşılık olarak",
  "Upload proof of payment": "Ödeme Kanıtı Yükle",
  "Confirm the account of the receiving party":
    "Alıcı tarafın hesabını onaylayın",
  "Payment QR code": "Ödeme QR kodu",
  confirmnote2:
    "Alıcı tarafın transferinizi onaylayabilmesi için lütfen siparişlerinizi mümkün olan en kısa sürede tamamlayın. Lütfen alıcı tarafın hesabını seçin. Hileli ödeme onayı muhtemelen hesabınızın dondurulmasına neden olacaktır",
  "I didn't Pay Yet": "Henüz ödemedim",
  "Transfer Completed": "Transfer tamamlandı",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "Jetonları Polkabridge Vault ve Ticaret Zorlu Ücretsiz Depozito",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Tokenleri polkabridge kasasından geri çekin",
  Amount: "Miktar",
  "Select Max": "Max'i seçin",
  Balance: "Denge",
  "Available Amount": "Mevcut Tutar",
  "Withdrawal Fee": "para çekme ücreti",
  "In order": "Sırayla",
  View: "Görüş",
  "Send OTP": "OTP gönder",
  "Verify Phone": "Telefonu Doğrula",
  "Verify Email": "E-mail'i doğrula",
  "Your phone number has been verified": "Telefon numaranız doğrulandı",
  "Your email address has been verified": "E-posta adresiniz doğrulandı",
  "6 digit otp has been sent on your phone":
    "Telefonunuza 6 haneli OTP gönderildi",
  emailsent:
    "E -postanızda 6 haneli doğrulama kodu gönderildi. Gelen kutunuzda e -postayı göremiyorsanız, spam klasörünüzü kontrol ettiğinizden emin olun",
  "Verify OTP": "OTP'yi doğrulayın",
  "OTP expired": "OTP'nin süresi doldu",
  "Resend OTP": "OTP'yi yeniden yap",
  "Your OTP will expire in": "OTP'nizin süresi dolacak",
  "Confirm and Release": "Onay ve Serbest Bırak",
  Tips: "İpuçları",
  dontnotcheckbuyerpayment:
    "Sadece alıcının ödeme kanıtını kontrol etmez. Hesabınıza giriş yaptığınızdan ve ödemenin alındığını doğruladığınızdan emin olun.",
  ifstillprocessing:
    "Ödeme hala işliyorsa, kriptoyu yayınlamadan önce hesabınıza ödeme alana kadar bekleyin!",
  donotacceptthridparty:
    "Üçüncü taraf bir hesaptan ödeme kabul etmeyin. Kriptoyu piyasaya sürdükten sonra banka ibliyetinin neden olduğu finansal kayıplardan kaçınmak için bu tür bir ödeme alırsanız, tam tutarı hemen iade edin.",
  "Confirm User Account and Release Amount":
    "Kullanıcı hesabını ve sürüm tutarını onaylayın",
  iconfirmpaymentcorrect:
    "Ödemenin hesabıma yatırıldığını ve mevcut bakiyenin doğru olduğunu onaylıyorum.",
  "Releasing...": "Serbest bırakma ...",
  Order: "Emir",
  "is successfully completed": "başarıyla tamamlandı",
  Submitted: "Gönderilen",
  Submit: "Göndermek",
  "Click below for processing": "İşleme için aşağıya tıklayın",
  "Waiting for confirmation": "onay bekliyor",
  "Please confirm the transaction into your metamask popup":
    "Lütfen işlemi Metamask açılır pencerenize onaylayın",
  "Withdrawal Request Submitted": "Gönderilen para çekme talebi",
  "Withdrawal Address": "Para çekme adresi",
  Back: "Geri",
  "Withdrawal History": "Para çekme geçmişi",
  "Deposit Processing": "Depozito işleme",
  depositapproval:
    "Depozito onayı gönderildi, lütfen sabırlı olun. Para yatırma işlemi devam ederken daha fazla mevduat başlatmayın.",
  "Deposits History": "Mevduat Tarihi",
  "Transaction Submitted": "İşlem gönderildi",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "İşlem gönderildi ve Blockchain'den onay bekliyor.",
  "View Status": "Durumu göster",
  "Transaction Successful!": "İşlem başarılı!",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "Harika! İşlem Blockchain'de başarıyla onaylandı.",
  "Transaction Failed!": "İşlem başarısız!",
  "We have encountered an error in the transaction. Please try again.":
    "İşlemde bir hata ile karşılaştık. Lütfen tekrar deneyin.",
  "Sign Message": "İşaret mesajı",
  "Connect Wallet": "Cüzdan Bağlayın",
  "Waiting to connect": "Bağlanmayı Bekliyor",
  "Confirm this connection in your wallet":
    "Cüzdanınızdaki bu bağlantıyı onaylayın",
  walletconnect_rule:
    "Bir cüzdanı bağlayarak, Polkabridge P2P'nin hizmet şartlarını ve gizlilik politikasına rıza göstermeyi kabul edersiniz.",
  "Please sign this message requested in your metamask":
    "Lütfen metamaskınızda istenen bu mesajı imzalayın",

  Metamask: "Metamas",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "Sonraki TestNet sürümü geliyor!",
  "Follow Us": "Bizi takip edin",
  "Claim Faucet": "Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Goerli TestNet veya BSC TestNet için test jetonlarını talep edin.",
  "Unauthorised Access!": "Yetkisiz Erişim!",
  "Please connect your wallet first to continue P2P trading.":
    "P2P ticaretine devam etmek için lütfen cüzdanınızı önce bağlayın.",
  Price: "Fiyat",
  Limit: "Sınırlamak",
  Following: "Takip etme",
  Unfollow: "Takip etmek",
  Follow: "Takip etmek",
  Rating: "Değerlendirme",
  "Online Ads": "Çevrimiçi Reklamlar",
  "All Feedback": "Tüm geri bildirimler",
  "Volume in": "Hacim",
  "Decentralized P2P Exchange, powered by ": "Merkezi olmayan P2P değişimi, ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Polkabridge ile ilk merkezi olmayan P2P ticaretini deneyimleyin",
  Fiat: "Fiat",
  All: "Tüm",
  Token: "Jeton",
  "Payment Mode": "Ödeme şekli",
  orders: "emirler",
  completion: "tamamlama",
  BUY: "SATIN ALMAK",
  SELL: "SATMAK",
  Verify: "Doğrulamak",
  "Crypto Amount": "Kripto miktarı",
  "Fiat Amount": "Fiat Miktarı",
  "Market Open Orders": "Piyasa Açık Siparişleri",
  Advertisers: "Reklamverenler",
  "Available | Limit": "Mevcut | Sınırlamak",
  Payment: "Ödeme",
  Trade: "Ticaret",
  "Exclusive ranking for Polkabridge Traders":
    "Polkabridge tüccarları için özel sıralama",
  Volume: "Hacim",
  Followers: "Takipçiler",
  "Avg. Pay Time": "AVG. Ödeme süresi",
  "Avg. Release Time": "AVG. Serbest bırakma süresi",
  Username: "Kullanıcı adı",
  Filter: "Filtre",
  Clear: "Temizlemek",
  User: "Kullanıcı",
  "Last Trade Time": "Son Ticaret Zamanı",
  Action: "Aksiyon",
  "Unauthorised Access": "Yetkisiz Erişim",
  Apply: "Uygula",
  "Order No.": "Sipariş No.",
  Coin: "Madeni para",
  Type: "Tip",
  Status: "Durum",
  Time: "Zaman",
  Operation: "Operasyon",
  "Crypto amount": "Kripto miktarı",
  "Fiat amount": "Fiat Miktarı",
  "Order type": "Sipariş türü",
  "Order Details": "sipariş detayları",
  "Order Number": "Sipariş numarası",
  "Time created": "Zaman yaratıldı",
  "Buy Order": "Sipariş Satın Alın",
  Executed: "Uygulanmış",
  at: "-den",
  "Total Fiat Amount": "Toplam Fiat Miktarı",
  "Crypto Unit Price": "Kripto birim fiyatı",
  "Min Order Limit": "Min sipariş sınırı",
  "Max Order Limit": "Maksimum Sipariş Sınırı",
  "Trade between": "Aralarında ticaret yapmak",
  "Payment Time Limit": "Ödeme Süresi Sınırı",
  Remark: "Söz",
  "Need Help": "Yardıma ihtiyacım var",
  "Please wait": "Lütfen bekleyin",
  "Cancel Order": "Siparişi iptal et",
  "Go To Home": "Eve gitmek",
  "Initiate New P2P Order": "Yeni P2P siparişi başlatın",
  "Set type and price": "Tip ve Fiyat Set",
  "Review Your Order": "Siparişini gözden geçir",
  "I want to": "istiyorum",
  "Order Limit": "Sipariş sınırı",
  Min: "Min",
  Max: "Maksimum",
  "Payment Methods": "Ödeme metodları",
  "Add payments": "Ödeme ekle",
  "Current Price": "Mevcut fiyat",
  "Highest Price": "En yüksek fiyat",
  "Lowest Price": "En düşük fiyat",
  "Please note that if you decide to": "Lütfen karar verirseniz",
  SUBMIT: "GÖNDERMEK",
  "the order, you wouldn't be able to": "sipariş, yapamazsın",
  EDIT: "DÜZENLEMEK",
  "it afterwards.": "daha sonra.",
  "Review New P2P Order": "Yeni P2P siparişini gözden geçirin",
  "Review your order": "Siparişini gözden geçir",
  Remarks: "Notlar",
  Cancel: "İptal etmek",
  "Submit Order": "Siparişi Gönder",
  "Order Submitted Successfully": "Başarılı bir şekilde gönderilen sipariş",
  "Order Type": "Sipariş türü",
  Total: "Toplam",
  "Check your order": "Siparişinizi Kontrol Edin",
  Deposit: "Depozito",
  "to fulfill this sell order": "Bu satış siparişini yerine getirmek için",
  with: "ile",
  "Time remaining": "Kalan süre",
  "Confirm Order Info": "Sipariş bilgilerini onaylayın",
  "Quantity (After fee)": "Miktar (ücretten sonra)",
  "Chat with": "İle sohbet et",
  Uploading: "Yükleme",
  "Upload Proof of Payment": "Ödeme Kanıtı Yükle",
  Browse: "Araştır",
  "Trade cancelled": "Ticaret iptal edildi",
  "Transfer Payment to seller": "Ödemeyi satıcıya aktarın",
  "Seller released tokens": "Satıcı Jetonlar yayınladı",
  "Pending seller confirmation": "Bekleyen Satıcı Onayı",
  Completed: "Tamamlanmış",
  "Token Deposited": "Token yatırıldı",
  "Deposit tokens to": "Tokenleri yatırın",
  reserve: "rezerve etmek",
  "Buyer confirmed": "Alıcı onayladı",
  "Pending buyer confirmation": "Bekleyen alıcı onay",
  "Order Summary": "sipariş özeti",
  Available: "Mevcut",
  "payment method": "ödeme yöntemi",
  "Seller's Message:": "Satıcının Mesajı:",
  "Buyer's Message:": "Alıcının Mesajı:",
  "No message": "Mesaj yok",
  "Terms and conditions": "Şartlar ve koşullar",
  ordersummary_rule:
    "Üçüncü taraf hesapları aracılığıyla ödeme göndermeyin, bu tür ödemelerin tüm anlaşmazlığa gitmesi gerekecek ve iade edilecek/iptal edilecektir. Lütfen P2P, Polkabridge, USDT, ETH, BTC vb. Gibi kripto ile ilgili kelimeleri dahil etmeyin.",
  "I want to buy for": "Satın almak istiyorum",
  "I want to sell for": "Satmak istiyorum",
  "I will get:": "Alacağım:",
  "I will send:": "Göndereceğim:",
  Loading: "Yükleniyor",
  "Order Processing": "sipariş düzenleniyor",
  "Reference message": "Referans mesajı",
  "QR Code": "QR kod",
  "Payment confirmed": "Ödeme Onaylandı",
  "I've Paid. Confirm": "Ödedim. Onaylamak",
  Cancelled: "İptal edildi",
  Name: "İsim",
  Email: "E -posta",
  Quantity: "Miktar",
  Your: "Senin",
  "has been reserved in PolkaBridge vault": "Polkabridge kasasında ayrıldı",
  "Token reserved": "Jeton saklıdır",
  "Waiting for buyer's confirmation": "Alıcının onayını bekliyorum",
  "Buyer confirmed payment": "Alıcı Ödemeyi Onayladı",
  "Buyer notified": "Alıcı Bildirildi",
  "Notify buyer": "Alıcıyı bilgilendirin",
  "Cancel trade": "Ticareti İptal Et",
  "Dispute submitted": "Söz konusu anlaşmazlık",
  Reason: "Sebep",
  "Release tokens to buyer wallet": "Jetonları alıcı cüzdanına serbest bırakın",
  "Token released": "Token çıktı",
  "Release tokens": "Jetonlar Serbest Bırak",
  "Your order is executed successfully.": "Siparişiniz başarıyla yürütülür.",
  "My Profile": "Benim profilim",
  "Update your preference for smooth trading experience.":
    "Pürüzsüz ticaret deneyimi için tercihinizi güncelleyin.",
  Menu: "Menü",
  Payments: "Ödemeler",
  "Transaction history": "İşlem geçmişi",
  Settings: "Ayarlar",
  Referral: "Referans",
  "Filter Order": "Filtre siparişi",
  TxnId: "Txnid",
  "Transaction not found": "İşlem bulunamadı",
  "Wallet unavailable": "Cüzdan mevcut değil",
  "Check explorer": "Kaşif kontrol edin",
  "Full Name": "Ad Soyad",
  Mobile: "Hareketli",
  "Preferred Fiat": "Tercih edilen fiat",
  "Edit profile": "Profili Düzenle",
  "Upload Avatar": "Avatar yükle",
  "Updating...": "Güncelleme ...",
  "Update profile": "Profili Güncelle",
  "Are you sure, want to leave PolkaBridge?":
    "Emin misin, Polkabridge'den ayrılmak ister misin?",
  "Log out": "Çıkış Yap",
  "Fiat Preference": "Fiat tercihi",
  Referrals: "Yönlendirmeler",
  "Will be available soon.": "Yakında satışa sunulacak.",
  "Payment Options": "Ödeme seçenekleri",
  "Transaction History": "işlem geçmişi",
  Fee: "Ücret",
  TxnID: "Txnid",
  "All payment methods were added": "Tüm ödeme yöntemleri eklendi",
  "Add Payment Method": "Ödeme yöntemi ekle",
  "Payment method": "Ödeme yöntemi",
  "QR Code (optional)": "QR kodu (isteğe bağlı)",
  "Upload QR Code": "QR Kodunu Yükle",
  "Update details": "Güncelleme Ayrıntıları",
  "Not Uploaded": "Yüklenmedi",
  Edit: "Düzenlemek",
  "Update Payment": "Ödemeyi Güncelle",
  Confirm: "Onaylamak",
  "Depositing...": "Yatırma ...",
  "Confirming...": "Onaylama ...",
  Withdraw: "Geri çekilmek",
  Locked: "Kilitli",
  "P2P Wallet": "P2P Cüzdan",
  walletpage_message:
    "P2P platformunuzdaki varlıkları yönetin. P2P'deki işlemlere katılmak için varlıkları yatırabilir, geri çekebilirsiniz",
  "Your Balances": "Dengeleriniz",
  "Buy Amount": "Miktar Satın Alın",
  "Sell Amount": "Satış Tutarı",
  "Trade(s)": "Ticaret(ler)",
  active: "aktif",
  "Executed at": "Yürütüldü",
  Chain: "Zincir",
  "My Ads": "Reklamlarım",
  Active: "Aktif",
  "Amount reversed by my Bank.": "Bankam tarafından iptal edilen tutar.",
  "Transaction failed on my behalf": "Benim adıma işlem başarısız oldu",
  "Seller received the amount successfully.": "Satıcı, tutarı başarıyla aldı.",
  "Seller provided the necessary information to complete the payment.":
    "Satıcı, ödemeyi tamamlamak için gerekli bilgileri sağladı.",
  "I sent the remaining amount.": "Kalan tutarı gönderdim.",
  "I raised the dispute by mistake.":
    "Anlaşmazlığı yanlışlıkla gündeme getirdim.",
  "I received the tokens.": "Jetonları aldım.",
  Others: "Diğerleri",
  Quest: "görev",
  "Complete your quests and collect points.":
    "Görevlerinizi tamamlayın ve puan toplayın.",
  "Total Points": "Toplam puanlar",
  Redeem: "Tazmin etmek",
  "Resets in": "Sıfırlar",
  "Daily Quests": "Günlük Görevler",
  "Weekly Quests": "Haftalık Görevler",
  Rules: "Tüzük",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1. Günlük Görev: Görev her gün 00:00 UTC'de yenilenecektir.",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2. Görev noktalarınızı almak için 'Talep'i tıklamanız gerekir. Noktalar hesabınıza yatırılmayacaksa.",
  questrule3:
    "3. 1. Görev turu için toplam ödül 60,000 PBR olacaktır. Puanlar, talep edilen puan sayısına bağlı olarak karşılık gelen PBR'ye dönüştürülecektir.",
  "4. Redeem points limit is 100 Points.": "4. Kullanım puanları 100 puandır.",
  questrule5:
    "5. Görev turu 1 sona erdikten sonra, kullanıcı ödülünü alacaktır. Ödüller kullanıcılara 7 iş günü içinde gönderilecektir.",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge, etkinlik için nihai yorumlama hakkını saklı tutar. Bir kullanıcının herhangi bir kötü niyetli davranışa dahil olması veya güvenlik açıklarından yararlanması durumunda, Polkabridge kullanıcının hesabını ve varlıklarını elden çıkarma hakkına sahiptir.",
  Points: "Puan",
  Claiming: "İddia eden",
  Claimed: "İddia edilen",
  Claim: "İddia",
  Action: "Aksiyon",
  "Redeem Points": "Puanları kullan",
  "1 Points = 1 PBR": "1 puan = 1 PBR",
  "Redeem Points limit is 100 Points": "Puan limiti 100 puandır",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "REDEM isteğinizi gönderin Yönetici, cüzdanınıza jetonu inceleyecek ve aktaracaktır.",
  Tokens: "Jeton",
  "Wallet Address": "Cüzdan adresi",
  "Confirm your wallet address and submit your request.":
    "Cüzdan adresinizi onaylayın ve isteğinizi gönderin.",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "Arka arkaya 7 gün içinde Ethereum zincirinde en az 20 sipariş tamamladı.",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "Arka arkaya 7 gün içinde BSC zincirinde en az 20 sipariş tamamladı.",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "Her iki zincirde arka arkaya 7 gün içinde yatırın ve çekin.",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "7 gün içinde tüm jetonlarla (PBR-ETH, PBR-BSC, USDT-ETH, USDT-BSC, USDC, DAI, ETH, BUSD, BNB) ile tam ticaret.",
  "Give at least 10 feedback .": "En az 10 geri bildirim verin.",
  "Create at least 1 Ad on your FIAT currency":
    "Fiat para biriminizde en az 1 reklam oluşturun",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Ethereum Zincirinde en az 1 SATIN AL siparişi ve 1 SATIŞ siparişi tamamlayın",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "BSC Zincirinde en az 1 SATIN AL siparişi ve 1 SATIŞ siparişi tamamlayınn",
  "Deposit or withdraw at least 1 token on Ethereum chain":
    "Ethereum zincirine en az 1 jeton yatırın veya çekin",
  "Deposit or withdraw at least 1 token on BSC chain":
    "BSC zincirine en az 1 jeton yatırın veya çekin",
  "Deposit at least 0.1 ETH or 0.1 BNB.": "En az 0.1 ETH veya 0.1 BNB yatırın.",

  "Waiting for seller to release tokens":
    "Satıcının jetonları serbest bırakması bekleniyor",
  "Transfer the funds to seller account provided below":
    "Parayı aşağıda verilen satıcı hesabına aktarın",
  "Seller released tokens": "Satıcı jetonları serbest bıraktı",
  "Waiting for confirmation": "onay bekliyor",
  "Dispute submitted": "İtiraz gönderildi",
  Completed: "Tamamlanmış",
  "Your order is executed successfully": "Siparişiniz başarıyla yürütüldü",
  Submit: "Göndermek",
  "Cancel Dispute": "Anlaşmazlığı İptal Et",
  Cancelled: "İptal edildi",
  "Reason:": "Sebep",
  "Dispute raised": "Anlaşmazlık gündeme geldi",
  "In reviewing": "incelemede",
  Resolved: "çözüldü",
  "Your Proofs": "Kanıtlarınız",

  "Seller notified": "satıcı bilgilendirildi",
  "Notify Seller": "Satıcıya Bildir",
  "Pending dispute": "Bekleyen anlaşmazlık",
  "Raise dispute": "Anlaşmazlığı artırın",
  "I paid too much": "çok fazla ödedim",
  "The seller payment account is wrong, can't pay":
    "Satıcı ödeme hesabı yanlış, ödeme yapamıyor",
  "I paid the wrong person": "yanlış kişiye ödeme yaptım",
  "I paid for the part of the money, can't continue to pay":
    "Paranın bir kısmını ödedim, ödemeye devam edemem",
  Pending: "Askıda olması",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "Sorunumun çözüldüğünü onaylıyorum ve bu anlaşmazlığı kapatmak istiyorum.",

  "Upload proof": "Kanıtı yükle",
  "Upload proof of payment": "Ödeme belgesini yükleyin",
  "I confirm there is no problem and I want to cancel this transaction":
    "Sorun olmadığını onaylıyorum ve bu işlemi iptal etmek istiyorumy",
  warningcancel_seller:
    "Bu işlemi iptal etmek istediğinizden emin misiniz? Alıcı zaten ödeme yaptıysa, PolkaBridge'in iptal sonrası anlaşmazlıkları ele almayacağını lütfen unutmayın.", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?": "Merhaba, size nasıl yardımcı olabiliriz?",
  Support: "Destek",
  "Raise Ticket": "Biletini yükseltmek",
  "Watch Tutorial": "Eğitimi izleyin",
  "Video Tutorial": "Video öğretici",
  Open: "Açık",
  Official: "Resmi",
  "Email Us": "Bize e -posta gönderin",
  Issues: "Sorunlar",
  Description: "Tanım",
  "Upload Proof": "Kanıtı Yükle",
  "Drag and drop the file, or": "Dosyayı sürükleyip bırakın veya",
  "Enter the description": "Açıklamayı girin",
  General: "Genel",
  "Deposit/Withdraw": "Mevduat çekimi",
  Account: "Hesap",
  Trading: "Ticaret",
  Others: "Diğerleri",
  Telegram: "Telegram",
  "Show Online Users": "Çevrimiçi/Çevrimdışı",
  "Tokens Locked": "Jetonlar kilitli",
  "Payment Done": "Ödeme Yapıldı",
  "In Dispute": "Anlaşmazlık",
  Date: "Tarih",
  Ascending: "Yükselen",
  Descending: "Yükselen",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "Biletiniz gönderildi ve ekibimiz tarafından incelenecek. Sorun 24 saat içinde çözülecek. Sabrınız için teşekkürler.",
  "Ticket History": "Bilet geçmişi",
  Date: "Tarih",
  "Ticket ID": "Bilet kimliği",
  Issue: "Sorun",
  Reviewing: "İnceleme",
  Resolved: "Kararlı",
  Pending: "Askıda olması",
  Authenticating: "Kimlik doğrulama",
  "Admin Remark": "Yönetici Notu",
  Message: "İleti",
  "Price Ascending": "Fiyat Artan",
  "Price Descending": "Fiyat Azalan",
  "Latest Ads": "En son reklamlar",
  "Latest Orders": "Son Siparişler",
  "Completed Trades": "Tamamlanan işlemler",
  "User Guide": "Kullanici rehberi",
  "Beginner's Guide": "Yeni başlayanların rehberi",
  "Video Tutorial": "Video öğretici",
  "P2P trading FAQ": "P2P Ticaret SSS",
  "Admin Message": "Yönetici Mesajı",
  "Ticket History": "Bilet Geçmişi",
  "Review Submitted Successfully": "Başarılı bir şekilde gönderilen inceleme",
  "Transaction Hash": "İşlem karma",
  "Ticket Details": "Bilet Ayrıntıları",
  Ended: "Bitti",
  "Round 1 Ended": "1. Tur Bitti",
  "Redeem your points before Round 2 starts":
    "2. Tur başlamadan önce puanlarınızı kullanın",
  "Redeem History": "Tarihi Koru",
  "Redeem Time": "amanı Kullan",
  Address: "Adres",
  pending: "askıda olması",
  completed: "tamamlanmış",
  rejected: "Reddedilmiş",
  "Redeemed At": "Kurtuldu",
  "Redeem Detail": "Detayı kullanın",
  "Transaction ID": "İşlem Kimliği",
  "Admin Remark": "Yönetici Açıklama",
  rejected: "Reddedilmiş",
  "Profile Tab": "Profil sekmesi",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "Profilinizi ad, kullanıcı kimliği, mobil no, e -posta ve en önemlisi de öngörülen ödeme seçeneklerinizi eklemek için tıklayın.",
  "Quest Tab": "Görev sekmesi",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "Devam eden görev kampanyasına katılmak ve $ PBR ödülleri almak için puan talep etmek için tıklayın.",
  "LeaderBoard Tab": "Lider Tablosu sekmesi",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "Polkabridge tüccarları için özel sıralamayı görmek için tıklayın.",
  "Buy Vs Sell": "VS SATIN ALIN",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "Satın alma seçeneğini seçerek, öngörülen fiat para birimlerinizle kripto satın alabilirsiniz.",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "Satış seçeneğini seçerek, öngörülen fiat para birimlerinizle kripto satabilirsiniz.",
  "Filter Tool": "Filtre aracı",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "Reklamları tercihlerinize göre sıralamak için tıklayın veya size görüntülenen reklamları yapılandırmak için filtreyi kullanın. Filtre seçenekleri, bölgenizin/para biriminizin mevcudiyetine dayanır.",
  "Advertiser Info": "Reklamveren Bilgisi",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "Burada satıcının profilini, çevrimiçi durumunu, tamamlama oranını, tamamlanan siparişlerin toplam sayısını ve satıcı rozetini görüyorsunuz. Daha fazla bilgi görüntülemek için satıcının adını tıklayın.",
  "Price and Limits": "Fiyat ve Sınırlar",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "Burada, kullanıcının ticaret yapmak istediği 1 madeni paranın fiyatını, kullanılabilirlik miktarı ve kullanıcının ticaret yapmak istediği en düşük ve en yüksek miktarı görebilirsiniz.",
  "Payment options": "Ödeme seçenekleri",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "Burada, reklamverenin jetonlar/fiat için ticaret yapmayı kabul ettiği ödeme modunu görebilirsiniz.",
  "Lowest Fees": "En düşük ücretler",
  "Buy crypto with lowest fees ever!":
    "Şimdiye kadarki en düşük ücretlerle kripto satın alın!",
  "P2P Wallet": "P2P Cüzdan",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "Bakiyenizi kontrol etmek ve varlıklarınızın ve geçmişlerinin mevduat ve para çekme işlemlerini yönetmek için tıklayın.",
  Next: "Sonraki",
  Skip: "Atlamak",
  Close: "Kapalı",
  "Create Order Tab": "Sipariş Sekmesi Oluştur",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "Tercih ettiğiniz para/jeton/ödeme modlarını kullanarak satın alma/satma siparişi oluşturmak için lütfen düğmeye tıklayın.",
  "Switch Network": "Anahtar Ağı",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "P2P platformumuz MultiChain'i desteklemek için tasarlanmıştır, yani seçtiğiniz herhangi bir zincir üzerinde işlem yapma esnekliğine sahipsiniz.",
  "24h Trading Volume": "24 saat ticaret hacmi",
  "24h Total Orders": "24 saat toplam sipariş",
  "Show Users Following": "Kullanıcıları Takip Et",
  Following: "Takip etme",
  Online: "Çevrimiçi",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "Lütfen 24 Mart, 00:00 UTC'den önce puanlarınızı kullanın. Dönüştürülecek minimum puan sayısı 100 puandır",
  "User's Statistics": "Kullanıcının İstatistikleri",
  Phone: "Telefon",
  Follower: "Takipçi",
  "All Trades": "Tüm esnaf",
  "Total Buy Orders": "Toplam Satın Alma Siparişleri",
  "Total Sell Orders": "Toplam Satış Siparişleri",
  Share: "Paylaşmak",
  "Reopen Ticket": "REACEN BİLET",
  Comment: "Yorum",
  "Add your comment": "yorumunu ekle",
  "Comment must be less than 5000 characters":
    "Yorum 5000 karakterden az olmalı",
  "Comment is required": "Yorum gerekli",
  "Reopen Date": "Tarihi Yeniden Aç",
  Proof: "Kanıt",
  "Share your P2P profile": "P2P profilinizi paylaşın",
  Sort: "Düzenlemek",
  "Select Token": "Token'i seçin",
  "Select Fiat": "Fiat'ı seçin",
  "Date Range": "Tarih aralığı",
  "Enter username": "Kullanıcı adı girin",
  "Trade Currency": "Ticari para birimi",
  "Enter your message for seller": "Satıcı için mesajınızı girin",
  "Deposit Token": "Depozit jetonu",
  "Withdraw Token": "Token'i Çektir",
  "Insufficient!": "Yetersiz!",
  Wait: "Beklemek",
  "Insufficient funds!": "Yetersiz bakiye!",
  "Insufficient withdraw!": "Yetersiz geri çekilme!",
  Approve: "Onaylamak",
  "Approving...": "Onaylama ...",
  "Issues with Deposit?": "Depozito ile ilgili sorunlar?",
  "All Networks": "Tüm ağlar",
  "Enter amount": "Miktarı girin",
  "Enter price": "Fiyatı girin",
  "Select payment methods": "Ödeme yöntemlerini seçin",
  "Enter order min and max limits": "Sipariş min ve maksimum sınırları girin",
  "Enter valid min limit": "Geçerli min sınırını girin",
  "Max limit must be less than": "Maksimum sınırdan daha az olmalıdır",
  "Min limit must be less than": "Min sınırı daha az olmalıdır",
  "Preview order": "Önizleme Siparişi",
  min: "min",
  Global: "Küresel",
  "Enter your name": "Adınızı giriniz",
  "Choose your username": "Kullanıcı adınızı seçin",
  "Enter your email": "E-postanızı giriniz",
  "Your Full Name": "Tam adınız",
  "A/c Number": "Klima numarası",
  "Account number": "Hesap numarası",
  "Bank Name": "Banka adı",
  "IFSC Code": "IFSC Kodu",
  "Note (Optional)": "Not (isteğe bağlı)",
  "Additional Notes": "ek Notlar",
  "Phone Number": "Telefon numarası",
  "Account phone number": "Hesap Telefon Numarası",
  "Payment QR Code (Optional)": "Ödeme QR kodu (isteğe bağlı)",
  "Please enter your QR Code": "Lütfen QR kodunuzu girin",
  Day: "Gün",
  Week: "Hafta",
  Month: "Ay",
  Months: "Aylar",
  hash: "doğramak",
  "Enter txn hash": "TXN karma girin",
  Success: "Başarı",
  Failed: "Arızalı",
  Review: "Gözden geçirmek",
  Dropped: "Düşmüş",
  LongPending: "Uzun süren",
  "Redeem Ended": "KURDURUM BAŞLADI",
  "Please enter your bank account number":
    "Lütfen banka hesap numaranızı girin",
  "Bank Name": "Banka adı",
  "Enter the name of your bank": "Bankanızın adını girin",
  "Account opening branch(Optional)": "Hesap Açma Şubesi (İsteğe Bağlı)",
  "Enter bank branch information": "Banka Şube Bilgilerini Girin",
  "Paypal ID / Number": "PayPal Kimliği / Numarası",
  "Perfect Money ID / Number": "Mükemmel para kimliği / numarası",
  "Bank Details (Optional)": "Banka Detayları (İsteğe Bağlı)",
  "Please enter your bank details": "Lütfen banka detaylarınızı girin",
  "Country of Residence (Optional)": "İkamet Ülkesi (isteğe bağlı)",
  "Please enter your country of residence": "Lütfen ikamet etmenize girin",
  "Username (Optional)": "Kullanıcı adı (isteğe bağlı)",
  "Account Username": "Hesap kullanıcı adı",
  "Account Email": "Hesap E-posta",
  "Wallet ID": "Cüzdan kimliği",
  "Revtag (Optional)": "REVTAG (isteğe bağlı)",
  "Please enter your Revtag": "Lütfen REVTAG'ınızı girin",
  "Phone number (Optional)": "Telefon numarası (isteğe bağlı)",
  "Please enter payment details": "Lütfen ödeme ayrıntılarını girin",
  "Account number (USD)": "Hesap Numarası (USD)",
  "Account number (KHR)(Optional)": "Hesap numarası (KHR) (isteğe bağlı)",
  "Payeer account number": "Payeer Hesap Numarası",
  "Enter your Payeer account number": "Payer Hesap Numaranızı Girin",
  "Email (Optional)": "E-posta isteğe bağlı)",
  "Account Email (Not required)": "Hesap e -postası (gerekli değil)",
  "Email or Username": "E-posta ya da kullanıcı adı",
  "Account Email or Username": "Hesap e -postası veya kullanıcı adı",
  "Full Name of Receiver": "Alıcının tam adı",
  "Receivers's Full Name": "Alıcıların tam adı",
  "Payment details (Optional)": "Ödeme Ayrıntıları (İsteğe bağlı)",
  "ID Number": "Kimlik Numarası",
  "Please enter your National ID Number":
    "Lütfen Ulusal Kimlik Numaranızı Girin",
  "Account Type": "hesap tipi",
  "Enter your account type": "Hesap Türünüzü Girin",
  "Name and Lastname": "İsim ve soyisim",
  "Enter Name and Lastname": "Ad ve LastName girin",
  "Full Name": "Ad Soyad",
  "Enter Full Name": "Tam adı girin",
  Cashtag: "Kasa",
  "Please enter your Cashtag": "Lütfen CashTag'ınızı girin",
  "Full Name (Optional)": "Tam Ad (İsteğe bağlı)",
  "ID Number (Optional)": "Kimlik numarası (isteğe bağlı)",
  "Address (Optional)": "Adres (isteğe bağlı)",
  "Residential Address": "Konut adresi",
  "Mobile Number": "Cep numarası",
  "Enter your Mobile Number available for all networks":
    "Tüm ağlar için mevcut cep telefonu numaranızı girin",
  "Contact Number": "İletişim numarası",
  "Phone Number": "Telefon numarası",
  Operator: "Şebeke",
  "Mobile / Landline / Data / TV": "Mobil / Sabit Hat / Veri / TV",
  "Enter account opening branch (Not required)":
    "Hesap Açma Şubesi'ni girin (gerekli değil)",
  "IBAN Number": "IBAN numarası",
  "BIC/SWIFT Code(Optional)": "BIC/SWIFT KODU (isteğe bağlı)",
  "BIC/SWIFT Code": "BIC/SWIFT KODU",
  "Enter international bank account number":
    "Uluslararası Banka Hesap Numarasına Girin",
  "SWIFT BIC (Optional)": "Swift BIC (isteğe bağlı)",
  "Enter your bank identification code": "Banka Kimlik Kodunuzu Girin",
  "GB IBAN account number": "GB Iban Hesap Numarası",
  "IBAN (Optional)": "Iban (isteğe bağlı)",
  "Other payment details (for international transfer) (Optional)":
    "Diğer Ödeme Ayrıntıları (Uluslararası Transfer İçin) (İsteğe Bağlı)",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "Kendi adresiniz, bankanın adresi, Iban kodu ve Swiftbic kod vb. Gibi ayrıntılar.",
  "Bank Branch (Optional)": "Banka Şubesi (İsteğe bağlı)",
  "Recommended Transfer Remarks (Optional)":
    "Önerilen Aktarım Açıklamaları (İsteğe Bağlı)",
  "Recommended Transfer Remarks": "Önerilen transfer sözleri",
  'Please enter your MoMo phone number"':
    'Lütfen Momo telefon numaranızı girin "',
  "Please enter your MoMo QR Code": "Lütfen Momo QR kodunuzu girin",
  "Please enter your Zalopay QR Code": "Lütfen Zalopay QR kodunuzu girin",
  "Additional account number (Optional)": "Ek hesap numarası (isteğe bağlı)",
  "Please enter additional account number": "Lütfen ek hesap numarasını girin",
  "Branch code (Optional)": "Şube kodu (isteğe bağlı)",
  "Enter branch code (not required)": "Şube kodunu girin (gerekli değil)",
  "IBAN (Optional)": "Iban (isteğe bağlı)",
  "Please enter the IBAN code of your Bank (not required)":
    "Lütfen bankanızın Iban kodunu girin (gerekli değildir)",
  "IBAN or account number": "Iban veya hesap numarası",
  "Please enter your IBAN or account number":
    "Lütfen Iban veya Hesap Numaranızı girin",
  "Papara account number": "Papara Hesap Numarası",
  "Please enter your Papara account number":
    "Lütfen Papara Hesap Numaranızı Girin",
  City: "Şehir",
  "Please enter your city": "Lütfen şehrinize girin",
  "Zip Code": "Posta kodu",
  "Please enter your city zipcode": "Lütfen Şehir Zipcode'unuzu girin",
  Country: "Ülke",
  "Please enter Country": "Lütfen ülkeye girin",
  "Moneygram Account Number": "Moneygram Hesap Numarası",
  "Please enter your Moneygram account number":
    "Lütfen Moneygram Hesap Numaranızı Girin",
  "Enter bank branch information": "Banka Şube Bilgilerini Girin",
  "Please enter your BCA account number": "Lütfen BCA hesap numaranızı girin",
  "Please enter your Mandiri account number":
    "Lütfen Mandiri hesap numaranızı girin",
  "Chipper Cash full name": "Chipper Nakit Tam Adı",
  "Please enter your full name used to register on chipper cash":
    "Lütfen Chipper Cash'e kaydolmak için kullanılan tam adınızı girin",
  "Chipper Tag": "Kipçi Etiket",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "Lütfen @Chippertag'inizi girin. Chipper Cash üzerindeki profil sekmenizde bulabilirsiniz",
  "QIWI account": "Qiwi Hesabı",
  "Please enter your QIWI account": "Lütfen Qiwi hesabınızı girin",
  "Your email registered on Binance": "Binance'e kayıtlı e -postanız",
  "Please enter your email registered on Binance":
    "Lütfen Binance'a kayıtlı e -postanızı girin",
  "Payment Details": "Ödeme detayları",
  "WeChat account": "WeChat Hesabı",
  "Please enter your WeChat account": "Lütfen WeChat hesabınızı girin",
  "Receive QR Code (Optional)": "QR Kodu Alın (İsteğe Bağlı)",
  "Alipay account": "Alipay Hesabı",
  "Please enter your Alipay account": "Lütfen Alipay hesabınızı girin",
  "Payee's LINE ID": "Alacaklının çizgi kimliği",
  "Please fill in your LINE ID": "Lütfen çizgi kimliğinizi doldurun",
  "Please fill in your phone number registered with LINE":
    "Lütfen hatta kayıtlı telefon numaranızı doldurun",
  "Linepay QR Code (Optional)": "LinePay QR kodu (isteğe bağlı)",
  "Reference number or barcode": "Referans numarası veya barkod",
  "Please enter reference number or barcode":
    "Lütfen referans numarası veya barkod girin",
  "Remarks (Optional)": "Notlar (isteğe bağlı)",
  "Additional Remarks": "Ek açıklamalar",
  "Cell Phone Number": "Cep telefonu numarası",
  "Please enter your cell phone number": "Lütfen cep telefonu numaranızı girin",
  "Enter your ID number": "Kimlik Numaranızı Girin",
  "Fill in your phone number": "Telefon numaranızı doldurun",
  "Full Name of Receiver": "Alıcının tam adı",
  "Please enter your Full Name": "Lütfen tam adınızı giriniz",
  CBU: "CBU",
  "Please enter your CBU": "Lütfen CBU'nuzu girin",
  "CUIT / CUIL": "Cuit / cuil",
  "Enter your unique tax identification code":
    "Benzersiz vergi kimlik kodunuzu girin",
  "Please enter your CVU": "Lütfen CVU'nuzu girin",
  Alias: "Takma ad",
  "Please enter Alias": "Lütfen takma adı girin",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "Limon cüzdan etiketi",
  "Uniform Bank Key": "Tek tip banka anahtarı",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "DNI/CPF numaranızı girin",
  "CVU (Optional)": "CVU (isteğe bağlı)",
  "Uniform Virtual Key": "Tek tip sanal anahtar",
  "CBU/CVU or": "CBU/CVU veya",
  "alias CVU/CBU or CVU/CBU number": "Takma adı CVU/CBU veya CVU/CBU numarası",
  "Alias del CBU": "Alias ​​del cbu",
  "Vodafone cash Number": "Vodafone nakit numarası",
  "Please enter your vodafone cash Number":
    "Lütfen Vodafone nakit numaranızı girin",
  "Sort code": "Sıralama kodu",
  "Please fill in your 6-digit sort code":
    "Lütfen 6 haneli sıralama kodunuzu doldurun",
  "Sort Code(Optional)": "Sıralama kodu (isteğe bağlı)",
  "GB IBAN account number": "GB Iban Hesap Numarası",
  "IIN (Optional)": "İin (isteğe bağlı)",
  "Enter individual identification number": "Bireysel kimlik numarasını girin",
  "Please enter your bank card number": "Lütfen banka kartı numaranızı girin",
  "Mobile Carrier": "Mobil taşıyıcı",
  "Please enter your mobile carrier": "Lütfen mobil operatörünüzü girin",
  "Easypaisa mobile account": "EasyPaisa Mobil Hesabı",
  "Please enter your Easypaisa mobile account":
    "Lütfen EasyPAISA mobil hesabınızı girin",
  "NayaPay Id": "Nayapay Kimliği",
  "Please enter NayaPay Id": "Lütfen Nayapay Kimliği'ni girin",
  "Enter Brazilian valid document": "Brezilya geçerli belgesini girin",
  "Bank agency": "Banka ajansı",
  "Enter the name of your bank agency": "Banka Ajansınızın Adını Girin",
  "Type of transfer": "Transfer türü",
  "Please filled with TED/DOC/PIX": "Lütfen TED/DOC/PIX ile dolu",
  "Name and Last Name": "İsim ve soyisim",
  "Pix Key": "Pix anahtarı",
  "Phone, email or key": "Telefon, e -posta veya anahtar",
  'Type of Bank"': 'Banka türü "',
  "Name registered in PicPay": "Picpay'de kayıtlı isim",
  "PicPay username": "Picpay kullanıcı adı",
  "Username used to log in in PicPay":
    "Kullanıcı adı picpay'de giriş yapmak için kullanılır",
  "urpay Account Details": "urpay hesap detayları",
  "Please enter your urpay account details":
    "Lütfen URPAY Hesap Ayrıntılarınızı Girin",
  "bKash Wallet Number": "Bkash cüzdan numarası",
  "Please enter your 11 digit registered bKash account number":
    "Lütfen 11 haneli kayıtlı BKASH hesap numaranızı girin",
  "Nagad Wallet Number": "Nagad cüzdan numarası",
  "Please enter your 11 digit registered Nagad wallet number":
    "Lütfen 11 haneli kayıtlı Nagad cüzdan numaranızı girin",
  "Please enter your full name registered with Rocket account":
    "Lütfen Rocket Hesabına Kayıtlı Tam Adınızı Girin",
  "Rocket Wallet Number": "Roket Cüzdan Numarası",
  "Please enter your 12 digit Rocket Wallet number":
    "Lütfen 12 basamaklı roket cüzdan numaranızı girin",
  "Please enter your JKOPAY ID": "Lütfen jkopay kimliğinizi girin",
  "EasyPay wallet number (Optional)": "EasyPay cüzdan numarası (isteğe bağlı)",
  "Please fill in EasyPay wallet number":
    "Lütfen EasyPay cüzdan numarasını doldurun",
  "User API Key": "Kullanıcı API anahtarı",
  "User Secret Key": "Kullanıcı Gizli Anahtarı",
  "Bank account number": "banka hesabı numarası",
  PayID: "Payd",
  "Please enter your PayID": "Lütfen PayId'inizi girin",
  "Institution number": "Kurum numarası",
  "Please enter institution number": "Lütfen kurum numarasını girin",
  "PayCode (Optional)": "Paycode (isteğe bağlı)",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "Hesabımda bulunabilir -> maaş kodum -> bağlantıyı kopyalayın",
  "Please fill in your mobile phone number connected with your PayMe":
    "Lütfen PayMi'nizle bağlantılı cep telefonu numaranızı doldurun",
  Cellphone: "Cep telefonu",
  "Card Number or password": "Kart numarası veya şifre",
  "Please fill in card Number or password":
    "Lütfen kart numarasını veya şifreyi doldurun",
  "Purpose of payment (Optional)": "Ödeme amacı (isteğe bağlı)",
  "Enter a payment purpose for buyers": "Alıcılar için bir ödeme amacı girin",
  "Card number, account or key": "Kart numarası, hesap veya anahtar",
  "CLABE number (Optional)": "Clabe numarası (isteğe bağlı)",
  "CLABE number": "Clabe numarası",
  "Account number/card": "Hesap Numarası/Kart",
  "Reference number or card number": "Referans numarası veya kart numarası",
  "Please enter reference number or card number":
    "Lütfen referans numarasını veya kart numarasını girin",
  "Provide transfer details during the trade":
    "Ticaret sırasında transfer detayları sağlayın",
  "Variable symbol (Optional)": "Değişken sembol (isteğe bağlı)",
  "Enter Variable symbol": "Değişken sembolü girin",
  "Enter your PayNow account": "Paynow hesabınızı girin",
  "Account Name": "Hesap adı",
  "Email Address": "E -posta adresi",
  "Please enter email address": "Lütfen e -posta adresini girin",
  "Please enter Your Full Name": "Lütfen tam adınızı giriniz",
  "Account Number": "Hesap numarası",
  "Enter your phone number": "Telefon numaranızı girin",
  "Enter your account number": "Hesap Numaranızı Girin",
  "Enter your bank account number": "Banka hesap numaranızı girin",
  "Payment details": "ödeme detayları",
  "Bank name": "banka adı",
  "Please enter your MoMo phone number": "Lütfen Momo telefon numaranızı girin",
  "Please enter your email": "Lütfen E-postanızı girin",
  "Enter branch code": "Şube Kodunu Girin",
  "Please enter the IBAN code of your Bank":
    "Lütfen bankanızın Iban kodunu girin",
  "Account opening branch (Optional)": "Hesap Açma Şubesi (İsteğe bağlı)",
  "Enter account opening branch (not required)":
    "Hesap Açma Şubesi'ni girin (gerekli değil)",
  "Please enter your Bank account number":
    "Lütfen banka hesap numaranızı girin",
  "Please enter your ID Number": "Lütfen kimlik numaranızı girin",
  "Enter bank account type": "Banka hesabı türünü girin",
  "Bank Branch (Optional)": "Banka Şubesi (İsteğe bağlı)",
  "Account type": "Hesap tipi",
  "Please enter bank branch information":
    "Lütfen Banka Şubesi Bilgilerini Girin",
  "Please enter Email address": "Lütfen e -posta adresini girin",
  "Phone number(Optional)": "Telefon numarası (isteğe bağlı)",
  "Phone Number (Optional)": "Telefon numarası (isteğe bağlı)",
  "Additional information": "Ek Bilgiler",
  "Type of Bank": "Banka türü",
  "Remark (Optional)": "Açıklama (isteğe bağlı)",
  "Mobile Phone": "Cep telefonu",
  "Enter the bank code": "Banka Kodunu Girin",
  "Please enter your phone number": "Lütfen telefon numaranızı girin",
  "Please enter your Bank name": "Lütfen banka adınızı girin",
};

export default TURKISH;
