import { combineReducers } from "redux";
import orderReduers from "./orderReduers";
import profileReducers from "./profileReducers";
import userReducer from "./userReducer";
import multicall from "../state/multicall/reducer";
import tradeReducer from "./tradeReducer";
import walletReducer from "./walletReducer";
import historyReducer from "./historyReducer";
import adminReducer from "./adminReducer";
import adminAuthReducer from "./adminAuthReducer";
import adminDisputeReducer from "./admin/adminDisputeReducer";

export default combineReducers({
  user: userReducer,
  order: orderReduers,
  profile: profileReducers,
  multicall: multicall,
  userTrade: tradeReducer,
  wallet: walletReducer,
  history: historyReducer,
  admin: adminReducer,
  adminAuth: adminAuthReducer,
  adminDispute: adminDisputeReducer,
});
