import React from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useMediaQuery, useTheme, Box, Dialog } from "@mui/material";
import DisputeForm from "common/popups/Dispute/DisputeForm";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },
}));

const DisputePopup = ({
  popupActive,
  resetPopup,
  trade,
  isSeller,
  isMoreProof,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      onClose={resetPopup}
      aria-labelledby="simple-dialog-title"
      open={popupActive}
    >
      <Box
        border="10px solid #D1FF1A"
        px={!sm ? "2vw" : "5%"}
        py={!sm ? "5vh" : "10%"}
        className={classes.popup}
      >
        <Close
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => {
            resetPopup();
          }}
        />
        <div className={classes.background}>
          <div className={classes.container}>
            <DisputeForm
              isMoreProof={isMoreProof}
              trade={trade}
              handleClose={resetPopup}
              isSeller={isSeller}
            />
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default DisputePopup;
