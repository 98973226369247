import {
  Box,
  Button,
  CircularProgress,
  Container,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useParsedQueryString from "../../hooks/useParsedQueryString";

import WalletButton from "../../common/WalletButton";

import QuestsTable from "./questComponents/QuestsTable";
import QuestsCardList from "./questComponents/QuestsCardList";
import {
  checkQuests,
  claimQuest,
  getQuests,
  getUserQuest,
} from "utils/httpCalls/questCalls";
import AlertPopup from "../../common/AlertPopup";
import Rules from "./questComponents/Rules";
import RedeemPopup from "common/popups/RedeemPopup";
import PopupLayout from "common/popups/PopupLayout";
import useBlockNumber from "hooks/useBlockNumber";
import Countdown from "react-countdown";
import { FormattedMessage } from "react-intl";
import { Refresh } from "@mui/icons-material";
import QuestEnded from "./questComponents/questEnded/QuestEnded";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  infoCardError: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border: "1px solid #eeeeee",
    padding: 20,
    backgroundColor: "rgba(255,0,0,0.06)",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #eeeeee"
        : "1px solid #212446",
    padding: 30,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#ffffff"
        : theme.palette.background.highlight,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
  },
  subtitle: {
    color:
      theme.palette.mode === "light" ? "#414141" : theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 16,
  },
  label: {
    color: "#c4c4c4",
    fontWeight: 500,
  },
  submitButton: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
  },
  orderTabSelected: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #eeeeee"
        : "1px solid #25284B",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: theme.palette.background.primary,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1em",
  },
  tr: {
    width: "100%",
    maxHeight: 20,
  },
  trHighlight: {
    width: "100%",
    backgroundColor:
      theme.palette.mode === "light"
        ? "#f7f7f7"
        : theme.palette.background.highlight,
    height: 45,
  },
  userText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  filterCard: {
    marginTop: 10,
    marginBottom: 10,
    height: "100%",
    width: "80%",
    border: "1px solid #eeeeee",

    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  walletBtn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 18px 7px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 15,
    "&:hover": {
      background: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 15,
      width: 150,
    },
  },
}));

const Finished = () => <span style={{ color: "green" }}>Reseting...</span>;

// Renderer callback
const renderer = ({ total, days, hours, minutes, seconds }) => {
  if (total) {
    // Render a countdown
    return (
      <Typography
        alignSelf="center"
        textAlign="right"
        variant="body2"
        fontSize={13}
        style={{
          fontWeight: 500,
          // marginTop: md ? 20 : 0,
          paddingRight: 2,
          color: "#2EBD85",
          marginLeft: 1,
        }}
      >
        {days ? `${days} days` : `${hours}:${minutes}:${seconds}`}
      </Typography>
    );
  } else {
    // Render a finished state
    return <Finished />;
  }
};

function Quest() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState({});
  const [quests, setQuests] = useState([]);
  const [totalQuests, setTotalQuests] = useState(null);
  const [totalPoints, setTotalPoints] = useState(null);
  const [claiming, setClaiming] = useState(false);
  const [error, setError] = useState("");
  const [redeem, setRedeem] = useState(false);
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(null);

  const navigate = useNavigate();

  const authState = useSelector((state) => state?.user);
  const authenticated = useSelector((state) => state?.user?.authenticated);

  const parsedQuery = useParsedQueryString();

  const currentTab = useMemo(() => {
    const page = parsedQuery.page;
    if (page === "daily") {
      return 0;
    } else if (page === "weekly") {
      return 1;
    } else if (page === "lifetime") {
      return 2;
    } else {
      return 0;
    }
  }, [parsedQuery]);

  //calculate time to utc
  const timeToReset = useMemo(() => {
    const now = new Date();
    let daysLeft = 1;

    if (currentTab === 1 && now.getUTCDay() !== 0) {
      daysLeft = 8 - now.getUTCDay();
    }
    const UTCmidnight = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + daysLeft,
      0,
      0,
      0
    );

    return UTCmidnight;
  }, [currentTab, currentTimeIndex]);

  //check if round ended
  const roundEnded = useMemo(() => {
    const currentDate = new Date();
    const march20th2023 = new Date("2023-06-20"); //TODO: update this accordingly
    return currentDate.getTime() >= march20th2023.getTime();
  }, []);

  //uncomment if want to apply date for when redeem available
  // //check if round ended
  // const redeemAvailable = useMemo(() => {
  //   const currentDate = new Date();
  //   const march24th2023 = new Date("2023-05-24");

  //   return currentDate.getTime() <= march24th2023.getTime();
  // }, []);

  const blocknumber = useBlockNumber();

  //load quests
  useEffect(() => {
    if (!authState?.jwtToken || !authState?.account || !authState?.id) {
      // console.log("quest test : invalid param", authState);
      return;
    }

    async function fetchData() {
      setLoading(true);
      //fetch quests
      const allQuestsRes = await getQuests(
        {
          page_number: 1,
          quest_type:
            currentTab === 0
              ? "DAILY"
              : currentTab === 1
              ? "WEEKLY"
              : "LIFETIME",
        },
        authState?.jwtToken,
        authState?.account
      );
      const userQuestsRes = await getUserQuest(
        {
          user_id: authState?.id,
          quest_type:
            currentTab === 0
              ? "DAILY"
              : currentTab === 1
              ? "WEEKLY"
              : "LIFETIME",
        },
        authState?.jwtToken,
        authState?.account
      );

      setLoading(false);

      setTotalQuests(allQuestsRes?.data?.totalQuests);
      setTotalPoints(userQuestsRes?.data.questPoints);

      const userQuests = userQuestsRes?.data?.quests;

      setQuests(userQuests);
      setClaimLoading(true);

      if (roundEnded) return;
      //fetch updated one and update component when done
      checkQuests(
        {
          user_id: authState?.id,
        },
        authState?.jwtToken,
        authState?.account
      )
        .then((res) => {
          setClaimLoading(false);
          setProgress(res?.data?.checkResults);
          if (res?.data?.completionCount === 0) return;
          console.log("updated");
          setRefresh(true);
        })
        .catch((err) => {
          console.log("error updating quests", err);
        });
    }

    fetchData();
  }, [
    blocknumber,
    authState,
    currentTab,
    pageNumber,
    claiming,
    authenticated,
    currentTimeIndex,
    refresh,
    roundEnded,
  ]);
  //handle manual refresh button
  const handleRefresh = async () => {
    try {
      setRefresh(true);
      const claimCheck = await checkQuests(
        {
          user_id: authState?.id,
        },
        authState?.jwtToken,
        authState?.account
      );
      console.log(claimCheck);
      setRefresh(false);
    } catch (error) {}
  };
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      navigate("/quest?page=daily");
    } else if (newValue === 1) {
      navigate("/quest?page=weekly");
    } else if (newValue === 2) {
      navigate("/quest?page=lifetime");
    }
  };

  const claim = useCallback(
    async (quest) => {
      setClaiming({ loading: true, id: quest?._id });
      const result = await claimQuest(
        quest?._id,
        authState?.jwtToken,
        authState?.account
      );

      if (result?.status !== 201) {
        setError(result?.message?.msg);
      }
      setClaiming({});
      return;
    },
    [authState?.jwtToken, authState?.account, setClaiming, setError]
  );

  const handleAlertClose = () => {
    setError("");
  };
  return (
    <Box className={classes.background}>
      <AlertPopup
        open={error === "" ? false : true}
        message={error}
        severity={"error"}
        handlePopupClose={handleAlertClose}
      />
      {redeem && (
        <PopupLayout popupActive={redeem}>
          <RedeemPopup
            resetPopup={() => {
              setRefresh(!refresh);
              setRedeem(false);
            }}
            maxPoints={totalPoints}
          />
        </PopupLayout>
      )}
      {authenticated && (
        <Container>
          <Box>
            <Typography variant="h3" className={classes.title}>
              <FormattedMessage id="Quest" defaultMessage="Quest" />
            </Typography>
            <p className={classes.subtitle}>
              <FormattedMessage
                id="Complete your quests and collect points."
                defaultMessage="Complete your quests and collect points."
              />
            </p>
          </Box>
          <Box mt={2} className={classes.infoCard}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ width: "100%", marginBottom: 1 }}
            >
              <Typography variant="h4" className={classes.title}>
                <FormattedMessage
                  id="Total Points"
                  defaultMessage="Total Points"
                />{" "}
                : {totalPoints ? totalPoints : 0}
              </Typography>

              <Button
                disabled={totalPoints < 100}
                size={sm ? "small" : "large"}
                style={{
                  borderRadius: 10,
                  backgroundColor:
                    totalPoints < 100
                      ? theme.palette.claimButton.secondary
                      : theme.palette.button.primary,
                  padding: "5px 20px 5px 20px",
                  color: "white",
                }}
                onClick={() => setRedeem(true)}
              >
                <FormattedMessage id="Redeem" defaultMessage="Redeem" />
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={sm ? "column" : "row"}
              sx={{ width: "100%", marginBottom: 1 }}
            >
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  value={0}
                  label={
                    <FormattedMessage
                      id="Daily Quests"
                      defaultMessage="Daily Quests"
                    />
                  }
                />
                <Tab
                  value={1}
                  label={
                    <FormattedMessage
                      id="Weekly Quests"
                      defaultMessage="Weekly Quests"
                    />
                  }
                />
                <Tab
                  value={2}
                  label={
                    <FormattedMessage
                      id="Lifetime Quests"
                      defaultMessage="Lifetime Quests"
                    />
                  }
                />
              </Tabs>
              <div className="d-flex justify-content-md-between align-content-center align-items-center mt-3">
                {!roundEnded && (
                  <Refresh
                    onClick={handleRefresh}
                    style={{
                      fontSize: 19,
                      marginRight: 0.5,
                      color: theme.palette.background.main,
                      cursor: "pointer",
                    }}
                  />
                )}
                <Typography
                  alignSelf="center"
                  textAlign="right"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{
                    fontWeight: 500,
                    // marginTop: md ? 20 : 0,
                    paddingRight: 2,
                  }}
                  className={classes.otherText}
                >
                  <FormattedMessage id="Resets in" defaultMessage="Resets in" />{" "}
                  :{" "}
                </Typography>
                {roundEnded ? (
                  <Typography
                    alignSelf="center"
                    textAlign="right"
                    variant="body2"
                    fontSize={sm ? 12 : 14}
                    style={{
                      fontWeight: 500,
                    }}
                    color={theme.palette.action.red}
                  >
                    <FormattedMessage id="Ended" defaultMessage="Ended" />{" "}
                  </Typography>
                ) : (
                  <Countdown
                    date={timeToReset}
                    key={currentTimeIndex}
                    renderer={renderer}
                    onComplete={() => setCurrentTimeIndex(currentTimeIndex + 1)}
                  />
                )}
              </div>
            </Box>

            {roundEnded ? (
              <Box
                className={classes.tableCard}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                p={4}
              >
                <Typography
                  alignSelf="center"
                  textAlign="center"
                  variant="body2"
                  fontSize={sm ? 18 : 22}
                  color={theme.palette.action.red}
                  fontWeight={600}
                  p={1}
                >
                  <FormattedMessage
                    id="Round 1 Ended"
                    defaultMessage="Round 1 Ended"
                  />
                </Typography>
                <Typography
                  alignSelf="center"
                  textAlign="center"
                  variant="body2"
                  fontSize={sm ? 16 : 18}
                  color={theme.palette.text.secondary}
                  fontWeight={400}
                  p={1}
                >
                  <FormattedMessage
                    id="Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points"
                    defaultMessage="Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points"
                  />
                </Typography>
                {
                  <Button
                    disabled={true}
                    size={sm ? "small" : "large"}
                    style={{
                      borderRadius: 10,
                      backgroundColor: theme.palette.claimButton.error,
                      padding: "5px 20px 5px 20px",
                      color: "red",
                    }}
                    onClick={() => setRedeem(true)}
                  >
                    <FormattedMessage
                      id="Redeem Ended"
                      defaultMessage="Redeem Ended"
                    />
                  </Button>
                }
              </Box>
            ) : (
              <Box>
                {md ? (
                  <QuestsCardList
                    loading={loading}
                    claimLoading={claimLoading}
                    quests={quests}
                    progress={progress}
                    claim={claim}
                    claiming={claiming}
                    tab={currentTab}
                    page={pageNumber}
                    totalPages={Math.ceil(totalQuests / 10)}
                    changePage={(_page) => setPageNumber(_page)}
                  />
                ) : (
                  <QuestsTable
                    quests={quests}
                    progress={progress}
                    claim={claim}
                    claimLoading={claimLoading}
                    claiming={claiming}
                    tab={currentTab}
                    loading={loading}
                    classes={classes}
                    page={pageNumber}
                    changePage={(_page) => setPageNumber(_page)}
                    totalPages={Math.ceil(totalQuests / 10)}
                  />
                )}
                <div className="text-center">
                  {false && <CircularProgress />}
                </div>
              </Box>
            )}
            {!roundEnded && <Rules />}
          </Box>
        </Container>
      )}
      {authenticated && roundEnded && <QuestEnded />}

      {!authenticated && (
        <Container>
          <div className={classes.infoCardError}>
            <Box
              height={250}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <div className="text-center">
                <img
                  src="https://cdn3d.iconscout.com/3d/premium/thumb/incognito-mode-5847986-4897792.png"
                  alt="thumb"
                  style={{ height: 100 }}
                />
              </div>
              <Box my={2} style={{ color: theme.palette.text.primary }}>
                <h4 className="text-center">
                  <FormattedMessage
                    id="Unauthorised Access!"
                    defaultMessage="Unauthorised Access!"
                  />
                </h4>
                <Typography textAlign="center">
                  <FormattedMessage
                    id="Please connect your wallet first to continue P2P trading."
                    defaultMessage="Please connect your wallet first to continue P2P trading."
                  />
                </Typography>
              </Box>
              <WalletButton />
            </Box>
          </div>
        </Container>
      )}
    </Box>
  );
}

export default Quest;
