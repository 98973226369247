import React, { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { fromWei, formatCurrency } from "../../utils/helper";
import CountDownTime from "../../pages/Orders/OrderTransaction/CountDownTime";
import { useDispatch, useSelector } from "react-redux";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { transferTokenCall } from "../../utils/httpCalls/orderTradeCalls";
import { getUserTradeById } from "actions/tradeActions";
import { FormattedMessage } from "react-intl";
import GenericPopup from "./GenericPopup";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  icon: {
    width: 25,
    height: 25,
    color: "white",
  },
  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: 20,
    width: "90%",

    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  roundedBox: {
    background:
      theme.palette.mode === "light" ? "rgba(189, 180, 241, 0.6)" : "#393D68",
    display: "flex",
    padding: 20,
    width: "90%",
    borderRadius: 12,
    alignItems: "center",
    maxWidth: 300,

    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  condition: {
    cursor: "pointer",
  },
}));

const ReleaseTokenPopup = ({ resetPopup, pendingTrade }) => {
  const [isSelect, setSelect] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSelect = () => {
    setSelect(!isSelect);
  };

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const userAuth = useSelector((state) => state?.user);

  const dispatch = useDispatch();
  const { chainId } = useActiveWeb3React();

  const [alertPopup, setAlertPopup] = useState({
    open: false,
    message: "",
    showSwitch: false,
  });

  const handleTokenTransfer = useCallback(async () => {
    if (!userAuth?.jwtToken || !pendingTrade?._id || !chainId) {
      return;
    }

    setError(null);

    try {
      setLoading(true);
      const transferRes = await transferTokenCall(
        pendingTrade?._id,
        userAuth?.account,
        userAuth.jwtToken
      );

      setLoading(false);

      if (transferRes.status !== 201) {
        if (transferRes.message?.msg?.includes("switch")) {
          setAlertPopup((prev) => ({
            ...prev,
            open: true,
            message: transferRes.message?.msg,
            showSwitch: true,
          }));
        } else {
          setError(transferRes.message?.msg);
        }

        return;
      }
      dispatch(
        getUserTradeById(
          pendingTrade?._id,
          userAuth?.account,
          userAuth.jwtToken
        )
      );

      resetPopup();
    } catch (error) {
      setError("Something went wrong! please try again");
    }

    // await transferTokens(buyerAddress, tokenAddress, chainId, tokenAmount);
  }, [pendingTrade?._id, userAuth, chainId]);

  return (
    <div className={classes.background}>
      <GenericPopup
        open={alertPopup.open}
        message={alertPopup.message}
        showSwitch={alertPopup.showSwitch}
        title="Error"
        handleClose={() => setAlertPopup({ open: false, message: "" })}
      />
      <div className={classes.container}>
        <div className="d-flex justify-content-end">
          <Close
            style={{ color: theme.palette.text.primary, fontSize: 28 }}
            onClick={resetPopup}
          />
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Box className={classes.heading}>
            <FormattedMessage
              id="Confirm and Release"
              defaultMessage="Confirm and Release"
            />
          </Box>
          <CountDownTime
            color={"#919191"}
            createdAt={pendingTrade?.created_at}
            timeWindow={pendingTrade?.time_limit}
            onComplete={() => {}}
          />
        </div>
        <Box display="flex" justifyContent="center" pt={sm ? 1 : 2}>
          <Box className={classes.cautionTextBox}>
            <Typography
              fontSize={sm ? 20 : 22}
              color={"#919191"}
              fontWeight={600}
              mb={sm ? 0.5 : 1}
            >
              <FormattedMessage id="Tips" defaultMessage="Tips" />
            </Typography>
            <Typography
              fontSize={sm ? 13 : 16}
              color={"#919191"}
              fontWeight={sm ? 400 : 500}
              mb={sm ? 0.5 : 1}
            >
              1.{" "}
              <FormattedMessage
                id="dontnotcheckbuyerpayment"
                defaultMessage="Do not only check the buyer's payment proof. Make sure that you
              login to your account and verify that the payment has been
              received."
              />
            </Typography>
            <Typography
              fontSize={sm ? 13 : 16}
              color={"#919191"}
              fontWeight={sm ? 400 : 500}
              mb={sm ? 0.5 : 1}
            >
              2.{" "}
              <FormattedMessage
                id="ifstillprocessing"
                defaultMessage="If the payment is still processing, wait until you have
              received payment in to your account before releasing the crypto!"
              />
            </Typography>
            <Typography
              fontSize={sm ? 13 : 16}
              color={"#919191"}
              fontWeight={sm ? 400 : 500}
              mb={sm ? 0.5 : 1}
            >
              3.{" "}
              <FormattedMessage
                id="donotacceptthridparty"
                defaultMessage="Do NOT accept payment from a third-party account. Refund the
              full amount immediately if you receive such payment to avoid
              financial losses caused by bank chargeback after you have released
              the crypto."
              />
            </Typography>
          </Box>
        </Box>

        <Box mt={3}>
          <Typography
            fontSize={sm ? 14 : 17}
            fontWeight={sm ? 400 : 600}
            color={"#919191"}
            textAlign="center"
          >
            <FormattedMessage
              id="Confirm User Account and Release Amount"
              defaultMessage="Confirm User Account and Release Amount"
            />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" pt={3}>
          <div className={classes.roundedBox}>
            <Box display="flex" alignItems="center" width="100%">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography
                  fontSize={sm ? 14 : 16}
                  fontWeight={500}
                  color={"#6A55EA"}
                >
                  {pendingTrade?.buyer?.name}
                </Typography>
                <Typography
                  fontSize={sm ? 14 : 16}
                  fontWeight={500}
                  color={"#6A55EA"}
                >
                  {`${formatCurrency(
                    fromWei(
                      pendingTrade?.final_token_amount,
                      pendingTrade?.order?.token?.decimals
                    )
                  )} ${pendingTrade?.order?.token?.symbol}`}
                </Typography>
              </Box>
            </Box>
          </div>
        </Box>
        <Box
          margin="3px auto"
          pt={3}
          display="flex"
          justifyContent="center"
          width="90%"
          alignItems="start"
          className={classes.condition}
          onClick={handleSelect}
        >
          <Checkbox size="small" checked={!isSelect} />
          <Typography
            fontSize={sm ? 14 : 18}
            fontWeight={500}
            color={"#919191"}
            ml={1}
          >
            <FormattedMessage
              id="iconfirmpaymentcorrect"
              defaultMessage="I confirm that the payment has been credited to my account and the
            available balance is correct."
            />
          </Typography>
        </Box>
        {error && (
          <Box mt={3}>
            <Typography
              fontSize={sm ? 14 : 17}
              fontWeight={sm ? 400 : 600}
              color={"#red"}
              textAlign="center"
            >
              {error}
            </Typography>
          </Box>
        )}
        <Box className="d-flex justify-content-center align-items-baseline pt-3">
          <div>
            <button
              onClick={handleTokenTransfer}
              style={{
                background: isSelect
                  ? theme.palette.mode === "light"
                    ? "#bdbdbd"
                    : "#646892"
                  : "#6A55EA",
                border: isSelect
                  ? theme.palette.mode === "light"
                    ? "1px solid #bdbdbd"
                    : "1px solid #646892"
                  : `1px solid #6A55EA`,
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: sm ? 15 : 20,
                paddingRight: sm ? 15 : 20,
                fontSize: sm ? 13 : 16,
                textDecoration: "none",
                color: "#fff",
              }}
              disabled={isSelect || loading}
            >
              {loading ? (
                <FormattedMessage
                  id="Releasing..."
                  defaultMessage="Releasing..."
                />
              ) : (
                <FormattedMessage
                  id="Confirm and Release"
                  defaultMessage="Confirm and Release"
                />
              )}
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ReleaseTokenPopup;
