import {
  SET_ADMIN_TXN_FETCH_ERROR,
  SET_ADMIN_TXN_LOADING,
  GET_ADMIN_TOTAL_TRANSACTIONS,
  GET_ADMIN_TRANSACTIONS,
  SET_REPUSH_ERROR,
  SET_ANALYTICS_LOADING,
  SET_ANALYTICS_FETCH_ERROR,
  GET_ANALYTICS_DATA,
  GET_ADMIN_ORDERS,
  GET_ADMIN_TOTAL_ORDERS,
  SET_ADMIN_ORDERS_LOADING,
  SET_ADMIN_ORDER_FETCH_ERROR,
  GET_ADMIN_USERS,
  GET_ADMIN_TOTAL_USERS,
  SET_ADMIN_USERS_LOADING,
  SET_ADMIN_USER_FETCH_ERROR,
  SET_REQUESTS_FETCH_ERROR,
  SET_REQUESTS_LOADING,
  GET_TOTAL_REQUESTS,
  GET_REDEEM_REQUESTS,
} from "../actions/types";

const initalState = {
  transactions: [],
  totalTransactions: 0,
  txnsLoading: false,
  txnFetchError: null,
  orders: [],
  totalOrders: 0,
  ordersLoading: false,
  orderFetchError: null,
  users: [],
  totalUsers: 0,
  usersLoading: false,
  userFetchError: null,
  redeemRequests: [],
  totalRequests: 0,
  totalPoints: 0,
  requestsLoading: false,
  requestFetchError: null,
  repushError: null,
  analyticsData: null,
  analyticsLoading: false,
  analyticsFetchError: null,
};

export default function adminReducer(state = initalState, action) {
  switch (action.type) {
    case GET_ADMIN_TRANSACTIONS:
      return {
        ...state,
        transactions: [...action.payload],
        txnFetchError: null,
      };
    case GET_ADMIN_TOTAL_TRANSACTIONS:
      return {
        ...state,
        totalTransactions: action.payload,
      };
    case SET_ADMIN_TXN_LOADING:
      return {
        ...state,
        txnsLoading: action.payload,
      };
    case SET_ADMIN_TXN_FETCH_ERROR: {
      return {
        ...state,
        txnFetchError: action.payload,
      };
    }
    case GET_ADMIN_ORDERS:
      return {
        ...state,
        orders: [...action.payload],
        orderFetchError: null,
      };
    case GET_ADMIN_TOTAL_ORDERS:
      return {
        ...state,
        totalOrders: action.payload,
      };
    case SET_ADMIN_ORDERS_LOADING:
      return {
        ...state,
        ordersLoading: action.payload,
      };
    case SET_ADMIN_ORDER_FETCH_ERROR:
      return {
        ...state,
        orderFetchError: action.payload,
      };

    case GET_ADMIN_USERS:
      return {
        ...state,
        users: [...action.payload],
        usersFetchError: null,
      };
    case GET_ADMIN_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };
    case SET_ADMIN_USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload,
      };
    case SET_ADMIN_USER_FETCH_ERROR:
      return {
        ...state,
        userFetchError: action.payload,
      };
    case GET_REDEEM_REQUESTS:
      return {
        ...state,
        redeemRequests: [...action.payload],
        requestFetchError: null,
      };
    case GET_TOTAL_REQUESTS:
      return {
        ...state,
        totalRequests: action.payload.totalRequests,
        totalPoints: action.payload.totalPoints,
      };
    case SET_REQUESTS_LOADING:
      return {
        ...state,
        requestsLoading: action.payload,
      };
    case SET_REQUESTS_FETCH_ERROR:
      return {
        ...state,
        requestFetchError: action.payload,
      };

    case GET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: action.payload,
      };
    case SET_ANALYTICS_LOADING:
      return {
        ...state,
        analyticsLoading: action.payload,
      };
    case SET_ANALYTICS_FETCH_ERROR:
      return {
        ...state,
        analyticsFetchError: action.payload,
      };
    case SET_REPUSH_ERROR: {
      return {
        ...state,
        repushError: action.payload,
      };
    }
    default:
      return state;
  }
}
