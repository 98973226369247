import {
  Box,
  Typography,
  ListItem,
  List,
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  Hidden,
  ListItemButton,
  Badge,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import useActiveWeb3React from "../hooks/useActiveWeb3React";
import clsx from "clsx";
import { Language, Menu } from "@mui/icons-material";
import AlertPopup from "./AlertPopup";
import WalletButton from "./WalletButton";
import { useInternet } from "../hooks/useInternet";
import { useFetchGlobalDataCallback } from "../hooks/useFetchGlobalDataCallback";
import NetworkSelect from "./NetworkSelect";
import pusher from "../utils/pusherInstance";
import TradePopup from "./popups/TradePopup";
import { IS_MAINNET, LANGUAGES } from "../constants";

import successAudio from "../assets/audio/success.mp3";
import { getUserTradeById, getUserTrades } from "../actions/tradeActions";
import { fetchBalances } from "../actions/walletActions";
import notificationSound from "../assets/audio/message_notification.mp3";
import MessagePopup from "./popups/MessagePopup";
import ModeSwitchButton from "./ModeSwitchButton";
import { FormattedMessage } from "react-intl";
import UserGuide from "./UserGuide";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    backgroundColor: "transparent",
  },
  connectedButton: {
    marginLeft: 7,
    color: "white",
    padding: "7px 13px 7px 13px",
    backgroundColor: theme.palette.primary.light,
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 14,

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,

      width: "fit-content",
      padding: "7px 5px 7px 10px",
    },
  },
  linkItems: {
    paddingRight: 12,
    // paddingTop: 12,
    textAlign: "center",
    fontWeight: 600,
    paddingLeft: 12,
    fontSize: 15,
    display: "none",
    [theme.breakpoints.down("xl")]: {
      display: "block",
      paddingRight: 12,
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  logo: {
    height: 55,
  },
  paper: {
    top: "67px !important",
    left: "unset !important",
    right: "0 !important",
    width: "45%",
    borderRadius: "0",
    backgroundColor: "black",
    transformOrigin: "16px -1px !important",
  },
  listItem: {
    justifyContent: "center",
  },
  navbarButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 18px 7px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 15,
    "&:hover": {
      background: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 15,
      width: 150,
    },
  },

  connectedAddress: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: "4px 18px 4px 18px",
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    letterSpacing: 0.4,
    textTransform: "none",
    fontSize: 15,

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 5,
      width: "fit-content",
      padding: "4px 10px 4px 10px",
    },
  },
  numbers: {
    color: "#f9f9f9",
    fontSize: 14,
  },
  grow: {
    flexGrow: 1,
  },
  menuTitle: {
    paddingLeft: 20,
    fontWeight: 500,
    verticalAlign: "baseline",
    letterSpacing: "-0.4px",
    textAlign: "left",
    fontSize: 17,
    color: "black",
    textDecoration: "none",
  },
  list: {
    backgroundColor: theme.palette.background.primary,
    width: 200,
  },
}));

const Appbar = ({ locale, changeLocale, open = false, setSidebarOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const authenticatedUser = useSelector((state) => state?.user);
  const userChainId = useSelector((state) => state?.user?.chainId);
  const userFiat = useSelector((state) => state?.user?.userFiat);
  const authError = useSelector((state) => state?.user?.authError);
  const profileError = useSelector((state) => state?.profile?.profileError);
  const pendingTrades = useSelector((state) => state?.userTrade?.trades);

  const [pendingTradesLength, setPendingTradesLength] = useState(0);

  const dispatch = useDispatch();
  const pathname = useLocation().pathname;

  const messageNotificationAudio = new Audio(notificationSound);
  messageNotificationAudio.loop = true;

  useEffect(() => {
    if (!authenticatedUser?.jwtToken || !authenticatedUser?.account) return;

    dispatch(
      getUserTrades(authenticatedUser?.account, authenticatedUser?.jwtToken, {
        status: "pending",
        page: 1,
      })
    );
  }, [authenticatedUser?.jwtToken, authenticatedUser?.account, dispatch]);

  useEffect(() => {
    let pendingTradesLength = 0;

    if (pendingTrades?.length > 0) {
      pendingTrades.forEach((trade) => {
        if (trade?.status === "pending") {
          pendingTradesLength++;
        }
      });

      setPendingTradesLength(pendingTradesLength);
    } else {
      setPendingTradesLength(0);
    }
  }, [pendingTrades]);

  const [state, setState] = React.useState({
    right: open,
    isNotificationOpen: false,
    data: undefined,
  });

  useEffect(() => {
    setState({ ...state, right: open });
  }, [open]);

  const toggleDrawer = (anchor, drawOpen) => (event) => {
    if (drawOpen == false) {
      setSidebarOpen(false);
    }
    setState({ ...state, [anchor]: drawOpen });
  };

  useEffect(() => {
    if (!(authenticatedUser?.authenticated && authenticatedUser?.jwtToken)) {
      return;
    }

    const messageChannel = `message_${authenticatedUser?.id}`;
    const channel = pusher.subscribe(messageChannel);

    channel.bind("message_received", (data) => {
      if (pathname !== `/order-waiting/${data?.transactionId}`) {
        setState((prev) => ({
          ...prev,
          isNotificationOpen: true,
          data,
        }));
        messageNotificationAudio.loop = false;
        messageNotificationAudio.play();
      }
    });

    return () => {
      channel.unbind();
      pusher.unsubscribe(messageChannel);
    };
  }, [authenticatedUser, pathname]);
  const DASHBOARD_TITLES = IS_MAINNET
    ? [
        {
          name: "Home",
          to: "/",
          className: "home",
        },
        {
          name: "Create Order",
          to: "/create-order",
          className: "create_order",
        },
        { name: "My Orders", to: "/my-orders", className: "my-orders" },
        { name: "Wallet", to: "/wallet", className: "wallet" },
        { name: "Profile", to: "/profile", className: "profile" },
        // { name: "Quest", to: "/quest", className: "quest" }, TODO: change for round 2
        {
          name: "Leaderboard",
          to: "/leaderboard",
          className: "leaderboard",
        },
      ]
    : [
        {
          name: "Home",
          to: "/",
          className: "home",
        },
        {
          name: "Create Order",
          to: "/create-order",
          className: "create_order",
        },
        { name: "My Orders", to: "/my-orders", className: "my-orders" },
        { name: "Wallet", to: "/wallet", className: "wallet" },
        { name: "Profile", to: "/profile", className: "profile" },
        { name: "Quest", to: "/quest", className: "quest" },
        {
          name: "Leaderboard",
          to: "/leaderboard",
          className: "leaderboard",
        },
        { name: "Faucet", to: "/faucet", className: "faucet" },
      ];
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        display="flex"
        flexDirection="column"
        pl={4}
        pt={5}
        alignItems="start"
      >
        <Box display="flex" alignItems="center">
          <FormattedMessage id="Mode" defaultMessage="Mode" />
          <ModeSwitchButton />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          zIndex={100000}
        >
          <FormControl size="small">
            <Select
              variant="standard"
              disableUnderline
              sx={{
                padding: "6px 0px 3px 12px",
                color: theme.palette.text.primary,
                border: "1px solid #6A55EA",
                borderRadius: 2.5,
                border: "none",
                bgcolor: theme.palette.background.secondary,
                fontWeight: 500,
                fontSize: 14,
                width: "fit-content",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                    width: 150,
                    backgroundColor: theme.palette.dropdown.main,
                  },
                },
              }}
              renderValue={(selected) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Language />
                  <span style={{ marginRight: 8 }}>{selected}</span>
                </div>
              )}
              inputProps={{
                IconComponent: () => null,
                sx: { padding: "0 !important" },
              }}
              value={locale}
              onChange={(e) => changeLocale(e.target.value)}
            >
              {LANGUAGES.map((lang, index) => {
                return (
                  <MenuItem key={index} value={lang.value} dense>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={theme.palette.text.primary}
                    >
                      {lang.label}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box onClick={(e) => e.stopPropagation()}>
            <UserGuide setSidebarOpen={setSidebarOpen} />
          </Box>
        </Box>

        <NetworkSelect />
      </Box>
      <List>
        {DASHBOARD_TITLES.map((tab, index) => {
          return (
            <Link
              key={index}
              to={tab.to}
              className={tab.className}
              smooth="true"
              offset={0}
              duration={500}
              delay={0}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                key={tab.name}
                onClick={toggleDrawer(anchor, false)}
                fontWeight={700}
              >
                {tab.name === "My Orders" && (
                  <p
                    primary={tab.name}
                    className={classes.menuTitle}
                    style={{
                      color:
                        tab.to === pathname
                          ? "#6A55EA"
                          : theme.palette.text.primary,
                      position: "relative",
                    }}
                  >
                    <FormattedMessage id={tab.name} defaultMessage={tab.name} />
                    <Badge
                      badgeContent={pendingTradesLength}
                      color="primary"
                      sx={{
                        position: "absolute",
                        top: 1,
                        right: -7,
                      }}
                    />
                  </p>
                )}
                {tab.name !== "My Orders" && (
                  <p
                    primary={tab.name}
                    className={classes.menuTitle}
                    id={tab.name}
                    style={{
                      color:
                        tab.to === pathname
                          ? "#6A55EA"
                          : theme.palette.text.primary,
                    }}
                  >
                    <FormattedMessage id={tab.name} defaultMessage={tab.name} />
                  </p>
                )}
              </ListItemButton>
            </Link>
          );
        })}
        <ListItem>
          <Stack spacing={1.5}>
            <Link to="/support">
              <button className={classes.connectedButton}>
                <FormattedMessage id="Support" defaultMessage="Support" />
              </button>
            </Link>
          </Stack>
        </ListItem>
      </List>
    </div>
  );

  const { fetchGlobalData } = useFetchGlobalDataCallback(true);

  // TRADE POPUP STATES
  const initialPopupState = {
    open: false,
    event: null,
    message: "",
    tradeId: null,
  };
  const [tradePopup, setTradePopup] = useState(initialPopupState);
  const { account, chainId } = useActiveWeb3React();

  const closeTradePopup = useCallback(() => {
    setTradePopup(initialPopupState);
  }, [tradePopup, setTradePopup]);

  //subscribe current user for order updates
  const audio = new Audio(successAudio);
  audio.loop = true;

  const location = useLocation();
  useEffect(() => {
    if (!(authenticatedUser?.authenticated && authenticatedUser?.jwtToken)) {
      return;
    }

    if (!location) {
      return;
    }

    const tradeChannel = `order_${authenticatedUser?.id}`;

    const channel = pusher.subscribe(tradeChannel);
    channel.bind("message", (data) => {
      audio.loop = false;

      audio.play();

      setTradePopup({
        ...tradePopup,
        open: true,
        message: data?.message,
        event: data?.event,
        tradeId: data?.trade,
      });

      if (
        location.pathname?.includes("order-waiting") &&
        !location?.pathname?.includes(data?.trade)
      ) {
        return;
      }
      dispatch(
        getUserTradeById(
          data?.trade,
          authenticatedUser?.account,
          authenticatedUser?.jwtToken
        )
      );
    });

    return () => {
      channel.unbind();
      pusher.unsubscribe(tradeChannel);
    };
  }, [authenticatedUser?.authenticated, authenticatedUser?.jwtToken, location]);

  // cancel trade events watch
  useEffect(() => {
    if (!(authenticatedUser?.authenticated && authenticatedUser?.jwtToken)) {
      return;
    }

    if (!location) {
      return;
    }
    if (!location?.pathname?.includes("order-waiting")) {
      return;
    }

    const tradeChannel = `trade_update_${authenticatedUser?.id}`;

    const channel = pusher.subscribe(tradeChannel);
    channel.bind("message", (data) => {
      if (
        location.pathname?.includes("order-waiting") &&
        !location?.pathname?.includes(data?.trade)
      ) {
        return;
      }

      dispatch(
        getUserTradeById(
          data?.trade,
          authenticatedUser?.account,
          authenticatedUser?.jwtToken
        )
      );
    });

    return () => {
      channel.unbind();
      pusher.unsubscribe(tradeChannel);
    };
  }, [authenticatedUser?.jwtToken, authenticatedUser?.authenticated, location]);

  // fetch wallet balance on app load
  useEffect(() => {
    if (!chainId || !authenticatedUser?.jwtToken) {
      return;
    }

    dispatch(fetchBalances(account, chainId, authenticatedUser?.jwtToken));
  }, [account, chainId, authenticatedUser]);

  useEffect(() => {
    if (!userChainId || !userFiat) {
      return;
    }

    fetchGlobalData(userChainId, userFiat);
  }, [userChainId, userFiat]);

  useEffect(() => {
    if (!chainId) {
      return;
    }
    const cachedChain = localStorage.getItem("cachedChain");

    if (cachedChain && chainId?.toString() !== cachedChain) {
      localStorage.setItem("cachedChain", chainId?.toString());

      window?.location.reload();
    } else if (!cachedChain) {
      localStorage.setItem("cachedChain", chainId?.toString());
    }
  }, [chainId, account]);

  useEffect(() => {
    if (!account) {
      return;
    }
    const cachedAccount = localStorage.getItem("cachedAccount");

    if (cachedAccount && account?.toString() !== cachedAccount) {
      localStorage.setItem("cachedAccount", account?.toString());

      window?.location.reload();
    } else if (!cachedAccount) {
      localStorage.setItem("cachedAccount", account?.toString());
    }
  }, [account]);

  // const currentAppVersion = process.env.REACT_APP_VERSION;
  // useEffect(() => {
  //   if (localStorage.getItem(`app_version`) === currentAppVersion) {
  //     return;
  //   }
  //   // clear all local storage
  //   localStorage.clear();

  //   localStorage.setItem(`app_version`, currentAppVersion);
  // }, [currentAppVersion]);

  const initalSnackObjectState = {
    open: false,
    message: "",
    isError: false,
    hash: null,
    severity: "error",
  };
  const [snackObject, setSnackObject] = useState(initalSnackObjectState);

  const internet = useInternet();
  const isError = useMemo(() => {
    if (!authError && !profileError) {
      return false;
    }
    return true;
  }, [authError, profileError]);
  const currentError = authError || profileError;

  useEffect(() => {
    if (!internet) {
      setSnackObject({
        ...initalSnackObjectState,
        open: true,
        severity: "error",
        message: "Please check your network connection",
      });
    }

    if (internet && snackObject.open) {
      setSnackObject(initalSnackObjectState);
      return;
    }
  }, [internet]);

  useEffect(() => {
    if (!isError && !snackObject.open) {
      return;
    }

    if (!isError && snackObject.open) {
      setSnackObject(initalSnackObjectState);
      return;
    }

    if (isError) {
      setSnackObject({
        ...initalSnackObjectState,
        open: true,
        severity: "error",
        message: currentError,
      });
    }
  }, [isError, currentError]);

  const handlePopupClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackObject(initalSnackObjectState);
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          background: theme.palette.mode === "light" ? "white" : "#1E2142",
          transition: "background 0.2s ease",
          boxShadow: "none",
        }}
      >
        <MessagePopup
          open={state.isNotificationOpen}
          data={state.data}
          handleClose={() =>
            setState((prev) => ({ ...prev, isNotificationOpen: false }))
          }
        />
        <AlertPopup
          message={snackObject.message}
          open={snackObject.open}
          severity={snackObject.severity}
          handlePopupClose={handlePopupClose}
        />
        <TradePopup
          handleClose={closeTradePopup}
          open={tradePopup.open}
          message={tradePopup.message}
          tradeId={tradePopup?.tradeId}
          tradeEvent={tradePopup.event}
        />
        <Hidden lgDown>
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <Typography className={classes.title} variant="h6" noWrap>
              <Link to="/">
                <img
                  src={
                    theme.palette.mode === "light"
                      ? "/polkabridge.png"
                      : "/p2p_white.png"
                  }
                  alt="logo"
                  height="60px"
                />
              </Link>
            </Typography>
            <div className="d-flex justify-content-end align-content-center align-items-center">
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  color="textSecondary"
                  variant="h6"
                  className={classes.linkItems}
                  style={{
                    color:
                      pathname === "/" ? "#6A55EA" : theme.palette.text.primary,
                  }}
                >
                  <FormattedMessage id="Home" defaultMessage="Home" />
                </Typography>
              </Link>
              <Link
                to="/create-order"
                className="create-order"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.linkItems}
                  style={{
                    color:
                      pathname === "/create-order"
                        ? "#6A55EA"
                        : theme.palette.text.primary,
                  }}
                >
                  <FormattedMessage
                    id="Create Order"
                    defaultMessage="Create Order"
                  />
                </Typography>
              </Link>

              <Link
                to="/my-orders"
                style={{ textDecoration: "none", position: "relative" }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.linkItems}
                  style={{
                    color:
                      pathname === "/my-orders"
                        ? "#6A55EA"
                        : theme.palette.text.primary,
                  }}
                >
                  <FormattedMessage id="My Orders" defaultMessage="My Orders" />
                </Typography>
                <Badge
                  badgeContent={pendingTradesLength}
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 7,
                  }}
                />
              </Link>
              <Link
                to="/wallet"
                className="my-wallet"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.linkItems}
                  style={{
                    textDecoration: "none",
                    color:
                      pathname === "/wallet"
                        ? "#6A55EA"
                        : theme.palette.text.primary,
                  }}
                >
                  <FormattedMessage id="Wallet" defaultMessage="Wallet" />
                </Typography>
              </Link>
              <Link
                to="/profile"
                className="my-profile"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.linkItems}
                  style={{
                    color:
                      pathname === "/profile"
                        ? "#6A55EA"
                        : theme.palette.text.primary,
                  }}
                >
                  <FormattedMessage id="Profile" defaultMessage="Profile" />
                </Typography>
              </Link>
              {/* TODO:change for round 2 */}
              {!IS_MAINNET && (
                <Link
                  to="/quest"
                  className="quest-tab"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.linkItems}
                    style={{
                      color:
                        pathname === "/quest"
                          ? "#6A55EA"
                          : theme.palette.text.primary,
                    }}
                  >
                    <FormattedMessage id="Quest" defaultMessage="Quest" />
                  </Typography>
                </Link>
              )}
              <Link
                to="/leaderboard"
                className="leaderboard-tab"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.linkItems}
                  style={{
                    color:
                      pathname === "/leaderboard"
                        ? "#6A55EA"
                        : theme.palette.text.primary,
                  }}
                >
                  <FormattedMessage
                    id="Leaderboard"
                    defaultMessage="Leaderboard"
                  />
                </Typography>
              </Link>
              {!IS_MAINNET && (
                <Link to="/faucet" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.linkItems}
                    style={{
                      color:
                        pathname === "/faucet"
                          ? "#6A55EA"
                          : theme.palette.text.primary,
                    }}
                  >
                    <FormattedMessage id="Faucet" defaultMessage="Faucet" />
                  </Typography>
                </Link>
              )}
              <UserGuide
                setSidebarOpen={setSidebarOpen}
                style={{ zIndex: 10000 }}
              />
              <FormControl size="small">
                <Select
                  variant="standard"
                  disableUnderline
                  sx={{
                    mr: 1,
                    padding: "6px 0px 3px 12px",
                    color: theme.palette.text.primary,
                    border: "1px solid #6A55EA",
                    borderRadius: 2.5,
                    border: "none",
                    bgcolor: theme.palette.background.secondary,
                    fontWeight: 500,
                    fontSize: 14,
                    width: "fit-content",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 350,
                        width: 150,
                        backgroundColor: theme.palette.dropdown.main,
                      },
                    },
                  }}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Language />
                      <span style={{ marginRight: 8 }}>{selected}</span>
                    </div>
                  )}
                  inputProps={{
                    IconComponent: () => null,
                    sx: { padding: "0 !important" },
                  }}
                  value={locale}
                  onChange={(e) => changeLocale(e.target.value)}
                >
                  {LANGUAGES.map((lang, index) => {
                    return (
                      <MenuItem key={index} value={lang.value} dense>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          color={theme.palette.text.primary}
                        >
                          {lang.label}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <NetworkSelect />
              <ModeSwitchButton />
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <WalletButton initHooks={true} />

                <Link to="/support">
                  <button className={classes.connectedButton}>
                    <FormattedMessage id="Support" defaultMessage="Support" />
                  </button>
                </Link>
              </Box>
            </div>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Toolbar className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Link to="/">
                <img
                  src={
                    theme.palette.mode === "light"
                      ? "/polkabridge.png"
                      : "/p2p_white.png"
                  }
                  alt="logo"
                  height="50px"
                />
              </Link>
            </div>

            <div className="d-flex justify-content-end">
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <WalletButton initHooks={true} />
              </Box>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    className={classes.menuIcon}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <Menu />
                  </IconButton>

                  <SwipeableDrawer
                    anchor={anchor}
                    disableSwipeToOpen={false}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                    transitionDuration={
                      authenticatedUser.authenticated &&
                      !authenticatedUser?.tour_done
                        ? 0
                        : 400
                    }
                  >
                    <div
                      style={{
                        height: "100%",
                        backgroundColor: theme.palette.background.primary,
                      }}
                    >
                      {list(anchor)}
                    </div>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
          </Toolbar>
        </Hidden>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Appbar);
