import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HowItWorks from "../../../common/HowItWorks";
import { getOrderDetailsById } from "../../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import {
  camelize,
  formatCurrency,
  fromWei,
  toMinutes,
  toWei,
} from "../../../utils/helper";
import BigNumber from "bignumber.js";
import { createTrade } from "../../../utils/httpCalls/orderTradeCalls";
import UserBalance from "../CreateOrder/UserBalance";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import { fetchBalances } from "../../../actions/walletActions";
import { FormattedMessage, useIntl } from "react-intl";
import GenericPopup from "../../../common/popups/GenericPopup";
import { CHAIN_IDS_TO_DISPLAY_NAMES } from "../../../constants/chains";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #212446",
    paddingTop: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.background.primary,
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 22,
    letterSpacing: "0.02em",
  },
  buttonAction: {
    padding: 0,
    color: `#6A55EA`,
    border: "none",
    borderRadius: 14,
    marginRight: 0,
    backgroundColor: "transparent",
    marginLeft: 8,
  },
}));

function OrderSummary() {
  const formattedNumber = (number, decimals) => {
    const numberArray = number.toString().split(".");
    const integer = numberArray?.[0];
    const decimal = numberArray?.[1];

    const formattedNumber = integer
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^0+(?=\d)/, "");

    return decimal !== undefined
      ? `${formattedNumber}.${decimals ? decimal.slice(0, decimals) : decimal}`
      : formattedNumber;
  };

  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { order_id } = useParams();
  const search = useLocation().search;
  const tradeType = new URLSearchParams(search).get("tradeType");
  const dispatch = useDispatch();

  const order = useSelector((state) => state?.order?.order);

  const [alertPopup, setAlertPopup] = useState({
    open: false,
    message: "",
    showSwitch: false,
  });
  const [fiatInput, setFiatInput] = useState("");
  const [tokenInput, setTokenInput] = useState("");
  const [isExactIn, setIsExactIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const currentUserAuth = useSelector((state) => state?.user?.jwtToken);
  const userAccount = useSelector((state) => state?.user?.account);

  const selectedToken = useMemo(() => {
    if (!order?._id) {
      return null;
    }

    return order?.token;
  }, [order]);

  useEffect(() => {
    async function asyncFn() {
      if (order_id && currentUserAuth && userAccount) {
        dispatch(getOrderDetailsById(order_id, userAccount, currentUserAuth));
      }
    }
    asyncFn();
  }, [order_id, userAccount, currentUserAuth, dispatch]);

  const onFiatInputChange = useCallback(
    (value) => {
      if (!isExactIn) {
        setIsExactIn(true);
      }

      setFiatInput(value);
    },
    [setFiatInput, isExactIn, setIsExactIn]
  );

  const onTokenInputChange = useCallback(
    (value) => {
      if (isExactIn) {
        setIsExactIn(false);
      }
      setTokenInput(value);
    },
    [setTokenInput, isExactIn, setIsExactIn]
  );

  const parsedFiatInput = useMemo(() => {
    if (isExactIn) {
      return fiatInput;
    }

    if (!tokenInput) {
      return "";
    }

    return new BigNumber(tokenInput)
      .multipliedBy(order?.order_unit_price)
      ?.toString();
  }, [isExactIn, fiatInput, tokenInput, order]);

  const parsedTokenInput = useMemo(() => {
    if (isExactIn && fiatInput) {
      return new BigNumber(fiatInput)
        .div(order?.order_unit_price)
        .toFixed(4)
        .toString();
    }

    return tokenInput;
  }, [isExactIn, order, tokenInput, fiatInput]);

  const minOrderLimit = useMemo(() => {
    if (!order?.curr_min_order_value) {
      return "0";
    }

    return order?.curr_min_order_value <= 0 ? 1 : order?.curr_min_order_value;
  }, [order]);

  const maxOrderLimit = useMemo(() => {
    if (!order?.curr_max_order_value) {
      return "0";
    }

    return order?.curr_max_order_value;
  }, [order]);

  const handleMax = useCallback(() => {
    if (isExactIn) {
      setIsExactIn(false);
    }

    let maxTokens = order?.pending_amount;

    if (
      new BigNumber(fromWei(order?.pending_amount, order?.token?.decimals))
        .multipliedBy(order?.order_unit_price)
        .gt(order?.max_order_value)
    ) {
      maxTokens = toWei(
        new BigNumber(order?.max_order_value).div(order?.order_unit_price),
        order?.token?.decimals
      );
    }
    setTokenInput(
      // order?.max_order_value

      fromWei(maxTokens, order?.token?.decimals)
    );
  }, [setTokenInput, isExactIn, setIsExactIn, order]);

  const tokenInputError = useMemo(() => {
    if (!parsedTokenInput) {
      return {
        status: true,
        message: `${intl.formatMessage({
          id: "Enter tokens",
          defaultMessage: "Enter tokens",
        })}`,
      };
    }

    if (new BigNumber(parsedTokenInput).lte(0)) {
      return {
        status: true,
        message: `${intl.formatMessage({
          id: "Enter tokens",
          defaultMessage: "Enter tokens",
        })}`,
      };
    }

    if (new BigNumber(parsedFiatInput).lt(minOrderLimit)) {
      return {
        status: true,
        message: `${intl.formatMessage({
          id: "Please enter more than min order Limit.",
          defaultMessage: "Please enter more than min order Limit.",
        })}`,
      };
    }
    // console.log("decimal test ", { parsedFiatInput, maxOrderLimit });
    if (new BigNumber(parsedFiatInput).gt(maxOrderLimit)) {
      return {
        status: true,
        message: `${intl.formatMessage({
          id: "Insufficient tokens! Please enter less than max order Limit.",
          defaultMessage:
            "Insufficient tokens! Please enter less than max order Limit.",
        })}`,
      };
    }

    return { status: false, message: "" };
  }, [parsedTokenInput, parsedFiatInput, minOrderLimit, maxOrderLimit]);

  const createTradeLoading = useSelector(
    (state) => state?.userTrade?.createTradeLoading
  );

  const { chainId } = useActiveWeb3React();

  const wallet = useSelector((state) => state?.wallet);

  const selectedTokenWallet = useMemo(() => {
    if (!wallet?.tokenBalances?.[selectedToken?._id]) {
      return {};
    }
    return wallet?.tokenBalances?.[selectedToken?._id];
  }, [selectedToken, wallet]);

  const availableDeposits = useMemo(() => {
    if (!selectedTokenWallet?.available_balance) {
      return "0";
    }
    return selectedTokenWallet?.available_balance;
  }, [selectedTokenWallet]);

  const depositsNeeded = useMemo(() => {
    const _tokenAmt = toWei(parsedTokenInput, selectedToken?.decimals);

    if (!_tokenAmt || !availableDeposits) {
      return "0";
    }

    if (new BigNumber(availableDeposits).gte(_tokenAmt)) {
      return "0";
    }

    const _depositNeeded = new BigNumber(_tokenAmt)
      .minus(availableDeposits)
      ?.toString();

    return _depositNeeded;
  }, [availableDeposits, parsedTokenInput, selectedToken]);

  const isSufficientDeposits = useMemo(() => {
    return new BigNumber(depositsNeeded).eq(0);
  }, [depositsNeeded]);

  const isConfirmBtnDisabled = useMemo(() => {
    if (tradeType === "sell") {
      return (
        !isSufficientDeposits ||
        tokenInputError?.status ||
        createTradeLoading ||
        !order?._id ||
        loading
      );
    }

    return (
      tokenInputError?.status || createTradeLoading || !order?._id || loading
    );
  }, [
    isSufficientDeposits,
    tradeType,
    tokenInputError,
    createTradeLoading,
    order,
    loading,
  ]);

  const handleTrade = useCallback(async () => {
    if (
      !tradeType ||
      !currentUserAuth ||
      !parsedTokenInput ||
      !parsedFiatInput
    ) {
      setAlertPopup((prev) => ({
        ...prev,
        open: true,
        message: "Missing input",
      }));
      return;
    }

    const tradeInput = {
      orderId: order?._id,
      tokenAmount: toWei(parsedTokenInput, order?.token?.decimals),
      fiatAmount: parsedFiatInput,
    };

    const requestBody = {
      order_id: tradeInput?.orderId,
      token_amount: tradeInput?.tokenAmount,
      fiat_amount: tradeInput?.fiatAmount,
      chainId: chainId,
      fiat: order.fiat._id,
    };
    setLoading(true);
    // console.log(requestBody);
    const result = await createTrade(
      tradeType,
      requestBody,
      userAccount,
      currentUserAuth
    );
    // console.log("result", result);

    setLoading(false);
    if (result?.status !== 201) {
      // todo handle error
      setAlertPopup((prev) => ({
        ...prev,
        open: true,
        message: result?.message?.msg,
        showSwitch:
          result?.message?.msg?.includes("chain") &&
          result?.message?.msg?.includes("switch"),
      }));
      return;
    }

    // update vault balance after joining sell order
    if (tradeType === "sell") {
      dispatch(fetchBalances(userAccount, chainId, currentUserAuth));
    }

    const tradeTrxId = result?.data?._id;

    navigate(`/order-waiting/${tradeTrxId}`);
  }, [
    currentUserAuth,
    userAccount,
    tradeType,
    order,
    parsedTokenInput,
    parsedFiatInput,
    setLoading,
    chainId,
    navigate,
    dispatch,
  ]);

  const handleOnCancelTrade = useCallback(() => {
    setFiatInput("");
    navigate(`/`);
  }, [setFiatInput, navigate]);

  const handleMaxToken = useCallback(
    (tokens) => {
      if (isExactIn) {
        setIsExactIn(false);
      }

      setTokenInput(tokens);
    },
    [setTokenInput, isExactIn, setIsExactIn]
  );
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box className={classes.background}>
      <GenericPopup
        open={alertPopup.open}
        message={alertPopup.message}
        showSwitch={alertPopup.showSwitch}
        title="Error"
        handleClose={() => setAlertPopup({ open: false, message: "" })}
      />
      <Container>
        <Box>
          <Box>
            <h4 variant="h4" color="textSecondary" className={classes.title}>
              <FormattedMessage
                id="Order Summary"
                defaultMessage="Order Summary"
              />
            </h4>
          </Box>
          <div className={classes.infoCard}>
            <Typography
              variant="body2"
              color={theme.palette.text.primary}
              fontSize={16}
              fontWeight={500}
            >
              <FormattedMessage
                id={tradeType?.toUpperCase()}
                defaultMessage={tradeType?.toUpperCase()}
              />{" "}
              {order?.token?.symbol}{" "}
              <FormattedMessage id="with" defaultMessage="with" />{" "}
              {order?.fiat?.fiat}
            </Typography>
            {order ? (
              <Grid container spacing={2} p={2}>
                <Grid
                  item
                  md={7}
                  style={{
                    borderRight: md
                      ? ""
                      : theme.palette.mode === "light"
                      ? "1px solid #e5e5e5"
                      : "1px solid #212446",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <Box mt={2}>
                        <Typography
                          className="d-flex flex-sm-row flex-column"
                          textAlign="left"
                          variant="body2"
                          fontSize={13}
                          color={
                            theme.palette.mode === "light"
                              ? "#778090"
                              : "#646892"
                          }
                        >
                          <span>
                            <FormattedMessage
                              id="Price"
                              defaultMessage="Price"
                            />
                            :
                          </span>
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              color:
                                tradeType === "buy" ? "#04A56D" : "#ef5350",
                              paddingLeft: sm ? 0 : 5,
                            }}
                          >
                            {formatCurrency(order?.order_unit_price, true)}
                            {order?.fiat?.fiat}
                          </span>
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Typography
                          className="d-flex flex-sm-row flex-column"
                          textAlign="left"
                          variant="body2"
                          fontSize={13}
                          color={
                            theme.palette.mode === "light"
                              ? "#778090"
                              : "#646892"
                          }
                        >
                          <span>
                            <FormattedMessage
                              id="Chain"
                              defaultMessage="Chain"
                            />
                            :
                          </span>

                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              paddingLeft: sm ? 0 : 5,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {CHAIN_IDS_TO_DISPLAY_NAMES?.[order?.chainId]}
                          </span>
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Typography
                          className="d-flex flex-sm-row flex-column"
                          textAlign="left"
                          variant="body2"
                          fontSize={13}
                          color={
                            theme.palette.mode === "light"
                              ? "#778090"
                              : "#646892"
                          }
                        >
                          <span>
                            <FormattedMessage
                              id="Payment Time Limit"
                              defaultMessage="Payment Time Limit"
                            />
                            :
                          </span>

                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              paddingLeft: sm ? 0 : 5,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {!order?.order_transfaction_duration
                              ? "60"
                              : toMinutes(order?.order_transfaction_duration)}
                          </span>
                        </Typography>
                      </Box>
                    </div>
                    <div className="col-md-6">
                      <Box mt={2}>
                        <Typography
                          className="d-flex flex-sm-row flex-column"
                          textAlign="left"
                          variant="body2"
                          fontSize={13}
                          color={
                            theme.palette.mode === "light"
                              ? "#778090"
                              : "#646892"
                          }
                        >
                          <span>
                            <FormattedMessage
                              id="Available"
                              defaultMessage="Available"
                            />
                            :
                          </span>

                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              paddingLeft: sm ? 0 : 5,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {formatCurrency(
                              fromWei(
                                order?.pending_amount,
                                order?.token?.decimals
                              ),
                              true
                            )}
                            {" " + order?.token?.symbol}
                          </span>
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography
                          textAlign="left"
                          variant="body2"
                          fontSize={13}
                          color={
                            theme.palette.mode === "light"
                              ? "#778090"
                              : "#646892"
                          }
                        >
                          <span style={{ paddingRight: 5 }}>
                            {tradeType === "buy" ? "Seller" : "Buyer"}
                            ’s{" "}
                            <FormattedMessage
                              id="payment method"
                              defaultMessage="payment method"
                            />
                            :
                          </span>
                          {order?.payment_options?.map(
                            (paymentOption, index) => (
                              <span
                                key={index}
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500,

                                  color: theme.palette.text.primary,
                                }}
                              >
                                {paymentOption?.toString()?.toUpperCase()}{" "}
                                {order.payment_options.length === index + 1
                                  ? ""
                                  : ","}
                              </span>
                            )
                          )}
                        </Typography>
                      </Box>{" "}
                    </div>
                  </div>
                  <div className="mt-5">
                    <Box mt={2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={theme.palette.text.primary}
                      >
                        {tradeType === "buy" ? (
                          <FormattedMessage
                            id="Seller's Message:"
                            defaultMessage="Seller's Message:"
                          />
                        ) : (
                          <FormattedMessage
                            id="Buyer's Message:"
                            defaultMessage="Buyer's Message:"
                          />
                        )}
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={13}
                        pt={1}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                        style={{ wordBreak: "break-word" }}
                      >
                        {/* Please mark your payment withing time limit by only
                        given payments method. */}
                        {order && order.description ? (
                          order.description
                        ) : (
                          <FormattedMessage
                            id="No message"
                            defaultMessage="No message"
                          />
                        )}
                      </Typography>
                    </Box>
                  </div>
                  <div className="mt-5">
                    <Box mt={2}>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        fontWeight={500}
                        color={theme.palette.text.primary}
                      >
                        <FormattedMessage
                          id="Terms and conditions"
                          defaultMessage="Terms and conditions"
                        />
                        :
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={13}
                        pt={1}
                        color={
                          theme.palette.mode === "light" ? "#778090" : "#646892"
                        }
                      >
                        <FormattedMessage
                          id="ordersummary_rule"
                          defaultMessage="DO NOT SEND PAYMENTS THROUGH THIRD PARTY ACCOUNTS, all
                        such payments will have to go to dispute and will be
                        refunded/cancelled. Please do not include crypto related
                        words such as P2P, PolkaBridge, USDT, ETH, BTC, etc."
                        />
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item md={5} width="inherit">
                  <Box mt={3}>
                    <Typography
                      textAlign="left"
                      variant="body2"
                      fontSize={15}
                      fontWeight={500}
                      color={
                        theme.palette.mode === "light" ? "#76808F" : "#646892"
                      }
                    >
                      {tradeType === "buy" ? (
                        <FormattedMessage
                          id="I want to buy for"
                          defaultMessage="I want to buy for"
                        />
                      ) : (
                        <FormattedMessage
                          id="I want to sell for"
                          defaultMessage="I want to sell for"
                        />
                      )}
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent="space-between"
                      alignItems={"center"}
                      mt={1}
                      style={{
                        border: "1px solid #bdbdbd",
                        borderRadius: 4,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                    >
                      <Input
                        fullWidth
                        disableUnderline
                        placeholder="0"
                        type="text"
                        value={formattedNumber(parsedFiatInput, 4)}
                        onChange={(e) => {
                          e.target.value = e.target.value
                            .replace(/,/g, "")
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");

                          onFiatInputChange(e.target.value);
                        }}
                      />
                      <Button
                        className={classes.buttonAction}
                        onClick={handleMax}
                        disabled={
                          !order?._id ||
                          parsedTokenInput ===
                            fromWei(
                              order?.pending_amount,
                              order?.token?.decimals
                            )
                        }
                      >
                        <FormattedMessage id="All" defaultMessage="All" />
                      </Button>
                      <span
                        style={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                        }}
                      >
                        {order?.fiat?.fiat}
                      </span>
                    </Box>
                  </Box>

                  <Box mt={3}>
                    <Typography
                      textAlign="left"
                      variant="body2"
                      fontSize={14}
                      fontWeight={500}
                      color={theme.palette.text.primary}
                    >
                      <FormattedMessage id="Limit" defaultMessage="Limit" />:{" "}
                      {minOrderLimit !== maxOrderLimit && (
                        <span>
                          {formatCurrency(minOrderLimit, true)}
                          <span
                            style={{
                              paddingLeft: 4,
                            }}
                          >
                            {order?.fiat?.fiat_label} -
                          </span>
                        </span>
                      )}
                      {formatCurrency(maxOrderLimit, true)}
                      <span
                        style={{
                          paddingLeft: 4,
                        }}
                      >
                        {order?.fiat?.fiat_label}
                      </span>
                    </Typography>
                  </Box>

                  <Box mt={3}>
                    <Typography
                      textAlign="left"
                      variant="body2"
                      fontSize={15}
                      fontWeight={500}
                      color={
                        theme.palette.mode === "light" ? "#76808F" : "#646892"
                      }
                    >
                      {tradeType === "buy" ? (
                        <FormattedMessage
                          id="I will get:"
                          defaultMessage="I will get:"
                        />
                      ) : (
                        <FormattedMessage
                          id="I will send:"
                          defaultMessage="I will send:"
                        />
                      )}
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      mt={1}
                      style={{
                        border: "1px solid #bdbdbd",
                        borderRadius: 4,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Input
                          fullWidth
                          type="text"
                          error={tokenInputError.status}
                          disableUnderline
                          placeholder="0"
                          onChange={(e) => {
                            e.target.value = e.target.value
                              .replace(/,/g, "")
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1");

                            onTokenInputChange(e.target.value);
                          }}
                          value={formattedNumber(parsedTokenInput, 4)}
                        />

                        <img
                          src={selectedToken?.logo}
                          style={{
                            height: 20,
                            width: 20,
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                          alt=""
                        />
                        <span
                          style={{
                            color: theme.palette.text.primary,
                            fontWeight: 500,
                          }}
                        >
                          {selectedToken?.symbol}
                        </span>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        {tradeType === "sell" && (
                          <Box display={"flex"} alignItems="center">
                            <UserBalance
                              inputTokens={parsedTokenInput}
                              selectedToken={{
                                ...selectedToken,
                                wallet_id: selectedTokenWallet?._id,
                                available_balance:
                                  selectedTokenWallet?.available_balance,
                              }}
                              handleMaxToken={handleMaxToken}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {tokenInputError?.status && (
                      <div>
                        <Typography
                          textAlign="left"
                          variant="body2"
                          fontSize={13}
                          fontWeight={500}
                          color={"#e57373"}
                          pt={1}
                        >
                          * {tokenInputError.message}
                        </Typography>
                      </div>
                    )}
                  </Box>

                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      style={{
                        borderRadius: 7,
                        background:
                          theme.palette.mode === "light"
                            ? "#F5F5F5"
                            : "#646892",
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                        padding: md ? "10px 30px" : "10px 20px",
                        marginRight: 5,
                      }}
                      onClick={handleOnCancelTrade}
                    >
                      <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                    </Button>

                    <Button
                      onClick={handleTrade}
                      style={{
                        borderRadius: 7,
                        background: isConfirmBtnDisabled
                          ? "#bdbdbd"
                          : "#6A55EA",

                        color: "white",
                        padding: md ? "10px 30px" : "10px 20px",
                        width: "100%",
                        marginLeft: 5,
                      }}
                      disabled={isConfirmBtnDisabled}
                    >
                      <FormattedMessage
                        id={tradeType}
                        defaultMessage={tradeType}
                      />{" "}
                      {order?.token?.symbol}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <FormattedMessage id="Loading" defaultMessage="Loading" />
            )}
          </div>
          <HowItWorks />
        </Box>
      </Container>
    </Box>
  );
}

export default OrderSummary;
