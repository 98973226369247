import { Box, Divider, useTheme } from "@mui/material";
import React from "react";
import Pagination from "../../../../common/Pagination";
import QuestEndedCard from "./QuestEndedCard";

const QuestsEndedCardList = ({
  requests,
  loading,
  page,
  totalPages,
  changePage,
}) => {
  const theme = useTheme();

  return (
    <Box
      px={0.1}
      py={2}
      mb={1}
      borderRadius={4}
      style={{
        backgroundColor:
          theme.palette.mode === "light"
            ? "#ffffff"
            : theme.palette.background.highlight,
      }}
    >
      {requests?.map((request, index) => {
        return (
          <React.Fragment key={index}>
            <QuestEndedCard request={request} index={index} />
            {index !== requests.length - 1 && (
              <Divider variant="fullwidth" sx={{ color: "#bdbdbd" }} />
            )}
          </React.Fragment>
        );
      })}
      {totalPages > 1 && (
        <Pagination
          page={page}
          changePage={changePage}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};

export default QuestsEndedCardList;
