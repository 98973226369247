export const WALLET_SUPORTED_ON_CHAINS = [
  1, 4, 5, 1285, 1287, 97, 56, 137, 80001, 1666700000, 1666600000,
];

export const IS_MAINNET =
  process.env.REACT_APP_DEPLOYMENT === "mainnet" ? true : false;
export const FALLBACK_DEFAULT_CHAIN =
  process.env.REACT_APP_DEPLOYMENT === "mainnet" ? 1 : 5; // Todo change this for release
export const DAPP_SUPPORTED_ON_CHAINS =
  process.env.REACT_APP_DEPLOYMENT === "mainnet" ? [1, 56] : [5, 97];
export const FALLBACK_DEFAULT_FIAT = "INR";

export const ALLOWANCE_AMOUNT = "999999999";

export const OTP_EXPIRE_TIME = 0.1; // IN MINUTES

export const WETH_ADDRESS = {
  1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  4: "0xc778417e063141139fce010982780140aa0cd5ab",
  5: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
  56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  97: "0xae13d989dac2f0debff460ac112a837c89baa7cd", // bnb for bsc chain
};

// claim PolkaBridgeFaucet contract Addresses
export const FAUCET_ADDRESSES: { [index: number]: string } = {
  1: "",
  5: "0x08114Eda0FD31B2D71586260Eb01DB064E1e8624",
  1981: "0x08114Eda0FD31B2D71586260Eb01DB064E1e8624",
  56: "",
  97: "0x252F9d11d193820997D38298346B381f53a54B5e",
};

export const P2P_ADDRESSES: { [index: number]: string } = {
  1: "0xf88761d220E77c035192D24e9aed376d10597F55",
  4: "0x55C4F673BAb13F3Ae1aBe6B8Fd3b3e83A70B8691",
  5: "0x6cf3Df5D00E842Aab1eB504Bcf01EBF81646F7B7",
  56: "0xf88761d220E77c035192D24e9aed376d10597F55",
  97: "0x91A3D9EC3491dE844d57235F74D344F947bCADA4",
};

export const CONNECTOR_TYPE = {
  injected: "injected",
  walletConnect: "walletConnect",
  unstoppable: "unstoppable",
};
export const STABLE_COINS = ["tether", "binance-usd", "usd-coin", "dai"];

export const NATIVE_TOKENS = {
  1: {
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    address: "0x0000000000000000000000000000000000001010",
    chainId: 5,
  },
  4: {
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    address: "",
    chainId: 5,
  },
  5: {
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    address: "",
    chainId: 5,
  },
  56: {
    name: "Binance Token",
    decimals: 18,
    symbol: "BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    chainId: 56,
  },
  97: {
    name: "Binance Token",
    decimals: 18,
    symbol: "BNB",
    address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    chainId: 97,
  },
};

export const GLOBAL_FIAT_LIST = [
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6263a50d54f64766e549a621",
    country_name: "United States",
    fiat: "USD",
    fiat_label: "$",
    flag_url: "images/USD.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6267e54c3c805016884e50f9",
    country_name: "India",
    fiat: "INR",
    fiat_label: "₹",
    flag_url: "images/INR.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5ac",
    country_name: "Europe",
    fiat: "EUR",
    fiat_label: "€",
    flag_url: "images/EUR.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6396fe4483bdfc44191cb688",
    country_name: "VietNam",
    fiat: "VND",
    fiat_label: "₫",
    flag_url: "images/VND.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5ab",
    country_name: "Turkey",
    fiat: "TRY",
    fiat_label: "₺",
    flag_url: "images/TRY.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5b1",
    country_name: "Nigeria",
    fiat: "NGN",
    fiat_label: "₦",
    flag_url: "images/NGN.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5b0",
    country_name: "Indonesia",
    fiat: "IDR",
    fiat_label: "Rp",
    flag_url: "images/IDR.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5ad",
    country_name: "Russia",
    fiat: "RUB",
    fiat_label: "₽",
    flag_url: "images/RUB.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5ae",
    country_name: "China",
    fiat: "CNY",
    fiat_label: "¥",
    flag_url: "images/CNY.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63241f9c13699c459924a5af",
    country_name: "Japan",
    fiat: "JPY",
    fiat_label: "¥",
    flag_url: "images/JPY.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6397022c83bdfc44191cb68d",
    country_name: "South African",
    fiat: "ZAR",
    fiat_label: "R",
    flag_url: "images/ZAR.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6397046583bdfc44191cb68f",
    country_name: "Venezuela",
    fiat: "VES",
    fiat_label: "Bs",
    flag_url: "images/VES.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6397054583bdfc44191cb691",
    country_name: "Argentina",
    fiat: "ARS",
    fiat_label: "ARS$",
    flag_url: "images/ARS.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6397064a83bdfc44191cb693",
    country_name: "UAE",
    fiat: "AED",
    fiat_label: "د.إ",
    flag_url: "images/AED.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "639706da83bdfc44191cb695",
    country_name: "Colombia",
    fiat: "COP",
    fiat_label: "COL$",
    flag_url: "images/COP.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "6397076e83bdfc44191cb697",
    country_name: "Egypt",
    fiat: "EGP",
    fiat_label: "E£",
    flag_url: "images/EGP.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "639709a983bdfc44191cb699",
    country_name: "England",
    fiat: "GBP",
    fiat_label: "£",
    flag_url: "images/GBP.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970a7383bdfc44191cb69b",
    country_name: "Ghana",
    fiat: "GHS",
    fiat_label: "GH¢",
    flag_url: "images/GHS.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970af183bdfc44191cb69d",
    country_name: "Kazakhstan",
    fiat: "KZT",
    fiat_label: "〒",
    flag_url: "images/KZT.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970b8683bdfc44191cb69f",
    country_name: "Philippines",
    fiat: "PHP",
    fiat_label: "₱",
    flag_url: "images/PHP.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970c0683bdfc44191cb6a1",
    country_name: "Pakistan",
    fiat: "PKR",
    fiat_label: "₨",
    flag_url: "images/PKR.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970c7483bdfc44191cb6a3",
    country_name: "Brazil",
    fiat: "BRL",
    fiat_label: "R$",
    flag_url: "images/BRL.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970d1a83bdfc44191cb6a5",
    country_name: "Saudi Arabia",
    fiat: "SAR",
    fiat_label: "ر.س",
    flag_url: "images/SAR.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970db483bdfc44191cb6a7",
    country_name: "Ukraina",
    fiat: "UAH",
    fiat_label: "₴",
    flag_url: "images/UAH.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970e2c83bdfc44191cb6a9",
    country_name: "Bangladesh",
    fiat: "BDT",
    fiat_label: "Tk.",
    flag_url: "images/BDT.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63970e8283bdfc44191cb6ab",
    country_name: "Taiwan",
    fiat: "TWD",
    fiat_label: "$",
    flag_url: "images/TWD.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63c2b0424b6a95d916a9daf2",
    country_name: "Peru",
    fiat: "PEN",
    fiat_label: "S/.",
    flag_url: "images/PEN.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75982cf1418a6df352377",
    country_name: "New Zealand",
    fiat: "NZD",
    fiat_label: "$",
    flag_url: "images/NZD.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75a32cf1418a6df352378",
    country_name: "Australia",
    fiat: "AUD",
    fiat_label: "A$",
    flag_url: "images/AUD.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75a9ecf1418a6df352379",
    country_name: "Canada",
    fiat: "CAD",
    fiat_label: "C$",
    flag_url: "images/CAD.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75b2ecf1418a6df35237a",
    country_name: "Thailand",
    fiat: "THB",
    fiat_label: "฿",
    flag_url: "images/THB.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75d51cf1418a6df35237b",
    country_name: "West African",
    fiat: "XOF",
    fiat_label: "FCFA",
    flag_url: "images/XOF.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75ddacf1418a6df35237c",
    country_name: "Central African",
    fiat: "XAF",
    fiat_label: "Fr",
    flag_url: "images/XAF.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75e96cf1418a6df35237d",
    country_name: "Uzbekistan",
    fiat: "UZS",
    fiat_label: "so'm",
    flag_url: "images/UZS.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75f12cf1418a6df35237e",
    country_name: "Uruguay ",
    fiat: "UYU",
    fiat_label: "$U",
    flag_url: "images/UYU.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e75f9ccf1418a6df35237f",
    country_name: "Quatar",
    fiat: "QAR",
    fiat_label: "ر.ق",
    flag_url: "images/QAR.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e7603acf1418a6df352380",
    country_name: "Hong Kong",
    fiat: "HKD",
    fiat_label: "$",
    flag_url: "images/HKD.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e760bdcf1418a6df352381",
    country_name: "Myanmar",
    fiat: "MYR",
    fiat_label: "RM",
    flag_url: "images/MYR.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e76148cf1418a6df352382",
    country_name: "Singapore",
    fiat: "SGD",
    fiat_label: "S$",
    flag_url: "images/SGD.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e76418cf1418a6df352383",
    country_name: "Mexico",
    fiat: "MXN",
    fiat_label: "Mex$",
    flag_url: "images/MXN.png",
  },

  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e7649acf1418a6df352384",
    country_name: "Czech Republic",
    fiat: "CZK",
    fiat_label: "Kč",
    flag_url: "images/CZK.png",
  },
  {
    added_at: "2022-06-20T11:43:48.690Z",
    _id: "63e76578cf1418a6df352385",
    country_name: "Romania",
    fiat: "RON",
    fiat_label: "lei",
    flag_url: "images/RON.png",
  },
];

//mapping of country iso code to local currency
export const COUNTRY_FIAT = {
  BD: "BDT",
  BE: "EUR",
  BF: "XOF",
  BG: "BGN",
  BA: "BAM",
  BB: "BBD",
  WF: "XPF",
  BL: "EUR",
  BM: "BMD",
  BN: "BND",
  BO: "BOB",
  BH: "BHD",
  BI: "BIF",
  BJ: "XOF",
  BT: "BTN",
  JM: "JMD",
  BV: "NOK",
  BW: "BWP",
  WS: "WST",
  BQ: "USD",
  BR: "BRL",
  BS: "BSD",
  JE: "GBP",
  BY: "BYR",
  BZ: "BZD",
  RU: "RUB",
  RW: "RWF",
  RS: "RSD",
  TL: "USD",
  RE: "EUR",
  TM: "TMT",
  TJ: "TJS",
  RO: "RON",
  TK: "NZD",
  GW: "XOF",
  GU: "USD",
  GT: "GTQ",
  GS: "GBP",
  GR: "EUR",
  GQ: "XAF",
  GP: "EUR",
  JP: "JPY",
  GY: "GYD",
  GG: "GBP",
  GF: "EUR",
  GE: "GEL",
  GD: "XCD",
  GB: "GBP",
  GA: "XAF",
  SV: "USD",
  GN: "GNF",
  GM: "GMD",
  GL: "DKK",
  GI: "GIP",
  GH: "GHS",
  OM: "OMR",
  TN: "TND",
  JO: "JOD",
  HR: "HRK",
  HT: "HTG",
  HU: "HUF",
  HK: "HKD",
  HN: "HNL",
  HM: "AUD",
  VE: "VEF",
  PR: "USD",
  PS: "ILS",
  PW: "USD",
  PT: "EUR",
  SJ: "NOK",
  PY: "PYG",
  IQ: "IQD",
  PA: "PAB",
  PF: "XPF",
  PG: "PGK",
  PE: "PEN",
  PK: "PKR",
  PH: "PHP",
  PN: "NZD",
  PL: "PLN",
  PM: "EUR",
  ZM: "ZMK",
  EH: "MAD",
  EE: "EUR",
  EG: "EGP",
  ZA: "ZAR",
  EC: "USD",
  IT: "EUR",
  VN: "VND",
  SB: "SBD",
  ET: "ETB",
  SO: "SOS",
  ZW: "ZWL",
  SA: "SAR",
  ES: "EUR",
  ER: "ERN",
  ME: "EUR",
  MD: "MDL",
  MG: "MGA",
  MF: "EUR",
  MA: "MAD",
  MC: "EUR",
  UZ: "UZS",
  MM: "MMK",
  ML: "XOF",
  MO: "MOP",
  MN: "MNT",
  MH: "USD",
  MK: "MKD",
  MU: "MUR",
  MT: "EUR",
  MW: "MWK",
  MV: "MVR",
  MQ: "EUR",
  MP: "USD",
  MS: "XCD",
  MR: "MRO",
  IM: "GBP",
  UG: "UGX",
  TZ: "TZS",
  MY: "MYR",
  MX: "MXN",
  IL: "ILS",
  FR: "EUR",
  IO: "USD",
  SH: "SHP",
  FI: "EUR",
  FJ: "FJD",
  FK: "FKP",
  FM: "USD",
  FO: "DKK",
  NI: "NIO",
  NL: "EUR",
  NO: "NOK",
  NA: "NAD",
  VU: "VUV",
  NC: "XPF",
  NE: "XOF",
  NF: "AUD",
  NG: "NGN",
  NZ: "NZD",
  NP: "NPR",
  NR: "AUD",
  NU: "NZD",
  CK: "NZD",
  XK: "EUR",
  CI: "XOF",
  CH: "CHF",
  CO: "COP",
  CN: "CNY",
  CM: "XAF",
  CL: "CLP",
  CC: "AUD",
  CA: "CAD",
  CG: "XAF",
  CF: "XAF",
  CD: "CDF",
  CZ: "CZK",
  CY: "EUR",
  CX: "AUD",
  CR: "CRC",
  CW: "ANG",
  CV: "CVE",
  CU: "CUP",
  SZ: "SZL",
  SY: "SYP",
  SX: "ANG",
  KG: "KGS",
  KE: "KES",
  SS: "SSP",
  SR: "SRD",
  KI: "AUD",
  KH: "KHR",
  KN: "XCD",
  KM: "KMF",
  ST: "STD",
  SK: "EUR",
  KR: "KRW",
  SI: "EUR",
  KP: "KPW",
  KW: "KWD",
  SN: "XOF",
  SM: "EUR",
  SL: "SLL",
  SC: "SCR",
  KZ: "KZT",
  KY: "KYD",
  SG: "SGD",
  SE: "SEK",
  SD: "SDG",
  DO: "DOP",
  DM: "XCD",
  DJ: "DJF",
  DK: "DKK",
  VG: "USD",
  DE: "EUR",
  YE: "YER",
  DZ: "DZD",
  US: "USD",
  UY: "UYU",
  YT: "EUR",
  UM: "USD",
  LB: "LBP",
  LC: "XCD",
  LA: "LAK",
  TV: "AUD",
  TW: "TWD",
  TT: "TTD",
  TR: "TRY",
  LK: "LKR",
  LI: "CHF",
  LV: "EUR",
  TO: "TOP",
  LT: "LTL",
  LU: "EUR",
  LR: "LRD",
  LS: "LSL",
  TH: "THB",
  TF: "EUR",
  TG: "XOF",
  TD: "XAF",
  TC: "USD",
  LY: "LYD",
  VA: "EUR",
  VC: "XCD",
  AE: "AED",
  AD: "EUR",
  AG: "XCD",
  AF: "AFN",
  AI: "XCD",
  VI: "USD",
  IS: "ISK",
  IR: "IRR",
  AM: "AMD",
  AL: "ALL",
  AO: "AOA",
  AQ: "",
  AS: "USD",
  AR: "ARS",
  AU: "AUD",
  AT: "EUR",
  AW: "AWG",
  IN: "INR",
  AX: "EUR",
  AZ: "AZN",
  IE: "EUR",
  ID: "IDR",
  UA: "UAH",
  QA: "QAR",
  MZ: "MZN",
};

// mongodb document snapshot
export const GLOBAL_TOKEN_LIST: { [index: number]: Array<any> } = {
  1: [
    {
      _id: "6410277821ed6dcd56b03cb0",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Polkabridge",
      symbol: "PBR",
      address: "0x298d492e8c1d909d3f63bc4a36c66c64acb3d695",
      chainId: 1,
      decimals: 18,
      __v: 0,
      logo: "https://assets.coingecko.com/coins/images/13744/small/symbol-whitebg200x200.png?1611377553",
      active: true,
    },
    {
      _id: "6410281321ed6dcd56b03cb1",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "US Tether",
      symbol: "USDT",
      address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      decimals: 6,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707",
      chainId: 1,
    },
    {
      _id: "64102bd021ed6dcd56b03cb2",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Ethereum",
      symbol: "ETH",
      address: "",
      decimals: 18,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      chainId: 1,
    },
    {
      _id: "64102c1021ed6dcd56b03cb3",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "USD Token",
      symbol: "USDC",
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      decimals: 6,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
      chainId: 1,
    },
    {
      _id: "64102c8121ed6dcd56b03cb4",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x6b175474e89094c44da98b954eedeac495271d0f",
      decimals: 18,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
      chainId: 1,
    },
  ],
  5: [
    {
      _id: "633ab83318b3d7e397763a49",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "PolkaBridge",
      symbol: "PBR",
      address: "0x89f01bc9865324583380d8d7ed08b8046bffd7fd",
      decimals: 18,
      __v: 0,
      active: true,
      chainId: 5,
      logo: "https://assets.coingecko.com/coins/images/13744/small/symbol-whitebg200x200.png?1611377553",
    },
    {
      _id: "633ab84818b3d7e397763a4a",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Tether",
      symbol: "USDT",
      address: "0x1cfd6813a59d7b90c41dd5990ed99c3bf2eb8f55",
      decimals: 6,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707",
      chainId: 5,
    },
    {
      _id: "633ab86f18b3d7e397763a4b",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Ethereum",
      symbol: "ETH",
      address: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
      decimals: 18,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
      chainId: 5,
    },
    {
      _id: "63d8dce13097826148d32d08",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "USD Coin",
      symbol: "USDC",
      address: "0xa98AaE59Ca251cD9ca1b931AAa4c3242C2015dDa",
      decimals: 6,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
      chainId: 5,
    },
    {
      _id: "63d8dd8e3097826148d32d09",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Dai",
      symbol: "DAI",
      address: "0xCD38874f05FC2672bBDB4e2b62B56Da38c63F586",
      decimals: 18,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
      chainId: 5,
    },
  ],
  56: [
    {
      _id: "6410222321ed6dcd56b03cac",
      added_at: {
        $date: {
          $numberLong: "1650697032957",
        },
      },
      name: "BNB Token",
      symbol: "BNB",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      chainId: 56,
      decimals: 18,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png?1600947313",
    },
    {
      _id: "6410272821ed6dcd56b03cae",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      chainId: 56,
      decimals: 18,
      __v: 0,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
      active: true,
    },
    {
      _id: "6410276021ed6dcd56b03caf",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Polkabridge",
      symbol: "PBR",
      address: "0x1D1cB8997570e73949930c01Fe5796C88d7336c6",
      chainId: 56,
      decimals: 18,
      __v: 0,
      logo: "https://assets.coingecko.com/coins/images/13744/small/symbol-whitebg200x200.png?1611377553",
      active: true,
    },
    {
      _id: "6410268a21ed6dcd56b03cad",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "USDT Token",
      symbol: "USDT",
      address: "0x55d398326f99059ff775485246999027b3197955",
      chainId: 56,
      decimals: 18,
      __v: 0,
      logo: "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663",
      active: true,
    },
  ],
  97: [
    {
      added_at: "2022-04-23T06:57:12.957Z",
      _id: "6263a34c3f3536307ddd741a",
      name: "BNB",
      symbol: "BNB",
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      chainId: 97,
      decimals: 18,
      __v: 0,
      active: true,
      logo: "https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png?1600947313",
    },
    {
      added_at: "2022-04-23T06:59:34.052Z",
      _id: "6263a3e538fd8c30a7c4d8b8",
      name: "Binance USD",
      symbol: "BUSD",
      address: "0x84a73728582591a1aa90a25a07f4f636331d6c1e",
      chainId: 97,
      decimals: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
      active: true,
      __v: 0,
    },
    {
      added_at: "2022-04-23T06:59:34.052Z",
      _id: "63246e6eaa980102011a42d1",
      name: "PolkaBridge",
      symbol: "PBR",
      address: "0xcdc22234e41a94ef3c028e0208b0f55cb63a3008",
      chainId: 97,
      decimals: 18,
      __v: 0,
      logo: "https://assets.coingecko.com/coins/images/13744/small/symbol-whitebg200x200.png?1611377553",
      active: true,
    },
    {
      _id: "63d8de3e3097826148d32d0a",
      added_at: {
        $date: {
          $numberLong: "1650697174052",
        },
      },
      name: "Tether",
      symbol: "USDT",
      address: "0x93Ea92B4258533eD3E031b3FF84a6222a2514867",
      chainId: 97,
      decimals: 18,
      __v: 0,
      logo: "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663",
      active: true,
    },
  ],
};

export const TOKEN_SYMBOL_BASED_ON_CHAIN = {
  1: {
    PBR: "PBR-ETH",
    USDT: "USDT-ETH",
    ETH: "ETH",
    USDC: "USDC",
    DAI: "DAI",
  },
  4: {
    PBR: "PBR-ETH",
    USDT: "USDT-ETH",
    ETH: "ETH",
    USDC: "USDC",
    DAI: "DAI",
  },
  5: {
    PBR: "PBR-ETH",
    USDT: "USDT-ETH",
    ETH: "ETH",
    USDC: "USDC",
    DAI: "DAI",
  },
  56: {
    BNB: "BNB",
    BUSD: "BUSD",
    USDT: "USDT-BSC",
    PBR: "PBR-BSC",
  },
  97: {
    BNB: "BNB",
    BUSD: "BUSD",
    USDT: "USDT-BSC",
    PBR: "PBR-BSC",
  },
};

export const TOKEN_FETCH_ID = {
  PBR: "polkabridge",
  USDT: "tether",
  BUSD: "binance-usd",
  ETH: "ethereum",
  BNB: "binancecoin",
  USDC: "usd-coin",
  DAI: "dai",
};

export const TRADE_EVENTS = {
  buyer_joined: "buyer_joined",
  seller_joined: "seller_joined",
  seller_transfered: "seller_transfered",
  buyer_paid: "buyer_paid",
  buyer_proof: "buyer_proof",
  notify_buyer: "notify_buyer",
  notify_seller: "notify_buyer",
  buyer_cancelled: "buyer_cancelled",
  seller_cancelled: "seller_cancelled",
};

export const TIME_TAGS = [
  {
    display_time: "1 day",
    unix_time: 86400000, // seconds in 1 day
  },
  {
    display_time: "3 days",
    unix_time: 259200000, // seconds in 3 days
  },
  {
    display_time: "7 days",
    unix_time: 604800000,
  },
  {
    display_time: "15 days",
    unix_time: 1296000000,
  },
  {
    display_time: "30 days",
    unix_time: 2592000000,
  },
  {
    display_time: "3 months",
    unix_time: 7776000000,
  },
  {
    display_time: "6 months",
    unix_time: 15552000000,
  },
];
export const TOKEN_LOGO_PATH = {
  PBR: "/images/logos/tokens/PBR.png",
  USDT: "/images/logos/tokens/USDT.svg",
  ETH: "/images/logos/tokens/ETH.svg",
  BUSD: "https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png?1600947313",
  BNB: "https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png?1600947313",
  USDC: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
  DAI: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
};

export const FIAT_LOGO_PATH = {
  USD: "/images/USD.png",
  INR: "/images/INR.png",
  EUR: "/images/EUR.png",
  VND: "/images/VND.png",
  TRY: "/images/TRY.png",
  NGN: "/images/NGN.png",
  IDR: "/images/IDR.png",
  RUB: "/images/RUB.png",
  CNY: "/images/CNY.png",
  JPY: "/images/JPY.png",
  ZAR: "/images/ZAR.png",
  VES: "/images/VES.png",
  ARS: "/images/ARS.png",
  AED: "/images/AED.png",
  COP: "/images/COP.png",
  EGP: "/images/EGP.png",
  GBP: "/images/GBP.png",
  GHS: "/images/GHS.png",
  KZT: "/images/KZT.png",
  PHP: "/images/PHP.png",
  PKR: "/images/PKR.png",
  BRL: "/images/BRL.png",
  SAR: "/images/SAR.png",
  UAH: "/images/UAH.png",
  BDT: "/images/BDT.png",
  TWD: "/images/TWD.png",
};

export const ROLES = {
  GUEST: "GUEST",
  AUTHENTICATED: "AUTHENTICATED",
  ADMIN: "ADMIN",
  CONSUMER: "CONSUMER",
  SUPERADMIN: "SUPERADMIN",
  COLLABORATOR: "COLLABORATOR",
};

export const BUYER_DISPUTE_TIME_LIMIT = 5 * 60; // 5 minutes
export const BUYER_NOTIFY_SELLER_TIME_LIMIT = 3 * 60; // 3 minutes

export const IMAGE_TYPES = [
  "jpeg",
  "JPEG",
  "png",
  "PNG",
  "webp",
  "WEBP",
  "jpg",
  "JPG",
  "gif",
  "GIF",
];

export const DISPUTE_STATUS = {
  in_review: "in_review",
  pending: "pending",
  resolved: "resolved",
  cancelled: "cancelled",
};

export const LANGUAGES = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "vi",
    label: "Tiếng Việt",
  },
  {
    value: "zh",
    label: "简体中文",
  },
  {
    value: "ja",
    label: "日本語",
  },
  {
    value: "fr",
    label: "Français",
  },
  {
    value: "es",
    label: "Español",
  },
  {
    value: "tr",
    label: "Türkçe",
  },
  {
    value: "pt",
    label: "Português",
  },
];

export const AUTHENTICATION_STATE = {
  NOT_STARTED: "NOT_STARTED",
  OPEN_POPUP: "OPEN_POPUP",
  NETWORK_SWITCH_REQUEST: "NETWORK_SWITCH_REQUEST",
  NETWORK_SWITCH_FAILED: "NETWORK_SWITCH_FAILED",
  CONNECTING_WALLET: "CONNECTING_WALLET",
  WALLET_CONNECTION_FAILED: "WALLET_CONNECTION_FAILED",
  WALLET_CONNECTED: "WALLET_CONNECTED",
  REQUESTING_SIGNATURE: "REQUESTING_SIGNATURE",
  SIGNATURE_REQUEST_FAILED: "SIGNATURE_REQUEST_FAILED",
  VALIDATING_SIGNATURE: "VALIDATING_SIGNATURE",
  SIGNATURE_VALIDATION_FAILED: "SIGNATURE_VALIDATION_FAILED",
  AUTHENTICATION_SUCCESS: "AUTHENTICATION_SUCCESS",
};
