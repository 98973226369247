import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import messages from "localization";
import { FormattedMessage } from "react-intl";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// snackbar error and success interface
// openSnackBar:  message, isError: true/false, hash,
export default function AlertPopup({
  open,
  message,
  severity,
  handlePopupClose,
  hash = null,
}) {
  //check if message has a translation first
  const alertMsg = React.useMemo(() => {
    message = typeof message === "object" ? message.msg : message;
    console.log(message);
    if (messages["en"].hasOwnProperty(message)) {
      return <FormattedMessage id={message} defaultMessage={message} />;
    } else {
      return message;
    }
  }, [message]);
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handlePopupClose}
      >
        <Alert
          onClose={handlePopupClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
