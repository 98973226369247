import React, { useMemo } from "react";
import Countdown from "react-countdown";

export default function CountDownTime({
  createdAt,
  onComplete,
  isCompleted,
  color,
  font = 16,
  timeWindow = 1 * 60 * 60,
}) {
  const CompletedContent = () => <span>00:00</span>;
  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <CompletedContent />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const currentExpireTime = useMemo(() => {
    const expiryWindowInMinutes = timeWindow ? timeWindow / 60 : 60;
    const expiredTime = new Date(createdAt);
    expiredTime.setMinutes(expiredTime.getMinutes() + expiryWindowInMinutes);

    return expiredTime;
  }, [timeWindow, createdAt]);

  return (
    <>
      {createdAt && (
        <Countdown
          date={Math.floor(new Date(currentExpireTime).getTime())}
          onComplete={onComplete}
          renderer={renderer}
        />
      )}
    </>
  );
}
