export const globalHeaders = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

export const globalHeadersAdmin = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

export const BASE_API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? process.env?.REACT_APP_BACKEND_URI_DEV
    : process.env.REACT_APP_BACKEND_URI_PROD;

export const BASE_SITE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://p2p.polkabridge.org";

export * from "./orderCalls";
export * from "./userCalls";
