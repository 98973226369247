import { Box, Button, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { formatTime, getAddEtherscanUrl } from "utils/helper";
import RequestView from "./RequestView";
import React from "react";

const QuestEndedCard = ({ request, index }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box p={1} mb={1} borderRadius={10}>
      {/* top section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Box>
          <Typography
            className="pt-1"
            style={{
              color: "#989898",
              fontWeight: 500,
              fontSize: 14,
              textAlign: "left",
            }}
          >
            <FormattedMessage id="Status" defaultMessage="Status" /> :
          </Typography>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 22,
              textAlign: "left",
            }}
            color={
              request.status === "pending"
                ? "#F9C74F"
                : request?.status === "completed"
                ? theme.palette.action.green
                : theme.palette.action.red
            }
          >
            <FormattedMessage
              id={request?.status}
              defaultMessage={request?.status}
            />
          </Typography>
        </Box>
        <Box className="d-flex flex-column justify-content-end align-items-start">
          <Typography
            className="pt-1"
            style={{
              color: "#989898",
              fontWeight: 400,
              fontSize: 12,
              textAlign: "left",
            }}
          >
            <FormattedMessage id="Redeem Time" defaultMessage="Redeem Time" /> :
          </Typography>
          <Typography
            style={{
              color: theme.palette.text.primary,
              fontWeight: 500,
              fontSize: 10,
              textAlign: "left",
            }}
          >
            {formatTime(request?.redeemed_at).substring(
              formatTime(request?.redeemed_at).indexOf("M") + 1,
              formatTime(request?.redeemed_at).length
            )}{" "}
            |{" "}
            {formatTime(request?.redeemed_at).substring(
              0,
              formatTime(request?.redeemed_at).indexOf("M") + 1
            )}
          </Typography>
        </Box>
      </Box>
      {/* bottom section */}
      <Box display="flex" justifyContent="space-between">
        <Box paddingRight={2}>
          {/* userAddress */}
          <Box display="flex" justifyContent="space-between">
            <Typography
              style={{
                color: "#989898",
                fontWeight: 400,
                fontSize: 14,
                textAlign: "left",
                marginRight: 1,
              }}
            >
              <FormattedMessage id="Account" defaultMessage="Account" /> :
            </Typography>
            <Typography
              textAlign="center"
              variant="body2"
              fontSize={14}
              color={"#212121"}
              alignSelf="end"
              className="d-flex flex-column"
              ml={1}
            >
              <a
                component="button"
                href={getAddEtherscanUrl(request?.account, 5)}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                {request?.account?.slice(0, 5)}
                {"..."}
                {request?.account?.slice(
                  request?.account?.length - 5,
                  request?.account?.length
                )}
              </a>
            </Typography>
          </Box>
          {/* points*/}
          <Box display="flex" justifyContent="space-between">
            <Typography
              style={{
                color: "#989898",
                fontWeight: 400,
                fontSize: 14,
                textAlign: "left",
                marginRight: 1,
              }}
            >
              <FormattedMessage id="Points" defaultMessage="Points" /> :
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={400}
              textAlign={"left"}
              color={theme.palette.text.primary}
              ml={1}
            >
              {request?.points}
            </Typography>
          </Box>
        </Box>

        <Box className="d-flex flex-column justify-content-center">
          <Button
            onClick={handleOpen}
            variant="outlined"
            size="small"
            style={{
              borderRadius: 14,
              fontSize: 12,
              textTransform: "none",
              margin: 1,
              marginLeft: 5,
            }}
          >
            View
          </Button>

          <RequestView
            popupActive={open}
            handleClose={handleClose}
            request={request}
            style={{ overflow: "scroll" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QuestEndedCard;
