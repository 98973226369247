import {
  FETCH_TOKEN_BALANCE,
  FETCH_TOKEN_BALANCES,
  GET_LATEST_WITHDRAW,
  GET_WITHDRAW_QUOTE,
  WALLET_ERROR,
  WALLET_LOADING,
} from "../actions/types";

const initalState = {
  loading: false,
  error: null,
  tokenBalances: {}, // tokenAddress: balanceObject
  pendingWithdrawal: {}, // tokenAddress: true/false
  withdrawQuote: {},
  latestWithdrawTxn: {},
};

export default function walletReducer(state = initalState, action) {
  // todo design and write action types and state updates
  switch (action.type) {
    case FETCH_TOKEN_BALANCES:
      let payload = {};
      const balances = action?.payload;
      balances.forEach((element) => {
        payload[element?.token?._id] = element;
      });

      return {
        ...state,
        tokenBalances: payload,
        loading: false,
        error: null,
      };
    case FETCH_TOKEN_BALANCE:
      const vaultItem = action?.payload;
      const updatePayload = state?.tokenBalances;
      updatePayload[vaultItem?.token?._id] = vaultItem;

      return {
        ...state,
        tokenBalances: updatePayload,
        loading: false,
        error: null,
      };
    case GET_WITHDRAW_QUOTE:
      const withdrawItem = action?.payload;
      const withdrawPayload = state?.withdrawQuote;
      withdrawPayload[withdrawItem?.token?._id] = withdrawItem;
      return {
        ...state,
        withdrawQuote: withdrawPayload,
        loading: false,
        error: null,
      };
    case GET_LATEST_WITHDRAW:
      return {
        ...state,
        loading: false,
        latestWithdrawTxn: [...action.payload],
      };
    case WALLET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case WALLET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
