import "./App.css";
import React, { Suspense } from "react";
import { Fragment } from "react";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Appbar from "./common/Appbar";
import { Container } from "@mui/material";

import MyOrders from "./pages/MyOrders/MyOrders";
import CreateOrder from "./pages/Orders/CreateOrder/CreateOrder";
import OrderPlaced from "./pages/Orders/CreateOrder/OrderPlaced";
import Footer from "./common/Footer";
import OrderSummary from "./pages/Orders/OrderTransaction/OrderSummary";
import OrderWaiting from "./pages/Orders/OrderTransaction/OrderWaiting";
import ViewMyOrder from "./pages/MyOrders/ViewMyOrder";
import Faucet from "./pages/Faucet";
import { IntlProvider } from "react-intl";
import { updateUserOnlineStatus } from "./utils/httpCalls";
import { useDispatch, useSelector } from "react-redux";
import setBodyColor from "setBodyColor";
import getDesignTokens from "./theme";
import BanAlert from "./common/BanAlert";
import messages from "localization";
import Quest from "./pages/Quest/Quest";
import ScrollToTop from "./common/ScrollToTop";
import Joyride from "react-joyride";
import { desktopSteps, mobileSteps, stepStyles } from "./utils/steps";
import { updateLastLogin } from "./actions/profileActions";
import { UPDATE_TOUR } from "./actions/types";
import { isNonIOSPhone } from "./utils/userAgent";
import { IS_MAINNET } from "./constants";

const PermissionRequestRenderer = React.lazy(() =>
  import("./PermissionRequestRenderer")
);

const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const Leaderboard = React.lazy(() => import("./pages/Leaderboard/Leaderboard"));
const WalletPage = React.lazy(() => import("./pages/Wallet/WalletPage"));
const Support = React.lazy(() => import("./pages/Support/Support"));
const AdvertiserDetail = React.lazy(() =>
  import("./pages/AdvertiserDetail/AdvertiserDetail")
);
const Admin = React.lazy(() => import("./pages/Admin/Admin"));
const AdminLogin = React.lazy(() => import("./pages/Admin/AdminLogin"));

const pathname = window.location.pathname;

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App() {
  const authenticatedUser = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  //check at start
  const currentAppVersion = process.env.REACT_APP_VERSION;
  if (localStorage.getItem(`app_version`) !== currentAppVersion) {
    console.log("clearing storage");
    localStorage.clear();
    localStorage.setItem(`app_version`, currentAppVersion);
  }

  const [mode, setMode] = React.useState(
    pathname?.includes("admin")
      ? "light"
      : localStorage.getItem("last_saved_mode")
      ? localStorage.getItem("last_saved_mode")
      : "light"
  );

  const [locale, setLocale] = React.useState(
    localStorage.getItem("locale") || "en"
  );
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [startTour, setStartTour] = React.useState(false);

  const changeLocale = (locale) => {
    setLocale(locale);
    localStorage.setItem("locale", locale);
  };

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  setBodyColor(
    pathname?.includes("admin")
      ? "light"
      : localStorage.getItem("last_saved_mode")
      ? localStorage.getItem("last_saved_mode")
      : "light"
  );

  // theme = responsiveFontSizes(theme);
  const md = window.matchMedia("(max-width:900px)").matches;
  //tour handlers
  const handleTour = (state) => {
    if (
      (state.action === "skip" || state.status === "finished") &&
      state.lifecycle === "complete"
    ) {
      if (!authenticatedUser.authenticated) {
        setStartTour(false);
        return;
      }
      setSidebarOpen(false);
      setStartTour(false);
      dispatch({
        type: UPDATE_TOUR,
        payload: true,
      });
      dispatch(
        updateLastLogin(
          { tour_done: true },
          authenticatedUser?.account,
          localStorage.getItem(
            `${authenticatedUser?.account}_${authenticatedUser?.chainId}`
          )
        )
      );
    }

    if (state.step.target === ".makeStyles-buttonWrapper-29")
      setSidebarOpen(false);
  };
  //open side bar for tour guide
  React.useEffect(() => {
    if (!authenticatedUser?.authenticated) {
      return;
    }

    if (
      authenticatedUser.authenticated &&
      !authenticatedUser?.tour_done &&
      pathname === "/"
    ) {
      if (md && authenticatedUser.authenticated) {
        setSidebarOpen(true);
        setStartTour(true);
      } else {
        setStartTour(true);
      }
    }
  }, [authenticatedUser?.authenticated, authenticatedUser.tour_done]);

  React.useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          registration.unregister();

          navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then(function (registration) {
              console.log(
                "Service worker registered with scope :>>",
                registration.scope
              );
            })
            .catch(function (err) {
              console.log("Service worker registration failed :>>", err);
            });
        })
        .catch(function (err) {
          console.log("Service worker not ready :>>", err);
        });
    }
  }, []);

  React.useEffect(() => {
    if (!authenticatedUser?.jwtToken || !authenticatedUser?.account) {
      return;
    }

    updateUserOnlineStatus(
      authenticatedUser?.account,
      authenticatedUser.jwtToken,
      {
        online: true,
      }
    ).then((res) => {
      // console.log("Start of Lifecycle :", res);
    });

    window.addEventListener("beforeunload", (e) => {
      updateUserOnlineStatus(
        authenticatedUser?.account,
        authenticatedUser.jwtToken,
        {
          online: false,
        }
      ).then((res) => {
        // console.log("End of Lifecycle :", res);
      });
    });

    return () => {
      window.removeEventListener("beforeunload", (e) => {
        updateUserOnlineStatus(
          authenticatedUser?.account,
          authenticatedUser.jwtToken,
          {
            online: false,
          }
        ).then((res) => {
          // console.log("End of Lifecycle :", res);
        });
      });
    };
  }, [authenticatedUser.authenticated, authenticatedUser.jwtToken]);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Fragment>
            <Router>
              <ScrollToTop />
              {authenticatedUser.authenticated && pathname === "/" && (
                <Joyride
                  steps={md ? mobileSteps : desktopSteps}
                  callback={handleTour}
                  continuous={true}
                  run={startTour}
                  disableOverlayClose={false}
                  disableScrollParentFix={true}
                  disableScrolling={true}
                  showSkipButton={true}
                  hideCloseButton={true}
                  styles={stepStyles}
                  floaterProps={{ disableAnimation: true }}
                />
              )}
              <Suspense fallback={null}>
                {isNonIOSPhone && <PermissionRequestRenderer />}
              </Suspense>
              {!pathname?.includes("admin") && (
                <div className="pb-5">
                  <Appbar
                    locale={locale}
                    changeLocale={changeLocale}
                    open={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                </div>
              )}

              {/* show ban alert */}
              {authenticatedUser?.authError?.blacklisted && <BanAlert />}

              <Routes>
                <Route
                  path="/"
                  element={<Home setSidebarOpen={setSidebarOpen} />}
                />

                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/profile/history"
                  element={<Profile defaultPage={2} />}
                />
                <Route path="/order/:order_id" element={<OrderSummary />} />
                <Route
                  path="/order-waiting/:trade_id"
                  element={<OrderWaiting />}
                />
                <Route path="/support" element={<Support />} />
                <Route path="/create-order" element={<CreateOrder />} />
                <Route
                  path="/order-placed/:order_id"
                  element={<OrderPlaced />}
                />
                <Route path="/my-orders" element={<MyOrders />} />
                <Route path="/my-orders/:order_id" element={<ViewMyOrder />} />
                <Route path="/wallet" element={<WalletPage />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/faucet" element={<Faucet />} />
                <Route
                  path="/advertiser-detail/:advertiserId"
                  element={<AdvertiserDetail />}
                />
                {/* TODO:change when starting round 2 */}
                {!IS_MAINNET && <Route path="/quest" element={<Quest />} />}
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/disputes" element={<Admin />} />
                <Route path="/admin/tickets" element={<Admin />} />
                <Route path="/admin/users" element={<Admin />} />
                <Route path="/admin/ads" element={<Admin />} />
                <Route path="/admin/orders" element={<Admin />} />
                <Route path="/admin/admins" element={<Admin />} />
                <Route path="/admin/transactions" element={<Admin />} />
                <Route path="/admin/review-transactions" element={<Admin />} />
                <Route path="/admin/quest-redeem" element={<Admin />} />
              </Routes>
              {!pathname?.includes("admin") && (
                <Container>
                  <Footer />
                </Container>
              )}
            </Router>
          </Fragment>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </IntlProvider>
  );
}

export default App;
