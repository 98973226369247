const ENGLISH = {
  Mode: "Mode",
  Feedback: "Feedback",
  Home: "Home",
  "Create Order": "Create Order",
  "My Orders": "My Orders",
  Wallet: "Wallet",
  Profile: "Profile",
  Leaderboard: "Leaderboard",
  Faucet: "Faucet",
  Banned: "Banned",
  "This account has been banned. If this is unexpected, please contact":
    "This account has been banned. If this is unexpected, please contact",
  footermessage:
    "Join 100K+ people working together to make the world a better place.",
  "Copyright 2023 By": "Copyright 2023 By",
  "All Rights Reserved": "All Rights Reserved",
  "How P2P Works": "How P2P Works",
  home_howitwork:
    "PolkaBridge P2P helps you convert your money to crypto instantly where PolkaBridge acts as an escrow for safekeeping of the transaction.",
  Buy: "Buy",
  Sell: "Sell",
  "STEP 1": "STEP 1",
  buy_step1:
    "Choose the ad with desired price and payment method. Enter your buy quantity and transaction amount to complete the order.",
  "Deposit tokens into": "Deposit tokens into",
  wallet: "wallet",
  "and create orders": "and create orders",
  "STEP 2": "STEP 2",
  buy_step2:
    "Complete your payments based on information provided by the seller.",
  step2_sell: "Confirm that you've received the full payments from the buyer.",
  "STEP 3": "STEP 3",

  step3_sell:
    "Release coins to the buyer only after you've received their payments in full.",
  "Before you start trading you need to update your profile.":
    "Before you start trading you need to update your profile.",
  "Before your start trading please verify your phone":
    "Before your start trading please verify your phone",
  "Please add a payment method to start buy crypto":
    "Please add a payment method to start buy crypto",
  "Update now": "Update now",
  "Verify now": "Verify now",
  "Add now": "Add now",
  Logout: "Logout",
  "Switching...": "Switching...",
  Switch: "Switch",
  "Switch Network": "Switch Network",
  "Cancel dispute": "Cancel dispute",
  canceldisputewarn:
    "There will be no option to raise dispute again. Please do NOT cancel if your issue is not resolved.",
  "I confirm I have recieved the money from seller":
    "I confirm I have recieved the money from seller",
  "Cancelling...": "Cancelling...",
  "Cancel Trade": "Cancel Trade",
  Warning: "Warning",
  warningcancel:
    "There will be no refund after cancelling the order. Please do NOT cancel if you have already transferred money.",
  tradewarning:
    "Trading will be suspended for the day if one cancelled 10 transactions in a day.",
  "I confirm I have not paid the payee.":
    "I confirm I have not paid the payee.",
  "Please wait...": "Please wait...",
  "Cancel this trade": "Cancel this trade",
  "Confirm Transfer": "Confirm Transfer",
  confirmnote:
    "P2P transactions require that you make payment or transfer in a method that is supported by the receiving party. PolkaBridge does not automatically deduct from your account.",
  "Transfer Amount": "Transfer Amount",
  "In exchange for": "In exchange for",
  "Upload proof of payment": "Upload proof of payment",
  "Confirm the account of the receiving party":
    "Confirm the account of the receiving party",
  "Payment QR code": "Payment QR code",
  confirmnote2:
    "Please complete your orders as soon as possible so that the receiving party may confirm your transfer. Please select the account of the receiving party. Fraudulent payment confirmation will likely cause your account to be frozen",
  "I didn't Pay Yet": "I didn't Pay Yet",
  "Transfer Completed": "Transfer Completed",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "Deposit tokens into PolkaBridge vault and trade hassle free",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "Withdraw  tokens from PolkaBridge vault with fee",
  Amount: "Amount",
  "Select Max": "Select Max",
  Balance: "Balance",
  "Available Amount": "Available Amount",
  "Withdrawal Fee": "Withdrawal Fee",
  "In order": "In order",
  View: "View",
  "Send OTP": "Send OTP",
  "Verify Phone": "Verify Phone",
  "Verify Email": "Verify Email",
  "Your phone number has been verified": "Your phone number has been verified",
  "Your email address has been verified":
    "Your email address has been verified",
  "6 digit otp has been sent on your phone":
    "6 digit otp has been sent on your phone",
  emailsent:
    "6 digit verification code sent on your email. If you can not see the email in your inbox, make sure to check your SPAM folder",
  "Verify OTP": "Verify OTP",
  "OTP expired": "OTP expired",
  "Resend OTP": "Resend OTP",
  "Your OTP will expire in": "Your OTP will expire in",
  "Confirm and Release": "Confirm and Release",
  Tips: "Tips",
  dontnotcheckbuyerpayment:
    "Do not only check the buyer's payment proof. Make sure that you login to your account and verify that the payment has been received.",
  ifstillprocessing:
    "If the payment is still processing, wait until you have received payment in to your account before releasing the crypto!",
  donotacceptthridparty:
    "Do NOT accept payment from a third-party account. Refund the full amount immediately if you receive such payment to avoid financial losses caused by bank chargeback after you have released the crypto.",
  "Confirm User Account and Release Amount":
    "Confirm User Account and Release Amount",
  iconfirmpaymentcorrect:
    "I confirm that the payment has been credited to my account and the available balance is correct.",
  "Releasing...": "Releasing...",
  Order: "Order",
  "is successfully completed": "is successfully completed",
  Submitted: "Submitted",
  Submit: "Submit",
  "Click below for processing": "Click below for processing",
  "Waiting for confirmation": "Waiting for confirmation",
  "Please confirm the transaction into your metamask popup":
    "Please confirm the transaction into your metamask popup",
  "Withdrawal Request Submitted": "Withdrawal Request Submitted",
  "Withdrawal Address": "Withdrawal Address",
  Back: "Back",
  "Withdrawal History": "Withdrawal History",
  "Deposit Processing": "Deposit Processing",
  depositapproval:
    "The approval for deposit has been submitted, please be patient. Do not initiate more deposits while the deposit process is ongoing.",
  "Deposits History": "Deposits History",
  "Transaction Submitted": "Transaction Submitted",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "Transaction has been submitted and waiting for the confirmation from blockchain.",
  "View Status": "View Status",
  "Transaction Successful!": "Transaction Successful!",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "Great! Transaction has been confirmed at the blockchain Successfully.",
  "Transaction Failed!": "Transaction Failed!",
  "We have encountered an error in the transaction. Please try again.":
    "We have encountered an error in the transaction. Please try again.",
  "Sign Message": "Sign Message",
  "Connect Wallet": "Connect Wallet",
  "Waiting to connect": "Waiting to connect",
  "Confirm this connection in your wallet":
    "Confirm this connection in your wallet",
  walletconnect_rule:
    "By connecting a wallet, you agree to Polkabridge P2P's Terms of Service and consent to its Privacy Policy.",
  "Please sign this message requested in your metamask":
    "Please sign this message requested in your metamask",

  Metamask: "Metamask",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "Next testnet version coming!",
  "Follow Us": "Follow Us",
  "Claim Faucet": "Claim Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "Claim test tokens for Goerli Testnet or BSC Testnet.",
  "Unauthorised Access!": "Unauthorised Access!",
  "Please connect your wallet first to continue P2P trading.":
    "Please connect your wallet first to continue P2P trading.",
  Price: "Price",
  Limit: "Limit",
  Following: "Following",
  Unfollow: "Unfollow",
  Follow: "Follow",
  Rating: "Rating",
  "Online Ads": "Online Ads",
  "All Feedback": "All Feedback",
  "Volume in": "Volume in",
  "Decentralized P2P Exchange, powered by ":
    "Decentralized P2P Exchange, powered by ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "Experience first decentralized P2P trading with PolkaBridge",
  Fiat: "Fiat",
  All: "All",
  Token: "Token",
  "Payment Mode": "Payment Mode",
  orders: "orders",
  completion: "completion",
  BUY: "BUY",
  SELL: "SELL",
  Verify: "Verify",
  "Crypto Amount": "Crypto Amount",
  "Fiat Amount": "Fiat Amount",
  "Market Open Orders": "Market Open Orders",
  Advertisers: "Advertisers",
  "Available | Limit": "Available | Limit",
  Payment: "Payment",
  Trade: "Trade",
  "Exclusive ranking for Polkabridge Traders":
    "Exclusive ranking for Polkabridge Traders",
  Volume: "Volume",
  Followers: "Followers",
  "Avg. Pay Time": "Avg. Pay Time",
  "Avg. Release Time": "Avg. Release Time",
  Username: "Username",
  Filter: "Filter",
  Clear: "Clear",
  User: "User",
  "Last Trade Time": "Last Trade Time",
  Action: "Action",
  "Unauthorised Access": "Unauthorised Access",
  Apply: "Apply",
  "Order No.": "Order No.",
  Coin: "Coin",
  Type: "Type",
  Status: "Status",
  Time: "Time",
  Operation: "Operation",
  "Crypto amount": "Crypto amount",
  "Fiat amount": "Fiat amount",
  "Order type": "Order type",
  "Order Details": "Order Details",
  "Order Number": "Order Number",
  "Time created": "Time created",
  "Buy Order": "Buy Order",
  Executed: "Executed",
  at: "at",
  "Total Fiat Amount": "Total Fiat Amount",
  "Crypto Unit Price": "Crypto Unit Price",
  "Min Order Limit": "Min Order Limit",
  "Max Order Limit": "Max Order Limit",
  "Trade between": "Trade between",
  "Payment Time Limit": "Payment Time Limit",
  Remark: "Remark",
  "Need Help": "Need Help",
  "Please wait": "Please wait",
  "Cancel Order": "Cancel Order",
  "Go To Home": "Go To Home",
  "Initiate New P2P Order": "Initiate New P2P Order",
  "Set type and price": "Set type and price",
  "Review Your Order": "Review Your Order",
  "I want to": "I want to",
  "Order Limit": "Order Limit",
  Min: "Min",
  Max: "Max",
  "Payment Methods": "Payment Methods",
  "Add payments": "Add payments",
  "Current Price": "Current Price",
  "Highest Price": "Highest Price",
  "Lowest Price": "Lowest Price",
  "Please note that if you decide to": "Please note that if you decide to",
  SUBMIT: "SUBMIT",
  "the order, you wouldn't be able to": "the order, you wouldn't be able to",
  EDIT: "EDIT",
  "it afterwards.": "it afterwards.",
  "Review New P2P Order": "Review New P2P Order",
  "Review your order": "Review your order",
  Remarks: "Remarks",
  Cancel: "Cancel",
  "Submit Order": "Submit Order",
  "Order Submitted Successfully": "Order Submitted Successfully",
  "Order Type": "Order Type",
  Total: "Total",
  "Check your order": "Check your order",
  Deposit: "Deposit",
  "to fulfill this sell order": "to fulfill this sell order",
  with: "with",
  "Time remaining": "Time remaining",
  "Confirm Order Info": "Confirm Order Info",
  "Quantity (After fee)": "Quantity (After fee)",
  "Chat with": "Chat with",
  Uploading: "Uploading",
  "Upload Proof of Payment": "Upload Proof of Payment",
  Browse: "Browse",
  "Trade cancelled": "Trade cancelled",
  "Transfer Payment to seller": "Transfer Payment to seller",
  "Seller released tokens": "Seller released tokens",
  "Pending seller confirmation": "Pending seller confirmation",
  Completed: "Completed",
  "Token Deposited": "Token Deposited",
  "Deposit tokens to": "Deposit tokens to",
  reserve: "reserve",
  "Buyer confirmed": "Buyer confirmed",
  "Pending buyer confirmation": "Pending buyer confirmation",
  "Order Summary": "Order Summary",
  Available: "Available",
  "payment method": "payment method",
  "Seller's Message:": "Seller's Message:",
  "Buyer's Message:": "Buyer's Message:",
  "No message": "No message",
  "Terms and conditions": "Terms and conditions",
  ordersummary_rule:
    "DO NOT SEND PAYMENTS THROUGH THIRD PARTY ACCOUNTS, all such payments will have to go to dispute and will be refunded/cancelled. Please do not include crypto related words such as P2P, PolkaBridge, USDT, ETH, BTC, etc.",
  "I want to buy for": "I want to buy for",
  "I want to sell for": "I want to sell for",
  "I will get:": "I will get:",
  "I will send:": "I will send:",
  Loading: "Loading",
  "Order Processing": "Order Processing",
  "Reference message": "Reference message",
  "QR Code": "QR Code",
  "Payment confirmed": "Payment confirmed",
  "I've Paid. Confirm": "I've Paid. Confirm",
  Cancelled: "Cancelled",
  Name: "Name",
  Email: "Email",
  Quantity: "Quantity",
  Your: "Your",
  "has been reserved in PolkaBridge vault":
    "has been reserved in PolkaBridge vault",
  "Token reserved": "Token reserved",
  "Waiting for buyer's confirmation": "Waiting for buyer's confirmation",
  "Buyer confirmed payment": "Buyer confirmed payment",
  "Buyer notified": "Buyer notified",
  "Notify buyer": "Notify buyer",
  "Cancel trade": "Cancel trade",
  "Dispute submitted": "Dispute submitted",
  Reason: "Reason",
  "Release tokens to buyer wallet": "Release tokens to buyer wallet",
  "Token released": "Token released",
  "Release tokens": "Release tokens",
  "Your order is executed successfully.":
    "Your order is executed successfully.",
  "My Profile": "My Profile",
  "Update your preference for smooth trading experience.":
    "Update your preference for smooth trading experience.",
  Menu: "Menu",
  Payments: "Payments",
  "Transaction history": "Transaction history",
  Settings: "Settings",
  Referral: "Referral",
  "Filter Order": "Filter Order",
  TxnId: "TxnId",
  "Transaction not found": "Transaction not found",
  "Wallet unavailable": "Wallet unavailable",
  "Check explorer": "Check explorer",
  "Full Name": "Full Name",
  Mobile: "Mobile",
  "Preferred Fiat": "Preferred Fiat",
  "Edit profile": "Edit profile",
  "Upload Avatar": "Upload Avatar",
  "Updating...": "Updating...",
  "Update profile": "Update profile",
  "Are you sure, want to leave PolkaBridge?":
    "Are you sure, want to leave PolkaBridge?",
  "Log out": "Log out",
  "Fiat Preference": "Fiat Preference",
  Referrals: "Referrals",
  "Will be available soon.": "Will be available soon.",
  "Payment Options": "Payment Options",
  "Transaction History": "Transaction History",
  Fee: "Fee",
  TxnID: "TxnID",
  "All payment methods were added": "All payment methods were added",
  "Add Payment Method": "Add Payment Method",
  "Payment method": "Payment method",
  "QR Code (optional)": "QR Code (optional)",
  "Upload QR Code": "Upload QR Code",
  "Update details": "Update details",
  "Not Uploaded": "Not Uploaded",
  Edit: "Edit",
  "Update Payment": "Update Payment",
  Confirm: "Confirm",
  "Depositing...": "Depositing...",
  "Confirming...": "Confirming...",
  Withdraw: "Withdraw",
  Locked: "Locked",
  "P2P Wallet": "P2P Wallet",
  walletpage_message:
    "Manage assets on your P2P platform. You can Deposit, Withdraw assets to participate in transactions on P2P",
  "Your Balances": "Your Balances",
  "Buy Amount": "Buy Amount",
  "Sell Amount": "Sell Amount",
  "Trade(s)": "Trade(s)",
  active: "active",
  "Executed at": "Executed at",
  Chain: "Chain",
  Quest: "Quest",
  "Complete your quests and collect points.":
    "Complete your quests and collect points.",
  "Total Points": "Total Points",
  Redeem: "Redeem",
  "Resets in": "Resets in",
  "Daily Quests": "Daily Quests",
  "Weekly Quests": "Weekly Quests",
  Rules: "Rules",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1. Daily Task : The task will be refreshed daily at 00:00 UTC.",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.",
  "3. 1 point value equal to 1 PBR.": "3. 1 point value equal to 1 PBR.",
  "4. Redeem points limit is 100 Points.":
    "4. Redeem points limit is 100 Points.",
  "5. Submit your redeem request, admin will review and transfer token in your wallet.":
    "5. Submit your redeem request, admin will review and transfer token in your wallet.",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.",
  Points: "Points",
  Claiming: "Claiming",
  Claimed: "Claimed",
  Claim: "Claim",
  Action: "Action",
  "Redeem Points": "Redeem Points",
  "1 Points = 1 PBR": "1 Points = 1 PBR",
  "Redeem Points limit is 100 Points": "Redeem Points limit is 100 Points",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "Submit your redeem request admin will review and transfer token in your wallet.",
  Tokens: "Tokens",
  "Wallet Address": "Wallet Address",
  "Confirm your wallet address and submit your request.":
    "Confirm your wallet address and submit your request.",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "Completed at least 20 orders in BSC Chain within 7 consecutive days.",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "Deposit and withdraw within 7 consecutive days in both chains.",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB) in 7 days.",
  "Give at least 10 feedback .": "Give at least 10 feedback .",
  "Create at least 1 Ad on your FIAT currency":
    "Create at least 1 Ad on your FIAT currency.",
  "Complete at least 2 orders (both BUY and SELL) in Ethereum Chain":
    "Complete at least 2 orders (both BUY and SELL) in Ethereum Chain",
  "Complete at least 2 orders (both BUY and SELL) in BSC Chain":
    "Complete at least 2 orders (both BUY and SELL) in BSC Chain",
  "Deposit or withdraw at least 1 token on Ethereum chain":
    "Deposit or withdraw at least 1 token on Ethereum chain",
  "Deposit or withdraw at least 1 token on BSC chain":
    "Deposit or withdraw at least 1 token on BSC chain",
  "Deposit at least 0.1 ETH or 0.1 BNB.":
    "Deposit at least 0.1 ETH or 0.1 BNB.",
  "My Ads": "My Ads",
  Active: "Active",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "Complete at least 1 BUY order and 1 SELL order in BSC Chain",
  "Hi, how can we help you?": "Hi, how can we help you?",
  Support: "Support",
  "Raise Ticket": "Raise Ticket",
  "Watch Tutorial": "Watch Tutorial",
  "Video Tutorial": "Video Tutorial",
  Open: "Open",
  Official: "Official",
  "Email Us": "Email Us",
  Issues: "Issues",
  Description: "Description",
  "Upload Proof": "Upload Proof",
  "Drag and drop the file, or": "Drag and drop the file, or",
  "Enter the description": "Enter the description",
  General: "General",
  "Deposit/Withdraw": "Deposit/Withdraw",
  Account: "Account",
  Trading: "Trading",
  Others: "Others",
  Telegram: "Telegram",
  "Online/Offline": "Online/Offline",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.",
  "Ticket History": "Ticket History",
  Date: "Date",
  "Ticket ID": "Ticket ID",
  Issue: "Issue",
  Reviewing: "Reviewing",
  Resolved: "Resolved",
  Pending: "Pending",
  "Show Online Users": "Show Online Users",
  "Tokens Locked": "Tokens Locked",
  "Payment Done": "Payment Done",
  "In Dispute": "In Dispute",
  Date: "Date",
  Ascending: "Ascending",
  Descending: "Descending",
  Authenticating: "Authenticating",
  "Admin Remark": "Admin Remark",
  Message: "Message",
  "Price Ascending": "Price Ascending",
  "Price Descending": "Price Descending",
  "Latest Ads": "Latest Ads",
  "Latest Orders": "Latest Orders",
  "Completed Trades": "Completed Trades",
  "User Guide": "User Guide",
  "Beginner's Guide": "Beginner's Guide",
  "Video Tutorial": "Video Tutorial",
  "P2P trading FAQ": "P2P trading FAQ",
  "Review Submitted Successfully": "Review Submitted Successfully",
  "Transaction Hash": "Transaction Hash",
  "Ticket Details": "Ticket Details",
  Ended: "Ended",
  "Round 1 Ended": "Round 1 Ended",
  "Redeem your points before Round 2 starts":
    "Redeem your points before Round 2 starts",
  "Redeem History": "Redeem History",
  "Redeem Time": "Redeem Time",
  Address: "Address",
  pending: "pending",
  completed: "completed",
  rejected: "rejected",
  "Redeemed At": "Redeemed At",
  "Redeem Detail": "Redeem Detail",
  "Transaction ID": "Transaction ID",
  "Admin Remark": "Admin Remark",
  rejected: "rejected",
  "Profile Tab": "Profile Tab",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.",
  "Quest Tab": "Quest Tab",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.",
  "LeaderBoard Tab": "LeaderBoard Tab",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "Click to see exclusive ranking for Polkabridge Traders.",
  "Buy Vs Sell": "Buy Vs Sell",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.",
  "Filter Tool": "Filter Tool",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.",
  "Advertiser Info": "Advertiser Info",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.",
  "Price and Limits": "Price and Limits",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.",
  "Payment options": "Payment options",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.",
  "Lowest Fees": "Lowest Fees",
  "Buy crypto with lowest fees ever!": "Buy crypto with lowest fees ever!",
  "P2P Wallet": "P2P Wallet",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "Click to check your balance and to manage deposits and withdrawals of your assets and their history.",
  Next: "Next",
  Skip: "Skip",
  Close: "Close",
  "Create Order Tab": "Create Order Tab",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.",
  "Switch Network": "Switch Network",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.",
  "24h Trading Volume": "24h Trading Volume",
  "24h Total Orders": "24h Total Orders",
  "Show Users Following": "Show Users Following",
  Following: "Following",
  Online: "Online",
  "Please redeem your points before 24th March, 00:00 AM UTC.":
    "Please redeem your points before 24th March, 00:00 AM UTC.",
  "User's Statistics": "Statistics",
  Phone: "Phone",
  Follower: "Follower",
  "All Trades": "All Trades",
  "Total Buy Orders": "Total Buy Orders",
  "Total Sell Orders": "Total Sell Orders",
  Share: "Share",
  "Reopen Ticket": "Reopen Ticket",
  Comment: "Comment",
  "Add your comment": "Add your comment",
  "Comment must be less than 5000 characters":
    "Comment must be less than 5000 characters",
  "Comment is required": "Comment is required",
  "Reopen Date": "Reopen Date",
  Proof: "Proof",
  "Share your P2P profile": "Share your P2P profile",
  Sort: "Sort",
  "Select Token": "Select Token",
  "Select Fiat": "Select Fiat",
  "Date Range": "Date Range",
  "Enter username": "Enter username",
  "Trade Currency": "Trade Currency",
  "Enter your message for seller": "Enter your message for seller",
  "Approve network switch in your wallet":
    "Approve network switch in your wallet",
  "Deposit Token": "Deposit Token",
  "Withdraw Token": "Withdraw Token",
  "Insufficient!": "Insufficient!",
  Wait: "Wait",
  "Insufficient funds!": "Insufficient funds!",
  "Insufficient withdraw!": "Insufficient withdraw!",
  Approve: "Approve",
  "Approving...": "Approving...",
  "Issues with Deposit?": "Issues with Deposit?",
  "All Networks": "All Networks",
  "Enter amount": "Enter amount",
  "Enter price": "Enter price",
  "Select payment methods": "Select payment methods",
  "Enter order min and max limits": "Enter order min and max limits",
  "Enter valid min limit": "Enter valid min limit",
  "Max limit must be less than": "Max limit must be less than",
  "Min limit must be less than": "Min limit must be less than",
  "Preview order": "Preview order",
  min: "min",
  Global: "Global",
  "Enter your name": "Enter your name",
  "Choose your username": "Choose your username",
  "Enter your email": "Enter your email",
  "Your Full Name": "Your Full Name",
  "A/c Number": "A/c Number",
  "Account number": "Account number",
  "Bank Name": "Bank Name",
  "IFSC Code": "IFSC Code",
  "Note (Optional)": "Note (Optional)",
  "Additional Notes": "Additional Notes",
  "Phone Number": "Phone Number",
  "Account phone number": "Account phone number",
  "Payment QR Code (Optional)": "Payment QR Code (Optional)",
  "Please enter your QR Code": "Please enter your QR code",
  Day: "Day",
  Week: "Week",
  Month: "Month",
  Months: "Months",
  hash: "hash",
  "Enter txn hash": "Enter txn hash",
  Success: "Success",
  Failed: "Failed",
  Review: "Review",
  Dropped: "Dropped",
  LongPending: "LongPending",
  "Redeem Ended": "Redeem Ended",
  "Please enter your bank account number":
    "Please enter your bank account number",
  "Bank Name": "Bank Name",
  "Enter the name of your bank": "Enter the name of your bank",
  "Account opening branch(Optional)": "Account opening branch(Optional)",
  "Enter bank branch information": "Enter bank branch information",
  "Paypal ID / Number": "Paypal ID / Number",
  "Perfect Money ID / Number": "Perfect Money ID / Number",
  "Bank Details (Optional)": "Bank Details (Optional)",
  "Please enter your bank details": "Please enter your bank details",
  "Country of Residence (Optional)": "Country of Residence (Optional)",
  "Please enter your country of residence":
    "Please enter your country of residence",
  "Username (Optional)": "Username (Optional)",
  "Account Username": "Account Username",
  "Account Email": "Account Email",
  "Wallet ID": "Wallet ID",
  "Revtag (Optional)": "Revtag (Optional)",
  "Please enter your Revtag": "Please enter your Revtag",
  "Phone number (Optional)": "Phone number (Optional)",
  "Please enter payment details": "Please enter payment details",
  "Account number (USD)": "Account number (USD)",
  "Account number (KHR)(Optional)": "Account number (KHR)(Optional)",
  "Payeer account number": "Payeer account number",
  "Enter your Payeer account number": "Enter your Payeer account number",
  "Email (Optional)": "Email (Optional)",
  "Account Email (Not required)": "Account Email (Not required)",
  "Email or Username": "Email or Username",
  "Account Email or Username": "Account Email or Username",
  "Full Name of Receiver": "Full Name of Receiver",
  "Receivers's Full Name": "Receivers's Full Name",
  "Payment details (Optional)": "Payment details (Optional)",
  "ID Number": "ID Number",
  "Please enter your National ID Number":
    "Please enter your National ID Number",
  "Account Type": "Account Type",
  "Enter your account type": "Enter your account type",
  "Name and Lastname": "Name and Lastname",
  "Enter Name and Lastname": "Enter Name and Lastname",
  "Full Name": "Full Name",
  "Enter Full Name": "Enter Full Name",
  Cashtag: "Cashtag",
  "Please enter your Cashtag": "Please enter your Cashtag",
  "Full Name (Optional)": "Full Name (Optional)",
  "ID Number (Optional)": "ID Number (Optional)",
  "Address (Optional)": "Address (Optional)",
  "Residential Address": "Residential Address",
  "Mobile Number": "Mobile Number",
  "Enter your Mobile Number available for all networks":
    "Enter your Mobile Number available for all networks",
  "Contact Number": "Contact Number",
  "Phone Number": "Phone Number",
  Operator: "Operator",
  "Mobile / Landline / Data / TV": "Mobile / Landline / Data / TV",
  "Enter account opening branch (Not required)":
    "Enter account opening branch (Not required)",
  "IBAN Number": "IBAN Number",
  "BIC/SWIFT Code(Optional)": "BIC/SWIFT Code(Optional)",
  "BIC/SWIFT Code": "BIC/SWIFT Code",
  "Enter international bank account number":
    "Enter international bank account number",
  "SWIFT BIC (Optional)": "SWIFT BIC (Optional)",
  "Enter your bank identification code": "Enter your bank identification code",
  "GB IBAN account number": "GB IBAN account number",
  "IBAN (Optional)": "IBAN (Optional)",
  "Other payment details (for international transfer) (Optional)":
    "Other payment details (for international transfer) (Optional)",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.",
  "Bank Branch (Optional)": "Bank Branch (Optional)",
  "Recommended Transfer Remarks (Optional)":
    "Recommended Transfer Remarks (Optional)",
  "Recommended Transfer Remarks": "Recommended Transfer Remarks",
  'Please enter your MoMo phone number"':
    'Please enter your MoMo phone number"',
  "Please enter your MoMo QR Code": "Please enter your MoMo QR Code",
  "Please enter your Zalopay QR Code": "Please enter your Zalopay QR Code",
  "Additional account number (Optional)":
    "Additional account number (Optional)",
  "Please enter additional account number":
    "Please enter additional account number",
  "Branch code (Optional)": "Branch code (Optional)",
  "Enter branch code (not required)": "Enter branch code (not required)",
  "IBAN (Optional)": "IBAN (Optional)",
  "Please enter the IBAN code of your Bank (not required)":
    "Please enter the IBAN code of your Bank (not required)",
  "IBAN or account number": "IBAN or account number",
  "Please enter your IBAN or account number":
    "Please enter your IBAN or account number",
  "Papara account number": "Papara account number",
  "Please enter your Papara account number":
    "Please enter your Papara account number",
  City: "City",
  "Please enter your city": "Please enter your city",
  "Zip Code": "Zip Code",
  "Please enter your city zipcode": "Please enter your city zipcode",
  Country: "Country",
  "Please enter Country": "Please enter Country",
  "Moneygram Account Number": "Moneygram Account Number",
  "Please enter your Moneygram account number":
    "Please enter your Moneygram account number",
  "Enter bank branch information": "Enter bank branch information",
  "Please enter your BCA account number":
    "Please enter your BCA account number",
  "Please enter your Mandiri account number":
    "Please enter your Mandiri account number",
  "Chipper Cash full name": "Chipper Cash full name",
  "Please enter your full name used to register on chipper cash":
    "Please enter your full name used to register on chipper cash",
  "Chipper Tag": "Chipper Tag",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash",
  "QIWI account": "QIWI account",
  "Please enter your QIWI account": "Please enter your QIWI account",
  "Your email registered on Binance": "Your email registered on Binance",
  "Please enter your email registered on Binance":
    "Please enter your email registered on Binance",
  "Payment Details": "Payment Details",
  "WeChat account": "WeChat account",
  "Please enter your WeChat account": "Please enter your WeChat account",
  "Receive QR Code (Optional)": "Receive QR Code (Optional)",
  "Alipay account": "Alipay account",
  "Please enter your Alipay account": "Please enter your Alipay account",
  "Payee's LINE ID": "Payee's LINE ID",
  "Please fill in your LINE ID": "Please fill in your LINE ID",
  "Please fill in your phone number registered with LINE":
    "Please fill in your phone number registered with LINE",
  "Linepay QR Code (Optional)": "Linepay QR Code (Optional)",
  "Reference number or barcode": "Reference number or barcode",
  "Please enter reference number or barcode":
    "Please enter reference number or barcode",
  "Remarks (Optional)": "Remarks (Optional)",
  "Additional Remarks": "Additional Remarks",
  "Cell Phone Number": "Cell Phone Number",
  "Please enter your cell phone number": "Please enter your cell phone number",
  "Enter your ID number": "Enter your ID number",
  "Fill in your phone number": "Fill in your phone number",
  "Full Name of Receiver": "Full Name of Receiver",
  "Please enter your Full Name": "Please enter your Full Name",
  CBU: "CBU",
  "Please enter your CBU": "Please enter your CBU",
  "CUIT / CUIL": "CUIT / CUIL",
  "Enter your unique tax identification code":
    "Enter your unique tax identification code",
  "Please enter your CVU": "Please enter your CVU",
  Alias: "Alias",
  "Please enter Alias": "Please enter Alias",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "Lemon Wallet Tag",
  "Uniform Bank Key": "Uniform Bank Key",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "Enter your DNI/CPF number",
  "CVU (Optional)": "CVU (Optional)",
  "Uniform Virtual Key": "Uniform Virtual Key",
  "CBU/CVU or": "CBU/CVU or",
  "alias CVU/CBU or CVU/CBU number": "alias CVU/CBU or CVU/CBU number",
  "Alias del CBU": "Alias del CBU",
  "Vodafone cash Number": "Vodafone cash Number",
  "Please enter your vodafone cash Number":
    "Please enter your vodafone cash Number",
  "Sort code": "Sort code",
  "Please fill in your 6-digit sort code":
    "Please fill in your 6-digit sort code",
  "Sort Code(Optional)": "Sort Code(Optional)",
  "GB IBAN account number": "GB IBAN account number",
  "IIN (Optional)": "IIN (Optional)",
  "Enter individual identification number":
    "Enter individual identification number",
  "Please enter your bank card number": "Please enter your bank card number",
  "Mobile Carrier": "Mobile Carrier",
  "Please enter your mobile carrier": "Please enter your mobile carrier",
  "Easypaisa mobile account": "Easypaisa mobile account",
  "Please enter your Easypaisa mobile account":
    "Please enter your Easypaisa mobile account",
  "NayaPay Id": "NayaPay Id",
  "Please enter NayaPay Id": "Please enter NayaPay Id",
  "Enter Brazilian valid document": "Enter Brazilian valid document",
  "Bank agency": "Bank agency",
  "Enter the name of your bank agency": "Enter the name of your bank agency",
  "Type of transfer": "Type of transfer",
  "Please filled with TED/DOC/PIX": "Please filled with TED/DOC/PIX",
  "Name and Last Name": "Name and Last Name",
  "Pix Key": "Pix Key",
  "Phone, email or key": "Phone, email or key",
  'Type of Bank"': 'Type of Bank"',
  "Name registered in PicPay": "Name registered in PicPay",
  "PicPay username": "PicPay username",
  "Username used to log in in PicPay": "Username used to log in in PicPay",
  "urpay Account Details": "urpay Account Details",
  "Please enter your urpay account details":
    "Please enter your urpay account details",
  "bKash Wallet Number": "bKash Wallet Number",
  "Please enter your 11 digit registered bKash account number":
    "Please enter your 11 digit registered bKash account number",
  "Nagad Wallet Number": "Nagad Wallet Number",
  "Please enter your 11 digit registered Nagad wallet number":
    "Please enter your 11 digit registered Nagad wallet number",
  "Please enter your full name registered with Rocket account":
    "Please enter your full name registered with Rocket account",
  "Rocket Wallet Number": "Rocket Wallet Number",
  "Please enter your 12 digit Rocket Wallet number":
    "Please enter your 12 digit Rocket Wallet number",
  "Please enter your JKOPAY ID": "Please enter your JKOPAY ID",
  "EasyPay wallet number (Optional)": "EasyPay wallet number (Optional)",
  "Please fill in EasyPay wallet number":
    "Please fill in EasyPay wallet number",
  "User API Key": "User API Key",
  "User Secret Key": "User Secret Key",
  "Bank account number": "Bank account number",
  PayID: "PayID",
  "Please enter your PayID": "Please enter your PayID",
  "Institution number": "Institution number",
  "Please enter institution number": "Please enter institution number",
  "PayCode (Optional)": "PayCode (Optional)",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "Can be found in My Account ->My PayCode -> Copy the Link",
  "Please fill in your mobile phone number connected with your PayMe":
    "Please fill in your mobile phone number connected with your PayMe",
  Cellphone: "Cellphone",
  "Card Number or password": "Card Number or password",
  "Please fill in card Number or password":
    "Please fill in card Number or password",
  "Purpose of payment (Optional)": "Purpose of payment (Optional)",
  "Enter a payment purpose for buyers": "Enter a payment purpose for buyers",
  "Card number, account or key": "Card number, account or key",
  "CLABE number (Optional)": "CLABE number (Optional)",
  "CLABE number": "CLABE number",
  "Account number/card": "Account number/card",
  "Reference number or card number": "Reference number or card number",
  "Please enter reference number or card number":
    "Please enter reference number or card number",
  "Provide transfer details during the trade":
    "Provide transfer details during the trade",
  "Variable symbol (Optional)": "Variable symbol (Optional)",
  "Enter Variable symbol": "Enter Variable symbol",
  "Enter your PayNow account": "Enter your PayNow account",
  "Account Name": "Account Name",
  "Email Address": "Email Address",
  "Please enter email address": "Please enter email address",
  "Please enter Your Full Name": "Please enter Your Full Name",
  "Account Number": "Account Number",
  "Enter your phone number": "Enter your phone number",
  "Enter your account number": "Enter your account number",
  "Enter your bank account number": "Enter your bank account number",
  "Payment details": "Payment details",
  "Bank name": "Bank name",
  "Please enter your MoMo phone number": "Please enter your MoMo phone number",
  "Please enter your email": "Please enter your email",
  "Enter branch code": "Enter branch code",
  "Please enter the IBAN code of your Bank":
    "Please enter the IBAN code of your Bank",
  "Account opening branch (Optional)": "Account opening branch (Optional)",
  "Enter account opening branch (not required)":
    "Enter account opening branch (not required)",
  "Please enter your Bank account number":
    "Please enter your Bank account number",
  "Please enter your ID Number": "Please enter your ID Number",
  "Enter bank account type": "Enter bank account type",
  "Bank Branch (Optional)": "Bank Branch (Optional)",
  "Account type": "Account type",
  "Please enter bank branch information":
    "Please enter bank branch information",
  "Please enter Email address": "Please enter Email address",
  "Phone number(Optional)": "Phone number(Optional)",
  "Phone Number (Optional)": "Phone Number (Optional)",
  "Additional information": "Additional information",
  "Type of Bank": "Type of Bank",
  "Remark (Optional)": "Remark (Optional)",
  "Mobile Phone": "Mobile Phone",
  "Enter the bank code": "Enter the bank code",
  "Please enter your phone number": "Please enter your phone number",
  "Please enter your Bank name": "Please enter your Bank name",
};

export default ENGLISH;
