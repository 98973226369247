import axios from "axios";
import { BASE_API_ENDPOINT, globalHeaders } from "./index";

export const getUser = async (
  account: string,
  chainId: number,
  authToken: string | undefined
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);

    const result = await axios.get(`${BASE_API_ENDPOINT}/auth-apis/v1/user`, {
      headers: {
        ...globalHeaders,
        "x-auth-token": authToken
          ? authToken
          : localStorage.getItem(`${account}_${chainId}`),
        signature,
      },
    });

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getUser ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getAdvertiserById = async (
  userId: string,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user-detail/${userId}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (e: any) {
    console.log("getUserById ", e);
    return {
      status: e?.response?.status,
      message: e?.response?.data?.errors?.[0],
    };
  }
};

export const getUserStats= async (
  userId: string,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user-stats/${userId}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (e: any) {
    console.log("getUserStats ", e);
    return {
      status: e?.response?.status,
      message: e?.response?.data?.errors?.[0],
    };
  }
};

export const getUserByDetails = async (
  authToken: string | undefined,
  account: string | undefined,
  username: string,
  email: string,
  phone: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/${username}/${email}/${phone}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (e: any) {
    console.log("getUserByUsernameOrEmail ", e);
    return {
      status: e?.response?.status,
      message: e?.response?.data?.errors?.[0],
    };
  }
};

export const getGuestUser = async () => {
  try {
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/guest_user`,
      {
        headers: {
          ...globalHeaders,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getUser ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const updateProfile = async (
  updateData: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.put(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user`,
      updateData,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("updateProfile ", {
      error,
      message: error?.response?.data?.errors?.[0]?.msg,
    });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0]?.msg,
    };
  }
};

export const updateLastLoginCall = async (
  updateData: any,
  account: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user-last-login`,
      updateData,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("updateProfile ", {
      error,
      message: error?.response?.data?.errors?.[0]?.msg,
    });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0]?.msg,
    };
  }
};

export const updateUserOnlineStatus = async (
  account: string,
  authToken: string,
  payload: any
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/online-status`,
      payload,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("updateUserOnlineStatus ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getUserAvatar = async (
  avatar: string,
  account: string,
  authToken: string
) => {
  try {
    if (!avatar) {
      return null;
    }

    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/avatar`,
      {
        params: { avatar },
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (e: any) {
    console.log("getUserAvatar ", e);
    return {
      status: e?.response?.status,
      message: e?.response?.data?.errors?.[0],
    };
  }
};

export const getQrCode = async (
  paymentMode: string,
  userId: string,
  account: string,
  authToken: string
) => {
  try {
    if (!paymentMode) {
      return null;
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/payment-mode/qr-code`,
      {
        params: { payment_mode: paymentMode, userId: userId },
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error :>>", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const updatePayments = async (
  updateData: any,
  account: string,
  authToken: string
) => {
  try {
    let result;

    const signature = localStorage.getItem(`signature_${account}`);
    if (!updateData?._id) {
      result = await axios.put(
        `${BASE_API_ENDPOINT}/auth-apis/v1/user/payment-option`,
        updateData,
        { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
      );
    } else {
      result = await axios.put(
        `${BASE_API_ENDPOINT}/auth-apis/v1/user/payment-option/${updateData?._id}`,
        updateData,
        { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
      );
    }

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("updatePayments ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const updateDefaultCurrency = async (
  updateData: any,
  account: any,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/payment-option`,
      updateData,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: result?.status, data: result?.data?.fiat };
  } catch (error: any) {
    console.log("updateDefaultCurrency ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const sendSmsOtp = async (
  phone: string,
  account: string,
  authToken: string
) => {
  try {
    if (!phone) {
      return { status: false, data: null };
    }

    const body = {
      phone: phone,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/otp-apis/v1/phone-otp`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const verifySmsOtp = async (
  otp: string,
  account: string,
  authToken: string
) => {
  try {
    if (!otp) {
      return { status: false, data: null };
    }

    const body = {
      otp: otp,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/otp-apis/v1/verify-phone-otp`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const sendEmailOtp = async (
  email: string,
  account: string,
  authToken: string
) => {
  try {
    if (!email) {
      return { status: false, data: null };
    }

    const body = {
      email: email,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/otp-apis/v1/email-otp`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const verifyEmailOtp = async (
  otp: string,
  account: string,
  authToken: string
) => {
  try {
    if (!otp) {
      return { status: false, data: null };
    }

    const body = {
      otp: otp,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/otp-apis/v1/verify-email-otp`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getOtpState = async (
  type: string,
  account: string,
  authToken: string
) => {
  try {
    if (!type) {
      return { status: false, data: null };
    }
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/otp-apis/v1/otp-state/${type}`,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const followUser = async (
  id: string,
  account: string,
  authToken: string
) => {
  try {
    if (!id) {
      return { status: false, message: "Invalid user id" };
    }

    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/follow`,
      { followingId: id },
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error in follow user ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const unfollowUser = async (
  id: string,
  account: string,
  authToken: string
) => {
  try {
    if (!id) {
      return { status: false, message: "Invalid user id" };
    }

    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/unfollow`,
      { unfollowingId: id },
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error in unfollow user ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const removeFollower = async (
  id: string,
  account: string,
  authToken: string
) => {
  try {
    if (!id) {
      return { status: false, message: "Invalid user id" };
    }

    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/remove-follower/${id}`,
      { },
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error in removing follower ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const postPushToken = async (
  token: string,
  account: string,
  authToken: string
) => {
  try {
    if (!token) {
      return { status: false, message: "Invalid token" };
    }

    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/auth-apis/v1/user/push-token`,
      { token: token },
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error in push token ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
