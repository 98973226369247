import { useCallback, useEffect, useMemo, useState } from "react";
import { useSingleCallResult } from "../state/multicall/hooks";
import { useTokenContract } from "./useContract";
import { Token, TransactionState, TransactionStatus } from "../utils/interface";
import { BigNumber } from "@ethersproject/bignumber";
import { FALLBACK_DEFAULT_CHAIN, P2P_ADDRESSES } from "../constants";
import useBlockNumber from "./useBlockNumber";
import { increaseGasLimit, toWei } from "../utils/helper";
import useActiveWeb3React from "./useActiveWeb3React";
import { NATIVE_TOKEN } from "../constants/chains";
import { useSelector } from "react-redux";
import { recordLog } from "../utils/httpCalls/logCalls";

export function useTokenAllowance(
  token?: Token
): [boolean, () => {}, TransactionStatus, () => void] {
  const tokenContract = useTokenContract(token?.address);
  const initialState: TransactionStatus = {
    hash: "",
    status: null,
    state: 0,
    type: null,
    tokenAmount: null,
    token: null,
    account: null,
    tokenId: null,
  };
  const [data, setData] = useState(initialState);
  const blockNumber = useBlockNumber();
  const { account, chainId, provider } = useActiveWeb3React();
  const userAuth = useSelector((state: any) => state?.user);

  const owner = account;
  const spender = P2P_ADDRESSES?.[chainId || FALLBACK_DEFAULT_CHAIN];

  const inputs = useMemo(
    () => [owner?.toLowerCase(), spender?.toLowerCase()],
    [owner, spender]
  );
  const allowance = useSingleCallResult(
    tokenContract,
    "allowance",
    inputs
  ).result;

  const confirmAllowance = useCallback(
    async (tokenAmount?: string) => {
      try {
        const _amount = toWei(tokenAmount, token?.decimals);
        console.log("allowance ", _amount);

        if (!tokenContract || !spender || !tokenAmount) {
          setData({ ...data, status: TransactionState.FAILED, state: 4 });
          const logPayload = {
            message: `Allowance test token contract address ${tokenContract?.address}`,
            functionName: "confirmAllowance",
            error:
              "Invalid params " +
              JSON.stringify({
                contractAddress: tokenContract?.address,
                spender,
                tokenAmount,
              }),
          };
          const res = await recordLog(
            logPayload,
            userAuth?.account,
            userAuth?.jwtToken
          );
          return;
        }

        setData({
          ...data,
          status: TransactionState.WAITING,
          state: 1,
          type: "allowance",
        });

        const gasLimit = await tokenContract.estimateGas?.approve(
          spender,
          _amount
        );
        const gasPrice = await provider?.getGasPrice();
        console.log("transaction test ", {
          increasedGas: increaseGasLimit(gasLimit?.toString()),
          gasPrice: gasPrice?.toString(),
        });
        const tx = await tokenContract.approve(spender, _amount, {
          gasLimit: 200000,
        });

        setData({
          ...data,
          hash: tx?.hash,
          status: TransactionState.PENDING,
          state: 2,
          type: "allowance",
        });
      } catch (error) {
        const logPayload = {
          message: `Allowance test token contract address ${tokenContract?.address}`,
          functionName: "confirmAllowance",
          error: JSON.stringify(error?.message),
        };
        const res = await recordLog(
          logPayload,
          userAuth?.account,
          userAuth?.jwtToken
        );
        console.log("transaction confirmAllowance log test ", res);
        console.log("transaction confirmAllowance  ", JSON.stringify(error));
        setData({ ...data, status: TransactionState.FAILED, state: 4 });
      }
    },
    [
      provider,
      tokenContract,
      data,
      setData,
      spender,
      token,
      userAuth?.account,
      userAuth?.jwtToken,
    ]
  );

  const resetTrxState = useCallback(() => {
    setData(initialState);
  }, [setData]);

  useEffect(() => {
    setData(initialState);
  }, []);

  useEffect(() => {
    if (!data?.hash) {
      return;
    }

    if (
      data?.status === TransactionState.COMPLETED ||
      data?.status === TransactionState.FAILED
    ) {
      return;
    }

    provider
      ?.getTransactionReceipt(data?.hash)
      .then((res) => {
        if (res?.blockHash && res?.blockNumber) {
          setData({ ...data, status: TransactionState.COMPLETED, state: 3 });
        }
      })
      .catch((err) => {
        console.log("transaction failed ", err);
        setData({ ...data, status: TransactionState.FAILED, state: 4 });
      });
  }, [blockNumber]);

  const allowanceStatus = useMemo(() => {
    if (token && chainId && token?.symbol === NATIVE_TOKEN?.[chainId]) {
      return true;
    }

    if (token && allowance && BigNumber.from(allowance?.toString()).gt(0)) {
      return true;
    }

    return false;
  }, [token, allowance, blockNumber, chainId]);

  const transactionStatus = useMemo(() => {
    return {
      status: data?.status,
      hash: data?.hash,
      state: data.state,
      type: data?.type,
      account: data?.account,
      tokenAmount: data?.tokenAmount,
      token: data?.token,
      tokenId: null,
    };
  }, [data]);

  return [allowanceStatus, confirmAllowance, transactionStatus, resetTrxState];
}
