const CHINESE = {
  Mode: "模式",
  Feedback: "反馈",
  Home: "家",
  "Create Order": "创建订单",
  "My Orders": "我的订单",
  Wallet: "钱包",
  Profile: "轮廓",
  Leaderboard: "排行榜",
  Faucet: "龙头",
  Banned: "禁止",
  "This account has been banned. If this is unexpected, please contact":
    "该帐户已被禁止。如果这是出乎意料的，请联系",
  footermessage: "加入100k+共同努力的人，使世界变得更美好。",
  "Copyright 2023 By": "版权2023",
  "All Rights Reserved": "版权所有",
  "How P2P Works": "P2P的工作原理",
  home_howitwork:
    "Polkabridge P2P可以帮助您立即将资金转换为加密货币，在那里Polkabridge可以作为保管交易的托管。",
  Buy: "买",
  Sell: "卖",
  "STEP 1": "步骤1",
  buy_step1:
    "选择具有所需价格和付款方式的广告。输入您的买入数量和交易金额以完成订单。",
  "Deposit tokens into": "将令牌存入",
  wallet: "钱包",
  "and create orders": "并创建订单",
  "STEP 2": "第2步",
  buy_step2: "根据卖方提供的信息来完成您的付款。",
  step2_sell: "确认您已收到买家的全部付款。",
  "STEP 3": "步骤3",
  step31_buy: "卖方收到付款后，他将释放硬币。前往",
  step32_buy: "查看您收到的硬币。",
  step3_sell: "只有在您收到全额付款后，才向买家发布硬币。",
  "Before you start trading you need to update your profile.":
    "在开始交易之前，您需要更新个人资料。",
  "Before your start trading please verify your phone":
    "在开始交易之前，请验证您的手机",
  "Please add a payment method to start buy crypto":
    "请添加付款方式开始购买加密货币",
  "Update now": "现在更新",
  "Verify now": "立即验证",
  "Add now": "立即添加",
  Logout: "登出",
  "Switching...": "交换...",
  Switch: "转变",
  "Switch Network": "开关网络",
  "Cancel dispute": "取消争议",
  canceldisputewarn:
    "没有选择再次提出争议。如果您的问题没有得到解决，请不要取消",
  "I confirm I have recieved the money from seller":
    "我确认我已经从卖家那里收到了钱",
  "Cancelling...": "取消...",
  "Cancel Trade": "取消交易",
  Warning: "警告",
  warningcancel: "取消订单后将没有退款。如果您已经转移了钱，请不要取消。",
  tradewarning: "如果一家人一天取消了4笔交易，则将暂停交易。",
  "I confirm I have not paid the payee.": "我确认我尚未支付收款人。",
  "Please wait...": "请稍等...",
  "Cancel this trade": "取消此交易",
  "Confirm Transfer": "确认转移",
  confirmnote:
    "P2P交易要求您以接收方支持的方法进行付款或转移。 Polkabridge不会自动从您的帐户中扣除。",
  "Transfer Amount": "转移金额",
  "In exchange for": "换来的",
  "Upload proof of payment": "上传付款证明",
  "Confirm the account of the receiving party": "确认接收方的帐户",
  "Payment QR code": "付款QR码",
  confirmnote2:
    "请尽快完成您的订单，以便接收方可以确认您的转移。请选择接收方的帐户。欺诈性付款确认可能会导致您的帐户被冻结",
  "I didn't Pay Yet": "我还没有付款",
  "Transfer Completed": "转移完成",
  "Deposit tokens into PolkaBridge vault and trade hassle free":
    "将令牌存入Polkabridge拱顶并免费贸易麻烦",
  "Withdraw  tokens from PolkaBridge vault with fee":
    "从Polkabridge Vault退出令牌",
  Amount: "数量",
  "Select Max": "选择最大",
  Balance: "平衡",
  "Available Amount": "可用金额",
  "Withdrawal Fee": "提款费",
  "In order": "为了",
  View: "看法",
  "Send OTP": "发送OTP",
  "Verify Phone": "验证电话",
  "Verify Email": "验证邮件",
  "Your phone number has been verified": "您的电话号码已验证",
  "Your email address has been verified": "您的电子邮件地址已验证",
  "6 digit otp has been sent on your phone": "6位Digit OTP已在您的手机上发送",
  emailsent:
    "6位数字验证代码在您的电子邮件中发送。如果您看不到收件箱中的电子邮件，请确保检查垃圾邮件文件夹",
  "Verify OTP": "验证OTP",
  "OTP expired": "OTP过期",
  "Resend OTP": "重新启动OTP",
  "Your OTP will expire in": "您的OTP将过期",
  "Confirm and Release": "确认并释放",
  Tips: "尖端",
  dontnotcheckbuyerpayment:
    "不仅检查买方的付款证明。确保您登录到您的帐户，并验证已收到付款。",
  ifstillprocessing:
    "如果付款仍在处理，请等到您已收到您的帐户付款，然后再发布加密！",
  donotacceptthridparty:
    "不要接受第三方帐户的付款。如果您收到此类付款，请立即退还全部金额，以避免您发布加密货币后银行拒绝造成的财务损失。",
  "Confirm User Account and Release Amount": "确认用户帐户并发布金额",
  iconfirmpaymentcorrect: "我确认付款已记入我的帐户，可用余额是正确的。",
  "Releasing...": "释放...",
  Order: "命令",
  "is successfully completed": "成功完成",
  Submitted: "提交",
  Submit: "提交",
  "Click below for processing": "单击下面的处理",
  "Waiting for confirmation": "等待确认中",
  "Please confirm the transaction into your metamask popup":
    "请确认交易到您的metamask弹出窗口中",
  "Withdrawal Request Submitted": "提交提取请求",
  "Withdrawal Address": "提款地址",
  Back: "后退",
  "Withdrawal History": "戒断历史",
  "Deposit Processing": "存款处理",
  depositapproval:
    "已提交了押金的批准，请耐心等待。在存款过程中，请勿发起更多的存款。",
  "Deposits History": "存款历史",
  "Transaction Submitted": "交易提交",
  "Transaction has been submitted and waiting for the confirmation from blockchain.":
    "交易已提交并等待区块链的确认。",
  "View Status": "查看状态",
  "Transaction Successful!": "交易成功！",
  "Great! Transaction has been confirmed at the blockchain Successfully.":
    "伟大的！交易已在区块链上成功确认。",
  "Transaction Failed!": "交易失败！",
  "We have encountered an error in the transaction. Please try again.":
    "我们在交易中遇到了错误。请再试一次。",
  "Sign Message": "标志消息",
  "Connect Wallet": "连接钱包",
  "Waiting to connect": "等待连接",
  "Confirm this connection in your wallet": "在您的钱包中确认此连接",
  walletconnect_rule:
    "通过连接钱包，您可以同意Polkabridge P2P的服务条款并同意其隐私政策。",
  "Please sign this message requested in your metamask":
    "请在您的metamask中签署此消息",
  Metamask: "metamask",
  "Wallet Connect": "Wallet Connect",
  "Next testnet version coming!": "下一个TestNet版本即将到来！",
  "Follow Us": "跟着我们",
  "Claim Faucet": "Faucet",
  "Claim test tokens for Goerli Testnet or BSC Testnet.":
    "要求Goerli Testnet或BSC TestNet的测试令牌。",
  "Unauthorised Access!": "未经授权的访问！",
  "Please connect your wallet first to continue P2P trading.":
    "请先连接您的钱包以继续P2P交易。",
  Price: "价格",
  Limit: "限制",
  Following: "下列的",
  Unfollow: "取消关注",
  Follow: "跟随",
  Rating: "评分",
  "Online Ads": "在线广告",
  "All Feedback": "所有反馈",
  "Volume in": "体积IN",
  "Decentralized P2P Exchange, powered by ": "分散的P2P交换，由 ",
  "Experience first decentralized P2P trading with PolkaBridge":
    "经验与Polkabridge首次分散的P2P交易",
  Fiat: "法定",
  All: "全部",
  Token: "令牌",
  "Payment Mode": "付款方式",
  orders: "命令",
  completion: "完成",
  BUY: "买",
  SELL: "卖",
  Verify: "核实",
  "Crypto Amount": "加密货币",
  "Fiat Amount": "法定金额",
  "Market Open Orders": "市场公开订单",
  Advertisers: "广告商",
  "Available | Limit": "可用|限制",
  Payment: "支付",
  Trade: "贸易",
  "Exclusive ranking for Polkabridge Traders": "Polkabridge交易者的独家排名",
  Volume: "体积",
  Followers: "追随者",
  "Avg. Pay Time": "avg。付费时间",
  "Avg. Release Time": "avg。推出日期",
  Username: "用户名",
  Filter: "筛选",
  Clear: "清除",
  User: "用户",
  "Last Trade Time": "最后的交易时间",
  Action: "行动",
  "Unauthorised Access": "未经授权的访问",
  Apply: "申请",
  "Order No.": "订单号。",
  Coin: "硬币",
  Type: "类型",
  Status: "地位",
  Time: "时间",
  Operation: "手术",
  "Crypto amount": "加密货币",
  "Fiat amount": "法定金额",
  "Order type": "订单类型",
  "Order Details": "订单详细信息",
  "Order Number": "订单号",
  "Time created": "创建的时间",
  "Buy Order": "购买订单",
  Executed: "执行",
  at: "在",
  "Total Fiat Amount": "总法定金额",
  "Crypto Unit Price": "加密单位价格",
  "Min Order Limit": "最小订单限制",
  "Max Order Limit": "最大订单限制",
  "Trade between": "之间的交易",
  "Payment Time Limit": "付款时间限制",
  Remark: "评论",
  "Need Help": "需要帮忙",
  "Please wait": "请稍等",
  "Cancel Order": "取消订单",
  "Go To Home": "回家",
  "Initiate New P2P Order": "发起新的P2P订单",
  "Set type and price": "设定类型和价格",
  "Review Your Order": "查看你的订单",
  "I want to": "我想要",
  "Order Limit": "订单限制",
  Min: "最小",
  Max: "最大限度",
  "Payment Methods": "支付方式",
  "Add payments": "添加付款",
  "Current Price": "时价",
  "Highest Price": "最高价格",
  "Lowest Price": "最低价格",
  "Please note that if you decide to": "请注意，如果您决定",
  SUBMIT: "提交",
  "the order, you wouldn't be able to": "订单，您将无法",
  EDIT: "编辑",
  "it afterwards.": "之后。",
  "Review New P2P Order": "查看新的P2P订单",
  "Review your order": "查看你的订单",
  Remarks: "评论",
  Cancel: "取消",
  "Submit Order": "提交订单",
  "Order Submitted Successfully": "成功提交的订单",
  "Order Type": "订单类型",
  Total: "全部的",
  "Check your order": "检查您的订单",
  Deposit: "订金",
  "to fulfill this sell order": "履行此卖出订单",
  with: "和",
  "Time remaining": "剩余时间",
  "Confirm Order Info": "确认订单信息",
  "Quantity (After fee)": "数量（收费后）",
  "Chat with": "和...聊天",
  Uploading: "上传",
  "Upload Proof of Payment": "上传付款证明",
  Browse: "浏览",
  "Trade cancelled": "贸易被取消",
  "Transfer Payment to seller": "将付款转移给卖方",
  "Seller released tokens": "卖方释放了令牌",
  "Pending seller confirmation": "等待卖方确认",
  Completed: "完全的",
  "Token Deposited": "令牌沉积",
  "Deposit tokens to": "存放令牌",
  reserve: "预订",
  "Buyer confirmed": "买方确认",
  "Pending buyer confirmation": "等待买方确认",
  "Order Summary": "订单摘要",
  Available: "可用的",
  "payment method": "付款方式",
  "Seller's Message:": "卖方的消息：",
  "Buyer's Message:": "买方的消息：",
  "No message": "没有消息",
  "Terms and conditions": "条款和条件",
  ordersummary_rule:
    "请勿通过第三方帐户发送付款，所有此类付款都必须进行争议，并将退还/取消。请不要包括与加密货币相关的单词，例如P2P，Polkabridge，USDT，ETH，BTC等。",
  "I want to buy for": "我想买",
  "I want to sell for": "我想卖",
  "I will get:": "我会得到：",
  "I will send:": "我会发送：",
  Loading: "加载",
  "Order Processing": "订单处理",
  "Reference message": "参考消息",
  "QR Code": "二维码",
  "Payment confirmed": "付款确认成功",
  "I've Paid. Confirm": "我付了。确认",
  Cancelled: "取消",
  Name: "姓名",
  Email: "电子邮件",
  Quantity: "数量",
  Your: "你的",
  "has been reserved in PolkaBridge vault": "已保留在Polkabridge Vault中",
  "Token reserved": "代币保留",
  "Waiting for buyer's confirmation": "等待买方的确认",
  "Buyer confirmed payment": "买方确认付款",
  "Buyer notified": "买方已通知",
  "Notify buyer": "通知买家",
  "Cancel trade": "取消交易",
  "Dispute submitted": "提交的争议",
  Reason: "原因",
  "Release tokens to buyer wallet": "向买家钱包释放令牌",
  "Token released": "令牌发布",
  "Release tokens": "释放令牌",
  "Your order is executed successfully.": "您的订单已成功执行。",
  "My Profile": "我的简历",
  "Update your preference for smooth trading experience.":
    "更新您对平滑交易经验的偏爱。",
  Menu: "菜单",
  Payments: "付款",
  "Transaction history": "交易历史记录",
  Settings: "设置",
  Referral: "推荐",
  "Filter Order": "过滤订单",
  TxnId: "txnid",
  "Transaction not found": "找不到交易",
  "Wallet unavailable": "钱包不可用",
  "Check explorer": "检查资源管理器",
  "Full Name": "全名",
  Mobile: "移动的",
  "Preferred Fiat": "首选法定",
  "Edit profile": "编辑个人资料",
  "Upload Avatar": "上传头像",
  "Updating...": "更新...",
  "Update profile": "更新个人信息",
  "Are you sure, want to leave PolkaBridge?": "您确定要离开Polkabridge吗？",
  "Log out": "登出",
  "Fiat Preference": "法定偏好",
  Referrals: "推荐",
  "Will be available soon.": "即将提供。",
  "Payment Options": "付款方式",
  "Transaction History": "交易历史记录",
  Fee: "费用",
  TxnID: "txnid",
  "All payment methods were added": "添加了所有付款方式",
  "Add Payment Method": "添加付款方式",
  "Payment method": "付款方式",
  "QR Code (optional)": "QR码（可选）",
  "Upload QR Code": "上传QR码",
  "Update details": "更新详细信息",
  "Not Uploaded": "未上传",
  Edit: "编辑",
  "Update Payment": "更新付款",
  Confirm: "确认",
  "Depositing...": "存放...",
  "Confirming...": "确认...",
  Withdraw: "提取",
  Locked: "锁定",
  "P2P Wallet": "P2P钱包",
  walletpage_message:
    "在您的P2P平台上管理资产。您可以存入，提取资产参加P2P的交易",
  "Your Balances": "你的余额",
  "Buy Amount": "购买金额",
  "Sell Amount": "出售金额",
  "Trade(s)": "贸易",
  active: "活跃",
  "Executed at": "执行",
  Chain: "链",
  "My Ads": "我的广告",
  Active: "积极的",
  "Amount reversed by my Bank.": "我的银行撤销的金额",
  "Transaction failed on my behalf": "代表我交易失败",
  "Seller received the amount successfully.": "卖家成功收到款项。",
  "Seller provided the necessary information to complete the payment.":
    "卖方提供了完成付款所需的信息。",
  "I sent the remaining amount.": "我发送了剩余的金额。",
  "I raised the dispute by mistake.": "我错误地提出了争议。",
  "I received the tokens.": "我收到了代币。",
  Others: "其他的",
  Quest: "尋求",
  "Complete your quests and collect points.": "完成你的任務並收集積分。",
  "Total Points": "總積分",
  Redeem: "赎回",
  "Resets in": "重置",
  "Daily Quests": "每日任务",
  "Weekly Quests": "每周任务",
  Rules: "规则",
  "1. Daily Task : The task will be refreshed daily at 00:00 UTC.":
    "1.日常任务：该任务将每天在00:00 UTC刷新。",
  "2. You need to click 'Claim' to get your task points.If not the points will not be credited to your account.":
    "2.您需要单击“索赔”才能获取任务点。如果没有将要点记入您的帐户。",
  questrule3:
    "3. 第一轮任务总奖励为60,000 PBR。 点数将转换为相应的PBR，具体取决于请求的点数",
  "4. Redeem points limit is 100 Points.": "4.兑换点限制为100点。",
  questrule5:
    "5. 第 1 轮任务结束后，用户将领取奖励。 奖励将在7个工作日内发送给用户。",
  "6. Polkabridge reserves the right of final interpretation for the event.If a user to be involved in any malicious behaviour or has exploited security vulnerabilities, Polkabridge has the right to dispose of the user's account and assets.":
    "6. Polkabridge保留对事件的最终解释权。如果用户要涉及任何恶意行为或利用安全性漏洞，则Polkabridge有权处置用户的帐户和资产。",
  Points: "点",
  Claiming: "要求",
  Claimed: "声称",
  Claim: "宣称",
  Action: "行动",
  "Redeem Points": "兑换积分",
  "1 Points = 1 PBR": "1分= 1 PBR",
  "Redeem Points limit is 100 Points": "赎回点限制为100分",
  "Submit your redeem request admin will review and transfer token in your wallet.":
    "提交您的赎回请求管理员将在您的钱包中审查和转移令牌。",
  Tokens: "令牌",
  "Wallet Address": "钱包地址",
  "Confirm your wallet address and submit your request.":
    "确认您的钱包地址并提交您的请求。",
  "Completed at least 20 orders in Ethereum Chain within 7 consecutive days.":
    "连续7天在以太坊链中完成至少20个订单。",
  "Completed at least 20 orders in BSC Chain within 7 consecutive days.":
    "连续7天在BSC链中完成至少20个订单。",
  "Deposit and withdraw within 7 consecutive days in both chains.":
    "连续7天将两条连锁店存入并退出。",
  "Complete trade with all tokens (PBR-ETH, PBR-BSC, USDT-ETH,USDT-BSC, USDC, DAI, ETH, BUSD, BNB)  in 7 days.":
    "在7天内与所有代币（PBR-ETH，PBR-BSC，USDT-ETH，USDT-BBC，USDC，USDC，USDC，dai，Busd，BNB）完成贸易。",
  "Give at least 10 feedback .": "给出至少10个反馈。",
  "Create at least 1 Ad on your FIAT currency":
    "在您的法定货币上至少创建1个广告",
  "Complete at least 1 BUY order and 1 SELL order in Ethereum Chain":
    "在以太坊链中完成至少 1 个买入订单和 1 个卖出订单",
  "Complete at least 1 BUY order and 1 SELL order in BSC Chain":
    "在 BSC 链中完成至少 1 个 BUY 订单和 1 个 SELL 订单",
  "在以太坊链上存入或取出至少 1 个代币": "",
  "Deposit or withdraw at least 1 token on BSC chain":
    "在 BSC 链上充值或提现至少 1 个代币",
  "Deposit at least 0.1 ETH or 0.1 BNB.": "至少存入0.1 eth或0.1 bnb。",

  "Waiting for seller to release tokens": "等待卖家释放代币",
  "Transfer the funds to seller account provided below":
    "将资金转入下方提供的卖家账户",
  "Seller released tokens": "卖家发布代币",
  "Waiting for confirmation": "等待确认中",
  "Dispute submitted": "提交争议",
  Completed: "完全的",
  "Your order is executed successfully": "您的订单已成功执行",
  Submit: "提交",
  "Cancel Dispute": "取消争议",
  Cancelled: "取消",
  "Reason:": "原因",
  "Dispute raised": "提出争议",
  "In reviewing": "审核中",
  Resolved: "解决",
  "Your Proofs": "你的证明",

  "Write a review": "写评论",
  "Transfer the funds to seller account provided below":
    "将资金转入下方提供的卖家账户",
  "Waiting for seller to release tokens": "等待卖家释放代币",
  "Dispute submitted": "提交争议",
  "Your Proofs": "你的证明",
  "Seller's Proofs": "卖方证明",
  "Raise dispute": "提出争议",
  "Upload dispute proof": "上传争议证明",
  "Raise a dispute request with valid proof": "凭有效证据提出争议请求",
  "Upload more proofs": "上传更多证明",
  "Reason for dispute": "争议原因",
  Description: "描述",
  "Upload Video Proof of Payment": "上传视频付款证明",
  Remove: "消除",
  "Drag and drop more proof of payment": "拖放更多付款证明",
  "Allowed file size less than 50 MB": "允许的文件大小小于 50 MB",
  "Start video from your P2P order screen": "从您的 P2P 订单屏幕开始播放视频",
  "Clear all applications running in the background":
    "清除后台运行的所有应用程序",
  "Open the payment application": "打开支付应用",
  "Show your Name and ID clearly in the application":
    "在申请中清楚地显示您的姓名和身份证件",
  "Show the transaction history": "显示交易记录",
  "The following things should be clearly visible. - Name and ID of the receiver. - Date and Time of the transaction. - Amount of the transaction. - Transaction Number":
    "下面的东西应该是清晰可见的。 - 接收者的姓名和 ID。 - 日期和时间 交易。 - 交易金额。 - 交易 数字",
  "Uploading...": "“正在上传……”",
  "I have paid, but the seller does not release the coin":
    "我已付款，但卖家不放币",
  "Seller notified": "卖家通知",
  "Notify Seller": "通知卖家",
  "Pending dispute": "悬而未决的争议",
  "Raise dispute": "提出争议",
  "I paid too much": "我付出了太多",
  "The seller payment account is wrong, can't pay":
    "卖家付款账号错误，无法付款",
  "I paid the wrong person": "我付错人了",
  "I paid for the part of the money, can't continue to pay":
    "我支付了部分钱，不能继续支付",
  Pending: "待办的",
  "I confirm that my issue is resolved and I want to close this dispute.":
    "我确认我的问题已解决，我想结束此争议。",
  "Upload proof": "上传证明",
  "Upload proof of payment": "上传付款证明",
  "I confirm there is no problem and I want to cancel this transaction":
    "我确认没有问题，我想取消这笔交易",
  warningcancel_seller:
    "您确定要取消此交易吗？ 请注意，如果买家已经付款，PolkaBridge 将不处理取消后的争议。", //Are you sure you want to cancel this transaction? Please note, PolkaBridge will not handle post-cancellation disputes, if the buyer has already paid.
  "Hi, how can we help you?": "嗨，我们该如何帮助您？",
  Support: "支持",
  "Raise Ticket": "提高票",
  "Watch Tutorial": "观看教程",
  "Video Tutorial": "视频教程",
  Open: "打开",
  Official: "官方的",
  "Email Us": "给我们发电子邮件",
  Issues: "问题",
  Description: "描述",
  "Upload Proof": "上传证明",
  "Drag and drop the file, or": "拖放文件，或",
  "Enter the description": "输入说明",
  General: "一般的",
  "Deposit/Withdraw": "存款/提取",
  Account: "帐户",
  Trading: "贸易",
  Others: "其他的",
  Telegram: "Telegram",
  "Show Online Users": "显示在线用户",
  "Tokens Locked": "令牌锁定",
  "Payment Done": "付款完成",
  "In Dispute": "在争吵",
  Date: "日期",
  Ascending: "上升",
  Descending: "上升",
  "Your ticket has been submitted and will be reviewed by our team. The issue will be resolved within 24 hours. Thanks for your patience.":
    "您的机票已提交，我们的团队将审查。该问题将在24小时内解决。谢谢你的耐心",
  "Ticket History": "门票历史记录",
  Date: "日期",
  "Ticket ID": "票证ID",
  Issue: "问题",
  Reviewing: "评论",
  Resolved: "解决",
  Pending: "待办的",
  Authenticating: "认证",
  "Admin Remark": "管理员备注",
  Message: "信息",
  "Price Ascending": "价格上涨",
  "Price Descending": "价格下降",
  "Latest Ads": "最新广告",
  "Latest Orders": "最新订单",
  "Completed Trades": "完成交易",
  "User Guide": "用户指南",
  "Beginner's Guide": "初学者指南",
  "Video Tutorial": "视频教程",
  "P2P trading FAQ": "P2P交易常见问题解答",
  "Admin Message": "管理员留言",
  "Ticket History": "工单历史",
  "Review Submitted Successfully": "审核成功提交",
  "Transaction Hash": "交易哈希",
  "Ticket Details": "门票详细信息",
  Ended: "结束",
  "Round 1 Ended": "第一轮结束",
  "Redeem your points before Round 2 starts": "在第2轮开始之前赎回您的观点",
  "Redeem History": "赎回历史",
  "Redeem Time": "赎回时间",
  Address: "地址",
  pending: "待办的",
  completed: "完全的",
  rejected: "拒绝",
  "Redeemed At": "赎回",
  "Redeem Detail": "赎回细节",
  "Transaction ID": "事务ID",
  "Admin Remark": "管理员备注",
  rejected: "拒绝",
  "Profile Tab": "个人资料选项卡",
  "Click to update your profile like Name, User ID, mobile No, Email and Most importantly to add your preffered payment options.":
    "单击以更新您的个人资料，例如名称，用户ID，移动号，电子邮件，最重要的是添加您的预先付款选项。",
  "Quest Tab": "任务选项卡",
  "Click to participate in Ongoing Quest Campaign and to Claim points to get $PBR Rewards.":
    "单击以参加正在进行的任务活动，并要求积分获得$ PBR奖励。",
  "LeaderBoard Tab": "排行榜选项卡",
  "Click to see exclusive ranking for Polkabridge Traders.":
    "单击以查看Polkabridge交易者的独家排名。",
  "Buy Vs Sell": "购买与卖出",
  "By selecting Buy option you can buy Crypto with your preffered Fiat currencies.":
    "通过选择购买选项，您可以使用预先限制的法定货币购买加密货币。",
  "By selecting Sell option you can sell Crypto with your preffered Fiat currencies.":
    "通过选择卖出选项，您可以使用预先预先的法定货币出售加密货币。",
  "Filter Tool": "过滤器工具",
  "Click to sort Ads by your preferences, or use the filter to  configure Ads displayed to you. Filter Options are based on availability of your region/currency.":
    "单击以按您的首选项对广告进行排序，或使用过滤器配置显示给您的广告。过滤选项基于您的区域/货币的可用性。",
  "Advertiser Info": "广告商信息",
  "Here you see the merchant's profile ,online status, completion rate, the total number of completed orders, and merchant badge. Click the merchant's name to view more information.":
    "在这里，您可以看到商人的个人资料，在线状态，完成率，完成订单的总数和商人徽章。单击商人的名称以查看更多信息。",
  "Price and Limits": "价格和限制",
  "Here you can see the price  of 1 coin the user wishes to trade for, its availability quantity, and the  lowest and highest amount the user is willing to trade.":
    "在这里，您可以看到用户希望交易的1个硬币的价格，其可用性数量以及用户愿意交易的最低和最高数量。",
  "Payment options": "付款方式",
  "Here you can see the mode of payments that the Advertiser  accepts to trade for tokens/fiat.":
    "在这里，您可以看到广告商接受代币/法定的付款方式。",
  "Lowest Fees": "最低费用",
  "Buy crypto with lowest fees ever!": "购买有史以来最低费用的加密货币！",
  "P2P Wallet": "P2P钱包",
  "Click to check your balance and to manage deposits and withdrawals of your assets and their history.":
    "单击以检查您的余额，管理您的资产及其历史记录的存款和提款。",
  Next: "下一个",
  Skip: "跳过",
  Close: "关闭",
  "Create Order Tab": "创建订单选项卡",
  "Kindly click on the button to create a Buy/Sell order using  your preferred currencies/tokens/payment modes.":
    "请单击按钮，使用您首选的货币/令牌/付款方式创建买入/出售订单。",
  "Switch Network": "开关网络",
  "Our P2P platform is designed to support Multichain, meaning you have the flexibility to conduct trades on any chain of your choosing.":
    "我们的P2P平台旨在支持多键，这意味着您可以灵活地在选择的任何链条上进行交易。",
  "24h Trading Volume": "24小时交易量",
  "24h Total Orders": "总订单24小时",
  "Show Users Following": "向用户显示以下内容",
  Following: "下列的",
  Online: "在线的",
  "Please redeem your points before 24th March, 00:00 AM UTC. Minimum number of points to convert is 100 points":
    "请在 UTC 时间 3 月 24 日 00:00 之前兑换您的积分。 转换的最低点数为 100 点",
  "User's Statistics": "用户的统计信息",
  Phone: "电话",
  Follower: "追随者",
  "All Trades": "所有交易",
  "Total Buy Orders": "总购买订单",
  "Total Sell Orders": "总卖出订单",
  Share: "分享",
  "Reopen Ticket": "重新打开票",
  Comment: "评论",
  "Add your comment": "添加您的评论",
  "Comment must be less than 5000 characters": "评论必须小于5000个字符",
  "Comment is required": "需要评论",
  "Reopen Date": "重新开放日期",
  Proof: "证明",
  "Share your P2P profile": "分享您的P2P个人资料",
  Sort: "种类",
  "Select Token": "选择令牌",
  "Select Fiat": "选择法定",
  "Date Range": "日期范围",
  "Enter username": "输入用户名",
  "Trade Currency": "贸易货币",
  "Enter your message for seller": "输入卖方的消息",
  "Deposit Token": "存放令牌",
  "Withdraw Token": "撤回令牌",
  "Insufficient!": "不足的！",
  Wait: "等待",
  "Insufficient funds!": "不充足的资金！",
  "Insufficient withdraw!": "撤回不足！",
  Approve: "批准",
  "Approving...": "批准...",
  "Issues with Deposit?": "存款问题？",
  "All Networks": "所有网络",
  "Enter amount": "输入金额",
  "Enter price": "输入价格",
  "Select payment methods": "选择付款方式",
  "Enter order min and max limits": "输入订单最小和最大限制",
  "Enter valid min limit": "输入有效的最小限制",
  "Max limit must be less than": "最大限制必须小于",
  "Min limit must be less than": "最小限制必须小于",
  "Preview order": "预览订单",
  min: "最小",
  Global: "全球的",
  "Enter your name": "输入你的名字",
  "Choose your username": "选择你的用户名",
  "Enter your email": "输入你的电子邮箱",
  "Your Full Name": "你的全名",
  "A/c Number": "空调编号",
  "Account number": "帐号",
  "Bank Name": "银行名",
  "IFSC Code": "IFSC代码",
  "Note (Optional)": "注意（可选）",
  "Additional Notes": "补充笔记",
  "Phone Number": "电话号码",
  "Account phone number": "帐户电话号码",
  "Payment QR Code (Optional)": "付款QR码（可选）",
  "Please enter your QR Code": "请输入您的QR代码",
  Day: "天",
  Week: "星期",
  Month: "月",
  Months: "月份",
  hash: "哈希",
  "Enter txn hash": "输入TXN哈希",
  Success: "成功",
  Failed: "失败的",
  Review: "审查",
  Dropped: "掉了",
  LongPending: "长期",
  "Redeem Ended": "兑换结束了",
  "Please enter your bank account number": "请输入您的银行帐号",
  "Bank Name": "银行名",
  "Enter the name of your bank": "输入银行的名称",
  "Account opening branch(Optional)": "帐户开设分支（可选）",
  "Enter bank branch information": "输入银行分行信息",
  "Paypal ID / Number": "PayPal ID /号码",
  "Perfect Money ID / Number": "完美的钱ID /号码",
  "Bank Details (Optional)": "银行详细信息（可选）",
  "Please enter your bank details": "请输入您的银行详细信息",
  "Country of Residence (Optional)": "居住国（可选）",
  "Please enter your country of residence": "请进入您的居住国",
  "Username (Optional)": "用户名（可选）",
  "Account Username": "帐户用户名",
  "Account Email": "帐户的电子邮件",
  "Wallet ID": "钱包ID",
  "Revtag (Optional)": "REVTAG（可选）",
  "Please enter your Revtag": "请输入您的Revtag",
  "Phone number (Optional)": "电话号码（可选）",
  "Please enter payment details": "请输入付款详细信息",
  "Account number (USD)": "帐号（美元）",
  "Account number (KHR)(Optional)": "帐号（KHR）（可选）",
  "Payeer account number": "付费人帐号",
  "Enter your Payeer account number": "输入您的付费帐户号",
  "Email (Optional)": "电子邮件（可选）",
  "Account Email (Not required)": "帐户电子邮件（不需要）",
  "Email or Username": "电子邮件或用户名",
  "Account Email or Username": "帐户电子邮件或用户名",
  "Full Name of Receiver": "接收器的全名",
  "Receivers's Full Name": "接收者的全名",
  "Payment details (Optional)": "付款详细信息（可选）",
  "ID Number": "身份证号",
  "Please enter your National ID Number": "请输入您的国家ID号",
  "Account Type": "帐户类型",
  "Enter your account type": "输入您的帐户类型",
  "Name and Lastname": "名称和姓氏",
  "Enter Name and Lastname": "输入名称和姓氏",
  "Full Name": "全名",
  "Enter Full Name": "输入全名",
  Cashtag: "CASHTAG",
  "Please enter your Cashtag": "请输入您的cashtag",
  "Full Name (Optional)": "全名（可选）",
  "ID Number (Optional)": "ID号（可选）",
  "Address (Optional)": "地址（可选）",
  "Residential Address": "住宅地址",
  "Mobile Number": "手机号码",
  "Enter your Mobile Number available for all networks":
    "输入所有网络的手机号码",
  "Contact Number": "联系电话",
  "Phone Number": "电话号码",
  Operator: "操作员",
  "Mobile / Landline / Data / TV": "移动 /座机 /数据 /电视",
  "Enter account opening branch (Not required)": "输入帐户开放分支（不需要）",
  "IBAN Number": "IBAN号码",
  "BIC/SWIFT Code(Optional)": "BIC/SWIFT代码（可选）",
  "BIC/SWIFT Code": "BIC/SWIFT代码",
  "Enter international bank account number": "输入国际银行帐号",
  "SWIFT BIC (Optional)": "Swift BIC（可选）",
  "Enter your bank identification code": "输入您的银行标识代码",
  "GB IBAN account number": "GB IBAN帐号",
  "IBAN (Optional)": "iban（可选）",
  "Other payment details (for international transfer) (Optional)":
    "其他付款详细信息（用于国际转移）（可选）",
  "Details like your own address, the address of the bank, the IBAN code and SWIFTBIC code, etc.":
    "详细信息，例如您自己的地址，银行的地址，IBAN代码和Swiftbic代码等。",
  "Bank Branch (Optional)": "银行分支（可选）",
  "Recommended Transfer Remarks (Optional)": "推荐的转移备注（可选）",
  "Recommended Transfer Remarks": "推荐的转移备注",
  'Please enter your MoMo phone number"': "请输入您的MOMO电话号码”",
  "Please enter your MoMo QR Code": "请输入您的MOMO QR代码",
  "Please enter your Zalopay QR Code": "请输入您的Zalopay QR代码",
  "Additional account number (Optional)": "附加帐号（可选）",
  "Please enter additional account number": "请输入其他帐号",
  "Branch code (Optional)": "分支代码（可选）",
  "Enter branch code (not required)": "输入分支代码（不需要）",
  "IBAN (Optional)": "iban（可选）",
  "Please enter the IBAN code of your Bank (not required)":
    "请输入您的银行的IBAN代码（不需要）",
  "IBAN or account number": "iban或帐号",
  "Please enter your IBAN or account number": "请输入您的IBAN或帐号",
  "Papara account number": "帕帕拉帐号",
  "Please enter your Papara account number": "请输入您的Papara帐号",
  City: "城市",
  "Please enter your city": "请输入您的城市",
  "Zip Code": "邮政编码",
  "Please enter your city zipcode": "请输入您的城市邮政编码",
  Country: "国家",
  "Please enter Country": "请进入国家",
  "Moneygram Account Number": "Moneygram帐号",
  "Please enter your Moneygram account number": "请输入您的Moneygram帐号",
  "Enter bank branch information": "输入银行分行信息",
  "Please enter your BCA account number": "请输入您的BCA帐号",
  "Please enter your Mandiri account number": "请输入您的Mandiri帐号",
  "Chipper Cash full name": "Chipper现金全名",
  "Please enter your full name used to register on chipper cash":
    "请输入您用于注册Chipper现金的全名",
  "Chipper Tag": "Chipper标签",
  "Please enter your @chippertag. You can find it in your profile tab on Chipper Cash":
    "请输入您的@chippertag。您可以在Chipper Cash的个人资料标签中找到它",
  "QIWI account": "QIWI帐户",
  "Please enter your QIWI account": "请输入您的QIWI帐户",
  "Your email registered on Binance": "您的电子邮件注册了binance",
  "Please enter your email registered on Binance":
    "请输入您在binance上注册的电子邮件",
  "Payment Details": "付款详情",
  "WeChat account": "微信帐户",
  "Please enter your WeChat account": "请输入您的微信帐户",
  "Receive QR Code (Optional)": "接收QR码（可选）",
  "Alipay account": "支付宝帐户",
  "Please enter your Alipay account": "请输入您的支付帐户",
  "Payee's LINE ID": "收款人的线路ID",
  "Please fill in your LINE ID": "请填写您的行ID",
  "Please fill in your phone number registered with LINE":
    "请填写您的电话号码注册",
  "Linepay QR Code (Optional)": "LinePay QR码（可选）",
  "Reference number or barcode": "参考号或条形码",
  "Please enter reference number or barcode": "请输入参考号或条形码",
  "Remarks (Optional)": "备注（可选）",
  "Additional Remarks": "其他评论",
  "Cell Phone Number": "手机号码",
  "Please enter your cell phone number": "请输入您的手机号码",
  "Enter your ID number": "输入您的ID号",
  "Fill in your phone number": "填写您的电话号码",
  "Full Name of Receiver": "接收器的全名",
  "Please enter your Full Name": "请输入您的全名",
  CBU: "CBU",
  "Please enter your CBU": "请输入您的CBU",
  "CUIT / CUIL": "CUIT / CUIL",
  "Enter your unique tax identification code": "输入您的唯一税收标识代码",
  "Please enter your CVU": "请输入您的CVU",
  Alias: "别名",
  "Please enter Alias": "请输入别名",
  Lemontag: "Lemontag",
  "Lemon Wallet Tag": "柠檬钱包标签",
  "Uniform Bank Key": "统一银行钥匙",
  "DNI/CPF": "DNI/CPF",
  "Enter your DNI/CPF number": "输入您的DNI/CPF号码",
  "CVU (Optional)": "CVU（可选）",
  "Uniform Virtual Key": "统一的虚拟钥匙",
  "CBU/CVU or": "CBU/CVU或",
  "alias CVU/CBU or CVU/CBU number": "别名CVU/CBU或CVU/CBU编号",
  "Alias del CBU": "别名del cbu",
  "Vodafone cash Number": "沃达丰现金编号",
  "Please enter your vodafone cash Number": "请输入您的沃达丰现金编号",
  "Sort code": "排序代码",
  "Please fill in your 6-digit sort code": "请填写您的6位排序代码",
  "Sort Code(Optional)": "排序代码（可选）",
  "GB IBAN account number": "GB IBAN帐号",
  "IIN (Optional)": "IIN（可选）",
  "Enter individual identification number": "输入个人身份证号码",
  "Please enter your bank card number": "请输入您的银行卡号码",
  "Mobile Carrier": "手机运营商",
  "Please enter your mobile carrier": "请输入您的移动运营商",
  "Easypaisa mobile account": "EasyPaisa移动帐户",
  "Please enter your Easypaisa mobile account": "请输入您的Easypaisa移动帐户",
  "NayaPay Id": "Nayapay ID",
  "Please enter NayaPay Id": "请输入Nayapay ID",
  "Enter Brazilian valid document": "输入巴西有效文件",
  "Bank agency": "银行代理",
  "Enter the name of your bank agency": "输入您的银行代理商的名称",
  "Type of transfer": "转移类型",
  "Please filled with TED/DOC/PIX": "请装满TED/DOC/PIX",
  "Name and Last Name": "名称和姓氏",
  "Pix Key": "pix键",
  "Phone, email or key": "电话，电子邮件或钥匙",
  'Type of Bank"': "银行类型”",
  "Name registered in PicPay": "在PICPAY中注册的名称",
  "PicPay username": "PICPAY用户名",
  "Username used to log in in PicPay": "用户名曾经在PICPAY中登录",
  "urpay Account Details": "URPAY帐户详细信息",
  "Please enter your urpay account details": "请输入您的URPAY帐户详细信息",
  "bKash Wallet Number": "Bkash钱包编号",
  "Please enter your 11 digit registered bKash account number":
    "请输入您的11位注册的BKASH帐号",
  "Nagad Wallet Number": "纳加德钱包编号",
  "Please enter your 11 digit registered Nagad wallet number":
    "请输入您的11位注册纳加德钱包编号",
  "Please enter your full name registered with Rocket account":
    "请输入您在火箭帐户注册的全名",
  "Rocket Wallet Number": "火箭钱包编号",
  "Please enter your 12 digit Rocket Wallet number":
    "请输入您的12位火箭钱包编号",
  "Please enter your JKOPAY ID": "请输入您的jkopay ID",
  "EasyPay wallet number (Optional)": "Easypay钱包编号（可选）",
  "Please fill in EasyPay wallet number": "请填写Easypay钱包编号",
  "User API Key": "用户API键",
  "User Secret Key": "用户秘密密钥",
  "Bank account number": "银行帐号",
  PayID: "PayID",
  "Please enter your PayID": "请输入您的PayID",
  "Institution number": "机构编号",
  "Please enter institution number": "请输入机构编号",
  "PayCode (Optional)": "工资码（可选）",
  "Can be found in My Account ->My PayCode -> Copy the Link":
    "可以在我的帐户中找到 - >我的工资代码 - >复制链接",
  "Please fill in your mobile phone number connected with your PayMe":
    "请填写与薪资连接的手机号码",
  Cellphone: "手机",
  "Card Number or password": "卡号或密码",
  "Please fill in card Number or password": "请填写卡号或密码",
  "Purpose of payment (Optional)": "付款目的（可选）",
  "Enter a payment purpose for buyers": "输入买家的付款目的",
  "Card number, account or key": "卡号，帐户或钥匙",
  "CLABE number (Optional)": "克拉贝号（可选）",
  "CLABE number": "克拉贝号",
  "Account number/card": "帐号/卡",
  "Reference number or card number": "参考号或卡号",
  "Please enter reference number or card number": "请输入参考号或卡号",
  "Provide transfer details during the trade": "在交易期间提供转移细节",
  "Variable symbol (Optional)": "可变符号（可选）",
  "Enter Variable symbol": "输入变量符号",
  "Enter your PayNow account": "输入您的付费帐户",
  "Account Name": "帐户名称",
  "Email Address": "电子邮件地址",
  "Please enter email address": "请输入电子邮件地址",
  "Please enter Your Full Name": "请输入您的全名",
  "Account Number": "帐号",
  "Enter your phone number": "输入你的电话号码",
  "Enter your account number": "输入您的帐号",
  "Enter your bank account number": "输入您的银行帐号",
  "Payment details": "付款详情",
  "Bank name": "银行名",
  "Please enter your MoMo phone number": "请输入您的MOMO电话号码",
  "Please enter your email": "请输入您的电子邮件",
  "Enter branch code": "输入分支代码",
  "Please enter the IBAN code of your Bank": "请输入您银行的IBAN代码",
  "Account opening branch (Optional)": "帐户开设分支（可选）",
  "Enter account opening branch (not required)": "输入帐户开放分支（不需要）",
  "Please enter your Bank account number": "请输入您的银行帐号",
  "Please enter your ID Number": "请输入您的身份证号码",
  "Enter bank account type": "输入银行帐户类型",
  "Bank Branch (Optional)": "银行分支（可选）",
  "Account type": "帐户类型",
  "Please enter bank branch information": "请输入银行分支信息",
  "Please enter Email address": "请输入电子邮件地址",
  "Phone number(Optional)": "电话号码（可选）",
  "Phone Number (Optional)": "电话号码（可选）",
  "Additional information": "附加信息",
  "Type of Bank": "银行类型",
  "Remark (Optional)": "备注（可选）",
  "Mobile Phone": "手机",
  "Enter the bank code": "输入银行代码",
  "Please enter your phone number": "请输入您的电话号码",
  "Please enter your Bank name": "请输入您的银行名称",
};

export default CHINESE;
