import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import PopupLayout from "../common/popups/PopupLayout";
import TxPopup from "../common/popups/TxPopup";
import { useClaimCallback } from "../hooks/useClaimCallback";
import WalletButton from "common/WalletButton";
import { FormattedMessage } from "react-intl";
import { GLOBAL_TOKEN_LIST, IS_MAINNET } from "../constants/index";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
    paddingBottom: "5%",
    paddingLeft: "5%",
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border: "1px solid #eeeeee",
    padding: 20,
    backgroundColor: "rgba(255,0,0,0.06)",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  card: {
    padding: 10,
    border: "1px solid #919191",
    width: 400,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 20,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: 80,
      fontSize: 14,
    },
  },
}));

const Faucet = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const userSelectedChain = useSelector((state) => state?.user?.chainId);
  const authenticated = useSelector((state) => state?.user?.authenticated);

  const { claimTokens, transactionStatus, resetTrxState } = useClaimCallback();

  const claimTokensHandler = (tokenAddress) => {
    console.log("res");
    claimTokens(tokenAddress);
  };

  const handleModalClose = useCallback(() => {
    setOpen(false);
    resetTrxState();
  }, [isOpen]);

  useEffect(() => {
    // remove faucet page access for mainnet
    if (IS_MAINNET && pathname?.includes("/faucet")) {
      navigate("/");
    }
  }, [pathname]);

  const faucetTokens = useMemo(() => {
    if (!userSelectedChain) {
      return [];
    }

    if (userSelectedChain === 5) {
      const items = GLOBAL_TOKEN_LIST[userSelectedChain].filter(
        (item) => item.symbol !== "ETH"
      );
      return items;
    }
    const items = GLOBAL_TOKEN_LIST[userSelectedChain].filter(
      (item) => item.symbol !== "BNB"
    );
    return items;
  }, [userSelectedChain]);

  return (
    <div>
      <Box className={classes.background}>
        <PopupLayout popupActive={transactionStatus?.state > 0}>
          <TxPopup
            txCase={transactionStatus?.state}
            hash={transactionStatus?.hash}
            resetPopup={handleModalClose}
          />
        </PopupLayout>
        <Box mb={5}>
          <Typography
            variant="h3"
            color={theme.palette.text.primary}
            align="center"
          >
            <FormattedMessage id="Claim Faucet" defaultMessage="Claim Faucet" />
          </Typography>
          <div
            className="text-center"
            style={{ color: theme.palette.text.secondary }}
          >
            <FormattedMessage
              id="Claim test tokens for Goerli Testnet or BSC Testnet."
              defaultMessage="Claim test tokens for Goerli Testnet or BSC Testnet."
            />
          </div>
        </Box>
        {authenticated ? (
          <div>
            <Box
              mt={3}
              display={"flex"}
              flexDirection="column"
              alignItems="center"
            >
              {faucetTokens.map((faucetToken) => (
                <Box
                  className={classes.card}
                  style={{ backgroundColor: "#d1c4e9" }}
                  onClick={() => {
                    claimTokensHandler(faucetToken?.address);
                  }}
                >
                  <img
                    src={faucetToken.logo}
                    height="30px"
                    style={{ marginRight: 10 }}
                  />
                  {`Claim ${faucetToken?.symbol} Faucet (${
                    userSelectedChain === 5 ? "Goerli" : "BSC"
                  })`}
                </Box>
              ))}
            </Box>
          </div>
        ) : (
          <Container>
            <div
              className={classes.infoCard}
              style={{
                backgroundColor: "rgba(255,0,0,0.06)",
                border: "1px solid #eeeeee",
              }}
            >
              <Box
                height={250}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <div className="text-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/incognito-mode-5847986-4897792.png"
                    style={{ height: 100 }}
                  />
                </div>
                <Box my={2} style={{ color: theme.palette.text.primary }}>
                  <h4 className="text-center">
                    <FormattedMessage
                      id="Unauthorised Access!"
                      defaultMessage="Unauthorised Access!"
                    />
                  </h4>
                  <Typography textAlign="center">
                    <FormattedMessage
                      id="Please connect your wallet first to continue P2P trading."
                      defaultMessage="Please connect your wallet first to continue P2P trading."
                    />
                  </Typography>
                </Box>
                <WalletButton />
              </Box>
            </div>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default Faucet;
