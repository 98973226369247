import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Pagination from "common/Pagination";
import { FormattedMessage } from "react-intl";
import { formatTime, getAddEtherscanUrl } from "utils/helper";
import RequestView from "./RequestView";

export default function QuestsEndedTable({
  requests,
  classes,
  page,
  totalPages,
  changePage,
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box className={classes.tableCard}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            <th style={{ width: "15%", height: "20px" }}>
              <Typography
                textAlign="center"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage
                  id="Redeem Time"
                  defaultMessage="Redeem Time"
                />
              </Typography>
            </th>

            <th style={{ width: "15%", height: "20px" }}>
              <Typography
                textAlign="center"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Address" defaultMessage="Address" />
              </Typography>
            </th>

            <th style={{ height: "20px" }}>
              <Typography
                textAlign="center"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Points" defaultMessage="Points" />
              </Typography>
            </th>
            <th style={{ height: "20px" }}>
              <Typography
                textAlign="center"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Status" defaultMessage="Status" />
              </Typography>
            </th>
            <th style={{ height: "20px" }}>
              <Typography
                textAlign="center"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Action" defaultMessage="ACtion" />
              </Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {requests?.map((request, index) => (
            <tr className={classes.trHighlight} key={index}>
              {/* redeem time */}
              <td
                style={{
                  width: "15%",
                  padding: "16px 10px",
                  borderRadius: "10px 0 0 10px",
                }}
              >
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontSize={md ? 14 : 16}
                  className="d-flex flex-column justify-content-center align-content-center"
                  color={theme.palette.text.primary}
                >
                  <span>
                    {formatTime(request?.redeemed_at).substring(
                      formatTime(request?.redeemed_at).indexOf("M") + 1,
                      formatTime(request?.redeemed_at).length
                    )}
                  </span>
                  <span>
                    {formatTime(request?.redeemed_at).substring(
                      0,
                      formatTime(request?.redeemed_at).indexOf("M") + 1
                    )}
                  </span>
                </Typography>
              </td>
              {/* account */}
              <td
                className={classes.otherText}
                style={{ width: "15%", padding: "16px 10px" }}
              >
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontSize={md ? 14 : 16}
                  color={"#212121"}
                  className="d-flex flex-column"
                >
                  <a
                    component="button"
                    href={getAddEtherscanUrl(request?.account, 5)}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {request?.account?.slice(0, 5)}
                    {"..."}
                    {request?.account?.slice(
                      request?.account?.length - 5,
                      request?.account?.length
                    )}
                  </a>
                </Typography>
              </td>
              {/* points */}
              <td
                className={classes.otherText}
                style={{ width: "15%", padding: "16px 10px" }}
              >
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  style={{ fontWeight: 400 }}
                  className={classes.otherText}
                >
                  {request?.points}
                </Typography>
              </td>
              {/* status */}
              <td
                className={classes.otherText}
                style={{ width: "15%", padding: "16px 10px" }}
              >
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontSize={sm ? 14 : 16}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                  color={
                    request.status === "pending"
                      ? "#F9C74F"
                      : request?.status === "completed"
                      ? theme.palette.action.green
                      : theme.palette.action.red
                  }
                >
                  <FormattedMessage
                    id={request?.status}
                    defaultMessage={request?.status}
                  />
                </Typography>
              </td>
              {/* action */}
              <td
                className={classes.otherText}
                style={{
                  width: "15%",
                  padding: "16px 10px",
                  borderRadius: "0 10px 10px 0",
                  textAlign: "center",
                }}
              >
                <Button
                  style={{
                    borderRadius: 10,
                    backgroundColor: theme.palette.button.primary,
                    padding: "5px 20px 5px 20px",
                    color: "white",
                    margin: "auto",
                  }}
                  onClick={() => {
                    setRequest(request);
                    handleOpen();
                  }}
                >
                  <FormattedMessage id="View" defaultMessage="View" />
                </Button>
              </td>
            </tr>
          ))}
          {open && request && (
            <RequestView
              popupActive={open}
              handleClose={handleClose}
              request={request}
            />
          )}
        </tbody>
      </table>

      {totalPages > 1 && (
        <Pagination
          page={page}
          changePage={changePage}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
}
