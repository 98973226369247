import BigNumber from "bignumber.js";
import moment from "moment";
import { COUNTRY_FIAT, GLOBAL_FIAT_LIST, IMAGE_TYPES } from "../constants";
import axios from "axios";
import { SupportedChainId } from "../constants/chains";
import { BASE_API_ENDPOINT } from "./httpCalls";

export const fromWei = (tokens, decimals = 18) => {
  try {
    if (!tokens) {
      return new BigNumber(0).toString();
    }

    return new BigNumber(tokens)
      .div(new BigNumber(10).exponentiatedBy(decimals))
      .toString();
  } catch (error) {
    console.log("exeption in fromWei ", error);
    return null;
  }
};

export const toWei = (tokens, decimals = 18) => {
  try {
    if (!tokens) {
      return new BigNumber(0).toString();
    }
    return new BigNumber(tokens)
      .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
      .toFixed(0)
      .toString();
  } catch (error) {
    console.log("exeption in toWei , ", error);
    return null;
  }
};

export const formatLargeNumber = (value, precision = 2) => {
  const _value = !value ? "0" : value;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: precision,
  });

  const formattedValue = convertToInternationalCurrencySystem(
    _value,
    formatter
  );

  return formattedValue;
};

export const formatCurrency = (
  value,
  usd = false,
  fractionDigits = 1,
  currencyFormat = false
) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: 4,
  });

  //for currency format with $symbol
  if (usd) {
    return formatter.format(value ? value : 0)?.slice(1);
  }

  if (typeof window.web3 === "undefined") {
    return formatter.format(value ? value : 0).slice(1);
  }

  return formatter.format(value ? value : 0).slice(1);
};

function convertToInternationalCurrencySystem(labelValue, formatter) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? formatter
        .format((Math.abs(Number(labelValue)) / 1.0e9).toFixed(2))
        .slice(1) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? formatter
        .format((Math.abs(Number(labelValue)) / 1.0e6).toFixed(2))
        .slice(1) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? formatter
        .format((Math.abs(Number(labelValue)) / 1.0e3).toFixed(2))
        .slice(1) + "K"
    : formatter.format(Math.abs(Number(labelValue))).slice(1);
}

export const resetCurrencyFormatting = (value) => {
  return value.split(",").join("");
};

export const isNumber = (value) => {
  return !isNaN(parseInt(value));
};
export const isTransaction = (value) => {
  return /^0x([A-Fa-f0-9]{64})$/.test(value);
};
export const isValidAccount = (value) => {
  return /^0x[a-fA-F0-9]{40}$/.test(value);
};

export function getIsBraveWallet() {
  return window.ethereum?.isBraveWallet ?? false;
}

export const isInjectedWalletInstalled = () => {
  return typeof window.web3 !== "undefined";
};

export const isMetaMaskInstalled = () => {
  // When using Brave browser, `isMetaMask` is set to true when using the built-in wallet
  // This function should return true only when using the MetaMask extension
  // https://wallet-docs.brave.com/ethereum/wallet-detection#compatability-with-metamask
  return (window.ethereum?.isMetaMask ?? false) && !getIsBraveWallet();
};

export const isTrustWalletInstalled = () => {
  // When using Brave browser, `isMetaMask` is set to true when using the built-in wallet
  // This function should return true only when using the MetaMask extension
  // https://wallet-docs.brave.com/ethereum/wallet-detection#compatability-with-metamask
  return (window.ethereum?.isTrustWallet || window?.ethereum?.isTrust) ?? false;
};

export const formattedAddress = (address) => {
  const _address = address?.toString();
  if (!address) {
    return "";
  }

  const _formatted = _address?.slice(0, 4) + "..." + _address?.slice(-4);
  return _formatted;
};
// //input  { chainId, chainName, currency: {name, symbol, decimals }, rpcUrls, blockExplorer }
export const setupNetwork = async (networkObject, library) => {
  const provider = library?.provider; //window.ethereum;
  if (provider) {
    // const _chainId = parseInt(networkObject.chainId, 10)
    try {
      if (
        networkObject.chainId ===
          `0x${SupportedChainId.MAINNET.toString(16)}` ||
        networkObject.chainId === `0x${SupportedChainId.GOERLI.toString(16)}`
      ) {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networkObject.chainId }],
        });
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [networkObject],
      });
      return true;
    } catch (error) {
      console.error("Failed to setup the network in Metamask:", error);
      // alert("Reload to connect");
      return false;
    }
  } else {
    console.log(
      "setup error Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};

export const formatTime = (dateString, requirement = null) => {
  if (!dateString) {
    return "";
  }

  if (!requirement) {
    return moment(moment.utc(dateString).toDate())
      .local()
      .format("hh:mm A DD-MM-YYYY");
  }

  if (requirement === "date-only") {
    return moment(moment.utc(dateString).toDate()).local().format("DD-MM-YYYY");
  }
};

export const compareStrings = (str1, str2) => {
  if (!str1 || !str2) {
    return false;
  }
  const str1WithoutSpacing = str1?.replace(/^\s+|\s+$/gm, "");
  const str2WithoutSpacing = str1?.replace(/^\s+|\s+$/gm, "");
  return str1WithoutSpacing === str2WithoutSpacing;
};

export const recoverKeys = (key) => {
  if (!key) {
    return null;
  }
  return key?.split("").reverse().join("");
};

export const getTrxEtherscanUrl = (trxHash, chainId) => {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/tx/${trxHash}`;
    case 5:
      return `https://goerli.etherscan.io/tx/${trxHash}`;
    case 97:
      return `https://testnet.bscscan.com/tx/${trxHash}`;
    case 56:
      return `https://bscscan.com/tx/${trxHash}`;
    default:
      return `https://etherscan.io/tx/${trxHash}`;
  }
};

export const getAddEtherscanUrl = (address, chainId) => {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/address/${address}`;
    case 5:
      return `https://goerli.etherscan.io/address/${address}`;
    case 97:
      return `https://testnet.bscscan.com/address/${address}`;
    case 56:
      return `https://bscscan.com/address/${address}`;
    default:
      return `https://goerli.etherscan.io/address/${address}`;
  }
};

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export function camelize(str) {
  if (!str) {
    return "";
  }

  const ele = str[0]?.toUpperCase();
  const ele2 = str.slice(1);
  return ele + ele2;
}

export function toMinutes(seconds) {
  if (!seconds) {
    return "0";
  }

  try {
    const d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  } catch (error) {
    console.log("toMinutes error ", error);
    return "0";
  }
}

export function getTimeDiff(time) {
  //minutes

  try {
    const currentTime = new Date(); //
    const txnTime = new Date(time);
    var diff = Math.abs(currentTime - txnTime) / (1000 * 60); //minutes
    return diff;
  } catch (error) {
    console.log("getTimeDiff error ", error);
    return "0";
  }
}

export const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number?.toString();
  }
};

export const getAdminMessageHistory = (messageType, dateTime, intl) => {
  switch (messageType) {
    case "created":
      return `${intl.formatMessage({
        id: "Transaction has been created on",
        defaultMessage: "Transaction has been created on",
      })} ${dateTime}`;
    case "paid":
      return `${intl.formatMessage({
        id: "Transaction has been marked as paid on",
        defaultMessage: "Transaction has been marked as paid on",
      })} ${dateTime}`;
    case "proof":
      return `${intl.formatMessage({
        id: "Payment proof for is uploaded on",
        defaultMessage: "Payment proof for is uploaded on",
      })} ${dateTime}`;
    case "cancelled-by-buyer":
      return `${intl.formatMessage({
        id: "Transaction has been cancelled by buyer on",
        defaultMessage: "Transaction has been cancelled by buyer on",
      })} ${dateTime}`;
    case "cancelled-by-seller":
      return `${intl.formatMessage({
        id: "Transaction has been cancelled by seller on",
        defaultMessage: "Transaction has been cancelled by seller on",
      })} ${dateTime}`;
    case "notified-by-buyer":
      return `${intl.formatMessage({
        id: "Buyer has notified the seller on",
        defaultMessage: "Buyer has notified the seller on",
      })} ${dateTime}`;
    case "notified-by-seller":
      return `${intl.formatMessage({
        id: "Seller has notified the buyer on",
        defaultMessage: "Seller has notified the buyer on",
      })} ${dateTime}`;
    case "dispute-by-buyer":
      return `${intl.formatMessage({
        id: "Buyer has raised a dispute on",
        defaultMessage: "Buyer has raised a dispute on",
      })} ${dateTime}`;
    case "dispute-by-seller":
      return `${intl.formatMessage({
        id: "Seller has raised a dispute on",
        defaultMessage: "Seller has raised a dispute on",
      })} ${dateTime}`;
    case "released":
      return `${intl.formatMessage({
        id: "Seller has released the tokens and the transaction is completed on",
        defaultMessage:
          "Seller has released the tokens and the transaction is completed on",
      })} ${dateTime}`;
    default:
      return "";
  }
};

export const getAdminMessage = (messageType, userType, dateTime, intl) => {
  switch (userType) {
    case "buyer":
      switch (messageType) {
        case "created":
          return `${intl.formatMessage({
            id: "Your buy transaction has been created successfully on",
            defaultMessage:
              "Your buy transaction has been created successfully on",
          })} ${dateTime}`;
        case "paid":
          return `${intl.formatMessage({
            id: "You marked the transaction as paid on",
            defaultMessage: "You marked the transaction as paid on",
          })} ${dateTime}`;
        case "proof":
          return `${intl.formatMessage({
            id: "You uploaded the payment proof for the transaction on",
            defaultMessage:
              "You uploaded the payment proof for the transaction on",
          })} ${dateTime}`;
        case "cancelled-by-buyer":
          return `${intl.formatMessage({
            id: "You have cancelled the transaction on",
            defaultMessage: "You have cancelled the transaction on",
          })} ${dateTime}`;
        case "cancelled-by-seller":
          return `${intl.formatMessage({
            id: "Seller has cancelled the transaction on",
            defaultMessage: "Seller has cancelled the transaction on",
          })}${dateTime}`;
        case "notified-by-buyer":
          return `${intl.formatMessage({
            id: "You have notified the seller on",
            defaultMessage: "You have notified the seller on",
          })} ${dateTime}`;
        case "notified-by-seller":
          return `${intl.formatMessage({
            id: "Seller has notified you on",
            defaultMessage: "Seller has notified you on",
          })} ${dateTime}`;
        case "dispute-by-buyer":
          return `${intl.formatMessage({
            id: "You have raised a dispute on",
            defaultMessage: "You have raised a dispute on",
          })} ${dateTime}`;
        case "dispute-by-seller":
          return `${intl.formatMessage({
            id: "Seller has raised a dispute on",
            defaultMessage: "Seller has raised a dispute on",
          })} ${dateTime}`;
        case "released":
          return `${intl.formatMessage({
            id: "Seller has released the tokens and the transaction is successfully completed on",
            defaultMessage:
              "Seller has released the tokens and the transaction is successfully completed on",
          })} ${dateTime}`;
        default:
          return "";
      }
    case "seller":
      switch (messageType) {
        case "created":
          return `${intl.formatMessage({
            id: "Your sell transaction has been created successfully on",
            defaultMessage:
              "Your sell transaction has been created successfully on",
          })} ${dateTime}`;
        case "paid":
          return `${intl.formatMessage({
            id: "Buyer marked the transaction as paid on",
            defaultMessage: "Buyer marked the transaction as paid on",
          })} ${dateTime}`;
        case "proof":
          return `${intl.formatMessage({
            id: "Buyer uploaded the payment proof for the transaction on",
            defaultMessage:
              "Buyer uploaded the payment proof for the transaction on",
          })} ${dateTime}`;
        case "cancelled-by-buyer":
          return `${intl.formatMessage({
            id: "Buyer has cancelled the transaction on",
            defaultMessage: "Buyer has cancelled the transaction on",
          })} ${dateTime}`;
        case "cancelled-by-seller":
          return `${intl.formatMessage({
            id: "You have cancelled the transaction on",
            defaultMessage: "You have cancelled the transaction on",
          })} ${dateTime}`;
        case "notified-by-buyer":
          return `${intl.formatMessage({
            id: "Buyer has notified you on",
            defaultMessage: "Buyer has notified you on",
          })} ${dateTime}`;
        case "notified-by-seller":
          return `${intl.formatMessage({
            id: "You notified the buyer you on",
            defaultMessage: "You notified the buyer you on",
          })} ${dateTime}`;
        case "dispute-by-buyer":
          return `${intl.formatMessage({
            id: "Buyer has raised a dispute on",
            defaultMessage: "Buyer has raised a dispute on",
          })}  ${dateTime}`;
        case "dispute-by-seller":
          return `${intl.formatMessage({
            id: "You have raised a dispute on",
            defaultMessage: "You have raised a dispute on",
          })} ${dateTime}`;
        case "released":
          return `${intl.formatMessage({
            id: "You have released the tokens and the transaction is successfully completed on",
            defaultMessage:
              "You have released the tokens and the transaction is successfully completed on",
          })} ${dateTime}`;
        default:
          return "";
      }
    default:
      return "";
  }
};

export const getFiatFromId = (fiat) => {
  const fiats = [...GLOBAL_FIAT_LIST];

  const result = fiats.filter((option) => {
    return option?._id === fiat;
  });
  if (result[0]) return result[0].fiat;
  return null;
};

//time limit in seconds
export const isExpired = (created, timeLimit) => {
  let expiryTime = new Date(created);
  expiryTime.setSeconds(expiryTime.getSeconds() + timeLimit);

  return Date.now() > expiryTime;
};

//get location on login
export const getLocation = async (account) => {
  try {
    const response = await axios.get(
      `${BASE_API_ENDPOINT}/auth-apis/v1/location`
    );

    if (response?.status !== 200) {
      console.log("error fetching location:", response);
      return null;
    }

    const locData = `${response?.data?.ip}_${response?.data?.city},${response?.data?.region},${response?.data?.country}`;

    //saved preference
    localStorage.setItem(`last_saved_location-${account}`, locData);

    return locData;
  } catch (error) {
    console.log("error fetching location :", error);
    return;
  }
};

//get local currency
export const getLocalCurrency = async (account) => {
  try {
    const lastSavedCurrency = localStorage.getItem(
      `last_saved_currency-${account}`
    );
    // if not saved then fetch from ipinfo
    if (!lastSavedCurrency || lastSavedCurrency === "null") {
      // const response = await axios.get(
      //   `https://ipinfo.io/json?token=17d331c7fcbda3`
      // );
      const response = await axios.get(
        `${BASE_API_ENDPOINT}/auth-apis/v1/location`
      );
      if (response?.status !== 200) {
        return "6263a50d54f64766e549a621";
      }
      //get local currency
      const fiat = COUNTRY_FIAT[response?.data?.country];
      //find the id for the local currency
      const fiatId = GLOBAL_FIAT_LIST.find(
        (fiatObject) => fiatObject.fiat === fiat
      )?._id;

      //saved preference
      if (fiatId) {
        localStorage.setItem(`last_saved_currency-${account}`, fiatId);
      }
      //if id not found for the country ,return USD
      return fiatId ? fiatId : "6263a50d54f64766e549a621"; //default to USD
    }
    return lastSavedCurrency;
  } catch (error) {
    console.log("error fetching local currency :", error);
    return "6263a50d54f64766e549a621"; //default to USD
  }
};

export const isImage = (image_url) => {
  if (!image_url) {
    return true;
  }
  const fileExts = image_url?.split(".");
  if (IMAGE_TYPES.includes(fileExts?.[fileExts?.length - 1])) {
    return true;
  }
  return false;
};

export const formattedTokenLabel = (token) => {
  try {
    if (!token) {
      return "";
    }
    if (token === "All" || token === "all") {
      return "All";
    }
    if (["PBR", "USDT"].includes(token?.symbol)) {
      return [1, 5].includes(token?.chainId)
        ? `${token?.symbol}-ETH`
        : `${token?.symbol}-BSC`;
    }
    return token?.symbol;
  } catch (error) {
    console.log("formattedTokenLabel exeption ", { error });
    return token?.symbol;
  }
};

export const getDateTime = (date) => {
  return new Date(parseInt(date)).toLocaleString();
};

export const isValidNumber = (str) => {
  // Use the unary plus operator to convert the string to a number
  const num = +str;

  // Check if the result is a finite non-negative number or a big number
  return (
    typeof num === "number" &&
    !isNaN(num) &&
    num >= 0 &&
    (isFinite(num) || new BigNumber(str).isFinite())
  );
};

// increase gas limit by 30%
export const increaseGasLimit = (gasLimit, percent = 30) => {
  if (!isValidNumber(gasLimit)) {
    return 100000; // 100K
  }

  try {
    return parseInt(
      new BigNumber(gasLimit)
        .multipliedBy(100 + percent)
        .div(100)
        .toString()
    );
  } catch (error) {
    return 100000; // 100K
  }
};
