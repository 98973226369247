import React, { useCallback } from "react";
import { DAPP_SUPPORTED_ON_CHAINS } from "../constants";
import { Box, FormControl, MenuItem, Select, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_CHAIN } from "../actions/types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
  },
  imgIcon: {
    height: 18,
  },
  buttonDrop: {
    display: "flex",
    justifyContent: "space-between",
    color: "black",

    backgroundColor: theme.palette.background.secondary,
    "&:hover": {
      backgroundColor: "grey",
      color: "#100525",
    },
  },
  dropdown: {
    backgroundColor: theme.palette.background.secondary,
    padding: "6px 0px 3px 12px",
    color: "white",
    borderRadius: 10,
    border: "none",
  },
  networkName: {},
}));

const NetworkSelect = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userSelectedChain = useSelector((state) => state?.user?.chainId);

  const handleChainSelection = useCallback(
    (chain) => {
      dispatch({ type: SET_USER_CHAIN, payload: parseInt(chain) });
      localStorage.userSelectedChain = chain;
    },
    [dispatch]
  );

  return (
    <Box display="flex" alignItems="center" className="network-select">
      <FormControl
        className={classes.root}
        size="small"
        style={{ marginRight: 7 }}
      >
        <Select
          variant="standard"
          className={classes.dropdown}
          value={userSelectedChain}
          disableUnderline={true}
          notched="false"
          sx={{ fontWeight: 500, fontSize: 14 }}
          id="adornment-weight"
          onChange={({ target: { value } }) => handleChainSelection(value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 350,
                width: 250,
                backgroundColor: theme.palette.dropdown.main,
              },
            },
          }}
        >
          <MenuItem
            value={DAPP_SUPPORTED_ON_CHAINS[0]}
            className={classes.buttonDrop}
          >
            <span style={{ fontSize: 14 }}>Ethereum</span>
            <img
              className={classes.imgIcon}
              alt="ethereum"
              src={
                "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880"
              }
            />
          </MenuItem>
          <MenuItem
            value={DAPP_SUPPORTED_ON_CHAINS[1]}
            className={classes.buttonDrop}
          >
            <span style={{ fontSize: 14 }}>BSC</span>
            <img
              className={classes.imgIcon}
              alt="bsc"
              src={
                "https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png?1600947313"
              }
            />
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default NetworkSelect;
