import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close, SwapHoriz, Edit, CurrencyExchange } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Checkbox,
  Input,
  Button,
} from "@mui/material";
import {
  fromWei,
  formatCurrency,
  formattedNumber,
  isValidAccount,
  getLocation,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { redeem } from "utils/httpCalls/questCalls";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxWidth: 788,
    maxHeight: "100%",
    // overflowY: "scroll",
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },

  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },

  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: 10,
    width: "90%",
  },
  roundedBox: {
    background:
      theme.palette.mode === "light" ? "rgba(189, 180, 241, 0.6)" : "#393D68",
    display: "flex",
    padding: "15px 10px",
    width: "95%",
    borderRadius: 25,
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
}));

const RedeemPopup = ({ resetPopup, maxPoints }) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(0);

  const currentUserAuth = useSelector((state) => state?.user);
  const [account, setAccount] = useState(currentUserAuth?.account);

  const dispatch = useDispatch();

  const handleConfirm = useCallback(async () => {
    setError(null);
    console.log(amount, account);
    if (!currentUserAuth?.jwtToken) {
      setError("Invalid auth state please refresh and try again!");
      return;
    }
    if (amount < 100) {
      setError("Minimum redeem limit is 100 points.");
      return;
    }
    console.log("redeeming.....");
    setLoading(true);

    const location = await getLocation();
    const payload = {
      points: amount,
      account: account,
      location: location,
    };

    const result = await redeem(
      payload,
      currentUserAuth?.jwtToken,
      currentUserAuth?.account
    );

    setLoading(false);

    if (result?.status !== 201) {
      setError(result?.message?.msg);
      return;
    }

    resetPopup();
    return;
  }, [loading, setLoading, amount, account, currentUserAuth?.jwtToken]);

  const handleMax = useCallback(() => {
    setAmount(maxPoints);
  }, [maxPoints]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const isConfirmDisabled = useMemo(() => {
    if (editMode) {
      return true;
    }
    return false;
  }, [editMode]);

  const formattedNumber = (number, decimals) => {
    const numberArray = number.toString().split(".");
    const integer = numberArray?.[0];
    const decimal = numberArray?.[1];

    const formattedNumber = integer
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^0+(?=\d)/, "");

    return decimal !== undefined
      ? `${formattedNumber}.${decimals ? decimal.slice(0, decimals) : decimal}`
      : formattedNumber;
  };
  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className="d-flex justify-content-end">
          <Close
            style={{ color: theme.palette.text.primary, fontSize: 28 }}
            onClick={resetPopup}
          />
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Box className={classes.heading}>
            <FormattedMessage
              id="Redeem Points"
              defaultMessage="Redeem Points"
            />
          </Box>
        </div>
        <Box display="flex" justifyContent="center" pt={sm ? 1 : 2}>
          <Box className={classes.cautionTextBox}>
            {/* <Typography
              textAlign="left"
              fontSize={sm ? 13 : 16}
              color={theme.palette.mode === "light" ? "#919191" : "#ffffff"}
              fontWeight={sm ? 400 : 500}
            >
              <FormattedMessage
                id="pointpbr"
                defaultMessage="1. The total reward for Quest round 1 will be 60,000 PBR. The points will be converted to the corresponding PBR, depending on the number of points requested"
              />
            </Typography> */}
            <Typography
              textAlign="left"
              fontSize={sm ? 13 : 16}
              color={theme.palette.mode === "light" ? "#919191" : "#ffffff"}
              fontWeight={sm ? 400 : 500}
            >
              <FormattedMessage
                id="Redeem Points limit is 100 Points"
                defaultMessage="Redeem Points limit is 100 Points"
              />
            </Typography>
            {/* <Typography
              textAlign="left"
              fontSize={sm ? 13 : 16}
              color={theme.palette.mode === "light" ? "#919191" : "#ffffff"}
              fontWeight={sm ? 400 : 500}
            >
              <FormattedMessage
                id="pointsubmit"
                defaultMessage="3. Submit your redeem request, admin will review and transfer token in your wallet. Distribution time from 24th - 27th March UTC"
              />
            </Typography> */}
          </Box>
        </Box>
        {/* swap text */}
        <Box
          pt={4}
          px={sm && 1}
          display="flex"
          w="100%"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box textAlign="center">
            <Typography
              textAlign="left"
              fontSize={sm ? 13 : 18}
              fontWeight={sm ? 400 : 600}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            >
              <FormattedMessage id="Points" defaultMessage="Points" />
              <Button
                size={sm ? "small" : "large"}
                variant="text"
                style={{
                  borderRadius: 10,

                  padding: "5px 20px 5px 20px",
                  color: theme.palette.action.red,
                }}
                onClick={handleMax}
              >
                <FormattedMessage id="Max" defaultMessage="Max" />
              </Button>
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
              style={{
                border: "2px solid #6A55EA",
                borderRadius: 4,
                paddingLeft: sm ? 8 : 10,
                paddingRight: sm ? 8 : 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <Input
                type="text"
                value={formattedNumber(amount, 4)}
                placeholder="0"
                inputProps={{
                  min: 0,
                }}
                onChange={(e) => {
                  e.target.value = e.target.value
                    .replace(/,/g, "")
                    .replace(/[^\d]/g, "");

                  setAmount(e.target.value);
                }}
                disableUnderline={true}
                className={classes.input}
                style={{ width: "100%" }}
              />
            </Box>
          </Box>
          <div className="mx-1 align-items-center align-content-center align-self-end">
            <SwapHoriz
              sx={{
                color: "#6A55EA",
                fontSize: sm ? 40 : 70,
                alignSelf: "end",
              }}
            />
          </div>
          <Box textAlign="center">
            <Typography
              textAlign="left"
              fontSize={sm ? 13 : 18}
              fontWeight={sm ? 400 : 600}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            >
              <FormattedMessage id="Tokens" defaultMessage="Tokens" />
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
              style={{
                border: "2px solid #6A55EA",
                borderRadius: 4,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <Input
                type="text"
                value={formattedNumber(amount, 4)}
                disabled={true}
                placeholder="0"
                inputProps={{
                  min: 0,
                }}
                disableUnderline={true}
                className={classes.input}
                style={{ width: "100%" }}
              />
              <Typography
                textAlign="center"
                fontSize={sm ? 13 : 18}
                fontWeight={sm ? 400 : 600}
                color={"#6A55EA"}
              >
                PBR
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" pt={4} pb={2}>
          <div
            className={classes.roundedBox}
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Typography
              textAlign="left"
              fontSize={sm ? 13 : 16}
              color={theme.palette.mode === "light" ? "#919191" : "#ffffff"}
              fontWeight={sm ? 400 : 500}
            >
              <FormattedMessage
                id="Wallet Address"
                defaultMessage="Wallet Address"
              />
              :
            </Typography>
            <div
              className="d-flex justify-content-center align-content-center align-items-center"
              style={{ width: "60%" }}
            >
              {!editMode ? (
                sm ? (
                  <Typography
                    textAlign="left"
                    fontSize={14}
                    color={"#6A55EA"}
                    fontWeight={sm ? 300 : 400}
                  >
                    {account.slice(0, 5)}
                    {"..."}
                    {account.slice(account.length - 5, account.length)}
                  </Typography>
                ) : (
                  <Typography
                    textAlign="left"
                    fontSize={14}
                    color={"#6A55EA"}
                    fontWeight={sm ? 300 : 400}
                  >
                    {account}
                  </Typography>
                )
              ) : (
                <Input
                  type="text"
                  value={account}
                  disabled={!editMode}
                  error={!isValidAccount(account)}
                  placeholder="0"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    setAccount(e.target.value);
                  }}
                  disableUnderline={true}
                  style={{ width: "100%" }}
                  fullWidth={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#6A55EA",
                    },
                    fontSize: 14,
                  }}
                />
              )}
              {/* <Edit
                sx={{
                  color: "#6A55EA",
                  fontSize: sm ? 16 : 18,
                  paddingLeft: 0.2,
                  marginLeft: 1,
                }}
                onClick={() => setEditMode(!editMode)}
              /> */}
            </div>
          </div>
        </Box>

        <div className="pt-3 d-flex justify-content-center">
          <Typography
            fontSize={sm ? 14 : 17}
            fontWeight={sm ? 400 : 600}
            color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            textAlign="center"
          >
            <FormattedMessage
              id="Confirm your wallet address and submit your request."
              defaultMessage="Confirm your wallet address and submit your request."
            />
          </Typography>
        </div>
        {error && (
          <Typography
            fontSize={sm ? 13 : 16}
            fontWeight={400}
            color={"red"}
            textAlign="center"
          >
            <FormattedMessage id={error} defaultMessage={error} />
          </Typography>
        )}
        <Box className="d-flex justify-content-center align-items-baseline pt-3">
          <div>
            <button
              onClick={handleConfirm}
              style={{
                background: isConfirmDisabled
                  ? theme.palette.mode === "light"
                    ? "#bdbdbd"
                    : "#646892"
                  : "#6A55EA",

                border: isConfirmDisabled
                  ? theme.palette.mode === "light"
                    ? "1px solid #bdbdbd"
                    : "1px solid #646892"
                  : `1px solid #6A55EA`,
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: sm ? 15 : 20,
                paddingRight: sm ? 15 : 20,
                fontSize: sm ? 13 : 16,
                textDecoration: "none",
                color: "#fff",
              }}
              disabled={isConfirmDisabled || loading}
            >
              {loading ? (
                <FormattedMessage
                  id="Please wait..."
                  defaultMessage="Please wait..."
                />
              ) : (
                <FormattedMessage id="Submit" defaultMessage="Submit" />
              )}
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default RedeemPopup;
