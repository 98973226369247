import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import copy from "copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import { useSelector } from "react-redux";
import allPaymentOptions from "../../../constants/payment_options.json";
import AlertPopup from "../../../common/AlertPopup";
import ConfirmPopup from "../../../common/popups/ConfirmPopup";
import PopupLayout from "../../../common/popups/PopupLayout";
import CancelTradePopup from "../../../common/popups/CancelTradePopup";
import { FormattedMessage } from "react-intl";

export default function PaymentInfo({
  classes,
  pendingTrade,
  isFiatTransferDone,
  isTimeExpire,
  isTradeCancelled,
}) {
  // selected payment option index
  const [selectedPaymentModeIndex, setSelectedMode] = useState(0);
  const theme = useTheme();
  const tradePaymentOptions = useMemo(() => {
    if (
      !pendingTrade?.order?.payment_options ||
      !pendingTrade?.seller?.payment_options
    ) {
      return [];
    }

    const sellerTradePaymentOptions = pendingTrade?.order?.payment_options;
    const sellerAllPaymentOptions = pendingTrade?.seller?.payment_options;

    return sellerAllPaymentOptions?.filter((option) =>
      sellerTradePaymentOptions?.includes(option?.payment_mode)
    );
  }, [pendingTrade]);

  const [open, setOpen] = useState(false);

  const [zoomedImage, setZoomedImage] = useState(null);
  const tradeLoading = useSelector((state) => state?.userTrade?.loading);

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //handling confirm popup

  const [confirm, setConfirm] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);

  const handleModalClose = useCallback(() => {
    setConfirm(false);
  }, [setConfirm]);

  const allPaymentModes = useMemo(() => {
    if (!pendingTrade?.fiat) {
      return [];
    }

    return allPaymentOptions?.[pendingTrade?.order?.fiat?.fiat];
  }, [pendingTrade]);

  const selectedPaymentOption = useMemo(() => {
    if (!tradePaymentOptions) {
      return {};
    }
    if (tradePaymentOptions?.length === 0) {
      return {};
    }

    return tradePaymentOptions?.[selectedPaymentModeIndex];
  }, [tradePaymentOptions, selectedPaymentModeIndex]);

  const selectedPaymentOptionFields = useMemo(() => {
    if (!selectedPaymentOption?.payment_mode) {
      return [];
    }

    const option = allPaymentModes?.find(
      (item) => item?.provider === selectedPaymentOption?.payment_mode
    );

    if (!option?.fields) {
      return [];
    }

    return option.fields;
  }, [selectedPaymentOption, allPaymentModes]);

  return (
    <div
      className={classes.paymentCard}
      style={sm ? { paddingLeft: "8px" } : {}}
    >
      <div
        className={classes.zoomedImageContainer}
        onClick={() => setZoomedImage(null)}
        style={{ display: zoomedImage ? "flex" : "none" }}
      >
        <img alt="zoomed" src={zoomedImage} className={classes.zoomedImage} />
      </div>
      {confirm && (
        <PopupLayout popupActive={confirm}>
          <ConfirmPopup
            paymentOption={selectedPaymentOption}
            paymentOptionFields={selectedPaymentOptionFields}
            resetPopup={handleModalClose}
            pendingTrade={pendingTrade}
            message="CONFIRM"
          />
        </PopupLayout>
      )}
      {cancelPopup && (
        <PopupLayout popupActive={cancelPopup}>
          <CancelTradePopup
            resetPopup={() => setCancelPopup(false)}
            isSeller={false}
            trade={pendingTrade}
          />
        </PopupLayout>
      )}
      <AlertPopup
        message="Copied!"
        open={open}
        severity="success"
        handlePopupClose={handleClose}
      />
      <div className="row w-100" style={{ maxWidth: 600, minHeight: 200 }}>
        <div className="col-md-4">
          {tradePaymentOptions?.map((paymentOption, index) => (
            <Box
              my={1}
              key={index}
              style={{
                backgroundColor:
                  selectedPaymentOption?.payment_mode ===
                  paymentOption?.payment_mode
                    ? theme.palette.mode === "light"
                      ? "#f9f9f9"
                      : "#646892"
                    : theme.palette.background.secondary,
                cursor: "pointer",
                marginTop: "20",
              }}
              onClick={() => setSelectedMode(index)}
            >
              <Typography
                textAlign="left"
                variant="body2"
                fontSize={15}
                color={theme.palette.text.primary}
                fontWeight={600}
                pl={2}
                p={1}
                style={{
                  borderLeft:
                    selectedPaymentOption?.payment_mode ===
                    paymentOption?.payment_mode
                      ? "3px solid #6A55EA"
                      : "none",
                  textTransform: "uppercase",
                }}
              >
                {paymentOption?.payment_mode}
              </Typography>
            </Box>
          ))}
        </div>
        {/* display selected payment details */}
        <div className="col-md-8">
          <Box
            my={1.5}
            display={selectedPaymentOption ? "flex" : "none"}
            flexDirection={sm && "column"}
            alignItems={!sm && "center"}
            flexWrap="wrap"
          >
            <label
              htmlFor="inputEmail4"
              className={classes.label}
              style={{
                float: "left",
                minWidth: "fit-content",
                width: "50%",
                marginRight: 5,
              }}
            >
              <Typography fontWeight={500}>
                <FormattedMessage
                  id="Reference message"
                  defaultMessage="Reference message"
                />
                :
              </Typography>
            </label>
            <Box display="flex">
              <Typography
                textAlign="start"
                fontWeight={500}
                style={{ wordBreak: "break-all" }}
                color={theme.palette.text.primary}
              >
                {pendingTrade?.order?.order_id}
              </Typography>
              <Typography
                textAlign="start"
                fontWeight={500}
                ml={1}
                style={{ cursor: "pointer" }}
              >
                <ContentCopy
                  sx={{ fontSize: 16, color: theme.palette.text.primary }}
                  onClick={() => {
                    copy(pendingTrade?.order?.order_id);
                    handleClick();
                  }}
                />
              </Typography>
            </Box>
          </Box>
          {selectedPaymentOptionFields?.map((field, index) => {
            if (!selectedPaymentOption?.[field?.key]) {
              return null;
            }

            if (field?.key === "qr_code") {
              return (
                <Box
                  my={1.5}
                  display="flex"
                  flexDirection={sm && "column"}
                  alignItems={!sm && "center"}
                  flexWrap="wrap"
                  key={index}
                >
                  <label
                    htmlFor="inputEmail4"
                    className={classes.label}
                    style={{
                      float: "left",
                      minWidth: "fit-content",
                      width: "50%",
                      marginRight: 5,
                    }}
                  >
                    <Typography fontWeight={500} mb={sm && 1}>
                      <FormattedMessage id="QR Code" defaultMessage="QR Code" />
                      :
                    </Typography>
                  </label>
                  {selectedPaymentOption?.qr_code && (
                    <img
                      className={classes.qrCodeImage}
                      src={selectedPaymentOption?.qr_code}
                      alt="qr"
                      onClick={() =>
                        setZoomedImage(selectedPaymentOption?.qr_code)
                      }
                    />
                  )}
                </Box>
              );
            }

            return (
              <Box
                my={1.5}
                display="flex"
                flexDirection={sm && "column"}
                alignItems={!sm && "center"}
                flexWrap="wrap"
                key={index}
              >
                <label
                  htmlFor="inputEmail4"
                  className={classes.label}
                  style={{
                    float: "left",
                    minWidth: "fit-content",
                    width: "50%",
                    marginRight: 5,
                  }}
                >
                  <Typography fontWeight={500}>
                    <FormattedMessage
                      id={field?.label}
                      defaultMessage={field?.label}
                    />
                    :
                  </Typography>
                </label>
                <Box display="flex">
                  <Typography
                    textAlign="start"
                    fontWeight={500}
                    style={{ wordBreak: "break-all" }}
                    color={theme.palette.text.primary}
                  >
                    {selectedPaymentOption?.[field?.key]}
                  </Typography>
                  <Typography
                    textAlign="start"
                    fontWeight={500}
                    ml={1}
                    style={{ cursor: "pointer" }}
                  >
                    {field.required && (
                      <ContentCopy
                        sx={{ fontSize: 16, color: theme.palette.text.primary }}
                        onClick={() => {
                          copy(selectedPaymentOption?.[field?.key]);
                          handleClick();
                        }}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </div>
      </div>
      <div
        className="row w-100"
        style={{ maxWidth: 600, justifyContent: sm && "center" }}
      >
        <Button
          style={{
            borderRadius: 7,
            background:
              isFiatTransferDone || tradeLoading || isTradeCancelled
                ? theme.palette.mode === "light"
                  ? "#bdbdbd"
                  : "#646892"
                : "#6A55EA",

            color: "white",
            minWidth: 200,
            fontWeight: 600,
            width: "50%",
            marginLeft: 5,
            marginTop: 15,
          }}
          disabled={isFiatTransferDone || tradeLoading || isTradeCancelled}
          onClick={() => setConfirm(true)}
        >
          {isFiatTransferDone ? (
            <>
              <CheckIcon
                color="success"
                fontSize="small"
                style={{ marginRight: 10 }}
              />
              <FormattedMessage
                id="Payment confirmed"
                defaultMessage="Payment confirmed"
              />
            </>
          ) : (
            <FormattedMessage
              id="I've Paid. Confirm"
              defaultMessage="I've Paid. Confirm"
            />
          )}
        </Button>
        {pendingTrade?.status !== "completed" ? (
          <Button
            style={{
              borderRadius: 7,
              background:
                tradeLoading || isTradeCancelled ? "#bdbdbd" : "#6A55EA",
              color: "white",
              minWidth: 200,
              fontWeight: 600,
              width: "50%",
              marginLeft: 5,
              marginTop: 15,
            }}
            disabled={tradeLoading || isTradeCancelled}
            onClick={() => setCancelPopup(true)}
          >
            {!isTradeCancelled ? (
              <FormattedMessage
                id="Cancel Trade"
                defaultMessage="Cancel Trade"
              />
            ) : (
              <FormattedMessage id="Cancelled" defaultMessage="Cancelled" />
            )}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
