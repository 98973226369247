import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  Hidden,
  Input,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HowItWorks from "../../../common/HowItWorks";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, toWei } from "../../../utils/helper";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import BigNumber from "bignumber.js";
import { createOrder } from "../../../utils/httpCalls";
import { getCurrenctMarketPrice } from "../../../actions/orderActions";
import UserBalance from "./UserBalance";
import { useCurrencyBalance } from "../../../hooks/useBalance";
import PopupLayout from "../../../common/popups/PopupLayout";
import ProfilePopup from "../../../common/popups/ProfilePopup";
import UpdateNeededBanner from "common/UpdateNeededBanner";
import { AccountBalanceOutlined, Check } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import UnauthorisedUI from "../../../common/UnauthorisedUI";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #212446",
    paddingTop: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.background.primary,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: "10px 50px 10px 50px",
    width: "fit-content",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },
  buttonFirst: {
    width: "fit-content",
    color: "#212121",
    backgroundColor: theme.palette.mode === "light" ? "#eeeeee" : "#646892",
    padding: "5px 25px 5px 25px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    cursor: "pointer",
    borderLeft:
      theme.palette.mode === "light"
        ? "4px solid #e5e5e5"
        : "4px solid #646892",
    borderTop:
      theme.palette.mode === "light"
        ? "4px solid #e5e5e5"
        : "4px solid #646892",
    borderBottom:
      theme.palette.mode === "light"
        ? "4px solid #e5e5e5"
        : "4px solid #646892",
  },
  buttonSecond: {
    width: "fit-content",
    color: "white",
    backgroundColor: "#6A55EA",
    padding: "5px 25px 5px 25px",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    cursor: "pointer",
    borderRight:
      theme.palette.mode === "light"
        ? "4px solid #e5e5e5"
        : "4px solid #646892",
    borderTop:
      theme.palette.mode === "light"
        ? "4px solid #e5e5e5"
        : "4px solid #646892",
    borderBottom:
      theme.palette.mode === "light"
        ? "4px solid #e5e5e5"
        : "4px solid #646892",
  },
  title: {
    color:
      theme.palette.mode === "light" ? theme.palette.text.primary : "#ffffff",
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
  },
  label: {
    color: "#616161",
    fontWeight: 500,
  },
  icon: {
    height: 50,
  },
}));

const PAYMENT_TIME_LIMITS = [
  {
    label: "15 Min",
    value: 0.25 * 60 * 60,
  },
  {
    label: "30 Min",
    value: 0.5 * 60 * 60,
  },
  {
    label: "45 Min",
    value: 0.75 * 60 * 60,
  },
  {
    label: "1 Hour",
    value: 1 * 60 * 60,
  },
];
function CreateOrder() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { chainId, account } = useActiveWeb3React();
  const theme = useTheme();
  const intl = useIntl();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const fiats = useSelector((state) => state?.order?.fiats);
  const userFiat = useSelector((state) => state?.user?.userFiat);
  const tokens = useSelector((state) => state?.order?.tokens);

  const profile = useSelector((state) => state?.profile?.profile);

  //States
  const [step, setStep] = useState(0);
  const [orderType, setOrderType] = useState("buy");
  const [price, setPrice] = useState("");
  const [token, setToken] = useState("PBR");
  const [tokenAmount, setTokenAmount] = useState("");
  const [minFiatInput, setMinToken] = useState("");
  const [maxFiatInput, setMaxToken] = useState("");
  const [fiat, setFiat] = useState(userFiat);
  const [selectedTimeLimit, setSelectedTime] = useState(
    PAYMENT_TIME_LIMITS[1].label
  );
  const [remarks, setRemarks] = useState("");
  const [selectedPaymentMethods, setPaymentMethods] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  //update fiat on userfiat change
  useEffect(() => {
    setFiat(userFiat);
  }, [userFiat]);

  const formattedNumber = (number, decimals) => {
    const numberArray = number.toString().split(".");
    const integer = numberArray?.[0];
    const decimal = numberArray?.[1];

    const formattedNumber = integer
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^0+(?=\d)/, "");

    return decimal !== undefined
      ? `${formattedNumber}.${decimals ? decimal.slice(0, decimals) : decimal}`
      : formattedNumber;
  };

  const isProfileUpdated = useMemo(() => {
    if (!profile?._id) {
      return false;
    }

    return !profile?.email || !profile?.phone ? false : true;
  }, [profile]);

  const isEmailVerified = useMemo(() => {
    if (!profile) {
      return false;
    }

    return !profile?.email_verified ? false : true;
  }, [profile]);
  const isPhoneVerified = useMemo(() => {
    if (!profile) {
      return false;
    }

    return !profile?.phone_verified ? false : true;
  }, [profile]);

  const isPaymentMethodAdded = useMemo(() => {
    if (!profile?._id) {
      return false;
    }

    return profile?.payment_options?.length > 0 ? true : false;
  }, [profile]);

  const userPaymentOptions = useSelector(
    (state) => state?.profile?.profile?.payment_options
  );
  let globalPaymentOptions = useSelector((state) => state?.order?.payments);

  const userFilteredPaymentOptions = useMemo(() => {
    if (!userFiat || !userPaymentOptions) {
      return [];
    }
    return userPaymentOptions?.filter((item) => item?.fiat === fiat);
  }, [userFiat, fiat, userPaymentOptions]);
  const userAuth = useSelector((state) => state?.user);

  globalPaymentOptions = useMemo(() => {
    if (!userFiat) {
      return [];
    }

    const filteredOptions = globalPaymentOptions?.filter(
      (element) => element?.fiat === fiat
    );
    return filteredOptions?.map((item) => {
      return { payment_mode: item?.provider };
    });
  }, [globalPaymentOptions, userFiat, fiat]);

  const currentPaymentOptions = useMemo(() => {
    setPaymentMethods([]); //reset chip methods
    return orderType === "sell"
      ? userFilteredPaymentOptions
      : globalPaymentOptions;
  }, [orderType, userFilteredPaymentOptions, globalPaymentOptions]);

  const buyMarketPrice = useSelector((state) => state?.order?.buyMarketPrice);
  const sellMarketPrice = useSelector((state) => state?.order?.sellMarketPrice);

  const updatePaymentMethods = (selectedValue) => {
    if (selectedPaymentMethods.includes(selectedValue)) {
      const index = selectedPaymentMethods.indexOf(selectedValue);
      let tempArray = selectedPaymentMethods;
      tempArray.splice(index, 1);
      setPaymentMethods([...tempArray]);
    } else {
      let tempArray = selectedPaymentMethods;
      tempArray.push(selectedValue);
      setPaymentMethods([...tempArray]);
    }
  };

  const selectedToken = useMemo(() => {
    const tokenObj = tokens?.filter((item) => item?.symbol === token);
    if (tokenObj?.length > 0) {
      return tokenObj?.[0];
    }
  }, [tokens, token]);

  const selectedTimeLimitValue = useMemo(() => {
    const item = PAYMENT_TIME_LIMITS.find(
      (el) => el?.label === selectedTimeLimit
    );

    if (!item) {
      return 0;
    }
    return item?.value;
  }, [selectedTimeLimit]);

  const parsedMinFiatAmount = useMemo(() => {
    if (minFiatInput) {
      return minFiatInput;
    }

    return "1";
  }, [minFiatInput, tokenAmount, price]);

  const parsedMaxFiatAmount = useMemo(() => {
    if (!price) {
      return "0";
    }
    if (maxFiatInput) {
      return maxFiatInput;
    }
    console.log("order payload amount test ", { tokenAmount, price });
    return new BigNumber(tokenAmount).multipliedBy(price)?.toString();
  }, [maxFiatInput, tokenAmount, price]);

  const reviewOrderFn = useCallback(() => {
    if (!isProfileUpdated || !isEmailVerified || !isPhoneVerified) {
      navigate("/profile");
      return;
    }
    setStep(1);
    window.scrollTo(0, 0);
  }, [
    isProfileUpdated,
    isPaymentMethodAdded,
    isPhoneVerified,
    isEmailVerified,
  ]);

  const selectedFiat = useMemo(() => {
    const fiatObj = fiats?.filter((item) => item?.fiat === fiat);
    if (fiatObj?.length > 0) {
      return fiatObj?.[0];
    }
  }, [fiats, userFiat, fiat]);

  const currentReviewBtn = useMemo(() => {
    if (!isProfileUpdated) {
      return { text: "Update profile", disabled: false };
    }

    if (!isEmailVerified) {
      return { text: "Verify Email", disabled: false };
    }

    if (!isPhoneVerified) {
      return { text: "Verify Phone", disabled: false };
    }

    if (!tokenAmount) {
      return { text: "Enter amount", disabled: true };
    }

    if (!price) {
      return { text: "Enter price", disabled: true };
    }

    console.log("min max", {
      parsedMinFiatAmount,
      parsedMaxFiatAmount,
      tokenAmount,
      price,
    });
    if (!parsedMinFiatAmount || !parsedMaxFiatAmount) {
      return { text: "Enter order min and max limits", disabled: true };
    }

    if (new BigNumber(parsedMinFiatAmount).lte(0)) {
      return { text: "Enter valid min limit", disabled: true };
    }

    if (selectedPaymentMethods.length === 0) {
      return { text: "Select payment methods", disabled: true };
    }

    if (
      new BigNumber(parsedMaxFiatAmount).gt(
        new BigNumber(tokenAmount).multipliedBy(price)
      )
    ) {
      return {
        text: `${intl.formatMessage({
          id: "Max limit must be less than",
          defaultMessage: "Max limit must be less than",
        })}  ${tokenAmount * price} ${selectedFiat?.fiat}`,
        disabled: true,
      };
    }

    if (new BigNumber(parsedMinFiatAmount).gt(parsedMaxFiatAmount)) {
      return {
        text: `${intl.formatMessage({
          id: "Min limit must be less than",
          defaultMessage: "Min limit must be less than",
        })} ${parsedMaxFiatAmount} ${selectedFiat?.fiat}`,
        disabled: true,
      };
    }

    return { text: "Preview order", disabled: false };
  }, [
    parsedMinFiatAmount,
    parsedMaxFiatAmount,
    selectedPaymentMethods,
    tokenAmount,
    price,
    isProfileUpdated,
    isPaymentMethodAdded,
    isPhoneVerified,
    isEmailVerified,
    intl.locale,
  ]);

  useEffect(() => {
    if (
      !chainId ||
      !userAuth?.jwtToken ||
      !selectedFiat?._id ||
      !selectedToken?._id
    ) {
      return;
    }

    dispatch(
      getCurrenctMarketPrice(
        selectedToken?._id,
        selectedFiat?._id,
        userAuth?.account,
        userAuth?.jwtToken
      )
    );
  }, [chainId, selectedFiat, selectedToken, userAuth]);

  const wallet = useSelector((state) => state?.wallet);
  const selectedTokenWallet = useMemo(() => {
    if (!wallet?.tokenBalances?.[selectedToken?._id]) {
      return {};
    }
    return wallet?.tokenBalances?.[selectedToken?._id];
  }, [selectedToken, wallet]);

  const availableDeposits = useMemo(() => {
    if (!selectedTokenWallet?.available_balance) {
      return "0";
    }
    return selectedTokenWallet?.available_balance;
  }, [selectedTokenWallet]);

  const depositsNeeded = useMemo(() => {
    const _tokenAmt = toWei(tokenAmount, selectedToken?.decimals);

    if (!_tokenAmt || !availableDeposits) {
      return "0";
    }

    if (new BigNumber(availableDeposits).gte(_tokenAmt)) {
      return "0";
    }

    const _depositNeeded = new BigNumber(_tokenAmt)
      .minus(availableDeposits)
      ?.toString();

    return _depositNeeded;
  }, [availableDeposits, tokenAmount, selectedToken]);

  const isSufficientDeposits = useMemo(() => {
    return new BigNumber(depositsNeeded).eq(0);
  }, [depositsNeeded]);

  // sell order states
  const isSubmitOrderDisabled = useMemo(() => {
    if (orderType !== "sell") {
      return false;
    }

    return new BigNumber(tokenAmount).lte(0) || !isSufficientDeposits;
  }, [isSufficientDeposits, tokenAmount, orderType]);

  const [isOpen, setOpen] = useState(false);
  const handleModalClose = useCallback(() => {
    setOpen(false);
  }, [isOpen, setOpen]);

  const submitOrder = useCallback(async () => {
    const transaction_wait_time = selectedTimeLimitValue;

    setLoading(true);
    let response;
    if (orderType === "buy") {
      const payload = {
        order_amount: toWei(tokenAmount, selectedToken?.decimals),
        token: selectedToken?._id,
        fiat: selectedFiat?._id,
        order_unit_price: parseFloat(price),
        payment_options: selectedPaymentMethods,
        description: remarks,
        chainId: chainId,
        order_transfaction_duration: transaction_wait_time,
        min_order_value: parsedMinFiatAmount,
        max_order_value: parsedMaxFiatAmount,
      };

      response = await createOrder(
        "buy",
        payload,
        userAuth?.account,
        userAuth?.jwtToken
      );
    } else {
      const payload = {
        order_amount: toWei(tokenAmount, selectedToken?.decimals),
        token: selectedToken?._id,
        fiat: selectedFiat?._id,
        order_unit_price: parseFloat(price),
        payment_options: selectedPaymentMethods,
        min_order_value: parsedMinFiatAmount,
        max_order_value: parsedMaxFiatAmount,
        description: remarks,
        chainId,
        order_transfaction_duration: transaction_wait_time,
      };

      response = await createOrder(
        "sell",
        payload,
        userAuth?.account,
        userAuth?.jwtToken
      );
      // console.log("order payload ", payload);
    }

    if (response?.status === 201) {
      navigate(`/order-placed/${response?.data?._id}`);
    } else {
      setOpen(true);
      setError(response.message?.msg);
    }
    setLoading(false);
  }, [
    orderType,
    tokenAmount,
    selectedToken,
    selectedFiat,
    price,
    selectedPaymentMethods,
    userAuth,
    remarks,
    selectedTimeLimitValue,
    parsedMinFiatAmount,
    parsedMaxFiatAmount,
  ]);

  const authenticated = useSelector((state) => state?.user?.authenticated);
  const isNetworkSwitchRequired = useSelector(
    (state) => state?.user?.isNetworkSwitchRequired
  );
  const handleFiatUpdate = useCallback(
    (selectedFiatItem) => {
      {
        setPaymentMethods([]);
        setFiat(selectedFiatItem);
      }
    },
    [fiats, userAuth, fiat]
  );

  const tokenBalance = useCurrencyBalance(account, selectedToken);

  const handleMaxToken = useCallback(
    (_tokenAmount) => {
      setTokenAmount(_tokenAmount);
    },
    [tokenAmount, setTokenAmount, tokenBalance, selectedToken]
  );

  const handleTokenChange = useCallback(
    (selected) => {
      setToken(selected);
      setTokenAmount("");
      setPrice("");
    },
    [price, setPrice, tokenAmount, setTokenAmount, token, setToken]
  );

  return (
    <Box>
      {isOpen && (
        <PopupLayout popupActive={isOpen}>
          <ProfilePopup resetPopup={handleModalClose} message={error} />
        </PopupLayout>
      )}
      {authenticated && !isNetworkSwitchRequired && (
        <Box className={classes.background}>
          {step === 0 && (
            <Container>
              <Box>
                <Box>
                  <Typography variant="h3" className={classes.title}>
                    <FormattedMessage
                      id="Create Order"
                      defaultMessage="Create Order"
                    />
                  </Typography>
                </Box>
                <div className={classes.infoCard}>
                  <Grid container>
                    <Grid item md={7} sm={12} xs={12}>
                      <Box style={{ width: "100%", maxWidth: 300 }}>
                        <Typography
                          variant="body2"
                          color={theme.palette.text.primary}
                          fontSize={16}
                          fontWeight={500}
                        >
                          -{" "}
                          <FormattedMessage
                            id="Initiate New P2P Order"
                            defaultMessage="Initiate New P2P Order"
                          />
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="start" mt={3}>
                        <Box pr={1}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#04A56D",
                                color: "white",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              1.
                            </div>
                            <div
                              style={{
                                borderTop: `1px dotted ${theme.palette.text.primary}`,
                                width: "80%",
                                height: 1,
                                marginLeft: 5,
                                minWidth: sm ? 100 : 170,
                              }}
                            />
                          </div>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={sm ? 11 : 14}
                            fontWeight={500}
                            mt={1}
                            color={
                              theme.palette.mode === "light"
                                ? "#919191"
                                : "#646892"
                            }
                          >
                            <FormattedMessage
                              id="Set type and price"
                              defaultMessage="Set type and price"
                            />
                          </Typography>
                        </Box>
                        <Box pr={1}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#bdbdbd",
                                color: "black",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              2.
                            </div>
                            <div
                              style={{
                                borderTop: `1px dotted ${theme.palette.text.primary}`,
                                width: "80%",
                                height: 1,
                                marginLeft: 5,
                                minWidth: sm ? 100 : 180,
                              }}
                            ></div>
                          </div>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={sm ? 11 : 14}
                            fontWeight={500}
                            mt={1}
                            color={
                              theme.palette.mode === "light"
                                ? "#919191"
                                : "#646892"
                            }
                          >
                            <FormattedMessage
                              id="Review Your Order"
                              defaultMessage="Review Your Order"
                            />
                          </Typography>
                        </Box>
                        <Box pr={1}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#bdbdbd",
                                color: "black",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              3.
                            </div>
                          </div>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={sm ? 11 : 14}
                            fontWeight={500}
                            mt={1}
                            color={
                              theme.palette.mode === "light"
                                ? "#919191"
                                : "#646892"
                            }
                          >
                            <FormattedMessage
                              id="Submit"
                              defaultMessage="Submit"
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={5}>
                        <Grid container>
                          <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="center"
                          >
                            <Typography
                              display="flex"
                              alignItems="center"
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              fontWeight={500}
                            >
                              <FormattedMessage
                                id="I want to"
                                defaultMessage="I want to"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={12} xs={12}>
                            <Box className={classes.buttonWrapper}>
                              <Box
                                className={classes.buttonFirst}
                                style={{
                                  backgroundColor:
                                    orderType === "buy"
                                      ? "#6A55EA"
                                      : theme.palette.mode === "light"
                                      ? "#eeeeee"
                                      : "#646892",
                                  color:
                                    orderType === "buy"
                                      ? "white"
                                      : theme.palette.text.primary,
                                }}
                                onClick={() => setOrderType("buy")}
                              >
                                <FormattedMessage
                                  id="Buy"
                                  defaultMessage="Buy"
                                />
                              </Box>
                              <Box
                                className={classes.buttonSecond}
                                style={{
                                  backgroundColor:
                                    orderType === "sell"
                                      ? "#6A55EA"
                                      : theme.palette.mode === "light"
                                      ? "#eeeeee"
                                      : "#646892",
                                  color:
                                    orderType === "sell"
                                      ? "white"
                                      : theme.palette.text.primary,
                                }}
                                onClick={() => setOrderType("sell")}
                              >
                                <FormattedMessage
                                  id="Sell"
                                  defaultMessage="Sell"
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        {orderType === "buy" ? (
                          <>
                            <Grid container mt={2}>
                              <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems={"center"}
                              >
                                <Typography
                                  textAlign="left"
                                  variant="body2"
                                  fontSize={15}
                                  fontWeight={500}
                                  color={
                                    theme.palette.mode === "light"
                                      ? "#757575"
                                      : "#646892"
                                  }
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <FormattedMessage
                                    id="Crypto Unit Price"
                                    defaultMessage="Crypto Unit Price"
                                  />
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={7} sm={12} xs={12}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  mt={1}
                                  style={{
                                    border: "1px solid #bdbdbd",
                                    borderRadius: 4,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <Input
                                    type="text"
                                    value={formattedNumber(price, 4)}
                                    placeholder="0"
                                    inputProps={{
                                      min: 0,
                                    }}
                                    onChange={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/,/g, "")
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*)\./g, "$1");

                                      setPrice(e.target.value);
                                    }}
                                    className={classes.input}
                                    style={{ width: "100%" }}
                                    disableUnderline={true}
                                  />
                                  <Select
                                    variant="standard"
                                    disableUnderline={true}
                                    value={fiat}
                                    label="Age"
                                    style={{
                                      fontWeight: 600,
                                      letterSpacing: 1,
                                      color: theme.palette.text.primary,
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 350,
                                          backgroundColor:
                                            theme.palette.dropdown.main,
                                        },
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleFiatUpdate(e.target.value)
                                    }
                                  >
                                    {fiats?.map((item, index) => (
                                      <MenuItem key={index} value={item?.fiat}>
                                        <img
                                          src={item?.flag_url}
                                          style={{
                                            height: 20,
                                            width: 20,
                                            borderRadius: "50%",
                                            marginRight: 5,
                                          }}
                                          alt="logo"
                                        />
                                        {item?.fiat}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container mt={2}>
                              <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems="center"
                              >
                                <Typography
                                  textAlign="left"
                                  variant="body2"
                                  fontSize={14}
                                  color={
                                    theme.palette.mode === "light"
                                      ? "#757575"
                                      : "#646892"
                                  }
                                  style={{ fontWeight: 500 }}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <FormattedMessage
                                    id="Crypto Amount"
                                    defaultMessage="Crypto Amount"
                                  />
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={7} sm={12} xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection={"column"}
                                  mt={1}
                                  style={{
                                    border: "1px solid #bdbdbd",
                                    borderRadius: 4,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent="space-between"
                                  >
                                    <Input
                                      type="text"
                                      value={formattedNumber(tokenAmount, 4)}
                                      placeholder="0"
                                      inputProps={{
                                        min: 0,
                                      }}
                                      onChange={(e) => {
                                        e.target.value = e.target.value
                                          .replace(/,/g, "")
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*)\./g, "$1");

                                        setTokenAmount(e.target.value);
                                        setMaxToken(e.target.value * price);
                                      }}
                                      disableUnderline={true}
                                      className={classes.input}
                                      style={{ width: "100%" }}
                                    />
                                    <Select
                                      variant="standard"
                                      disableUnderline={true}
                                      value={token}
                                      label="Age"
                                      style={{
                                        fontWeight: 600,
                                        letterSpacing: 1,
                                        color: theme.palette.text.primary,
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxHeight: 350,
                                            backgroundColor:
                                              theme.palette.dropdown.main,
                                          },
                                        },
                                      }}
                                      onChange={(e) =>
                                        handleTokenChange(e.target.value)
                                      }
                                    >
                                      {tokens?.map((item, index) => (
                                        <MenuItem
                                          key={index}
                                          onClick={() => setToken(item?.symbol)}
                                          value={item?.symbol}
                                        >
                                          <img
                                            src={item?.logo}
                                            style={{
                                              height: 20,
                                              width: 20,
                                              borderRadius: "50%",
                                              marginRight: 5,
                                            }}
                                            alt=""
                                          />
                                          {item?.symbol}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Box>

                                  <Box display="flex" justifyContent="flex-end">
                                    {orderType === "sell" && (
                                      <UserBalance
                                        inputTokens={tokenAmount}
                                        selectedToken={{
                                          ...selectedToken,
                                          wallet_id: selectedTokenWallet?._id,
                                          available_balance:
                                            selectedTokenWallet?.available_balance,
                                        }}
                                        handleMaxToken={handleMaxToken}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid container mt={2}>
                              <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems={"center"}
                              >
                                <Typography
                                  textAlign="left"
                                  variant="body2"
                                  fontSize={14}
                                  color={
                                    theme.palette.mode === "light"
                                      ? "#757575"
                                      : "#646892"
                                  }
                                  style={{ fontWeight: 500 }}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <FormattedMessage
                                    id="Crypto Amount"
                                    defaultMessage="Crypto Amount"
                                  />
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={7} sm={12} xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection={"column"}
                                  mt={1}
                                  style={{
                                    border: "1px solid #bdbdbd",
                                    borderRadius: 4,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent="space-between"
                                  >
                                    <Input
                                      type="text"
                                      value={formattedNumber(tokenAmount, 4)}
                                      placeholder="0"
                                      inputProps={{
                                        min: 0,
                                      }}
                                      onChange={(e) => {
                                        e.target.value = e.target.value
                                          .replace(/,/g, "")
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*)\./g, "$1");

                                        setTokenAmount(e.target.value);
                                      }}
                                      disableUnderline={true}
                                      className={classes.input}
                                      style={{ width: "100%" }}
                                    />
                                    <Select
                                      variant="standard"
                                      disableUnderline={true}
                                      value={token}
                                      label="Age"
                                      style={{
                                        fontWeight: 600,
                                        letterSpacing: 1,
                                        color: theme.palette.text.primary,
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxHeight: 350,
                                            backgroundColor:
                                              theme.palette.dropdown.main,
                                          },
                                        },
                                      }}
                                      onChange={(e) =>
                                        handleTokenChange(e.target.value)
                                      }
                                    >
                                      {tokens?.map((item, index) => (
                                        <MenuItem
                                          key={index}
                                          onClick={() => setToken(item?.symbol)}
                                          value={item?.symbol}
                                        >
                                          <img
                                            src={item?.logo}
                                            style={{
                                              height: 20,
                                              width: 20,
                                              borderRadius: "50%",
                                              marginRight: 5,
                                            }}
                                            alt=""
                                          />
                                          {item?.symbol}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Box>

                                  <Box display="flex" justifyContent="flex-end">
                                    {orderType === "sell" && (
                                      <UserBalance
                                        inputTokens={tokenAmount}
                                        selectedToken={{
                                          ...selectedToken,
                                          wallet_id: selectedTokenWallet?._id,
                                          available_balance:
                                            selectedTokenWallet?.available_balance,
                                        }}
                                        handleMaxToken={handleMaxToken}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container mt={2}>
                              <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                                display="flex"
                                alignItems={"center"}
                              >
                                <Typography
                                  textAlign="left"
                                  variant="body2"
                                  fontSize={15}
                                  fontWeight={500}
                                  color={
                                    theme.palette.mode === "light"
                                      ? "#757575"
                                      : "#646892"
                                  }
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <FormattedMessage
                                    id="Crypto Unit Price"
                                    defaultMessage="Crypto Unit Price"
                                  />
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={7} sm={12} xs={12}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  mt={1}
                                  style={{
                                    border: "1px solid #bdbdbd",
                                    borderRadius: 4,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <Input
                                    type="text"
                                    value={formattedNumber(price, 4)}
                                    placeholder="0"
                                    inputProps={{
                                      min: 0,
                                    }}
                                    onChange={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/,/g, "")
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*)\./g, "$1");

                                      setPrice(e.target.value);
                                    }}
                                    disableUnderline={true}
                                    className={classes.input}
                                    style={{ width: "100%" }}
                                  />
                                  <Select
                                    variant="standard"
                                    disableUnderline={true}
                                    value={fiat}
                                    label="Age"
                                    style={{
                                      fontWeight: 600,
                                      letterSpacing: 1,
                                      color: theme.palette.text.primary,
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 350,
                                          backgroundColor:
                                            theme.palette.dropdown.main,
                                        },
                                      },
                                    }}
                                    onChange={(e) => {
                                      handleFiatUpdate(e.target.value);
                                    }}
                                  >
                                    {fiats?.map((item, index) => (
                                      <MenuItem key={index} value={item?.fiat}>
                                        <img
                                          src={item?.flag_url}
                                          style={{
                                            height: 20,
                                            width: 20,
                                            borderRadius: "50%",
                                            marginRight: 5,
                                          }}
                                          alt="logo"
                                        />
                                        {item?.fiat}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        <Grid container mt={2}>
                          <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems={"center"}
                          >
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={15}
                              fontWeight={500}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Order Limit"
                                defaultMessage="Order Limit"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={12} xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                style={{
                                  border: "1px solid #bdbdbd",
                                  borderRadius: 4,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  marginRight: 2,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                <Input
                                  type="text"
                                  value={formattedNumber(minFiatInput, 4) || ""}
                                  placeholder="1"
                                  inputProps={{
                                    min: 0,
                                  }}
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/,/g, "")
                                      .replace(/^0+(?=\d)/, "")
                                      .replace(/(\.\d{4})\d*/g, "$1")
                                      .replace(/(\.[0-9]*[1-9])0*$/, "$1")
                                      .replace(/^\./, "0.")
                                      .replace(/[^\d.]/g, "");

                                    if (
                                      e.target.value === "1" &&
                                      e.nativeEvent.inputType ===
                                        "deleteContentBackward"
                                    ) {
                                      setMinToken("");
                                    } else {
                                      setMinToken(e.target.value);
                                    }
                                  }}
                                  disableUnderline={true}
                                  className={classes.input}
                                  style={{
                                    width: "100%",
                                    color: theme.palette.text.primary,
                                  }}
                                />

                                {selectedFiat?.fiat}
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                style={{
                                  border: "1px solid #bdbdbd",
                                  borderRadius: 4,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                  marginLeft: 2,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                <Input
                                  type="text"
                                  value={formattedNumber(
                                    parsedMaxFiatAmount,
                                    4
                                  )}
                                  placeholder="0"
                                  inputProps={{
                                    min: 0,
                                  }}
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/,/g, "")
                                      .replace(/[^0-9.]/g, "")
                                      .replace(/(\..*)\./g, "$1");

                                    setMaxToken(e.target.value);
                                  }}
                                  disableUnderline={true}
                                  className={classes.input}
                                  style={{
                                    width: "100%",
                                    color: theme.palette.text.primary,
                                  }}
                                />
                                {selectedFiat?.fiat}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                width={"100%"}
                                style={{
                                  paddingBottom: 5,
                                  marginLeft: 5,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                <FormattedMessage
                                  id="Min"
                                  defaultMessage="Min"
                                />
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                width={"100%"}
                                style={{
                                  paddingBottom: 5,
                                  marginLeft: 5,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                <FormattedMessage
                                  id="Max"
                                  defaultMessage="Max"
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container mt={2}>
                          <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="center"
                          >
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={15}
                              fontWeight={500}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              display="flex"
                              alignItems="center"
                            >
                              <FormattedMessage
                                id="Payment Time Limit"
                                defaultMessage="Payment Time Limit"
                              />{" "}
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={12} xs={12}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              mt={1}
                              width="50%"
                              style={{
                                border: "1px solid #bdbdbd",
                                borderRadius: 4,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                            >
                              <Select
                                variant="standard"
                                disableUnderline={true}
                                value={selectedTimeLimit}
                                label="Payment Time Limit"
                                sx={{
                                  fontWeight: 600,
                                  letterSpacing: 1,
                                  color: theme.palette.text.primary,
                                  width: "100%",
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 350,
                                      backgroundColor:
                                        theme.palette.dropdown.main,
                                    },
                                  },
                                }}
                                onChange={(e) => {
                                  console.log("select ", e.target.value);
                                  setSelectedTime(e.target.value);
                                }}
                              >
                                {PAYMENT_TIME_LIMITS?.map((item, index) => (
                                  <MenuItem key={index} value={item?.label}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container mt={3}>
                          <Grid item md={4} display="flex" alignItems="center">
                            <Typography
                              alignItems="center"
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              pr={1}
                            >
                              <FormattedMessage
                                id="Total Fiat Amount"
                                defaultMessage="Total Fiat Amount"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7}>
                            <Box
                              display="flex"
                              alignItems={"center"}
                              style={{
                                width: "fit-content",
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                textAlign="left"
                                variant="body2"
                                fontSize={18}
                                style={{
                                  fontWeight: 500,
                                  color: theme.palette.text.primary,
                                }}
                                className={classes.otherText}
                              >
                                {formatCurrency(
                                  new BigNumber(tokenAmount)
                                    .multipliedBy(price)
                                    .toString(),
                                  true
                                )}
                                <span
                                  style={{
                                    fontSize: 14,
                                    paddingLeft: 4,
                                    color: theme.palette.text.primary,
                                  }}
                                >
                                  {selectedFiat?.fiat}
                                </span>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          mt={3}
                          display="flex"
                          flexDirection={sm ? "column" : "row"}
                        >
                          <Grid item md={4} display="flex" alignItems="center">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              fontWeight={500}
                              display="flex"
                              alignItems="center"
                              pr={1}
                              mb={1}
                            >
                              <FormattedMessage
                                id="Payment Methods"
                                defaultMessage="Payment Methods"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={7}
                            display="flex"
                            alignItems={"center"}
                          >
                            <FormControl
                              style={{
                                m: 1,
                                width: "100%",
                              }}
                            >
                              <Select
                                className="col-sm-8"
                                variant="standard"
                                disableUnderline
                                value={selectedPaymentMethods}
                                renderValue={(
                                  selected //display options as chips
                                ) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        size="small"
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                        icon={<AccountBalanceOutlined />}
                                        onDelete={() =>
                                          updatePaymentMethods(value)
                                        }
                                        sx={{ borderRadius: 8 }}
                                      />
                                    ))}
                                  </Box>
                                )}
                                multiple
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                  fontWeight: 600,
                                  fontSize: sm && 14,
                                  letterSpacing: 1,
                                  color: "#36454F",
                                  width: "100%",
                                  padding: 1,
                                  border: "1px solid #bdbdbd",
                                  borderRadius: "6px",
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 350,
                                      width: 250,
                                      backgroundColor:
                                        theme.palette.dropdown.main,
                                    },
                                  },
                                }}
                                disabled={currentPaymentOptions?.length === 0}
                              >
                                {currentPaymentOptions.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item?.payment_mode}
                                    onClick={() =>
                                      updatePaymentMethods(item?.payment_mode)
                                    }
                                    sx={{
                                      fontSize: sm && 14,
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    dense={sm}
                                  >
                                    <Box
                                      key={index}
                                      display="flex"
                                      flexWrap="wrap"
                                      width="fit-content"
                                      borderRadius={2}
                                      mr={1}
                                      fontSize={11}
                                      minWidth={120}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src="https://cdn0.iconfinder.com/data/icons/elasto-online-store/26/00-ELASTOFONT-STORE-READY_bank-512.png"
                                          height="15px"
                                          style={{
                                            marginRight: 5,
                                            filter:
                                              theme.palette.mode === "dark"
                                                ? "invert(100%)"
                                                : "",
                                            color: theme.palette.text.primary,
                                          }}
                                        />
                                        <Typography
                                          alignItems={"center"}
                                          textAlign="left"
                                          variant="body2"
                                          fontSize={11}
                                          color={"#313131"}
                                          style={{
                                            fontWeight: 500,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            color: theme.palette.text.primary,
                                          }}
                                        >
                                          {item?.payment_mode?.toUpperCase()}
                                        </Typography>
                                      </div>
                                    </Box>
                                    <Check
                                      style={{
                                        fontSize: sm ? 16 : 20,
                                        color: theme.palette.background.main,
                                        display:
                                          !selectedPaymentMethods.includes(
                                            item?.payment_mode
                                          ) && "none",
                                      }}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {currentPaymentOptions?.length === 0 && (
                              <a
                                href="/profile"
                                rel="noreferrer"
                                style={{
                                  width: "fit-content",
                                  whiteSpace: "nowrap",
                                  borderRadius: 7,
                                  marginRight: 5,
                                  marginLeft: 5,
                                  fontSize: 14,
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  color: "#6A55EA",
                                }}
                              >
                                <Button variant="outlined">
                                  <FormattedMessage
                                    id="Add payments"
                                    defaultMessage="Add payments"
                                  />
                                </Button>
                              </a>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Hidden mdDown>
                        <Grid container>
                          <Grid item md={5} sm={6} xs={6}>
                            <Box>
                              <Typography
                                display="flex"
                                textAlign="left"
                                variant="body2"
                                fontSize={13}
                                color={
                                  theme.palette.mode === "light"
                                    ? "#757575"
                                    : "#646892"
                                }
                                style={{ fontWeight: 500 }}
                              >
                                <FormattedMessage
                                  id="Current Price"
                                  defaultMessage="Current Price"
                                />
                              </Typography>
                              <Typography
                                variant="body1"
                                align="left"
                                fontSize={22}
                                style={{ fontWeight: 600 }}
                                color={"#04A56D"}
                              >
                                {orderType === "buy"
                                  ? buyMarketPrice?.current
                                  : sellMarketPrice?.current}
                                <span style={{ fontSize: 14, paddingLeft: 2 }}>
                                  {selectedFiat?.fiat}
                                </span>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid md={5} sm={6} xs={6}>
                            <Box>
                              <Typography
                                display="flex"
                                textAlign="left"
                                variant="body2"
                                fontSize={13}
                                color={
                                  theme.palette.mode === "light"
                                    ? "#757575"
                                    : "#646892"
                                }
                                style={{ fontWeight: 500 }}
                              >
                                {orderType === "sell" ? (
                                  <FormattedMessage
                                    id="Highest Price"
                                    defaultMessage="Highest Price"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="Lowest Price"
                                    defaultMessage="Lowest Price"
                                  />
                                )}
                              </Typography>
                              <Typography
                                variant="body1"
                                align="left"
                                fontSize={22}
                                style={{
                                  fontWeight: 600,
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {orderType === "buy"
                                  ? buyMarketPrice?.allTime
                                  : sellMarketPrice?.allTime}
                                <span style={{ fontSize: 14, paddingLeft: 2 }}>
                                  {selectedFiat?.fiat}
                                </span>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Hidden>
                      <Box
                        mt={3}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h5"
                          align="left"
                          style={{
                            marginBottom: 10,
                            color:
                              theme.palette.mode === "light"
                                ? "#757575"
                                : "#646892",
                          }}
                        >
                          <FormattedMessage
                            id="Remark"
                            defaultMessage="Remark"
                          />
                          :
                        </Typography>
                        <TextareaAutosize
                          type="text"
                          maxLength={1000}
                          value={remarks}
                          onChange={(e) => setRemarks(e.target.value)}
                          placeholder={intl.formatMessage({
                            id: "Enter your message for seller",
                            defaultMessage: "Enter your message for seller",
                          })}
                          style={{
                            width: "95%",
                            minHeight: 160,
                            height: 240,
                            maxHeight: 800,
                            border:
                              theme.palette.mode === "light"
                                ? "1px solid #EAECEE"
                                : "1px solid #25284B",
                            boxSizing: "border-box",
                            borderRadius: 15,
                            outline: "none",
                            padding: 10,
                            backgroundColor: theme.palette.background.highlight,
                            color: theme.palette.text.primary,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <div style={{ color: "red", textAlign: "center" }}>
                    {currentReviewBtn?.disabled && (
                      <FormattedMessage
                        id={currentReviewBtn.text}
                        defaultMessage={currentReviewBtn.text}
                      />
                    )}
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <UpdateNeededBanner />
                  </div>

                  <div className="text-center mt-4 mb-2">
                    <Button
                      onClick={reviewOrderFn}
                      style={{
                        borderRadius: 10,
                        background: isSubmitOrderDisabled
                          ? "rgba(106, 85, 234, 0.5)"
                          : "#6A55EA",
                        padding: sm ? "9px 25px 9px 25px" : "9px 35px 9px 35px",
                        color: "white",
                      }}
                      disabled={currentReviewBtn.disabled}
                    >
                      <FormattedMessage
                        id={currentReviewBtn.text}
                        defaultMessage={currentReviewBtn.text}
                      />
                    </Button>
                  </div>
                </div>
                <HowItWorks />
              </Box>
            </Container>
          )}

          {step === 1 && (
            <Container>
              <Box>
                <Box
                  className={classes.cautionTextBox}
                  mb={3}
                  mx="auto"
                  textAlign="center"
                  display={step === 0 ? "none" : "block"}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id="Please note that if you decide to"
                    defaultMessage="Please note that if you decide to"
                  />{" "}
                  <span style={{ fontWeight: 600 }}>
                    <FormattedMessage id="SUBMIT" defaultMessage="SUBMIT" />
                  </span>{" "}
                  <FormattedMessage
                    id="the order, you wouldn't be able to"
                    defaultMessage="the order, you
                  wouldn't be able to"
                  />{" "}
                  <span style={{ fontWeight: 600 }}>
                    <FormattedMessage id="EDIT" defaultMessage="EDIT" />
                  </span>{" "}
                  <FormattedMessage
                    id="it afterwards."
                    defaultMessage="it afterwards."
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    className={classes.title}
                  >
                    <FormattedMessage
                      id="Create Order"
                      defaultMessage="Create Order"
                    />
                  </Typography>
                </Box>
                <div className={classes.infoCard}>
                  <Grid container>
                    <Grid item md={7} sm={12} xs={12}>
                      <Box style={{ width: "100%", maxWidth: 300 }}>
                        <Typography
                          variant="body2"
                          color={theme.palette.text.primary}
                          fontSize={16}
                          fontWeight={500}
                        >
                          -{" "}
                          <FormattedMessage
                            id="Review New P2P Order"
                            defaultMessage="Review New P2P Order"
                          />
                        </Typography>
                      </Box>
                      <Box display={"flex"} justifyContent="start" mt={3}>
                        <Box pr={1}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#04A56D",
                                color: "white",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              1.
                            </div>{" "}
                            <div
                              style={{
                                borderTop: `1px dotted ${theme.palette.text.primary}`,
                                width: "80%",
                                height: 1,
                                marginLeft: 5,
                                minWidth: sm ? 100 : 170,
                              }}
                            ></div>
                          </div>{" "}
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={sm ? 11 : 14}
                            fontWeight={500}
                            mt={1}
                            color={
                              theme.palette.mode === "light"
                                ? "#919191"
                                : "#646892"
                            }
                          >
                            <FormattedMessage
                              id="Set type and price"
                              defaultMessage="Set type and price"
                            />
                          </Typography>
                        </Box>
                        <Box pr={1}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#04A56D",
                                color: "white",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              2.
                            </div>
                            <div
                              style={{
                                borderTop: `1px dotted ${theme.palette.text.primary}`,
                                width: "80%",
                                height: 1,
                                marginLeft: 5,
                                minWidth: sm ? 100 : 170,
                              }}
                            ></div>
                          </div>{" "}
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={sm ? 11 : 14}
                            fontWeight={500}
                            mt={1}
                            color={
                              theme.palette.mode === "light"
                                ? "#919191"
                                : "#646892"
                            }
                          >
                            <FormattedMessage
                              id="Review your order"
                              defaultMessage="Review your order"
                            />
                          </Typography>
                        </Box>
                        <Box pr={1}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                backgroundColor: "#bdbdbd",
                                color: "black",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                            >
                              3.
                            </div>
                          </div>
                          <Typography
                            textAlign="left"
                            variant="body2"
                            fontSize={sm ? 11 : 14}
                            fontWeight={500}
                            mt={1}
                            color={
                              theme.palette.mode === "light"
                                ? "#919191"
                                : "#646892"
                            }
                          >
                            <FormattedMessage
                              id="Submit"
                              defaultMessage="Submit"
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={5}>
                        <Grid container>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              display="flex"
                              alignItems={"center"}
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                            >
                              <FormattedMessage
                                id="Order type"
                                defaultMessage="Order type"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid md={7} sm={6} xs={6} ml={1}>
                            <Typography
                              alignItems={"center"}
                              textAlign="left"
                              variant="body2"
                              fontSize={16}
                              color={
                                theme.palette.mode === "light"
                                  ? "#313131"
                                  : theme.palette.text.primary
                              }
                              style={{ fontWeight: 600 }}
                            >
                              <FormattedMessage
                                id={orderType?.toUpperCase()}
                                defaultMessage={orderType?.toUpperCase()}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container mt={2}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              fontWeight={500}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Crypto Unit Price"
                                defaultMessage="Crypto Unit Price"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={16}
                              color={theme.palette.text.primary}
                              style={{ fontWeight: 500 }}
                            >
                              {formatCurrency(price, true)}
                              <span style={{ fontSize: 12, paddingLeft: 5 }}>
                                {fiat}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container mt={2}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Crypto Amount"
                                defaultMessage="Crypto Amount"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={16}
                              color={theme.palette.text.primary}
                              style={{ fontWeight: 500 }}
                            >
                              {formatCurrency(tokenAmount, true)}
                              <span style={{ fontSize: 12, paddingLeft: 5 }}>
                                {token}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container mt={2}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Min Order Limit"
                                defaultMessage="Min Order Limit"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={16}
                              color={theme.palette.text.primary}
                              style={{ fontWeight: 500 }}
                            >
                              {formatCurrency(parsedMinFiatAmount, true)}
                              <span style={{ fontSize: 12, paddingLeft: 5 }}>
                                {selectedFiat?.fiat}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container mt={2}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Max Order Limit"
                                defaultMessage="Max Order Limit"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={16}
                              color={theme.palette.text.primary}
                              style={{ fontWeight: 500 }}
                            >
                              {formatCurrency(parsedMaxFiatAmount, true)}
                              <span style={{ fontSize: 12, paddingLeft: 5 }}>
                                {selectedFiat?.fiat}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container mt={2}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Total Fiat Amount"
                                defaultMessage="Total Fiat Amount"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Box
                              display="flex"
                              alignItems={"center"}
                              style={{
                                width: "fit-content",
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                textAlign="left"
                                variant="body2"
                                fontSize={18}
                                style={{
                                  fontWeight: 500,
                                  color: theme.palette.text.primary,
                                }}
                                className={classes.otherText}
                              >
                                {formatCurrency(
                                  new BigNumber(tokenAmount).multipliedBy(
                                    price
                                  ),
                                  true
                                )}
                                <span style={{ fontSize: 13, paddingLeft: 4 }}>
                                  {fiat}
                                </span>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mt={2}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              display="flex"
                              alignItems="center"
                            >
                              <FormattedMessage
                                id="Payment Time Limit"
                                defaultMessage="Payment Time Limit"
                              />{" "}
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{
                                width: "fit-content",
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                textAlign="left"
                                variant="body2"
                                fontSize={18}
                                style={{
                                  fontWeight: 500,
                                  color: theme.palette.text.primary,
                                }}
                                className={classes.otherText}
                              >
                                {selectedTimeLimit}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container mt={3}>
                          <Grid item md={4} sm={5} xs={5} display="flex">
                            <Typography
                              textAlign="left"
                              variant="body2"
                              fontSize={14}
                              color={
                                theme.palette.mode === "light"
                                  ? "#757575"
                                  : "#646892"
                              }
                              style={{ fontWeight: 500 }}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <FormattedMessage
                                id="Payment Methods"
                                defaultMessage="Payment Methods"
                              />
                              :
                            </Typography>
                          </Grid>
                          <Grid item md={7} sm={6} xs={6} ml={1}>
                            <Box
                              display="flex"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              {selectedPaymentMethods?.map((item, index) => {
                                return (
                                  <Box
                                    key={index}
                                    bgcolor={
                                      selectedPaymentMethods.includes(item)
                                        ? "#E1DCFF"
                                        : "transparent"
                                    }
                                    width="fit-content"
                                    padding={
                                      sm
                                        ? "4px 9px 4px 9px"
                                        : "5px 14px 5px 14px"
                                    }
                                    mb={1}
                                    borderRadius={2}
                                    mr={1}
                                    fontSize={14}
                                    border="1px solid #E1DCFF"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div className="d-flex justify-content-center align-items-center">
                                      <img
                                        src="https://cdn0.iconfinder.com/data/icons/elasto-online-store/26/00-ELASTOFONT-STORE-READY_bank-512.png"
                                        height="15px"
                                        style={{ marginRight: 5 }}
                                      />
                                      <Typography
                                        alignItems="center"
                                        textAlign="left"
                                        variant="body2"
                                        fontSize={sm ? 12 : 14}
                                        color={"#313131"}
                                        style={{ fontWeight: 500 }}
                                      >
                                        {item?.toUpperCase()}
                                      </Typography>
                                    </div>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Box
                        mt={3}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          textAlign="left"
                          variant="body2"
                          fontSize={18}
                          fontWeight={500}
                          mb={1}
                          color={
                            theme.palette.mode === "light"
                              ? "#757575"
                              : "#646892"
                          }
                        >
                          <FormattedMessage
                            id="Remarks"
                            defaultMessage="Remarks"
                          />{" "}
                          :
                        </Typography>
                        <Typography
                          textAlign="left"
                          variant="body2"
                          fontSize={14}
                          color={theme.palette.text.primary}
                          pt={1}
                          lineHeight={1.8}
                          style={{ wordBreak: "break-all" }}
                        >
                          {remarks}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <div className="d-flex justify-content-center mt-4 mb-2">
                    <Button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setStep(0);
                      }}
                      style={{
                        borderRadius: 10,
                        background:
                          theme.palette.mode === "light"
                            ? "#F5F5F5"
                            : "#646892",
                        color: theme.palette.text.primary,
                        width: sm ? "fit-content" : 180,

                        padding: sm ? "9px 25px 9px 25px" : "9px 35px 9px 35px",
                        marginRight: 20,
                      }}
                    >
                      <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                    </Button>

                    <Button
                      onClick={submitOrder}
                      style={{
                        borderRadius: 10,
                        background: loading
                          ? "rgba(106, 85, 234, 0.5)"
                          : "#6A55EA",
                        padding: sm ? "9px 25px 9px 25px" : "9px 35px 9px 35px",
                        color: "white",
                        width: sm ? "fit-content" : 180,
                      }}
                      disabled={loading}
                    >
                      <FormattedMessage
                        id="Submit Order"
                        defaultMessage="Submit Order"
                      />
                    </Button>
                  </div>
                </div>

                <HowItWorks />
              </Box>
            </Container>
          )}
        </Box>
      )}

      {(!authenticated || isNetworkSwitchRequired) && <UnauthorisedUI />}
    </Box>
  );
}

export default CreateOrder;
