import { Connector } from "@web3-react/types";
import { UPDATE_AUTH_STATE } from "../actions/types";
import { AUTHENTICATION_STATE } from "../constants/index";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Connection,
  injectedConnection,
  walletConnectConnection,
} from "../constants/connectionConstants";
import { isMetaMaskInstalled } from "../utils/helper";

export async function connect(connector: Connector) {
  try {
    await connector.activate();
  } catch (error) {
    console.log(`web3-react eager connection error: ${error}`);
  }
}

export default function useEagerlyConnect() {
  const dispatch = useDispatch();
  useEffect(() => {
    async function startConnecting() {
      let res: any = null;
      if (isMetaMaskInstalled()) {
        res = await connect(injectedConnection.connector);
      } else {
        res = await connect(walletConnectConnection.connector);
      }
      console.log("wallet test connection res ", res);

      if (!res) {
        // retry connecting
        dispatch({
          type: UPDATE_AUTH_STATE,
          payload: AUTHENTICATION_STATE.NOT_STARTED,
        });
      }
    }

    startConnecting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
