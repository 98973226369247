import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import theme from "../theme";

const useStyles = makeStyles((theme) => ({
  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: "10px 60px 10px 60px",
    width: "fit-content",
  },
}));

export default function UpdateNeededBanner() {
  const classes = useStyles();
  const theme = useTheme();
  const profile = useSelector((state) => state?.profile?.profile);
  const authenticatedUser = useSelector((state) => state?.user);

  const isProfileUpdated = useMemo(() => {
    if (!profile?._id) {
      return false;
    }

    return !profile?.email || !profile?.phone ? false : true;
  }, [profile]);

  const isEmailVerified = useMemo(() => {
    if (!profile) {
      return false;
    }

    return !profile?.email_verified ? false : true;
  }, [profile]);
  const isPhoneVerified = useMemo(() => {
    if (!profile) {
      return false;
    }

    return !profile?.phone_verified ? false : true;
  }, [profile]);

  const isPaymentMethodAdded = useMemo(() => {
    if (!profile?._id) {
      return false;
    }

    return profile?.payment_options?.length > 0 ? true : false;
  }, [profile]);

  return (
    <div>
      {(!isProfileUpdated ||
        !isEmailVerified ||
        !isPhoneVerified ||
        !isPaymentMethodAdded) && (
        <Box
          className={classes.cautionTextBox}
          mt={4}
          mb={3}
          mx="auto"
          textAlign="center"
          color={theme.palette.text.primary}
          display={
            ((profile?.email &&
              profile?.email?.length &&
              profile?.phone &&
              profile?.phone?.length &&
              profile?.payment_options?.length) ||
              !authenticatedUser?.authenticated) &&
            "none"
          }
        >
          {!isProfileUpdated ? (
            <FormattedMessage
              id="Before you start trading you need to update your profile."
              defaultMessage="Before you start trading you need to update your profile."
            />
          ) : !isEmailVerified ? (
            <FormattedMessage
              id="Before your start trading please verify your email"
              defaultMessage="Before you start trading you need to update your profile."
            />
          ) : !isPhoneVerified ? (
            <FormattedMessage
              id="Before your start trading please verify your phone"
              defaultMessage="Before your start trading please verify your phone"
            />
          ) : !isPaymentMethodAdded ? (
            <FormattedMessage
              id="Please add a payment method to start buy crypto"
              defaultMessage="Please add a payment method to start buy crypto"
            />
          ) : (
            ""
          )}
          <span style={{ paddingLeft: 5 }}>
            <Link
              to="/profile"
              style={{
                textDecoration: "none",
                color: theme.palette.action.green,
                fontWeight: 500,
              }}
            >
              {!isProfileUpdated ? (
                <FormattedMessage id="Update now" defaultMessage="Update now" />
              ) : !isEmailVerified || !isPhoneVerified ? (
                <FormattedMessage id="Verify now" defaultMessage="Verify now" />
              ) : !isPaymentMethodAdded ? (
                <FormattedMessage id="Add now" defaultMessage="Add now" />
              ) : (
                ""
              )}
            </Link>
          </span>
        </Box>
      )}
    </div>
  );
}
