import axios from "axios";
import { globalHeaders } from "./index";
import { BASE_API_ENDPOINT } from "./index";

export const getVaultBalances = async (
  account: string,
  chainId: number,
  authToken: string | undefined
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/vault-apis/v1/vaults`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getVaultBalances  error", {
      error,
    });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getVaultBalance = async (
  account: string,
  chainId: number,
  vaultId: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/vault-apis/v1/vault/${vaultId}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getUser ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getTokenPrices = async (
  account: string,
  chainId: number,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/vault-apis/v1/token-prices`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getUser ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const updateDepositCall = async (
  account: string,
  chainId: number,
  tokenId: string,
  vaultId: string,
  trxHash: string,
  authToken: string
) => {
  try {
    const body = {
      tokenId,
      vaultId,
      trxHash,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/vault-apis/v1/update-deposit`,
      body,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("updateDepositCall error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const recordDepositHashCall = async (
  account: string,
  chainId: number,
  tokenId: string,
  trxHash: string,
  tokenAmount: string,
  authToken: string
) => {
  try {
    const body = {
      tokenId,
      trxHash,
      tokenAmount,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/vault-apis/v1/record-deposit-hash`,
      body,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("recordDepositHashCall error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getPendingDeposit = async (
  account: string,
  chainId: number,
  tokenId: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/vault-apis/v1/pending-deposit-hash/${tokenId}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getPendingDeposit  error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const getPendingWithdraw = async (
  account: string,
  chainId: number,
  tokenId: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/vault-apis/v1/pending-withdraw-hash/${tokenId}`,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getPendingWithdraw  error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const withdrawQuoteCall = async (
  account: string,
  chainId: number,
  tokenId: string,
  tokenAmount: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(
      `${BASE_API_ENDPOINT}/vault-apis/v1/withdraw-quote?token_id=${tokenId}&&token_amount=${tokenAmount}`,

      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("wallet test updateDepositCall error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const withdrawCall = async (
  account: string,
  chainId: number,
  tokenId: string,
  tokenAmount: string,
  authToken: string
) => {
  try {
    const body = {
      tokenId,
      tokenAmount,
    };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/vault-apis/v1/withdraw-token`,
      body,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("wallet test updateDepositCall error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const createWalletCall = async (
  account: string,
  chainId: number,
  tokenId: string,
  authToken: string
) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const body = {
      tokenId,
    };
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/vault-apis/v1/vault`,
      body,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken
            ? authToken
            : localStorage.getItem(`${account}_${chainId}`),
          signature,
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("create missing wallet error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
