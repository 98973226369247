import { makeStyles } from "@mui/styles";
import { Close, SmartDisplayOutlined } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  Button,
  DialogContent,
  Dialog,
} from "@mui/material";

import { FormattedMessage } from "react-intl";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxWidth: 795,
    maxHeight: "100%",
    // overflowY: "scroll",
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 20,
    paddingBottom: 30,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
      padding: 8,
    },
  },

  img: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: 120,
      height: 120,
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TutorialPopup = ({ resetPopup }) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isTutOpen, setIsTutOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className="d-flex justify-content-end">
          <Close
            style={{
              color: theme.palette.text.primary,
              fontSize: 28,
              zIndex: 10000,
              cursor: "pointer",
            }}
            onClick={resetPopup}
          />
        </div>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={6} sm={6} md={4}>
            <Box className={classes.column}>
              <img
                src="./images/buy_tut.png"
                className={classes.img}
                alt="Buy Tutorial"
              />
              <Button
                variant="contained"
                color="primary"
                size={sm ? "small" : "medium"}
                sx={{
                  textTransform: "capitalize",
                  padding: sm ? "0.7px" : "1.5px",
                  width: sm ? "100%" : "80%",
                  fontSize: sm && 12,
                }}
                onClick={() => {
                  setVideoUrl("https://www.youtube.com/embed/IK-B9N7TVi8");
                  setIsTutOpen(true);
                }}
              >
                <SmartDisplayOutlined
                  sx={{ fontSize: sm ? 16 : 20, marginRight: 0.5 }}
                />
                {sm ? (
                  <FormattedMessage
                    id="How to buy crypto?"
                    defaultMessage="How to buy crypto?"
                  />
                ) : (
                  <FormattedMessage
                    id="How do i buy crypto?"
                    defaultMessage="How do i buy crypto?"
                  />
                )}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Box className={classes.column}>
              <img
                src="./images/sell_tut.png"
                className={classes.img}
                alt="Sell Tutorial"
              />
              <Button
                variant="contained"
                color="primary"
                size={sm ? "small" : "medium"}
                sx={{
                  textTransform: "capitalize",
                  padding: sm ? "0.7px" : "1.5px",
                  width: sm ? "100%" : "80%",
                  fontSize: sm && 12,
                }}
                onClick={() => {
                  setVideoUrl("https://www.youtube.com/embed/KTNh-vtwEME");
                  setIsTutOpen(true);
                }}
              >
                <SmartDisplayOutlined
                  sx={{ fontSize: sm ? 16 : 20, marginRight: 0.5 }}
                />
                {sm ? (
                  <FormattedMessage
                    id="How to sell crypto?"
                    defaultMessage="How to sell crypto?"
                  />
                ) : (
                  <FormattedMessage
                    id="How do i sell crypto?"
                    defaultMessage="How do i sell crypto?"
                  />
                )}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box className={classes.column}>
              <img
                src="./images/ad_tut.png"
                className={classes.img}
                alt="Ad tutorial"
              />
              <Button
                variant="contained"
                color="primary"
                size={sm ? "small" : "medium"}
                sx={{
                  textTransform: "capitalize",
                  padding: sm ? "0.7px" : "1.5px",
                  fontSize: sm && 12,
                }}
                onClick={() => {
                  setVideoUrl("https://www.youtube.com/embed/8McCYIMTDZ8");
                  setIsTutOpen(true);
                }}
              >
                <SmartDisplayOutlined
                  sx={{ fontSize: sm ? 16 : 20, marginRight: 0.5 }}
                />
                {sm ? (
                  <FormattedMessage
                    id="How to create Buy/Sell Ad?"
                    defaultMessage="How to create Buy/Sell Ad?"
                  />
                ) : (
                  <FormattedMessage
                    id="How do i create Buy/Sell Ad?"
                    defaultMessage="How do i create Buy/Sell Ad?"
                  />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      {videoUrl && (
        <Dialog
          open={isTutOpen}
          onClose={() => setIsTutOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <Box position="relative" paddingTop="56.25%">
              <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                bgcolor={theme.palette.background.secondary}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <iframe
                  src={videoUrl}
                  width="100%"
                  height="100%"
                  allow="autoplay; encrypted-media"
                  title="YouTube video player"
                  frameBorder="0"
                />
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default TutorialPopup;
