import axios from "axios";
import { globalHeaders } from "./index";
import { BASE_API_ENDPOINT } from "./index";

export const getChat = async (params: any,account:string, authToken: string) => {
  try {
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.get(`${BASE_API_ENDPOINT}/chat-apis/v1/chat/`, {
      params: params,
      headers: { ...globalHeaders, "x-auth-token": authToken, signature },
    });

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("getChat ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
