import {
  Box,
  Button,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatTime, getAddEtherscanUrl } from "../../../../utils/helper";
import { Close } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { getTrxEtherscanUrl } from "../../../../utils/helper";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.1)",
  },
  container: {
    maxWidth: 700,
    maxHeight: "100%",
    overflow: "hidden",
    overflowY: "auto",
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      minWidth: 370,
      width: "100%",
      maxWidth: "95%",
    },
  },

  heading: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: 22,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    marginBottom: 6,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },
}));

function RequestView({ popupActive, handleClose, request }) {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={popupActive}
      // PaperProps={{
      //   style: {
      //     backgroundColor: "transparent",
      //     boxShadow: "none",
      //   },
      // }}
      BackdropProps={{
        invisible: true,
      }}
      maxWidth="md"
    >
      <Box
        border="10px solid #6A55EA"
        px={!sm ? "2vw" : "5%"}
        py={!sm ? "5vh" : "10%"}
        className={classes.popup}
      >
        <div className={classes.background}>
          <div className={classes.container}>
            <div className="d-flex justify-content-end">
              <Close
                style={{
                  color: theme.palette.text.primary,
                  fontSize: 22,
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </div>
            <div className="row align-items-center">
              {/* heading */}
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <Box className={classes.heading}>
                  <FormattedMessage
                    id="Redeem Detail"
                    defaultMessage="Redeem Detail"
                  />
                </Box>
              </div>
              {/* order data */}
              <div
                style={{
                  padding: !sm ? "8px 0px 15px" : "",
                }}
              >
                {/* redeem_at */}
                <div className="d-flex flex-column flex-md-row justify-content-between px-lg-4 ">
                  <div
                    className="pt-2 flex "
                    style={{
                      color: "#989898",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage
                      id="Redeemed At"
                      defaultMessage="Redeemed At"
                    />
                  </div>
                  <div
                    className="pt-2 d-flex "
                    style={{
                      color: "#202020",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      textAlign="center"
                      variant="body2"
                      fontSize={sm ? 12 : 16}
                      fontWeight={500}
                      color={theme.palette.text.primary}
                    >
                      {formatTime(request?.redeemed_at).substring(
                        formatTime(request?.redeemed_at).indexOf("M") + 1,
                        formatTime(request?.redeemed_at).length
                      )}{" "}
                      |{" "}
                      {formatTime(request?.redeemed_at).substring(
                        0,
                        formatTime(request?.redeemed_at).indexOf("M") + 1
                      )}
                    </Typography>
                  </div>
                </div>
                {/* account */}
                <div className="d-flex flex-column flex-md-row justify-content-between px-lg-4">
                  <div
                    className="pt-2 flex "
                    style={{
                      color: "#989898",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage id="Account" defaultMessage="Account" />
                  </div>

                  <Typography
                    textAlign="left"
                    variant="body2"
                    fontSize={14}
                    color={"#212121"}
                    className="d-flex flex-column"
                    alignSelf={md ? "start" : "end"}
                  >
                    <a
                      component="button"
                      href={getAddEtherscanUrl(request?.account, 5)}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {request?.account?.slice(0, 5)}
                      {"..."}
                      {request?.account?.slice(
                        request?.account?.length - 5,
                        request?.account?.length
                      )}
                    </a>
                  </Typography>
                </div>
                {/* points */}
                <div className="d-flex flex-column flex-md-row justify-content-between px-lg-4">
                  <div
                    className="pt-2 flex "
                    style={{
                      color: "#989898",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage id="Points" defaultMessage="Points" />
                  </div>
                  <div
                    className="pt-2 d-flex "
                    style={{
                      color: theme.palette.text.primary,
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    {request?.points}
                  </div>
                </div>
                {/* status */}
                <div className="d-flex flex-column flex-md-row justify-content-between px-lg-4">
                  <div
                    className="pt-2 flex "
                    style={{
                      color: "#989898",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage id="Status" defaultMessage="Status" />
                  </div>
                  <div
                    className="pt-2 d-flex "
                    style={{
                      color:
                        request.status === "pending"
                          ? "#F9C74F"
                          : request?.status === "completed"
                          ? theme.palette.action.green
                          : theme.palette.action.red,
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage
                      id={request?.status}
                      defaultMessage={request?.status}
                    />
                  </div>
                </div>
                {/* txn id */}
                <div className="d-flex flex-column flex-md-row justify-content-between px-lg-4">
                  <div
                    className="pt-2 flex "
                    style={{
                      color: "#989898",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage
                      id="Transaction ID"
                      defaultMessage="Transaction ID"
                    />{" "}
                  </div>
                  <div
                    className="pt-2 d-flex "
                    style={{
                      color: theme.palette.text.primary,
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    {request?.txnHash ? (
                      <Typography
                        textAlign="left"
                        variant="body2"
                        fontSize={14}
                        color={"#212121"}
                        className="d-flex flex-column"
                        alignSelf="end"
                      >
                        <a
                          component="button"
                          href={getTrxEtherscanUrl(request?.txnHash, 5)}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {request?.txnHash?.slice(0, 5)}
                          {"..."}
                          {request?.txnHash?.slice(
                            request?.txnHash?.length - 5,
                            request?.txnHash?.length
                          )}
                        </a>
                      </Typography>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
                {/* admin remark*/}
                <div className="d-flex flex-column flex-md-row justify-content-between px-lg-4">
                  <div
                    className="pt-2 flex "
                    style={{
                      color: "#989898",
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <FormattedMessage
                      id="Admin Remark"
                      defaultMessage="Admin Remark"
                    />{" "}
                  </div>
                  <div
                    className="pt-2 d-flex "
                    style={{
                      color: theme.palette.text.primary,
                      fontWeight: 500,
                      fontSize: sm ? 12 : 16,
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      textAlign={sm ? "left" : "right"}
                      variant="body2"
                      fontWeight={500}
                      fontSize={sm ? 12 : 16}
                      style={{ wordBreak: "break-word" }}
                    >
                      {request?.remark || "NA"}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row justify-content-center">
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size={md ? "small" : "large"}
                  style={{
                    margin: sm ? "4px 8px" : "0px 5px",
                    borderRadius: 14,
                    fontSize: sm ? 10 : 14,
                    textTransform: "none",
                  }}
                >
                  <FormattedMessage id="Back" defaultMessage="Back" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Dialog>
  );
}

export default RequestView;
