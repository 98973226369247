import {
  getAdminCall,
  getAdminsCall,
  loginCall,
} from "utils/httpCalls/admin/adminAuthCalls";
import {
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  GET_ADMINS,
  SET_ADMIN_AUTH_ERROR,
  SET_ADMIN_AUTH_LOADING,
} from "../types";

export const checkLogin = () => async (dispatch) => {
  const token = localStorage.getItem("admin");

  if (!token) {
    return;
  }

  console.log("authenticated checking login ", token);
  dispatch({ type: SET_ADMIN_AUTH_LOADING });

  const result = await getAdminCall(token);

  if (result?.status !== 200) {
    dispatch({
      type: SET_ADMIN_AUTH_ERROR,
      payload: result?.message,
    });
    return;
  }

  dispatch({
    type: ADMIN_LOGIN,
    payload: {
      jwtToken: token,
      id: result?.data?.id,
      email: result?.data?.email,
      name: result?.data?.name,
      role: result?.data?.role,
    },
  });
};

export const loginAdmin = (email, password) => async (dispatch) => {
  dispatch({ type: SET_ADMIN_AUTH_LOADING });

  const result = await loginCall(email, password);

  if (result?.status !== 201) {
    dispatch({
      type: SET_ADMIN_AUTH_ERROR,
      payload: result?.message,
    });

    return;
  }

  localStorage.setItem(`admin`, result?.data?.jwtToken);
  dispatch({
    type: ADMIN_LOGIN,
    payload: {
      jwtToken: result?.data?.jwtToken,
      id: result?.data?.user?.id,
      email: result?.data?.user?.email,
      name: result?.data?.user?.name,
      role: result?.data?.user?.role,
    },
  });
};

export const logoutAdmin = () => async (dispatch) => {
  localStorage.removeItem(`admin`);
  dispatch({
    type: ADMIN_LOGOUT,
  });
};

export const getAdmins =
  (token, page = 1) =>
  async (dispatch) => {
    if (!token) {
      return;
    }

    const result = await getAdminsCall(token, page);

    console.log("admins data", { result });
    if (result?.status !== 200) {
      dispatch({
        type: SET_ADMIN_AUTH_ERROR,
        payload: result?.message,
      });
      return;
    }

    dispatch({
      type: GET_ADMINS,
      payload: result.data,
    });
  };
