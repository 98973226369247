import {
  MenuBook,
  MenuBookOutlined,
  PlayCircle,
  SupportAgentOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UPDATE_TOUR } from "actions/types";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PopupLayout from "./popups/PopupLayout";
import TutorialPopup from "./popups/TutorialPopup";

function UserGuide({ setSidebarOpen }) {
  const theme = useTheme();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isTutOpen, setIsTutOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTour = () => {
    dispatch({
      type: UPDATE_TOUR,
      payload: false,
    });
  };
  return (
    <div style={{ zIndex: 10000 }}>
      <IconButton onClick={handleClick}>
        <MenuBook />
      </IconButton>

      <Menu
        id="basic-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            backgroundColor:
              theme.palette.mode === "light" ? "#ffffff" : "#393C59",
          },
        }}
        anchorOrigin={{
          vertical: sm ? "top" : "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: sm ? "top" : "center",
          horizontal: "left",
        }}
        sx={{ marginLeft: 1 }}
      >
        <MenuList
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 0,
            m: 0,
          }}
        >
          <MenuItem
            sx={{
              m: 0,
              paddingLeft: 1,
              borderRight: `1px solid ${
                theme.palette.mode === "light" ? "#919191" : "#646892"
              }`,
              fontSize: sm ? 14 : 16,
              fontWeight: 450,
            }}
            onClick={() => {
              handleClose();
              md && setSidebarOpen(true);
              handleTour();
            }}
          >
            <MenuBookOutlined sx={{ color: "#6A55EA", paddingRight: 0.1 }} />
            <FormattedMessage
              id="Beginner's Guide"
              defaultMessage="Beginner's Guide"
            />
          </MenuItem>
          <MenuItem
            sx={{
              m: 0,
              paddingLeft: 1,
              borderRight: `1px solid ${
                theme.palette.mode === "light" ? "#919191" : "#646892"
              }`,
              fontSize: sm ? 14 : 16,
              fontWeight: 450,
            }}
            onClick={() => {
              handleClose();
              setIsTutOpen(true);
            }}
          >
            <PlayCircle sx={{ color: "#6A55EA", paddingRight: 0.1 }} />
            <FormattedMessage
              id="Video Tutorial"
              defaultMessage="Video Tutorial"
            />
          </MenuItem>
          <MenuItem
            sx={{
              m: 0,
              paddingLeft: 1,
              fontSize: sm ? 14 : 16,
              fontWeight: 450,
            }}
            onClick={() => {
              handleClose();
              navigate(`/support`);
            }}
          >
            <SupportAgentOutlined
              sx={{ color: "#6A55EA", paddingRight: 0.1 }}
            />

            <FormattedMessage id="Support" defaultMessage="Support" />
          </MenuItem>
        </MenuList>
      </Menu>
      <PopupLayout popupActive={isTutOpen}>
        <TutorialPopup
          resetPopup={() => {
            setIsTutOpen(false);
          }}
        />
      </PopupLayout>
    </div>
  );
}

export default UserGuide;
