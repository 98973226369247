import {
  GET_PROFILE,
  SET_EMAIL_OTP,
  SET_PHONE_OTP,
  SET_PROFILE_ERROR,
  SET_PROFILE_LOADING,
} from "../actions/types";

const initalState = {
  profile: null,
  loading: false,
  profileError: null,
  verificationOtp: null,
};

export default function profileReducer(state = initalState, action) {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        profileError: null,
        loading: false,
      };
    case SET_PROFILE_LOADING:
      return {
        ...state,
        profileError: null,
        loading: true,
      };
    case SET_PROFILE_ERROR:
      return {
        ...state,
        profileError: action.payload,
        loading: false,
      };
    case SET_PHONE_OTP:
      return {
        ...state,
        verificationOtp: { ...state.verificationOtp, ...action.payload },
        profileError: null,
        loading: false,
      };

    case SET_EMAIL_OTP:
      return {
        ...state,
        verificationOtp: { ...state.verificationOtp, ...action.payload },
        profileError: null,
        loading: false,
      };
    default:
      return state;
  }
}
