import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useParams } from "react-router-dom";
import HowItWorks from "../../common/HowItWorks";
import { getOrderDetailsById } from "../../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { formatCurrency, fromWei, toMinutes } from "../../utils/helper";
import BigNumber from "bignumber.js";
import { cancelOrder } from "../../utils/httpCalls";
import { FormattedMessage } from "react-intl";
import GenericPopup from "common/popups/GenericPopup";

const useStyles = makeStyles((theme) => ({
  background: {
    height: "100%",
    width: "100%",
    paddingTop: "5%",
  },
  infoCard: {
    marginTop: 20,
    marginBottom: 20,
    height: "100%",
    width: "100%",
    border:
      theme.palette.mode === "light"
        ? "1px solid #EAECEE"
        : "1px solid #25284B",
    paddingTop: 30,
    padding: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.background.primary,
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: "0.02em",
  },
  subtitle: {
    color: "#414141",
    fontWeight: 400,
    fontSize: 16,
  },
  cardTitle: {
    textAlign: "center",
  },

  submitButton: {
    borderRadius: 10,
    backgroundColor: "#E0077D",
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
    minWidth: 120,
    textAlign: "center",
  },
  orderTabSelected: {
    backgroundColor: "#DF097C",
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
    minWidth: 120,
    textAlign: "center",
  },
  tableCard: {
    width: "100%",
    height: "100%",
    border: "1px solid #eeeeee",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
  },
  table: {
    width: "100%",
  },
  tr: {
    width: "100%",
    height: 45,
  },
  userText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#DF097C",
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
  },
  label: {
    color: "#616161",
    fontWeight: 500,
  },
  submitButton: {
    borderRadius: 10,
    backgroundColor: "#E0077D",
    padding: "5px 15px 5px 15px",
    color: "white",
  },
  orderBox: {
    border: "1px solid #eeeeee",
    padding: 20,
    borderRadius: "30px",
  },
  para: {
    paddingTop: 10,
    color: "#757575",
    fontSize: 14,
  },
  iconTitle: {
    paddingTop: 20,
    color: "#333333",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
  },
  iconSubtitle: {
    color: "#757575",
    fontSize: 14,
    textAlign: "center",
  },
  icon: {
    height: 50,
  },
  howTitle: {
    color: "#333333",
    fontSize: 22,
    fontWeight: 600,
    textAlign: "center",
  },
  howSubtitle: {
    width: 600,
    color: "#757575",
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
  },
}));

function ViewMyOrder() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { order_id } = useParams();
  const order = useSelector((state) => state?.order?.order);
  const userAuth = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(false);
  const [alertPopup, setAlertPopup] = useState({
    open: false,
    message: "",
  });

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(async () => {
    if (!order_id || !userAuth?.jwtToken) {
      return;
    }

    dispatch(
      getOrderDetailsById(order_id, userAuth?.account, userAuth?.jwtToken)
    );
  }, [order_id, userAuth]);

  const handleCancel = useCallback(async () => {
    if (!userAuth?.jwtToken || !order) {
      return;
    }
    console.log("cancelling...");
    setLoading(true);
    const cancelRes = await cancelOrder(
      order?._id,
      userAuth?.account,
      userAuth?.jwtToken
    );

    if (cancelRes?.status !== 200) {
      // todo: show correct error popup
      setAlertPopup({
        open: true,
        message: cancelRes.message?.msg,
      });
    }
    dispatch(
      getOrderDetailsById(order_id, userAuth?.account, userAuth?.jwtToken)
    );

    setLoading(false);

    // todo: show popup that order is camcelled
  }, [order, userAuth, loading, setLoading]);

  return (
    <Box className={classes.background}>
      <GenericPopup
        open={alertPopup.open}
        message={alertPopup.message}
        title="Error"
        handleClose={() =>
          setAlertPopup({
            open: false,
            message: "",
          })
        }
      />
      <Container>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h3" className={classes.title}>
                <FormattedMessage
                  id="Order Details"
                  defaultMessage="Order Details"
                />
              </Typography>
            </Box>
            <Box>
              <Typography
                className="d-flex flex-md-row flex-column"
                textAlign="left"
                variant="body2"
                fontSize={13}
                color="#778090"
              >
                <span>
                  <FormattedMessage
                    id="Order Number"
                    defaultMessage="Order Number"
                  />
                  :
                </span>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    mt: sm && 1,
                    color: theme.palette.text.primary,
                    paddingLeft: !sm && 5,
                  }}
                >
                  {order?.order_id}
                </span>
              </Typography>
              <Typography
                className="d-flex flex-md-row flex-column"
                textAlign="left"
                variant="body2"
                fontSize={13}
                color={"#778090"}
                mt={1}
              >
                <span>
                  <FormattedMessage
                    id="Time created"
                    defaultMessage="Time created"
                  />
                  :
                </span>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    mt: sm && 1,
                    paddingLeft: !sm && 5,
                  }}
                >
                  {moment(order?.created_at).format("hh:mm A MM-DD-YYYY")}
                </span>
              </Typography>
            </Box>
          </Box>
          <div className={classes.infoCard}>
            <Typography
              variant="h4"
              classes={classes.cardTitle}
              align="center"
              color={theme.palette.text.primary}
            >
              {order?.order_type === "buy" ? (
                <FormattedMessage id="Buy Order" defaultMessage="Buy Order" />
              ) : (
                <FormattedMessage id="Sell Order" defaultMessage="Sell Order" />
              )}
              : #{order?.order_id}
            </Typography>
            <Typography
              variant="body2"
              classes={classes.para}
              fontSize={13}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
              align="center"
              pt={1}
            >
              <FormattedMessage id="Executed at" defaultMessage="Executed at" />
              : ---
            </Typography>
            <Box className="text-center">
              <img src="/images/success_icon.png" height="100px" />
            </Box>

            <div className="d-flex justify-content-center">
              <div
                className="row justify-content-start mt-1"
                style={{ maxWidth: 800, width: "100%" }}
              >
                <div className="col-md-4 mt-3">
                  <Box>
                    <Typography
                      display="flex"
                      textAlign="left"
                      variant="body2"
                      fontSize={13}
                      color={
                        theme.palette.mode === "light" ? "#757575" : "#646892"
                      }
                      style={{ fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Total Fiat Amount"
                        defaultMessage="Total Fiat Amount"
                      />
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      fontSize={sm ? 16 : 20}
                      style={{ fontWeight: 600 }}
                      color={theme.palette.text.primary}
                    >
                      {formatCurrency(
                        new BigNumber(
                          fromWei(order?.pending_amount, order?.token?.decimals)
                        )
                          .multipliedBy(order?.order_unit_price)
                          ?.toString(),
                        true
                      )}{" "}
                      <span style={{ fontSize: 14, paddingLeft: 2 }}>
                        {order?.fiat?.fiat}
                      </span>
                    </Typography>
                  </Box>
                </div>

                <div className="col-md-4 mt-3">
                  <Typography
                    display="flex"
                    textAlign="left"
                    variant="body2"
                    fontSize={13}
                    color={
                      theme.palette.mode === "light" ? "#757575" : "#646892"
                    }
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage
                      id="Crypto Unit Price"
                      defaultMessage="Crypto Unit Price"
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize={sm ? 16 : 20}
                    style={{ fontWeight: 600 }}
                    color={theme.palette.text.primary}
                  >
                    {formatCurrency(order?.order_unit_price, true)}
                    <span style={{ fontSize: 14, paddingLeft: 2 }}>
                      {order?.fiat?.fiat}
                    </span>
                  </Typography>
                </div>

                <div className="col-md-4 mt-3">
                  <Box>
                    <Typography
                      display="flex"
                      textAlign="left"
                      variant="body2"
                      fontSize={13}
                      color={
                        theme.palette.mode === "light" ? "#757575" : "#646892"
                      }
                      style={{ fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Crypto Amount"
                        defaultMessage="Crypto Amount"
                      />
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      fontSize={sm ? 16 : 20}
                      style={{ fontWeight: 600 }}
                      color={theme.palette.text.primary}
                    >
                      {formatCurrency(
                        fromWei(order?.pending_amount, order?.token?.decimals),
                        true
                      )}
                      <span style={{ fontSize: 14, paddingLeft: 2 }}>
                        {order?.token?.symbol}
                      </span>
                    </Typography>
                  </Box>
                </div>

                <div className="col-md-4 mt-3">
                  <Box>
                    <Typography
                      display="flex"
                      textAlign="left"
                      variant="body2"
                      fontSize={13}
                      color={
                        theme.palette.mode === "light" ? "#757575" : "#646892"
                      }
                      style={{ fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Min Order Limit"
                        defaultMessage="Min Order Limit"
                      />
                      :
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      fontSize={sm ? 16 : 20}
                      style={{ fontWeight: 600 }}
                      color={theme.palette.text.primary}
                    >
                      {formatCurrency(order?.curr_min_order_value, true)}{" "}
                      <span style={{ fontSize: 14, paddingLeft: 2 }}>
                        {order?.token?.symbol}
                      </span>
                    </Typography>
                  </Box>
                </div>

                <div className="col-md-4 mt-3">
                  <Box>
                    <Typography
                      display="flex"
                      textAlign="left"
                      variant="body2"
                      fontSize={13}
                      color={
                        theme.palette.mode === "light" ? "#757575" : "#646892"
                      }
                      style={{ fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Max Order Limit"
                        defaultMessage="Max Order Limit"
                      />
                      :
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      fontSize={sm ? 16 : 20}
                      style={{ fontWeight: 600 }}
                      color={theme.palette.text.primary}
                    >
                      {formatCurrency(order?.curr_max_order_value, true)}
                      <span style={{ fontSize: 14, paddingLeft: 2 }}>
                        {order?.token?.symbol}
                      </span>
                    </Typography>
                  </Box>
                </div>

                <div className="col-md-4 mt-4">
                  <Typography
                    display="flex"
                    textAlign="left"
                    variant="body2"
                    fontSize={13}
                    color={
                      theme.palette.mode === "light" ? "#757575" : "#646892"
                    }
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage
                      id="Trade between"
                      defaultMessage="Trade between"
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize={sm ? 16 : 20}
                    style={{ fontWeight: 600 }}
                    color={theme.palette.text.primary}
                  >
                    {order?.token?.symbol} - {order?.fiat?.fiat}
                  </Typography>
                </div>
                <div className="col-md-4 mt-4">
                  <Typography
                    display="flex"
                    textAlign="left"
                    variant="body2"
                    fontSize={13}
                    color={
                      theme.palette.mode === "light" ? "#757575" : "#646892"
                    }
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Payment" defaultMessage="Payment" />
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize={20}
                    style={{ fontWeight: 600 }}
                    color={theme.palette.text.primary}
                  >
                    {order?.payment_options?.map((value, index) => (
                      <Box
                        key={index}
                        style={{
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? "#E1DCFF"
                              : "#646892",
                          width: "fit-content",
                          padding: "5px 14px 5px 14px",
                          margin: 2,
                          borderRadius: 7,
                          marginRight: 5,
                          fontSize: 14,
                        }}
                      >
                        {value?.toUpperCase()}
                      </Box>
                    ))}
                  </Typography>
                </div>
                <div className="col-md-4 mt-4">
                  <Typography
                    display="flex"
                    textAlign="left"
                    variant="body2"
                    fontSize={13}
                    color={
                      theme.palette.mode === "light" ? "#757575" : "#646892"
                    }
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Status" defaultMessage="Status" />
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize={sm ? 16 : 20}
                    style={{ fontWeight: 600 }}
                    color={theme.palette.text.primary}
                  >
                    {order?.order_status}
                  </Typography>
                </div>

                <div className="col-md-4 mt-4">
                  <Typography
                    display="flex"
                    textAlign="left"
                    variant="body2"
                    fontSize={13}
                    color={
                      theme.palette.mode === "light" ? "#757575" : "#646892"
                    }
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage
                      id="Payment Time Limit"
                      defaultMessage="Payment Time Limit"
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize={sm ? 16 : 20}
                    style={{ fontWeight: 600 }}
                    color={theme.palette.text.primary}
                  >
                    {toMinutes(order?.order_transfaction_duration)}
                  </Typography>
                </div>

                <div className="col-md-4 mt-4">
                  <Typography
                    display="flex"
                    textAlign="left"
                    variant="body2"
                    fontSize={13}
                    color={
                      theme.palette.mode === "light" ? "#757575" : "#646892"
                    }
                    style={{ fontWeight: 500 }}
                  >
                    <FormattedMessage id="Remark" defaultMessage="Remark" />
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize={11}
                    color={theme.palette.text.primary}
                  >
                    {order?.description}
                  </Typography>
                </div>
              </div>
            </div>

            <Box
              my={sm ? 1 : 2}
              mt={md ? 3 : 5}
              mx={sm ? 2 : 5}
              display="flex"
              flexWrap="wrap"
              textAlign="center"
              justifyContent="space-evenly"
            >
              <Link to="/my-orders" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    borderRadius: 10,
                    background: "#6A55EA",
                    padding: sm
                      ? "7px 16px 7px 16px"
                      : md
                      ? "9px 20px 9px 20px"
                      : "9px 55px 9px 55px",
                    color: "white",
                    marginTop: 10,
                    fontSize: md ? 12 : 14,
                  }}
                >
                  <FormattedMessage id="Need Help" defaultMessage="Need Help" />
                  ?
                </Button>
              </Link>
              <Button
                style={{
                  borderRadius: 10,
                  background:
                    order?.order_status === "cancelled" || loading
                      ? "#bdbdbd"
                      : "#6A55EA",
                  padding: sm
                    ? "7px 16px 7px 16px"
                    : md
                    ? "9px 20px 9px 20px"
                    : "9px 55px 9px 55px",
                  marginTop: 10,
                  color: "white",
                  fontSize: md ? 12 : 14,
                }}
                disabled={order?.order_status === "cancelled" || loading}
                onClick={handleCancel}
              >
                {loading ? (
                  <>
                    <FormattedMessage
                      id="Please wait"
                      defaultMessage="Please wait"
                    />
                    ...
                  </>
                ) : (
                  <FormattedMessage
                    id="Cancel Order"
                    defaultMessage="Cancel Order"
                  />
                )}
              </Button>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    borderRadius: 10,
                    background: "#6A55EA",
                    padding: sm
                      ? "7px 16px 7px 16px"
                      : md
                      ? "9px 20px 9px 20px"
                      : "9px 55px 9px 55px",
                    marginTop: 10,
                    color: "white",
                    fontSize: md ? 12 : 14,
                  }}
                >
                  <FormattedMessage
                    id="Go To Home"
                    defaultMessage="Go To Home"
                  />
                </Button>
              </Link>
            </Box>
          </div>
          <HowItWorks />
        </Box>
      </Container>
    </Box>
  );
}

export default ViewMyOrder;
