import axios from "axios";
import { globalHeaders } from "./index";
import { BASE_API_ENDPOINT } from "./index";

// order calls

export const createTrade = async (
  tradeType: string,
  requestBody: any,
  account:string,
  authToken: string
) => {
  try {
    let result;
    const signature = localStorage.getItem(`signature_${account}`);
    if (tradeType === "buy") {
      result = await axios.post(
        `${BASE_API_ENDPOINT}/transaction-apis/v1/buy-order`,
        requestBody,
        { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
      );
    } else if (tradeType === "sell") {
      result = await axios.post(
        `${BASE_API_ENDPOINT}/transaction-apis/v1/sell-order/`,
        requestBody,
        { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
      );
    } else {
      return {};
    }

    const _data = await result?.data;

    return { status: result?.status, data: _data };
  } catch (error: any) {
    console.log("createTrade error", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const fetchUserTrades = async (filter: any,account:string, authToken: string) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    response = await axios.get(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/order-transactions`,
      {
        params: filter,
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("fetchUserTrades ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const fetchUserTradeById = async (id: string,account:string, authToken: string) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    response = await axios.get(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/order-transaction/${id}`,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("fetchUserTrades ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const fetchFiatTransaferData = async (
  tradeId: string,
  account:string,
  authToken: string
) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    response = await axios.get(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/fiat-transfer/${tradeId}`,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("fetchUserTrades ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const fetchTokenTransaferData = async (
  tradeId: string,
  account:string,
  authToken: string
) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    response = await axios.get(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/token-transfer/${tradeId}`,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("fetchUserTrades ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const fetchUserTradeByOrderId = async (
  id: string,
  account:string,
  authToken: string
) => {
  try {
    let response;
    const signature = localStorage.getItem(`signature_${account}`);
    response = await axios.get(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/order/order-transaction/${id}`,
      { headers: { ...globalHeaders, "x-auth-token": authToken, signature } }
    );

    return { status: response?.status, data: response?.data };
  } catch (error: any) {
    console.log("fetchUserTrades ", { error });
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

// notify payment done request
export const updateTransaction = async (
  trxId: string,
  body: object,
  account:string,
  authToken: string
) => {
  try {
    if (!trxId) {
      return { status: false, data: null };
    }
    const signature = localStorage.getItem(`signature_${account}`);

    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/update/${trxId}`,
      body,
      {
        headers: {
          ...globalHeaders,
          "x-auth-token": authToken,
          signature,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const transferTokenCall = async (tradeId: string,account:string, authToken: string) => {
  try {
    if (!tradeId) {
      return { status: false, data: null };
    }

    const body = { trade_id: tradeId, request_time: Date.now() };
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.post(
      `${BASE_API_ENDPOINT}/vault-apis/v1/transfer-tokens`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const notifyBuyer = async (tradeId: string,account:string, authToken: string) => {
  try {
    if (!tradeId) {
      return { status: false, data: null };
    }

    const body = {};
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/notify-buyer/${tradeId}`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const notifySeller = async (tradeId: string,account:string, authToken: string) => {
  try {
    if (!tradeId) {
      return { status: false, data: null };
    }

    const body = {};
    const signature = localStorage.getItem(`signature_${account}`);
    const result = await axios.patch(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/notify-seller/${tradeId}`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};

export const cancelTradeCall = async (
  tradeId: string,
  isSeller: boolean,
  account:string,
  authToken: string
) => {
  try {
    if (!tradeId) {
      return { status: false, data: null };
    }

    const body = { };
    const signature = localStorage.getItem(`signature_${account}`);
    let result = await axios.patch(
      `${BASE_API_ENDPOINT}/transaction-apis/v1/cancel-trade-${
        isSeller ? "seller" : "buyer"
      }/${tradeId}`,
      body,
      {
        headers: { ...globalHeaders, "x-auth-token": authToken, signature },
      }
    );

    return { status: result?.status, data: result?.data };
  } catch (error: any) {
    console.log("error ", error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.errors?.[0],
    };
  }
};
