import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import DayProgress from "./DayProgress";

const QuestCard = ({
  quest,
  progress,
  claiming,
  claimLoading,
  claim,
  index,
}) => {
  const theme = useTheme();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box pl={0.3} py={1} mb={0.3} alignItems="center" className="row">
      <Box className="col-8" alignItems="center" alignContent="center">
        <div className="d-flex flex-row">
          <Typography
            fontSize={sm ? 16 : 20}
            fontWeight={600}
            color={theme.palette.text.primary}
            width="95%"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {progress && progress[quest?._id]?.day && (
              <DayProgress day={progress[quest?._id]?.day} />
            )}
            <FormattedMessage
              id={quest?.description}
              defaultMessage={quest?.description}
            />
          </Typography>
          {progress && progress[quest?._id]?.total && (
            <Typography
              textAlign="right"
              alignSelf="start"
              variant="body2"
              fontSize={sm ? 12 : 14}
              px={0.3}
              style={{
                color: theme.palette.action.green,
                width: "10%",
              }}
            >
              {progress[quest?._id]?.achieved}/{progress[quest?._id]?.total}
            </Typography>
          )}
        </div>
      </Box>

      <Box className="col-4 ">
        <Box
          m={1}
          mr={0}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="end"
        >
          <Typography
            fontSize={sm ? 12 : 14}
            fontWeight={500}
            ml={1}
            color={
              theme.palette.mode === "light"
                ? "#919191"
                : theme.palette.text.secondary
            }
          >
            <FormattedMessage id="Points" defaultMessage="Points" />
          </Typography>
          <Typography
            fontSize={sm ? 12 : 14}
            fontWeight={500}
            ml={1}
            color={theme.palette.text.primary}
          >
            {quest?.reward}
          </Typography>
        </Box>
        <Box
          m={1}
          mr={0}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="end"
        >
          <Button
            disabled={!quest.completed || quest.claimed || claimLoading}
            style={{
              borderRadius: 10,
              backgroundColor: quest.completed
                ? quest.claimed
                  ? theme.palette.action.green
                  : theme.palette.button.primary
                : theme.palette.mode === "light"
                ? "#bdbdbd"
                : "#646892",
              padding: "5px 20px 5px 20px",
              color: "white",
            }}
            size={sm ? "medium" : "large"}
            onClick={() => claim(quest)}
          >
            {claiming?.id === quest?._id ? (
              <FormattedMessage id="Claiming" defaultMessage="Claiming" />
            ) : !claimLoading ? (
              quest.claimed ? (
                <FormattedMessage id="Claimed" defaultMessage="Claimed" />
              ) : (
                <FormattedMessage id="Claim" defaultMessage="Claim" />
              )
            ) : (
              <img
                src="/table_loader.gif"
                alt="loader"
                height={25}
                width={25}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestCard;
