const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          action: {
            disabled: "#bdbdbd",
            disabledBackground: "rgba(209, 255, 26, 0.3)",
            red: "#F7455F",
            green: "#2EBD85",
            payment: {
              "Phone Pay": "#5A2397",
              "Paytm Wallet": "#005AA9",
              IMPS: "#EC6E3D",
              NEFT: "#8E8E8E",
              Paypal: "#0094D3",
              "Bank Transfer": "#FFDD3C",
              "Perfect Money": "#B4B4B4",
              Wise: "#00AEEF",
              "Google Pay": "#4285f4",
              PhonePe: "#5f259f",
              "UPI (any)": "#6d6e72",
              Advcash: "#00b27a",
              Revolut: "#191c1f",
              "Cash In Person": "#00c087",
              ABA: "#015F7B",
              Skrill: "#811E68",
              Payeer: "#03A9F4",
              Zinli: "#5A37B4",
              Zelle: "#7042D0",
              AirTM: "#1783FA",
              NETELLER: "#8FB750",
              "Bank of America": "#012169",
              "Banesco Panama": "#00794F",
              "TBC Bank": "#00A3E0",
              "Banco Pichincha": "#D89F00",
              "Bank of Georgia": "#F26724",
              "Credit Bank of Peru": "#002A8D",
              "Western Union": "#365578",
              "Cash App": "#00d800",
              "Mercantil Bank Panama": "#00438e",
              "Banco de Credito": "#FF7B00",
              GCash: "#70baf7",
              Payall: "#2BC27D",
              "SEPA Instant": "#10298e",
              "SEPA(EU) bank transfer": "#7b74f6",
              ZEN: "#222222",
              Paysera: "#010199",
              BBVA: "#002e6d",
              N26: "#339a84",
              Bunq: "#912f30",
              ING: "#eb6606",
              "CaixaBank (Spain)": "#008fc9",
              MAIB: "#3bbfad",
              MICB: "#f2c801",
              "Bank Transfer (Vietnam)": "#FFDD3C",
              MoMo: "#ae2070",
              ZaloPay: "#008fe5",
              "Viettel Money": "#e20030",
              "ShopeePay-SEA": "#e1482d",
              "Bank Transfer (Turkey)": "#e1482d",
              Ziraat: "#ec1c24",
              Garanti: "#006b3d",
              Papara: "#b43a66",
              "Moneygram Turkey": "#eb1c2e",
              DenizBank: "#0066a8",
              "Kuveyt Turk": "#036646",
              BCA: "#005eaf",
              DANA: "#108de9",
              GoPay: "#5da4da",
              OVO: "#765ea9",
              "Mandiri Pay": "#fab20a",
              "Permata Me": "#32a93a",
              "Bank BRI": "#ffde00",
              "SEA Bank": "#de5a00",
              "Yap! (BNI)": "#0c7393",
              "Chipper Cash": "#6972f1",
              Tinkoff: "#e9da18",
              RosBank: "#14215f",
              Raiffeisenbank: "#fff101",
              QIWI: "#ff9900",
              "A-Bank": "#78B045",
              "BinancePay (RUB)": "#f3ba2f",
              Юmoney: "#843bef",
              "MTS-Bank": "#e30611",
              "Home Credit Bank (Russia)": "#c80f2e",
              "Uralsib Bank": "#542f89",
              "BCS Bank": "#005eaf",
              Wechat: "#0cc902",
              Alipay: "#009fe3",
              "Line pay": "#000000",
              "Post Bank": "#a72723",
              "7-E leven": "#ef6c00",
              "FNB-ewallet": "#01aaad",
              "Capitec Bank": "#dd303a",
              "Bank Transfer (Venezuela)": "#1a2038",
              "Pago Movil": "#0d89e0",
              Banesco: "#003c71",
              "Banco de Venezuela": "#ffef00",
              Mercantil: "#004192",
              Provincial: "#003c70",
              "BNC Banco Nacional de Crédito": "#003d7d",
              "Bank Transfer (Argentina)": "#1a2038",
              "Lemon Cash": "#86ff2e",
              "Banco Brubank": "#614ad9",
              Uala: "#3e6bfd",
              Reba: "#4364ee",
              "Naranja X": "#fe5000",
              "Belo app": "#2bfcb3",
              Prex: "#141c48",
              "Banco Santander Argentina": "#ec1c24",
              "Cash Deposit to Bank": "#32425a",
              "Emirates NBD": "#172e7a",
              "ADIB: Abu Dhabi Islamic Bank": "#3da7ea",
              "Abu Dhabi Commercial Bank ADCB": "#ed1d24",
              "AL MASHREQ Bank": "#f47731",
              "National Bank of Abu Dhabi FAB": "#ef2c1e",
              "Al Hilal Bank": "#f38131",

              "Bancolombia S.A": "#283572",
              Nequi: "#ff2f73",
              "Davivienda S.A": "#ed1c27",
              Daviplata: "#f62936",
              "Banco de Bogota": "#002c76",
              "Scotiabank Colpatria": "#ec111a",
              Movii: "#dd0059",
              "Vodafone cash": "#ee3023",
              "CIB Bank": "#006f3a",
              "National Bank of Egypt (NBE)": "#00643e",
              "Banque Misr": "#e6ad02",
              "Alex Bank": "#eb660b",
              "Orange Money (OM)": "#ffb3c7",
              "Lloyds Bank": "#006a4d",
              Monzo: "#d35662",
              "Faster Payments": "#036ab4",
              "Starling Bank": "#6040cb",
              "Skrill (Moneybookers)": "#6a3494",
              "Paysend.com": "#7733ff",

              "MTN Mobile Money": "#036ab4",
              EziPay: "#123456",
              CalBank: "#ffd100",
              "Halyk Bank": "#00805f",
              "Kaspi Bank": "#ef4635",
              ForteBank: "#0db4c4",
              "CenterCredit Bank": "#06834c",
              "Jysan Bank": "#f05323",
              "Altyn Bank": "#f24a3f",
              "Eurasian Bank": "#e1056d ",
              "Home Credit Kazakhstan": "#da0028 ",
              "Freedom Bank": "#4fb84e ",
              "Bank RBK": "#2fc7c8 ",
              "Mobile top-up": "#4bd63b ",
              "UnionBank of the Philippines": "#c8102f",
              Paymaya: "#177fff",
              "Bank of the Philippine Islands (BPI)": "#b11116",
              "Banco De Oro(BDO)": "#0b2972",
              "Coins.ph": "#0060ab",
              "Metropolitan Bank of the Philippines": "#023184",
              "Rizal Commercial Banking Corporation": "#3783e9",
              "CIMB Philippines": "#e50019",

              "Easypaisa-PK Only": "#674e99",
              "Meezan Bank": "#711871",
              SadaPay: "#66eed0",
              Raast: "#06663c",
              NayaPay: "#f18821",
              "Allied Bank Limited": "#102a83",
              "Standard Chartered Bank": "#38d200",
              "United Bank Limited": "#0075bf",
              Pix: "#32bcad",
              "Bank Transfer (Brazil)": "#1a2038",
              PicPay: "#21c25e",
              PagSeguro: "#1bb99a",
              "Alinma Bank": "#522d24",
              "stc pay": "#00c48c",
              "Al Rajhi Bank": "#221afb",
              "Transfers with specific bank": "#a911cf",
              urpay: "#ff5b24",
              "Saudi National Bank (AlAhli Bank)": "#00a63f",
              "Riyad Bank": "#008e7f",
              "The Saudi British Bank (SABB)": "#f0b90b",
              bKash: "#d12053",
              Nagad: "#e7873c",
              Rocket: "#119849",
              "Bank Transfer (Taiwan) ": "#1a2038",
              "JKO Transfer ": "#c9191d ",
              "EasyPay ": "#136bb5 ",
              AnyCash: "#f0b90b",
              Monobank: "#664cdb",
              PUMB: "#d13239",

              Izibank: "#eb522a",
              Oschadbank: "#008348",

              Sportbank: "#e53721",
              "Raiffeisen Bank Aval": "#fdf051",
              "Sense SuperApp": "#000000",
              Ukrsibbank: "#009b7b",
              NEO: "#ffbe00",
              "BinancePay (UAH)": "#d89f00",
              "OTP Bank": "#52ae30",
              "Bank Vlasnyi Rakhunok": "#ff001d",
              "Credit Agricole": "#007d8f",
              "Scotiabank Peru": "#ec111a",
              Interbank: "#00bb31",
              Yape: "#692480",
              Plin: "#0581f6",

              OSKO: "#181a20",
              "Interac e-Transfer": "#e2a100",
              "TD Bank": "#008a00",
              "RBC Royal Bank": "#006ac3",
              BMO: "#0078c1",
              "Tangerine bank": "#ea8024",
              CIBC: "#c41f3e",
              "True Money": "#32425a",
              Ecobank: "#ffb3c7",
              "Wave Mobile Money": "#f57b00",
              "Moov Money": "#cd0067",
              Inecobank: "#006037",
              "Airtel Money": "#e40000",
              Humo: "#bfa64b",
              "Uzbek National Bank": "#093475",
              Unired: "#e20001",
              Uzcard: "#fd8700",
              Kapitalbank: "#fbc002",
              Anorbank: "#a30041",
              PayMe: "#db0011",
              Apelsin: "#f47235",
              CLICK: "#65bed6",
              "Red Pagos": "#d2d70c",
              "Itaú Uruguay": "#ec7000",
              "Bank of the Republic of Uruguay": "#005ca4",
              "Banco Santander Uruguay": "#ec0000",
              "OCA Blue": "#0069df",
              "Scotiabank Uruguay": "#ec111a",
              "Qatar National Bank QNB": "#072a5b",
              "Qatar Islamic Bank QIB": "#0175b0",
              "Bank Transfer (Middle East)": "#f0b90b",
              QNB: "#001b59",
              "Qatar International Islamic Bank QIIB": "#be2d34",
              "Trans Bank": "#d2a25b",
              "Faster Payment System (FPS)": "#3270bb",

              STP: "#004197",
              Citibanamex: "#056dae",
              Banorte: "#eb0029",
              "Santander Mexico": "#eb0029",
              OXXO: "#cc2229",
              "Česká spořitelna": "#e30613",

              ČSOB: "#0099cc",
              "MONETA Money Bank": "#140757",
              UniCredit: "#e2001a",
              "BCR Bank": "#004b84",
              "Bank Transilvania": "#000000",
              "BRD Bank": "#ed0210",
              CEC: "#004710",

              "Touch n Go": "#295daa",
              Maybank: "#ffce04",
              DuitNow: "#ec2f66",
              PayNow: "#7c1a78",
            },
          },
          primary: {
            main: "#6A55EA",
            hover: "rgba(70, 56, 160,1)",
          },
          text: {
            main: "#212121",
            primary: "#212121",
            secondary: "#414141",
          },
          button: {
            primary: "#6A55EA",
            secondary: "#eeeeee",
          },
          claimButton: {
            primary: "#6A55EA",
            secondary: "#B0A6F0",
          },
          background: {
            main: "#6A55EA",
            primary: "#FFFFFF",
            secondary: "#eeeeee",
            light: "rgba(106, 85, 234,0.6)",
            highlight: "rgb(106, 85, 234, 0.18)",
          },
          dropdown: {
            main: "#ffffff",
          },
        }
      : {
          action: {
            disabled: "#bdbdbd",
            disabledBackground: "rgba(209, 255, 26, 0.3)",
            red: "#F7455F",
            green: "#2EBD85",
            payment: {
              "Phone Pay": "#5A2397",
              "Paytm Wallet": "#005AA9",
              IMPS: "#EC6E3D",
              NEFT: "#8E8E8E",
              Paypal: "#0094D3",
              "Bank Transfer": "#FFDD3C",
              "Perfect Money": "#B4B4B4",
              Wise: "#00AEEF",
              "Google Pay": "#4285f4",
              PhonePe: "#5f259f",
              "UPI (any)": "#6d6e72",
              Advcash: "#00b27a",
              Revolut: "#191c1f",
              "Cash In Person": "#00c087",
              ABA: "#015F7B",
              Skrill: "#811E68",
              Payeer: "#03A9F4",
              Zinli: "#5A37B4",
              Zelle: "#7042D0",
              AirTM: "#1783FA",
              NETELLER: "#8FB750",
              "Bank of America": "#012169",
              "Banesco Panama": "#00794F",
              "TBC Bank": "#00A3E0",
              "Banco Pichincha": "#D89F00",
              "Bank of Georgia": "#F26724",
              "Credit Bank of Peru": "#002A8D",
              "Western Union": "#365578",
              "Cash App": "#00d800",
              "Mercantil Bank Panama": "#00438e",
              "Banco de Credito": "#FF7B00",
              GCash: "#70baf7",
              Payall: "#2BC27D",
              "SEPA Instant": "#10298e",
              "SEPA(EU) bank transfer": "#7b74f6",
              ZEN: "#222222",
              Paysera: "#010199",
              BBVA: "#002e6d",
              N26: "#339a84",
              Bunq: "#912f30",
              ING: "#eb6606",
              "CaixaBank (Spain)": "#008fc9",
              "Privat Bank (Universal Card)": "#55b029",
              MAIB: "#3bbfad",
              MICB: "#f2c801",
              "Bank Transfer (Vietnam)": "#FFDD3C",
              MoMo: "#ae2070",
              ZaloPay: "#008fe5",
              "Viettel Money": "#e20030",
              "ShopeePay-SEA": "#e1482d",
              "Bank Transfer (Turkey)": "#e1482d",
              Ziraat: "#ec1c24",
              Garanti: "#006b3d",
              Papara: "#b43a66",
              "Moneygram Turkey": "#eb1c2e",
              DenizBank: "#0066a8",
              "Kuveyt Turk": "#036646",
              BCA: "#005eaf",
              DANA: "#108de9",
              GoPay: "#5da4da",
              OVO: "#765ea9",
              "Mandiri Pay": "#fab20a",
              "Permata Me": "#32a93a",
              "Bank BRI": "#ffde00",
              "SEA Bank": "#de5a00",
              "Yap! (BNI)": "#0c7393",
              "Chipper Cash": "#6972f1",
              Tinkoff: "#e9da18",
              RosBank: "#14215f",
              Raiffeisenbank: "#fff101",
              QIWI: "#ff9900",
              "A-Bank": "#78B045",
              "BinancePay (RUB)": "#f3ba2f",
              Юmoney: "#843bef",
              "MTS-Bank": "#e30611",
              "Home Credit Bank (Russia)": "#c80f2e",
              "Uralsib Bank": "#542f89",
              "BCS Bank": "#005eaf",
              Wechat: "#0cc902",
              Alipay: "#009fe3",
              "Line pay": "#000000",
              "Post Bank": "#a72723",
              "7-Eleven": "#ef6c00",
              "FNB-ewallet": "#01aaad",
              "Capitec Bank": "#dd303a",
              "Bank Transfer (Venezuela)": "#1a2038",
              "Pago Movil": "#0d89e0",
              Banesco: "#003c71",
              "Banco de Venezuela": "#ffef00",
              Mercantil: "#004192",
              Provincial: "#003c70",
              "BNC Banco Nacional de Crédito": "#003d7d",
              "Bank Transfer (Argentina)": "#1a2038",
              "Lemon Cash": "#86ff2e",
              "Banco Brubank": "#614ad9",
              Uala: "#3e6bfd",
              Reba: "#4364ee",
              "Naranja X": "#fe5000",
              "Belo app": "#2bfcb3",
              Prex: "#141c48",
              "Banco Santander Argentina": "#ec1c24",
              "Cash Deposit to Bank": "#32425a",
              "Emirates NBD": "#172e7a",
              "ADIB: Abu Dhabi Islamic Bank": "#3da7ea",
              "Abu Dhabi Commercial Bank ADCB": "#ed1d24",
              "AL MASHREQ Bank": "#f47731",
              "National Bank of Abu Dhabi FAB": "#ef2c1e",
              "Al Hilal Bank": "#f38131",

              "Bancolombia S.A": "#283572",
              Nequi: "#ff2f73",
              "Davivienda S.A": "#ed1c27",
              Daviplata: "#f62936",
              "Banco de Bogota": "#002c76",
              "Scotiabank Colpatria": "#ec111a",
              Movii: "#dd0059",
              "Vodafone cash": "#ee3023",
              "CIB Bank": "#006f3a",
              "National Bank of Egypt (NBE)": "#00643e",
              "Banque Misr": "#e6ad02",
              "Alex Bank": "#eb660b",
              "Orange Money (OM)": "#ffb3c7",
              "Lloyds Bank": "#006a4d",
              Monzo: "#d35662",
              "Faster Payments": "#036ab4",
              "Starling Bank": "#6040cb",
              "Skrill (Moneybookers)": "#6a3494",
              "Paysend.com": "#7733ff",
              "MTN Mobile Money": "#036ab4",
              EziPay: "#123456",
              CalBank: "#ffd100",
              "Halyk Bank": "#00805f",
              "Kaspi Bank": "#ef4635",
              ForteBank: "#0db4c4",
              "CenterCredit Bank": "#06834c",
              "Jysan Bank": "#f05323",
              "Altyn Bank": "#f24a3f",
              "Eurasian Bank": "#e1056d ",
              "Home Credit Kazakhstan": "#da0028 ",
              "Freedom Bank": "#4fb84e ",
              "Bank RBK": "#2fc7c8 ",
              "Mobile top-up": "#4bd63b ",
              "UnionBank of the Philippines": "#c8102f",
              Paymaya: "#177fff",
              "Bank of the Philippine Islands (BPI)": "#b11116",
              "Banco De Oro (BDO)": "#0b2972",
              "Coins.ph": "#0060ab",
              "Metropolitan Bank of the Philippines": "#023184",
              "Rizal Commercial Banking Corporation": "#3783e9",
              "CIMB Philippines": "#e50019",

              "Easypaisa-PK Only": "#674e99",
              "Meezan Bank": "#711871",
              SadaPay: "#66eed0",
              Raast: "#06663c",
              NayaPay: "#f18821",
              "Allied Bank Limited": "#102a83",
              "Standard Chartered Bank": "#38d200",
              "United Bank Limited": "#0075bf",
              Pix: "#32bcad",
              "Bank Transfer (Brazil)": "#1a2038",
              PicPay: "#21c25e",
              Mercadopago: "#009ee3",
              PagSeguro: "#1bb99a",
              "Alinma Bank": "#522d24",
              "stc pay": "#00c48c",
              "Al Rajhi Bank": "#221afb",
              "Transfers with specific bank": "#a911cf",
              urpay: "#ff5b24",
              "Saudi National Bank (AlAhli Bank)": "#00a63f",
              "Riyad Bank": "#008e7f",
              "The Saudi British Bank (SABB)": "#f0b90b",
              bKash: "#d12053",
              Nagad: "#e7873c",
              Rocket: "#119849",
              "Bank Transfer (Taiwan)": "#1a2038",
              "JKO Transfer": "#c9191d ",
              EasyPay: "#136bb5 ",

              AnyCash: "#f0b90b",
              Monobank: "#664cdb",
              PUMB: "#d13239",

              Izibank: "#eb522a",
              Oschadbank: "#008348",

              Sportbank: "#e53721",
              "Raiffeisen Bank Aval": "#fdf051",
              "Sense SuperApp": "#000000",
              Ukrsibbank: "#009b7b",
              NEO: "#ffbe00",
              "BinancePay (UAH)": "#d89f00",
              "OTP Bank": "#52ae30",
              "Bank Vlasnyi Rakhunok": "#ff001d",
              "Credit Agricole": "#007d8f",
              "Scotiabank Peru": "#ec111a",
              Interbank: "#00bb31",

              Yape: "#692480",
              Plin: "#0581f6",

              OSKO: "#181a20",
              "Interac e-Transfer": "#e2a100",
              "TD Bank": "#008a00",
              "RBC Royal Bank": "#006ac3",
              BMO: "#0078c1",
              "Tangerine bank": "#ea8024",
              CIBC: "#c41f3e",
              "True Money": "#32425a",
              "Wave Mobile Money": "#f57b00",
              "Moov Money": "#cd0067",
              Inecobank: "#006037",
              "Airtel Money": "#e40000",
              Humo: "#bfa64b",
              "Uzbek National Bank": "#093475",
              Unired: "#e20001",
              Uzcard: "#fd8700",
              Kapitalbank: "#fbc002",
              Anorbank: "#a30041",
              PayMe: "#db0011",
              Apelsin: "#f47235",
              CLICK: "#65bed6",
              "Red Pagos": "#d2d70c",
              "Itaú Uruguay": "#ec7000",
              "Bank of the Republic of Uruguay": "#005ca4",
              "Banco Santander Uruguay": "#ec0000",
              "OCA Blue": "#0069df",
              "Scotiabank Uruguay": "#ec111a",
              "Qatar National Bank QNB": "#072a5b",
              "Qatar Islamic Bank QIB": "#0175b0",
              "Bank Transfer (Middle East)": "#f0b90b",
              QNB: "#001b59",
              "Qatar International Islamic Bank QIIB": "#be2d34",
              "Trans Bank": "#d2a25b",
              "Faster Payment System (FPS)": "#3270bb",

              STP: "#004197",
              Citibanamex: "#056dae",
              Banorte: "#eb0029",
              "Santander Mexico": "#eb0029",
              OXXO: "#cc2229",
              "Česká spořitelna": "#e30613",

              ČSOB: "#0099cc",
              "MONETA Money Bank": "#140757",
              UniCredit: "#e2001a",
              "BCR Bank": "#004b84",
              "Bank Transilvania": "#000000",
              "BRD Bank": "#ed0210",
              CEC: "#004710",
              "Touch n Go": "#295daa",
              Maybank: "#ffce04",
              DuitNow: "#ec2f66",
              PayNow: "#7c1a78",
            },
          },
          primary: {
            main: "#6A55EA",
            hover: "rgba(70, 56, 160,1)",
          },
          text: {
            main: "#212121",
            primary: "#FFFFFF",
            secondary: "#646892",
          },
          button: {
            primary: "#6A55EA",
            secondary: "#212446",
          },
          claimButton: {
            primary: "#6A55EA",
            secondary: "#483F9A",
          },
          background: {
            main: "#6A55EA",
            primary: "#25284B",
            secondary: "#212446",
            light: "rgba(106, 85, 234,0.6)",
            highlight: "#33365A",
          },
          dropdown: {
            main: "#1E2142",
          },
        }),
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h1: {
      fontFamily: "Poppins",
      fontSize: "3rem",
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: "2.4rem",
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "0.02em",
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: "1.0rem",
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Poppins",
      fontSize: "0.75",
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    body1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: "0.02em",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "1rem",
    },
  },
});

// let theme = responsiveFontSizes(themes);
export default getDesignTokens;
