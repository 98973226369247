import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserTradeById } from "actions/tradeActions";
import { cancelDisputeCall } from "utils/httpCalls/disputeCalls";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: 788,
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  icon: {
    width: 25,
    height: 25,
    color: "white",
  },
  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: 20,
    width: "90%",

    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  condition: {
    cursor: "pointer",
  },
}));

const DISPUTE_CANCEL_REASONS = [
  "Amount reversed by my Bank.",
  "Transaction failed on my behalf.",
  "Seller received the amount successfully.",
  "Seller provided the necessary information to complete the payment.",
  "I sent the remaining amount.",
  "I raised the dispute by mistake.",
  "I received the tokens.",
  "Others",
];

const CancelDisputePopup = ({ resetPopup, trade }) => {
  const [isChecked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedReason, setReason] = useState(null);
  const authToken = useSelector((state) => state?.user?.jwtToken);
  const account = useSelector((state) => state?.user?.account);
  const dispatch = useDispatch();
  const [otherReason, setOtherReason] = useState("");

  const handleConfirm = useCallback(async () => {
    if (!authToken) {
      setError("Invalid auth token, please refresh and try again!");
      return;
    }

    if (!trade?._id) {
      setError("Invalid trade, please refresh and try again!");
      return;
    }

    if (!selectedReason) {
      setError("Please select reason for dispute cancel!");
      return;
    }

    setLoading(true);

    const reason = selectedReason === "Others" ? otherReason : selectedReason;
    const cancelRes = await cancelDisputeCall(
      trade?.dispute?._id,
      reason,
      account,
      authToken
    );
    setLoading(false);

    // console.log("cancel response", cancelRes);
    resetPopup();
    if (cancelRes.status !== 201) {
      // console.log("cancel response ", { cancelRes });
      setError(cancelRes?.message?.msg);
      return;
    }

    dispatch(getUserTradeById(trade?._id, account, authToken));

    resetPopup();
  }, [loading, authToken, trade, selectedReason, otherReason]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const isDisabled = useMemo(() => {
    if (selectedReason === "Others") {
      return loading || !otherReason || !isChecked;
    }

    return loading || !selectedReason || !isChecked;
  }, [loading, selectedReason, otherReason, isChecked]);

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className="d-flex justify-content-end">
          <Close
            style={{ color: theme.palette.text.primary, fontSize: 28 }}
            onClick={resetPopup}
          />
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Box className={classes.heading}>
            <FormattedMessage
              id="Cancel dispute"
              defaultMessage="Cancel dispute"
            />
          </Box>
        </div>
        <Box display="flex" justifyContent="center" pt={sm ? 1 : 2}>
          <Box className={classes.cautionTextBox}>
            <Typography
              fontSize={sm ? 20 : 22}
              color={"#919191"}
              fontWeight={600}
              mb={sm ? 0.5 : 1}
            >
              <FormattedMessage id="Warning" defaultMessage={"Warning"} />
            </Typography>
            <Typography
              fontSize={sm ? 13 : 16}
              color={"#919191"}
              fontWeight={sm ? 400 : 500}
              mb={sm ? 0.5 : 1}
            >
              <FormattedMessage
                id="canceldisputewarn"
                defaultMessage="There will be no option to raise dispute again. Please do NOT cancel if your issue is not resolved"
              />
            </Typography>
          </Box>
        </Box>

        <Typography
          fontSize={sm ? 14 : 17}
          fontWeight={sm ? 400 : 600}
          color={"#919191"}
          textAlign="center"
          mt={2.5}
          mb={2.5}
        ></Typography>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-10">
            <FormControl
              variant="outlined"
              margin={"1"}
              style={{ width: "100%" }}
            >
              <InputLabel id="raise-dispute-label">
                <FormattedMessage id="Reason" defaultMessage="Reason" />{" "}
              </InputLabel>
              <Select
                variant="outlined"
                value={selectedReason}
                translate="yes"
                lang="fr"
                label="Reason"
                disableUnderline={true}
                style={{
                  fontWeight: 600,
                  letterSpacing: 1,
                  width: sm ? "90%" : "80%",
                  color: theme.palette.text.primary,
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 350,
                      width: 250,
                      backgroundColor: theme.palette.dropdown.main,
                    },
                  },
                }}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              >
                {DISPUTE_CANCEL_REASONS?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    <div className="text-wrap">
                      {" "}
                      <FormattedMessage id={item} defaultMessage={item} />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-md-2"></div>
        </div>

        {selectedReason === "Others" && (
          <div className=" row mt-3">
            <div className="col-md-2 pb-2 pb-sm-0"></div>
            <div className="col-md-10">
              <TextareaAutosize
                type="text"
                maxLength={1000}
                value={otherReason}
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
                placeholder="Enter your reason"
                style={{
                  width: sm ? "95%" : "80%",
                  minHeight: 120,
                  height: 120,
                  maxHeight: 800,
                  border:
                    theme.palette.mode === "light"
                      ? "1px solid #EAECEE"
                      : "1px solid #25284B",
                  boxSizing: "border-box",
                  borderRadius: 15,
                  outline: "none",
                  padding: 10,
                  backgroundColor: theme.palette.background.highlight,
                  color: theme.palette.text.primary,
                }}
              />
            </div>
          </div>
        )}

        <Box
          margin="3px auto"
          mt={2.5}
          display="flex"
          justifyContent="center"
          width="90%"
          alignItems="center"
          className={classes.condition}
          onClick={() => setChecked(!isChecked)}
        >
          <Checkbox size="small" checked={isChecked} />
          <Typography
            fontSize={sm ? 14 : 18}
            fontWeight={500}
            color={"#919191"}
            ml={1}
          >
            <FormattedMessage
              id="I confirm that my issue is resolved and I want to close this dispute."
              defaultMessage="I confirm that my issue is resolved and I want to close this dispute."
            />
          </Typography>
        </Box>
        {error && (
          <Box
            margin="3px auto"
            mt={2.5}
            display="flex"
            justifyContent="center"
            width="90%"
            alignItems="center"
          >
            <Typography
              fontSize={sm ? 14 : 18}
              fontWeight={500}
              color={"red"}
              ml={1}
            >
              {error}
            </Typography>
          </Box>
        )}
        <Box className="d-flex justify-content-center align-items-baseline pt-3">
          <div>
            <button
              onClick={handleConfirm}
              style={{
                background: isDisabled
                  ? theme.palette.mode === "light"
                    ? "#bdbdbd"
                    : "#646892"
                  : "#6A55EA",
                border: isDisabled
                  ? theme.palette.mode === "light"
                    ? "1px solid #bdbdbd"
                    : "1px solid #646892"
                  : `1px solid #6A55EA`,
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: sm ? 15 : 20,
                paddingRight: sm ? 15 : 20,
                fontSize: sm ? 13 : 16,
                textDecoration: "none",
                color: "#fff",
              }}
              disabled={isDisabled}
            >
              {loading ? (
                <FormattedMessage
                  id="Cancelling..."
                  defaultMessage="Cancelling..."
                />
              ) : (
                <FormattedMessage
                  id="Cancel dispute"
                  defaultMessage="Cancel dispute"
                />
              )}
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default CancelDisputePopup;
