import { Close } from "@mui/icons-material";
import {
  Button,
  Snackbar,
  IconButton,
  Box,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fromWei } from "../../utils/helper";
import { FormattedMessage } from "react-intl";

const MessagePopup = ({ open, handleClose, data }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={10000}
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: theme.palette.background.primary,
          color: "black",
          border: `5px solid ${theme.palette.primary.main}`,
          padding: 0,
        },
        "& .MuiSnackbarContent-action": {
          padding: 0,
          margin: 0,
          width: "100%",
        },
        "& .MuiSnackbarContent-message": {
          padding: 0,
        },
      }}
      onClose={handleClose}
      action={
        <Box width="100%" my={1.5} mx={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={sm ? "flex-start" : "center"}
            mb={1.5}
          >
            <Box
              display="flex"
              flexDirection={sm && "column"}
              mr={5}
              flexWrap="wrap"
            >
              <Typography
                fontWeight={600}
                fontSize={13}
                color={theme.palette.text.secondary}
                whiteSpace="nowrap"
              >
                {`New message from
              ${
                data?.sender?.length > 10
                  ? data?.sender?.slice(0, 10) + "..."
                  : data?.sender
              }`}
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={13}
                color={theme.palette.text.secondary}
                whiteSpace="nowrap"
                ml={1}
                display={sm && "none"}
              >
                -
              </Typography>
              <Box display="flex">
                <Typography
                  fontWeight={600}
                  fontSize={13}
                  color={theme.palette.text.secondary}
                  ml={!sm && 1}
                  whiteSpace="nowrap"
                >
                  <FormattedMessage id="In order" defaultMessage="In order" />
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={13}
                  color={
                    data?.orderType === "buy"
                      ? theme.palette.action.green
                      : theme.palette.action.red
                  }
                  ml={0.5}
                  whiteSpace="nowrap"
                >
                  <FormattedMessage
                    id={data?.orderType?.toUpperCase()}
                    defaultMessage={data?.orderType?.toUpperCase()}
                  />
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={13}
                  color={theme.palette.text.secondary}
                  ml={0.5}
                  whiteSpace="nowrap"
                >
                  {`${fromWei(data?.amount, data?.token?.decimals)} ${
                    data?.token?.symbol
                  }`}
                </Typography>
              </Box>
            </Box>

            <IconButton onClick={handleClose} size="small" sx={{ p: 0, m: 0 }}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={data?.avatar ? `${data?.avatar}` : null}
                alt={data?.sender}
                sx={{
                  height: 36,
                  width: 36,
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                {data?.sender[0]?.toUpperCase()}
              </Avatar>
              <Box mx={1.5} width="80%">
                <Typography fontSize={14} color={theme.palette.text.primary}>
                  {data?.text.length > 30
                    ? data?.text?.slice(0, 30) + "..."
                    : data?.text}
                </Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Button
                size="small"
                sx={{
                  color: "white",
                  backgroundColor: theme.palette.primary.main,
                  padding: "3px 8px",
                  marginRight: 0.2,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={() => {
                  navigate(`/order-waiting/${data?.transactionId}`);
                  handleClose();
                }}
              >
                <FormattedMessage id="View" defaultMessage="View" />
              </Button>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default MessagePopup;
