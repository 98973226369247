import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close, CloseRounded, Image, SwapHoriz } from "@mui/icons-material";
import {
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Checkbox,
  Hidden,
} from "@mui/material";
import { fromWei, formatCurrency } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import CountDownTime from "../../pages/Orders/OrderTransaction/CountDownTime";
import { updateTransaction } from "utils/httpCalls/orderTradeCalls";
import { getUserTradeById, updateTransferData } from "actions/tradeActions";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    maxWidth: 788,
    maxHeight: "100%",
    overflowY: "scroll",
    position: "relative",
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(180deg, #FFFFFF 0%, #D9E8FC 100%)"
        : theme.palette.background.primary,
    border: "10px solid #6A55EA",
    padding: 8,
    borderRadius: 4,
    zIndex: 11,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "95%",
    },
  },
  inputWrapper: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    backgroundColor: "#ffffff",
    border: "1px solid #757575",
    borderRadius: 18,
    width: "80%",
    padding: 6,
    outline: "none",
    color: "#212121",
    textAlign: "left",
    paddingLeft: 10,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    fontFamily: "Karla",
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 26,
    paddingRight: 20,
    letterSpacing: "0.01em",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingRight: 10,
    },
  },

  qrCodeImage: {
    width: 200,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: 150,
      objectFit: "contain",
    },
  },

  para: {
    color: "#919191",
    letterSpacing: 1,
    textAlign: "center",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.5,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },

  connectButton: {
    width: "fit-content",
    height: "45px",
    background: "#6A55EA",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontSize: 16,
    lineHeight: "33px",
    color: "#ffffff",
    fontWeight: 700,
    marginTop: 20,
    padding: "12px 50px 12px 50px",
    "&:hover": {
      background: "#FFB469",
    },
    [theme.breakpoints.down("md")]: {
      padding: "12px 20px 12px 20px",
      fontSize: 18,
    },
  },
  svgImage: {
    width: "100%",
    height: "fit-content",
    maxHeight: 250,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      maxHeight: 120,
    },
  },
  iconWrapper: {
    marginRight: 10,
    backgroundColor: "#FF87FF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 42,
    height: 42,
  },
  icon: {
    width: 25,
    height: 25,
    color: "white",
  },
  cautionTextBox: {
    background: "rgba(250, 173, 20, 0.1)",
    border: " 1px solid #FAAD14",
    borderRadius: "5px",
    padding: 10,
    width: "90%",
  },
  roundedBox: {
    background:
      theme.palette.mode === "light" ? "rgba(189, 180, 241, 0.6)" : "#393D68",
    display: "flex",
    padding: 15,
    width: "90%",
    borderRadius: 12,
    alignItems: "center",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  blueButton: {
    backgroundColor: "#6A55EA",
    border: `1px solid #6A55EA`,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 17,
    textDecoration: "none",
    color: "#fff",
  },
  whiteButton: {
    backgroundColor: "transparent",
    border: "none",
    fontSize: 17,
    textDecoration: "none",
    color: "#6A55EA",
    "&button:hover": {
      color: "red",
    },
  },
  iconContainer: {
    "&:hover $icon": {
      cursor: "hand",
    },
    padding: 0,
    margin: 0,
  },
  imageInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  selectedImage: {
    width: "75%",
    height: 70,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#rgba(205,204,246,0.5)"
        : "#rgba(57,61,104,0.5)",
    padding: "2px 1px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ConfirmPopup = ({
  resetPopup,
  paymentOption,
  paymentOptionFields,
  pendingTrade,
}) => {
  const [isSelect, setSelect] = useState(false);
  const [proof, setProof] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const currentUserAuth = useSelector((state) => state?.user);

  const dispatch = useDispatch();

  const handleImageInput = (e) => {
    const file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      alert("Image size must be less than 5MB");
      return;
    }
    setProof(file);
  };

  const handleSelect = () => {
    if (proof) {
      setSelect(!isSelect);
    }
  };

  const handleConfirm = useCallback(async () => {
    setError(null);
    if (!currentUserAuth?.jwtToken) {
      setError("Invalid auth state please refresh and try again!");
      return;
    }

    const formData = new FormData();

    formData.append("image", proof);
    formData.append("paymentOptionId", paymentOption?._id);
    formData.append("created_at", Date.now());

    setLoading(true);
    const confirmRes = await updateTransaction(
      pendingTrade?._id,
      formData,
      currentUserAuth?.account,
      currentUserAuth?.jwtToken
    );

    setLoading(false);
    if (confirmRes.status !== 201) {
      setError(
        confirmRes.message?.msg
          ? confirmRes.message?.msg
          : "Something went wrong at server! Please try again"
      );
      return;
    }

    dispatch(
      updateTransferData(
        pendingTrade?._id,
        currentUserAuth?.account,
        currentUserAuth?.jwtToken
      )
    );
    dispatch(
      getUserTradeById(
        pendingTrade?._id,
        currentUserAuth?.account,
        currentUserAuth?.jwtToken
      )
    );
    resetPopup();
  }, [loading, setLoading, currentUserAuth?.jwtToken, paymentOption, proof]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  const isConfirmDisabled = useMemo(() => {
    if (!isSelect || !proof) {
      return true;
    }
    return false;
  }, [isSelect, proof]);

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className="d-flex justify-content-end">
          <Close
            style={{ color: theme.palette.text.primary, fontSize: 28 }}
            onClick={resetPopup}
          />
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Box className={classes.heading}>
            <FormattedMessage
              id="Confirm Transfer"
              defaultMessage="Confirm Transfer"
            />
          </Box>
          <CountDownTime
            color={"#919191"}
            createdAt={pendingTrade?.created_at}
            timeWindow={pendingTrade?.time_limit}
            onComplete={() => {}}
          />
        </div>
        <Box display="flex" justifyContent="center" pt={sm ? 1 : 2}>
          <Box className={classes.cautionTextBox}>
            <Typography
              textAlign="center"
              fontSize={sm ? 13 : 16}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
              fontWeight={sm ? 400 : 500}
            >
              <FormattedMessage
                id="confirmnote"
                defaultMessage="P2P transactions require that you make payment or transfer in a
              method that is supported by the receiving party. PolkaBridge does
              not automatically deduct from your account."
              />
            </Typography>
          </Box>
        </Box>

        <Box
          pt={4}
          display="flex"
          w="100%"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box textAlign="center">
            <Typography
              fontSize={sm ? 13 : 18}
              fontWeight={sm ? 400 : 600}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            >
              <FormattedMessage
                id="Transfer Amount"
                defaultMessage="Transfer Amount"
              />
            </Typography>
            <Typography
              variant="h6"
              fontWeight={600}
              fontSize={sm ? 22 : 25}
              paddingTop={1}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            >
              {`${formatCurrency(pendingTrade?.fiat_amount)} ${
                pendingTrade?.order?.fiat?.fiat
              }`}
            </Typography>
          </Box>
          <div className="">
            <SwapHoriz sx={{ color: "#6A55EA", fontSize: sm ? 40 : 50 }} />
          </div>
          <Box textAlign="center">
            <Typography
              fontSize={sm ? 13 : 18}
              fontWeight={sm ? 400 : 600}
              color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            >
              <FormattedMessage
                id="In exchange for"
                defaultMessage="In exchange for"
              />
            </Typography>
            <Typography
              fontSize={sm ? 22 : 25}
              fontWeight={600}
              paddingTop={1}
              color={"#6A55EA"}
            >
              {`${formatCurrency(
                fromWei(
                  pendingTrade?.final_token_amount,
                  pendingTrade?.order?.token?.decimals
                )
              )} ${pendingTrade?.order?.token?.symbol}`}
            </Typography>
          </Box>
        </Box>
        <div className="pt-3 d-flex justify-content-center">
          <Typography
            fontSize={sm ? 14 : 17}
            fontWeight={sm ? 400 : 600}
            color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            textAlign="center"
          >
            <FormattedMessage
              id="Upload proof of payment"
              defaultMessage="Upload proof of payment"
            />
          </Typography>
          <Typography color={"red"} textAlign="start" fontSize={16}>
            *
          </Typography>
        </div>
        <Box display="flex" justifyContent="center" pt={1}>
          <div
            className={classes.roundedBox}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Box className={classes.imageInput}>
              {proof && (
                <Box className={classes.selectedImage}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "80%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(proof)}
                      alt="selected"
                      style={{ maxWidth: "40%" }}
                    />
                    <Typography
                      fontSize={13}
                      ml={sm ? 2 : 4}
                      wordBreak
                      color={theme.palette.text.primary}
                    >
                      {proof.name}
                    </Typography>
                  </div>
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      setProof(null);
                      setSelect(!isSelect);
                    }}
                  >
                    <CloseRounded fontSize="small" color="primary" />
                  </button>
                </Box>
              )}
              {!proof && (
                <label
                  htmlFor="proof-input"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image size="small" color="primary" />
                  <input
                    id="proof-input"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageInput}
                  />
                  <Typography fontSize={sm ? "12px" : "16px"} ml={1}>
                    <FormattedMessage
                      id="Upload proof of payment"
                      defaultMessage="Upload proof of payment"
                    />
                  </Typography>
                </label>
              )}
            </Box>
          </div>
        </Box>
        <div className="pt-3">
          <Typography
            fontSize={sm ? 14 : 17}
            fontWeight={sm ? 400 : 600}
            color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            textAlign="center"
          >
            <FormattedMessage
              id="Confirm the account of the receiving party"
              defaultMessage="Confirm the account of the receiving party"
            />
          </Typography>
        </div>
        <Box display="flex" justifyContent="center" pt={1}>
          <div className={classes.roundedBox} onClick={handleSelect}>
            <Box display="flex" alignItems="center">
              <Checkbox size="small" checked={isSelect} />
              <Box display="flex-fill" flexDirection="column" ml={sm ? 0.5 : 2}>
                {paymentOptionFields?.map((field, index) => {
                  if (!paymentOption?.[field?.key]) return null;

                  return (
                    <Box
                      display="flex"
                      flexDirection={sm ? "column" : "row"}
                      flexWrap="wrap"
                      key={index}
                    >
                      {field?.key !== "qr_code" ? (
                        <Typography
                          fontSize={sm ? 13 : 15}
                          fontWeight={500}
                          mr={1}
                          wordBreak
                        >
                          <FormattedMessage
                            id={field?.label}
                            defaultMessage={field?.label}
                          />
                          :
                        </Typography>
                      ) : (
                        <Hidden mdUp>
                          <Typography
                            fontSize={sm ? 13 : 15}
                            fontWeight={500}
                            mr={1}
                            wordBreak
                          >
                            <FormattedMessage
                              id={field?.label}
                              defaultMessage={field?.label}
                            />
                            :
                          </Typography>
                        </Hidden>
                      )}

                      {field?.key !== "qr_code" ? (
                        <Typography
                          fontSize={sm ? 13 : 15}
                          fontWeight={500}
                          color={"#6A55EA"}
                          style={{
                            wordBreak: "break-all",
                            maxWidth: sm && 200,
                          }}
                        >
                          {paymentOption?.[field?.key]}
                        </Typography>
                      ) : (
                        <Hidden mdUp>
                          {paymentOption.qr_code &&
                            paymentOption.qr_code !== "null" && (
                              <img
                                className={classes.qrCodeImage}
                                src={`${paymentOption.qr_code}`}
                              />
                            )}
                        </Hidden>
                      )}
                    </Box>
                  );
                })}
              </Box>
              <Hidden mdDown>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="left"
                  flexWrap="wrap"
                  ml={sm ? 0 : 8}
                >
                  {paymentOption.qr_code &&
                    paymentOption.qr_code !== "null" && (
                      <Box>
                        <img
                          className={classes.qrCodeImage}
                          src={`${paymentOption.qr_code}`}
                        />
                        <Typography
                          textAlign="center"
                          fontSize={sm ? 13 : 15}
                          fontWeight={500}
                          mt={1}
                          mr={1}
                          whiteSpace="nowrap"
                        >
                          <FormattedMessage
                            id="Payment QR code"
                            defaultMessage="Payment QR code"
                          />
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Hidden>
            </Box>
          </div>
        </Box>

        <div style={{ width: "90%", margin: "3px auto" }} className="pt-3">
          <Typography
            fontSize={sm ? 13 : 16}
            fontWeight={400}
            color={theme.palette.mode === "light" ? "#919191" : "#646892"}
            textAlign="center"
          >
            <FormattedMessage
              id="confirmnote2"
              defaultMessage="Please complete your orders as soon as possible so that the
            receiving party may confirm your transfer. Please select the account
            of the receiving party. Fraudulent payment confirmation will likely
            cause your account to be frozen"
            />
          </Typography>
        </div>

        {error && (
          <Typography
            fontSize={sm ? 13 : 16}
            fontWeight={400}
            color={"red"}
            textAlign="center"
          >
            {error}
          </Typography>
        )}
        <Box className="d-flex justify-content-center align-items-baseline pt-3">
          <div style={{ paddingRight: 10 }}>
            <button
              className={classes.whiteButton}
              onClick={resetPopup}
              style={{
                background: "transparent",
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: sm ? 15 : 20,
                paddingRight: sm ? 15 : 20,
                fontSize: sm ? 13 : 16,
                textDecoration: "none",
              }}
            >
              <FormattedMessage
                id="I didn't Pay Yet"
                defaultMessage="I didn't Pay Yet"
              />
            </button>
          </div>
          <div>
            <button
              onClick={handleConfirm}
              style={{
                background: isConfirmDisabled
                  ? theme.palette.mode === "light"
                    ? "#bdbdbd"
                    : "#646892"
                  : "#6A55EA",

                border: isConfirmDisabled
                  ? theme.palette.mode === "light"
                    ? "1px solid #bdbdbd"
                    : "1px solid #646892"
                  : `1px solid #6A55EA`,
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: sm ? 15 : 20,
                paddingRight: sm ? 15 : 20,
                fontSize: sm ? 13 : 16,
                textDecoration: "none",
                color: "#fff",
              }}
              disabled={isConfirmDisabled || loading}
            >
              {loading ? (
                <FormattedMessage
                  id="Please wait..."
                  defaultMessage="Please wait..."
                />
              ) : (
                <FormattedMessage
                  id="Transfer Completed"
                  defaultMessage="Transfer Completed"
                />
              )}
            </button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ConfirmPopup;
