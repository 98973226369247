import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { FormattedMessage } from "react-intl";
const useStyles = makeStyles((theme) => ({
  cautionTextBox: {
    background: "rgba(254,217,218,0.3)",
    border: " 1px solid #F94144",
    borderRadius: "5px",
    padding: 20,
    width: "90%",

    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
}));
function BanAlert() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display="flex" justifyContent="center" pt={sm ? 2 : 4}>
      <Box className={classes.cautionTextBox}>
        <Typography
          fontSize={sm ? 22 : 24}
          textAlign="center"
          color={theme.palette.mode === "light" ? "#919191" : "#f5f5f5"}
          fontWeight={600}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage id="Banned" defaultMessage="Banned" />
        </Typography>
        <Typography
          fontSize={sm ? 14 : 18}
          textAlign="center"
          color={theme.palette.mode === "light" ? "#919191" : "#f5f5f5"}
          fontWeight={sm ? 400 : 500}
          mb={sm ? 0.5 : 1}
        >
          <FormattedMessage
            id="This account has been banned. If this is unexpected, please contact"
            defaultMessage="This account has been banned. If this is unexpected, please contact"
          />{" "}
          <a href="mailto:">support@polkabridge.org</a>.
        </Typography>
      </Box>
    </Box>
  );
}

export default BanAlert;
