import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  formatCurrency,
  formatTime,
  formattedTokenLabel,
  fromWei,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import Pagination from "common/Pagination";
import { FormattedMessage } from "react-intl";
import { CHAIN_IDS_TO_DISPLAY_NAMES } from "../../constants/chains";

export default function UserAds({
  userAds,
  classes,
  page,
  totalPages,
  changePage,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.tableCard}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Coin" defaultMessage="Coin" />
              </Typography>
            </th>

            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Price" defaultMessage="Price" />
              </Typography>
            </th>

            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Amount" defaultMessage="Amount" />
              </Typography>
            </th>
            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Type" defaultMessage="Type" />
              </Typography>
            </th>

            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Status" defaultMessage="Status" />
              </Typography>
            </th>
            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Time" defaultMessage="Time" />
              </Typography>
            </th>

            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Chain" defaultMessage="Chain" />
              </Typography>
            </th>

            <th>
              <Typography
                textAlign="left"
                variant="body2"
                color={theme.palette.mode === "light" ? "#616161" : "#ffffff"}
                fontSize={sm ? 10 : 12}
                style={{ fontWeight: 500 }}
              >
                <FormattedMessage id="Operation" defaultMessage="Operation" />
              </Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {userAds?.map((orderAd, index) => (
            <tr className={classes.tr} key={index}>
              <td style={{ width: "12%" }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.userText}
                >
                  {formattedTokenLabel(orderAd?.token)}
                </Typography>
              </td>

              <td className={classes.otherText} style={{ width: "10%" }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {formatCurrency(orderAd?.order_unit_price, true)}
                  <span style={{ fontSize: 10, paddingLeft: 2 }}>
                    {orderAd?.fiat?.fiat}
                  </span>
                </Typography>
              </td>
              <td className={classes.otherText}>
                <Box display="flex" flexDirection={"column"}>
                  <Typography
                    textAlign="left"
                    variant="body2"
                    fontSize={sm ? 12 : 14}
                    style={{ fontWeight: 500 }}
                    className={classes.otherText}
                  >
                    {formatCurrency(
                      fromWei(
                        orderAd?.pending_amount,
                        orderAd?.token?.decimals
                      ),
                      true
                    )}
                    <span style={{ fontSize: 10, paddingLeft: 2 }}>
                      {orderAd?.token?.symbol}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize={md ? 10 : 12}
                    color={theme.palette.text.primary}
                  >
                    <FormattedMessage id="Limit" defaultMessage="Limit" />
                    {": "}
                    {orderAd?.min_order_value <= 0
                      ? "1"
                      : formatCurrency(orderAd?.curr_min_order_value, true)}
                    <span
                      style={{
                        paddingLeft: 4,
                      }}
                    >
                      {orderAd?.fiat?.fiat_label}
                    </span>
                    -{formatCurrency(orderAd?.curr_max_order_value, true)}
                    <span
                      style={{
                        paddingLeft: 4,
                      }}
                    >
                      {orderAd?.fiat?.fiat_label}
                    </span>
                  </Typography>
                </Box>
              </td>
              <td className={classes.otherText}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  <FormattedMessage
                    id={orderAd?.order_type?.toUpperCase()}
                    defaultMessage={orderAd?.order_type?.toUpperCase()}
                  />
                </Typography>
              </td>
              <td className={classes.otherText}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                  color={theme.palette.text.primary}
                >
                  <FormattedMessage
                    id={orderAd?.order_status}
                    defaultMessage={orderAd?.order_status}
                  />
                </Typography>
              </td>
              <td className={classes.otherText}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {formatTime(orderAd?.created_at)}
                </Typography>
              </td>
              <td className={classes.otherText}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  fontSize={sm ? 12 : 14}
                  style={{ fontWeight: 500 }}
                  className={classes.otherText}
                >
                  {CHAIN_IDS_TO_DISPLAY_NAMES?.[orderAd?.chainId]}
                </Typography>
              </td>
              <td className={classes.otherText}>
                <Button
                  style={{
                    borderRadius: 10,
                    backgroundColor: theme.palette.primary.main,
                    padding: "5px 20px 5px 20px",
                    color: "white",
                  }}
                  onClick={() => navigate(`/my-orders/${orderAd?._id}`)}
                >
                  <FormattedMessage id="View" defaultMessage="View" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <Pagination
          page={page}
          changePage={changePage}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
}
